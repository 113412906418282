import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'common/environments/environment';
import { ContentHeadingData } from 'common/models/content-heading';
import { ToastService } from 'common/services/toast.service';
import { AddControlWorkflow, WorkflowData, SearchControlStatus } from 'private/app/models/add-control.model';
import { CompanyCode } from 'private/app/models/company.model';
import { SearchQueryParams } from 'private/app/models/default-query-params';
import { CustomerControlContextService } from 'private/app/services/connected-portal/customer-control-context.service';
import { ConnectedPortalUserService } from 'private/app/services/connected-portal/connected-portal-user.service';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { SearchService } from 'private/app/services/connected-portal/search.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { QueryParamsService } from '../../../../services/connected-portal/query-params.service';

enum CustomerUrlSegment {
    CONNECTED = 'connected',
    QUEUED = 'queued'
}

@Component({
    selector: 'hvac-connected-portal-dealer-customers',
    templateUrl: './connected-portal-dealer-customers.component.html',
    styleUrls: ['./connected-portal-dealer-customers.component.scss'],
    providers: [QueryParamsService, CustomerControlContextService]
})
export class ConnectedPortalDealerCustomersComponent implements OnInit, OnDestroy {
    ngOnDestroy$ = new Subject();
    queryControl = new UntypedFormControl('', Validators.required);
    searchPlaceholder: string = '';
    isAddControlModalVisible = false;
    dealerId: string;
    readonly AddControlWorkflow = AddControlWorkflow;
    currentWorkflowStep = '';
    workflowData: WorkflowData;
    showAddControlFeature = environment.features.connectedPortal.addControlFeature;
    public companyCode$: Observable<CompanyCode>;
    public connectedCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public queuedCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    controlType:string = this.translateService.instant('CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.QUEUED_CONTROLS')

    public breadCrumbData: ContentHeadingData = {};
    readonly CompanyCode = CompanyCode;

    constructor(
        public customerControlContextSvc: CustomerControlContextService,
        private searchService: SearchService,
        private translateService: TranslateService,
        private queryParamsService: QueryParamsService,
        private dealersService: DealersService,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router,
        private toastService: ToastService,
        private connectedPortalUserService: ConnectedPortalUserService
    ) { }

    ngOnInit() {
        this.searchService.searchPlaceholder.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((res: string) => {
            this.searchPlaceholder = res;

            this.cdr.detectChanges();
        });

        this.queryParamsService.onParamsChange$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((params) => {
                const { search } = params;

                if (search) {
                    this.queryControl.setValue(search);
                }
            });

        this.route.parent?.paramMap.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((params) => {
            const dealerId = params?.get('id') || '';
            this.dealerId = dealerId;
            this.breadCrumbData = this.getBreadcrumbData(dealerId);

            this.dealersService.queryDealerById(dealerId)
                .pipe(takeUntil(this.ngOnDestroy$)).subscribe((res) => {
                    const { name } = res.data;

                    this.breadCrumbData = this.getBreadcrumbData(dealerId, name);
                });

            this.updateCustomersCount();
        });

        this.companyCode$ = this.connectedPortalUserService.getCompanyCode().pipe(
            map((code: string) => code as CompanyCode)
        );

        this.customerControlContextSvc.updateCustomersCount$.subscribe(() => {
            this.updateCustomersCount();
        });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    handleListMenuLinkClick() {
        this.queryControl.reset();
    }

    handleSearchSubmit(query: string | null) {
        const params = {
            page: 1,
            search: query
        };

        this.queryParamsService.updateParams(params);
    }

    handleSearchReset() {
        const params = {
            page: 1,
            search: null
        };

        this.queryParamsService.updateParams(params);
    }

    getBreadcrumbData(dealerId: string, dealerName?: string): ContentHeadingData {
        return {
            Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CUSTOMERS') },
            breadCrumbs: [
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                    url: '/connected-portal'
                },
                {
                    title: dealerName ? dealerName : this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DEALER'),
                    url: `/connected-portal/dealers/${dealerId}`
                }
            ]
        };
    }


    getQueuedCustomerCount(dealerId: string) {
        const queryParams: SearchQueryParams = {
            pagination: {
                pageSize: 1,
                currentPage: 1
            }
        };

        return this.dealersService
            .queryQueuedCustomersByDealerId(dealerId, queryParams)
            .pipe(
                map((value): number => {
                    const { totalCount } = value;

                    return totalCount;
                })
            );
    }

    getConnectedCustomerCount(dealerId: string) {
        const queryParams: SearchQueryParams = {
            pagination: {
                pageSize: 1,
                currentPage: 1
            }
        };

        return this.dealersService
            .queryPropertiesByDealerId(dealerId, queryParams)
            .pipe(
                map((value): number => {
                    const { totalCount } = value;

                    return totalCount;
                })
            );
    }

    openAddControlModal() {
        this.currentWorkflowStep = AddControlWorkflow.SEARCH_CONTROL;
        this.isAddControlModalVisible = true;
    }

    handleCloseModal(evt?: string) {
        this.isAddControlModalVisible = false;

        if (evt) {
            evt === SearchControlStatus.CONTROL_NOT_REGISTERED ? this.showToast(CustomerUrlSegment.QUEUED, './queued') : this.showToast(CustomerUrlSegment.CONNECTED, './connected');
        }
    }

    handleWorkflowChange(evt: WorkflowData) {
        if (evt) {
            this.workflowData = evt;
            switch (evt.status) {
                case SearchControlStatus.CONTROL_FOUND_READY_TO_LINK:
                    this.currentWorkflowStep = AddControlWorkflow.ADD_CONTROL;
                    break;
                case SearchControlStatus.CONTROL_LINKED_TO_OTHER_DEALER:
                    this.currentWorkflowStep = AddControlWorkflow.ADD_CONTROL;
                    break;
                case SearchControlStatus.CONTROL_NOT_REGISTERED:
                    this.currentWorkflowStep = AddControlWorkflow.ADD_CONTROL;
                    break;
                default:
                    break;
            }
        }
    }

    private isActiveRoute(urlSegment: string): boolean {
        const params = this.router.url?.split('/');

        return params && params[params.length - 1].split('?')[0] === urlSegment;
    }

    private updateCustomersCount() {
        this.getConnectedCustomerCount(this.dealerId).subscribe((connectedCount) => {
            this.connectedCount$.next(connectedCount);
        });

        this.getQueuedCustomerCount(this.dealerId).subscribe((queuedCount) => {
            this.queuedCount$.next(queuedCount);
        });
    }

    private showToast(urlSegment: string, path: string) {
        if (this.isActiveRoute(urlSegment) && this.customerControlContextSvc.toastContent) {
            this.toastService.add(this.customerControlContextSvc.toastContent);
            this.customerControlContextSvc.toastContent = null;
        }
        this.router.navigate([path], { relativeTo: this.route });
        this.updateCustomersCount();
    }
}
