<div class="hvac-models-table-container">
    <div class="hvac-county-filter-container">
        <utc-input class="hvac-col-xs hvac-county-input" [inputControl]="filterControlGroup.controls.brandControl"
            label="{{ 'ACCOUNT_ADMIN.MANAGE_COUNTIES.BRAND' | translate }}">
        </utc-input>
        <utc-input class="hvac-col-xs hvac-county-input" [inputControl]="filterControlGroup.controls.countryControl"
            label="{{ 'ACCOUNT_ADMIN.MANAGE_COUNTIES.COUNTRY' | translate }}">
        </utc-input>
        <utc-input class="hvac-col-xs hvac-county-input" [inputControl]="filterControlGroup.controls.stateControl"
            label="{{ 'ACCOUNT_ADMIN.MANAGE_COUNTIES.STATE' | translate }}">
        </utc-input>
        <utc-input class="hvac-col-xs hvac-county-input" [inputControl]="filterControlGroup.controls.countyControl"
            label="{{ 'ACCOUNT_ADMIN.MANAGE_COUNTIES.COUNTY' | translate }}">
        </utc-input>
    </div>
    <div class="hvac-model-table-wrap hvac-rhythm-3">
        <hvac-table>
            <thead>
                <tr>
                    <td>
                        <button class="hvac-select-all-button" hvacButton="text"
                        (click)="selectAllCounties()">
                        {{ 'ACCOUNT_ADMIN.MANAGE_COUNTIES.SELECT_ALL' | translate}}
                    </button>
                    </td>
                    <td *ngFor="let item of dataHeaders">
                        <div class="hvac-model-table-header">
                            <button class="hvac-table-sort-btn" (click)="sort(item)">
                                {{item.title}}
                                <span class="hvac-table-list-sort" [ngClass]="{
                            'hvac-table-list-sort-show': item.order,
                            'column-ascending': item.order === 'asc'
                        }">
                                    <utc-svg src="assets/icons/chevron-down.svg"></utc-svg>
                                </span>
                            </button>

                        </div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let data of (countiesDisplayArray$ | async)'>
                    <td>
                        <utc-checkbox  *ngIf="tableTypeIdentifier === availableCountiesTable; else assignedCounty" [checked]="isChecked(data.isAssigned) || false" inputId="create-account-input-terms" inputName="create-account-input-terms"
                            (change)="selectCounty(data, $event)">
                        </utc-checkbox>
                        <ng-template #assignedCounty>
                            <utc-checkbox [checked]="!data.isAssigned" inputId="create-account-input-terms" inputName="create-account-input-terms"
                            (change)="selectCounty(data, $event)">
                        </utc-checkbox>
                        </ng-template>
                    </td>
                    <td class="hvac-county-parent-brand-name">
                        {{ data.brandName }}
                    </td>
                    <td>
                        {{ data.country }}
                    </td>
                    <td>
                        {{ data.stateName }}
                    </td>
                    <td>
                        {{ data.name }}
                    </td>
                </tr>
            </tbody>
        </hvac-table>
        <p class="hvac-manage-counties-no-results" *ngIf="!(countiesDisplayArray$ | async)?.length && !loading && !emptyCountiesList">{{ 'ACCOUNT_ADMIN.NO_RESULTS' | translate }}</p>
        <p class="hvac-manage-counties-no-results" *ngIf="!(countiesDisplayArray$ | async)?.length && !loading && emptyCountiesList">{{ 'ACCOUNT_ADMIN.NO_COUNTY_FOR_COUNTRY' | translate }}</p>
    </div>
    <div class="hvac-row hvac-between-xs">
        <div class="hvac-county-pagination-control">
            <hvac-pagination *ngIf="totalPages > 1" [totalPages]="totalPages" [currentPage]="currentPage"
                (pageChange)="handlePageChange($event)">
            </hvac-pagination>
        </div>
        <div *ngIf="totalPages > 0" class="hvac-county-assignment-action-container">
            <ng-container *ngIf="tableTypeIdentifier === availableCountiesTable">
                <button class="hvac-county-assignment-button" [hvacButton]="'primary'" (click)="assignSelectedCodes()" [disabled]="!isButtonDisabled()">
                    <span>
                        {{ 'ACCOUNT_ADMIN.MANAGE_COUNTIES.ASSIGN_SELECTED_COUNTIES' | translate  }}
                    </span>
                </button>
            </ng-container>

            <ng-container *ngIf="tableTypeIdentifier === assignedCountiesTable">
                <button class="hvac-county-assignment-button" [hvacButton]="'primary'" (click)="assignSelectedCodes()" [disabled]="!isButtonDisabled()">
                    <span>
                        {{ 'ACCOUNT_ADMIN.MANAGE_COUNTIES.REMOVE_SELECTED_COUNTIES' | translate  }}
                    </span>
                </button>
            </ng-container>

            <ng-container>
                <button class="hvac-county-assignment-button" [hvacButton]="'primary'" (click)="exportCounties()">
                    <span>
                        {{ 'ACCOUNT_ADMIN.MANAGE_COUNTIES.EXPORT' | translate  }}
                    </span>
                </button>
            </ng-container>
        </div>
    </div>
    <utc-loading-spinner *ngIf="isLoading"></utc-loading-spinner>
</div>

