import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from 'common/content/services/error.service';
import { CharSymbol } from 'common/enums/char-symbol';
import { AddToast, Toast, ToastService } from 'common/services/toast.service';
import { differenceInMinutes } from 'date-fns';
import { ConnectedPortalAPIError } from 'private/app/models/connected-portal-error.model';
import { CarrierELTProductDetailsData, EcobeeNIProductDetailsData, EcobeeProductDetailsData, ProductType, SystemType } from 'private/app/models/connected-product.model';
import { DefaultUpdateDatum, DefaultUpdateResponse } from 'private/app/models/default-update-response.model';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { ApiErrorName, ApiResponseCode, MISSING_ROUTE_PARAMS_ERROR } from '../../constants';

export interface PageRouteData {
    dealerId: string;
    propertyId: string;
    systemType: SystemType;
    serialNo: string;
}

export enum EcobeeEditConfigState {
    Unconnected = 'UNCONNECTED',
    EstablishingConnection = 'ESTABLISHING_CONNECTION',
    Connected = 'CONNECTED',
}

@Injectable()
export class ProductDetailContextService implements OnDestroy {
    public onEcobeeDataRefresh$ = new Subject<true>();
    public onConfigSaveConfirmed$ = new Subject<true>();

    public isReportModalVisible$ = new BehaviorSubject(false);
    public closeReportModal$ = new Subject<boolean>();

    public isDiagnosticsModalVisible$ = new BehaviorSubject(false);
    public closeDiagnosticsTest$ = new Subject<Toast>();

    public isConfigEditConfirmModalVisible$ = new BehaviorSubject<boolean>(false);
    public confirmConfigEditSave$ = new Subject<boolean>();

    public enableEcobeeEditConfig$ = new Subject<boolean>();
    public ecobeeEditConfigState$ = new BehaviorSubject<EcobeeEditConfigState>(EcobeeEditConfigState.Unconnected);

    public productData$: Observable<EcobeeProductDetailsData | CarrierELTProductDetailsData | EcobeeNIProductDetailsData>;

    private ngOnDestroy$ = new Subject();

    constructor(
        private translateService: TranslateService,
        private errorService: ErrorService,
        private route: ActivatedRoute,
        private toastService: ToastService
    ) {
        this.confirmConfigEditSave$.pipe(
            tap(() => {
                this.onConfigSaveConfirmed$.next(true);
                this.isConfigEditConfirmModalVisible$.next(false);
            }),
            takeUntil(this.ngOnDestroy$)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    public getIsTestEquipmentEnabled(isWallControlConnected: boolean, isEditConfigEnabled: boolean, isPlcEquipped: boolean, systemType?: SystemType): boolean {
        return Boolean(systemType === SystemType.ECOBEE
            && isPlcEquipped
            && isWallControlConnected
            && isEditConfigEnabled);
    }

    public getRouteData$(): Observable<PageRouteData> {
        if (!this.route.parent) {
            this.errorService.errorStatus$.next(404);

            return EMPTY;
        }

        return combineLatest([
            this.route.parent.paramMap,
            this.route.paramMap
        ]).pipe(
            map(([parentParams, params]): PageRouteData => {
                const dealerId = parentParams.get('id');
                const propertyId = params.get('id');
                const systemType = params.get('systemType');
                const serialNo = params.get('serialNo');

                if (dealerId && propertyId && systemType && serialNo) {
                    return {
                        dealerId,
                        propertyId,
                        systemType: systemType as SystemType,
                        serialNo
                    };
                }
                throw new Error(MISSING_ROUTE_PARAMS_ERROR);
            }),
            catchError((err) => {
                if (err === MISSING_ROUTE_PARAMS_ERROR) {
                    this.errorService.errorStatus$.next(404);
                }

                throw err;
            })
        );
    }

    public showToast(isError: boolean, data: DefaultUpdateResponse | ConnectedPortalAPIError, toastOutlet?: string) {
        const configEditToast: AddToast = {
            outletName: (toastOutlet ? toastOutlet : 'configEditToast'),
            closeable: true,
            autoClose: true
        };

        this.toastService.removeAll();

        if (isError === false) {
            if (data?.code === ApiResponseCode.SUCCESS) {
                const configEditErrorToast: AddToast = {
                    ...configEditToast,
                    ...{
                        content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.SUCCESS.TITLE') + this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.SUCCESS.SUB_TEXT'),
                        theme: 'success'
                    }
                };
                this.toastService.add(configEditErrorToast);
            }
            else if (data?.code === ApiResponseCode.BAD_REQUEST) {
                data.data?.every((datum: DefaultUpdateDatum) => {
                    switch (datum.name) {
                        case ApiErrorName.DeviceSettingsInvalid: {
                            const toast: AddToast = {
                                ...configEditToast,
                                ...{
                                    content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.INVALID_INPUT.TITLE') + this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.INVALID_INPUT.SUB_TEXT'),
                                    theme: 'error'
                                }
                            };

                            this.toastService.add(toast);

                            return false;
                        }
                        default:
                            break;
                    }

                    return true;
                });
            }
            else if (data?.code === ApiResponseCode.SERVICE_UNAVAILABLE) {
                data.data?.every((datum: DefaultUpdateDatum) => {
                    switch (datum.name) {
                        case ApiErrorName.DeviceDisconnectedError: {
                            const deviceDisconnectedErrorToast: AddToast = {
                                ...configEditToast,
                                ...{
                                    content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.DEVICE_DISCONNECTED.TITLE') + this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.DEVICE_DISCONNECTED.SUB_TEXT'),
                                    theme: 'error'
                                }
                            };

                            this.toastService.add(deviceDisconnectedErrorToast);

                            return false;
                        }
                        case ApiErrorName.DeviceCommandUnacknowledgedError: {
                            const unAcknowledgedErrorToast: AddToast = {
                                ...configEditToast,
                                ...{
                                    content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.UNACKNOWLEDGED.TITLE') + this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.UNACKNOWLEDGED.SUB_TEXT'),
                                    theme: 'error'
                                }
                            };

                            this.toastService.add(unAcknowledgedErrorToast);

                            return false;
                        }
                        case ApiErrorName.DiagnosticsTestCompletionDatetime: {
                            const testMinutesRemaining = datum.value
                                ? differenceInMinutes(new Date(datum.value), Date.now())
                                : this.translateService.instant('CONNECTED_PORTAL.NOT_AVAILABLE');
                            const title = this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.TEST_ALREADY_IN_PROGRESS.TITLE');
                            const subtext = this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.TEST_ALREADY_IN_PROGRESS.SUB_TEXT', { testMinutesRemaining });

                            const toast: AddToast = {
                                ...configEditToast,
                                ...{
                                    content: title + subtext,
                                    theme: 'error'
                                }
                            };

                            this.toastService.add(toast);

                            return false;
                        }
                        default:
                            break;
                    }

                    return true;
                });
            }
        }
        else {
            const configEditErrorToast: AddToast = {
                ...configEditToast,
                ...{
                    content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.UNACKNOWLEDGED.TITLE') + this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.UNACKNOWLEDGED.SUB_TEXT'),
                    theme: 'error'
                }
            };
            this.toastService.add(configEditErrorToast);
        }
    }

    public getBreadcrumbData(productType: ProductType, dealerId: string, propertyId: string, dealerName?: string) {
        const contentTitle = this.getContentTitle(productType);

        const data = {
            Content: { title: contentTitle },
            breadCrumbs: [
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                    url: `/connected-portal`
                },
                {
                    title: dealerName ? dealerName : this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DEALER'),
                    url: `/connected-portal/dealers/${dealerId}`
                },
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CUSTOMERS'),
                    url: `/connected-portal/dealers/${dealerId}/customers`
                },
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.PROPERTY_DETAILS'),
                    url: `/connected-portal/dealers/${dealerId}/customers/${propertyId}`
                }
            ]
        };

        return data;
    }

    private getContentTitle(productType: ProductType) {
        switch (productType) {
            case ProductType.WallControl:
                return this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONTROL_DETAILS');
            case ProductType.IndoorUnit:
                return this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.INDOOR_UNIT_DETAILS');
            case ProductType.OutdoorUnit:
                return this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.OUTDOOR_UNIT_DETAILS');
            default:
                return CharSymbol.DoubleDash;
        }
    }
}
