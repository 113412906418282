<ng-container *ngIf="(dataSharingPermissions$ | async) as dataSharingPermissions">
    <div class="hvac-row">
        <div class="hvac-col-xs-12">
            <div class="hvac-cp-card">
                <hvac-product-details-card
                    *ngIf="(productData$ | async) as productData; else productDetailLoader"
                    [dealerId]="dealerId"
                    [systemInfo]="productData.system"
                    [propertyId]="propertyId"
                    [product]="productData.productInfo"
                    [isDeviceConnected]="productData.isWallControlConnected"></hvac-product-details-card>

                <ng-template #productDetailLoader>
                    <div class="hvac-product-detail-loader">
                        <utc-loading-spinner></utc-loading-spinner>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <ng-container *ngIf="(accordions$ | async) as accordions">
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.alerts"
                    [open]="accordions.alerts && dataSharingPermissions?.alerts === true" (toggle)="toggleAccordion$.next('alerts')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.ALERTS.CONTROL_ALERTS' | translate }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-product-detail-alerts
                            *ngIf="(alertsData$ | async) as alertsData"
                            [data]="alertsData.items"
                            [currentPage]="alertsData.currentPage"
                            [totalPages]="alertsData.totalPages" (onPageChange)="alertsCurrentPage$.next($event)">
                        </hvac-product-detail-alerts>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>

        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.historicalData"
                    [open]="accordions.historicalData && dataSharingPermissions?.historicalData === true"
                    (toggle)="toggleAccordion$.next('historicalData')">
                    <hvac-accordion-header>{{'CONNECTED_PORTAL.ODU.HISTORICAL_DATA.HEADING' |
                        translate}}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-product-property-list *ngIf="(historicalData$ | async) as historicalData">
                            <hvac-product-property-list-item *ngFor="let prop of historicalData">
                                <hvac-product-property-list-item-label> {{'CONNECTED_PORTAL.ODU.HISTORICAL_DATA.' + prop[0] |
                                    translate}} </hvac-product-property-list-item-label>
                                <hvac-product-property-list-item-value> {{ prop[1] }} </hvac-product-property-list-item-value>
                            </hvac-product-property-list-item>
                        </hvac-product-property-list>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>

        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.detailedStatus"
                    [open]="accordions.status && dataSharingPermissions?.detailedStatus === true"
                    (toggle)="toggleAccordion$.next('status')">
                    <hvac-accordion-header>{{'CONNECTED_PORTAL.ODU.STATUS.HEADING' | translate}}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-product-property-list *ngIf="(oduStatusProps$ | async) as oduStatusProps">
                            <hvac-product-property-list-item *ngFor="let prop of oduStatusProps">
                                <hvac-product-property-list-item-label> {{'CONNECTED_PORTAL.ODU.STATUS.' + prop[0] | translate}}
                                </hvac-product-property-list-item-label>
                                <hvac-product-property-list-item-value>
                                    <utc-svg class="hvac-cp-warning-icon" src="assets/icons/info.svg"
                                        *ngIf="prop[1] === (CharSymbol.DoubleDash)"></utc-svg> {{ prop[1] }}
                                </hvac-product-property-list-item-value>
                            </hvac-product-property-list-item>
                        </hvac-product-property-list>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>

        <div class="hvac-row">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.controlConfiguration"
                    [open]="accordions.configuration && dataSharingPermissions?.controlConfiguration === true"
                    (toggle)="toggleAccordion$.next('configuration')">
                    <hvac-accordion-header>{{'CONNECTED_PORTAL.ODU.CONFIGURATION.HEADING' | translate}}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-infinity-odu-configuration *ngIf="dataSharingPermissions?.controlConfiguration && serialNo && systemType"
                            [dealerId]="dealerId"
                            [serialNo]="serialNo" [systemType]="systemType" [editEnabled]="dataSharingPermissions?.editConfig"
                            [saveEnabled]="dataSharingPermissions?.saveConfig"
                            (onSaveButtonClick)="contextService.isConfigEditConfirmModalVisible$.next(true)"
                            [isDeviceConnected]="(isWallControlConnected$ | async) || false"></hvac-infinity-odu-configuration>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
    </ng-container>
</ng-container>
