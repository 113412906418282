import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectedSoldTos, UpdatedUserRelationshipsDetails, UserDetailsEntity, UserRelationshipsDetails, UserSoldTos } from 'private/app/models/user.model';
import { UserRelationshipsEditService } from 'private/app/services/account-admin/user/edit-user-relationships.service';
import { UserAdminService } from 'private/app/services/account-admin/user/user-admin.service';
import { UserApiService } from 'private/app/services/account-admin/user/user-api.service';
@Component({
    selector: 'hvac-edit-user-relationships',
    templateUrl: './edit-user-relationships.component.html',
    styleUrls: ['./edit-user-relationships.component.scss']
})
export class EditUserRelationshipsComponent implements OnInit {
  @Input() userDetails: UserDetailsEntity;
  @Output() public isCancelButtonclicked = new EventEmitter();
  public isLoading = false;
  public showAssignedSoldTo: boolean = false;
  public availableSoldToArray: UserSoldTos[] = [];
  public assignedSoldToArray: UserSoldTos[] = [];
  public userRelationshipsData: UserRelationshipsDetails;
  public availableSoldToTable = 'availableSoldToTable';
  public assignedSoldToTable = 'assignedSoldToTable';
  public addOrRemoveUserDetails: UpdatedUserRelationshipsDetails;
  public tableType: string;
  private addSoldtoList: string[] = [];
  private removeSoldtoList: string[] = [];
  private selectedSoldTos: UserSoldTos[] = [];

  constructor(
      public userRelationshipsEditService: UserRelationshipsEditService,
      public userAdminService: UserAdminService,
      public userApiService: UserApiService
  ) {}

  ngOnInit(): void {
      this.isLoading = true;
      this.userAdminService.menuActive = false;
      this.userApiService.editUserRelationshipsDetails(this.userDetails.userID).subscribe((data) => {
          this.userRelationshipsData = data;
          this.availableSoldToArray = data.availableSoldTo;
          this.assignedSoldToArray = data.assignedSoldTo;
          this.isLoading = false;
      }, (error) => {
          if (error) {
              this.isLoading = false;
              this.userAdminService.showToastBar('error', error.error.message);
          }
      });
  }

  toggleTable(tableType: string) {
      this.showAssignedSoldTo = (tableType === this.assignedSoldToTable);
      if (this.tableType === 'availableSoldToTable') {
          this.availableSoldToArray.map((soldTo) => {
              soldTo.assigned = false;

              return soldTo;
          });
      }
      if (this.tableType === 'assignedSoldToTable') {
          this.assignedSoldToArray.map((soldTo) => {
              soldTo.assigned = true;

              return soldTo;
          });
      }
      this.selectedSoldTos = [];
  }

  onSelectedSoldTos(assignedSoldTo: SelectedSoldTos) {
      this.selectedSoldTos = assignedSoldTo.soldTos;
      this.tableType = assignedSoldTo.type;
  }

  isCancelButtonclickedFn(event: Event) {
      this.isCancelButtonclicked.emit(event);
  }

  assignSoldToAction() {
      if (this.selectedSoldTos.length > 0) {
          this.isLoading = true;
          this.selectedSoldTos.forEach((element) => {
              element.assigned ? this.addSoldtoList.push(element.soldTo) : this.removeSoldtoList.push(element.companyId);
          });
          this.addOrRemoveUserDetails = {
              userId: this.userDetails.userID,
              addedList: this.addSoldtoList,
              removedList: this.removeSoldtoList
          };
          this.addSoldtoList = [];
          this.removeSoldtoList = [];
          this.userApiService.updateUserRelationshipsDetails(this.addOrRemoveUserDetails).subscribe((data) => {
              this.userApiService.editUserRelationshipsDetails(this.userDetails.userID).subscribe((response) => {
                  this.userRelationshipsData = response;
                  this.availableSoldToArray = response.availableSoldTo;
                  this.assignedSoldToArray = response.assignedSoldTo;
              });
              this.isLoading = false;
              this.userAdminService.showToastBar('success', data.message);
          }, (error) => {
              if (error) {
                  this.userAdminService.showToastBar('error', error.error.message);
              }
          });
      }
      else {
          this.userAdminService.showToastBar('error', 'Please select atleast one company');
      }
  }
}
