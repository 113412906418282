/* eslint-disable no-undefined */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'common/app-constants';
import { ContentHeadingData } from 'common/models/content-heading';
import { LanguageTranslationService } from 'common/services/language-translation.service';
import { ToastService } from 'common/services/toast.service';
import { endOfDay, startOfDay } from 'date-fns';
import { RelationshipCriteriaDate } from 'private/app/models/account-admin-company.model';
import { AccountNumbersAPIResponse, AccountNumbersDataResponse, CustomerMonthlyStatementPayload } from 'private/app/models/monthly-customer-statement.model';
import { AccountPostAdminService } from 'private/app/services/account-admin/account-post-admin.service';
import { UserAdminService } from 'private/app/services/account-admin/user/user-admin.service';
import { MonthlyCustomerStatementsService } from 'private/app/services/monthly-customer-statements.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'hvac-monthly-customer-statements',
    templateUrl: './monthly-customer-statements.component.html',
    styleUrls: ['./monthly-customer-statements.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class MonthlyCustomerStatementsComponent implements OnInit {
    public breadCrumbData: ContentHeadingData;
    public customerReportForm: FormGroup;
    public isModalVisible: boolean = false;
    public inputDateControls: [UntypedFormControl, UntypedFormControl];
    public startDate: number | undefined;
    public endDate: number | undefined;
    public currentPage: number = 1;
    public pageSize: number = 10;
    public totalPages: number;
    public isTableLoading: boolean = false;
    public isAPIRequestActive: boolean = false;
    public filterControlGroup = this.monthlycustomerStatementsService.filterControlGroup;
    public dataHeaders = this.monthlycustomerStatementsService.dataHeaders;
    public accountNumbersArray$ = new BehaviorSubject<AccountNumbersDataResponse[]>([]);
    public accountNumberDisplayArray$ = new BehaviorSubject<AccountNumbersDataResponse[]>([]);
    public selectedAccountNumberArray$ = new BehaviorSubject<AccountNumbersDataResponse[]>([]);
    public selectedAccountNumber$ = new BehaviorSubject<string[]>([]);
    public dateRangeError: string = '';
    public fileName: string = '';
    public isNoPayerAssigned: boolean = false;

    dateRange$: BehaviorSubject<RelationshipCriteriaDate> = new BehaviorSubject<RelationshipCriteriaDate>({
        startDate: undefined,
        endDate: undefined
    });

    private searchFilteredData: AccountNumbersDataResponse[] = [];
    private ngOnDestroy$ = new Subject();
    private filteredData$ = new BehaviorSubject<AccountNumbersDataResponse[]>([]);

    constructor(
        public translate: TranslateService,
        public accountPostAdminService: AccountPostAdminService,
        public monthlycustomerStatementsService: MonthlyCustomerStatementsService,
        public userAdminService: UserAdminService,
        private languageTranslationService: LanguageTranslationService,
        private toastService: ToastService
    ) { }

    ngOnInit(): void {
        this.languageTranslationService.translationsLoaded().subscribe(() => {
            this.breadCrumbData = this.monthlycustomerStatementsService.breadCrumbData;
            this.monthlycustomerStatementsService.setAdminBreadCrumbData(this.breadCrumbData);
        });
        this.customerReportForm = this.monthlycustomerStatementsService.customerStatementForm;
        this.inputDateControls = [this.monthlycustomerStatementsService.customerStatementForm.controls.startDate, this.monthlycustomerStatementsService.customerStatementForm.controls.endDate];

        this.monthlycustomerStatementsService.customerStatementForm.controls.customerIds.setValue([]);

        this.resetForm();
        this.getAccountNumbers();

        this.filterControlGroup.valueChanges.pipe(takeUntil(this.ngOnDestroy$), distinctUntilChanged(), tap(() => this.isTableLoading = true), delay(AppConstants.enrollmentsSearchDelay)).subscribe(() => {
            this.filterAccountNumbers();
        });
        this.dateRange$.subscribe((date) => this.checkDateRange(date));
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    getAccountNumbers() {
        this.isTableLoading = true;
        this.accountPostAdminService.getAccountNumbers().subscribe((result: AccountNumbersAPIResponse) => {
            this.isNoPayerAssigned = Boolean(result.totalCount);
            this.isTableLoading = false;
            this.totalPages = Math.ceil(result.totalCount / this.pageSize);
            this.accountNumbersArray$.next(result.accountNumbers);
            this.filteredData$.next(result.accountNumbers);
            this.accountNumberDisplayArray$.next(result?.accountNumbers?.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize));
        });
    }

    resetForm() {
        this.filterControlGroup?.controls.accountNumberControl.setValue('');
        this.filterControlGroup?.controls.accountNameControl.setValue('');
        this.filterControlGroup?.controls.soldToControl.setValue('');
        this.filterControlGroup?.controls.cityControl.setValue('');
        this.filterControlGroup?.controls.stateControl.setValue('');
    }

    handleCloseModal() {
        this.isModalVisible = false;
    }

    handlePageChange(page: number) {
        this.currentPage = parseInt(page.toString(), 10);
        const filterData = this.searchFilteredData.length ? this.searchFilteredData : this.filteredData$.value;
        this.accountNumberDisplayArray$.next(
            filterData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
        );
    }

    selectAccountNumber(account: AccountNumbersDataResponse, event: Event) {
        this.accountNumbersArray$.value.map((data) => {
            if (data.payer === account.payer) {
                data.isSelected = (event.target as HTMLInputElement).checked;
            }
            else {
                data.isSelected = false;
            }

            return data;
        });
    }

    selectMultipleAccountsButton() {
        this.isModalVisible = false;
        const selectedAccountNumbers: string[] = [];
        this.accountNumbersArray$.value.filter((item) => (item.isSelected) && selectedAccountNumbers.push(item.payer));
        this.monthlycustomerStatementsService.customerStatementForm.controls.customerIds.setValue([...new Set(selectedAccountNumbers)]);
        this.selectedAccountNumber$.next(selectedAccountNumbers);
    }

    setSelectionDate(dates: RelationshipCriteriaDate) {
        this.dateRange$.next({
            startDate: dates.startDate,
            endDate: dates.endDate
        });
        if (dates.startDate) {
            this.startDate = startOfDay(dates.startDate).getTime();
        }
        if (dates.endDate) {
            this.endDate = endOfDay(dates.endDate).getTime();
        }
    }

    checkDateRange(date: RelationshipCriteriaDate) {
        if (date.startDate && date.endDate) {
            const start = new Date(date.startDate);
            const end = new Date(date.endDate);
            const difference = Math.abs(end.getTime() - start.getTime());
            const differenceInDays = Math.ceil(difference / (1000 * 60 * 60 * 24));
            this.dateRangeError = (differenceInDays > 31) ? this.translate.instant('MONTHLY_CUSTOMER_STATEMENT.DATE_RANGE_ERROR') : '';
        }
        else {
            this.dateRangeError = '';
        }
    }

    onDownload(event: UntypedFormGroup) {
        this.isAPIRequestActive = true;
        this.isAPIRequestActive = true;
        const body: CustomerMonthlyStatementPayload = {
            CustomerIds: [],
            requestType: '',
            responseFormat: 'CSV'
        };

        const customerId = this.monthlycustomerStatementsService.customerStatementForm.controls.customerIds.value;
        body.CustomerIds = (customerId) ? customerId : [];

        if (this.monthlycustomerStatementsService.monthlyStatementForm.closedItems === true) {
            body.requestType = 'cleared';
            body.startDate = event.value.startDate;
            body.endDate = event.value.endDate;
            this.fileName = `${String(`Customer_Statement_${customerId}_${body.requestType}_${body.startDate}_${body.endDate}.csv`)}`;
        }
        else {
            body.requestType = 'open';
            this.fileName = `${String(`Customer_Statement_${customerId}_${body.requestType}_.csv`)}`;
        }


        this.accountPostAdminService.exportCustomerStatements(body).subscribe((response) => {
            this.isAPIRequestActive = false;
            this.userAdminService.smoothScrollToTop();
            if (response.status === 200) {
                const url = window.URL.createObjectURL(response.body);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = this.fileName;
                document.body.appendChild(anchor);
                anchor.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(anchor);

                this.toastService.add({
                    content: this.translate.instant('MONTHLY_CUSTOMER_STATEMENT.EXPORT_SUCCESS'),
                    theme: 'success',
                    id: 'customerStatmentSuccess',
                    closeable: true,
                    autoClose: true
                });
            }
            else if (response.status === 204) {
                this.toastService.add({
                    content: this.translate.instant('MONTHLY_CUSTOMER_STATEMENT.NO_CONTENT_FOUND'),
                    theme: 'error',
                    id: 'customerStatmentSuccess',
                    closeable: true,
                    autoClose: true
                });
            }
        }, (err) => {
            if (err) {
                this.isAPIRequestActive = false;
                this.toastService.add({
                    content: this.translate.instant('MONTHLY_CUSTOMER_STATEMENT.ERROR_WHILE_EXPORT'),
                    theme: 'error',
                    id: 'customerStatmentError',
                    closeable: true,
                    autoClose: true
                });
            }
        });
    }

    private filterAccountNumbers() {
        const formControls = this.filterControlGroup.value;
        this.searchFilteredData = this.filteredData$.value
            .filter((item) => item.payer?.includes(formControls.accountNumberControl) &&
            item.companyName?.toLowerCase().includes(formControls.accountNameControl?.toLowerCase()) &&
            item.soldTo?.toLowerCase().includes(formControls.soldToControl?.toLowerCase()) &&
            item.city?.toLowerCase().includes(formControls.cityControl.toLowerCase()) &&
            item.state?.toLowerCase().includes(formControls.stateControl?.toLowerCase()));

        this.totalPages = Math.ceil(this.searchFilteredData.length / this.pageSize);
        this.accountNumberDisplayArray$.next(this.searchFilteredData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize));
        this.isTableLoading = false;
    }
}
