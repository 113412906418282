<nav class="hvac-cp-navigation" *ngIf="(navLinks$ | async) as navLinks">
    <ul class="hvac-cp-nav-list"
        [ngClass]="{ 'hvac-cp-nav-list-open': isNavOpen }" (hvacClickOutside)="closeNav()">
        <button class="hvac-cp-toggle" (click)="toggleNav()"><span class="hvac-accessible-text">{{ 'CONNECTED_PORTAL.NAV_LABELS.NAVIGATION_MENU' | translate }}</span><utc-svg class="hvac-cp-chevron-icon" src="assets/icons/chevron-down.svg"></utc-svg></button>
        <li *ngFor="let link of navLinks; trackBy:trackByNavLink"
            class="hvac-cp-nav-list-item"
            routerLinkActive="hvac-cp-nav-list-item-active"
            >
            <a class="hvac-p-sm hvac-cp-nav-link" [attr.aria-disabled]="link.comingSoon" [ngClass]="{ 'hvac-cp-nav-link-disabled': link.comingSoon }"
                routerLinkActive="hvac-cp-nav-link-active" [routerLink]="link.route" target="_self">
                    <span class="hvac-cp-item-text">{{ link.label | translate }}</span>
                    <span class="hvac-cp-coming-soon-text">{{ 'CONNECTED_PORTAL.COMING_SOON' | translate }}</span>
                    <span class="hvac-cp-pro-feature" *ngIf="link.isPro">Pro</span>
                </a>

            <div class="hvac-p-sm hvac-cp-active-item-text"><span class="hvac-cp-item-text">{{ link.label | translate }}</span></div>
        </li>
    </ul>
</nav>
<utc-loading-spinner *ngIf="(isLoadingNavLink$ | async)" [spinnerSize]="'small'"></utc-loading-spinner>
