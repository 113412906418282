<form [formGroup]="companyCreateForm">
    <section class="hvac-company-info-container">
        <div class="hvac-company-header">
            <h2> {{ "ACCOUNT_ADMIN.COMPANY_CREATE.BUSINESS_INFO" | translate }}</h2>
        </div>
        <div class="hvac-company-form">
            <div class="hvac-row">
                <div class="hvac-col-xs-12 hvac-col-lg-6">
                    <div class="hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.COMPANY_NAME" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8"
                            [error]="companyCreateForm.controls.companyName.dirty && !companyCreateForm.controls.companyName.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.COMPANY_NAME' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.companyName" type="text"
                            [max]="200">
                        </utc-input>
                    </div>
                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.parentCompany?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.PARENT_COMPANY" | translate }}
                        </div>

                        <hvac-autocomplete shadow="light" class="hvac-col-xs-12 hvac-col-lg-8" [inputControl]="$any(companyCreateForm.controls.parentCompany)"
                            [data]="(companyCreateService.parentCompanyData$ | async) || []"
                            (onSelect)="onParentCompanyChange($event)"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.PARENT_COMPANY' | translate">
                        </hvac-autocomplete>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.companyUrl?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.COMPANY_URL" | translate }}
                        </div>
                        <utc-input shadow="light" shadow="light" class="hvac-col-xs-12 hvac-col-lg-8"
                            [error]="companyCreateForm.controls.companyUrl.dirty && !companyCreateForm.controls.companyUrl.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.COMPANY_URL' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.companyUrl" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row">
                            <div [ngClass]="(!isServicesDisabled) ? 'hvac-col-xs-12 hvac-col-lg-4 hvac-label' : 'hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required'">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.SERVICES" | translate }}
                        </div>
                        <utc-select shadow="light" class="hvac-col-xs-12 hvac-col-lg-8" [group]="companyCreateForm" control="services" [multiple]="true"
                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.SERVICES' | translate }}"
                            [disabled]="!isServicesDisabled"
                            [options]="(companyCreateService.servicesOptionData | async ) || []"
                            [error]="(companyCreateForm.controls.services.dirty && !companyCreateForm.controls.services.valid) || isServicesInvalid"
                            (onChange)="onServicesChange($event)">
                        </utc-select>
                    </div>


                    <div class="hvac-row"  *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.claimType?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.CLAIM_TYPE" | translate }}
                        </div>
                        <utc-select shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field" [group]="companyCreateForm"
                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.CLAIM_TYPE' | translate }}" shadow="light"
                            control="claimType" [options]="claimOptions" [disabled]="isCBP" [error]="companyCreateForm.controls.claimType.dirty && !companyCreateForm.controls.claimType.valid"
                            (onChange)="onClaimTypeChange($event)">
                        </utc-select>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.brands?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.BRANDS" | translate }}
                        </div>
                        <utc-select shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field" [group]="companyCreateForm"
                            [error]="companyCreateForm.controls.brands.dirty && !companyCreateForm.controls.brands.valid"
                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.BRANDS' | translate }}"
                            control="brands" [multiple]="true" [options]="brandList"
                            (onChange)="onBrandsChange($event)">
                        </utc-select>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.contactLic?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.CONT_LIC" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.CONT_LIC' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.contLic" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.soldTo?.view">
                        <div [ngClass]="(isSoldToRequired$ | async) ? 'hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required' : 'hvac-col-xs-12 hvac-col-lg-4 hvac-label'">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.SELL_TO" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.soldTo.dirty && !companyCreateForm.controls.soldTo.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ENTER_SAP_SOLD_TO_NUMBER' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.soldTo" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.shipToNumber?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.SHIP_TO" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.shipTo.dirty && !companyCreateForm.controls.shipTo.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ENTER_SAP_SHIP_TO_NUMBER' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.shipTo" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.payer?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.PAYER" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.payer.dirty && !companyCreateForm.controls.payer.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ENTER_SAP_PAYER' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.payer" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.priceList?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.PRICE_LIST" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.priceList.dirty && !companyCreateForm.controls.priceList.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.PRICE_LIST' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.priceList" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.priceGroup?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.PRICE_GROUP" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.priceGroup.dirty && !companyCreateForm.controls.priceGroup.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.PRICE_GROUP' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.priceGroup" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row"  *ngIf="companyConfiguration?.formConfig?.isPrimary?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.PRIMARY" | translate }}
                        </div>
                        <div class="hvac-col-xs-12 hvac-col-lg-8">
                            <utc-checkbox
                                [inputId]="'isPrimary'" [value]="'Is Primary'"
                                (change)="companyCreateService.onIsPrimaryChange($event)"
                                [inputName]="'isPrimary'">
                            </utc-checkbox>
                        </div>
                    </div>

                    <div class="hvac-row"  *ngIf="companyConfiguration?.formConfig?.isAssignable?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.ACCOUNT_ASSIGNABLE" | translate }}
                        </div>
                        <div class="hvac-col-xs-12 hvac-col-lg-8">
                            <utc-checkbox
                                [inputId]="'isAssignable'" [value]="'Is Assignable'"
                                (change)="companyCreateService.onIsAccountAssignableChange($event)"
                                [inputName]="'isAssignable'">
                            </utc-checkbox>
                        </div>
                    </div>

                    <div class="hvac-row"  *ngIf="companyConfiguration?.formConfig?.legalEntity?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.LOCATION" | translate }}
                        </div>
                        <div class="hvac-col-xs-12 hvac-col-lg-8">
                            <hvac-radio-button [checked]="companyCreateService.company.legalEntity || false" [inputName]="'location'"
                            (change)="companyCreateService.company.legalEntity = true">
                                {{ 'ACCOUNT_ADMIN.MAIN' | translate }}
                            </hvac-radio-button>
                            <hvac-radio-button [checked]="!companyCreateService.company.legalEntity || false" [inputName]="'location'"
                            (change)="companyCreateService.company.legalEntity = false">
                                {{ 'ACCOUNT_ADMIN.BRANCH' | translate }}
                            </hvac-radio-button>
                        </div>
                    </div>

                    <div class="hvac-row"  *ngIf="companyConfiguration?.formConfig?.flagForPOS?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.FLAG_FOR_POS" | translate }}
                        </div>
                        <div class="hvac-col-xs-12 hvac-col-lg-8">
                            <utc-checkbox
                                inputId="'flagForPOS'"
                                (change)="companyCreateService.onflagForPOS($event)"
                                inputName="'flagForPOS'">
                            </utc-checkbox>
                        </div>
                    </div>
                    <div class="hvac-row"  *ngIf="companyConfiguration?.formConfig?.flagForDC?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.FLAG_FOR_DC" | translate }}
                        </div>
                        <div class="hvac-col-xs-12 hvac-col-lg-8">
                            <utc-checkbox
                                inputId="'flagForDC'"
                                (change)="companyCreateService.onflagForDC($event)"
                                inputName="'flagForDC'">
                            </utc-checkbox>
                        </div>
                    </div>

                    <div class="hvac-row"  *ngIf="companyConfiguration?.formConfig?.showOnDistributorLocator?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.SHOW_ON_DISTRIBUTOR_LOCATOR" | translate }}
                        </div>
                        <div class="hvac-col-xs-12 hvac-col-lg-8">
                            <utc-checkbox
                                inputId="'showOnDistributorLocator'"
                                (change)="companyCreateService.onShowOnDistributorLocator($event)"
                                inputName="'showOnDistributorLocator'">
                            </utc-checkbox>
                        </div>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.locatorName?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.LOCATOR_NAME" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.LOCATOR_NAME' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.locatorName" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.latitude?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label" [ngClass]="{ 'hvac-required' : (companyCreateService.locationMandatory$ | async) }">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.LATITUDE" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.latitude.dirty && !companyCreateForm.controls.latitude.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.LATITUDE' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.latitude" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.longitude?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label" [ngClass]="{ 'hvac-required' : (companyCreateService.locationMandatory$ | async) }">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.LONGITUDE" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.longitude.dirty && !companyCreateForm.controls.longitude.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.LONGITUDE' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.longitude" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.salesOfficeNumber?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.SALES_OFFICE_NUMBER" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.SALES_OFFICE_NUMBER' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.salesOfficeNumber" type="text">
                        </utc-input>
                    </div>

                </div>
                <div class="hvac-col-xs-12 hvac-col-lg-6">

                    <section *ngIf="companyConfiguration?.managerInformation?.show">
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.COMPANY_CREATE.RSM" | translate }}
                            </div>
                            <utc-select shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                                [group]="companyCreateForm" control="rsm"

                                (onChange)="setManager(companyCreateService.contactType.RSM, $event)"
                                defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.RSM' | translate }}"
                                [options]="formData.rsms || []">
                            </utc-select>
                        </div>

                        <div class="hvac-row" *ngIf="companyConfiguration?.managerInformation?.partRSM?.view">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.COMPANY_CREATE.PARTS_RSM" | translate }}
                            </div>
                            <utc-select shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                                [group]="companyCreateForm" control="partRSM"

                                (onChange)="setManager(companyCreateService.contactType.PRSM, $event)"
                                defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.PRSM' | translate }}"
                                [options]="formData.prsm || []">
                            </utc-select>
                        </div>

                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.COMPANY_CREATE.ACCOUNT_MANAGER" | translate }}
                            </div>
                            <utc-select shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                                [group]="companyCreateForm" control="accountManagers"

                                (onChange)="setManager(companyCreateService.contactType.AM, $event)"
                                defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.AM' | translate }}"
                                [options]="formData.accountManagers || []">
                            </utc-select>
                        </div>

                        <div class="hvac-row"  *ngIf="companyConfiguration?.managerInformation?.partAM?.view">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.COMPANY_CREATE.PARTS_ACCT_MANAGER" | translate }}
                            </div>
                            <utc-select shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                                [group]="companyCreateForm" control="partAM"

                                (onChange)="setManager(companyCreateService.contactType.PTAM, $event)"
                                defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.PTAM' | translate }}"
                                [options]="formData.ptam || []">
                            </utc-select>
                        </div>

                        <div class="hvac-row"  *ngIf="companyConfiguration?.managerInformation?.partAM?.view">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.COMPANY_CREATE.TSM" | translate }}
                            </div>
                            <utc-select shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                                [group]="companyCreateForm" control="tsm"

                                defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.TSM' | translate }}"
                                (onChange)="setManager(companyCreateService.contactType.TSM, $event)"
                                [options]="formData.tsms || []">
                            </utc-select>
                        </div>

                        <div class="hvac-row"  *ngIf="companyConfiguration?.formConfig?.pricingAnalyst?.view">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.COMPANY_CREATE.PRICING_ANAL" | translate }}
                            </div>
                            <utc-select shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field" [group]="companyCreateForm"
                                control="pricingAnalyst"

                                (onChange)="setManager(companyCreateService.contactType.PA, $event)"
                                defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.PRICING_ANALYST' | translate }}"
                                [options]="formData.pricingAnal || []">
                            </utc-select>
                        </div>

                        <div class="hvac-divider"></div>
                    </section>


                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.divRegNumber?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.DIV_REG_NUMBER" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.divRegNumber.dirty && !companyCreateForm.controls.divRegNumber.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.DIV_REG_NUMBER' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.divRegNumber" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.c10Parent?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.C10_PARENT" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.c10Parent.dirty && !companyCreateForm.controls.c10Parent.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.C10_PARENT' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.c10Parent" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.c10Child?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.C10_CHILD" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.c10Child.dirty && !companyCreateForm.controls.c10Child.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.C10_CHILD' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.c10Child" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.storeNumber?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.STORE_NUMBER" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.c10Child.dirty && !companyCreateForm.controls.storeNumber.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.STORE_NUMBER' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.storeNumber" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.searchRadius?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.SEARCH_RADIUS" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.c10Child.dirty && !companyCreateForm.controls.searchRadius.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.SEARCH_RADIUS' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.searchRadius" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.language?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.LANGUAGE" | translate }}
                        </div>
                        <utc-select shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [group]="companyCreateForm" control="language" [multiple]="true"
                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.LANGUAGE' | translate }}"
                            (onChange)="onLanguageChange($event)"

                            [options]="companyCreateService.languageOptions || []">
                        </utc-select>
                    </div>

                    <div class="hvac-row" *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.hoursOfOperation?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.HOURS_OF_OPERATION" | translate }}
                        </div>
                        <div class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field">
                            <utc-checkbox *ngFor="let operation of companyCreateService?.hoursOfOperation"
                                [checked]="operation.toLowerCase() == companyCreateService.selectedHoursOfOperarion.toLowerCase()"
                                [inputId]="operation" [value]="operation"
                                (change)="companyCreateService.setHoursOfOperation($event,operation)"
                                [inputName]="operation">
                                <span class="hvac-search-filter-name">{{ operation }}</span>
                            </utc-checkbox>
                        </div>
                    </div>

                    <div class="hvac-divider"></div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.inventoryAPI?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.DISTRIBUTOR_INVENTORY_API" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.inventoryAPI.dirty && !companyCreateForm.controls.inventoryAPI.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.DISTRIBUTOR_INVENTORY_API' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.inventoryAPI" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.regionCode?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.DISTRIBUTOR_REGION_CODE" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.regionCode.dirty && !companyCreateForm.controls.regionCode.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.DISTRIBUTOR_REGION_CODE' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.regionCode" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.customerUserName?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.DISTRIBUTOR_USER_NAME" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.customerUserName.dirty && !companyCreateForm.controls.customerUserName.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.DISTRIBUTOR_USER_NAME' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.customerUserName" type="text">
                        </utc-input>
                    </div>

                    <div class="hvac-row" *ngIf="companyConfiguration?.formConfig?.customerGUID?.view">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.CUSTOMER_GUID" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.customerGUID.dirty && !companyCreateForm.controls.customerGUID.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.CUSTOMER_GUID' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.customerGUID" type="text">
                        </utc-input>
                    </div>

                </div>
            </div>
        </div>
    </section>
</form>
