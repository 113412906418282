import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { ContentHeadingData } from 'common/models/content-heading';
import { ToastService } from 'common/services/toast.service';
import { AssignedCounties, ChildTable, CountiesResponse, CountiesResponseData, CountiesStrings } from 'private/app/models/manage-counties.model';
import { ManageCountiesService } from 'private/app/services/manage-counties.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-manage-counties',
    templateUrl: './manage-counties.component.html',
    styleUrls: ['./manage-counties.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ManageCountiesComponent implements OnInit {
  public breadCrumbData: ContentHeadingData = {
      Content: { title: this.translate.instant('ACCOUNT_ADMIN.MANAGE_COUNTIES.MANAGE_COUNTIES_TITLE') },
      breadCrumbs: [
          {
              title: this.translate.instant('ACCOUNT_ADMIN.PARENT_1'),
              url: '/admin'
          },
          {
              title: this.translate.instant('ACCOUNT_ADMIN.PARENT_2'),
              url: '/admin/admin-tools'
          }
      ]
  };

  public hvacpCompanyName: string;
  public loading: boolean;
  public emptyCountyList: boolean = false;
  public showAssignedCounties = false;
  public assignedCountiesArray: CountiesResponse[] = [];
  public availableCountiesArray: CountiesResponse[] = [];
  public availableCountiesArray$ = new BehaviorSubject<CountiesResponse[]>([]);
  public assignedCountiesArray$ = new BehaviorSubject<CountiesResponse[]>([]);
  public availableCountiesTable = CountiesStrings.availableCountiesTable;
  public assignedCountiesTable = CountiesStrings.assignedCountiesTable;
  public selectedCounties: CountiesResponse[];
  public hvacCompanyId: number;
  public userId: string;
  public tableTypeForSubmit: string;
  private hvacpCompanyId: string;
  private ngOnDestroy$ = new Subject();

  constructor(
      private manageCountiesService: ManageCountiesService,
      private translate: TranslateService,
      private router: Router,
      private store: Store,
      private toastService: ToastService
  ) {
      this.router.events.pipe(takeUntil(this.ngOnDestroy$)).subscribe((event) => {
          if (event instanceof NavigationEnd) {
              const urlSplit = event.urlAfterRedirects.split('/').slice(-1).toString() || '';
              this.hvacpCompanyId = urlSplit ? urlSplit.split('?compName=')[0] : '';
              this.hvacpCompanyName = urlSplit ? decodeURIComponent(urlSplit.split('?compName=')[1]) : '';
          }
      });
  }

  ngOnInit(): void {
      this.manageCountiesService.backAction.pipe(takeUntil(this.ngOnDestroy$), skip(1)).subscribe((value) => {
          if (value) {
              this.store.dispatch(new Navigate(['Admin/Admin-Tools/account-admin.html/company', this.hvacpCompanyId]));
          }
      });
      if (this.hvacpCompanyId) {
          this.getCounties();
      }
  }

  getCounties() {
      this.assignedCountiesArray = [];
      this.availableCountiesArray = [];
      this.loading = true;
      this.manageCountiesService.getCounties(this.hvacpCompanyId).subscribe((value) => {
          this.loading = false;
          this.hvacCompanyId = value.hvacCompanyId;
          this.userId = value.userId;
          this.segregateCounties(value.counties);
      });
  }

  segregateCounties(counties: CountiesResponse[]) {
      if (counties.length === 0) {
          this.emptyCountyList = true;
      }
      counties.map((countie) => {
          if (countie.isAssigned === 1) {
              this.assignedCountiesArray.push(countie);
          }
          else if (countie.isAssigned === 0) {
              this.availableCountiesArray.push(countie);
          }

          return countie;
      });
      this.assignedCountiesArray$.next(this.assignedCountiesArray);
      this.availableCountiesArray$.next(this.availableCountiesArray);
  }

  backToCompanyDetails() {
      this.manageCountiesService.backAction.next(true);
  }

  onSelectAllCheckboxSelection(value: ChildTable) {
      if (value.tableType === this.availableCountiesTable) {
          this.availableCountiesArray$.value?.map((county: CountiesResponse) => {
              county.isAssigned = Number(value.isSelected);

              return county;
          });
      }
      else {
          this.assignedCountiesArray$.value?.map((county: CountiesResponse) => {
              county.isSelected = Number(value.isSelected);

              return county;
          });
      }
  }

  onSelectedCounties(assignedCounty: AssignedCounties) {
      this.selectedCounties = assignedCounty.counties;
      this.tableTypeForSubmit = assignedCounty.type;
  }

  toggleTable(tableType: string) {
      this.showAssignedCounties = (tableType === this.assignedCountiesTable);
  }

  saveAction() {
      this.loading = true;
      this.assignedCountiesArray = (this.tableTypeForSubmit === this.availableCountiesTable) ? Array.from(this.assignedCountiesArray.concat(this.selectedCounties).reduce((objA, objB) => objA.set(objB.id, objB), new Map()).values()) : this.selectedCounties;
      const body: CountiesResponseData = {
          counties: this.assignedCountiesArray,
          hvacCompanyId: this.hvacCompanyId,
          userId: this.userId
      };
      this.manageCountiesService.smoothScrollToTop();
      this.manageCountiesService.postUpdatedCounties(body).subscribe((data) => {
          this.loading = false;
          if (data.status === 'Success') {
              this.getCounties();
              this.toastService.add({
                  content: this.translate.instant('ACCOUNT_ADMIN.MANAGE_COUNTIES.COUNTIES_POST_SUCCESS_MESSAGE'),
                  theme: 'success',
                  id: 'COUNTIES_POST_SUCCESS_MESSAGE',
                  closeable: true,
                  autoClose: true
              });
          }
      }, () => {
          this.loading = false;
          this.toastService.add({
              content: this.translate.instant('ACCOUNT_ADMIN.MANAGE_COUNTIES.COUNTIES_POST_ERROR_MESSAGE'),
              id: this.translate.instant('ACCOUNT_ADMIN.MANAGE_COUNTIES.COUNTIES_POST_ERROR_MESSAGE'),
              theme: 'error',
              closeable: true,
              autoClose: true
          });
      });
  }
}
