<div class="hvac-account-admin-results-search hvac-row" [ngClass]="{'hvac-is-collapsed': !accountAdminService.formOpen$.value}">
    <aside class="hvac-account-admin-results-search-form" [ngClass]="{'hvac-account-admin-results-search-form-open': accountAdminService.formOpen$.value}">
        <div class="hvac-account-admin-results-search-form-wrap">
            <div class="hvac-row hvac-between-xs no-gutter hvac-middle-xs hvac-account-admin-results-heading-wrap">
                <h1 class="hvac-col-xs hvac-h4 hvac-account-admin-heading">{{ 'ACCOUNT_ADMIN.ADVANCED_SEARCH_HEADING' | translate }}</h1>

                <div>
                    <a [routerLink]="['/Admin/Admin-Tools/account-admin.html']" class="hvac-text-link">
                        {{ 'ACCOUNT_ADMIN.BACK_TO_SEARCH' | translate}}
                    </a>
                </div>
            </div>

            <hvac-account-admin-form [onAccountAdminResult]="isResultPage"></hvac-account-admin-form>
        </div>
    </aside>

    <main class="hvac-account-admin-results">
        <div class="hvac-account-admin-heading hvac-account-admin-heading-default">
            <hvac-tab-bar>
                <button routerLink="companies" routerLinkActive="hvac-active" queryParamsHandling="merge">{{ 'ACCOUNT_ADMIN.COMPANIES' | translate }}</button>
                <button routerLink="contacts" routerLinkActive="hvac-active" queryParamsHandling="merge">{{ 'ACCOUNT_ADMIN.CONTACTS' | translate }}</button>
                <button routerLink="users" routerLinkActive="hvac-active" queryParamsHandling="merge">{{ 'ACCOUNT_ADMIN.USERS' | translate }}</button>

            </hvac-tab-bar>

            <button *ngIf="adminAccess && isCreateUserFeatureAvailable" type="button" routerLink="/Admin/Admin-Tools/create-user" class="hvac-user-edit-details-button" [hvacButton]="'default'" size="small">
                {{ 'ACCOUNT_ADMIN.CREATE_USER' | translate }}
              </button>
            <button *ngIf="adminAccess" type="button" class="hvac-user-edit-details-button" routerLink="/Admin/Admin-Tools/create-company" [hvacButton]="'default'"  size="small">
                {{ 'ACCOUNT_ADMIN.CREATE_COMPANY' | translate }}
            </button>
        </div>

        <router-outlet></router-outlet>
    </main>
</div>
