import { CreateCompanyComponent } from './views/create-company/create-company.component';
import { CreateUserComponent } from './views/create-user/create-user.component';
import { Routes } from '@angular/router';
import { environment } from 'common/environments/environment';
import { AccountAdminFormComponent } from './components/account-admin-form/account-admin-form.component';
import { AccountAdminCompanyDetailsComponent } from './views/account-admin-company-details/account-admin-company-details.component';
import { AccountAdminContactDetailsComponent } from './views/account-admin-contact-details/account-admin-contact-details.component';
import { AccountAdminResultsComponent } from './views/account-admin-results/account-admin-results.component';
import { CompaniesTableComponent } from './views/account-admin-results/companies-table/companies-table.component';
import { UsersTableComponent } from './views/account-admin-results/users-table/users-table.component';
import { AccountAdminComponent } from './views/account-admin/account-admin.component';
import { Auth } from './guards/auth.guard';
import { EnvironmentFeaturesGuard } from './guards/environment-features/environment-features.guard';
import { DealerLocatorDetailsComponent } from './views/dealer-locator/dealer-locator-details/dealer-locator-details.component';
import { DealerLocatorComponent } from './views/dealer-locator/dealer-locator.component';
import { ThirdPartyRatingsComponent } from './views/dealer-locator/third-party-rating/third-party-ratings.component';
import { ZipcodeAssignmentComponent } from './views/dealer-locator/zipcode-assignment/zipcode-assignment.component';
import { SearchProgramComponent } from './views/program/search-program/search-program.component';
import { EditProgramComponent } from './views/program/edit-program/edit-program.component';
import { ProgramAdminComponent } from './views/program/program-admin.component';
import { ManageEnrollmentsComponent } from './views/program/manage-enrollments/manage-enrollments.component';
import { EditEligibilityCriteriaComponent } from './views/program/edit-eligibility-criteria/edit-eligibility-criteria.component';
import { CreateProgramComponent } from './views/program/create-program/create-program.component';
import { EnrollmentFormComponent } from './views/program/enrollment-form/enrollment-form.component';
import { UsersComponent } from './views/account-admin-results/user/users.component';
import { UserDetailsComponent } from './views/account-admin-results/user/user-details/user-details.component';
import { AccountAdminGuard } from './guards/account-admin/account-admin.guard';
import { IdmUserRoles } from './enums/idm-user-roles';
import { IDMUserRolesResolver } from './resolver/idm-user-role.resolver';
import { CreateEligibilityCriteriaComponent } from './views/program/create-eligibility-criteria/create-eligibility-criteria.component';
import { UserRegistrationsComponent } from './components/user-registrations/user-registrations.component';
import { ManageCountiesComponent } from './views/manage-counties/manage-counties.component';
import { ProgramEnrollmentsComponent } from './views/program-enrollments/program-enrollments.component';

export const accountAdminRoutes: Routes = [
    {
        path: 'Admin/Admin-Tools/account-admin.html',
        component: AccountAdminComponent,
        canActivate: [
            Auth,
            AccountAdminGuard
        ],
        data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN, IdmUserRoles.ACCOUNTREADONLY] },
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: '',
                component: AccountAdminFormComponent,
                data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] },
                resolve: { adminAccess: IDMUserRolesResolver }
            },
            {
                path: 'results',
                component: AccountAdminResultsComponent,
                resolve: { adminAccess: IDMUserRolesResolver },
                children: [
                    {
                        path: '',
                        redirectTo: 'companies',
                        pathMatch: 'full'
                    },
                    {
                        path: 'companies',
                        component: CompaniesTableComponent
                    },
                    {
                        path: 'contacts',
                        component: UsersTableComponent
                    },
                    {
                        path: 'users',
                        component: UsersComponent
                    }
                ]
            },
            {
                path: 'company/:id',
                component: AccountAdminCompanyDetailsComponent,
                data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] },
                resolve: { adminAccess: IDMUserRolesResolver }
            },
            {
                path: 'contact/:id',
                component: AccountAdminContactDetailsComponent
            },
            {
                path: 'user-details/:id',
                component: UserDetailsComponent
            }
        ]
    },
    {
        path: 'Admin/Admin-Tools/manage-dealer-locator/:id',
        component: DealerLocatorComponent,
        canActivate: [
            Auth,
            AccountAdminGuard
        ],
        data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] },
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: '',
                redirectTo: 'details',
                pathMatch: 'full'
            },
            {
                path: 'details',
                component: DealerLocatorDetailsComponent
            },
            {
                path: 'zipcode',
                component: ZipcodeAssignmentComponent
            },
            {
                path: 'ratings',
                component: ThirdPartyRatingsComponent
            }
        ]
    },
    {
        path: 'Admin/Admin-Tools/manage-counties/:id',
        component: ManageCountiesComponent,
        canActivate: [
            Auth,
            AccountAdminGuard
        ],
        data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'Admin/Admin-Tools/program-enrollments/:id',
        component: ProgramEnrollmentsComponent,
        canActivate: [
            Auth,
            AccountAdminGuard
        ],
        data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'Admin/Admin-Tools/create-company',
        component: CreateCompanyComponent,
        canActivate: [
            Auth,
            AccountAdminGuard
        ],
        data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] }
    },
    {
        path: 'Admin/Admin-Tools/create-user',
        component: CreateUserComponent,
        canActivate: [
            Auth,
            AccountAdminGuard
        ],
        data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] },
        resolve: { adminAccess: IDMUserRolesResolver }
    },
    {
        path: 'Admin/Admin-Tools/user-registrations',
        component: UserRegistrationsComponent,
        canActivate: [
            Auth,
            AccountAdminGuard
        ],
        data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] },
        resolve: { adminAccess: IDMUserRolesResolver }
    },
    {
        path: 'Admin/Program/search-program',
        component: ProgramAdminComponent,
        canActivate: [
            Auth,
            EnvironmentFeaturesGuard,
            AccountAdminGuard
        ],
        canActivateChild: [
            AccountAdminGuard
        ],
        data: {
            envFeature: environment.features.searchProgram,
            accessRoles: [IdmUserRoles.MARKETING_PROGRAM_ADMIN,
                IdmUserRoles.CMNA_MARKETING_PROGRAM_ADMIN,
                IdmUserRoles.PROGRAM_ENROLLMENT_ADMIN,
                IdmUserRoles.CMNA_PROGRAM_ENROLLMENT_ADMIN]
        },
        children: [
            {
                path: '',
                redirectTo: 'search',
                pathMatch: 'full'
            },
            {
                path: 'search',
                component: SearchProgramComponent,
                data: {
                    accessRoles: [IdmUserRoles.MARKETING_PROGRAM_ADMIN,
                        IdmUserRoles.CMNA_MARKETING_PROGRAM_ADMIN,
                        IdmUserRoles.PROGRAM_ENROLLMENT_ADMIN,
                        IdmUserRoles.CMNA_PROGRAM_ENROLLMENT_ADMIN]
                },
                resolve: { programAccess: IDMUserRolesResolver }
            },
            {
                path: 'create',
                component: CreateProgramComponent,
                data: { accessRoles: [IdmUserRoles.MARKETING_PROGRAM_ADMIN, IdmUserRoles.CMNA_MARKETING_PROGRAM_ADMIN] },
                resolve: { programAccess: IDMUserRolesResolver }
            },
            {
                path: 'edit/:programId',
                component: EditProgramComponent,
                data: {
                    accessRoles: [IdmUserRoles.MARKETING_PROGRAM_ADMIN,
                        IdmUserRoles.CMNA_MARKETING_PROGRAM_ADMIN,
                        IdmUserRoles.PROGRAM_ENROLLMENT_ADMIN,
                        IdmUserRoles.CMNA_PROGRAM_ENROLLMENT_ADMIN]
                },
                resolve: { programAccess: IDMUserRolesResolver }
            },
            {
                path: 'manage-enrollments/:programId',
                component: ManageEnrollmentsComponent,
                data: {
                    accessRoles: [IdmUserRoles.MARKETING_PROGRAM_ADMIN,
                        IdmUserRoles.CMNA_MARKETING_PROGRAM_ADMIN,
                        IdmUserRoles.PROGRAM_ENROLLMENT_ADMIN,
                        IdmUserRoles.CMNA_PROGRAM_ENROLLMENT_ADMIN]
                },
                resolve: { programAccess: IDMUserRolesResolver }
            },
            {
                path: 'enrollments/form',
                component: EnrollmentFormComponent,
                data: {
                    accessRoles: [IdmUserRoles.MARKETING_PROGRAM_ADMIN,
                        IdmUserRoles.CMNA_MARKETING_PROGRAM_ADMIN,
                        IdmUserRoles.PROGRAM_ENROLLMENT_ADMIN,
                        IdmUserRoles.CMNA_PROGRAM_ENROLLMENT_ADMIN]
                }
            },
            {
                path: 'edit-eligibility-criteria',
                component: EditEligibilityCriteriaComponent,
                data: {
                    accessRoles: [IdmUserRoles.MARKETING_PROGRAM_ADMIN,
                        IdmUserRoles.CMNA_MARKETING_PROGRAM_ADMIN]
                }
            },
            {
                path: 'create-eligibility-criteria',
                component: CreateEligibilityCriteriaComponent,
                data: {
                    accessRoles: [IdmUserRoles.MARKETING_PROGRAM_ADMIN,
                        IdmUserRoles.CMNA_MARKETING_PROGRAM_ADMIN]
                }
            },
            {
                path: 'clone/:programId',
                component: EditProgramComponent,
                data: { accessRoles: [IdmUserRoles.MARKETING_PROGRAM_ADMIN, IdmUserRoles.CMNA_MARKETING_PROGRAM_ADMIN] },
                resolve: { programAccess: IDMUserRolesResolver }
            }
        ]
    }
];
