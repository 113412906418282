<ng-container *ngIf="(dataSharingPermissions$ | async) as dataSharingPermissions">
    <div class="hvac-row">
        <div class="hvac-col-xs-12">
            <div class="hvac-cp-card">
                <hvac-product-details-card *ngIf="(productData$ | async) as productData; else productDetailLoader"
                    [dealerId]="dealerId"
                    [propertyId]="propertyId"
                    [systemInfo]="productData.system"
                    [product]="productData.productInfo"
                    [isConnectionStatusVisible]="true"
                    [isDeviceConnected]="productData.isWallControlConnected"
                    [isRemoveControlEnabled]="true"
                    [dataSharingPermissions]="dataSharingPermissions"></hvac-product-details-card>

                    <ng-template #productDetailLoader>
                        <div class="hvac-product-detail-loader">
                            <utc-loading-spinner ></utc-loading-spinner>
                        </div>
                    </ng-template>
            </div>

            <ng-container *ngIf="(systemConditionsZonesDataState$ | async) as zonesDataState">
                <div *ngIf="dataSharingPermissions?.systemConditions"
                    class="hvac-system-conditions-container hvac-cp-card"
                    [ngClass]="{ 'hvac-system-conditions-container-no-zones': zonesDataState === ApiResponseState.Error}">

                    <div class="hvac-row hvac-end-md" [ngClass]="{ 'hvac-rhythm-4': zonesDataState === ApiResponseState.Success }">
                        <div class="hvac-zones-header hvac-col-xs-12">
                            <div *ngIf="(wallControlStatus$ | async) as wallControlStatus" class="hvac-zone-status-date hvac-p-sm">{{
                                'CONNECTED_PORTAL.WALL_CONTROL.STATUS_AS_OF' | translate }} {{
                                wallControlStatus.dateTime | dateTimeTz }}</div>

                            <div class="hvac-zone-tabs" *ngIf="zonesDataState === ApiResponseState.Success && (isZoningEnabled$ | async) === 'on'">
                                <div class="hvac-label-sm hvac-zone-label">{{
                                    'CONNECTED_PORTAL.WALL_CONTROL.ZONING.HEADER' | translate }}</div>
                                <hvac-tab-bar variant="secondary">
                                    <a *ngFor="let zone of (wallControlDetails$ | async)?.zones" [queryParams]="{ scroll: false }" [routerLink]="zone.name | encodeURIComponent"
                                            routerLinkActive="hvac-active">
                                        {{zone.name}} </a>
                                </hvac-tab-bar>
                            </div>
                        </div>

                        <div *ngIf="zonesDataState === ApiResponseState.Error" class="hvac-system-conditions-no-zones-message hvac-col-xs-12">
                            <span class="hvac-h5"> {{'CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_CONDITIONS_MESSAGES.NO_DATA_DETECTED' |
                                translate}} </span>
                        </div>
                    </div>

                    <router-outlet></router-outlet>

                    <utc-loading-spinner class="hvac-zone-loader" *ngIf="zonesDataState === ApiResponseState.Loading"></utc-loading-spinner>
                </div>
            </ng-container>

        </div>
    </div>
    <ng-container *ngIf="(accordions$ | async) as accordions">
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.detailedStatus"
                    [open]="accordions.detailedStatus && dataSharingPermissions?.detailedStatus === true"
                    (toggle)="toggleAccordion$.next('detailedStatus')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.DETAILED_STATUS.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-wall-control-status-details *ngIf="(wallControlStatus$ | async) as wallControlStatus"
                            [data]="wallControlStatus"
                            [systemType]="systemType">
                        </hvac-wall-control-status-details>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.systemInfo"
                    [open]="accordions.systemInformation && dataSharingPermissions?.systemInfo === true"
                    (toggle)="toggleAccordion$.next('systemInformation')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_INFORMATION.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-wall-control-system-info *ngIf="(wallControlSystemInfo$ | async) as wallControlSystemInfo"
                            [data]="wallControlSystemInfo"></hvac-wall-control-system-info>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [open]="accordions.notification" (toggle)="toggleAccordion$.next('notification')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.NOTIFICATION.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-wall-control-notifications
                            *ngIf="(wallControlNotificationsData$ | async) as wallControlNotificationsData"
                            [data]="wallControlNotificationsData.items"
                            [currentPage]="wallControlNotificationsData.currentPage"
                            [totalPages]="wallControlNotificationsData.totalPages"
                            (onPageChange)="wallControlNotificationsCurrentPage$.next($event)"></hvac-wall-control-notifications>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.eventLog"
                    [open]="accordions.eventLog && dataSharingPermissions?.eventLog === true"
                    (toggle)="toggleAccordion$.next('eventLog')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-wall-control-event-log *ngIf="(wallControlEventLogData$ | async) as eventLogData"
                            [config]="eventLogData.config" [data]="eventLogData.items"
                            [totalPages]="eventLogData.totalPages" [currentPage]="eventLogData.currentPage"
                            (onPageChange)="wallControlEventLogCurrentPage$.next($event)"></hvac-wall-control-event-log>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.humidityAndVentilation"
                    [open]="accordions.humidityAndVentilation && dataSharingPermissions?.humidityAndVentilation === true"
                    (toggle)="toggleAccordion$.next('humidityAndVentilation')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.HUMIDITY_AND_VENTILATION.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-wall-control-humidity-and-ventilation-details
                            *ngIf="(wallControlHumidAndVent$ | async) as wallControlHumidAndVent"
                            [data]="wallControlHumidAndVent"></hvac-wall-control-humidity-and-ventilation-details>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.controlConfiguration"
                    [open]="accordions.config && dataSharingPermissions?.controlConfiguration === true"
                    (toggle)="toggleAccordion$.next('config')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-infinity-wall-control-config
                            *ngIf="dataSharingPermissions?.controlConfiguration"
                            [dealerId]="dealerId"
                            [serialNo]="serialNo"
                            [systemType]="systemType"
                            [isEditEnabled]="dataSharingPermissions?.editConfig"
                            [isSaveEnabled]="dataSharingPermissions?.saveConfig"
                            [isDeviceConnected]="(isWallControlConnected$ | async) || false"></hvac-infinity-wall-control-config>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [open]="accordions.zoning" (toggle)="toggleAccordion$.next('zoning')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.ZONING.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-wall-control-zoning-details *ngIf="(wallControlZoning$ | async) as wallControlZoning"
                            [data]="wallControlZoning"
                            [tempUnitFormat]="tempUnitFormat"
                            [isZoningEnabled]="(isZoningEnabled$ | async)"></hvac-wall-control-zoning-details>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
        <div class="hvac-row hvac-rhythm-3" *ngIf="infinityWallControlDetailEventsService.isInfinityWallControlEnergyEventEnabled">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.energyEvents || (productData$ | async)?.system?.vppEligibility !== EventType.ENROLLED"
                                    [open]="accordions.energyEvents && dataSharingPermissions?.energyEvents === true"
                                    (toggle)="toggleAccordion$.next('energyEvents')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.ENERGY_EVENTS.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <ng-container *ngIf="dataSharingPermissions?.energyEvents">
                            <hvac-wall-control-energy-events
                                *ngIf="(wallControlEnergyEventData$ | async) as eventData"
                                [data]="eventData.items"
                                [totalPages]="eventData.totalPages"
                                [currentPage]="eventData.currentPage"
                                (onPageChange)="infinityWallControlDetailEventsService.wallControlEnergyEventCurrentPage$.next($event)"
                            ></hvac-wall-control-energy-events>
                            <utc-loading-spinner *ngIf="infinityWallControlDetailEventsService.isLoadingEnergyEvents$ | async" variant="overlay"></utc-loading-spinner>
                        </ng-container>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
    </ng-container>

</ng-container>

