import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'hvac-customers-list-menu',
    templateUrl: './customers-list-menu.component.html',
    styleUrls: ['./customers-list-menu.component.scss'],
    standalone: true,
    imports: [TranslateModule, RouterModule, CommonModule]
})
export class CustomersListMenuComponent {
    @Input() connectedCount = 0;
    @Input() queuedCount = 0;
    @Output() onLinkClick = new EventEmitter<string>();

    handleLinkClick(link: string) {
        this.onLinkClick.emit(link);
    }
}
