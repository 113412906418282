import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'common/environments/environment';
import { ApiOptions } from '../../../../common/services/api-options/api-options.service';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { CompanyAuthSearchFields, CompanySearchFields, DuplicateCompanyAuthSearchFields } from 'private/app/models/distributor-relationships.model';
import { ContactsPost, User } from 'private/app/models/account-admin-search';
import { AccountAdminDistributorBranch, CompanySearchResultResponse } from 'private/app/models/account-admin-search-results';
import { AccountNumbersAPIResponse, CustomerMonthlyStatementPayload } from 'private/app/models/monthly-customer-statement.model';

@Injectable({ providedIn: 'root' })
export class AccountPostAdminService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) {
    }

    public postCompanySearch(body: CompanySearchFields): Observable<CompanySearchResultResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<CompanySearchResultResponse>(`${environment.api.accountAdminCompanySearch}`, body, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public postCompanyAuthSearch(body: CompanyAuthSearchFields): Observable<CompanySearchResultResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<CompanySearchResultResponse>(`${environment.api.accountAdminCompanyAuthSearch}`, body, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public duplicateCompanyAuthSearch(body: DuplicateCompanyAuthSearchFields): Observable<CompanySearchResultResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<CompanySearchResultResponse>(`${environment.api.accountAdminCompanyAuthSearch}`, body, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public postCompanySearchExport(body: string): Observable<any> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<string>(`${environment.api.accountAdminExport}`, body, {
                ...options,
                observe: 'response',
                responseType: 'blob' as 'json'
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public postContactsSearch(body: string): Observable<User> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<User>(`${environment.api.accountAdminContactSearch}`, body, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public postContacts(body: ContactsPost) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<User>(`${environment.api.accountAdminContacts}`, body, { ...options })),
            catchError((err: HttpErrorResponse) => of(err))
        );
    }

    public getDistributorBranch(hvacCompanyId: string): Observable<AccountAdminDistributorBranch[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<AccountAdminDistributorBranch[]>(`${environment.api.accountAdminDistributorBranchApi}${hvacCompanyId}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getAccountNumbers(): Observable<AccountNumbersAPIResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<AccountNumbersAPIResponse>(`${environment.api.customerStatementsAccountNumbers}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public exportCustomerStatements(body: CustomerMonthlyStatementPayload): Observable<any> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<string>(`${environment.api.getCustomerStatements}`, body, {
                ...options,
                observe: 'response',
                responseType: 'blob' as 'json'
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }
}
