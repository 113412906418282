import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BaseContentEntity, EntityModelData } from 'common/models';
import { AccountStatusResponse, UserStatus } from 'common/models/account-status';
import { User } from 'common/models/auth';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { AuthState } from 'common/store/auth/auth.state';
import { AccountState } from 'common/store/create-account.state';
import { OpenRecentActivity } from 'common/store/ui/ui.actions';
import { ResetPasswordStatus } from 'private/app/models/accountInfo';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { DataResolverService } from '../../../../common/content/services/data-resolver.service';
import { DashboardContentService } from './services/dashboard-content.service';
import { AccountStatusService } from 'common/services/account-status.service';
import { environment } from 'common/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { IdmService } from 'private/app/services/idm.service';
import { UserEntity } from 'private/app/models/user.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastService } from 'common/services/toast.service';
import { BaseComponent } from 'common/components/base/base.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let pendo: any;

@Component({
    selector: 'utc-secured-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DashboardComponent extends BaseComponent implements OnInit {
    @Select(AuthState.user) user$: Observable<User | null>;
    @Select(AccountStatusState.isPublicPlusUser) isPublicPlusUser$: Observable<UserStatus>;
    @Select(AccountStatusState.isActiveUser) isActiveUser$: Observable<UserStatus>;
    @Select(AccountState.passwordResetStatus) passwordResetStatus$: Observable<ResetPasswordStatus>;
    public envtName: string;
    public userID: string;
    public visitorID: string;
    public companyID: string;
    public accountID: string;
    public firstName: string;
    public lastName: string;
    public userEmail: string;
    public companyName: string;
    public companyType: string;
    public creationDate: string;
    public idToken: string;
    public rolesArray: string[] = [];
    public brandsArray: string[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageData$: Observable<any>;

    whatsNewContent$ = this.dashboardContent.whatsNew$;

    whatsNewDocListContent$ = this.dashboardContent.whatsNewDocList$;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    videosContent$: Observable<EntityModelData<any>[]> = this.dashboardContent.videos$.pipe(
        map((data) => (data ? data.Content.list.$values.slice(0, 4) : []))
    );

    highlightsContent$: Observable<BaseContentEntity[] | undefined> = this.dashboardContent.highlights$;
    serviceBulletinsContent$: Observable<BaseContentEntity | undefined> = this.dashboardContent.serviceBulletins$;
    bulletinsDocListContent$: Observable<BaseContentEntity | undefined> = this.dashboardContent.bulletinsDocList$;

    showHighlightsSection$ = combineLatest([this.highlightsContent$, this.serviceBulletinsContent$, this.bulletinsDocListContent$]).pipe(
        map(([highlightContent, serviceBulletinsContent, bulletinsDocListContent]) => ((highlightContent && highlightContent.length > 0) || (serviceBulletinsContent && serviceBulletinsContent.Content.list.$values.length > 0) || (bulletinsDocListContent && bulletinsDocListContent.Content)))
    );

    constructor(
        public dataResolverService: DataResolverService,
        private readonly dashboardContent: DashboardContentService,
        private readonly store: Store,
        private readonly statusService: AccountStatusService,
        private translate: TranslateService,
        private readonly idmService: IdmService,
        private jwtHelper: JwtHelperService,
        private toastService: ToastService
    ) {
        super();
    }

    ngOnInit() {
        this.pageData$ = this.dataResolverService.pageData$;

        this.pageData$.subscribe((pageData) => {
            if (pageData && pageData.Id) {
                this.dashboardContent.addData(pageData);
            }
        });
        if (environment.features.pendo) {
            this.initializePendoData();
        }
    }

    ngAfterViewInit(): void {
        this.dataResolverService.limitedFeatures$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((isLimited) => {
                if (isLimited) {
                    this.toastService.add({
                        bgColor: '#F8F8F8',
                        content: this.translate.instant('CONNECTED_PORTAL.LIMITED_FEATURES_MESSAGE'),
                        id: 'cp-limited-features-message',
                        closeable: true
                    });
                }
            });
    }

    openRecentActivity() {
        this.store.dispatch(new OpenRecentActivity());
    }

    initializePendoData() {
        this.statusService.getUserStatus().pipe(
            switchMap((result: AccountStatusResponse) => {
                this.userID = result.userId;
                this.companyID = result.company.id;
                this.firstName = result.firstName;
                this.lastName = result.lastname;
                this.userEmail = result.email;
                this.companyName = result.company.name;
                this.companyType = result.company.companyType.name;

                switch (environment.envName) {
                    case 'local':
                        this.envtName = this.translate.instant('PENDO.DEV');
                        break;
                    case 'dev':
                        this.envtName = this.translate.instant('PENDO.DEV');
                        break;
                    case 'staging':
                        this.envtName = this.translate.instant('PENDO.STAGING');
                        break;
                    case 'production':
                        this.envtName = this.translate.instant('PENDO.PRODUCTION');
                        break;
                    case 'future':
                        this.envtName = this.translate.instant('PENDO.FUTURE');
                        break;
                    case 'test':
                        this.envtName = this.translate.instant('PENDO.TEST');
                        break;
                    default:
                }

                return this.idmService.getUser(this.userID).pipe(
                    switchMap((res: UserEntity) => {
                        this.creationDate = res.creationDate;

                        return this.statusService.getBearerIdToken().pipe(
                            switchMap((tokenResult) => {
                                this.idToken = tokenResult.bearerIdToken;

                                return of({
                                    result,
                                    res,
                                    tokenResult
                                });
                            })
                        );
                    })
                );
            })
        ).subscribe(() => {
            const decodedToken = this.jwtHelper.decodeToken(this.idToken);
            const idmUserRoles: string[] = decodedToken?.idm_user_roles;

            this.rolesArray = idmUserRoles.filter((role: string) => role.startsWith(this.translate.instant('PENDO.CLAIM')))
                .map((role: string) => role.substring(this.translate.instant('PENDO.CLAIM').length));

            this.brandsArray = idmUserRoles.filter((brand: string) => brand.startsWith(this.translate.instant('PENDO.BRAND')))
                .map((brand: string) => brand.substring(this.translate.instant('PENDO.BRAND').length));

            this.initializePendo();
        });
    }

    initializePendo() {
        this.visitorID = this.envtName === this.translate.instant('PENDO.PRODUCTION')
            ? `${this.userID}`
            : `${this.envtName}-${this.userID}`;

        this.accountID = this.envtName === this.translate.instant('PENDO.PRODUCTION')
            ? `${this.companyID}`
            : `${this.envtName}-${this.companyID}`;

        pendo.initialize({
            visitor: {
                id: this.visitorID,
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.userEmail,
                creationDate: this.creationDate,
                roles: this.rolesArray,
                brands: this.brandsArray
            },

            account: {
                id: this.accountID,
                companyName: this.companyName,
                companyType: this.companyType
            }
        });
    }
}
