import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { getErrorMessage, InputErrorObject } from 'common/utils/createAccountValidators';
import { combineLatest, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { delay, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'common/components/base/base.component';
import { DealerControlService } from 'private/app/services/connected-portal/dealer-control.service';
import { SearchControlStatus, SearchControlStatusData, SearchControlStatusResponse, WorkflowData } from 'private/app/models/add-control.model';
import { Select } from '@ngxs/store';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { BrandFamily } from 'common/models/brand.model';

@Component({
    selector: 'hvac-cp-search-control',
    templateUrl: './search-control.component.html',
    styleUrls: ['./search-control.component.scss']
})
export class SearchControlComponent extends BaseComponent implements OnInit {
    @Input() dealerId: string;
    @Output() onModalClose = new EventEmitter();
    @Output() onWorkflowChange = new EventEmitter<WorkflowData>();
    @Select(AccountStatusState.getUserBrandFamily) userBrandFamily$: Observable<string | null>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchControlErrorMessage$: Observable<any>;
    searchControlForm = new UntypedFormGroup({ searchControl: new UntypedFormControl('', [], [this.searchInputValidator()]) });
    errorMessages$ = this.translate.get('SEARCH_CONTROL_VALIDATION.ERROR_MESSAGES');
    searchInputValue = '';
    isLoading = false;
    disclaimerThermostats$: Observable<string[]>

    constructor(
        private translate: TranslateService,
        private addControlSvc: DealerControlService
    ) {
        super();
    }

    ngOnInit(): void {
        this.searchControlErrorMessage$ = combineLatest([
            this.searchControlForm.controls.searchControl.statusChanges, this.errorMessages$
        ]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([valid, message]) => {
                if (valid) {
                    this.searchInputValue = this.searchControlForm.controls.searchControl.value;
                }

                return getErrorMessage(this.searchControlForm.controls.searchControl.errors, message);
            })
        );
        this.disclaimerThermostats$ = this.userBrandFamily$.pipe(
            distinctUntilChanged(),
            map((brand) => {
                const thermostatDisclaimerData: string[] = brand?.toLowerCase() === BrandFamily.ICP ?
                    this.translate.instant('CONNECTED_PORTAL.ADD_NEW_CONTROL.SEARCH_CONTROL.DISCLAIMER.ICP_THERMOSTATS') :
                    this.translate.instant('CONNECTED_PORTAL.ADD_NEW_CONTROL.SEARCH_CONTROL.DISCLAIMER.THERMOSTATS');

                if (Array.isArray(thermostatDisclaimerData)) {
                    return thermostatDisclaimerData;
                }

                return [] as string[];
            })
        );
    }

    searchControl() {
        this.isLoading = true;
        this.addControlSvc.queryDealerControlStatus(this.dealerId, this.searchInputValue)
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((responseData: SearchControlStatusResponse) => {
                this.isLoading = false;

                if (responseData) {
                    const { data } = responseData;
                    if (data.status) {
                        this.processControlStatus(data);
                    }
                }
            }, this.handleApiError.bind(this));
    }

    private searchInputValidator(): AsyncValidatorFn {
        // eslint-disable-next-line arrow-body-style
        return (control: AbstractControl): Observable<InputErrorObject | null> => {
            return of(control.value).pipe(
                delay(500),
                map(() => {
                    const errorObject: InputErrorObject = {};
                    const controlValue = control.value;
                    if (typeof controlValue !== 'undefined') {
                        if (controlValue.trim() === '') {
                            errorObject.REQUIRED = true;
                        }
                        if (!(controlValue.length === 11 || controlValue.length === 12)) {
                            errorObject.LENGTH = true;
                        }
                        if (Object.keys(errorObject).length !== 0) {
                            return errorObject;
                        }
                    }

                    return null;
                })
            );
        };
    }

    private processControlStatus(data: SearchControlStatusData) {
        switch (data.status) {
            case SearchControlStatus.CONTROL_ALREADY_LINKED_TO_DEALER:
                this.searchControlForm.controls.searchControl.setErrors({ CONTROL_ALREADY_LINKED_TO_DEALER: true });
                break;
            case SearchControlStatus.CONTROL_SERIAL_NOT_VALID:
                this.searchControlForm.controls.searchControl.setErrors({ CONTROL_SERIAL_NOT_VALID: true });
                break;
            case SearchControlStatus.CONTROL_LINKED_TO_ECOBEE_DEALER:
                this.searchControlForm.controls.searchControl.setErrors({ CONTROL_LINKED_TO_ECOBEE_DEALER: true });
                break;
            case SearchControlStatus.CONTROL_FOUND_READY_TO_LINK:
                this.onWorkflowChange.next({
                    status: data.status,
                    customerAddress: data.customerAddress,
                    controlNumber: this.searchInputValue
                });
                break;
            case SearchControlStatus.CONTROL_LINKED_TO_OTHER_DEALER:
                this.onWorkflowChange.next({
                    status: data.status,
                    controlNumber: this.searchInputValue
                });
                break;
            case SearchControlStatus.CONTROL_NOT_REGISTERED:
                this.onWorkflowChange.next({
                    status: data.status,
                    controlNumber: this.searchInputValue
                });
                break;
            default:
                break;
        }
    }

    private handleApiError() {
        this.onModalClose.next();
    }
}
