export interface Counties {
    [key: string]: string | boolean | undefined;
    brandLocatorId: string;
    isAssigned: boolean;
    parentBrandId: string;
    parentBrandName: string;
    countryName: string;
    cityName: string;
    countyName: string;
    isSelected?: boolean;
}

export interface ChildTable {
    isSelected: boolean;
    tableType: string;
}

export interface CountiesResponse {
    [key: string]: string | number | undefined;
    countyId : number;
    id: string;
    code: string;
    name: string;
    stateId: string;
    stateName: string;
    country: string;
    isAssigned: number;
    brandId: number;
    brandName: string;
}

export interface AssignedCounties {
    type: string;
    counties: CountiesResponse[]
}

export interface CountiesResponseData {
    counties: CountiesResponse[];
    hvacCompanyId: number;
    userId: string;
}

export enum CountiesStrings {
    availableCountiesTable = 'availableCountiesTable',
    assignedCountiesTable = 'assignedCountiesTable'
}
