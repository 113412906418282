<section class="hvac-account-admin-header hvac-company-designation-header hvac-create-company-designation-section">
    <hvac-content-header [data]="breadCrumbData"></hvac-content-header>
</section>
<div class="hvac-content-container hvac-create-user-content-container">
    <div class="hvac-account-admin-form-header hvac-account-admin-heading hvac-account-admin-heading-default">
        <h1 class="hvac-h4">
            {{ getCreateUserActiveScreenName() }}
        </h1>
        <a hvacButton="default" size="small" class="hvac-back-button" [routerLink]="['/Admin/Admin-Tools/account-admin.html/', {}]"
            queryParamsHandling="merge" class="hvac-text-link">
            <utc-svg class='hvac-back-arrow' src="assets/icons/arrow.svg"></utc-svg>
            <div class="hvac-back-text">{{ "ACCOUNT_ADMIN.BACK_TO_COMPANY_ADMIN" | translate }}</div>
        </a>
    </div>
    <ng-container *ngIf="userRegistrationStepNumber==='step0_searchUser'">
        <div class="hvac-content-container">
            <form [formGroup]="userRegistrationsSearchForm" class="user-registration-search-form">
                <div class="hvac-row">
                    <div class="hvac-col-md-4 hvac-form-field">
                        <utc-input shadow="light" [inputControl]="userRegistrationsSearchForm.controls.firstName"
                            label="{{ 'ACCOUNT_ADMIN.FIRST_NAME' | translate }}"></utc-input>
                    </div>
                    <div class="hvac-col-md-4 hvac-form-field">
                        <utc-input shadow="light" [inputControl]="userRegistrationsSearchForm.controls.lastName"
                            label="{{ 'ACCOUNT_ADMIN.LAST_NAME' | translate }}"></utc-input>
                    </div>
                    <div class="hvac-col-md-4 hvac-form-field">
                        <utc-input shadow="light" [inputControl]="userRegistrationsSearchForm.controls.userName"
                            label="{{ 'ACCOUNT_ADMIN.USER_ID' | translate }}"></utc-input>
                    </div>
                </div>
                <div class="hvac-row">
                    <div class="hvac-col-md-4 hvac-form-field">
                        <utc-input shadow="light" [inputControl]="userRegistrationsSearchForm.controls.companyName"
                            label="{{ 'ACCOUNT_ADMIN.COMPANY_NAME' | translate }}"></utc-input>
                    </div>
                    <div class="hvac-col-md-4 hvac-form-field">
                        <utc-input type="number" shadow="light" [inputControl]="userRegistrationsSearchForm.controls.companyId"
                            label="{{ 'ACCOUNT_ADMIN.COMPANY_ID' | translate }}"></utc-input>
                    </div>
                    <div class="hvac-col-md-4 hvac-form-field">
                        <hvac-date-picker [lightShadow]="true" [label]="'ACCOUNT_ADMIN.CREATED_DATE' | translate"
                            [id]="'CreatedDate'" [value]="userRegistrationsSearchForm.controls.createdDate.value"
                            [formControl]="userRegistrationsSearchForm.controls.createdDate">
                        </hvac-date-picker>
                    </div>
                </div>
                <div class="hvac-row">
                    <div class="hvac-col-lg-4 hvac-col-md-4 hvac-col-sm-6 hvac-col-xs-6 hvac-form-field">
                        <utc-select [group]="userRegistrationsSearchForm" control="userStatus" shadow="light"
                            [defaultOption]="'ACCOUNT_ADMIN.STATUS' | translate" [options]="userRegistrationStatusOptions">
                        </utc-select>
                    </div>
                </div>
            </form>
            <div class="display-flex-end">
                <button (click)="clearAll()" type="button" class="hvac-button" hvacButton="default">{{
                    'ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.CLEAR_SEARCH_TEXT' | translate }}</button>
                <button (click)="search()" type="button" class="hvac-button" hvacButton="primary">{{
                    'ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.SEARCH_USERS_TEXT' | translate }}</button>
            </div>
            <hvac-user-registrations-results [users]="userRegistrationRequest" [loading]="loading"
                (registrationUserChangePage)="onClickRegisterUserChangePage($event)"></hvac-user-registrations-results>
        </div>
    </ng-container>

    <ng-container *ngIf="userRegistrationStepNumber==='step1_userRegistrationForm'">
        <hvac-user-registrations-details [usersData]="usersData" (registrationUserChangePage)="onClickRegisterUserDetailsChangePage($event)" (userDetails)="registeredUserDetails($event)"></hvac-user-registrations-details>
    </ng-container>

    <ng-container *ngIf="userRegistrationStepNumber==='step2_userRegistrationForm'">
        <hvac-user-roles-details (activeScreenName)="getActiveScreenName($event)" (userDetailsSubmit)="userRegistrationDetailsSubmit($event)" [userDetails]="userDetails" (registrationUserChangePage)="onContinueToStep2Click($event)" [page]="'registerUser'"></hvac-user-roles-details>
    </ng-container>
    <ng-template [ngIf]="apiRequestActive">
        <utc-loading-spinner></utc-loading-spinner>
    </ng-template>
</div>
