import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'common/services/toast.service';
import { DataHeader } from 'private/app/models/account-admin-search';
import { AssignedCounties, CountiesResponse, CountiesStrings } from 'private/app/models/manage-counties.model';
import { AccountAdminExportService } from 'private/app/services/account-admin/account-admin-export.service';
import { AccountAdminExtendedService } from 'private/app/services/account-admin/account-admin-extended.service';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-app-manage-counties-table',
    templateUrl: './app-manage-counties-table.component.html',
    styleUrls: ['./app-manage-counties-table.component.scss']
})
export class AppManageCountiesTableComponent implements OnInit {
    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
    @Input() tableTypeIdentifier: string;
    @Input() hvacCompanyId:number;
    @Input() emptyCountiesList: boolean;
    @Input() countiesArray: CountiesResponse[] = [];
    @Input() loading: boolean;
    @Output() public onSelectAllClick = new EventEmitter();
    @Output() public onSave = new EventEmitter();
    @Output() public selectedCountyArray = new EventEmitter<AssignedCounties>()
    public countiesDisplayArray$ = new BehaviorSubject<CountiesResponse[]>([]);
    public isAllSelected: boolean = false;
    public searchControl: UntypedFormGroup;
    public filterControlGroup: UntypedFormGroup;
    public currentPage: number = 1;
    public pageSize: number = 10;
    public totalPages: number;
    public isLoading: boolean;
    public bannerType: string = 'ManageCounties';
    public availableCountiesTable = CountiesStrings.availableCountiesTable;
    public assignedCountiesTable = CountiesStrings.assignedCountiesTable;

    dataHeaders: DataHeader[] = [
        {
            title: this.translate.instant('ACCOUNT_ADMIN.MANAGE_COUNTIES.BRAND'),
            value: 'brandName',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.MANAGE_COUNTIES.COUNTRY'),
            value: 'country',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.MANAGE_COUNTIES.STATE'),
            value: 'stateName',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.MANAGE_COUNTIES.COUNTY'),
            value: 'name',
            order: null
        }
    ];

    private ngOnDestroy$ = new Subject();
    private countiesArray$ = new BehaviorSubject<CountiesResponse[]>([]);
    private filteredData$ = new BehaviorSubject<CountiesResponse[]>([]);
    private searchKeyUpSubject$ = new BehaviorSubject<{event: string, key: string}>({
        event: '',
        key: ''
    });

    private filterKeyValues = this.dataHeaders.map(({ value }) => ({
        value,
        query: ''
    }));

    private filterSubject$ = new BehaviorSubject(this.filterKeyValues);
    private filter$ = this.filterSubject$.asObservable();
    private dataSource$ = combineLatest([this.filter$, this.countiesArray$]).pipe(
        map(([filter, dataSource]) => dataSource.filter((item) => filter.every((value) => new RegExp(String(value.query).toLowerCase()).test(
            String(item[value.value]).toLowerCase()
        ))))
    );

    constructor(
        @Inject(AccountAdminExportService) public accountAdminExportService: AccountAdminExportService,
        private translate: TranslateService,
        private accountAdminExtendedService: AccountAdminExtendedService,
        private readonly toastService: ToastService

    ) { }

    ngOnInit(): void {
        this.searchControl = new UntypedFormGroup({ search: new UntypedFormControl('', null) });
        this.filterControlGroup = new UntypedFormGroup({
            brandControl: new UntypedFormControl(''),
            countryControl: new UntypedFormControl(''),
            stateControl: new UntypedFormControl(''),
            countyControl: new UntypedFormControl('')
        });

        this.searchKeyUpSubject$.pipe(
            takeUntil(this.ngOnDestroy$),
            debounceTime(100)
        ).subscribe((searchQuery) => {
            this.filterKeyValues.forEach((filterKeyValue) => {
                if (filterKeyValue.value === searchQuery.event) {
                    filterKeyValue.query = searchQuery.key;
                }
            });
            this.filterSubject$.next(this.filterKeyValues);
        });

        this.filterControlGroup.controls.brandControl.valueChanges.subscribe((value: string) => {
            this.inputChange('brandName', value);
        });
        this.filterControlGroup.controls.countryControl.valueChanges.subscribe((value: string) => {
            this.inputChange('country', value);
        });
        this.filterControlGroup.controls.stateControl.valueChanges.subscribe((value: string) => {
            this.inputChange('stateName', value);
        });
        this.filterControlGroup.controls.countyControl.valueChanges.subscribe((value: string) => {
            this.inputChange('name', value);
        });

        this.dataSource$.subscribe((data) => {
            this.totalPages = Math.ceil(data.length / this.pageSize);
            this.filteredData$.next(data);
            this.countiesDisplayArray$.next(data.slice(0, this.pageSize));
        });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.countiesArray['currentValue']) {
            this.resetForm();
            this.countiesArray = changes.countiesArray['currentValue'];
            this.totalPages = Math.ceil(this.countiesArray.length / this.pageSize);
            this.handlePageChange(1);
            this.countiesArray$.next(this.countiesArray);
            this.countiesDisplayArray$.next(
                this.countiesArray$.value.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
            );
        }
    }

    resetForm() {
        this.filterControlGroup?.controls.brandControl.setValue('');
        this.filterControlGroup?.controls.countryControl.setValue('');
        this.filterControlGroup?.controls.stateControl.setValue('');
        this.filterControlGroup?.controls.countyControl.setValue('');
        this.filterKeyValues = this.dataHeaders.map(({ value }) => ({
            value,
            query: ''
        }));
    }

    handlePageChange(page: number) {
        this.currentPage = parseInt(page.toString(), 10);
        this.countiesDisplayArray$.next(
            this.filteredData$.value.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
        );
    }

    sort(item: DataHeader) {
        let sortedArr: CountiesResponse[] = [];
        const currentSort: Partial<DataHeader> = this.dataHeaders.find((header: DataHeader) => header.value === item.value) || {};

        if (!currentSort.order) {
            currentSort.order = 'asc';
        }
        else if (currentSort.order === 'asc') {
            currentSort.order = 'desc';
        }
        else {
            currentSort.order = null;
        }
        if (!currentSort.order) {
            this.countiesDisplayArray$.next(
                this.countiesArray.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
            );

            return;
        }
        sortedArr = this.countiesArray.slice().sort((a, b) => {
            const valueA = a[item.value] || '';
            const valueB = b[item.value] || '';

            return valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase());
        });

        if (currentSort.order === 'desc') {
            sortedArr.reverse();
        }
        this.countiesDisplayArray$.next(
            sortedArr.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
        );
    }

    selectAllCounties() {
        this.isAllSelected = !this.isAllSelected;
        if (this.isAllSelected) {
            this.filteredData$.value.map((data) => {
                data.isAssigned = 1;

                return data;
            });

            this.selectedCountyArray.next(
                {
                    type: this.tableTypeIdentifier,
                    counties: this.countiesArray$.value.filter((county) => county.isAssigned)
                }
            );

            return;
        }

        this.filteredData$.value.map((data) => {
            data.isAssigned = 0;

            return data;
        });

        this.selectedCountyArray.next(
            {
                type: this.tableTypeIdentifier,
                counties: this.countiesArray$.value.filter((county) => county.isAssigned)
            }
        );
    }

    selectCounty(countyObj: CountiesResponse, event: Event) {
        this.countiesArray$.value.map((data) => {
            if (data.id === countyObj.id) {
                const assigned = this.tableTypeIdentifier === this.availableCountiesTable ? (event.target as HTMLInputElement).checked : !(event.target as HTMLInputElement).checked;
                data.isAssigned = Number(assigned);
            }

            return data;
        });

        this.selectedCountyArray.next(
            {
                type: this.tableTypeIdentifier,
                counties: this.countiesArray$.value.filter((county) => county.isAssigned)
            }
        );
    }

    isButtonDisabled() {
        if (this.tableTypeIdentifier === this.assignedCountiesTable) {
            return this.countiesArray$.value.some((county) => county.isAssigned === 0);
        }

        return this.countiesArray$.value.some((county) => county.isAssigned === 1);
    }

    assignSelectedCodes() {
        this.onSave.next();
    }

    exportCounties() {
        this.isLoading = true;
        this.accountAdminExtendedService.exportCounties(this.hvacCompanyId).subscribe((response: HttpResponse<string>) => {
            this.isLoading = false;
            this.accountAdminExportService.doExport(response);
        },
        (err) => {
            if (err) {
                this.isLoading = false;
                this.toastService.add({
                    content: this.translate.instant('ACCOUNT_ADMIN.ASSOCIATION_EXPORT_ERROR'),
                    theme: 'error',
                    id: this.bannerType,
                    closeable: true,
                    autoClose: true
                });
            }
        });
    }

    isChecked(input: number) {
        return Boolean(input);
    }

    private inputChange(event: string, key: string) {
        this.searchKeyUpSubject$.next({
            event,
            key
        });
    }
}
