import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'common/components/base/base.component';
import { CharSymbol } from 'common/enums/char-symbol';
import { SystemType } from 'private/app/models/connected-product.model';
import { EltActivityResponse, SystemMode, WallControlStatus } from 'private/app/models/wall-control.model';
import { DataSharingOptions, DataSharingService } from 'private/app/services/connected-portal/data-sharing.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { LOADING_STATE, TempUnitFormat } from 'private/app/views/connected-portal/constants';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, map, shareReplay, startWith } from 'rxjs/operators';
import { SystemConditionsService } from '../../../../components/system-conditions/system-conditions.service';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { AsyncPipe, NgIf } from '@angular/common';
import { WallControlEltProgramSettingsComponent } from 'private/app/views/connected-portal/dealer/components/system-conditions/components/wall-control-elt-program-settings/wall-control-elt-program-settings.component';
import { EmptyToCharPipe } from 'private/app/views/connected-portal/pipes/empty-to-char.pipe';
import { TranslateModule } from '@ngx-translate/core';

interface SystemDetails {
    outdoorTemp: string;
    indoorTemp?: string;
    indoorHumidity?: string;
    relativeHumidity?: string;
    coolTo?: string;
    heatTo?: string;
}

interface PageData {
    systemDetails: SystemDetails;
    programSettings: EltActivityResponse;
    systemMode: SystemMode;
}
@Component({
    selector: 'hvac-carrier-elt-system-conditions',
    templateUrl: './carrier-elt-system-conditions.component.html',
    styleUrls: ['./carrier-elt-system-conditions.component.scss'],
    providers: [SystemConditionsService],
    standalone: true,
    imports: [CommonComponentsModule, NgIf, AsyncPipe, WallControlEltProgramSettingsComponent, EmptyToCharPipe, TranslateModule]
})

export class CarrierEltSystemConditionsComponent extends BaseComponent implements OnInit {
  @Input() systemType: SystemType;
  @Input() serialNo: string;
  @Input() dealerId: string;
  @Input() tempUnitFormat: TempUnitFormat;
  @Input() eltWallControlStatus: WallControlStatus | null;
  @Input() dataSharingPermissions: DataSharingOptions;

  public readonly SystemType = SystemType;
  public readonly CharSymbol = CharSymbol;
  public readonly TempUnitFormat = TempUnitFormat;

  public isLoading$: Observable<boolean>;
  public pageData$: Observable<PageData>;
  public isAnySystemConditionOutOfRange = false;
  public programSettingDetails$: Observable<EltActivityResponse>;

  constructor(
      private productService: ProductService,
      private dataSharingService: DataSharingService,
      private systemConditionsService: SystemConditionsService
  ) {
      super();
  }

  ngOnInit(): void {
      const systemDetails$ = this.productService.queryWallControlBySerialNo(this.serialNo, this.dealerId, this.systemType)
          .pipe(
              map((wallControlDetailsRes) => {
                  const { indoorHumidity, zones, outdoorTemp } = wallControlDetailsRes.data;
                  const zone = zones?.[0];

                  return {
                      indoorTemp: zone.indoorTemp,
                      indoorHumidity: zone.relativeHumidity ?? indoorHumidity,
                      outdoorTemp,
                      coolTo: zone.coolTo,
                      heatTo: zone.heatTo
                  };
              })
          );

      const dataSharingPermissions$ = this.dataSharingService.dataPoints$
          .pipe(shareReplay());

      const wallControlDetails$ = this.productService.queryWallControlBySerialNo(this.serialNo, this.dealerId, this.systemType)
          .pipe(
              map((wallControlDetailsRes) => this.systemConditionsService.getCarrierEltWallControlDetails(wallControlDetailsRes.data)),
              shareReplay()
          );

      const systemMode$ = combineLatest([dataSharingPermissions$, wallControlDetails$]).pipe(
          map(([dataSharingPermissions, wallControlDetails]): SystemMode => {
              const { fan, cooling, heating, userSetting } = wallControlDetails;

              return {
                  isSystemModeEnabled: dataSharingPermissions.systemMode,
                  isEquipmentActivityEnabled: dataSharingPermissions.equipmentActivity,
                  userSetting,
                  fan,
                  cooling,
                  heating
              };
          }),
          shareReplay()
      );

      const programSettings$ = this.productService.queryCarrierELTComfortProfile(this.serialNo)
          .pipe(
              map((eltActivityRes: EltActivityResponse) => eltActivityRes)
          );

      this.pageData$ = combineLatest([
          systemDetails$,
          programSettings$,
          systemMode$
      ]).pipe(
          map(([
              systemDetails,
              programSettings,
              systemMode
          ]): PageData => ({
              systemDetails,
              programSettings,
              systemMode
          }))
      );

      this.isLoading$ = this.pageData$.pipe(
          startWith(LOADING_STATE),
          map((data) => data === LOADING_STATE),
          catchError(() => of(false))
      );
  }
}
