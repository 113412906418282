/* eslint-disable max-lines */
import { EnvName } from 'common/app-constants';
import { ValueRange } from 'common/models/value-range';
import { SystemType } from 'private/app/models/connected-product.model';
import { ConnectedPortalNavLink } from './components/connected-portal-navigation/connected-portal-navigation.component';

export const CONNECTED_PORTAL_BASE_NAV_LINKS: Record<string, ConnectedPortalNavLink> = {
    settings: {
        label: 'CONNECTED_PORTAL.NAV_LABELS.SETTINGS',
        route: '/connected-portal/settings',
        comingSoon: true
    },
    resources: {
        label: 'CONNECTED_PORTAL.NAV_LABELS.RESOURCES',
        route: '/connected-portal/resources',
        comingSoon: true
    },
    opportunities: {
        label: 'CONNECTED_PORTAL.NAV_LABELS.OPPORTUNITIES',
        route: '/connected-portal/opportunities',
        comingSoon: true
    },
    updates: {
        label: 'CONNECTED_PORTAL.NAV_LABELS.UPDATES',
        route: '/connected-portal/updates',
        comingSoon: true
    },
    faq: {
        label: 'CONNECTED_PORTAL.NAV_LABELS.FAQ',
        route: '/connected-portal/faq',
        comingSoon: true
    }
};
export const UNDOCUMENTED_MODELS = [
    'SYST0101CW'
];
export const MAX_LIST_FIELD_LENGTH = 100;
export const PRODUCT_ALERTS_PAGE_SIZE = 3;
export const SEARCH_RESULTS_PAGE_SIZE = 10;
export const RECENT_ACTIVITY_BANNER_MAX_AGE_DAYS = 90;
export const SKIP_BRAND_PARAMS = true;
export const CONFIGURATION_RELOAD_DELAY = 3000;
export const ECOBEE_CONFIGURATION_RELOAD_DELAY = 0;
export const SYSTEM_DIAGNOSTIC_TEST_TIME = 20 * 60 * 1000;
export const SYSTEM_DIAGNOSTIC_TOAST_TIME = 4000;
export const QUERY_PARAM_DEBOUNCE_INTERVAL = 500;
export const DEFAULT_SYSTEM_TYPE = SystemType.INFINITY;
export const REPORT_MAX_YEAR_SELECTION = 3;
export const CHART_COLORS = {
    gradientTop: 'rgba(0, 157, 163, 0.85)',
    gradientBottom: 'rgba(0, 157, 163, 0)',
    border: 'rgba(0, 157, 163, 0.1)',
    pointBackground: '#82CFD2',
    pointBorder: '#FFF'
};

export const ECOBEE_PRODUCT_IMAGE_MAP: { [key: string]: string } = { nikeSmart: 'https://images.carriercms.com/image/upload/v1667335001/carrier/residential-hvac/products/thermostats/ecobee3_lite_Carrier_image.png' };

export const ECOBEE_UNKNOWN_VALUES = [
    'HVAC_UNKNOWN',
    'PRGM_MODE_UNKNOWN',
    'FAN_MODE_UNKNOWN'
];

export const ECOBEE_TRANSLATION_VALUE_KEYS = [
    ...ECOBEE_UNKNOWN_VALUES,
    'HVAC_AUTO',
    'HVAC_HEAT',
    'HVAC_COOL',
    'HVAC_OFF',
    'HVAC_AUX_HEAT_ONLY',
    'PRGM_MODE_HOLD',
    'PRGM_MODE_RUN',
    'FAN_MODE_AUTO',
    'FAN_MODE_ON',
    'FAN_MODE_OFF'
];

export const ECOBEE_PLC_MODELS = [
    'nikeSmart',
    'aresSmart',
    'EB-STATE6ICR-01',
    'EB-STATE6IBR-01',
    'EB-STATE3LTICR-01',
    'EB-STATE3LTIBR-01',
    'EB-STATE3LTICB-01',
    'EB-STATE3LTIBB-01'
];

export enum ApiResponseCode {
    SUCCESS = 200,
    NO_CONTENT = 204,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    NOT_ALLOWED = 405,
    SERVER_ERROR = 500,
    BAD_GATEWAY = 502,
    SERVICE_UNAVAILABLE = 503,
    SERVICE_TIMEOUT = 504
}

export enum ApiResponseMessage {
    SERVER_ERROR = 'Server Error',
    NOT_FOUND = 'Resource not found',
    SERVICE_UNAVAILABLE = 'Service unavailable',
    PENDING_DEALER_ACTIVATION = 'No Data'
}

export const REALTIME_API_CUSTOM_DOMAIN_ENVS = [EnvName.Staging, EnvName.Production];

export const MQTT_ACTIVATION_REFRESH_INTERVAL = 1000 * 60;
export const MQTT_ACTIVATION_TIMEOUT = 1000 * 60 * 10;
export const MAX_ACTIVATED_MQTT_SYSTEMS = 3;

export enum ApiErrorName {
    DiagnosticsTestCompletionDatetime = 'diagnostics_test_completion_datetime',
    DeviceDisconnectedError = 'Device is Disconnected',
    DeviceCommandUnacknowledgedError = 'Unacknowledged',
    DeviceSettingsInvalid = 'tstat_user_settings_fail_time',
    DisconnectUserEnded = 'DISCONNECT_USER_ENDED',
    DeltaConfigParams = 'DELTA_CONFIG_PARAMS',
    ConfigParams = 'CONFIG_PARAMS'
}

export enum ApiErrorValue {
    UpdateError = 'UPDATE_ERROR'
}

export const ECOBEE_CONFIG_RANGE_PROPERTIES = [
    'compressorOutdoorMinTemp',
    'deadband',
    'auxOutdoorMaxTemp',
    'fanRuntimeMin',
    'acOvercoolMax'
];

export const ECOBEE_CONFIG_READ_ONLY_PROPERTY_RANGES: Record<string, ValueRange> = {
    compressorOutdoorMinTemp: {
        min: 0,
        max: 65
    },
    auxOutdoorMaxTemp: {
        min: 0,
        max: 100
    },
    heatToSetPoint: {
        min: 45,
        max: 120
    },
    coolToSetPoint: {
        min: -10,
        max: 120
    }
};

export const DEVICE_TEMPERATURE_PROPERTIES = [
    'auxiliaryHeatLockoutTemp',
    'auxOutdoorMaxTemp',
    'compressorOutdoorMinTemp',
    'coolingLockoutTemp',
    'coolSetpoint',
    'coolToSetPoint',
    'deadband',
    'deltaT',
    'heatingLockoutTemp',
    'heatSetpoint',
    'heatToSetPoint',
    'leavingAirTemp',
    'outdoorAirTemperature',
    'outdoorTempOffset',
    'temperatureOffset',
    // Carrier ELT
    'overcoolSetpoint',
    'auxHeatLockoutTemp',
    'coolDeltaStage1',
    'coolDeltaStage2',
    'heatDeltaAuxStage1',
    'heatDeltaAuxStage2',
    'heatDeltaStage1',
    'heatDeltaStage2',
    'ventilationMaxIOTempDelta',
    'minCoolSetpoint',
    'maxHeatSetpoint',
    // INFINITY
    'liquidServiceValveSubcooling',
    'estimatedServiceValveSubcooling',
    'liquidLineTemperature'
];
export const DEVICE_PERCENT_PROPERTIES = [
    'rhHumidificationSetpoint',
    'ventilationPercentOnTime',
    'rhDehumidificationSetpoint',
    'drymodeSetpoint'
];
export const UI_TRANSLATION_CONFIG = [
    'userModeSelection',
    'auxHeatLockoutEnableDisable',
    'compressorLockoutEnableDisable',
    'rhHumidifierControlOption',
    'rhDehumidifierControlOption',
    'dryMode'
];
export const UI_TRANSLATION_VALUES:{
    [key: string]: {
        [key: string]: string;
    };
} = {
    userModeSelection: {
        cool: 'Cool',
        heat: 'Heat',
        // eslint-disable-next-line camelcase
        emergency_heat: 'Emergency Heat',
        auto: 'Auto',
        off: 'Off'
    },
    auxHeatLockoutEnableDisable: {
        true: 'On',
        false: 'Off'
    },
    compressorLockoutEnableDisable: {
        true: 'On',
        false: 'Off'
    },
    rhHumidifierControlOption: {
        true: 'On',
        false: 'Off'
    },
    rhDehumidifierControlOption: {
        true: 'On',
        false: 'Off'
    },
    dryMode: {
        true: 'On',
        false: 'Off'
    }

};
export enum ApiResponseState {
    Loading = 'LOADING',
    Success = 'SUCCESS',
    Error = 'ERROR'
}

export const COMPRESSOR_OUTDOOR_MIN_TEMP_AUX_OUTDOOR_MAX_TEMP_OFFSET = 5;
export const COMPRESSOR_OUTDOOR_MIN_TEMP_DISABLED_VALUE = 'disabled';
export const COMPRESSOR_OUTDOOR_MIN_TEMP_MIN_VALUE = 0;
export const INPUT_AUTO_UPDATE_DELAY = 500;


export const MISSING_ROUTE_PARAMS_ERROR = 'Missing Route Params';
export const MISSING_PRODUCT_DATA = 'Missing Product Data';
export const MISSING_REQUIRED_PROPERTY = 'Missing Required Property';
export const MISSING_API_RESPONSE_DATA = 'Missing API Response Data';

export enum DataError {
    MissingRouteParamsError = 'Missing Route Params',
    MissingProductData = 'Missing Product Data',
    MissingRequiredProperty = 'Missing Required Property',
    MissingApiResponseData = 'Missing API Response Data'
}

export const ERROR_RETRY_MAX = 2;
export const DATA_REFRESH_DISCONNECT_DELAY = 1000 * 60;
export const LOADING_STATE = 'LOADING';
export const ECOBEE_MAX_RUNTIME_REPORT_DAY_RANGE = 3;
export const ECOBEE_NI_MAX_RUNTIME_REPORT_DAY_RANGE = 2;
export const RUNTIME_REPORT_END_DATE_DIFF_MINUTE_MAX = 5;
export const REMOVE_CONTROL_TOAST_TIMEOUT = 1000 * 5;

export enum AdvancedSearchDataSharingOptions {
    VIEW_STATUS = 'viewStatus',
    VIEW_CONFIG = 'viewConfig',
    EDIT_CONFIG = 'editConfig'
}

export const CONNECTED_PORTAL_ADV_SEARCH_SUPPORTED_COUNTRIES = [
    'US',
    'CA'
];

export const ESN_MODEL_NUMBERS = [
    'TC-WHS01',
    'T2-WHS01',
    'TP-WEM01',
    'T6-WEM01',
    'SYSTXCCITW01-A',
    'SYSTXCCITC01-A',
    'SYSTXBBECW01-A',
    'SYSTXBBECC01-A',
    'TSTWRH01',
    'TSTWHA01',
    'SmartSi',
    '3-Series',
    '3-Lite-Series',
    '4-Series',
    'SYSTXCCITC01-B',
    'SYSTXCC',
    'SYSTXCCITW01',
    'SYSTXCCITC01',
    'SYSTXBBECW01',
    'SYSTXBBECC01-B',
    'SYSTXBB',
    'SYSTXBBECC01',
    'SYSTXBBECF01-B',
    'SYSTXCCICF01-B',
    'SYSTXCCWIC01-B',
    'SYSTXCCWIF01-B',
    'SYSTXBBWEC01-B',
    'SYSTXBBWEF01-B',
    'SYST0101CW',
    'TP-WEM01-A',
    'T6-WEM01-A',
    'EB-STATE5CB-01',
    'EB-STATE5CR-01',
    'EB-STATE5BB-01',
    'EB-STATE5BR-01',
    'EB-STATE6ICR-01',
    'EB-STATE6IBR-01',
    'EB-STATE3LTCB-01',
    'EB-STATE3LTCR-01',
    'EB-STATE3LTBB-01',
    'EB-STATE3LTBR-01',
    'EB-STATE3LTICR-01',
    'EB-STATE3LTIBR-01',
    'EB-STATE3LTICB-01',
    'EB-STATE3LTIBB-01',
    'EB-STATE5P-01',
    'SYSTXCCITC01-C',
    'SYSTXBBECC01-C',
    'SYSTXCCWIC01-C',
    'SYSTXBBWEC01-C',
    'EB-STATE6CR-01',
    'EB-STATE6BR-01',
    'EB-STATE6LP-01',
    'EB-STATE6P-01',
    'TSTATCCEWF-01',
    'TSTATBBEWF-01',
    'TSTATIIEWF-01'
];

export enum ToastOutlet {
    DealerAlertsToast = 'dealerAlertsToast'
}

export const FAD_REPORT_MAX_MONTH_SELECTION = 5;

export const SYSYEM_DIAGNOSTICS_OUT_OF_RANGE_PARAMS = {
    fanCoilDeltaT: {
        outOfRangeLowerMin: 0,
        outOfRangeLowerMax: 14,
        outOfRangeUpperMin: 91,
        outOfRangeUpperMax: 105,
        iduDisplayOrder: 1
    },
    furnaceDeltaT: {
        outOfRangeLowerMin: 0,
        outOfRangeLowerMax: 14,
        outOfRangeUpperMin: 91,
        outOfRangeUpperMax: 105,
        iduDisplayOrder: 1
    },
    fanCoilBlowerRpm: {
        outOfRangeLowerMin: 0,
        outOfRangeLowerMax: 299,
        outOfRangeUpperMin: 1401,
        outOfRangeUpperMax: 1620,
        iduDisplayOrder: 2
    },
    furnaceBlowerRpm: {
        outOfRangeLowerMin: 0,
        outOfRangeLowerMax: 299,
        outOfRangeUpperMin: 2001,
        outOfRangeUpperMax: 2340,
        iduDisplayOrder: 2
    },
    returnAirTemperature: {
        outOfRangeLowerMin: 49,
        outOfRangeLowerMax: 54,
        outOfRangeUpperMin: 86,
        outOfRangeUpperMax: 91,
        iduDisplayOrder: 3
    },
    supplyAirTemperature: {
        outOfRangeLowerMin: 9,
        outOfRangeLowerMax: 39,
        outOfRangeUpperMin: 196,
        outOfRangeUpperMax: 226,
        iduDisplayOrder: 4
    }
};

export const SYSTEM_DIAGNOSTICS_ODU_ADDITIONAL_PARAMS = {
    superHeat: {
        oduCategory: 'suction',
        oduDisplayOrder: 3
    },
    suctionPressure: {
        oduCategory: 'suction',
        oduDisplayOrder: 1
    },
    suctionTemperature: {
        oduCategory: 'suction',
        oduDisplayOrder: 2
    },
    liquidLinePressure: {
        oduCategory: 'liquid',
        oduDisplayOrder: 1
    },
    liquidLineTemperature: {
        oduCategory: 'liquid',
        oduDisplayOrder: 2
    },
    subCooling: {
        oduCategory: 'liquid',
        oduDisplayOrder: 3
    },
    outdoorTemperature: { oduCategory: 'airTemperature' }
};

export const ENGINEERING_GRAPH_DEFAULT_DAY_RANGE = 3;
export const ENGINEERING_GRAPH_MAX_DATE_TIME_DELTA = 7 * 24 * 60 * 60 * 1000;
export const ENGINEERING_GRAPH_RELAY_PROPS = ['Y1', 'Y2', 'W1', 'W2', 'C', 'G', 'OB', 'RC'];
export const ENGINEERING_GRAPH_PARAMETER_PROPS = [
    'CSP',
    'HSP',
    'IT',
    'OT',
    'IH',
    'OH',
    'RPM',
    'deltaT',
    'LLP',
    'LLT',
    'OAT',
    'RAT',
    'SC',
    'SLP',
    'SLT',
    'SH',
    'SAT'
];

export const ENGINEERING_GRAPH_TEMP_PROPS = ['IT', 'OT', 'OAT', 'RAT', 'SC', 'SLT', 'SH', 'SAT', 'deltaT', 'HSP', 'CSP', 'LLT'];
export const ENGINEERING_GRAPH_PERCENT_PROPS = ['IH', 'OH'];

export const ENGINEERING_GRAPH_SELECTIONS = {
    relays: ['Y1', 'Y2', 'W1', 'W2', 'C', 'G', 'OB', 'RC'],
    parameters: ['OAT', 'RAT', 'SAT']
};

export const CARRIER_ELT_CONFIG_PROP_CATEGORY_MAP = {
    systemConfiguration: [
        'systemType',
        'subsystemType',
        'accessoryOutput',
        'fanType'
    ],
    basic: [
        'userModeSelection',
        'coolToSetPoint',
        'heatToSetPoint'
    ],
    stage: [
        'coolDeltaStage1',
        'coolDeltaStage2',
        'heatDeltaAuxStage1',
        'heatDeltaAuxStage2',
        'heatDeltaStage1',
        'heatDeltaStage2'
    ],
    cycle: [
        'heatingCycles',
        'coolingCycles'
    ],
    lockoutOffset: [
        'compressorLockoutEnableDisable',
        'compressorOutdoorMinTemp',
        'auxHeatLockoutEnableDisable',
        'auxHeatLockoutTemp'
    ],
    minMax: [
        'deadband',
        'maxHeatSetpoint',
        'minCoolSetpoint'
    ],
    fan: [
        'fanPurgeCool',
        'fanPurgeHeat'
    ],
    accessory: [
        'rhHumidificationSetpoint',
        'rhHumidifierControlOption',
        'rhDehumidificationSetpoint',
        'rhDehumidifierControlOption',
        'ventilationMaxIOTempDelta',
        'ventilationPercentOnTime'
    ],
    overcoolToDehumidify: [
        'dryMode',
        'overcoolSetpoint',
        'drymodeSetpoint'
    ]
};

export enum TempUnitFormat {
    Fahrenheit = 'F',
    Celsius = 'C'
}

export const CARRIER_ELT_STATUS_PROPERTIES_DISPLAY_MAP = [
    'dateTime',
    'dayBrightness',
    'displayLock',
    'isRuntimeReportEnabled',
    'nightModeBrightness',
    'softwareUpdateAvailable',
    'stageStatus',
    'tempUnitFormat',
    'zipCode'
];

export const CARRIER_ECOBEE_SWIMLANE_GRAPH_RELAY_PROPS = [
    'fan',
    'coolingStage1',
    'coolingStage2',
    'heatingStage1',
    'heatingStage2',
    'hpHeatingStage1',
    'hpHeatingStage2',
    'auxHeatingStage1',
    'auxHeatingStage2'
];

export const FALLBACK_HVPID_FOR_UNASSIGNED_DEALER = '9';

export const DEVICE_PRESSURE_PROPERTIES = [
    'dischargePressure',
    'liquidLinePressure',
    'liquidServiceValvePressure',
    'staticPressure',
    'suctionPressure'
];

export const SYSTEM_DIAGNOSTICS_PARAMS_WITH_DECIMAL_POINT = ['superHeat', 'subCooling'];

export const SYSTEM_TYPES_FOR_DEMAND_RESPONSE = [SystemType.CARRIER_ELT, SystemType.INFINITY];

