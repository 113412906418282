import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'common/environments/environment';
import { BaseResponse } from 'common/models/account-status';
import { ApiOptions, ApiOptionsService } from 'common/services/api-options/api-options.service';
import { CreateUserDetails, UpdatedUserRelationshipsDetails, UserAuthorizedFeatures, UserAuthorizedRoles, UserDetailsEntity, UserRegistrationDetails, UserRegistrationRequestPayload, UserRegistrationRequestsResponse, UserRelationshipsDetails } from 'private/app/models/user.model';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserApiService {
    constructor(private readonly httpClient: HttpClient, private readonly apiOptions: ApiOptionsService) {}

    getUserDetailsById(userId: string):Observable<UserDetailsEntity[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<UserDetailsEntity[]>(`${environment.api.profileDetails}?userId=${userId}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    getRegistrationUserDetailsById(requestId: string):Observable<UserRegistrationDetails> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<UserRegistrationDetails>(`${environment.api.accountAdminUserRegistrationDetails}?requestId=${requestId}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    getUserAuthorizedRoles(companyId: string):Observable<UserAuthorizedRoles[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<UserAuthorizedRoles[]>(`${environment.api.accountAdminUserAuthorizedRoles}?hvacCompanyId=${companyId}&contactOnly=false`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    getUserAuthorizedFeatures(companyId: string):Observable<UserAuthorizedFeatures[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<UserAuthorizedFeatures[]>(`${environment.api.accountAdminUserAuthorizedFeatures}?hvacCompanyId=${companyId}&contactOnly=false`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    updateUserProfileDetails(user: UserDetailsEntity): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.put<BaseResponse>(environment.api.profileDetails, user, {
                ...options,
                responseType: 'json'
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    updateUserRegistrationDetails(user: UserRegistrationDetails): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<BaseResponse>(environment.api.accountAdminUserRegistrationDetails, user, {
                ...options,
                responseType: 'json'
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    editUserRelationshipsDetails(userId: string): Observable<UserRelationshipsDetails> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<UserRelationshipsDetails>(`${environment.api.accountAdminUserRelationshipsDetails}?userId=${userId}`, {
                ...options,
                responseType: 'json'
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    updateUserRelationshipsDetails(updatedUserRelationshipData: UpdatedUserRelationshipsDetails): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<BaseResponse>(`${environment.api.accountAdminUserRelationshipsDetails}`, updatedUserRelationshipData, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    createOrRegisterUser(user: CreateUserDetails): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<BaseResponse>(environment.api.accountAdminUserCreateRegisterDetails, user, {
                ...options,
                responseType: 'json'
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    deactivateUser(userId: string, user: UserDetailsEntity): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.put<BaseResponse>(`${environment.api.deactivateUserApi}?userId=${userId}`, user, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    reactivateUser(userId: string, user: UserDetailsEntity): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.put<BaseResponse>(`${environment.api.reactivateUserApi}?userId=${userId}`, user, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    userRegistrationRequest(filters: UserRegistrationRequestPayload): Observable<UserRegistrationRequestsResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<UserRegistrationRequestsResponse>(environment.api.accountAdminUserRegistrationRequestDetails, filters, {
                ...options,
                responseType: 'json'
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    syncUserToServicesApi(serviceName: string, user: UserDetailsEntity): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<BaseResponse>(`${environment.api.syncUserToServicesApi}?userId=${user.userID}&service=${serviceName}`, user, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }
}
