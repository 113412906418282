import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'common/components/base/base.component';
import { CharSymbol } from 'common/enums/char-symbol';
import { SystemType } from 'private/app/models/connected-product.model';
import { EcobeeNISystemConfig, SystemConditionsParamsConfig } from 'private/app/models/wall-control.model';
import { DataSharingOptions, DataSharingService } from 'private/app/services/connected-portal/data-sharing.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { LOADING_STATE } from 'private/app/views/connected-portal/constants';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith, takeUntil } from 'rxjs/operators';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ConnectedPortalModule } from 'private/app/views/connected-portal/connected-portal.module';
import { EmptyToCharPipe } from 'private/app/views/connected-portal/pipes/empty-to-char.pipe';

@Component({
    selector: 'hvac-ecobee-ni-system-conditions',
    templateUrl: './ecobee-ni-system-conditions.component.html',
    styleUrls: ['./ecobee-ni-system-conditions.component.scss'],
    standalone: true,
    imports: [
        CommonComponentsModule,
        NgIf,
        AsyncPipe,
        TranslateModule,
        ConnectedPortalModule,
        EmptyToCharPipe
    ]
})
export class EcobeeNiSystemConditionsComponent extends BaseComponent implements OnInit {
    @Input() systemType: SystemType;
    @Input() serialNo: string;
    @Input() dealerId: string;
    @Input() esn: string;

    public readonly SystemType = SystemType;
    public readonly CharSymbol = CharSymbol;

    public isLoading$: Observable<boolean>;
    public wallControlDetails$: Observable<EcobeeNISystemConfig>;
    public dataSharing: DataSharingOptions;
    public isLoading = false;
    public systemConditionsConfig: SystemConditionsParamsConfig;
    public isAnySystemConditionOutOfRange = false;

    constructor(
        private productService: ProductService,
        private dataSharingService: DataSharingService
    ) {
        super();
    }

    ngOnInit(): void {
        this.dataSharingService.dataPoints$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((dataPoints) => {
                this.dataSharing = dataPoints;
            });

        this.wallControlDetails$ = this.productService.queryWallControlBySerialNo(this.esn, this.dealerId, this.systemType)
            .pipe(
                map((wallControlDetailsRes) => {
                    const { indoorHumidity, zones } = wallControlDetailsRes.data;
                    const zone = zones?.[0];

                    return {
                        indoorTemp: zone.indoorTemp,
                        indoorHumidity,
                        coolTo: zone.coolTo,
                        heatTo: zone.heatTo
                    };
                })
            );

        this.isLoading$ = this.wallControlDetails$.pipe(
            startWith(LOADING_STATE),
            map((data) => data === LOADING_STATE),
            catchError(() => of(false))
        );
    }
}
