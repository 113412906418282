import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ApprovalRequestUser } from 'common/models/approval-requests';
import { ApprovalRequestsState } from 'private/app/store/approval-requests.state';
import { Observable } from 'rxjs';
import { UserRegistrationsForm, UserRegistrationsService } from '../../services/account-admin/user/user-registrations.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { FetchApprovalRequests } from 'private/app/store/approval-requests.actions';
import { Option } from 'common/components/select/select.component';
import { UserApiService } from 'private/app/services/account-admin/user/user-api.service';
import { CreateUserDetails, RegisterUsers, UserDetails, UserRegistration, UserRegistrationDetails, UserRegistrationRequest } from 'private/app/models/user.model';
import { UserAdminService } from 'private/app/services/account-admin/user/user-admin.service';
import { Router } from '@angular/router';
import { ToastService, ToastTheme } from 'common/services/toast.service';

@Component({
    selector: 'hvac-user-registrations',
    templateUrl: './user-registrations.component.html',
    styleUrls: ['./user-registrations.component.scss']
})
export class UserRegistrationsComponent implements OnInit, OnDestroy {
    @Select(ApprovalRequestsState.approvalRequestUsers) approvalRequestUsers$: Observable<ApprovalRequestUser[]>;
    public userRegistrationsSearchForm: FormGroup<UserRegistrationsForm>;
    public userRegistrationStepNumber: string;
    public usersData : UserRegistrationRequest;
    public userRegistrationDetails: UserRegistrationDetails;
    public userDetails: UserDetails;
    public userRegistrationRequest: UserRegistrationRequest[] = [];
    public originaluserRegistrationRequest: UserRegistrationRequest[] = [];
    public loading: boolean = false;

    public breadCrumbData = {
        Content: { title: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.HEADING') },
        breadCrumbs: [
            {
                title: this.translateService.instant('ACCOUNT_ADMIN.ADMIN'),
                url: '/admin'
            },
            {
                title: this.translateService.instant('ACCOUNT_ADMIN.ADMIN_TOOLS'),
                url: '/admin/admin-tools'
            },
            {
                title: this.translateService.instant('ACCOUNT_ADMIN.ACCOUNT_ADMIN'),
                url: '/Admin/Admin-Tools/account-admin.html'
            }
        ]
    };

    public userRegistrationStatusOptions: Option[];
    public originalApprovalRequestUsers: ApprovalRequestUser[] = [];
    public filteredApprovalRequestUsers: ApprovalRequestUser[] = [];
    public activeScreenName: string;
    public apiRequestActive = false;

    constructor(
        public translateService: TranslateService,
        public userAPIService : UserApiService,
        public userAdminService: UserAdminService,
        private router: Router,
        private userRegistrationService: UserRegistrationsService,
        private readonly store: Store,
        private readonly toastService: ToastService
    ) {}

    @HostListener('keyup', ['$event'])
    public keyListener(evt: KeyboardEvent) {
        if (evt && evt.code && evt.code.toLowerCase() === 'enter') {
            this.search();
        }
    }

    ngOnInit(): void {
        this.userAdminService.smoothScrollToTop();
        this.userRegistrationsSearchForm = this.userRegistrationService.userRegistrationsSearchForm;
        this.store.dispatch(new FetchApprovalRequests());
        this.userRegistrationStepNumber = 'step0_searchUser';
        this.loading = true;
        this.userAPIService.userRegistrationRequest({}).subscribe((requests) => {
            if (requests) {
                this.loading = false;
                this.userRegistrationRequest = requests.registrations;
                this.originaluserRegistrationRequest = requests.registrations;
            }
        });
        this.approvalRequestUsers$.subscribe((data: ApprovalRequestUser[]) => {
            this.originalApprovalRequestUsers = [...data];
            this.filteredApprovalRequestUsers = data;
        });
        this.userRegistrationStatusOptions = [
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.DROPDOWN_OPTIONS.STATUS.SELECT'),
                value: ''
            },
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.DROPDOWN_OPTIONS.STATUS.PENDING'),
                value: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.DROPDOWN_OPTIONS.STATUS.PENDING')
            },
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.DROPDOWN_OPTIONS.STATUS.FORWARD'),
                value: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.DROPDOWN_OPTIONS.STATUS.FORWARD')
            }
        ];
    }

    getCreateUserActiveScreenName(): string {
        if (this.userRegistrationStepNumber === 'step0_searchUser') {
            this.activeScreenName = this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.STEP_0');
        }
        if (this.userRegistrationStepNumber === 'step1_userRegistrationForm') {
            this.activeScreenName = this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.STEP_1');
        }

        return this.activeScreenName;
    }

    getActiveScreenName(name: string) {
        this.activeScreenName = name;
    }

    ngOnDestroy(): void {
        this.userRegistrationService.userRegistrationsSearchForm.reset(this.userRegistrationService.defaultRegistrationsSearchFormValue);
    }

    clearAll(): void {
        this.loading = false;
        this.userRegistrationsSearchForm.reset(this.userRegistrationService.defaultRegistrationsSearchFormValue);
        this.userRegistrationRequest = this.originaluserRegistrationRequest;
    }

    search(): void {
        this.loading = true;
        const formValue = this.userRegistrationsSearchForm.value;
        if (formValue.userStatus) {
            formValue.userStatus = formValue.userStatus[0].name;
        }
        this.userAPIService.userRegistrationRequest(formValue).subscribe((requests) => {
            if (requests) {
                this.loading = false;
                this.userRegistrationRequest = requests.registrations;
            }
        });
    }

    onClickRegisterUserChangePage(event : RegisterUsers) {
        const { usersData } = event;
        this.usersData = usersData;
        this.userRegistrationStepNumber = event.registrationUserStep;
    }

    onClickRegisterUserDetailsChangePage(event : UserRegistration) {
        const { usersData } = event;
        this.userRegistrationDetails = usersData;
        this.userRegistrationStepNumber = event.registrationUserStep;
    }

    registeredUserDetails(event: UserDetails) {
        this.userDetails = event;
    }

    onContinueToStep2Click(event: string) {
        this.userRegistrationStepNumber = event;
    }

    userRegistrationDetailsSubmit(event : CreateUserDetails) {
        this.apiRequestActive = true;
        let userID: string | undefined;
        this.userAPIService.createOrRegisterUser(event).subscribe((response) => {
            this.apiRequestActive = false;
            this.userAdminService.smoothScrollToTop();
            this.showToastBar('success', response.details);
            this.userAdminService.userEditForm.markAsPristine();
            userID = response.details.split('UserID : ').pop();
            this.router.navigate([`/Admin/Admin-Tools/account-admin.html/user-details/${userID}`]);
        }, (error) => {
            this.apiRequestActive = false;
            this.userAdminService.smoothScrollToTop();
            this.showToastBar('error', error.error.message);
        });
    }

    private showToastBar(theme: ToastTheme, response: string) {
        this.toastService.add({
            content: response,
            theme: theme,
            id: 'createUser',
            closeable: true,
            autoClose: true
        });
    }
}
