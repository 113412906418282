import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { environment } from 'common/environments/environment';
import { AddToast, ToastService } from 'common/services/toast.service';
import { ConnectedProduct, ProductType, SystemType } from 'private/app/models/connected-product.model';
import { CustomerControlContextService } from 'private/app/services/connected-portal/customer-control-context.service';
import { DealerControlService } from 'private/app/services/connected-portal/dealer-control.service';
import { getIsValidSerialOrModelNo, getWarrantyLookupUrl } from 'private/app/services/connected-portal/utils';
import { EMPTY } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApiResponseCode, REMOVE_CONTROL_TOAST_TIMEOUT, SYSTEM_TYPES_FOR_DEMAND_RESPONSE, UNDOCUMENTED_MODELS } from '../../../constants';
import { HeapService } from 'common/services/heap.service';
import { eventNames } from '../../../heap/constants';
import { ConnectedSystem } from 'private/app/models/connected-system.model';
import { DataSharingOptions } from 'private/app/services/connected-portal/data-sharing.service';
import { Activity, EventType } from 'private/app/models/event.model';

export interface ProductDetailsCardData extends ConnectedProduct {}

@Component({
    selector: 'hvac-product-details-card',
    templateUrl: './product-details-card.component.html',
    styleUrls: ['./product-details-card.component.scss']
})
export class ProductDetailsCardComponent extends BaseComponent implements OnInit {
    @Input() propertyId: string;
    @Input() dealerId: string;
    @Input() product: ProductDetailsCardData;
    @Input() isRuntimeReportEnabled: boolean = false;
    @Input() isTestEquipmentEnabled: boolean = false;
    @Input() isDeviceConnected: boolean = false;
    @Input() isConnectionStatusVisible: boolean = false;
    @Input() isRemoveControlEnabled: boolean = false;
    @Input() systemInfo: Pick<ConnectedSystem, 'vppEligibility'| 'vppActivity' | 'systemId' | 'systemName'> | null;
    @Input() dataSharingPermissions: DataSharingOptions;

    @Output() onRunReportClick = new EventEmitter();
    @Output() onTestEquipmentClick = new EventEmitter();

    public showWarrantyLookup = environment.features.connectedPortal.warrantyLookup;
    public documentationLink: string | null = null;
    public warrantyLookup?: string;
    public toastOutlet = 'productDetailsCardToast';
    public showSpinner: boolean = false;
    public showDemandResponse: boolean = false;

    readonly SystemType = SystemType;
    readonly ProductType = ProductType;
    readonly EventType = EventType;
    readonly Activity = Activity;

    constructor(
        public customerControlContextSvc: CustomerControlContextService,
        private toastService: ToastService,
        private dealerControlSvc: DealerControlService,
        private translateService: TranslateService,
        private heapService: HeapService,
        private router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        const { serialNo, systemType, type, modelNo } = this.product;

        if (!UNDOCUMENTED_MODELS.includes(modelNo)) {
            this.documentationLink = this.getDocumentationLink(modelNo, type);
        }

        if (getIsValidSerialOrModelNo(serialNo)) {
            this.warrantyLookup = getWarrantyLookupUrl(
                this.dealerId,
                this.propertyId,
                serialNo,
                systemType,
                type
            );
        }

        this.customerControlContextSvc.onRemoveConnectedControlConfirmed$.pipe(
            switchMap(() => this.removeConnectedControl$()),
            takeUntil(this.ngOnDestroy$)
        ).subscribe();

        this.showDemandResponse = (SYSTEM_TYPES_FOR_DEMAND_RESPONSE.includes(this.product.systemType)
            && this.systemInfo?.vppEligibility
            && !(this.systemInfo?.vppEligibility === EventType.NONE || this.systemInfo?.vppEligibility === EventType.NOT_ELIGIBLE)
            && this.product?.type === ProductType.WallControl) as boolean;
    }

    public handleRunReportClick() {
        this.heapService.track(eventNames.handleRunReportClick);
        this.onRunReportClick?.emit();
    }

    public handleTestEquipmentClick() {
        this.heapService.track(eventNames.handleTestEquipmentClick);
        this.onTestEquipmentClick.emit();
    }

    public isOnStage(stage:string | undefined | null): boolean {
        if (stage) {
            return (parseInt(stage, 10) > 0);
        }

        return false;
    }

    public isOperationStatusVisible(stage: string | undefined | null) {
        if (stage) {
            return (this.product?.type === ProductType.IndoorUnit || this.product?.type === ProductType.OutdoorUnit);
        }

        return false;
    }

    private getDocumentationLink(modelNo: string, type: ProductType): string | null {
        if (getIsValidSerialOrModelNo(modelNo)) {
            return (type === ProductType.WallControl)
                ? `/products/detail/${modelNo}`
                : `/search/documents?q=${modelNo}`;
        }

        return null;
    }

    private removeConnectedControl$() {
        const removeControlSuccessToast = this.createToast({
            content: this.translateService.instant('CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.TOAST.SUCCESS'),
            theme: 'success'
        });

        const removeControlErrorToast = this.createToast({
            content: this.translateService.instant('CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.TOAST.ERROR'),
            theme: 'error'
        });

        this.showSpinner = true;
        const idParam = this.product.esn ? this.product.esn : this.product.serialNo;

        return this.dealerControlSvc.removeConnectedControl(idParam, this.dealerId)
            .pipe(
                tap((res) => {
                    if (res) {
                        const { code } = res;

                        if (code === ApiResponseCode.SUCCESS) {
                            this.toastService.add(removeControlSuccessToast);
                            setTimeout(() => this.router.navigate([`/connected-portal/dealers/${this.dealerId}/customers`]), REMOVE_CONTROL_TOAST_TIMEOUT);
                        }
                        else {
                            this.toastService.add(removeControlErrorToast);
                        }
                    }

                    this.showSpinner = false;
                }),
                catchError(() => {
                    this.toastService.add(removeControlErrorToast);
                    this.showSpinner = false;

                    return EMPTY;
                })
            );
    }

    private createToast(toast: AddToast): AddToast {
        const defaults = {
            outletName: this.toastOutlet,
            closeable: true,
            autoClose: true
        };

        return {
            ...defaults,
            content: toast.content,
            theme: toast.theme,
            timeOut: REMOVE_CONTROL_TOAST_TIMEOUT
        };
    }
}
