import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, Params, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Company, DataHeader, User, UserResponse } from 'private/app/models/account-admin-search';
import { AccountAdminExportService } from 'private/app/services/account-admin/account-admin-export.service';
import { AccountAdminService } from 'private/app/services/account-admin/account-admin.service';
import { AccountPostAdminService } from 'private/app/services/account-admin/account-post-admin.service';
import { AccountAdminUserService, UserModel } from 'private/app/services/account-admin/account-admin-user.service';
import { DOCUMENT } from '@angular/common';
import { ToastService } from 'common/services/toast.service';

export enum CurrentTab {
    USERS = 'users'
}
@Component({
    selector: 'hvac-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit {
    users?: UserResponse[];
    dataHeaders: DataHeader[] = [
        {
            title: this.translate.instant('ACCOUNT_ADMIN.FIRST_NAME'),
            value: 'firstName',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.LAST_NAME'),
            value: 'lastName',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.COMPANY_NAME'),
            value: 'companyName',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.USER_ID'),
            value: 'userId',
            order: null
        }
    ];

    loading: boolean;
    pagination: number;
    totalCount: number;
    totalPages: number;
    exportCompanyLimit = 10000;
    public toastOutlet = 'AccountAdminNoExportError';


    private pageSize = 10;

    constructor(
        private accountAdminService: AccountAdminService,
        public accountPostAdminService: AccountPostAdminService,
        public accountAdminExportService: AccountAdminExportService,
        private accountAdminUserService: AccountAdminUserService,
        private route: ActivatedRoute,
        private router: Router,
        @Inject(DOCUMENT) private doc: Document,
        private translate: TranslateService,
        private toastService: ToastService
    ) {}

    ngOnInit() {
        this.accountAdminService.currentTab = CurrentTab.USERS;
        this.route.queryParamMap.subscribe((value: ParamMap) => {
            if (value.get('usersPagination')) {
                this.pagination = parseInt(value.get('usersPagination') || '', 10);
            }
            else {
                this.pagination = 1;
            }

            this.dataHeaders = this.accountAdminService.updateDataHeaders(value.get('usersSortFields') || '', this.dataHeaders);

            const sortParams = value.get('usersSortFields') || '';

            this.postUsersSearch(sortParams, Number(value.get('usersPagination')));
            localStorage.setItem(this.translate.instant('ACCOUNT_ADMIN.LOCAL_HOST_KEYS.USER_MODEL_DATA_KEY'), '');
        }, (error: HttpErrorResponse) => this.toastService.raiseDefaultErrorToast('UsersComponent-ngOnInit', error.message));
    }

    public onRowClick(userId: string) {
        const filteredUsers = (this.users?.filter((user) => user.userId === userId) || []);
        const user = filteredUsers && filteredUsers.length > 0 ? filteredUsers[0] : '';

        localStorage.setItem(this.translate.instant('ACCOUNT_ADMIN.LOCAL_HOST_KEYS.USER_MODEL_DATA_KEY'), JSON.stringify(user));

        if (!this.doc.getSelection()?.toString()) {
            this.router.navigate(['../../user-details/', userId], { relativeTo: this.route });
        }
    }

    public findDefaultCompany(contact: User): Company | undefined {
        return contact.companies?.find((company: Company) => company.id === contact.defaultCompanyId);
    }

    public handlePageChange(event: number) {
        const queryParams: Params = { usersPagination: event };

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: queryParams,
                queryParamsHandling: 'merge'
            }
        );

        this.pagination = event;
    }

    public sort(item: DataHeader) {
        const currentSort: Partial<DataHeader> = this.dataHeaders.find((header: DataHeader) => header.value === item.value) || {};

        if (!currentSort) {
            return;
        }

        if (!currentSort.order) {
            currentSort.order = 'asc';
        }
        else if (currentSort.order === 'asc') {
            currentSort.order = 'desc';
        }
        else {
            currentSort.order = null;
        }

        const queryParams: Params = { usersSortFields: currentSort.order ? `${currentSort.value}-${currentSort.order}` : null };

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: queryParams,
                queryParamsHandling: 'merge'
            }
        );
    }

    public postUserSearchExport(results: number) {
        if (results >= 1) {
            this.loading = true;

            this.accountAdminUserService.postUserSearchExport(
                this.accountAdminUserService.createUserSearchBody(
                    this.accountAdminService.advancedSearchForm.value,
                    null,
                    ''
                )
            ).subscribe((response: HttpResponse<string>) => {
                this.loading = false;
                this.accountAdminExportService.doExport(response);
            });
        }
    }

    private postUsersSearch(sortParams: string, pagination: number = 1) {
        this.loading = true;

        this.accountAdminUserService.postUserSearch(
            this.accountAdminUserService.createUserSearchBody(
                this.accountAdminService.advancedSearchForm.value,
                pagination,
                sortParams
            )
        ).subscribe((res: UserModel) => {
            this.loading = false;
            this.users = res?.data;
            this.totalCount = (res?.totalCount === 0 && res?.data.length > 0) ? this.totalCount : res?.totalCount;
            if (this.totalCount > this.exportCompanyLimit) {
                this.toastService.add({
                    content: this.translate.instant('ACCOUNT_ADMIN.UNABLE_TO_PROCESS_EXPORT'),
                    theme: 'warning',
                    id: 'AccountAdminNoExportError',
                    outletName: this.toastOutlet,
                    closeable: false,
                    autoClose: false
                });
            }
            this.totalPages = Math.ceil(this.totalCount / this.pageSize);
        });
    }
}
