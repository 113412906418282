import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { BehaviorSubject, Subject } from 'rxjs';
import { Option } from '../../../../common/components/select/select.component';
import { AccountAdminBrandsItemsEntity, AccountAdminFormResultMap, CompanyCategory, CompanyType, RelationshipType, User } from '../../models/account-admin-search';
import { AccountAdminFormFieldService, AccountAdminParentForm } from './account-admin-form-field.service';
import { FormGroup } from '@angular/forms';
import { LegalEntityResponse } from 'private/app/models/account-admin-search-results';
import { FilteredOption } from 'private/app/models/distributor-relationships.model';
export interface AccountAdminConfiguratrion {
    excludeParts?: boolean;
}

export const LATITUDE = 90;
export const LONGITUDE = 180;

export interface CompanyCategoryResult {
    canCreate?: boolean;
    companyCategory: string;
    companyCategoryCode: string;
    companyTypes?: CompanyType[];
}

@Injectable({ providedIn: 'root' })
export class AccountAdminUtilsService {
    public formFields : FormGroup<AccountAdminParentForm> = this.formFieldService.accountAdminParentFormFields;
    public clearAllClicked = new BehaviorSubject<boolean>(false);
    isButtonClicked$ = this.clearAllClicked.asObservable();
    public companyDetailsForm = this.formFieldService.companyDetailsForm;
    public breadCrumbData = {
        Content: { title: this.translateService.instant('ACCOUNT_ADMIN.HEADING') },
        breadCrumbs: [
            {
                title: this.translateService.instant('ACCOUNT_ADMIN.PARENT_1'),
                url: '/admin'
            },
            {
                title: this.translateService.instant('ACCOUNT_ADMIN.PARENT_2'),
                url: '/admin/admin-tools'
            }
        ]
    };

    private contactFields = [
        'firstName',
        'lastName',
        'title',
        'id'
    ];

    private employeeContactsFields = [
        'accountManager',
        'rsm',
        'tsm',
        'pricingAnal',
        'prsm',
        'ptam'
    ];

    private languageOptions = [
        'English',
        'Spanish'
    ]

    private ownershipOptions = [
        'Wholly Owned',
        'Joint Venture',
        'Independent'
    ]

    private breadCrumbData$: Subject<ContentHeadingData> = new Subject<ContentHeadingData>();

    constructor(private translateService: TranslateService, private formFieldService: AccountAdminFormFieldService) { }

    public setAdminBreadCrumbData(breadCrumb: ContentHeadingData) {
        this.breadCrumbData$.next(breadCrumb);
    }

    public getAdminBreadCrumbData() {
        return this.breadCrumbData$;
    }

    public hasContactCriteria(formValue: object) {
        const hasContactField = this.populatedFormFields(formValue).find((formField: string) => this.contactFields.find((contactField: string) => Object.prototype.hasOwnProperty.call(formField, contactField)));

        return Boolean(hasContactField);
    }

    public hasEmployeeContacts(formValue: object) {
        const hasField = this.populatedFormFields(formValue).find((formField: string) => this.employeeContactsFields.find((contactField: string) => Object.prototype.hasOwnProperty.call(formField, contactField)));

        return Boolean(hasField);
    }

    public getLanguageOptions(): Option[] {
        return [{
            name: this.translateService.instant('ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.LANGUAGE'),
            value: ''
        }, ...this.languageOptions?.map((option: string) => ({
            name: option,
            value: option
        }))];
    }

    public getOwnerShipOptions(): Option[] {
        return this.ownershipOptions?.map((option: string) => ({
            name: option,
            value: option
        }));
    }

    public mapAccountAdminSearchForm(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any,
        accountAdminConfiguratrion?: AccountAdminConfiguratrion
    ): AccountAdminFormResultMap {
        const [
            brands,
            contactTypes,
            countries,
            managers,
            companyTypes,
            relationshipTypes,
            legalEntity
        ] = data;

        return {
            legalEntity: [
                {
                    name: this.translateService.instant('ACCOUNT_ADMIN.SELECT_LEGAL_ENTITY'),
                    value: ''
                },
                ...this.mapLegalEntities(legalEntity)
            ],
            accountManagers: [
                {
                    name: this.translateService.instant('ACCOUNT_ADMIN.SELECT_ACCOUNT_MANAGER'),
                    value: ''
                },
                ...this.mapManagers(
                    [
                        ...(managers?.AM || []),
                        ...(accountAdminConfiguratrion?.excludeParts ? [] : (managers?.PTAM || []))
                    ]
                )
            ],
            brands: [
                {
                    name: this.translateService.instant('ACCOUNT_ADMIN.SELECT_BRAND'),
                    value: '',
                    usageType: []
                },
                ...this.mapBrands(brands)
            ],
            contactTypes: [
                {
                    name: this.translateService.instant('ACCOUNT_ADMIN.SELECT_CONTACT_TYPE'),
                    value: ''
                },
                ...this.createSelectOptions(contactTypes, 'name', 'id')
            ],
            countries: [
                {
                    name: this.translateService.instant('ACCOUNT_ADMIN.SELECT_COUNTRY'),
                    value: ''
                },
                ...this.createSelectOptions(countries, 'name', 'code')
            ],
            rsms: [
                {
                    name: this.translateService.instant('ACCOUNT_ADMIN.SELECT_RSM'),
                    value: ''
                },
                ...this.mapManagers(
                    [
                        ...(managers?.RSM || []),
                        ...(accountAdminConfiguratrion?.excludeParts ? [] : (managers?.PRSM || []))
                    ]
                )
            ],
            tsms: [
                {
                    name: this.translateService.instant('ACCOUNT_ADMIN.SELECT_TSM'),
                    value: ''
                },
                ...this.mapManagers(managers.TSM || [])
            ],
            companyTypes: this.mapCompanyTypes(companyTypes),
            relationshipTypes: [
                {
                    name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_TYPE_OPTION_SELECT'),
                    value: ''
                },
                ...this.mapRelationshipTypes(relationshipTypes)
            ],
            pricingAnal: [
                {
                    name: this.translateService.instant('ACCOUNT_ADMIN.SELECT_PRICING_ANAL'),
                    value: ''
                },
                ...this.mapManagers(managers.PA || [])
            ],
            prsm: [
                {
                    name: this.translateService.instant('ACCOUNT_ADMIN.SELECT_PART_RSM'),
                    value: ''
                },
                ...this.mapManagers(
                    [
                        ...(managers?.PRSM || [])
                    ]
                )
            ],
            ptam: [
                {
                    name: this.translateService.instant('ACCOUNT_ADMIN.SELECT_PART_ACCOUNT_MANAGER'),
                    value: ''
                },
                ...this.mapManagers(
                    [
                        ...(managers?.PTAM || [])
                    ]
                )
            ]
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public populatedFormFields(formValue: any): any[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Object.entries(formValue).map((entry: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const [key, val]: any = entry;

            if (!val || Object.keys(val).length === 0 || (val.length === 1 && val[0].value === '')) {
                return false;
            }

            return { [key]: (val[0] === '{' || val[0] === '[') ? JSON.parse(val) : val };
        }).filter(Boolean);
    }

    public mapLegalEntities(legalEntity: LegalEntityResponse[]): Option[] {
        return legalEntity.map((entity: LegalEntityResponse) => ({
            name: `${entity.companyName} - ${entity.hvacCompanyId}`,
            value: entity.hvacCompanyId.toString()
        })).slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()));
    }

    public mapManagers(managers: User[]): Option[] {
        return this.filterDuplicates(managers?.map((manager: User) => ({
            value: manager.id,
            name: `${manager.firstName} ${manager.lastName}`
        })).slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase())));
    }

    public clearAllButtonClicked(value: boolean) {
        this.clearAllClicked.next(value);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private createSelectOptions(options: { [key: string]: any }[], nameProp: string, valueProp: string): Option[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return options.map((option: { [key: string]: any }): Option => ({
            name: option[nameProp],
            value: option[valueProp]
        })).slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()));
    }

    private mapBrands(options: AccountAdminBrandsItemsEntity[]): FilteredOption[] {
        return options.map((option:AccountAdminBrandsItemsEntity): FilteredOption => ({
            name: option.name,
            value: option.code,
            usageType: option.usageTypes || []
        })).slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()));
    }

    private mapCompanyTypes(options: CompanyCategoryResult[]): CompanyCategory[] {
        return options.map((option: CompanyCategoryResult) => ({
            code: option.companyCategoryCode,
            name: option.companyCategory,
            canCreate: option.canCreate,
            companyTypes: option.companyTypes
        })).slice().sort((valueA: CompanyCategory, valueB: CompanyCategory) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()));
    }

    private mapRelationshipTypes(relationshipType: RelationshipType[]): Option[] {
        return relationshipType.map((relationType:RelationshipType) => ({
            name: relationType.description,
            value: relationType.code,
            id: relationType.id
        })).slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private filterDuplicates(arr: any[]): Option[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return arr.filter((value, idx, array) => array.findIndex((value2: any) => (value2.value === value.value)) === idx);
    }
}
