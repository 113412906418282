<hvac-toast [name]="toastOutlet"></hvac-toast>

<section class="hvac-account-admin-header hvac-company-designation-header hvac-create-company-designation-section">
    <hvac-content-header [data]="breadCrumbData"></hvac-content-header>
</section>
<div class="hvac-content-container hvac-create-company-content-container">
    <div class="hvac-account-admin-form-header hvac-account-admin-heading hvac-account-admin-heading-default">
        <h1 class="hvac-h4">
            {{
            "ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.COMPANY_DESIGNATION"
            | translate
            }}
        </h1>
        <a hvacButton="default" size="small" class="hvac-back-button" [routerLink]="['/Admin/Admin-Tools/account-admin.html/', {}]"
            queryParamsHandling="merge" class="hvac-text-link">
            <utc-svg class='hvac-back-arrow' src="assets/icons/arrow.svg"></utc-svg>
            <div class="hvac-back-text">{{ "ACCOUNT_ADMIN.BACK_TO_COMPANY_ADMIN" | translate }}</div>
        </a>
    </div>
    <hvac-company-designation></hvac-company-designation>
</div>

<div *ngIf="companyCreateService.designation$.value.companyType.length">
    <div class="hvac-content-container hvac-create-company-content">
        <hvac-business-information [brandList]="(authorisedBrandList$ | async)!"></hvac-business-information>
    </div>
    <div class="hvac-content-container hvac-create-company-content">

        <hvac-location-information [title]="
            translate.instant('ACCOUNT_ADMIN.COMPANY_CREATE.LOCATION_INFO')
        "></hvac-location-information>
    </div>
    <div class="hvac-content-container hvac-create-company-content">

        <section *ngIf="userIsInternal" class="hvac-company-info-container">
            <div class="hvac-company-header">
                <h2>{{ "ACCOUNT_ADMIN.COMPANY_CREATE.NOTES" | translate }}</h2>
            </div>
            <form [formGroup]="companyCreateForm">
                <div class="hvac-company-form">
                    <textarea placeholder="{{
                                'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.NOTES' | translate }}"
                        class="hvac-create-company-note hvac-input" rows="5" formControlName="notes" maxlength="1000"></textarea>
                </div>
            </form>
        </section>
        <div>
            <div class="hvac-company-create-footer-container">
                <button class="hvac-comapny-create-footer-button" (click)="cancelForm()" [hvacButton]="'default'">
                    <span>{{ "ACCOUNT_ADMIN.COMPANY_CREATE.CANCEL" | translate }}</span>
                </button>
                <button [hvacButton]="'primary'" (click)="initCreateCmpnyRqstIfValidInput()" [disabled]="((apiRequestActive$ | async) || (!companyCreateForm.valid))">
                    <span>{{ (addCompanyRelationship$ | async) ? ("ACCOUNT_ADMIN.COMPANY_CREATE.SUBMIT" | translate) : ("ACCOUNT_ADMIN.COMPANY_CREATE.CREATE" | translate) }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
<hvac-manage-relationship [isCreate]="isCreate" [brandFamily]="userBrandFamily" [hvacCompanyId]="existingCompanyId" (submitChanges)="updateOrAddRelationships($event)"></hvac-manage-relationship>
<hvac-address-lookup [visible]="(showAddressVerification$ | async) || false" [addressLookup]="addressLookUpInput" (onAddressConfirmation)="onAddressConfirmation($event)" (toastOpenStatus)="toastOpenStatus($event)"></hvac-address-lookup>
<hvac-duplicate-company-check [visible]="(showDuplicateCompanyCheck$ | async) || false" [apiRequestActive]="(apiRequestActive$ | async)" [duplicateCompanyLookup]="duplicateCompanyCheckInput" (onDuplicateCompanyConfirmation)="onDuplicateCompanyConfirmation($event)" (toastOpenStatus)="toastOpenStatus($event)" (createCompanyAfterDuplicateCheck) = "initCreateCompany()"></hvac-duplicate-company-check>
<hvac-validate-soldto-popup
    [showModal]="showModalFrMkngCrntCmpnyToAsPrmry"
    [existingPrimaryDistributorDetails]="existingPrimaryDistributorDetails"
    (onCancel)="cnclChngngExstingPrmryDstrbtr()"
    (onContinue)="issueCreateCompanyPostRequest()">
</hvac-validate-soldto-popup>
<div class="hvac-create-company-loader">
    <utc-loading-spinner *ngIf="loading" class="hvac-account-admin-loader" spinnerSize="small">
    </utc-loading-spinner>
</div>
