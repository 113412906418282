import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'common/components/base/base.component';
import { ContentHeadingData } from 'common/models/content-heading';
import { CompanyCode } from 'private/app/models/company.model';
import { ProductType, SystemType } from 'private/app/models/connected-product.model';
import { ConnectedPortalUserService } from 'private/app/services/connected-portal/connected-portal-user.service';
import { CustomerControlContextService } from 'private/app/services/connected-portal/customer-control-context.service';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { ProductConfigurationService } from 'private/app/services/connected-portal/product-configuration.service';
import { QueryParamsService } from 'private/app/services/connected-portal/query-params.service';
import { concat, Observable, of } from 'rxjs';
import { concatAll, map, switchMap } from 'rxjs/operators';
import { PageRouteData, ProductDetailContextService } from '../../product-detail-context.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConnectedPortalLayoutContainerComponent } from 'private/app/views/connected-portal/components/connected-portal-layout-container/connected-portal-layout-container.component';
import { DistributorNavigationComponent } from '../../../components/distributor-navigation/distributor-navigation.component';
import { ContentModule } from 'common/content/content.module';
import { ConnectedPortalModule } from 'private/app/views/connected-portal/connected-portal.module';
import { AsyncPipe, CommonModule, NgIf, NgSwitch } from '@angular/common';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { InfinityWallControlDetailComponent } from '../infinity-wall-control-detail/infinity-wall-control-detail.component';
import { EcobeeWallControlDetailComponent } from '../ecobee-wall-control-detail/ecobee-wall-control-detail.component';
import { EcobeeNIWallControlDetailComponent } from '../ecobee-ni-wall-control-detail/ecobee-ni-wall-control-detail.component';
import { CarrierEltWallControlDetailComponent } from '../carrier-elt-wall-control-detail/carrier-elt-wall-control-detail.component';
import { CarrierEltRunTimeReportComponent } from '../components/carrier-elt-run-time-report/carrier-elt-run-time-report.component';
import { EcobeeRunTimeReportComponent } from '../components/ecobee-run-time-report/ecobee-run-time-report.component';
import { EcobeeNiRunTimeReportComponent } from '../components/ecobee-ni-run-time-report/ecobee-ni-run-time-report.component';

@Component({
    selector: 'hvac-wall-control-detail-container',
    templateUrl: './wall-control-detail-container.component.html',
    styleUrls: ['./wall-control-detail-container.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ConnectedPortalLayoutContainerComponent,
        ContentModule,
        DistributorNavigationComponent,
        ConnectedPortalModule,
        TranslateModule,
        CommonComponentsModule,
        NgSwitch,
        AsyncPipe,
        NgIf,
        InfinityWallControlDetailComponent,
        EcobeeWallControlDetailComponent,
        EcobeeNIWallControlDetailComponent,
        CarrierEltWallControlDetailComponent,
        CarrierEltRunTimeReportComponent,
        EcobeeRunTimeReportComponent,
        EcobeeNiRunTimeReportComponent
    ],
    providers: [QueryParamsService, ProductDetailContextService, ProductConfigurationService, CustomerControlContextService]
})
export class WallControlDetailContainerComponent extends BaseComponent implements OnInit {
    public readonly SystemType = SystemType;
    public readonly CompanyCode = CompanyCode;

    public routeData$: Observable<PageRouteData>;
    public companyCode$: Observable<string>;
    public dealerId$: Observable<string | null>;
    public breadcrumbs$: Observable<ContentHeadingData>;
    public controlType:string =this.translateService.instant('CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.CONNECTED_CONTROLS')
    readonly ProductType = ProductType;

    constructor(
        public contextService: ProductDetailContextService,
        private dealersService: DealersService,
        private cpUserService: ConnectedPortalUserService,
        private translateService: TranslateService,
        public customerControlContextSvc: CustomerControlContextService
    ) {
        super();
    }

    ngOnInit(): void {
        this.routeData$ = this.contextService.getRouteData$();
        this.dealerId$ = this.routeData$.pipe(map((routeData) => routeData.dealerId));
        this.companyCode$ = this.cpUserService.getCompanyCode();

        this.breadcrumbs$ = this.routeData$.pipe(
            switchMap(({ dealerId, propertyId }) => {
                const basic = of(this.contextService.getBreadcrumbData(ProductType.WallControl, dealerId, propertyId));
                const withDealerName = this.dealersService.queryDealerById(dealerId).pipe(
                    map((res) => {
                        const { name } = res.data;

                        return this.contextService.getBreadcrumbData(ProductType.WallControl, dealerId, propertyId, name);
                    })
                );

                return concat([basic, withDealerName]);
            }),
            concatAll()
        );
    }
}
