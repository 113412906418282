import { Component } from '@angular/core';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'safe-pipe';

@Component({
    selector: 'hvac-dealer-pending-activation',
    templateUrl: './dealer-pending-activation.component.html',
    styleUrls: ['./dealer-pending-activation.component.scss'],
    standalone: true,
    imports: [
        CommonComponentsModule,
        TranslateModule,
        SafePipeModule
    ]
})
export class DealerPendingActivationComponent {}

