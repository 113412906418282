<div>
    <h1 class="hvac-h4">{{
        'ACCOUNT_ADMIN.ADVANCED_SEARCH_HEADING' | translate }}</h1>
    <h1 class="hvac-p-sm">{{
        'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.COMPANY_DETAILS' | translate }}</h1>
</div>
<form [formGroup]="accountAdminService.advancedSearchForm">
    <div class="hvac-row hvac-account-admin-form-inner">
        <div class="hvac-col-custom hvac-account-admin-col">
            <div class="hvac-form-field">
                <utc-input shadow="light"  [inputControl]="accountAdminService.advancedSearchForm.controls.companyId"
                    label="{{ 'ACCOUNT_ADMIN.COMPANY_ID' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.soldTo"
                    label="{{ 'ACCOUNT_ADMIN.SOLD_TO' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-select (onChange)="onCountryChange($event)" [group]="accountAdminService.advancedSearchForm" control="country" shadow="light"
                    defaultOption="{{ 'ACCOUNT_ADMIN.COUNTRY' | translate }}" [options]="(countryList$ | async) || []" [multiple]="false">
                </utc-select>
            </div>
        </div>
        <div class="hvac-col-custom hvac-account-admin-col">
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.companyName"
                    label="{{ 'ACCOUNT_ADMIN.COMPANY_NAME' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.address1"
                    label="{{ 'ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.POSTAL_ADDRESS' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-select [disabled]="isStateDisabled" [group]="accountAdminService.advancedSearchForm" control="region" shadow="light" (onChange)="onStateChange($event)"
                    defaultOption="{{ 'ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.STATE_PROVINCE' | translate }}" [options]="(stateList$ | async) || []" [multiple]="false">
                </utc-select>
            </div>
        </div>
        
        <div class="hvac-col-custom hvac-account-admin-col">
            <div class="hvac-form-field">
                <utc-select *ngIf="!isCompanyTypeReadOnlyField" [multiple]="false" shadow="light" [group]="accountAdminService.advancedSearchForm" control="companyType"
                    defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_TYPE' | translate }}" [options]="companyTypes || []"></utc-select>
                <utc-select *ngIf="isCompanyTypeReadOnlyField" [multiple]="false" shadow="light" [disabled]="true" [group]="accountAdminService.advancedSearchForm" control="readonlyCompanyType"
                    defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_TYPE' | translate }}" [options]="companyTypes || []"></utc-select>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.city"
                    label="{{ 'ACCOUNT_ADMIN.CITY' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.postalCode"
                    label="{{ 'ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.ZIP_POSTAL_CODE' | translate }}"></utc-input>
            </div>
        </div>
    </div>
    <div class="hvac-divider"></div>
    <div class="hvac-row hvac-create-user-action-buttons">
        <button type="button" hvacButton="default" (click)="clearFilters()">
            <span>{{ 'ACCOUNT_ADMIN.CLEAR_ALL' | translate }}</span>
        </button>
        <button buttonType="submit" hvacButton="primary" (click)="onSubmit(accountAdminService.advancedSearchForm)">
            <span>{{ 'ACCOUNT_ADMIN.SEARCH' | translate }}</span>
        </button>
    </div>
</form>
<ng-template [ngIf]="isLoading" #spinner>
    <utc-loading-spinner></utc-loading-spinner>
</ng-template>