/* tslint:disable:max-line-length */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { ContentUrlModule } from 'common/pipes/content-url.module';
import { ContentModule } from 'common/content/content.module';
import { RichTextModule } from 'common/pipes/rich-text/rich-text.module';
import { TextOverflowModule } from 'common/pipes/text-overflow/text-overflow.module';
import { DateStatusPipe } from 'common/pipes/date-status.pipe';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { TabNavigationFooterComponent } from 'private/app/components/tabNavigationFooter/tabNavigationFooter.component';
import { SafePipeModule } from 'safe-pipe';
import { BookmarksMenuComponent } from './bookmarks-menu/bookmarks-menu.component';
import { BookmarksListComponent } from './bookmarks-slide/bookmarks-list/bookmarks-list.component';
import { BookmarksSlideComponent } from './bookmarks-slide/bookmarks-slide.component';
import { BulletinsComponent } from './bulletins/bulletins.component';
import { BusinessLocatorComponent } from './business-locator/businessLocator.component';
import { ChatBotComponent } from './chat-bot/chatBot.component';
import { CreateAccountHeaderComponent } from './create-account-header/createAccountHeader.component';
import { CreateAccountInfoFormComponent } from './create-account-info-form/createAccountInfoForm.component';
import { PrivateHeaderIconLinksComponent } from './header/private-header-icon-links/private-header-icon-links.component';
import { PrivateHeaderComponent } from './header/private-header.component';
import { HighlightSectionComponent } from './highlights/highlight-section.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { MediaHighlightComponent } from './highlights/media-highlight.component';
import { InboxComponent } from './inbox/inbox.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PrivateFooterComponent } from './private-footer/private-footer.component';
import { RecentActivityComponent } from './recent-activity/recent-activity.component';
import { UserDetailDropdown } from './user-detail-dropdown/user-detail-dropdown.component';
import { VideoWidgetComponent } from './video-widget/video-widget.component';
import { UserValidationComponent } from '../views/user-validation/user-validation.component';
import { UserValidationFormComponent } from './user-validation-form/user-validation-form.component';
import { EmailValidationComponent } from 'private/app/components/email-validation/email-validation.component';
import { CreateCompanyComponent } from '../views/create-company/create-company.component';
import { BusinessInformationComponent } from '../views/create-company/business-information/business-information.component';
import { LocationInformationComponent } from '../views/create-company/location-information/location-information.component';
import { CompanyDesignationComponent } from './company-designation/company-designation.component';
import { ManageRelationshipComponent } from './manage-relationship/manage-relationship.component';
import { AddressLookupComponent } from './address-lookup/address-lookup.component';
import { ValidationSoldtoPopupComponent } from '../views/account-admin-company-details/validation-soldto-popup/validation-soldto-popup.component';
import { NgxPopperModule } from 'common/libs/ngx-popper/popper.module';
import { DuplicateCompanyCheckComponent } from './duplicate-company-check/duplicate-company-check.component';

/* tslint:enable:max-line-length */

const privateComponents = [
    BookmarksMenuComponent,
    BookmarksListComponent,
    BulletinsComponent,
    BusinessLocatorComponent,
    ChatBotComponent,
    CreateAccountHeaderComponent,
    CreateAccountInfoFormComponent,
    HighlightsComponent,
    HighlightSectionComponent,
    MediaHighlightComponent,
    NotificationsComponent,
    PrivateFooterComponent,
    PrivateHeaderComponent,
    PrivateHeaderIconLinksComponent,
    UserDetailDropdown,
    VideoWidgetComponent,
    RecentActivityComponent,
    BookmarksSlideComponent,
    TabNavigationFooterComponent,
    InboxComponent,
    UserValidationComponent,
    UserValidationFormComponent,
    EmailValidationComponent,
    ManageRelationshipComponent,
    BusinessInformationComponent,
    LocationInformationComponent,
    AddressLookupComponent,
    ValidationSoldtoPopupComponent,
    DuplicateCompanyCheckComponent
];

@NgModule({
    imports: [
        CommonModule,
        CommonComponentsModule,
        HttpClientModule,
        SafePipeModule,
        ContentUrlModule,
        ContentModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxPopperModule,
        TranslateModule,
        PdfJsViewerModule,
        ContentModule,
        RichTextModule,
        TextOverflowModule
    ],
    declarations: [...privateComponents, DateStatusPipe, CreateCompanyComponent, CompanyDesignationComponent],
    exports: [...privateComponents, CommonComponentsModule]
})
export class ComponentsModule { }
