import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NumberSuffixPipe } from 'common/pipes/number-suffix.pipe';
import { CharSymbol } from 'common/enums/char-symbol';
import { WallControlHumidAndVent, WallControlHumidAndVentProps } from 'private/app/models/wall-control.model';
import { DEVICE_TEMPERATURE_PROPERTIES, TempUnitFormat } from 'private/app/views/connected-portal/constants';
import { SystemType } from 'private/app/models/connected-product.model';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { KeyValuePipe, NgClass, NgForOf, NgIf } from '@angular/common';

type HumidityAndVentProfiles = keyof WallControlHumidAndVent
type TemplateProps = {
    [key in HumidityAndVentProfiles]: { label: string; value: unknown }[]
};

@Component({
    selector: 'hvac-wall-control-humidity-and-ventilation-details',
    templateUrl: './wall-control-humidity-and-ventilation-details.component.html',
    styleUrls: ['./wall-control-humidity-and-ventilation-details.component.scss'],
    standalone: true,
    imports: [
        CommonComponentsModule,
        NgIf,
        NgClass,
        TranslateModule,
        KeyValuePipe,
        NgForOf
    ]
})
export class WallControlHumidityAndVentilationDetailsComponent implements OnChanges {
    @Input() data: WallControlHumidAndVent;
    @Input() systemType: SystemType;
    @Input() tempUnitFormat: TempUnitFormat | null;

    public selectedTab: HumidityAndVentProfiles = 'away';
    public templateProps: TemplateProps;
    public arePropsEmpty: boolean = false;

    constructor(
        private translateService: TranslateService,
        private numberSuffixPipe: NumberSuffixPipe
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.data?.currentValue) {
            this.selectedTab = this.systemType === SystemType.CARRIER_ELT ? this.getNonEmptyProfile() : 'away';

            this.templateProps = Object.keys(this.data)
                .reduce((acc, curKey) => {
                    const items: { label: string; value: unknown }[] = Object
                        .entries(this.data[curKey as HumidityAndVentProfiles]!)
                        .map(([key, value]) => ({
                            label: this.getPropLabel(key),
                            value: this.formatValue(key, value)
                        }));

                    acc[curKey as HumidityAndVentProfiles] = items;

                    return acc;
                }, {
                    home: [],
                    away: [],
                    vacation: [],
                    wake: [],
                    sleep: []
                } as TemplateProps);

            this.arePropsEmpty = Object.values(this.data).every((key) => this.isEmpty(key));
        }
    }

    handleTabClick(tabSelection: HumidityAndVentProfiles) {
        this.selectedTab = tabSelection;
    }

    formatValue(propertyName: string, value: string) {
        const isPresent = DEVICE_TEMPERATURE_PROPERTIES.map((item) => item.toLowerCase()).includes(propertyName.toLowerCase());
        if (isPresent) {
            if (this.tempUnitFormat === TempUnitFormat.Celsius) {
                return this.numberSuffixPipe.transform(value, CharSymbol.Celsius);
            }
            else if (this.tempUnitFormat === TempUnitFormat.Fahrenheit) {
                return this.numberSuffixPipe.transform(value, CharSymbol.Fahrenheit);
            }

            return value;
        }
        switch (propertyName) {
            case 'dehumidificationTarget':
            case 'humidificationTarget':
                return this.numberSuffixPipe.transform(value, CharSymbol.Percent);
            default:
                return value;
        }
    }

    getPropLabel(propName: string): string {
        const translationKey = `CONNECTED_PORTAL.WALL_CONTROL.HUMIDITY_AND_VENTILATION.PROPS.${propName}`;

        return this.translateService.instant(translationKey) || '';
    }

    isEmpty(key:WallControlHumidAndVentProps) {
        return Object.keys(key).length === 0;
    }

    getNonEmptyProfile(): HumidityAndVentProfiles {
        if (this.data.wake && !this.isEmpty(this.data.wake)) {
            return 'wake';
        }

        const comfortProfileName = Object.keys(this.data).find((key) => !this.isEmpty(this.data[key as HumidityAndVentProfiles] as WallControlHumidAndVentProps));

        return comfortProfileName as HumidityAndVentProfiles || 'away';
    }
}
