import { BaseResponse } from 'common/models/account-status';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Option } from 'common/components/select/select.component';
import { environment } from 'common/environments/environment';
import { ApiOptions, ApiOptionsService } from 'common/services/api-options/api-options.service';
import { Company, CompanyServices } from 'private/app/models/account-admin-search';
import { DealerLocatorEnrollmentList } from 'private/app/models/manage-dealer-locator';
import { Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ProgramSearchPayload, ProgramSearchResults, Brand, ProgramDetails, EligibilityCriteria, ProgramAdminLocatorIcon, ProgramEnrollment, EnrollmentStatusPayload, ProgramManager, UpdateCriteriaData } from 'private/app/models/account-admin-program.model';
import { AccountAdminProgramService } from './account-admin-program.service';
import { ToastService } from 'common/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { AccountAdminExtendedService } from './account-admin-extended.service';
@Injectable({ providedIn: 'root' })
export class AccountAdminApiService {
    public companyServices: CompanyServices[];

    constructor(
        public accountAdminProgramService: AccountAdminProgramService,
        public translate: TranslateService,
        private toastService: ToastService,
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService,
        private readonly accountAdminExtendedService: AccountAdminExtendedService
    ) {
    }

    public getAccountAdminCompanyDetails(companyId: string): Observable<Company> {
        return forkJoin([
            this.accountAdminExtendedService.getAccountAdminCompanyById(companyId),
            this.getEnrollments(companyId)
        ]).pipe(
            map((res) => this.prepareCompanyInformation(res))
        );
    }

    public getAccountAdminCompanyServices(companyType: string, serviceOptionData: BehaviorSubject<Option[]>) {
        this.getCompanyServicesByCompanyType(companyType).subscribe((services) => {
            this.companyServices = services;
            const optionList: Option[] = [];
            services.map((service: CompanyServices) => optionList.push({
                name: service.name,
                value: service.id
            }));
            serviceOptionData.next(optionList);
        });
    }

    public getCompanyServices(): CompanyServices[] {
        return this.companyServices;
    }

    public getAccountAdminProgramsBySearch(criteria: ProgramSearchPayload) {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const url = environment.api.accountAdminProgramSearch;

        return options$.pipe(
            switchMap((options) => this.httpClient.post<ProgramSearchResults>(url, criteria, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getAccountAdminProgramsById(programId: string): Observable<ProgramDetails> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<ProgramDetails>(`${environment.api.accountAdminProgram}/details?programId=${programId}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public deactivate(companyId:string): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<BaseResponse>(`${environment.api.accountAdminCompanyDeactivateById}?hvacCompanyId=${companyId}`, {}, { ...options })),
            catchError((error:HttpErrorResponse) => {
                throw error;
            })
        );
    }

    public reactivate(companyId:string): Observable<Company> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<Company>(`${environment.api.accountAdminCompanyReactivateById}?hvacCompanyId=${companyId}`, {}, { ...options })),
            catchError((error:HttpErrorResponse) => {
                throw error;
            })
        );
    }

    public getAccountAdminProgramEnrollmentsById(programId: string): Observable<ProgramEnrollment[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<ProgramEnrollment[]>(`${environment.api.accountAdminProgramEnrollments}?programId=${programId}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public accountAdminProgramEnrollmentStatus(enrollmentStatus: EnrollmentStatusPayload): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<BaseResponse>(environment.api.accountAdminProgramEnrollmentStatus, enrollmentStatus, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public accountAdminProgramEnrollmentLevel(programId: number, companyId: number, levelId: number): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<BaseResponse>(`${environment.api.accountAdminProgramEnrollmentLevel}?programId=${programId}&dealerCompanyId=${companyId}&enrollmentLevelId=${levelId}`, {}, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public postAccountAdminProgramDetails(programDetails: ProgramDetails): Observable<ProgramDetails> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<ProgramDetails>(`${environment.api.accountAdminProgram}/details`, programDetails, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public deleteAccountAdminProgram(programId:string) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.delete<BaseResponse>(`${environment.api.accountAdminProgram}?programId=${programId}`, { ...options })),
            catchError((error:HttpErrorResponse) => {
                throw error;
            })
        );
    }

    public createAccountAdminProgramDetails(programDetails: ProgramDetails): Observable<ProgramDetails> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<ProgramDetails>(`${environment.api.accountAdminProgram}`, programDetails, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getAccountAdminProgramsLocatorIcon(): Observable<ProgramAdminLocatorIcon> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<ProgramAdminLocatorIcon>(`${environment.api.accountAdminProgramLocatorIcons}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getAccountAdminProgramsManagers(): Observable<ProgramManager[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<ProgramManager[]>(`${environment.api.accountAdminProgram}/managers`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getAccountAdminProgramBrands() {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const url = environment.api.accountAdminProgramBrands;

        return options$.pipe(
            switchMap((options) => this.httpClient.get<Brand[]>(url, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getAccountAdminProgramEligibilityCriteria() {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const url = environment.api.accountAdminProgramEligibilityCriteria;

        return options$.pipe(
            switchMap((options) => this.httpClient.get<EligibilityCriteria[]>(url, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public postCreateEligibilityCriteria(criteriaDetails: UpdateCriteriaData): Observable<EligibilityCriteria> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const url = environment.api.accountAdminProgramCriteria;

        return options$.pipe(
            switchMap((options) => this.httpClient.post<EligibilityCriteria>(`${url}`, criteriaDetails, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getAccountAdminProgramCriteria(criteriaId: number) {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const url = environment.api.accountAdminProgramCriteria;

        return options$.pipe(
            switchMap((options) => this.httpClient.get<EligibilityCriteria>(`${url}?criteriaId=${criteriaId}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public postUpdateEligibilityCriteria(criteriaDetails: UpdateCriteriaData): Observable<EligibilityCriteria> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const url = environment.api.accountAdminProgramEligibilityCriteria;

        return options$.pipe(
            switchMap((options) => this.httpClient.post<EligibilityCriteria>(`${url}`, criteriaDetails, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getAccountAdminCompanyDealerLocatorBrands(companyId: string) {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const params = new HttpParams({ fromString: `hvacCompanyId=${companyId}` });
        const url = environment.api.accountAdminCompanyDealerLocatorBrands;

        return options$.pipe(
            switchMap((options) => this.httpClient.get<string[]>(url, {
                params,
                ...options
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public updatedCompanyInformation(company: Company, companyDetailsForm: UntypedFormGroup, isSoldToRequiredInEdit$: BehaviorSubject<boolean>): Observable<HttpResponse<string>> | undefined {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        if (isSoldToRequiredInEdit$.value && !company.soldToCustomerId) {
            companyDetailsForm.controls.soldTo.markAsDirty();
            this.toastService.add({
                content: this.translate.instant('ACCOUNT_ADMIN.COMPANY_CREATE.VALIDATION_ERROR'),
                id: 'CompanyEdit',
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        }
        else {
            return options$.pipe(
                switchMap((options: ApiOptions) => this.httpClient.post<HttpResponse<string>>(`${environment.api.accountAdminCompanyById}`, company, { ...options })),
                catchError((err: HttpErrorResponse) => {
                    this.toastService.add({
                        content: err.error.message,
                        id: 'CompanyEdit',
                        theme: 'error',
                        closeable: true,
                        autoClose: true
                    });
                    throw err;
                })
            );
        }

        return;
    }

    public exportAssociationdetails(companyId: string) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<string>(`${environment.api.accountAdminAssociationExportApi}?hvacCompanyID=${companyId}`, {
                ...options,
                observe: 'response',
                responseType: 'blob' as 'json'
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    private getCompanyServicesByCompanyType(companyType: string) {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const params = new HttpParams({ fromString: `companyType=${companyType}` });
        const url = environment.api.accountAdminCompanyServices;

        return options$.pipe(
            switchMap((options) => this.httpClient.get<CompanyServices[]>(url, {
                params,
                ...options
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    private getEnrollments(id: string): Observable<DealerLocatorEnrollmentList> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<DealerLocatorEnrollmentList>(`${environment.api.dealerLocatorEnrollments}${id}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    private prepareCompanyInformation(res: [Company, DealerLocatorEnrollmentList]): Company {
        const [
            company,
            enrollment
        ] = res;
        company.dealerLocatorBrands = enrollment.dealerLocatorEnrollments.map((enrollmentBrands) => `${enrollmentBrands.dealerBrandLocatorNm}`)
            .join(', ');

        return company;
    }
}
