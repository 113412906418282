import { Injectable } from '@angular/core';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { AccountAdminFormResultMap, Company, CompanyCategory, ParentCompany } from 'private/app/models/account-admin-search';
import { BehaviorSubject } from 'rxjs';
import { AccountAdminApiService } from './account-admin-api.service';
import { coordinateValidator } from './account-admin-edit-form.service';
import { AccountAdminUtilsService, LATITUDE, LONGITUDE } from './account-admin-utils.service';
import { AccountAdminService } from './account-admin.service';
import { CompanyQueryService } from './company-query.service';
import { Option } from '../../../../common/components/select/select.component';
import { AccountAdminFormFieldService } from './account-admin-form-field.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AddressLookup } from 'private/app/components/address-lookup/address-lookup.component';
import { TranslateService } from '@ngx-translate/core';
import { CompanyCategoryType } from 'private/app/views/account-admin-company-details/company-query/company-query-configuration';
import { DuplicateCompanyLookup } from 'private/app/components/duplicate-company-check/duplicate-company-check.component';

export enum ContactType {
    RSM = 'Regional Sales Manager',
    PRSM = 'Parts Regional Sales Manager',
    AM = 'Account Manager',
    PTAM = 'Parts Account Manager',
    TSM = 'Technical Service Manager (TSM)',
    PA = 'Pricing Analyst'
}

export enum CountryForMasking {
    US = 'US',
    CA = 'CA',
    USA = 'USA - US',
    CANADA = 'Canada - CA'
}
export interface CompanyDesignation {
    companyCategory: string;
    companyType: string;
}

@Injectable({ providedIn: 'root' })
export class AccountAdminCompanyCreateService {
    public companyCreateForm = this.accountAdminFormFieldService.companyCreateForm;
    public companyCategory: CompanyCategory[] = [];
    public designation$: BehaviorSubject<CompanyDesignation> = new BehaviorSubject<CompanyDesignation>({
        companyCategory: '',
        companyType: ''
    });

    public locationMandatory$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public countryOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public stateOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public idmRoles$: BehaviorSubject<IdmUserRoles> = new BehaviorSubject<IdmUserRoles>(IdmUserRoles.USERADMIN);
    public servicesOptionData: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public languageOptions = this.accountAdminUtilsService?.getLanguageOptions() || [];
    public hoursOfOperation = this.companyQuery?.hoursOfOperation;
    public selectedHoursOfOperarion: string = '';
    public parentCompanyData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);

    public formData: BehaviorSubject<AccountAdminFormResultMap> = new BehaviorSubject<AccountAdminFormResultMap>({
        legalEntity: [],
        companyTypes: [],
        contactTypes: [],
        relationshipTypes: [],
        accountManagers: []
    });

    public breadCrumbData = {
        Content: { title: this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY') },
        breadCrumbs: [
            {
                title: this.translate.instant('ACCOUNT_ADMIN.PARENT_1'),
                url: '/admin'
            },
            {
                title: this.translate.instant('ACCOUNT_ADMIN.PARENT_2'),
                url: '/admin/admin-tools'
            },
            {
                title: this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY'),
                url: 'Admin/Admin-Tools/create-company'
            }
        ]
    };

    public company: Company = this.accountAdminFormFieldService.emptyCompanyInitialization;

    constructor(
        public accountAdminService: AccountAdminService,
        private readonly translate: TranslateService,
        private readonly accountAdminUtilsService: AccountAdminUtilsService,
        private readonly companyQuery: CompanyQueryService,
        private readonly accountAdminApiService: AccountAdminApiService,
        private readonly accountAdminFormFieldService: AccountAdminFormFieldService

    ) {
        this.accountAdminService.getAccountAdminSearchForm({ excludeParts: true }).subscribe((data: AccountAdminFormResultMap) => {
            this.formData.next(data);
            this.companyCategory = data.companyTypes;
        });
    }

    public get contactType(): typeof ContactType {
        return ContactType;
    }

    initializeCompany() {
        this.company = this.accountAdminFormFieldService.emptyCompanyInitialization;
        this.company.companyServices = [];
        this.company.companyContacts = [];
        this.company.brands = [];
        this.company.language = [];
        this.company.brandedRelationships = [];
        this.company.pricingWarrantyRelationships = [];
    }

    setHoursOfOperation(event: Event, hoursOfOperation: string) {
        const input = <HTMLInputElement>event.target;
        if (input.checked) {
            this.selectedHoursOfOperarion = hoursOfOperation;
            this.company.operatingTime = hoursOfOperation;
        }
    }

    fetchParentCompany(category: string) {
        this.companyQuery.getParentCompanyByCategoryType(category).subscribe((parentCompany: ParentCompany[]) => {
            this.parentCompanyData$.next(parentCompany?.map((company: ParentCompany) => ({
                value: company.id,
                name: company.name
            })).slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase())));
        });
    }

    fetchCompanyServiceData(companyType: string) {
        this.accountAdminApiService.getAccountAdminCompanyServices(companyType, this.servicesOptionData);
    }

    onIsPrimaryChange(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input) {
            this.company.isPrimaryAccount = input.checked;
        }
    }

    onIsAccountAssignableChange(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input) {
            this.company.isAssignable = input.checked;
        }
    }

    onflagForPOS(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input) {
            this.company.flagForPOS = input.checked;
        }
    }

    onflagForDC(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input) {
            this.company.flagForDC = input.checked;
        }
    }

    onShowOnDistributorLocator(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input) {
            this.company.includeInDistributorLocator = input.checked;
        }
        this.locationMandatory$.next(input.checked);
        input.checked ? this.companyCreateForm.controls.latitude.addValidators([Validators.required, coordinateValidator(LATITUDE, -LATITUDE, true)]) : this.companyCreateForm.controls.latitude.setValidators([coordinateValidator(LATITUDE, -LATITUDE, false)]);
        input.checked ? this.companyCreateForm.controls.longitude.addValidators([Validators.required, coordinateValidator(LONGITUDE, -LONGITUDE, true)]) : this.companyCreateForm.controls.longitude.setValidators([coordinateValidator(LONGITUDE, -LONGITUDE, false)]);
        this.companyCreateForm.controls.latitude.updateValueAndValidity();
        this.companyCreateForm.controls.longitude.updateValueAndValidity();
    }

    public getCompanyData() {
        this.company.warrantyType = this.companyQuery.claimTypeOptions.find((option) => option.name === this.company.warrantyType)?.value || '';
        this.company.phoneNumber = (this.company.phoneNumber) ? this.company.phoneNumber.replace(/\D/g, '') : null;
        this.company.mobileNum = (this.company.mobileNum) ? Number(this.company.mobileNum.toString().replace(/\D/g, '')) : null;
        this.company.faxNumber = (this.company.faxNumber) ? Number(this.company.faxNumber.toString().replace(/\D/g, '')) : null;
        this.company.tollFreeNumber = (this.company.tollFreeNumber) ? Number(this.company.tollFreeNumber.toString().replace(/\D/g, '')) : null;

        return this.company;
    }

    companyAddressToValidate(companyCreateForm: UntypedFormGroup): AddressLookup {
        const form = companyCreateForm.controls;

        return {
            address1: form.address1.value,
            address2: form.address2.value,
            city: form.city.value,
            state: {
                label: form.state.value[0].name ?? form.state.value,
                code: form.state.value[0].value ?? form.state.value
            },
            country: {
                label: form.country.value[0].name ?? form.country.value,
                code: form.country.value[0].value ?? form.country.value
            },
            postalCode: form.postalCode.value
        };
    }

    duplicateCompanyToBeValidate(companyCreateForm: UntypedFormGroup): DuplicateCompanyLookup {
        const form = companyCreateForm.controls;

        return {
            companyType: [this.designation$.value.companyType],
            companyName: form.companyName.value,
            address1: form.address1.value,
            city: form.city.value,
            state: {
                label: form.state.value[0].name ?? form.state.value,
                code: form.state.value[0].value ?? form.state.value
            },
            country: {
                label: form.country.value[0].name ?? form.country.value,
                code: form.country.value[0].value ?? form.country.value
            },
            postalCode: form.postalCode.value
        };
    }

    onAddressConfirmation(address: AddressLookup): void {
        this.companyCreateForm.controls.address1.setValue(address.address1, { emitEvent: false });
        this.companyCreateForm.controls.address2.setValue(address.address2, { emitEvent: false });
        this.companyCreateForm.controls.city.setValue(address.city, { emitEvent: false });
        this.companyCreateForm.controls.state.setValue(address.state.label, { emitEvent: false });
        this.companyCreateForm.controls.country.setValue(address.country.code, { emitEvent: false });
        this.companyCreateForm.controls.postalCode.setValue(address.postalCode, { emitEvent: false });
        this.company.country = address.country.code;
        this.company.state = address.state.code;
    }

    onDuplicateCompanyConfirmation(duplicateCompany: DuplicateCompanyLookup): void {
        this.companyCreateForm.controls.companyType.setValue(duplicateCompany.companyType, { emitEvent: false });
        this.companyCreateForm.controls.companyName.setValue(duplicateCompany.companyName, { emitEvent: false });
        this.companyCreateForm.controls.companyId.setValue(duplicateCompany.companyId, { emitEvent: false });
        this.companyCreateForm.controls.address1.setValue(duplicateCompany.address1, { emitEvent: false });
        this.companyCreateForm.controls.address2.setValue(duplicateCompany.address2, { emitEvent: false });
        this.companyCreateForm.controls.city.setValue(duplicateCompany.city, { emitEvent: false });
        this.companyCreateForm.controls.state.setValue(duplicateCompany.state?.code, { emitEvent: false });
        this.companyCreateForm.controls.country.setValue(duplicateCompany.country?.code, { emitEvent: false });
        this.companyCreateForm.controls.postalCode.setValue(duplicateCompany.postalCode, { emitEvent: false });
        this.company.country = duplicateCompany.country.code;
        this.company.state = duplicateCompany.state.code;
    }

    isCompanyCategoryDistributorType(companyCategory: string): boolean {
        return [CompanyCategoryType.DOMESTIC_DISTRIBUTOR.toString(), CompanyCategoryType.INTERNATIONAL_DISTRIBUTOR.toString(), CompanyCategoryType.MARINE_DISTRIBUTOR.toString()].includes(companyCategory);
    }

    checkValidation(companyCategory: string, companyType: string) {
        if ((companyCategory === this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.INTERNAL')) || (companyCategory === this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.MARINE_DISTRIBUTOR'))) {
            this.companyCreateForm.controls.services.clearValidators();
            this.companyCreateForm.controls.brands.setValidators([Validators.required]);
        }
        else {
            this.companyCreateForm.controls.services.setValidators([Validators.required]);
        }
        if ((companyCategory === this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.HVAC_CUSTOMER')) || (companyCategory === this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.INTERNATIONAL_CUSTOMER')) || (companyCategory === this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.MARINE_CUSTOMER')) || (companyCategory === this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.NATIONAL_ACCOUNT')) || (companyCategory === this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.VENDOR'))) {
            this.companyCreateForm.controls.services.clearValidators();
            this.companyCreateForm.controls.brands.clearValidators();
            if (companyType === this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.DEALER_CONTRACTOR')) {
                this.companyCreateForm.controls.services.setValidators([Validators.required]);
            }
            else {
                this.companyCreateForm.controls.services.clearValidators();
            }
        }

        if ((companyCategory === this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.INTERNATIONAL_DISTRIBUTOR')) || (companyCategory === this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.DOMESTIC_DISTRIBUTOR'))) {
            this.companyCreateForm.controls.services.setValidators([Validators.required]);
            this.companyCreateForm.controls.brands.setValidators([Validators.required]);
        }

        this.companyCreateForm.controls.services.valueChanges.subscribe((value) => {
            if (value.length > 0 && value[0].name === this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.WARRANTY_DISTRIBUTOR')) {
                this.companyCreateForm.controls.claimType.setValidators([Validators.required]);
            }
            else {
                this.companyCreateForm.controls.claimType.clearValidators();
            }
        });
        this.companyCreateForm.controls.services.updateValueAndValidity();
        this.companyCreateForm.controls.brands.updateValueAndValidity();
        this.companyCreateForm.controls.claimType.updateValueAndValidity();
    }

    resetDesignation() {
        this.designation$.next({
            companyCategory: '',
            companyType: ''
        });
    }
}
