import { Component, Output, OnInit, ViewEncapsulation, EventEmitter, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AccountAdminService } from '../../services/account-admin/account-admin.service';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'common/components/select/select.component';
import { environment } from 'common/environments/environment';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { IdToken } from 'common/models';
import { OktaService } from 'common/services/okta/okta.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from 'common/components/base/base.component';
import { AccountAdminFormResultMap } from 'private/app/models/account-admin-search';
import { AccountPostAdminService } from 'private/app/services/account-admin/account-post-admin.service';
import { FilteredOption } from 'private/app/models/distributor-relationships.model';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
import { ToastService } from 'common/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountAdminUtilsService } from 'private/app/services/account-admin/account-admin-utils.service';

export interface AllCountries{
    name: string
    value: string | null
}

@Component({
    selector: 'hvac-create-user-company-search-form',
    templateUrl: './create-user-company-search-form.component.html',
    styleUrls: ['./create-user-company-search-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreateUserCompanySearchFormComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedCompanyTypeName?: string;
  @Input() selectedCompanyTypeCode?: string;
  @Input() isCompanyTypeReadOnlyField?: boolean;
  @Input() clickClearForm?: () => void;
  @Output() onClickSearch = new EventEmitter();

  public formData?: AccountAdminFormResultMap;
  public showDealerSearchCriteriaCIN = environment.features.dealerSearchCriteriaCIN;
  public showRelationshipType = environment.features.relationshipType;
  public companyTypes: Option[];
  public isUserFeatureAvailable = environment.features.accountAdminUser;
  public isCreateUserFeatureAvailable = environment.features.accountAdminCreateUser;
  public adminAccess: boolean;
  public isNonInternalDistributor$ = new BehaviorSubject<boolean>(false);
  public countryList$: Observable<Option[]>;
  public stateList$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
  public isLoading: boolean = false;
  public isStateDisabled: boolean = true;

  constructor(
      public accountAdminService: AccountAdminService,
      public accountPostAdminService: AccountPostAdminService,
      public accountAdminUtilsService: AccountAdminUtilsService,
      private translate: TranslateService,
      private route: ActivatedRoute,
      private oktaService: OktaService,
      private commonUtils: CommonUtilsService,
      private toastService: ToastService
  ) {
      super();
  }

  ngOnInit(): void {
      this.isLoading = true;
      this.oktaService.idToken$.subscribe((res: IdToken) => {
          this.isNonInternalDistributor$.next(this.isNonInternalDistributor(res?.claims?.idm_user_roles || []));
      });
      this.adminAccess = (this.route.snapshot.data['adminAccess'] as IdmUserRoles[])?.includes(IdmUserRoles.ACCOUNTADMIN);
      this.accountAdminService.getAccountAdminSearchForm().subscribe(
          (data: AccountAdminFormResultMap) => {
              data.brands = this.filterBrands(data.brands!);
              this.companyTypes = data.companyTypes.map(({ companyTypes }) => companyTypes).flat().map((companyType) => ({
                  name: companyType?.name!,
                  value: companyType?.code!
              })).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

              this.companyTypes.unshift({
                  name: this.translate.instant('ACCOUNT_ADMIN.SELECT_COMPANY_TYPE'),
                  value: ''
              });
              this.formData = data;
              if (this.isCompanyTypeReadOnlyField) {
                  this.accountAdminService.advancedSearchForm.controls.companyType.setValue([{
                      name: this.selectedCompanyTypeName,
                      value: this.selectedCompanyTypeCode
                  }]);
                  this.accountAdminService.advancedSearchForm.controls.readonlyCompanyType.setValue([{
                      name: this.selectedCompanyTypeName,
                      value: this.selectedCompanyTypeCode
                  }]);
              }
              this.isLoading = false;
          }, (error: HttpErrorResponse) => {
              this.toastService.raiseDefaultErrorToast('CreateUserCompanySearchFormComponent-ngOnInit', error.message);
              this.isLoading = false;
          }
      );
      this.countryList$ = this.commonUtils.getCountryOptions().pipe(
          map((countries) => this.reprioritizeCountries(countries))
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (this.isCompanyTypeReadOnlyField) {
          if (changes.selectedCompanyTypeCode && changes.selectedCompanyTypeName) {
              this.accountAdminService.advancedSearchForm.controls.companyType.setValue([{
                  name: changes.selectedCompanyTypeName.currentValue,
                  value: changes.selectedCompanyTypeCode.currentValue
              }]);
              this.accountAdminService.advancedSearchForm.controls.readonlyCompanyType.setValue([{
                  name: changes.selectedCompanyTypeName.currentValue,
                  value: changes.selectedCompanyTypeCode.currentValue
              }]);
          }
      }
  }

  ngOnDestroy(): void {
      if (this.isCompanyTypeReadOnlyField) {
          this.accountAdminService.advancedSearchForm.controls.companyType.setValue('');
      }
  }

  public onSubmit(event: UntypedFormGroup) {
      this.accountAdminUtilsService.clearAllButtonClicked(true);
      this.accountAdminService.lastQueryParams.next(this.accountAdminService.createQueryParams(event));
      this.onClickSearch.next({ queryParams: this.accountAdminService.lastQueryParams.value });
      this.accountAdminUtilsService.clearAllButtonClicked(false);
  }

  clearFilters() {
      this.accountAdminUtilsService.clearAllButtonClicked(true);
      if (this.clickClearForm) {
          this.clickClearForm();
      }
      else {
          this.accountAdminService.advancedSearchForm.reset(this.accountAdminService.advancedSearchFormDefault.value);
      }
  }

  reprioritizeState(options: Option[]) {
      const filteredOptions = options.filter((item: Option) => item.value !== '');
      filteredOptions.unshift({
          name: this.translate.instant('ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.STATE'),
          value: ''
      });

      return filteredOptions;
  }

  reprioritizeCountries(options: Option[]) {
      const filteredOptions = options.filter((item: Option) => item.value !== 'MX' && item.value !== 'US' && item.value !== 'CA' && item.value !== '');
      filteredOptions.unshift({
          name: 'Mexico',
          value: 'MX'
      });
      filteredOptions.unshift({
          name: 'Canada',
          value: 'CA'
      });
      filteredOptions.unshift({
          name: 'USA',
          value: 'US'
      });
      filteredOptions.unshift({
          name: this.translate.instant('ACCOUNT_ADMIN.SELECT_COUNTRY'),
          value: ''
      });

      return filteredOptions;
  }

  filterBrands(options: FilteredOption[]): FilteredOption[] {
      return options.filter((item: Option) => (item.usageType && item.usageType.includes('PRODUCTS')) || item.name === this.translate.instant('ACCOUNT_ADMIN.SELECT_BRAND'));
  }

  isNonInternalDistributor(idmUserRoles: string[]) {
      return !idmUserRoles?.includes(IdmUserRoles.DEFAULT_INTERNAL) && (idmUserRoles?.includes(IdmUserRoles.COMPANY_TYPE_INTERNATIONAL_DISTRIBUTOR) || idmUserRoles?.includes(IdmUserRoles.COMPANY_TYPE_DOMESTIC_DISTRIBUTOR) || idmUserRoles?.includes(IdmUserRoles.COMPANY_TYPE_MARINE_DISTRIBUTOR));
  }

  getStateListByCountry(selectedCountry: string) {
      this.isLoading = true;
      this.commonUtils.getStateByCountry(selectedCountry).pipe(
          map((stateList) => this.reprioritizeState(stateList)),
          takeUntil(this.ngOnDestroy$),
          tap((stateList) => {
              this.isLoading = false;
              this.stateList$.next(stateList);
              this.isStateDisabled = false;
          })
      ).subscribe();
  }

  onCountryChange(selectedCountries: Option[]) {
      const selectedCountryOption = selectedCountries.length ? selectedCountries[0] : null;

      if (selectedCountryOption && selectedCountryOption.value) {
          this.stateList$.next([]);
          this.accountAdminService.advancedSearchForm.controls.region.setValue('');
          this.getStateListByCountry(selectedCountryOption.value);
      }
      else {
          this.isStateDisabled = true;
          this.stateList$.next([]);
          this.accountAdminService.advancedSearchForm.controls.region.setValue('');
      }
  }

  onStateChange(selectedState: Option[]) {
      if (selectedState.length && selectedState[0].value === '') {
          this.accountAdminService.advancedSearchForm.controls.region.setValue('');
      }
  }
}
