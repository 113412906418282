import { AccountAdminDetailsEditConfiguration, CompanyCategoryType } from './company-query-configuration';

export const DomesticDistributorConfig = {
    configType: CompanyCategoryType.DOMESTIC_DISTRIBUTOR,
    association: {
        associatedDistributor: false,
        associatedContact: true,
        associatedDealer: true,
        addContact: true
    },
    formConfig: {
        companyName: {
            view: true,
            edit: true
        },
        companyType: { edit: false },
        parentCompany: {
            view: true,
            edit: true
        },
        companyUrl: {
            view: true,
            edit: true
        },
        services: {
            view: false,
            edit: false
        },
        claimType: {
            view: false,
            edit: false
        },
        brands: {
            view: true,
            edit: true
        },
        shipToNumber: {
            view: true,
            edit: true
        },
        soldTo: {
            view: true,
            edit: false
        },
        priceGroup: {
            view: true,
            edit: true
        },
        priceList: {
            view: true,
            edit: true
        },
        payer: {
            view: true,
            edit: true
        },
        address1: {
            view: true,
            edit: true
        },
        address2: {
            view: true,
            edit: true
        },
        country: {
            view: true,
            edit: true
        },
        state: {
            view: true,
            edit: true
        },
        city: {
            view: true,
            edit: true
        },
        postalCode: {
            view: true,
            edit: true
        },
        phone: {
            view: true,
            edit: true
        },
        mobile: {
            view: true,
            edit: true
        },
        tollFree: {
            view: true,
            edit: true
        },
        fax: {
            view: true,
            edit: true
        },
        email: {
            view: true,
            edit: true
        },
        rsm: {
            view: true,
            edit: true
        },
        prsm: {
            view: true,
            edit: true
        },
        accountManager: {
            view: true,
            edit: true
        },
        partAccountManager: {
            view: true,
            edit: true
        },
        tsm: {
            view: true,
            edit: true
        },
        pricingAnalyst: {
            view: true,
            edit: true
        },
        c10Parent: {
            view: true,
            edit: true
        },
        c10Child: {
            view: true,
            edit: true
        },
        storeNumber: {
            view: true,
            edit: true
        },
        flagForPOS: {
            view: true,
            edit: true
        },
        flagForDC: {
            view: true,
            edit: true
        },
        legalEntity: {
            view: true,
            edit: true
        },
        divRegNumber: {
            view: true,
            edit: true
        },
        searchRadius: {
            view: true,
            edit: true
        },
        ownership: {
            view: false,
            edit: false
        },
        isPrimary: {
            view: true,
            edit: true
        },
        isAssignable: {
            view: true,
            edit: true
        },
        showOnDistributorLocator: {
            view: true,
            edit: true
        },
        latitude: {
            view: true,
            edit: true
        },
        longitude: {
            view: true,
            edit: true
        },
        locatorName: {
            view: true,
            edit: true
        },
        notes: {
            view: true,
            edit: true
        }
    },
    managerInformation: {
        show: true,
        partRSM: {
            view: true,
            edit: false
        },
        partAM: {
            view: true,
            edit: false
        }
    },
    addBrands: true
} as AccountAdminDetailsEditConfiguration;
