import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Link } from 'common/models/link';
import { ConnectedProductUpdate, ConnectedProductDescriptor, ConnectedProductId } from 'private/app/models/connected-product.model';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { RouterLinkWithHref } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'hvac-connected-portal-system-product-card',
    templateUrl: './connected-portal-system-product-card.component.html',
    styleUrls: ['./connected-portal-system-product-card.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, NgClass, NgForOf, NgIf, RouterLinkWithHref, TranslateModule]
})
export class ConnectedPortalSystemProductCardComponent {
    @Input() image: string = '';
    @Input() name: string;
    @Input() showFaultIndicator? = false;
    @Input() descriptors: ConnectedProductDescriptor[];
    @Input() productLink?: Link;
    @Input() systemType?: string;
    @Input() type?: string;
    @Input() isUpdateEnabled?: boolean;
    @Input() noDataMessage?: string;
    @Input() systemId: string;
    @Input() showDescriptor?: boolean;
    @Output() onUpdateClick = new EventEmitter<ConnectedProductUpdate>();

    updateData() {
        /* eslint-disable no-param-reassign */
        const currentDescriptorData = this.descriptors.reduce((acc, currDescriptor) => {
            switch (currDescriptor.id) {
                case ConnectedProductId.ModelNo:
                    acc = {
                        ...acc,
                        ...{ modelNo: currDescriptor.value }
                    };
                    break;
                case ConnectedProductId.SerialNo:
                    acc = {
                        ...acc,
                        ...{ serialNo: currDescriptor.value }
                    };
                    break;
                default:
                    break;
            }

            return acc;
        }, {} as {[key in ConnectedProductId]: string});

        const currentData: ConnectedProductUpdate = {
            systemId: this.systemId,
            productType: this.type as string,
            data: currentDescriptorData
        };

        this.onUpdateClick.emit(currentData);
    }
}
