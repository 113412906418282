import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptions, ApiOptionsService } from 'common/services/api-options/api-options.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AccountAdminBrandsItemsEntity, AccountAdminContactType, AccountAdminManagersEntity, HeirarchyBrands, RelationshipType, Company } from 'private/app/models/account-admin-search';
import { environment } from 'common/environments/environment';
import { CompanyCategoryResult } from './account-admin-utils.service';
import { LegalEntityResponse } from 'private/app/models/account-admin-search-results';
import { AccountAdminProgramEnrollments } from 'private/app/models/account-admin-program.model';
import { BrandValidationResponse } from 'private/app/models/distributor-relationships.model';

@Injectable({ providedIn: 'root' })
export class AccountAdminExtendedService {
    public programEnrollmentsBackAction = new BehaviorSubject(false);
    private companyTypeOptionsCache: CompanyCategoryResult[] | undefined;
    private contactTypeOptionsCache: AccountAdminContactType[] | undefined;
    private managersListCache: AccountAdminManagersEntity | undefined;
    private relationshipTypeOptionsCache: RelationshipType[] | undefined;
    private legalEntityOptionsCache: LegalEntityResponse[] | undefined;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) {
    }

    public getCompanyTypes(): Observable<CompanyCategoryResult[]> {
        if (this.companyTypeOptionsCache) {
            return of(this.companyTypeOptionsCache);
        }

        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<CompanyCategoryResult[]>(environment.api.accountAdminCompanyTypes, { ...options })),
            tap((companyTypes) => this.companyTypeOptionsCache = companyTypes),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getBrands(isInternalUser?: boolean): Observable<AccountAdminBrandsItemsEntity[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const fullFetch = (isInternalUser) ? `${environment.api.accountAdminBrands}?full-fetch=true` : environment.api.accountAdminBrands;

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<AccountAdminBrandsItemsEntity[]>(fullFetch, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getContactTypes(): Observable<AccountAdminContactType[]> {
        if (this.contactTypeOptionsCache) {
            return of(this.contactTypeOptionsCache);
        }

        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<AccountAdminContactType[]>(environment.api.accountAdminContactTypes, { ...options })),
            tap((contactTypes) => this.contactTypeOptionsCache = contactTypes),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getManagers(): Observable<AccountAdminManagersEntity> {
        if (this.managersListCache) {
            return of(this.managersListCache);
        }

        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<AccountAdminManagersEntity>(environment.api.accountAdminManagers, { ...options })),
            tap((managers) => this.managersListCache = managers),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getRelationshipTypes(): Observable<RelationshipType[]> {
        if (this.relationshipTypeOptionsCache) {
            return of(this.relationshipTypeOptionsCache);
        }

        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<RelationshipType[]>(environment.api.accountAdminRelationshipTypes, { ...options })),
            tap((relationTypes) => this.relationshipTypeOptionsCache = relationTypes),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public legalEntities(): Observable<LegalEntityResponse[]> {
        if (this.legalEntityOptionsCache) {
            return of(this.legalEntityOptionsCache);
        }

        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<LegalEntityResponse[]>(`${environment.api.accountAdminLegalEntityApi}?locationType=MAIN`, { ...options })),
            tap((legalEntities) => this.legalEntityOptionsCache = legalEntities),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getAssociatedBrands(hvacCompanyId: string): Observable<HeirarchyBrands[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<HeirarchyBrands[]>(`${environment.api.accountAdminAssociatedBrands}?hvacCompanyID=${hvacCompanyId}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getAccountAdminCompanyById(id: string): Observable<Company> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const params = new HttpParams({ fromString: `hvacCompanyID=${id}` });

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<Company>(`${environment.api.accountAdminCompanyById}`, {
                params,
                ...options
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getProgramEnrollments(hvacCompanyId: string): Observable<AccountAdminProgramEnrollments[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<AccountAdminProgramEnrollments[]>(`${environment.api.accountAdminProgramEnrollmentDetails}?hvacCompanyId=${hvacCompanyId}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }


    public exportProgramEnrollments(hvacCompanyId: string) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<string>(`${environment.api.accountAdminProgramEnrollmentExport}?hvacCompanyId=${hvacCompanyId}`, {
                ...options,
                observe: 'response',
                responseType: 'blob' as 'json'
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public brandRelationshipValidation(parentHvacCompanyId: string, childHvacCompanyId: string, brandId: string): Observable<BrandValidationResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const params = new HttpParams({ fromString: `?parentCompanyId=${parentHvacCompanyId}&childCompanyId=${childHvacCompanyId}&brandId=${brandId}` });

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<BrandValidationResponse>(`${environment.api.brandRelationshipValidation}`, {
                params,
                ...options
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public exportCounties(companyId: number) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<string>(`${environment.api.exportCounties}?hvacCompanyID=${companyId}`, {
                ...options,
                observe: 'response',
                responseType: 'blob' as 'json'
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }
}
