        <ng-template [ngIf]="!isLoading" [ngIfElse]="spinner">
    <section class="hvac-program-admin-container">
        <div class="hvac-program-header">
            <h1 class="hvac-h3">
                {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_INFORMATION' | translate }}
            </h1>
            <div>
                <a *ngIf="!programAdminFullAccess" [routerLink]="['/Admin/Program/search-program/search']" class="hvac-text-link">{{ 'ACCOUNT_ADMIN.BACK_TO_SEARCH' | translate}}</a>
                <p class="hvac-p-sm"><abbr title="required"
                        class="hvac-program-header-required">*</abbr>{{"ACCOUNT_ADMIN.PROGRAMS.REQUIRED_INFORMATION" |
                    translate }}</p>
            </div>
        </div>
        <div class="hvac-program-admin-form" *ngIf="programAdminData">
            <form [formGroup]="programEditForm">
                <div class="hvac-row">
                    <div class="hvac-col-xs-12 hvac-col-lg-6">
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.PROGRAM_NAME" | translate }}
                            </div>
                            <utc-input class="hvac-col-xs-12 hvac-col-lg-8" [disabledInput]="accountAdminProgramService.isEditProgram || !programAdminFullAccess"
                                shadow="none" size="small"
                                [hideLabel]="false" [inputControl]="programEditForm.controls.name" type="text">
                            </utc-input>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.PROGRAM_CODE" | translate }}
                            </div>
                            <utc-input class="hvac-col-xs-12 hvac-col-lg-8" [disabledInput]="accountAdminProgramService.isEditProgram || !programAdminFullAccess"
                                [error]="programEditForm.controls.code.dirty && !programEditForm.controls.code.valid"
                                [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.PLACEHOLDER_TEXT.PROGRAM_CODE_XXXX' | translate"
                                [errorMessage]="programEditForm.controls.code.hasError('specialCharProhibitError') ? ('ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.PROGRAM_CODE_SPECIAL_CHAR_PROHIBIT' | translate) : 
                                ('ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.PROGRAM_CODE_LENGTH_ERROR' | translate)"
                                shadow="none" size="small"
                                [hideLabel]="false" [inputControl]="programEditForm.controls.code" type="text">
                            </utc-input>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.PROGRAM_PERIOD" | translate }}
                            </div>
                            <div class="hvac-col-xs-12 hvac-col-lg-8">
                                <hvac-date-range-picker
                                    [startDate]="programAdminData.programStart"
                                    [endDate]="programAdminData.programEnd"
                                    [error]="programPeriodErrorValidationResult.genericErrorMessage"
                                    [startDateFieldError]="programPeriodErrorValidationResult.startDateFieldErrorMessage"
                                    [endDateFieldError]="programPeriodErrorValidationResult.endDateFieldErrorMessage"
                                    [disabled]="!programAdminFullAccess"
                                    [disableLastValidDateRestore]="true"
                                    [isRequired]="true"
                                    [theme]="'retro'"
                                    [controls]="programDateControls"
                                    [fieldNameOrId]="programPeriodErrorValidationResult.fieldName"
                                    (onErrorResult)="onDateError($event)"
                                    (onSelection)="setProgramPeriod({ startDate: $event.startDate, endDate: $event.endDate })">
                                </hvac-date-range-picker>
                            </div>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_PERIOD" | translate }}
                            </div>
                            <hvac-date-range-picker class="hvac-col-xs-12 hvac-col-lg-8"
                                [startDate]="dateToTimeStamp(programAdminData.programDetails.enrollmentStartDate, { formatTimeAs: 'StartOfDay'})"
                                [endDate]="dateToTimeStamp(programAdminData.programDetails.enrollmentEndDate, { formatTimeAs: 'EndOfDay'})"
                                [error]="enrollmentPeriodErrorValidationResult.genericErrorMessage"
                                [startDateFieldError]="enrollmentPeriodErrorValidationResult.startDateFieldErrorMessage"
                                [endDateFieldError]="enrollmentPeriodErrorValidationResult.endDateFieldErrorMessage"
                                [disableLastValidDateRestore]="true"
                                [isRequired]="true"
                                [theme]="'retro'"
                                [disabled]="!programAdminFullAccess"
                                [controls]="enrollmentDateControls"
                                [fieldNameOrId]="enrollmentPeriodErrorValidationResult.fieldName"
                                (onErrorResult)="onDateError($event)"
                                (onSelection)="setEnrollmentPeriod({ startDate: $event.startDate, endDate: $event.endDate })" >
                            </hvac-date-range-picker>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.DESCRIPTION" | translate }}
                            </div>
                            <utc-textarea class="hvac-col-xs-12 hvac-col-lg-8 hvac-distributor-comments-input" shadow="none"
                                [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.PLACEHOLDER_TEXT.PROGRAM_DESCRIPTION' | translate"
                                [disabledInput]="!programAdminFullAccess"
                                [maxLength]="maxProgramDescriptionLimit"
                                [inputControl]="programEditForm.controls.description"></utc-textarea>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.VERTICAL" | translate }}
                            </div>
                            <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                                [selectId]="programAdminData.programDetails.vertical.id.toString() || ''"
                                [error]="programEditForm.controls.vertical.dirty && !programEditForm.controls.vertical.valid"
                                [shadow]="'none'" [group]="programEditForm" control="vertical"
                                shadow="none" size="small"
                                [disabled]="!programAdminFullAccess"
                                [options]="accountAdminProgramService.programVerticalOptions">
                            </utc-select>
                        </div>

                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.BRANDS" | translate }}
                            </div>
                            <utc-select class="hvac-col-xs-12 hvac-col-lg-8" shadow="none" size="small" [group]="programEditForm" [disabled]="accountAdminProgramService.isEditProgram || !programAdminFullAccess"
                                control="brands" [options]="(brandOptionData$ | async) || []">
                            </utc-select>
                        </div>
                    </div>
                    <div class="hvac-col-xs-12 hvac-col-lg-6">
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.PROGRAM_MANAGER" | translate }}
                            </div>
                            <div class="hvac-col-xs-12 hvac-col-lg-8">
                                <div class="hvac-program-manager-container">
                                    <hvac-chip [disabled]="!programAdminFullAccess"
                                        *ngFor="let programManager of programAdminData?.programDetails?.programManagers" [text]="programManager.firstName + ' ' + programManager.lastName" [chipId]="programManager.userId"  (onChipClose)="accountAdminProgramService.removeManager($event)"></hvac-chip>
                                </div>
                                <button *ngIf="programAdminFullAccess" hvacButton="text" (click)="accountAdminProgramService.addManagerPopUp()"
                                    class="hvac-program-add-manager">{{
                                    'ACCOUNT_ADMIN.PROGRAMS.ADD_PROGRAM_MANAGER' | translate }}</button>
                            </div>
                        </div>

                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.ELIGIBILITY_CRITERIA" | translate }}
                            </div>
                            <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                                [selectId]="programAdminData.programDetails.eligibilityCriteria.id.toString() || ''"
                                [error]="programEditForm.controls.eligibilityCriteria.value[0]?.value?.length === 0 || programEditForm.controls.eligibilityCriteria.dirty && !programEditForm.controls.eligibilityCriteria.valid"
                                shadow="none" size="small" [group]="programEditForm" control="eligibilityCriteria"
                                [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.SELECT_ELIGIBILTY_CRITERIA' | translate"
                                [disabled]="!programAdminFullAccess"
                                [options]="programAdminData.criteria || []">
                            </utc-select>
                        </div>

                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_LEVEL" | translate }}
                            </div>
                            <utc-checkbox class="hvac-col-xs-12 hvac-col-lg-8"
                                [checked]="programAdminData.programDetails.enrollmentLevel"
                                [inputId]="'enrollmentLevel'" [value]="'enrollmentLevel'"
                                [disabled]="!programAdminFullAccess"
                                [inputControl]="programEditForm.controls.enrollmentLevel"
                                [inputName]="'enrollmentLevel'"> {{ "ACCOUNT_ADMIN.PROGRAMS.LOCATOR_SUSPEND" | translate
                                }}
                            </utc-checkbox>
                        </div>

                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.LOCATOR" | translate }}
                            </div>
                            <hvac-date-range-picker class="hvac-col-xs-12 hvac-col-lg-8"
                                [startDate]="dateToTimeStamp(programAdminData.programDetails.locatorStartDate, { formatTimeAs: 'StartOfDay'})"
                                [endDate]="dateToTimeStamp(programAdminData.programDetails.locatorEndDate, { formatTimeAs: 'EndOfDay'})"
                                [error]="locatorPeriodErrorValidationResult.genericErrorMessage"
                                [startDateFieldError]="locatorPeriodErrorValidationResult.startDateFieldErrorMessage"
                                [endDateFieldError]="locatorPeriodErrorValidationResult.endDateFieldErrorMessage"
                                [disableLastValidDateRestore]="true"
                                [theme]="'retro'"
                                [disabled]="!programAdminFullAccess"
                                [fieldNameOrId]="locatorPeriodErrorValidationResult.fieldName"
                                [controls]="locatorDateControls"
                                (onErrorResult)="onDateError($event)"
                                (onSelection)="setLocatorPeriod({ startDate: $event.startDate, endDate: $event.endDate })">
                            </hvac-date-range-picker>
                            <p *ngIf="!isLocatorDateSet" class="hvac-locator-date-error">{{ "ACCOUNT_ADMIN.PROGRAMS.ERROR.LOCATOR_EMPTY_DATE_ERROR" | translate }}</p>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.LOCATOR_ICON" | translate }}
                            </div>
                            <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                                [selectId]="programAdminData.programDetails.locatorIcon?.id?.toString() || ''"
                                shadow="none" size="small" [group]="programEditForm" control="locatorIcon"
                                [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.SELECT_ICON' | translate"
                                [disabled]="!programAdminFullAccess"
                                [options]="programAdminData.locatorIcons || []">
                            </utc-select>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.ENTITLEMENT_WORKFLOW" | translate }}
                            </div>
                            <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                                [selectId]="programAdminData.programDetails.enrollmentWorkflow?.id?.toString() || ''"
                                shadow="none" size="small" [group]="programEditForm" control="enrollmentWorkflow"
                                [disabled]="!programAdminFullAccess"
                                [options]="accountAdminProgramService.programEnrollmentWorkflowOptions">
                            </utc-select>
                        </div>
                        <div *ngIf="(accountAdminProgramService.isEnrollmentFormVisible$ | async); else massEnrollment"
                            class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.ENTITLEMENT_FORM" | translate }}
                            </div>
                            <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                                [error]="programEditForm.controls.enrollmentForm.dirty && !programEditForm.controls.enrollmentForm.valid"
                                [selectId]="programAdminData.programDetails.enrollmentForm?.id?.toString() || ''"
                                shadow="none" size="small" [group]="programEditForm" control="enrollmentForm"
                                [disabled]="!programAdminFullAccess"
                                [options]="accountAdminProgramService.programEnrollmentFormOptions">
                            </utc-select>
                        </div>
                        <ng-template #massEnrollment>
                            <div class="hvac-row">
                                <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                    {{ "ACCOUNT_ADMIN.PROGRAMS.MASS_ENROLLMENT" | translate }}
                                </div>
                                <utc-checkbox class="hvac-col-xs-12 hvac-col-lg-8"
                                    [checked]="programAdminData.programDetails.massEnrollFl"
                                    [inputId]="'massEnrollFl'" [value]="'massEnrollFl'"
                                    [inputControl]="programEditForm.controls.massEnrollFl"
                                    [disabled]="!programAdminFullAccess"
                                    [inputName]="'massEnrollFl'">
                                </utc-checkbox>
                            </div>
                            <div class="hvac-row">
                                <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                    {{ "ACCOUNT_ADMIN.PROGRAMS.FINAL_APPROVED_BY" | translate }}
                                </div>
                                <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                                    [selectId]="programAdminData.programDetails.finalApprovedBy?.id?.toString() || ''"
                                    shadow="none" size="small" [group]="programEditForm" control="finalApprovedBy"
                                    [disabled]="!programAdminFullAccess"
                                    [options]="accountAdminProgramService.programFinalApprover">
                                </utc-select>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="programAdminFullAccess" class="hvac-program-edit-footer">
            <button [hvacButton]="'default'"
                (click)="onCancel()">
                <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.CANCEL' | translate }}</span>
            </button>
            <button [hvacButton]="'primary'" 
                [disabled]="(!programEditForm.valid || isRequesting || areDateFieldsInError || !programAdminFullAccess || !isLocatorDateSet || programEditForm.pristine) && accountAdminProgramService.chnagesMadeInProgramManagers"
                (click)="onSubmit(programEditForm)">
                <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.SAVE' | translate }}</span>
            </button>
        </div>
        <section class="hvac-program-manager-model-container">
            <hvac-program-manager [isModalVisible]="accountAdminProgramService.isModalVisible" (isModalVisibleChange)="accountAdminProgramService.modalVisibilityChanged($event)" (onManagerListChange)="accountAdminProgramService.onManagerListChange($event)" [programManagers]="programManagers || []" [assignedManagers]="programAdminData.programDetails.programManagers || []"></hvac-program-manager>
        </section>
    </section>
</ng-template>
<ng-template #spinner>
    <utc-loading-spinner></utc-loading-spinner>
</ng-template>
