import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { httpInterceptorProviders } from 'common/interceptors/interceptor.provider';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { BrowseModule } from 'common/views/browse/browse.module';
import { ContentRoutingModule } from 'common/content/content-routing.module';
import { ContentModule } from 'common/content/content.module';
import { environment } from 'common/environments/environment';
import { provider as oktaProvider } from 'common/services/okta/okta.provider';
import { OktaService } from 'common/services/okta/okta.service';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { AuthState } from 'common/store/auth/auth.state';
import { BrandingState } from 'common/store/branding/branding.state';
import { CatalogState } from 'common/store/catalog/catalog.state';
import { ChatbotState } from 'common/store/chatbot/chatbot.state';
import { DocumentsState } from 'common/store/documents/documents.state';
import { GdprState } from 'common/store/gdpr/gdpr.state';
import { LoginPanelState } from 'common/store/login-panel/login-panel.state';
import { ProductsState } from 'common/store/products/products.state';
import { SearchState } from 'common/store/search/search.state';
import { SiteContentState } from 'common/store/site-content/site-content.state';
import { PartsState } from 'common/store/parts/parts.state';
import { SuggestionsState } from 'common/store/suggestions/suggestions.state';
import { ApiOptionsServicePrivate } from 'private/app/services/api-options/api-options-private.service';
import { SafePipeModule } from 'safe-pipe';
import { ContentState } from '../../common/store/content/content.state';
import { AccountState } from '../../common/store/create-account.state';
import { NavigationState } from '../../common/store/navigation/navigation.state';
import { UiState } from '../../common/store/ui/ui.state';
import { ApprovalRequestsState } from './store/approval-requests.state';
import { BookmarkState } from './store/bookmarks.state';
import { InboxState } from './store/inbox.state';
import { NotificationsState } from './store/notification.state';
import { AccountAdminFormComponent } from './components/account-admin-form/account-admin-form.component';
import { ComponentsModule } from './components/components.module';
import { ApprovalRequestsService } from './services/approval-requests.service';
import { CreateEmailValidateModule } from './views/create-email-validate/createEmailvalidate.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticatingModule } from './components/authenticated/authenticating.module';
import { CallbackModule } from './views/callback/callback.module';
import { ChangePasswordModule } from './views/change-password/change-password.module';
import { CreateAccountInformationModule } from './views/create-account-information/createAccountInformation.module';
import { CreateAccountLookupModule } from './views/create-account-lookup/createAccountLookup.module';
import { DashboardModule } from './views/dashboard/dashboard.module';
import { FrameContentModule } from './views/frame-content/frame-content.module';
import { LoginModule } from './views/login/login.module';
import { SignOutModule } from './views/sign-out/signOut.module';
import { TridionContentPageModule } from './views/trid-content-page/trid-content-page.module';
import { AccountAdminCompanyDetailsComponent } from './views/account-admin-company-details/account-admin-company-details.component';
import { AccountAdminContactDetailsComponent } from './views/account-admin-contact-details/account-admin-contact-details.component';
import { AccountAdminResultsComponent } from './views/account-admin-results/account-admin-results.component';
import { CompaniesTableComponent } from './views/account-admin-results/companies-table/companies-table.component';
import { UsersTableComponent } from './views/account-admin-results/users-table/users-table.component';
import { AccountAdminComponent } from './views/account-admin/account-admin.component';
import { ProfileLandingComponent } from './views/profile-landing/profile-landing.component';
import { ProfileComponent } from './views/profile-landing/profile/profile.component';
import { SearchProgramComponent } from './views/program/search-program/search-program.component';
import { CompanyInfoComponent } from './views/profile-landing/company-info/company-info.component';
import { BrandSelectComponent } from './views/brand-select/brand-select.component';
import { CoolCalcComponent } from '../app/views/cool-calc/cool-calc.component';
import { AssociatedDistributorLookup } from 'private/app/components/associated-distributor-lookup/associated-distributor-lookup';
import { AssociatedDistributorsComponent } from './views/account-admin/associated-distributors/associated-distributors.component';
import { AssociatedDealersComponent } from './views/account-admin/associated-dealers/associated-dealers.component';
import { AssociationBaseComponent } from './views/account-admin/association-base/association-base.component';
import { AccountAdminDistributorDetailsComponent } from './views/account-admin-distributer-details/account-admin-distributor-details.component';
import { AssociatedContactsComponent } from './views/account-admin/associated-contacts/associated-contacts.component';
import { ManageSubscriptionsComponent } from './views/profile-landing/manage-subscriptions/manage-subscriptions.component';
import { WINDOW_PROVIDERS } from 'common/window.provider';
import { AppCommonModule } from 'common/app-common.module';
import { CoolCalcTermsAndConditionsComponent } from './views/cool-calc-terms-and-conditions/cool-calc-terms-and-conditions.component';
import { DealerLocatorComponent } from './views/dealer-locator/dealer-locator.component';
import { DealerLocatorDetailsComponent } from './views/dealer-locator/dealer-locator-details/dealer-locator-details.component';
import { ZipcodeAssignmentComponent } from './views/dealer-locator/zipcode-assignment/zipcode-assignment.component';
import { ThirdPartyRatingsComponent } from './views/dealer-locator/third-party-rating/third-party-ratings.component';
import { ZipcodeTableComponent } from './views/dealer-locator/zipcode-assignment/zipcode-table/zipcode-table.component';
import { ConnectedPortalModule } from './views/connected-portal/connected-portal.module';
import { GraphQLModule } from './graphql.module';
import { AuthorizedBrandsComponent } from './views/account-admin-company-details/authorised-brands/authorized-brands.component';
import { EditProgramComponent } from './views/program/edit-program/edit-program.component';
import { ProgramAdminComponent } from './views/program/program-admin.component';
import { ManageEnrollmentsComponent } from './views/program/manage-enrollments/manage-enrollments.component';
import { EditEligibilityCriteriaComponent } from './views/program/edit-eligibility-criteria/edit-eligibility-criteria.component';
import { CreateProgramComponent } from './views/program/create-program/create-program.component';
import { ProgramManagerComponent } from './views/program/program-manager/program-manager.component';
import { EnrollmentFormComponent } from './views/program/enrollment-form/enrollment-form.component';
import { EnrollmentFormBuilderComponent } from './views/program/enrollment-form/enrollment-form-builder/enrollment-form-builder.component';
import { TradepartnersComponent } from './views/trade-partners/trade-partners.component';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { PurchaseOrderDetailComponent } from './views/trade-partners/purchase-order-detail/purchase-order-detail.component';
import { PurchaseOrderListComponent } from './views/trade-partners/purchase-order-list/purchase-order-list.component';
import { AcknowledgementComponent } from './views/trade-partners/acknowledgement/acknowledgement.component';
import { AdvancedShipmentComponent } from './views/trade-partners/advanced-shipment/advanced-shipment.component';
import { UsersComponent } from './views/account-admin-results/user/users.component';
import { UserDetailsComponent } from './views/account-admin-results/user/user-details/user-details.component';
import { YearMoDatePipe } from './pipes/year-mo-date.pipe';
import { DecimalPipe } from '@angular/common';
import { CreateEligibilityCriteriaComponent } from './views/program/create-eligibility-criteria/create-eligibility-criteria.component';
import { EditUserRelationshipsComponent } from './views/account-admin-results/user/edit-user-relationships/edit-user-relationships.component';
import { CreateUserComponent } from './views/create-user/create-user.component';
import { CreateUserCompanySearchFormComponent } from './components/create-user-company-search-form/create-user-company-search-form.component';
import { CreateUserCompanyTableComponent } from './views/create-user/create-user-company-table/create-user-company-table.component';
import { EditUserSecurityComponent } from './views/account-admin-results/user/edit-user-security/edit-user-security.component';
import { ReviewRegisteredUsersInCompanyComponent } from './views/create-user/review-registered-users-in-company/review-registered-users-in-company.component';
import { OptiCleanSizingSelectionToolComponent } from './components/opti-clean-sizing-selection-tool/opti-clean-sizing-selection-tool.component';
import { UserRegistrationsComponent } from './components/user-registrations/user-registrations.component';
import { UserRegistrationsResultsComponent } from './components/user-registrations/user-registrations-results/user-registrations-results.component';
import { CreateUserDetailsComponent } from './views/create-user/create-user-details/create-user-details.component';
import { UserRegistrationsDetailsComponent } from './components/user-registrations/user-registrations-details/user-registrations-details.component';
import { HvacUserRolesDetailsComponent } from './components/user-registrations/hvac-user-roles-details/hvac-user-roles-details.component';
import { SoldtoTableComponent } from './views/account-admin-results/user/edit-user-relationships/soldto-table/soldto-table.component';
import { ManageCountiesComponent } from './views/manage-counties/manage-counties.component';
import { AppManageCountiesTableComponent } from './views/manage-counties/app-manage-counties-table/app-manage-counties-table.component';
import { ProgramEnrollmentsComponent } from './views/program-enrollments/program-enrollments.component';
import { MonthlyCustomerStatementsComponent } from './components/monthly-customer-statements/monthly-customer-statements/monthly-customer-statements.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/translations/', '.json');
}

export const apiOptionsProvider: Provider = {
    provide: ApiOptionsService,
    useClass: ApiOptionsServicePrivate
};

@NgModule({
    declarations: [
        AppComponent,
        AccountAdminCompanyDetailsComponent,
        AccountAdminContactDetailsComponent,
        AccountAdminComponent,
        AccountAdminResultsComponent,
        AccountAdminFormComponent,
        CompaniesTableComponent,
        UsersTableComponent,
        UsersComponent,
        UserDetailsComponent,
        ProfileLandingComponent,
        ProfileComponent,
        ProgramAdminComponent,
        SearchProgramComponent,
        CreateProgramComponent,
        EditProgramComponent,
        ProgramManagerComponent,
        ManageEnrollmentsComponent,
        EnrollmentFormComponent,
        EnrollmentFormBuilderComponent,
        EditEligibilityCriteriaComponent,
        CompanyInfoComponent,
        BrandSelectComponent,
        CoolCalcComponent,
        AssociatedDistributorsComponent,
        AccountAdminDistributorDetailsComponent,
        AssociatedDealersComponent,
        AssociationBaseComponent,
        AccountAdminDistributorDetailsComponent,
        AssociatedDistributorLookup,
        AssociatedDistributorsComponent,
        AssociatedContactsComponent,
        ManageSubscriptionsComponent,
        CoolCalcTermsAndConditionsComponent,
        DealerLocatorComponent,
        DealerLocatorDetailsComponent,
        ZipcodeAssignmentComponent,
        ThirdPartyRatingsComponent,
        ZipcodeTableComponent,
        AuthorizedBrandsComponent,
        TradepartnersComponent,
        PurchaseOrderDetailComponent,
        PurchaseOrderListComponent,
        AcknowledgementComponent,
        AdvancedShipmentComponent,
        YearMoDatePipe,
        CreateEligibilityCriteriaComponent,
        EditUserSecurityComponent,
        CreateUserCompanySearchFormComponent,
        EditUserRelationshipsComponent,
        EditUserSecurityComponent,
        CreateUserComponent,
        CreateUserCompanySearchFormComponent,
        CreateUserCompanyTableComponent,
        EditUserSecurityComponent,
        ReviewRegisteredUsersInCompanyComponent,
        OptiCleanSizingSelectionToolComponent,
        UserRegistrationsComponent,
        UserRegistrationsResultsComponent,
        CreateUserDetailsComponent,
        UserRegistrationsDetailsComponent,
        HvacUserRolesDetailsComponent,
        SoldtoTableComponent,
        ManageCountiesComponent,
        AppManageCountiesTableComponent,
        ProgramEnrollmentsComponent,
        MonthlyCustomerStatementsComponent
    ],
    imports: [
        BrowserAnimationsModule,
        AppCommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        ComponentsModule,
        CommonComponentsModule,
        DashboardModule,
        LoginModule,
        SafePipeModule,
        AuthenticatingModule,
        SignOutModule,
        TridionContentPageModule,
        CreateAccountInformationModule,
        CreateAccountLookupModule,
        CreateEmailValidateModule,
        ChangePasswordModule,
        FrameContentModule,
        RouterModule,
        ContentRoutingModule,
        ContentModule,
        BrowseModule,
        CallbackModule,
        ConnectedPortalModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxsModule.forRoot([AccountState,
            AccountStatusState,
            ApprovalRequestsState,
            AuthState,
            BookmarkState,
            BrandingState,
            CatalogState,
            ChatbotState,
            ContentState,
            DocumentsState,
            GdprState,
            InboxState,
            LoginPanelState,
            NavigationState,
            NotificationsState,
            ProductsState,
            SearchState,
            PartsState,
            SiteContentState,
            SuggestionsState,
            UiState], { developmentMode: !environment.production }),
        NgxsRouterPluginModule.forRoot(),
        GraphQLModule,
        HttpClientModule,
        NgxsLoggerPluginModule.forRoot({ disabled: true }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: true })
    ],
    providers: [
        oktaProvider,
        httpInterceptorProviders,
        apiOptionsProvider,
        ApprovalRequestsService,
        WINDOW_PROVIDERS,
        YearMoDatePipe,
        DecimalPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        translate: TranslateService,
        private readonly okta: OktaService
    ) {
        translate.setDefaultLang('en');
        translate.use('en');

        const language = translate.getBrowserLang();
        translate.use(language.match(/en|fr/) ? language : 'en');

        this.okta.resumeSecureSession();
    }
}
