/* eslint-disable max-lines */
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { API_RESPONSE, AppConstants } from 'common/app-constants';
import { BaseComponent } from 'common/components/base/base.component';
import { HvacAutoCompleteEvent } from 'common/components/hvac-autocomplete/hvac-auto-complete.component';
import { Option } from 'common/components/select/select.component';
import { ToastService } from 'common/services/toast.service';
import { BrandedPricingAndWarrantyConfiguration, BrandedRelationships, CompanyRelationshipsResult, DealerDistributor, PricingBrandWarrantyRelationship, PricingWarrantyRelationships, RelationshipConfig, RelationshipTabOption } from 'private/app/models/account-admin-company.model';
import { AccountAdminBrandsItemsEntity } from 'private/app/models/account-admin-search';
import { ACTIVATED_STAT, CompanyDetailsResponse } from 'private/app/models/distributor-relationships.model';
import { AccountAdminExtendedService } from 'private/app/services/account-admin/account-admin-extended.service';
import { AccountAdminService } from 'private/app/services/account-admin/account-admin.service';
import { CompanyQueryService } from 'private/app/services/account-admin/company-query.service';
import { AutocompleteInputTypes, ClaimOption, CompanyRelationshipService, NON_BRANDED, RelationshipTypeCode, RelationshipTypeId } from 'private/app/services/account-admin/company-relationship.service';
import { AccountAdminDetailsEditConfiguration } from 'private/app/views/account-admin-company-details/company-query/company-query-configuration';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { takeUntil, debounceTime, switchMap, catchError, tap } from 'rxjs/operators';
type ManageRelationshipProfiles = keyof BrandedPricingAndWarrantyConfiguration


enum RelationTypeCodeField {
    RelationTypeCode = 'relationTypeCode'

}
@Component({
    selector: 'hvac-manage-relationship',
    templateUrl: './manage-relationship.component.html',
    styleUrls: ['./manage-relationship.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ManageRelationshipComponent extends BaseComponent implements OnInit {
    @Input() onFormSubmit: Observable<void> = new Observable<void>();
    @Input() relationshipList?: PricingBrandWarrantyRelationship[];
    @Input() isCreate?: boolean;
    @Input() brandFamily: string;
    @Input() hvacCompanyId?: string;
    @Output() submitChanges = new EventEmitter<CompanyRelationshipsResult>();
    @Input() selectedRelationship: Observable<PricingBrandWarrantyRelationship> = new Observable<PricingBrandWarrantyRelationship>();

    public brandList: Option[];
    public selectedTab: ManageRelationshipProfiles = RelationshipTabOption.BRAND;
    public showRelationshipList: boolean = false;
    public tableData: PricingBrandWarrantyRelationship[] = [];
    public isFormValid: boolean = false;
    public isEditModal: boolean = false;
    public isRelationshipExists: boolean = false;
    public unsavedChangesModal: boolean = false;
    public unsavedRelationshipsModal: boolean = false;
    public tabAfterModalClose: ManageRelationshipProfiles;
    public warningMessage: string;
    public autocompleteInputType: string;
    public companyNameInputDisabled: boolean = false;
    public hvacpIdInputDisabled: boolean = false;
    public soldToInputDisabled: boolean = false;
    public noResutsFoundFlag: boolean = false;
    public validationToastErrorId: string = 'brandValidationError';

    public relationshipForm: UntypedFormGroup = new UntypedFormGroup({
        brand: new UntypedFormControl('', [Validators.required]),
        distributorCompanyName: new UntypedFormControl('', [Validators.maxLength(60)]),
        distributorHvacpId: new UntypedFormControl('', [Validators.maxLength(60)]),
        distributorSoldTo: new UntypedFormControl('', [Validators.maxLength(60)]),
        cinNumber: new UntypedFormControl('', [Validators.required, Validators.maxLength(10)]),
        tierLevel: new UntypedFormControl(''),
        claims: new UntypedFormControl('', [Validators.required]),
        comments: new UntypedFormControl('', [Validators.maxLength(4000)])
    })

    companyConfiguration: AccountAdminDetailsEditConfiguration;
    config = new BrandedPricingAndWarrantyConfiguration();
    selectedCompany: PricingBrandWarrantyRelationship;
    formConfig$: BehaviorSubject<RelationshipConfig> = new BehaviorSubject<RelationshipConfig>(this.config[this.selectedTab]);
    brands$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    companyLookUpData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    companyLookUpDataHvacpId$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    companyLookUpDataSoldTo$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    companyDetails$: BehaviorSubject<CompanyDetailsResponse | null> = new BehaviorSubject<CompanyDetailsResponse | null>(null);
    companyQuerySubject$: BehaviorSubject<string> = new BehaviorSubject('');
    companyQuerySubjectHvacpId$: BehaviorSubject<string> = new BehaviorSubject('');
    companyQuerySubjectSoldTo$: BehaviorSubject<string> = new BehaviorSubject('');
    cinQuerySubject$: BehaviorSubject<string> = new BehaviorSubject('');
    invalidCIN$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    CINLengthExceeded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    companyDetailsError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    brandError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    autoComplete$: BehaviorSubject<HvacAutoCompleteEvent> = new BehaviorSubject<HvacAutoCompleteEvent>({ loading: false });
    autoCompleteHvacId$: BehaviorSubject<HvacAutoCompleteEvent> = new BehaviorSubject<HvacAutoCompleteEvent>({ loading: false });
    autoCompleteSoldTo$: BehaviorSubject<HvacAutoCompleteEvent> = new BehaviorSubject<HvacAutoCompleteEvent>({ loading: false });

    private brandsByRelationship: ({
        code: string; id: string; name: string;
    } | undefined)[];

    private selectedBrand: string = '';

    constructor(public readonly companyRelationshipService: CompanyRelationshipService,
        protected adminService: AccountAdminService,
        protected toastService: ToastService,
        public companyQuery: CompanyQueryService,
        public accountAdminExtendedService: AccountAdminExtendedService,
        private translate: TranslateService) {
        super();
    }

    ngOnInit(): void {
        this.getNewBrandsList();
        this.selectedRelationship.pipe(takeUntil(this.ngOnDestroy$)).subscribe((data) => {
            if (data) {
                this.isEditModal = true;
                this.selectedCompany = data;
                const parentDetails = data?.dealerDistributor;
                const details: CompanyDetailsResponse = {
                    companyName: parentDetails?.distributorCompanyName!,
                    hvacCompanyId: parentDetails?.distributorCompanyId?.toString()!,
                    address1: parentDetails?.distributorAddress!
                };
                if (data.relationshipType === RelationshipTypeCode.WARRANTY) {
                    this.selectedTab = RelationshipTabOption.WARRANTY;
                    this.formConfig$.next(this.config[this.selectedTab]);
                    this.relationshipForm.controls.distributorCompanyName.setValue(data.dealerDistributor.distributorCompanyName);
                    this.relationshipForm.controls.comments.setValue(data.dealerDistributor.comment);
                    this.relationshipForm.controls.cinNumber.setValue(data.cin);
                    this.setClaimsEntitlementField(data.claims);
                    details.soldTo = data.soldTo?.toString();
                    this.companyDetails$.next(details);
                }

                else if (data.relationshipType === RelationshipTypeCode.PRICING) {
                    this.selectedTab = RelationshipTabOption.PRICING;
                    this.formConfig$.next(this.config[this.selectedTab]);
                    this.relationshipForm.controls.distributorCompanyName.setValue(data.dealerDistributor.distributorCompanyName);
                    this.relationshipForm.controls.comments.setValue(data.dealerDistributor.comment);
                    this.relationshipForm.controls.cinNumber.setValue(data.cin);
                    details.soldTo = data.soldTo?.toString();
                    this.companyDetails$.next(details);
                }
                else {
                    this.selectedTab = RelationshipTabOption.BRAND;
                    this.formConfig$.next(this.config[this.selectedTab]);
                    this.relationshipForm.controls.distributorCompanyName.setValue(data.dealerDistributor.distributorCompanyName);
                    this.relationshipForm.controls.comments.setValue(data.dealerDistributor.comment);
                    const selectedBrand = this.brandList.filter((brandedRelationship) => brandedRelationship?.name === data.brandName);
                    this.relationshipForm.controls.brand.setValue(selectedBrand);
                    const selectedTier = this.companyRelationshipService.tierLevelOptions.filter((tier) => tier?.name === data.tierLevel);
                    this.relationshipForm.controls.tierLevel.setValue(selectedTier);
                    this.companyDetails$.next(details);
                }
            }
        });
        this.adminService.getRelationshipTypes().subscribe((relationshipType) => {
            this.brandsByRelationship = relationshipType.map((relationship) => relationship.brand);
            this.brandList = this.brandsByRelationship.filter((relationship) => relationship && this.brandList?.some((brand) => brand.value === relationship.code)).map((brand) => ({
                name: brand?.name,
                value: brand?.id
            }) as Option);
            this.brandList.unshift({
                name: this.translate.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.SELECT_BRANDS'),
                value: ''
            });
        });

        this.companyQuery.companyDetailsConfiguration.pipe(takeUntil(this.ngOnDestroy$)).subscribe((configuration) => {
            if (NON_BRANDED.includes(configuration.companyType!)) {
                this.selectedTab = RelationshipTabOption.PRICING;
                this.formConfig$.next(this.config[this.selectedTab]);
            }
            this.companyConfiguration = configuration;
        });

        this.companyLookUpData$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((_status) => {
            this.autoComplete$.next({
                loading: false,
                loadMore: this.companyRelationshipService.isLoadMoreData$.value || false
            });
            this.noResutsFoundFlag = _status.length === 0;
        });

        this.companyLookUpDataHvacpId$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((_status) => {
            this.autoCompleteHvacId$.next({
                loading: false,
                loadMore: this.companyRelationshipService.isLoadMoreData$.value || false
            });
            this.noResutsFoundFlag = _status.length === 0;
        });

        this.companyLookUpDataSoldTo$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((_status) => {
            this.autoCompleteSoldTo$.next({
                loading: false,
                loadMore: this.companyRelationshipService.isLoadMoreData$.value || false
            });
            this.noResutsFoundFlag = _status.length === 0;
        });

        this.relationshipForm.controls.brand.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((brand: Option[]) => {
            if (!this.isEditModal) {
                this.relationshipForm.controls.distributorCompanyName.setValue('');
                this.relationshipForm.controls.distributorHvacpId.setValue('');
                this.relationshipForm.controls.distributorSoldTo.setValue('');
                this.companyRelationshipService.initialPage = 0;
            }
            if (Array.isArray(brand) && brand.length) {
                this.companyDetails$.next(null);
                this.brandError$.next(false);
                this.selectedBrand = this.brandsByRelationship.find((brandedRelationship) => brandedRelationship?.id === brand[0].value)?.code || '';
            }
        });

        this.relationshipForm.controls.distributorCompanyName.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((companyName: string) => {
            if (companyName) {
                this.hvacpIdInputDisabled = true;
                this.soldToInputDisabled = true;
                this.companyQuerySubject$.next(companyName!);
            }
            else {
                this.companyDetails$.next(null);
                this.hvacpIdInputDisabled = false;
                this.soldToInputDisabled = false;
            }
        });

        this.relationshipForm.controls.distributorHvacpId.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((HvacpId: string) => {
            if (HvacpId) {
                this.companyNameInputDisabled = true;
                this.soldToInputDisabled = true;
                this.companyQuerySubjectHvacpId$.next(HvacpId!);
            }
            else {
                this.companyDetails$.next(null);
                this.companyNameInputDisabled = false;
                this.soldToInputDisabled = false;
            }
        });

        this.relationshipForm.controls.distributorSoldTo.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((soldTo: string) => {
            if (soldTo) {
                this.companyNameInputDisabled = true;
                this.hvacpIdInputDisabled = true;
                this.companyQuerySubjectSoldTo$.next(soldTo!);
            }
            else {
                this.companyDetails$.next(null);
                this.companyNameInputDisabled = false;
                this.hvacpIdInputDisabled = false;
            }
        });

        this.relationshipForm.controls.cinNumber.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((cinQuery: string) => {
            (cinQuery && cinQuery.length > 10) ? this.CINLengthExceeded$.next(true) : this.CINLengthExceeded$.next(false);
            this.cinQuerySubject$.next(cinQuery);
        });

        this.companyDetails$.subscribe((data) => {
            this.isRelationshipExists = Boolean(Number(data?.hvacCompanyId));
        });

        this.companyQuerySubject$.pipe(
            debounceTime(AppConstants.defaultTypeAheadDebounceTime),
            takeUntil(this.ngOnDestroy$)
        ).subscribe((searchTextValue) => {
            this.companyDetailsError$.next(false);
            this.companyLookUpData$.next([]);
            this.brandError$.next(false);
            if (!searchTextValue) {
                return;
            }

            if ((this.selectedTab === RelationshipTabOption.BRAND && this.selectedBrand?.length === 0)) {
                this.brandError$.next(true);

                return;
            }
            if (!this.isEditModal) {
                this.autoComplete$.next({ loading: true });
                this.companyRelationshipService.getCompaniesByRelationshipQuery(this.selectedTab, AutocompleteInputTypes.CompanyName, {
                    brand: (this.selectedTab === RelationshipTabOption.BRAND && this.selectedBrand.length > 0) ? [this.selectedBrand] : [],
                    company: searchTextValue,
                    brandFamily: this.brandFamily || '',
                    companyType: this.companyConfiguration.companyType || '',
                    companyCategory: this.companyConfiguration.configType || ''
                }, this.companyLookUpData$, false);
            }
        });

        this.companyQuerySubjectHvacpId$.pipe(
            debounceTime(AppConstants.defaultTypeAheadDebounceTime),
            takeUntil(this.ngOnDestroy$)
        ).subscribe((searchTextValue) => {
            this.companyDetailsError$.next(false);
            this.companyLookUpDataHvacpId$.next([]);
            this.brandError$.next(false);
            if (!searchTextValue) {
                return;
            }

            if ((this.selectedTab === RelationshipTabOption.BRAND && this.selectedBrand?.length === 0)) {
                this.brandError$.next(true);

                return;
            }
            if (!this.isEditModal) {
                this.autoCompleteHvacId$.next({ loading: true });
                this.companyRelationshipService.getCompaniesByRelationshipQuery(this.selectedTab, AutocompleteInputTypes.HvacpId, {
                    brand: (this.selectedTab === RelationshipTabOption.BRAND && this.selectedBrand.length > 0) ? [this.selectedBrand] : [],
                    company: searchTextValue,
                    brandFamily: this.brandFamily || '',
                    companyType: this.companyConfiguration.companyType || '',
                    companyCategory: this.companyConfiguration.configType || ''
                }, this.companyLookUpDataHvacpId$, false);
            }
        });

        this.companyQuerySubjectSoldTo$.pipe(
            debounceTime(AppConstants.defaultTypeAheadDebounceTime),
            takeUntil(this.ngOnDestroy$)
        ).subscribe((searchTextValue) => {
            this.companyDetailsError$.next(false);
            this.companyLookUpDataSoldTo$.next([]);
            this.brandError$.next(false);
            if (!searchTextValue) {
                return;
            }

            if ((this.selectedTab === RelationshipTabOption.BRAND && this.selectedBrand?.length === 0)) {
                this.brandError$.next(true);

                return;
            }
            if (!this.isEditModal) {
                this.autoCompleteSoldTo$.next({ loading: true });
                this.companyRelationshipService.getCompaniesByRelationshipQuery(this.selectedTab, AutocompleteInputTypes.SoldTo, {
                    brand: (this.selectedTab === RelationshipTabOption.BRAND && this.selectedBrand.length > 0) ? [this.selectedBrand] : [],
                    company: searchTextValue,
                    brandFamily: this.brandFamily || '',
                    companyType: this.companyConfiguration.companyType || '',
                    companyCategory: this.companyConfiguration.configType || ''
                }, this.companyLookUpDataSoldTo$, false);
            }
        });

        this.cinQuerySubject$.pipe(
            debounceTime(AppConstants.defaultTypeAheadDebounceTime),
            switchMap((cinQuery) => {
                this.invalidCIN$.next(false);
                if (!cinQuery) {
                    return EMPTY;
                }
                if (!this.companyDetails$.value) {
                    this.companyDetailsError$.next(true);

                    return of(false);
                }

                if (!this.isEditModal) {
                    return this.companyQuery.validateCIN({
                        CIN: cinQuery,
                        distributorCompanyId: Number(this.companyDetails$.value.hvacCompanyId),
                        relationshipTypeId: this.selectedTab === RelationshipTabOption.PRICING ? RelationshipTypeId.PRICING : RelationshipTypeId.WARRANTY
                    }).pipe(
                        tap((response) => {
                            this.invalidCIN$.next(response.status === API_RESPONSE.SUCCESS);
                        }),
                        catchError((err) => {
                            this.invalidCIN$.next(err.status === API_RESPONSE.CONFLICT);

                            return EMPTY;
                        })
                    );
                }

                return of(false);
            }),
            takeUntil(this.ngOnDestroy$)
        ).subscribe();
    }

    saveChanges() {
        Object.keys(this.relationshipForm.controls).forEach((key) => {
            this.relationshipForm.get(key)?.markAsDirty();
        });
        this.isFormValid = this.isFormValidByTab(this.selectedTab);
        const parentCompany = this.companyDetails$.value;

        if (this.isFormValid) {
            const dealerDistributor: DealerDistributor = {
                comment: this.relationshipForm.controls.comments.value,
                companyRelationShipId: this.isEditModal ? Number(this.selectedCompany.dealerDistributor.companyRelationShipId) : 0,
                createdDate: new Date().toISOString(),
                status: ACTIVATED_STAT,
                distributorCompanyId: Number(parentCompany?.hvacCompanyId) || 0,
                distributorCompanyName: parentCompany?.companyName || '',
                distributorAddress: parentCompany?.address1 || '',
                validToDate: '',
                isSameHierarchy: this.isEditModal ? 'true' : ''
            };
            if (this.selectedTab === RelationshipTabOption.BRAND) {
                const brandedRelationship: BrandedRelationships = {
                    dealerDistributor: dealerDistributor,
                    brandId: this.relationshipForm.controls.brand.value[0].value,
                    brandName: this.relationshipForm.controls.brand.value[0].name,
                    tierLevel: this.relationshipForm.controls.tierLevel.value[0]?.name || '',
                    tierLevelId: Number(this.relationshipForm.controls.tierLevel.value[0]?.value) || 0
                };
                this.tableData.push(brandedRelationship);
            }
            else {
                const pricingWarrantyRelationships: PricingWarrantyRelationships = {
                    dealerDistributor: dealerDistributor,
                    cin: this.relationshipForm.controls.cinNumber.value,
                    claims: this.relationshipForm.controls.claims.value[0]?.value || '',
                    hvp: '',
                    relationshipId: (this.selectedTab === RelationshipTabOption.PRICING) ? RelationshipTypeId.PRICING : RelationshipTypeId.WARRANTY,
                    relationshipType: (this.selectedTab === RelationshipTabOption.PRICING) ? RelationshipTypeCode.PRICING : RelationshipTypeCode.WARRANTY,
                    relationTypeCode: null,
                    soldTo: Number(parentCompany?.soldTo) || 0
                };
                this.tableData.push(pricingWarrantyRelationships);
            }
            this.resetForm();
        }
    }

    updateRelationship() {
        this.saveChanges();
        this.addRelationships();
    }

    getNewBrandsList() {
        this.accountAdminExtendedService.getBrands(false).subscribe((data) => {
            this.brandList = data.map((option:AccountAdminBrandsItemsEntity) => ({
                name: option.name,
                value: option.code
            }));

            this.brandList = this.brandsByRelationship.filter((relationship) => relationship && this.brandList?.some((brand) => brand.value === relationship.code)).map((brand) => ({
                name: brand?.name,
                value: brand?.id
            }) as Option);
            this.brandList.sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()));
            this.brandList.unshift({
                name: this.translate.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.SELECT_BRANDS'),
                value: ''
            });
        });
    }

    addRelationships() {
        const priceWarrantyRelationships = this.tableData.filter((item) => (item.relationshipId === RelationshipTypeId.PRICING || item.relationshipId === RelationshipTypeId.WARRANTY));
        const brandedRelationships = this.tableData.filter((item) => !(Object.prototype.hasOwnProperty.call(item, RelationTypeCodeField.RelationTypeCode)));
        this.submitChanges.emit({
            brandedRelationships: brandedRelationships,
            pricingWarrantyRelationships: priceWarrantyRelationships,
            edit: this.isEditModal
        });
        this.tableData = [];
        this.showRelationshipList = false;
        this.companyRelationshipService.claimTypeOptions$.next([{
            name: '',
            value: ''
        }]);
    }

    handleTabClick(tabSelection: ManageRelationshipProfiles) {
        this.tabAfterModalClose = tabSelection;
        if (this.relationshipForm.dirty && this.selectedTab !== tabSelection) {
            this.unsavedChangesModal = true;
            this.warningMessage = this.translate.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.UNSAVED_CHANGES_WARNING_MODAL.WARNING_INFO', { selectedTab: this.selectedTab });
        }
        else if (this.relationshipForm.dirty && this.selectedTab === tabSelection) {
            this.unsavedChangesModal = false;
        }
        else {
            this.resetForm();
            this.selectedTab = tabSelection;
            this.formConfig$.next(this.config[tabSelection]);
        }
    }

    brandedRelationValidation(option: Option) {
        const parentCompanyId = option?.value?.split('-')[0].trim();
        const childCompanyId = (this.hvacCompanyId) ? this.hvacCompanyId : '';
        const brandId = this.relationshipForm.controls.brand.value[0]?.value;
        const brandName = this.relationshipForm.controls.brand.value[0]?.name;

        if (!this.isCreate) {
            this.accountAdminExtendedService.brandRelationshipValidation(parentCompanyId, childCompanyId, brandId).subscribe((response) => {
                if (response.statusCode !== 'OK') {
                    this.isRelationshipExists = false;
                    this.toastService.add({
                        content: this.translate.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.RELATIONSHIP_VALIDATION_ERROR', {
                            selectedBrand: brandName,
                            selectedCompany: option.name
                        }),
                        id: childCompanyId,
                        theme: 'error',
                        closeable: true,
                        autoClose: true,
                        outletName: this.validationToastErrorId
                    });
                }
            });
        }
    }

    onCompanyLookUp(option: Option) {
        if (this.selectedTab === RelationshipTabOption.BRAND) {
            this.brandedRelationValidation(option);
        }
        const companyLookup: CompanyDetailsResponse | null = this.companyRelationshipService.companyDetails.find((company) => company.hvacCompanyId === option.value.split(' - ')[0]) || null;
        this.companyDetails$.next(companyLookup);
        this.companyRelationshipService.getClaimTypeOptions(this.companyDetails$.value?.warranty);
    }

    companyLookupWithPagination() {
        this.autoComplete$.next({ loading: true });
        this.companyRelationshipService.getCompaniesByRelationshipQuery(this.selectedTab, AutocompleteInputTypes.CompanyName, {
            brand: (this.selectedTab === RelationshipTabOption.BRAND && this.selectedBrand.length > 0) ? [this.selectedBrand] : [],
            company: this.companyQuerySubject$.value,
            companyType: this.companyConfiguration.companyType || ''
        }, this.companyLookUpData$, true);
    }

    companyHvacpIdLookupWithPagination() {
        this.autoCompleteHvacId$.next({ loading: true });
        this.companyRelationshipService.getCompaniesByRelationshipQuery(this.selectedTab, AutocompleteInputTypes.HvacpId, {
            brand: (this.selectedTab === RelationshipTabOption.BRAND && this.selectedBrand.length > 0) ? [this.selectedBrand] : [],
            company: this.companyQuerySubject$.value,
            companyType: this.companyConfiguration.companyType || ''
        }, this.companyLookUpDataHvacpId$, true);
    }

    companySoldToLookupWithPagination() {
        this.autoCompleteSoldTo$.next({ loading: true });
        this.companyRelationshipService.getCompaniesByRelationshipQuery(this.selectedTab, AutocompleteInputTypes.SoldTo, {
            brand: (this.selectedTab === RelationshipTabOption.BRAND && this.selectedBrand.length > 0) ? [this.selectedBrand] : [],
            company: this.companyQuerySubject$.value,
            companyType: this.companyConfiguration.companyType || ''
        }, this.companyLookUpDataSoldTo$, true);
    }

    handleCloseModal() {
        if (this.tableData.length >= 1) {
            this.unsavedRelationshipsModal = true;
        }
        else {
            this.resetRelationships();
        }
    }

    unsavedChangesModalClose() {
        this.unsavedChangesModal = false;
    }

    unsavedRelationshipsModalClose() {
        this.unsavedRelationshipsModal = false;
    }

    removeUnsavedRelationships() {
        this.unsavedRelationshipsModal = false;
        this.resetRelationships();
    }

    resetRelationships() {
        this.adminService.isModalVisible = false;
        this.tableData = [];
        this.showRelationshipList = false;
        this.isEditModal = false;
        this.resetForm();
    }

    removeUnsavedChanges() {
        this.unsavedChangesModal = false;
        this.resetForm();
        this.selectedTab = this.tabAfterModalClose;
        this.formConfig$.next(this.config[this.tabAfterModalClose]);
    }

    removeRelationship(distributor: PricingBrandWarrantyRelationship) {
        this.tableData = this.tableData.filter((item) => item.dealerDistributor.distributorCompanyId !== distributor.dealerDistributor.distributorCompanyId);
        this.showRelationshipList = this.tableData.length > 0;
    }

    private resetForm() {
        this.relationshipForm.reset({
            brand: '',
            tierLevel: '',
            claims: ''
        });
        this.companyRelationshipService.companyDetails = [];
        this.isRelationshipExists = false;
        this.companyDetails$.next(null);
        this.selectedBrand = '';
    }

    private setClaimsEntitlementField(code?:string) {
        if (code === ClaimOption.ENTITLEMENT) {
            this.relationshipForm.controls.claims.setValue(this.translate.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.ENTITLEMENT'));
        }
        else if (code === ClaimOption.ENTITLEMENT_AND_CLAIMS) {
            this.relationshipForm.controls.claims.setValue(this.translate.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.ENTITLEMENT_CLAIMS'));
        }
    }

    private isFormValidByTab(tab: ManageRelationshipProfiles): boolean {
        switch (tab) {
            case RelationshipTabOption.BRAND:
                return this.relationshipForm.controls.brand.valid
                    && this.relationshipForm.controls.distributorCompanyName.valid;
            case RelationshipTabOption.PRICING:
                return this.relationshipForm.controls.distributorCompanyName.valid
                    && this.relationshipForm.controls.cinNumber.valid;
            case RelationshipTabOption.WARRANTY:
                return this.relationshipForm.controls.distributorCompanyName.valid
                    && this.relationshipForm.controls.cinNumber.valid
                    && this.relationshipForm.controls.claims.valid;
            default:
                return false;
        }
    }
}
