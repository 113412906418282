import { DOCUMENT } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'common/services/toast.service';
import { Subject } from 'rxjs';
import { DataHeader } from '../../../models/account-admin-search';
import { AccountAdminExportService } from '../../../services/account-admin/account-admin-export.service';
import { AccountAdminService } from '../../../services/account-admin/account-admin.service';
import { AccountPostAdminService } from 'private/app/services/account-admin/account-post-admin.service';
import { CompanyResultDataResponse, CompanySearchResultResponse } from 'private/app/models/account-admin-search-results';

@Component({
    selector: 'hvac-companies-table',
    templateUrl: './companies-table.component.html',
    styleUrls: ['./companies-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class CompaniesTableComponent implements OnInit {
    ngOnDestroy$ = new Subject();
    companies?: CompanyResultDataResponse[];
    dataHeaders: DataHeader[] = [
        {
            title: this.translate.instant('ACCOUNT_ADMIN.COMPANY'),
            value: 'companyName',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.ID'),
            value: 'companyId',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.SOLD_TO'),
            value: 'soldToCustomerId',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.SHIP_TO'),
            value: 'shipToCustomerId',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.PAYER'),
            value: 'payerCustomerId',
            order: null
        },
        {
            title: this.translate.instant('COMPANY_INFO_FORM.COMPANY_TYPE'),
            value: 'companyType',
            order: null
        }
    ];

    loading: boolean;
    pagination: number;
    totalCount: number;
    totalPages: number;
    exportCompanyLimit = 10000;
    public toastOutlet = 'AccountAdminNoExportError';

    private pageSize = 10;
    constructor(
        public accountAdminService: AccountAdminService,
        public accountPostAdminService: AccountPostAdminService,
        public accountAdminExportService: AccountAdminExportService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        @Inject(DOCUMENT) private doc: Document,
        private readonly toastService: ToastService
    ) { }

    ngOnInit() {
        this.accountAdminService.currentTab = 'companies';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.route.queryParamMap.subscribe((value: any) => {
            if (value.params.companyPagination) {
                this.pagination = parseInt(value.params.companyPagination, 10);
            }
            else {
                this.pagination = 1;
            }

            this.dataHeaders = this.accountAdminService.updateDataHeaders(value.params.companySortFields, this.dataHeaders);

            const sortParams = value.params.companySortFields || [];

            this.postCompanySearch(sortParams, value.params.companyPagination);
        });
    }

    public createDetails(company: CompanyResultDataResponse): string[] {
        const city = (company?.city || '').trim();
        const state = (company?.state || '').trim();
        const detailsFirstLine = city ? `${city}, ${state}` : state;
        const detailsSecondLine = [
            company.phone,
            company.fax,
            company.email
        ].filter(Boolean).join(', ');

        return detailsFirstLine ? [detailsFirstLine, detailsSecondLine] : [detailsSecondLine];
    }

    public handlePageChange(event: number) {
        const queryParams: Params = { companyPagination: event };

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: queryParams,
                queryParamsHandling: 'merge'
            }
        );

        this.pagination = event;
    }

    public sort(item: DataHeader) {
        const currentSort: Partial<DataHeader> = this.dataHeaders.find((header: DataHeader) => header.value === item.value) || {};

        if (!currentSort.order) {
            currentSort.order = 'asc';
        }
        else if (currentSort.order === 'asc') {
            currentSort.order = 'desc';
        }
        else {
            currentSort.order = null;
        }

        const queryParams: Params = { companySortFields: currentSort.order ? `${currentSort.value}-${currentSort.order}` : null };

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: queryParams,
                queryParamsHandling: 'merge'
            }
        );
    }

    public postCompanySearchExport(results: number) {
        if (results >= 1) {
            this.loading = true;

            this.accountPostAdminService.postCompanySearchExport(
                this.accountAdminService.createSearchBody(
                    this.accountAdminService.advancedSearchForm.value,
                    null,
                    null,
                    'companyCriteria'
                )
            ).subscribe((response: HttpResponse<string>) => {
                this.loading = false;

                this.accountAdminExportService.doExport(response);
            },
            (error) => {
                if (error) {
                    this.loading = false;
                    this.toastService.raiseDefaultErrorToast('CompaniesTableComponent-ngOnInit', this.translate.instant('ACCOUNT_ADMIN.MAXIMUM_EXPORT_LIMIT'));
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
            });
        }
    }

    public onRowClick(companyId: string) {
        if (!this.doc.getSelection()?.toString()) {
            this.router.navigate(['../../company/', companyId], { relativeTo: this.route });
        }
    }

    private postCompanySearch(sortParams: string, pagination: number = 1) {
        this.loading = true;

        this.accountPostAdminService.postCompanySearch(
            this.accountAdminService.createSearchBody(
                this.accountAdminService.advancedSearchForm.value,
                pagination,
                sortParams,
                'companyCriteria'
            )
        ).subscribe((res: CompanySearchResultResponse) => {
            this.loading = false;
            this.companies = res?.data;
            this.totalCount = (res?.totalCount === 0 && res?.data.length > 0) ? this.totalCount : res?.totalCount;
            if (this.totalCount > this.exportCompanyLimit) {
                this.toastService.add({
                    content: this.translate.instant('ACCOUNT_ADMIN.UNABLE_TO_PROCESS_EXPORT'),
                    theme: 'warning',
                    id: 'AccountAdminNoExportError',
                    outletName: this.toastOutlet,
                    closeable: false,
                    autoClose: false
                });
            }
            this.totalPages = Math.ceil(this.totalCount / this.pageSize);
        });
    }
}
