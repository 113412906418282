import { PrivateConfig } from '../../models/environment.private';
import { commonEnvVars } from 'src/common/environments/private/environment.private.common';
import { EnvName } from 'common/app-constants';

const config: PrivateConfig = {
    envName: EnvName.Dev,
    appId: 'l7xx5e5d882c07aa4188ba03f91ffd0dbe2e',
    googleMapAPI: 'AIzaSyCtNT6wBKYNseCCEBD6amIqyjsHQaOXnx0',
    brand: 'private',
    auth: {
        baseUrl: 'https://ssodev.hvacpartners.com',
        clientId: `0oas5xhe12yGhPHeV0h7`,
        identityProviders: [{
            type: 'GOOGLE',
            id: '0oa1n6sz328RUncj30h8'
        }, {
            type: 'APPLE',
            id: '0oa1n6u9b3eKAE3SL0h8'
        }]
    },
    addressDoctor: {
        login: '162277',
        password: 'i0gYu*^hgg'
    },
    baseUrl: 'https://dev.hvacpartners.com',
    chatbotUrl: 'https://dev-chatbot.hvacpartners.com/bot2.html',
    connectedPortal: {
        url: 'https://17li4ggbul.execute-api.us-east-1.amazonaws.com/v1',
        key: 'da2-ymumcbrqdvatfl5jbzg6awtfxa',
        webSocketUrl: 'https://fkrsspdmnjacfk3os3z3nlpmna.appsync-api.us-east-1.amazonaws.com/graphql',
        webSocketKey: 'da2-dvjoxcun7nhnrmgaehkfs5evve',
        homeOwnerResetAPI: 'https://dataservice.infinity.preprod.iot.carrier.com/graphql-no-auth'
    },
    coolCalcAPIDomain: 'stagingapi.coolcalc.com',
    coolCalcEnv: 'staging',
    documents: {
        whatsNewAge: 365,
        bulletinAge: 2555
    },
    envPath: '/dev',
    features: {
        accountAdmin: true,
        accountAdminDealers: true,
        associatedContacts: true,
        accountAdminUser: true,
        accountAdminCreateUser: true,
        auth: { login: true },
        brandFilter: {
            brand: true,
            content: true
        },
        brandSelection: true,
        chatbot: true,
        connectedPortal: {
            internal: true,
            distributors: true,
            distributorStats: true,
            dealers: true,
            warrantyLookup: false,
            ecobeeDiagnosticsTestingFeature: true,
            ecobeeSystemsFeature: true,
            ecobeeEditConfigFeature: true,
            dealerSettingFeature: true,
            addControlFeature: true,
            opportunityReportUsageGraph: true,
            addControlDisableNotification: true,
            dealerOpportunities: true,
            ecobeeNiRuntimeLink: true,
            linkedDealersEnabled: true,
            factorySystemsOverviewEnabled: true,
            energyEventsEnabled: true,
            fad: true,
            vppEligiblityReportEnabled: true,
            fleetAnalysisFeature: true
        },
        dealerSearchCriteriaCIN: false,
        product: {
            worksWellWith: false,
            options: false
        },
        profile: true,
        relationshipType: false,
        searchContent: true,
        searchProgram: true,
        selectPrimaryDistributor: true,
        socialLogin: true,
        suggestions: true,
        tradePartner: true,
        userValidation: true,
        partsCatalog: true,
        googleAddressValidation: true,
        pendo: true,
        registrationRequest: true
    },
    gatewayPath: 'https://staging.servicesgateway.carrier.com',
    gatewayPathNoProtocol: 'staging.servicesgateway.carrier.com',
    idmPath: 'idm-dev',
    iframePath: 'https://dev-legacy2.hvacpartners.com',
    mocks: {
        connectedPortal: {
            graphql: {
                url: 'https://dev-io.hvacpartners.com/graphql',
                queries: {}
            },
            rest: {
                url: 'https://dev-io.hvacpartners.com',
                apis: { 'ui/config': false }
            }
        }
    },
    production: false,
    secureBaseUrl: 'https://dev.hvacpartners.com',
    siteUrls: {
        default: 'https://dev.hvacpartners.com',
        carrier: 'https://dev-carrier.hvacpartners.com',
        bryant: 'https://dev-bryant.hvacpartners.com',
        arcoaire: 'http://dev-arcoaire.hvacpartners.com',
        airquest: 'http://dev-airquest.hvacpartners.com',
        comfortmaker: 'http://dev-comfortmaker.hvacpartners.com',
        dayandnight: 'http://dev-dayandnight.hvacpartners.com',
        heil: 'http://dev-heil.hvacpartners.com',
        keeprite: 'http://dev-keeprite.hvacpartners.com',
        tempstar: 'http://dev-tempstar.hvacpartners.com',
        totaline: 'http://dev-totaline.hvacpartners.com'
    },
    trackingId: '3246456584'
};

const commonEnv = commonEnvVars(config);

// We are updating the STG API because of Cutover,
// so this is temporary update
// see https://carrier.atlassian.net/browse/HVACP-6226 for more info
commonEnv.api.connectedPortal.graphql = `https://platform.preprod.iot.carrier.com/partner/dataservice/graphql`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const environment: any = { ...commonEnv };
