import { Injectable } from '@angular/core';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { DefaultQueryParams } from 'private/app/models/default-query-params';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { PAGINATION_PAGE_SIZE } from 'private/app/views/connected-portal/dealer/customer-product-details/wall-control/infinity-wall-control-detail/infinity-wall-control-detail.component';
import { EnergyEventsService } from 'private/app/services/connected-portal/energy-events.service';
import { environment } from 'common/environments/environment';

@Injectable()
export class InfinityWallControlDetailEventsService {
    wallControlEnergyEventCurrentPage$ = new BehaviorSubject<number>(1);
    readonly isInfinityWallControlEnergyEventEnabled = environment.features.connectedPortal.energyEventsEnabled;
    isLoadingEnergyEvents$ = new BehaviorSubject(false);

    constructor(private energyEventsService: EnergyEventsService) {
    }

    eventsData$(serialNo: string, dealerId: string) {
        return this.wallControlEnergyEventCurrentPage$.pipe(
            debounceTime(100),
            tap(() => {
                if (this.isInfinityWallControlEnergyEventEnabled) {
                    return;
                }
                this.isLoadingEnergyEvents$.next(true);
            }),
            switchMap(
                (currentPage) => {
                    const queryParams: DefaultQueryParams = {
                        pagination: {
                            currentPage,
                            pageSize: PAGINATION_PAGE_SIZE
                        }
                    };

                    return this.energyEventsService
                        .queryScheduledVppEventsBySerialNo(
                            serialNo,
                            dealerId,
                            queryParams
                        ).pipe(
                            map(({ data, totalPages }) => {
                                const eventData = {
                                    items: data,
                                    totalPages,
                                    currentPage
                                };

                                return eventData;
                            }),
                            catchError(() => {
                                this.isLoadingEnergyEvents$.next(false);

                                return EMPTY;
                            })
                        );
                }
            ),
            tap(() => {
                this.isLoadingEnergyEvents$.next(false);
            })
        );
    }
}
