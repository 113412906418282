/* eslint-disable max-lines */
import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from 'common/services/language-translation.service';
import { AccountAdminProgramService } from 'private/app/services/account-admin/account-admin-program.service';
import { EnrollmentFormService } from 'private/app/services/account-admin/enrollment/enrollment-form.service';
import { EnrollmentForm, EnrollmentFormData, EnrollmentFormField } from 'private/app/models/account-admin-enrollment.model';

import ductlessConversionWithNoOwner from './enrollment-forms/ductless-conversion-with-no-owner.json';
import ductlessConversionWithOwner from './enrollment-forms/ductless-conversion-with-owner.json';
import newDealer from './enrollment-forms/new-dealer.json';
import enrolmentStatusValues from './enrollment-forms/enrollment-status.json';
import { Option } from 'common/components/select/select.component';
import { BrandOverwriteType, UserService } from 'common/services/user.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, mergeMap, skip, takeUntil } from 'rxjs/operators';
import formBrands from '../enrollment-form/enrollment-forms/enrolment-form-brands.json';
import { EnrollmentApiService } from 'private/app/services/account-admin/enrollment/enrollment-api.service';
import { OktaService } from 'common/services/okta/okta.service';
import { IdToken } from 'common/models';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { AccountAdminApiService } from 'private/app/services/account-admin/account-admin-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'common/services/toast.service';

export enum FormType {
    NEW_DEALER= 'New Dealer',
    DUCTLESS_WITH_OWNER = 'Ductless Conversion With Owner',
    DUCTLESS_WITH_NO_OWNER = 'Ductless Conversion No Owner'
}

export enum YearEstimatedPurchases {
    SECOND_YEAR_ESTIMATED_PURCHASES = 'SecondYearEstimatedPurchases',
    THIRD_YEAR_ESTIMATED_PURCHASES = 'ThirdYearEstimatedPurchases',
    ESTIMATED_YEAR_2_PURCHASES = 'Est. Year 2 Purchases ($):',
    ESTIMATED_YEAR_3_PURCHASES = 'Est. Year 3 Purchases ($):',
}

export enum DealerExpectedYear {
    YEAR_TWO = 'secondYearFundingForDealer',
    YEAR_THREE = 'thirdYearFundingForDealer',
    YEAR_TWO_PERCENTAGE = 'Year 2:',
    YEAR_THREE_PERCENTAGE = 'Year 3:',
}

export enum EnrollmentFormFieldType {
    DEALER_OWNER = 'DealerOwnerId',
    DISTRIBUTOR_NAME = 'distributorName',
    RESIDENTIAL_SM = 'DistributorSalesManagerId',
    TERRITORY_MANAGERS = 'DistributorTerritoryManagerId',
    APPROVER_ACTION = 'ApproverAction',
    DEALER_COMPANY_ID = 'DealerCompanyId',
    DISTRIBUTOR_COMPANY_ID = 'DistributorCompanyId',
    ALL_BRAND_PURCHASE_DUSTLESS = 'CBPAnnualDuctlessEquipmentPurchasesCurrentYear',
    BRAND_PERCENTAGE_CURRENT_YEAR = 'CBPBrandPercentageCurrentYear',
    BRAND = 'Brand'
}

@Component({
    selector: 'hvac-enrollment-form',
    templateUrl: './enrollment-form.component.html',
    styleUrls: ['./enrollment-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EnrollmentFormComponent implements OnInit {
    @Input() brandName: string;
    public enrollmentFormConfiguration = newDealer as EnrollmentForm;
    ngOnDestroy$ = new Subject();
    public enrolmentBrandList: string[] = formBrands.newDealer;
    public enrolmentStatus?: number | null;
    public ApproverWorkflowStatusId?: number | null;
    public idmUserRole: string[];
    public isDistributor: boolean;
    public isRegionalSalesManager: boolean;
    public isProgramManager: boolean;
    public displayApproverInfo: boolean;

    public disableDealerInfo: boolean = false;
    public disableDistributorInfo: boolean = false;
    public disableSalesInfo: boolean = false;
    public disableApproverInfo: boolean = false;
    public disableRegionalSalesManager: boolean = false;
    public loggedInUserRole: string;
    public residentialSalesManagerValue?: string;
    public loggedInUserName: string;
    public formType: string;
    public isSumHundred: boolean = true;
    public isapprovalActionDisable: boolean;
    public programManagersList: string[] = [];

    enrollmentFormGroup = new UntypedFormGroup({
        dealerInformation: new UntypedFormGroup({}),
        distributorInformation: new UntypedFormGroup({}),
        salesInformation: new UntypedFormGroup({}),
        salesInfoCommon: new UntypedFormGroup({}),
        splitOfBusiness: new UntypedFormGroup({}),
        salesBrandSold: new UntypedFormGroup({}),
        percentOfPriorYearSales: new UntypedFormGroup({}),
        comments: new UntypedFormControl(''),
        ApproverInformation: new UntypedFormGroup({})
    });

    enrollmentFormData: EnrollmentFormData;
    isFormReady: boolean;
    isFormSubmitted: boolean;
    private brandSoldArray: string[] = [];
    private userBrandFamily$: Observable<BrandOverwriteType> = this.userService.getBrandFamily();
    private companyId: string;
    private programId: string;
    private formdata: EnrollmentForm;
    private dealerAddressParts: string[] | null;
    private distributorAddressParts: string[] | null;
    private formattedNewAddress: string;
    private salesBrandSoldFormGroup: UntypedFormGroup | undefined;
    private salesBrandSoldFormControls: string[] | undefined;
    private percentOfPriorYearSalesFormGroup: UntypedFormGroup | undefined;
    private percentOfPriorYearSalesFormControls: string[] | undefined;
    private salesBrandSoldFormValuesMap: Map<string, Option> = new Map();
    private enrolmentBrandListOptions = this.enrolmentBrandList.map((brand: string, index: number) => ({
        name: brand,
        value: `${index}`
    }));

    constructor(public translate: TranslateService,
        private oktaService: OktaService,
        private readonly enrolmentApiService: EnrollmentApiService,
        private formBuilder: UntypedFormBuilder,
        private languageTranslationService: LanguageTranslationService,
        private accountAdminProgramService: AccountAdminProgramService,
        public enrollmentFormService: EnrollmentFormService,
        private activatedRoute: ActivatedRoute,
        private readonly accountAdminApiService: AccountAdminApiService,
        private readonly userService: UserService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private toastService: ToastService) {
    }

    ngOnInit(): void {
        this.oktaService.idToken$.subscribe((res: IdToken) => {
            this.idmUserRole = res?.claims?.idm_user_roles || [];
            this.loggedInUserName = (res?.claims?.name) ? res?.claims?.name : '';
            if (this.idmUserRole.includes(IdmUserRoles.DEFAULT_DISTRIBUTOR)) {
                this.loggedInUserRole = IdmUserRoles.DEFAULT_DISTRIBUTOR;
            }
            if (this.idmUserRole.includes(IdmUserRoles.REGIONAL_SALES_MANAGER)) {
                this.loggedInUserRole = IdmUserRoles.REGIONAL_SALES_MANAGER;
            }
            if (this.idmUserRole.includes(IdmUserRoles.PROGRAM_MANAGER)) {
                this.loggedInUserRole = IdmUserRoles.PROGRAM_MANAGER;
            }
        });

        if (this.activatedRoute.snapshot.queryParams.pid && this.activatedRoute.snapshot.queryParams.cid) {
            this.companyId = this.activatedRoute.snapshot.queryParams.cid;
            this.programId = this.activatedRoute.snapshot.queryParams.pid;
            this.brandName = this.activatedRoute.snapshot.queryParams.brandName;
        }

        if (this.activatedRoute.snapshot.queryParams.pid) {
            this.accountAdminApiService.getAccountAdminProgramsById(this.activatedRoute.snapshot.queryParams.pid).subscribe((data) => {
                this.programManagersList = data.programManagers.map((manager) => `${manager.firstName} ${manager.lastName}`.toLowerCase());
            }, (error: HttpErrorResponse) => this.toastService.raiseDefaultErrorToast('EnrollmentFormComponent-ngOnInit', error.message));
        }

        this.languageTranslationService.translationsLoaded().subscribe(() => {
            this.accountAdminProgramService.setProgramAdminBreadCrumData({
                Content: { title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORM') },
                breadCrumbs: [
                    {
                        title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_1'),
                        url: '/admin'
                    },
                    {
                        title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_2'),
                        url: '/Admin/Program'
                    },
                    {
                        title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_3'),
                        url: '/Admin/Program/search-program/search'
                    }
                ]
            });
        });

        this.userBrandFamily$.pipe(
            takeUntil(this.ngOnDestroy$),
            skip(1),
            map((brandFamily) => brandFamily),
            mergeMap((brandFamily) => forkJoin([this.enrollmentFormService.getEnrollmentFormDetails(this.companyId, this.programId, brandFamily)]))
        ).subscribe(([formDetails]) => {
            this.formType = formDetails.formType;
            switch (formDetails.formType) {
                case FormType.NEW_DEALER:
                    this.enrollmentFormConfiguration = newDealer as EnrollmentForm;
                    this.enrolmentBrandList = formBrands.newDealer;
                    break;
                case FormType.DUCTLESS_WITH_OWNER:
                    this.enrollmentFormConfiguration = ductlessConversionWithOwner as EnrollmentForm;
                    this.enrolmentBrandList = formBrands.ductlessWithOwner;
                    break;
                case FormType.DUCTLESS_WITH_NO_OWNER:
                    this.enrollmentFormConfiguration = ductlessConversionWithNoOwner as EnrollmentForm;
                    this.enrolmentBrandList = formBrands.ductlessNoOwner;
                    break;
                default:
                    break;
            }
            this.isFormReady = true;
            this.enrollmentFormData = formDetails;
            this.enrolmentStatus = formDetails.enrollmentStatus;
            this.ApproverWorkflowStatusId = formDetails.approverInformation.ApproverWorkflowStatusId;
            this.displayApproverInfo = !(this.enrolmentStatus === null && this.ApproverWorkflowStatusId === null);
            this.createForm();
            this.valueChangeListeners();
            this.enrollmentWorkflowCheck();
        });
    }

    getSalesBrandSoldFormVariables(): [UntypedFormGroup, string[]] {
        if (!this.salesBrandSoldFormGroup) {
            this.salesBrandSoldFormGroup = this.enrollmentFormGroup.get('salesBrandSold') as UntypedFormGroup;
        }

        if (!this.salesBrandSoldFormControls) {
            this.salesBrandSoldFormControls = Object.keys(this.salesBrandSoldFormGroup.controls);
        }

        return [this.salesBrandSoldFormGroup, this.salesBrandSoldFormControls];
    }

    getpercentOfPriorYearSalesFormVariables(): [UntypedFormGroup, string[]] {
        if (!this.percentOfPriorYearSalesFormGroup) {
            this.percentOfPriorYearSalesFormGroup = this.enrollmentFormGroup.get('percentOfPriorYearSales') as UntypedFormGroup;
        }

        if (!this.percentOfPriorYearSalesFormControls) {
            this.percentOfPriorYearSalesFormControls = Object.keys(this.percentOfPriorYearSalesFormGroup.controls);
        }

        return [this.percentOfPriorYearSalesFormGroup, this.percentOfPriorYearSalesFormControls];
    }

    enrollmentWorkflowCheck() {
        if (this.loggedInUserRole === IdmUserRoles.PROGRAM_MANAGER && this.enrolmentStatus !== null && this.ApproverWorkflowStatusId !== null) {
            this.disableDealerInfo = false;
            this.disableDistributorInfo = false;
            this.disableSalesInfo = false;
            this.disableApproverInfo = false;

            if ((Number(this.enrolmentStatus) === enrolmentStatusValues.enrollmentStatus['Active'] && Number(this.ApproverWorkflowStatusId) === enrolmentStatusValues.workflowStatus['PM Approved']) || !(this.programManagersList.includes(this.loggedInUserName?.toLowerCase()))) {
                this.disableDealerInfo = true;
                this.disableDistributorInfo = true;
                this.disableSalesInfo = true;
                this.disableApproverInfo = true;
                this.isFormSubmitted = true;
            }
        }

        if (this.loggedInUserRole === IdmUserRoles.REGIONAL_SALES_MANAGER && this.enrolmentStatus !== null && this.ApproverWorkflowStatusId !== null) {
            this.disableDealerInfo = false;
            this.disableDistributorInfo = false;
            this.disableSalesInfo = false;
            this.disableApproverInfo = false;
            this.disableRegionalSalesManager = true;
            if ((Number(this.enrolmentStatus) === enrolmentStatusValues.enrollmentStatus['Pending Final'] && Number(this.ApproverWorkflowStatusId) === enrolmentStatusValues.workflowStatus['SM Approved']) || (Number(this.enrolmentStatus) === enrolmentStatusValues.enrollmentStatus['Active'] && Number(this.ApproverWorkflowStatusId) === enrolmentStatusValues.workflowStatus['PM Approved'])) {
                this.disableDealerInfo = true;
                this.disableDistributorInfo = true;
                this.disableSalesInfo = true;
                this.disableApproverInfo = true;
                this.isFormSubmitted = true;
            }
        }

        if (this.loggedInUserRole === IdmUserRoles.DEFAULT_DISTRIBUTOR && this.enrolmentStatus !== null && this.ApproverWorkflowStatusId !== null) {
            this.disableDealerInfo = true;
            this.disableDistributorInfo = true;
            this.disableSalesInfo = true;
            this.disableApproverInfo = true;
            if (Number(this.enrolmentStatus) === enrolmentStatusValues.enrollmentStatus.Returned && Number(this.ApproverWorkflowStatusId) === enrolmentStatusValues.workflowStatus['SM Returned']) {
                this.disableDealerInfo = false;
                this.disableDistributorInfo = false;
                this.disableSalesInfo = false;
                this.disableApproverInfo = true;
            }
            if ((Number(this.enrolmentStatus) === enrolmentStatusValues.enrollmentStatus['Pending Initial'] && Number(this.ApproverWorkflowStatusId) === enrolmentStatusValues.workflowStatus['Dist Submitted']) || (Number(this.enrolmentStatus) === enrolmentStatusValues.enrollmentStatus['Pending Final'] && Number(this.ApproverWorkflowStatusId) === enrolmentStatusValues.workflowStatus['SM Approved']) || (Number(this.enrolmentStatus) === enrolmentStatusValues.enrollmentStatus['Pending Initial'] && Number(this.ApproverWorkflowStatusId) === enrolmentStatusValues.workflowStatus['PM Returned'])) {
                this.isFormSubmitted = true;
            }
            if (Number(this.enrolmentStatus) === enrolmentStatusValues.enrollmentStatus['Active'] && Number(this.ApproverWorkflowStatusId) === enrolmentStatusValues.workflowStatus['PM Approved']) {
                this.disableDealerInfo = true;
                this.disableDistributorInfo = true;
                this.disableSalesInfo = true;
                this.disableApproverInfo = true;
                this.isFormSubmitted = true;
            }
        }
    }

    valueChangeListeners() {
        this.enrollmentFormGroup.get('salesInfoCommon.YearsDealerInProgram')?.valueChanges.subscribe((value) => {
            const option = value[0].name || value;
            if (Array.isArray(value)) {
                if (value[0].value === '') {
                    value[0].name = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_CHOOSE');
                    this.enrollmentFormGroup.get('salesInfoCommon.YearsDealerInProgram')?.setValue('');
                }
            }
            switch (option) {
                case '1':
                    this.enrollmentFormService.removeYearSale(this.enrollmentFormGroup, this.enrollmentFormConfiguration.salesInfoCommon, DealerExpectedYear.YEAR_TWO);
                    this.enrollmentFormService.removeYearSale(this.enrollmentFormGroup, this.enrollmentFormConfiguration.salesInfoCommon, DealerExpectedYear.YEAR_THREE);
                    if (this.enrollmentFormData.formType === FormType.NEW_DEALER) {
                        this.enrollmentFormService.removeYearPurchase(this.enrollmentFormGroup, this.enrollmentFormConfiguration.salesInformation, YearEstimatedPurchases.SECOND_YEAR_ESTIMATED_PURCHASES);
                        this.enrollmentFormService.removeYearPurchase(this.enrollmentFormGroup, this.enrollmentFormConfiguration.salesInformation, YearEstimatedPurchases.THIRD_YEAR_ESTIMATED_PURCHASES);
                    }
                    this.enrollmentFormData.salesInfoCommon.SecondYearFundingForDealer = '';
                    this.enrollmentFormData.salesInfoCommon.ThirdYearFundingForDealer = '';
                    break;
                case '2':
                    this.enrollmentFormService.addYearSale(this.enrollmentFormData.salesInfoCommon.SecondYearFundingForDealer, this.enrollmentFormGroup, this.enrollmentFormConfiguration.salesInfoCommon, DealerExpectedYear.YEAR_TWO);
                    this.enrollmentFormService.removeYearSale(this.enrollmentFormGroup, this.enrollmentFormConfiguration.salesInfoCommon, DealerExpectedYear.YEAR_THREE);
                    this.enrollmentFormData.salesInfoCommon.ThirdYearFundingForDealer = '';
                    if (this.enrollmentFormData.formType === FormType.NEW_DEALER) {
                        this.enrollmentFormService.addYearPurchase(this.enrollmentFormGroup, this.enrollmentFormConfiguration.salesInformation, YearEstimatedPurchases.SECOND_YEAR_ESTIMATED_PURCHASES);
                        this.enrollmentFormService.removeYearPurchase(this.enrollmentFormGroup, this.enrollmentFormConfiguration.salesInformation, YearEstimatedPurchases.THIRD_YEAR_ESTIMATED_PURCHASES);
                    }
                    break;
                case '3':
                    this.enrollmentFormService.addYearSale(this.enrollmentFormData.salesInfoCommon.SecondYearFundingForDealer, this.enrollmentFormGroup, this.enrollmentFormConfiguration.salesInfoCommon, DealerExpectedYear.YEAR_TWO);
                    this.enrollmentFormService.addYearSale(this.enrollmentFormData.salesInfoCommon.ThirdYearFundingForDealer, this.enrollmentFormGroup, this.enrollmentFormConfiguration.salesInfoCommon, DealerExpectedYear.YEAR_THREE);
                    if (this.enrollmentFormData.formType === FormType.NEW_DEALER) {
                        this.enrollmentFormService.addYearPurchase(this.enrollmentFormGroup, this.enrollmentFormConfiguration.salesInformation, YearEstimatedPurchases.SECOND_YEAR_ESTIMATED_PURCHASES);
                        this.enrollmentFormService.addYearPurchase(this.enrollmentFormGroup, this.enrollmentFormConfiguration.salesInformation, YearEstimatedPurchases.THIRD_YEAR_ESTIMATED_PURCHASES);
                    }
                    break;
                default:
                    break;
            }
            this.cdr.detectChanges();
        });

        this.enrollmentFormGroup.get('salesInfoCommon')?.valueChanges.subscribe((value) => {
            if (Array.isArray(value.FirstYearFundingForDealer)) {
                if (value.FirstYearFundingForDealer[0].value === '') {
                    value.FirstYearFundingForDealer[0].name = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_CHOOSE');
                    this.enrollmentFormGroup.get('salesInfoCommon.FirstYearFundingForDealer')?.setValue('');
                }
            }
            if (Array.isArray(value.secondYearFundingForDealer)) {
                if (value.secondYearFundingForDealer[0].value === '') {
                    value.secondYearFundingForDealer[0].name = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_CHOOSE');
                    this.enrollmentFormGroup.get('salesInfoCommon.secondYearFundingForDealer')?.setValue('');
                }
            }
            if (Array.isArray(value.thirdYearFundingForDealer)) {
                if (value.thirdYearFundingForDealer[0].value === '') {
                    value.thirdYearFundingForDealer[0].name = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_CHOOSE');
                    this.enrollmentFormGroup.get('salesInfoCommon.thirdYearFundingForDealer')?.setValue('');
                }
            }
        });

        this.enrollmentFormGroup.get('dealerInformation.DealerOwnerId')?.valueChanges.subscribe((values: Option[]) => {
            if (Array.isArray(values)) {
                if (values[0].value === '') {
                    if (this.enrollmentFormData.dealerInformation?.DealerOwnerId !== ('undefined' || null)) {
                        Object.keys(values).forEach((value) => {
                            this.enrollmentFormGroup.get('dealerInformation.DealerOwnerId')?.get(value)?.markAsDirty();
                        });
                    }
                    values[0].name = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_CHOOSE');
                    this.enrollmentFormGroup.get('dealerInformation.DealerOwnerId')?.setValue('');
                }
                const selectedOwner = this.enrolmentApiService.dealerOwnerInfo.filter((owner) => owner.ownerUserId.toString().toLowerCase() === values[0].value.toString().toLowerCase());
                this.enrollmentFormGroup.get('dealerInformation.dealerOwnerEmailAddress')?.setValue(selectedOwner[0]?.ownerEmail || '');
            }
        });

        const [salesBrandSoldFormGroup, salesBrandSoldFormControls] = this.getSalesBrandSoldFormVariables();
        const [percentOfPriorSalesFormGroup, percentOfPriorSalesFormControls] = this.getpercentOfPriorYearSalesFormVariables();

        salesBrandSoldFormControls.forEach((controlName, i) => {
            salesBrandSoldFormGroup.controls[controlName].valueChanges.subscribe((values) => {
                this.refreshSalesBrandSoldFormOptions(controlName, values);
                const percentControl = percentOfPriorSalesFormGroup.controls[percentOfPriorSalesFormControls[i]];

                if (values && values[0].value === '') {
                    percentControl?.setValue('');
                    percentControl?.disable();
                }

                if (values && values !== 'Please Select Brand') {
                    if (values[0].value !== '') {
                        percentControl?.enable();
                        percentControl?.setValidators([Validators.required]);
                    }
                }
                else {
                    percentControl?.clearValidators();
                    percentControl?.disable();
                }
                percentControl?.updateValueAndValidity();
            });
        });

        const approverInformationFormGroup = this.enrollmentFormGroup.get('approverInformation') as UntypedFormGroup;
        const approverActionFormControl = approverInformationFormGroup?.controls['ApproverAction'] as UntypedFormControl;
        if (this.displayApproverInfo) {
            approverInformationFormGroup?.valueChanges.subscribe(() => {
                approverActionFormControl.setValidators([Validators.required]);
                approverActionFormControl.valueChanges.subscribe((value) => {
                    if (value[0].value === '') {
                        this.isapprovalActionDisable = true;
                    }
                    if (value[0].value !== '') {
                        this.isapprovalActionDisable = false;
                    }
                });
            });
        }

        const splitOfBusinessFormGroup = this.enrollmentFormGroup.get('splitOfBusiness') as UntypedFormGroup;
        splitOfBusinessFormGroup?.valueChanges.subscribe((values) => {
            let sum: number = 0;
            let isAtLeastOneNonZero = false;
            let hasNegativeValue = false;
            const controls = splitOfBusinessFormGroup?.controls;
            Object.keys(values).forEach((key) => {
                const value = Number(values[key]);
                if (value < 0) {
                    hasNegativeValue = true;
                }
                if (value > 0) {
                    isAtLeastOneNonZero = true;
                }
                sum += value;
            });
            Object.keys(controls).forEach((key) => {
                const control = controls[key];
                if (control.value !== '') {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const errors: any = {};
                    if (hasNegativeValue) {
                        errors.negativeValue = true;
                    }
                    if (sum !== 100) {
                        control.setErrors({ sumNot100: true });
                    }
                    if (!isAtLeastOneNonZero) {
                        errors.atLeastOneNonZero = true;
                    }
                    if (Object.keys(errors).length > 0) {
                        control.markAsDirty();
                        control.setErrors(errors);
                        this.isSumHundred = false;
                    }
                    else {
                        control.markAsPristine();
                        control.setErrors(null);
                        this.isSumHundred = (sum === 100);
                        this.enrollmentFormService.totalSplitOfBusiness$.next(sum === 100);
                    }
                }
            });
        });

        this.enrollmentFormGroup.get('percentOfPriorYearSales')?.valueChanges.subscribe((values) => {
            let sum: number = 0;
            Object.keys(values).forEach((value) => {
                this.isSumHundred = true;
                if (!Number(values[value])) {
                    return;
                }
                sum += Number(values[value]);
                this.enrollmentFormService.percentOfPriorYearSales$.next(sum === 100);
                this.enrollmentFormGroup.get('percentOfPriorYearSales')?.get(value)?.markAsPristine();
                this.enrollmentFormGroup.get('percentOfPriorYearSales')?.get(value)?.setErrors(null);
                if (sum < 100) {
                    this.enrollmentFormGroup.get('percentOfPriorYearSales')?.get(value)?.markAsDirty();
                    this.isSumHundred = false;
                }
            });
        });

        this.enrollmentFormGroup.get('distributorInformation.distributorName')?.valueChanges.subscribe((values: Option[]) => {
            if (!Array.isArray(values) || values[0].name === 'Please Choose') {
                return;
            }

            const comapanyAddressOne = this.enrollmentFormService.distributors$.value.filter((dist) => `${dist.id}` === `${values[0].value}`)[0]?.companyAddressOne;
            const comapanyAddressTwo = this.enrollmentFormService.distributors$.value.filter((dist) => `${dist.id}` === `${values[0].value}`)[0]?.companyAddressTwo;
            const comapanyAddressCity = this.enrollmentFormService.distributors$.value.filter((dist) => `${dist.id}` === `${values[0].value}`)[0]?.city;
            const comapanyAddressState = this.enrollmentFormService.distributors$.value.filter((dist) => `${dist.id}` === `${values[0].value}`)[0]?.state;
            const comapanyAddressPostalCode = this.enrollmentFormService.distributors$.value.filter((dist) => `${dist.id}` === `${values[0].value}`)[0]?.postalCode;
            const comapanyAddressCountry = this.enrollmentFormService.distributors$.value.filter((dist) => `${dist.id}` === `${values[0].value}`)[0]?.country;

            this.distributorAddressParts = [comapanyAddressOne, comapanyAddressTwo, `${comapanyAddressCity}, ${comapanyAddressState}, ${comapanyAddressPostalCode}`, comapanyAddressCountry];
            this.enrollmentFormData.distributorInformation.distributorAddress = this.formatAddress(this.distributorAddressParts);
            this.enrollmentFormGroup.get('distributorInformation.DistributorCompanyIdForUI')?.setValue(this.enrollmentFormService.distributors$.value.filter((dist) => `${dist.id}` === `${values[0].value}`)[0]?.hvacCompanyId);

            this.enrollmentFormGroup.get('distributorInformation.distributorAddress')?.setValue(this.enrollmentFormData.distributorInformation.distributorAddress);

            const distributorCompanyName = this.formdata.distributorInformation.filter((dist) => dist.name === EnrollmentFormFieldType.DISTRIBUTOR_NAME)[0];
            if (distributorCompanyName) {
                distributorCompanyName.selectedId = (this.enrollmentFormService.distributors$.value.filter((dist) => `${dist.id}` === `${values[0].value}`))[0].id;
                (this.enrollmentFormGroup.get('distributorInformation') as UntypedFormGroup).addControl(EnrollmentFormFieldType.DISTRIBUTOR_COMPANY_ID, new FormControl(distributorCompanyName.selectedId));
                this.enrollmentFormGroup.get('distributorInformation.distributorName')?.setValue(this.enrollmentFormService.distributors$.value.filter((dist) => `${dist.id}` === `${values[0].value}`)[0]?.companyName);
            }

            const residentialSm = this.formdata.distributorInformation.filter((dist) => dist.name === EnrollmentFormFieldType.RESIDENTIAL_SM)[0];
            if (residentialSm) {
                residentialSm.options = this.enrollmentFormService.distributors$.value.filter((dist) => `${dist.id}` === `${values[0].value}`)[0]?.salesManagers.map((manager) => ({
                    name: `${manager.firstName} ${manager.lastName}`,
                    value: `${manager.userId}`
                }));
                residentialSm.selectedId = (this.enrollmentFormService.distributors$.value.filter((dist) => `${dist.id}` === `${values[0].value}`)[0].salesManagers.filter((rsm) => ((`${rsm.firstName} ${rsm.lastName}`) === this.enrollmentFormData.distributorInformation.DistributorSalesManagerId))[0]?.userId)?.toString();
                (residentialSm.selectedId) ? this.enrollmentFormGroup.get('distributorInformation.DistributorSalesManagerId')?.setValue(residentialSm.value) : this.enrollmentFormGroup.get('distributorInformation.DistributorSalesManagerId')?.setValue('');
            }

            const territoryManager = this.formdata.distributorInformation.filter((dist) => dist.name === EnrollmentFormFieldType.TERRITORY_MANAGERS)[0];
            if (territoryManager) {
                territoryManager.options = this.enrollmentFormService.distributors$.value.filter((dist) => `${dist.id}` === `${values[0].value}`)[0]?.territoryManagers.map((manager) => ({
                    name: `${manager.firstName} ${manager.lastName}`,
                    value: `${manager.userId}`
                }));
                territoryManager.selectedId = (this.enrollmentFormService.distributors$.value.filter((dist) => `${dist.id}` === `${values[0].value}`)[0].territoryManagers.filter((tManager) => ((`${tManager.firstName} ${tManager.lastName}`) === this.enrollmentFormData.distributorInformation.DistributorTerritoryManagerId))[0]?.userId)?.toString();
                (territoryManager.selectedId) ? this.enrollmentFormGroup.get('distributorInformation.DistributorTerritoryManagerId')?.setValue(territoryManager.value) : this.enrollmentFormGroup.get('distributorInformation.DistributorTerritoryManagerId')?.setValue('');
            }
        });

        this.enrollmentFormGroup.get('distributorInformation.DistributorSalesManagerId')?.valueChanges.subscribe((values: Option[]) => {
            if (Array.isArray(values)) {
                if (values[0].value === '') {
                    if (this.enrollmentFormData.distributorInformation.DistributorSalesManagerId !== ('undefined' || null)) {
                        Object.keys(values).forEach((value) => {
                            this.enrollmentFormGroup.get('distributorInformation.DistributorSalesManagerId')?.get(value)?.markAsDirty();
                        });
                    }
                    values[0].name = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_CHOOSE');
                    this.enrollmentFormGroup.get('distributorInformation.DistributorSalesManagerId')?.setValue('');
                }
            }
        });

        this.enrollmentFormGroup.get('distributorInformation.DistributorTerritoryManagerId')?.valueChanges.subscribe((values: Option[]) => {
            if (Array.isArray(values)) {
                if (values[0].value === '') {
                    if (this.enrollmentFormData.distributorInformation.DistributorTerritoryManagerId !== ('undefined' || null)) {
                        this.enrollmentFormGroup.get('distributorInformation.DistributorTerritoryManagerId')?.setErrors(null);
                    }
                    values[0].name = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_CHOOSE');
                    this.enrollmentFormGroup.get('distributorInformation.DistributorTerritoryManagerId')?.setValue('');
                }
            }
        });
    }

    refreshSalesBrandSoldFormOptions(crntEditedCntrlName: string, optionValue: Option[] = []) {
        const selectedOptionValue = optionValue?.length > 0
            ? optionValue[0]
            : {
                name: '',
                value: ''
            };
        const crntEditedCntrlPrvsVal = (this.salesBrandSoldFormValuesMap.get(crntEditedCntrlName) || {}).name;

        this.brandSoldArray = this.brandSoldArray.filter((val) => val !== crntEditedCntrlPrvsVal);
        this.salesBrandSoldFormValuesMap.set(crntEditedCntrlName, selectedOptionValue);
        this.brandSoldArray.push(selectedOptionValue.name);
        this.setEachSalesBrandSoldOptions();
    }

    getBrandSoldFieldAtIndex(ind: number): string {
        switch (ind) {
            case 0:
                return this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.FIRST_BRAND_SOLD');
            case 1:
                return this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.SECOND_BRAND_SOLD');
            case 2:
                return this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.THIRD_BRAND_SOLD');
            case 3:
                return this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.FOURTH_BRAND_SOLD');
            default:
                return '';
        }
    }

    setEachSalesBrandSoldOptions() {
        const [, salesBrandSoldFormControls] = this.getSalesBrandSoldFormVariables();

        for (let i = 0; i < (salesBrandSoldFormControls?.length || 0); i++) {
            const cnrtBrandSoldOption = this.salesBrandSoldFormValuesMap.get(this.getBrandSoldFieldAtIndex(i));

            if (this.enrollmentFormConfiguration.salesBrandSold[i]) {
                this.enrollmentFormConfiguration.salesBrandSold[i].options = this.enrolmentBrandListOptions.filter(
                    (option) => !this.brandSoldArray.includes(option.name)
                );

                if (cnrtBrandSoldOption && cnrtBrandSoldOption.value) {
                    this.enrollmentFormConfiguration.salesBrandSold[i].options?.push(cnrtBrandSoldOption);
                }
            }
        }
    }

    onSubmit() {
        this.isFormSubmitted = true;
        const [percentOfPriorSalesFormGroup, percentOfPriorSalesFormControls] = this.getpercentOfPriorYearSalesFormVariables();
        this.enrolmentApiService.setEnrollmentStatus(this.enrollmentFormGroup, this.loggedInUserRole, this.enrolmentStatus, this.ApproverWorkflowStatusId);
        if ((this.enrollmentFormGroup.get('dealerInformation') as UntypedFormGroup).get(EnrollmentFormFieldType.DEALER_COMPANY_ID) === null) {
            const dealerCompanyId = `${this.enrollmentFormData.dealerInformation.DealerCompanyId}`.toLowerCase().toString();
            (this.enrollmentFormGroup.get('dealerInformation') as UntypedFormGroup).addControl(EnrollmentFormFieldType.DEALER_COMPANY_ID, new FormControl(dealerCompanyId));
        }
        if ((this.enrollmentFormGroup.get('distributorInformation') as UntypedFormGroup).get(EnrollmentFormFieldType.DISTRIBUTOR_COMPANY_ID) === null) {
            const distributorCompanyId = `${this.enrollmentFormData.distributorInformation.DistributorCompanyId}`.toLowerCase().toString();
            (this.enrollmentFormGroup.get('distributorInformation') as UntypedFormGroup).addControl(EnrollmentFormFieldType.DISTRIBUTOR_COMPANY_ID, new FormControl(distributorCompanyId));
        }
        if (percentOfPriorSalesFormGroup?.status === 'DISABLED') {
            percentOfPriorSalesFormControls?.forEach((_var, i) => {
                const percentControl = percentOfPriorSalesFormGroup?.controls[percentOfPriorSalesFormControls[i]];
                percentControl?.enable();
            });
        }

        this.enrolmentApiService.postEnrollmentFormData(this.enrollmentFormGroup, this.formdata, this.formType).pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => {
            this.enrollmentFormService.toastMessage(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.FORM_SUCCESS'), 'success');
            this.enrollmentFormGroup.disable();
            this.enrollmentFormGroup.markAsPristine();
        },
        (error) => {
            this.enrollmentFormService.toastMessage(error.error?.message, 'error');
            this.isFormSubmitted = false;
        });
    }

    onClose() {
        this.router.navigate([`/Admin/Program/search-program/manage-enrollments/${this.programId}`]);
    }

    private createForm() {
        this.enrollmentFormGroup = this.formBuilder.group({
            dealerInformation: new UntypedFormGroup(this.mapFormControls(this.enrollmentFormData.dealerInformation, this.enrollmentFormConfiguration.dealerInformation)),
            distributorInformation: new UntypedFormGroup(this.mapFormControls(this.enrollmentFormData.distributorInformation, this.enrollmentFormConfiguration.distributorInformation)),
            salesInformation: new UntypedFormGroup(this.mapFormControls(this.enrollmentFormData.salesInformation, this.enrollmentFormConfiguration.salesInformation)),
            salesInfoCommon: new UntypedFormGroup(this.mapFormControls(this.enrollmentFormData.salesInfoCommon, this.enrollmentFormConfiguration.salesInfoCommon)),
            splitOfBusiness: new UntypedFormGroup(this.mapFormControls(this.enrollmentFormData.splitOfBusiness, this.enrollmentFormConfiguration.splitOfBusiness)),
            salesBrandSold: new UntypedFormGroup(this.mapFormControls(this.enrollmentFormData.salesBrandSold, this.enrollmentFormConfiguration.salesBrandSold)),
            percentOfPriorYearSales: new UntypedFormGroup(this.mapFormControls(this.enrollmentFormData.percentOfPriorYearSales, this.enrollmentFormConfiguration.percentOfPriorYearSales)),
            comments: new UntypedFormControl(this.enrollmentFormData.distributorComment),
            approverInformation: new UntypedFormGroup(this.mapFormControls(this.enrollmentFormData.approverInformation, this.enrollmentFormConfiguration.approverInformation))
        });

        this.enrollmentFormService.getEnrollmentFormManagers(this.companyId, this.programId, this.enrollmentFormConfiguration, this.enrollmentFormData).subscribe((data) => {
            data.dealerInformation.map((field) => {
                if (field.name === EnrollmentFormFieldType.DEALER_OWNER) {
                    const dealerOwnerId = field.options?.filter((option) => option.value.toLowerCase().toString() === `${this.enrollmentFormData.dealerInformation.DealerOwnerId}`.toLowerCase().toString())[0]?.name;
                    this.enrollmentFormData.dealerInformation.DealerOwnerId = dealerOwnerId;
                    field.value = dealerOwnerId;
                    (this.enrollmentFormGroup.get('dealerInformation') as UntypedFormGroup).get(EnrollmentFormFieldType.DEALER_OWNER)?.setValue(dealerOwnerId);
                }

                return field;
            });

            data.salesInformation.map((field) => {
                if (field.name === EnrollmentFormFieldType.ALL_BRAND_PURCHASE_DUSTLESS) {
                    field.label = `${this.brandName} ${field.label}`;
                }

                return field;
            });

            data.salesInformation.map((field) => {
                if (field.name === EnrollmentFormFieldType.BRAND_PERCENTAGE_CURRENT_YEAR) {
                    field.label = field.label?.replace(EnrollmentFormFieldType.BRAND, this.brandName);
                }

                return field;
            });

            this.dealerAddressParts = [this.enrolmentApiService?.dealerOwnerAddressOne, this.enrolmentApiService?.dealerOwnerAddressTwo, `${this.enrolmentApiService.dealerOwnerAddressCity}, ${this.enrolmentApiService.dealerOwnerAddressState}, ${this.enrolmentApiService.dealerOwnerPostalCode}`, this.enrolmentApiService.dealerOwnerCountry];
            this.enrollmentFormData.dealerInformation.dealerAddress = this.formatAddress(this.dealerAddressParts);

            this.distributorAddressParts = [this.enrolmentApiService?.distributorManagerCompanyAddressOne, this.enrolmentApiService?.distributorManagerCompanyAddressTwo, `${this.enrolmentApiService.distributorManagerCity}, ${this.enrolmentApiService.distributorManagerState}, ${this.enrolmentApiService.distributorManagerPostalCode}`, this.enrolmentApiService.distributorManagerCountry];
            this.enrollmentFormData.distributorInformation.distributorAddress = this.formatAddress(this.distributorAddressParts);

            if (this.enrolmentApiService?.distributorSubmittedBy) {
                this.enrollmentFormData.distributorInformation.distributorSubmittedBy = `${this.enrolmentApiService?.distributorManagerFirstName} ${this.enrolmentApiService?.distributorManagerLastName} (${this.enrolmentApiService?.distributorManagerUserId})`;
            }

            data.distributorInformation.map((field) => {
                if (field.name === EnrollmentFormFieldType.RESIDENTIAL_SM) {
                    this.residentialSalesManagerValue = field.options?.filter((option) => option.value.toLowerCase().toString() === `${this.enrollmentFormData.distributorInformation.DistributorSalesManagerId}`.toLowerCase().toString())[0]?.name;
                    this.enrollmentFormData.distributorInformation.DistributorSalesManagerId = this.residentialSalesManagerValue;
                    field.value = this.residentialSalesManagerValue;
                    (this.enrollmentFormGroup.get('distributorInformation') as UntypedFormGroup).get(EnrollmentFormFieldType.RESIDENTIAL_SM)?.setValue(this.residentialSalesManagerValue);
                }

                return field;
            });

            if (this.loggedInUserRole === IdmUserRoles.REGIONAL_SALES_MANAGER && this.enrolmentStatus !== null && this.ApproverWorkflowStatusId !== null && this.residentialSalesManagerValue?.toLowerCase() !== this.loggedInUserName?.toLowerCase()) {
                this.disableDealerInfo = true;
                this.disableDistributorInfo = true;
                this.disableSalesInfo = true;
                this.disableApproverInfo = true;
                this.isFormSubmitted = true;
            }

            data.distributorInformation.map((field) => {
                if (field.name === EnrollmentFormFieldType.TERRITORY_MANAGERS) {
                    const territoryManagersId = field.options?.filter((option) => option.value === `${this.enrollmentFormData.distributorInformation.DistributorTerritoryManagerId}`)[0]?.name;
                    this.enrollmentFormData.distributorInformation.DistributorTerritoryManagerId = territoryManagersId;
                    (this.enrollmentFormGroup.get('distributorInformation') as UntypedFormGroup).get(EnrollmentFormFieldType.TERRITORY_MANAGERS)?.setValue(territoryManagersId);
                }

                return field;
            });

            this.formdata = data;
            this.updateConfigurationWithEnrollmentFormData();
        });
    }

    private formatAddress(dealerAddressParts: string[]) {
        this.formattedNewAddress = '';
        for (const addressParts of dealerAddressParts) {
            if (addressParts !== null) {
                this.formattedNewAddress += `${addressParts}\n`;
            }
        }

        return this.formattedNewAddress;
    }

    private updateConfigurationWithEnrollmentFormData() {
        Object.keys(this.enrollmentFormConfiguration).forEach((enrolmentConfig) => {
            this.enrollmentFormConfiguration[enrolmentConfig as keyof EnrollmentForm].forEach((enrollmentInfo) => {
                if (this.enrollmentFormData[`${enrolmentConfig}` as keyof {}]) {
                    enrollmentInfo.value = String(this.enrollmentFormData[`${enrolmentConfig}` as keyof {}][`${enrollmentInfo.name}` as keyof {}] || '');
                    (this.enrollmentFormGroup.get(enrolmentConfig) as UntypedFormGroup)?.controls[`${enrollmentInfo.name}`]?.patchValue(enrollmentInfo.value, { emitEvent: true });
                }
            });
        });
    }

    private mapFormControls(data: {}, inputFields: EnrollmentFormField[]) {
        const fieldsControls: {[key:string]: UntypedFormArray | UntypedFormControl} = {};

        inputFields?.forEach(
            (field) => {
                fieldsControls[field?.name] = new UntypedFormControl(
                    data[field.name as keyof typeof data] || '',
                    this.enrollmentFormService.addValidator(field.rules as keyof typeof data),
                    this.enrollmentFormService.addAsyncValidator(field.asyncRules as keyof typeof data, field.name)
                );
            }
        );

        return fieldsControls;
    }
}
