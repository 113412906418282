<ng-container *ngIf="(dataSharingPermissions$ | async) as dataSharingPermissions">
    <div class="hvac-row">
        <div class="hvac-col-xs-12">
            <div class="hvac-cp-card">
                <hvac-product-details-card *ngIf="(productData$ | async) as productData; else productDetailLoader"
                    [product]="productData.productInfo"
                    [isConnectionStatusVisible]="true"
                    [dealerId] = "dealerId"
                    [propertyId] = "propertyId"
                    [systemInfo]="productData.system"
                    [isRuntimeReportEnabled]="productData.isRuntimeReportEnabled"
                    [isDeviceConnected]="productData.isWallControlConnected"
                    (onRunReportClick)="contextService.isReportModalVisible$.next(true)"
                    [isRemoveControlEnabled]="true"></hvac-product-details-card>
                <ng-template #productDetailLoader>
                    <div class="hvac-product-detail-loader">
                        <utc-loading-spinner></utc-loading-spinner>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="dataSharingPermissions?.systemConditions" class="hvac-system-conditions-container hvac-cp-card">
                <div class="hvac-row hvac-end-md hvac-rhythm-4">
                    <div class="hvac-zones-header hvac-col-xs-12">
                        <div>
                            <div *ngIf="(wallControlStatus$ | async) as wallControlStatus"
                                class="hvac-zone-status-date hvac-p-sm">{{ 'CONNECTED_PORTAL.WALL_CONTROL.STATUS_AS_OF'
                                | translate }} {{ wallControlStatus.dateTime | dateTimeTz }}</div>
                        </div>
                    </div>
                </div>
                <hvac-ecobee-ni-system-conditions *ngIf="(esn$ | async) as esn"
                    [systemType]="systemType"
                    [serialNo]="serialNo"
                    [dealerId]="dealerId"
                    [esn]="esn">
                </hvac-ecobee-ni-system-conditions>
            </div>
        </div>
    </div>
    <ng-container *ngIf="(accordions$ | async) as accordions">
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new
                    [disabled]="!dataSharingPermissions?.eventLog"
                    [open]="accordions.eventLog && dataSharingPermissions?.eventLog === true"
                    (toggle)="toggleAccordion$.next('eventLog')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-wall-control-event-log
                            *ngIf="(wallControlEventLogData$ | async) as eventLogData"
                            [config]="eventLogData.config"
                            [data]="eventLogData.items"
                            [totalPages]="eventLogData.totalPages"
                            [currentPage]="eventLogData.currentPage"
                            (onPageChange)="wallControlEventLogCurrentPage$.next($event)"></hvac-wall-control-event-log>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
    </ng-container>
</ng-container>




