import { FormGroup, FormControl } from '@angular/forms';

export enum DisabledDropdownStrings {
    contactType = 'contactType',
    rsm = 'rsm',
    tsm = 'tsm',
    accountManager = 'accountManager',
    pricingAnal = 'pricingAnal'
}
export interface Values {
    contactType: boolean;
    rsm: boolean;
    tsm: boolean;
    accountManager: boolean;
    pricingAnal: boolean;
}

export class AdvancedSearchFormGroupConfig {
    static createFormGroup(): FormGroup {
        return new FormGroup({
            accountManager: new FormControl(''),
            address1: new FormControl(''),
            brand: new FormControl(''),
            city: new FormControl(''),
            companyType: new FormControl(''),
            readonlyCompanyType: new FormControl(''),
            entityType: new FormControl(''),
            distributorBranch: new FormControl(''),
            companyName: new FormControl(''),
            companyId: new FormControl(''),
            contactType: new FormControl(''),
            country: new FormControl(''),
            email: new FormControl(''),
            firstName: new FormControl(''),
            fax: new FormControl(''),
            lastName: new FormControl(''),
            id: new FormControl(''),
            locationBranch: new FormControl(false),
            locationMain: new FormControl(false),
            payer: new FormControl(''),
            cin: new FormControl(''),
            hvp: new FormControl(''),
            phone: new FormControl(''),
            priceGroup: new FormControl(''),
            priceList: new FormControl(''),
            relationshipType: new FormControl(''),
            rsm: new FormControl(''),
            shipTo: new FormControl(''),
            soldTo: new FormControl(''),
            title: new FormControl(''),
            region: new FormControl(''),
            statusActive: new FormControl(false),
            statusInactive: new FormControl(false),
            tsm: new FormControl(''),
            postalCode: new FormControl(''),
            pricingAnal: new FormControl(''),
            parentCompanyID: new FormControl(''),
            parentCompanyName: new FormControl(''),
            createdDate: new FormControl(''),
            distributorID: new FormControl(''),
            soldToNumber: new FormControl(''),
            status: new FormControl(''),
            type: new FormControl(''),
            tierLevel: new FormControl(''),
            startDate: new FormControl(''),
            endDate: new FormControl(''),
            statusPendingApproval: new FormControl(''),
            statusProspect: new FormControl(''),
            prsm: new FormControl(''),
            distributorCompanyID: new FormControl('')
        });
    }
}
