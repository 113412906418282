import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'common/environments/environment';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { BreakPointService } from '../../../../common/services/breakpoint.service';
import { AccountAdminService } from '../../services/account-admin/account-admin.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-account-admin-results',
    templateUrl: './account-admin-results.component.html',
    styleUrls: ['./account-admin-results.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccountAdminResultsComponent implements OnInit {
    public ngOnDestroy$ = new Subject();
    allCompanySelected = false;
    allContactSelected = false;
    currentPage = 1;
    totalPages = 10;
    isResultPage = true;
    isCreateUserFeatureAvailable = environment.features.accountAdminCreateUser;
    adminAccess: boolean;

    constructor(
        public accountAdminService: AccountAdminService,
        private breakpointService: BreakPointService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.breakpointService.breakpoint$.pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => {
            this.accountAdminService.formOpen$.next(this.breakpointService.isDesktop());
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.route.queryParamMap.subscribe((value: any) => {
            this.accountAdminService.fromQueryParams(value);
        });
        this.adminAccess = (this.route.snapshot.data['adminAccess'] as IdmUserRoles[])?.includes(IdmUserRoles.ACCOUNTADMIN);
    }

    public expand(currentValue: boolean) {
        this.accountAdminService.formOpen$.next(!currentValue);
    }
}
