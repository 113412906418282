import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { environment } from 'common/environments/environment';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { Option } from '../../../../common/components/select/select.component';
import { AccountAdminBrandsItemsEntity, AccountAdminContactType, AccountAdminFormFields, AccountAdminFormResultMap, AccountAdminManagersEntity, Company, CompanyContact, Contact, DataHeader, RelationshipType } from '../../models/account-admin-search';
import { ApiOptions } from '../../../../common/services/api-options/api-options.service';
import { AccountAdminConfiguratrion, AccountAdminUtilsService, CompanyCategoryResult } from './account-admin-utils.service';
import { AccountAdminCommonComponentService } from './account-admin-common-constants.service';
import { DistributorSearchCriteria } from 'private/app/models/company.model';
import { AccountAdminParentForm } from './account-admin-form-field.service';
import { AdvancedSearchFormGroupConfig } from './account-admin-form-group-config';
import { AccountPostAdminService } from './account-post-admin.service';
import { AccountAdminExtendedService } from './account-admin-extended.service';
import { LegalEntityResponse } from 'private/app/models/account-admin-search-results';
import { CommonUtilsService } from '../common-utils.service';
import { CacheObservableResponse } from 'private/app/models/Cache-Observable-Response';
import { AddressLookup } from 'private/app/components/address-lookup/address-lookup.component';
import { OktaService } from 'common/services/okta/okta.service';
import { IdToken } from 'common/models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AccountAdminService {
    public advancedSearchFormDefault: FormGroup<AccountAdminParentForm>;
    public advancedSearchForm: FormGroup<AccountAdminParentForm>;
    public companyDetailsForm: UntypedFormGroup;
    public currentTab = 'companies';
    public formOpen$ = new BehaviorSubject<boolean>(true);
    public lastQueryParams = new BehaviorSubject<Partial<AccountAdminFormFields>>({});
    public languageOptions = this.accountAdminUtilsService?.getLanguageOptions() || [];
    public ownershipOptions = this.accountAdminUtilsService?.getOwnerShipOptions() || [];
    public relationshipStatusOptions = this.accountAdminCommonComponents?.relationshipStatusOptions || [];
    public relationshipTypeOptions = this.accountAdminCommonComponents?.relationshipTypeOptions || [];
    public tierLevelOptions = this.accountAdminCommonComponents?.tierLevelOptions || [];
    public createdDateOptions = this.accountAdminCommonComponents?.createdDateOptions || [];
    public isModalVisible: boolean;
    public isInternalUser$ = new BehaviorSubject<boolean>(false);
    public dateCriteria : string = '';
    public selectedLegalEntityValue$ = new BehaviorSubject<string>('');
    private cacheAccountAdminSearchFormResponse: CacheObservableResponse<AccountAdminFormResultMap>;

    constructor(
        public accountPostAdminService: AccountPostAdminService,
        private oktaService: OktaService,
        private translate: TranslateService,
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService,
        private readonly accountAdminUtilsService: AccountAdminUtilsService,
        private readonly accountAdminCommonComponents: AccountAdminCommonComponentService,
        private readonly commonUtils: CommonUtilsService,
        public readonly accountAdminExtendedService: AccountAdminExtendedService
    ) {
        this.advancedSearchFormDefault = (this.accountAdminUtilsService.formFields);
        this.advancedSearchForm = (this.accountAdminUtilsService.formFields);
        this.companyDetailsForm = this.accountAdminUtilsService.companyDetailsForm;
        this.advancedSearchForm = AdvancedSearchFormGroupConfig.createFormGroup();
        this.oktaService.idToken$.subscribe((res: IdToken) => {
            if (res?.claims?.idm_user_companies) {
                this.isInternalUser$.next(res?.claims?.idm_user_companies[0]?.companyCategory?.name === this.translate.instant('ACCOUNT_ADMIN.INTERNAL'));
            }
        });
    }

    public createAssociatedDistributerSearchBody(company: Company, pagination: number | null, sortParams: string[] | null, companyType: string) {
        const body: DistributorSearchCriteria = {
            criteria: {
                companyId: company?.hvacCompanyId,
                companyTypeCode: companyType,
                postalCode: '',
                getAssociatedBrands: false
            },
            pageParams: {
                page: (pagination || 1) - 1,
                pageSize: 5
            },
            determineTotalCount: true,
            sortFields: sortParams?.length ? sortParams : []
        };
        if (companyType === 'DCR') {
            body.criteria.getAssociatedBrands = true;
        }

        return body;
    }

    public createSearchBody(formValue: Partial <AccountAdminFormFields>, pagination: number | null, sortParams: string | null, section: string) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: any = {
            criteria: {
                companyCriteria:
                { companyTypeCodes: [] },
                contactTypeIds: [],
                includeContactsInResponse: false
            },
            pageParams: {
                page: (pagination || 1) - 1,
                pageSize: 10
            },
            determineTotalCount: true
        };

        // Create Structure
        if (sortParams?.length) {
            body.sortFields = [sortParams];
        }

        if (this.accountAdminUtilsService.hasEmployeeContacts(formValue)) {
            body.criteria.companyCriteria.employeeContacts = {};
        }

        if (section === 'userCriteria' || this.accountAdminUtilsService.hasContactCriteria(formValue)) {
            body.criteria.userCriteria = {};
            body.criteria.includeContactsInResponse = false;
        }

        // Contact
        formValue.firstName && (body.criteria.userCriteria.firstName = `*${formValue.firstName}*`);
        formValue.lastName && (body.criteria.userCriteria.lastName = `*${formValue.lastName}*`);
        formValue.id && (body.criteria.userCriteria.id = `*${formValue.id}*`);
        formValue.title && (body.criteria.userCriteria.title = `*${formValue.title}*`);

        // Address
        formValue.address1 && (body.criteria[section].addressLine1 = `*${formValue.address1}*`);
        formValue.city && (body.criteria[section].city = `*${formValue.city}*`);
        (typeof (formValue.region) === 'string') ? formValue.region && (body.criteria.companyCriteria.regionCodes = [formValue.region]) : formValue.region && (body.criteria.companyCriteria.regionCodes = formValue.region.map((regionOption) => regionOption.value));
        formValue.postalCode && (body.criteria[section].postalCode = `*${formValue.postalCode}*`);

        // INFO
        formValue.email && (body.criteria[section].emailAddress = `*${formValue.email}*`);
        formValue.phone && (body.criteria[section].phoneNumber = `*${formValue.phone}*`);

        formValue.country?.length && formValue.country?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria[section].countryCodes = formValue.country.map((country: Option) => country.value));
        formValue.fax && (body.criteria[section].faxNumber = `*${formValue.fax}*`);

        if (formValue.statusActive && !formValue.statusInactive) {
            body.criteria[section].statuses = ['ACTIVE'];
        }
        else if (!formValue.statusActive && formValue.statusInactive) {
            body.criteria[section].statuses = ['INACTIVE'];
        }
        else if (formValue.statusActive && formValue.statusInactive) {
            body.criteria[section].statuses = ['ACTIVE', 'INACTIVE'];
        }

        // Contact Types
        formValue.contactType?.length && formValue.contactType?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.contactTypeIds = formValue.contactType.map((contactType: Option) => contactType.value));

        // Company Criteria
        formValue.entityType?.length && (body.criteria.legalEntity = Number(formValue.entityType[0].value));
        formValue.distributorBranch?.length && formValue.distributorBranch?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.distBranch = formValue.distributorBranch[0].value);
        formValue.companyType?.length && formValue.companyType?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.companyCriteria.companyTypeCodes = formValue.companyType.map((type: Option) => type.value));
        formValue.relationshipType?.length && formValue.relationshipType?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.companyCriteria.relationShipTypes = formValue.relationshipType.map((type: Option) => type.value));
        formValue.brand?.length && formValue.brand?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.companyCriteria.brandCodes = formValue.brand.map((brand: Option) => brand.value));
        formValue.companyName && (body.criteria.companyCriteria.name = `*${formValue.companyName}*`);
        formValue.companyId && (body.criteria.companyCriteria.hvacCompanyIds = [Number(formValue.companyId)]);
        formValue.payer && (body.criteria.companyCriteria.payerCustomerId = `*${formValue.payer}*`);
        formValue.cin && (body.criteria.companyCriteria.cin = formValue.cin);
        formValue.hvp && (body.criteria.companyCriteria.hvp = formValue.hvp);
        formValue.priceList && (body.criteria.companyCriteria.priceListType = `*${formValue.priceList}*`);
        formValue.priceGroup && (body.criteria.companyCriteria.priceGroup = `*${formValue.priceGroup}*`);
        formValue.shipTo && (body.criteria.companyCriteria.shipToCustomerId = `*${formValue.shipTo}*`);
        formValue.soldTo && (body.criteria.companyCriteria.soldToCustomerId = `*${formValue.soldTo}*`);
        formValue.parentCompanyID && (body.criteria.companyCriteria.parentCompanyId = [Number(formValue.parentCompanyID)]);
        formValue.parentCompanyName && (body.criteria.companyCriteria.parentCompanyName = `*${formValue.parentCompanyName}*`);
        formValue.soldToNumber && (body.criteria.companyCriteria.relationshipSoldTo = [String(formValue.soldToNumber)]);
        formValue.distributorID && (body.criteria.companyCriteria.relationshipDistributorIds = [Number(formValue.distributorID)]);
        formValue.type?.length && formValue.type?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.companyCriteria.relationShipTypes = formValue.type.map((type: Option) => type.name));
        formValue.tierLevel?.length && formValue.tierLevel?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.companyCriteria.tierLevel = formValue.tierLevel.map((tierLevel: Option) => tierLevel.name));
        formValue.status?.length && formValue.status?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.companyCriteria.relationShipStatuses = formValue.status.map((status: Option) => status.name));
        formValue.createdDate?.length && formValue.createdDate?.filter((item: Option) => item.value && item.value !== '').length && (this.dateCriteria = formValue.createdDate[0].value);

        if (formValue.createdDate?.length && this.dateCriteria === '1') {
            formValue.startDate && (body.criteria.companyCriteria.relCreatedStartDate = String(formValue.startDate));
            formValue.endDate && (body.criteria.companyCriteria.relCreatedEndDate = String(formValue.endDate));
        }
        else if (formValue.createdDate?.length) {
            formValue.startDate && (body.criteria.companyCriteria.relModifiedStartDate = String(formValue.startDate));
            formValue.endDate && (body.criteria.companyCriteria.relModifiedEndDate = String(formValue.endDate));
        }

        if (formValue.locationMain && !formValue.locationBranch) {
            body.criteria.companyCriteria.locationTypes = ['MAIN'];
        }
        else if (!formValue.locationMain && formValue.locationBranch) {
            body.criteria.companyCriteria.locationTypes = ['BRANCH'];
        }
        else if (formValue.locationMain && formValue.locationBranch) {
            body.criteria.companyCriteria.locationTypes = ['MAIN', 'BRANCH'];
        }

        // Managers
        if (this.accountAdminUtilsService.hasEmployeeContacts(formValue)) {
            body.criteria.companyCriteria.employeeContacts = {};

            formValue.accountManager?.length && (body.criteria.companyCriteria.employeeContacts['AM'] = formValue.accountManager.map((contact: Option) => contact.value));
            formValue.accountManager?.length && (body.criteria.companyCriteria.employeeContacts['PTAM'] = formValue.accountManager.map((contact: Option) => contact.value));
            formValue.rsm?.length && (body.criteria.companyCriteria.employeeContacts['RSM'] = formValue.rsm.map((contact: Option) => contact.value));
            formValue.rsm?.length && (body.criteria.companyCriteria.employeeContacts['PRSM'] = formValue.rsm.map((contact:Option) => contact.value));
            formValue.tsm?.length && (body.criteria.companyCriteria.employeeContacts['TSM'] = formValue.tsm.map((contact: Option) => contact.value));
            formValue.pricingAnal?.length && (body.criteria.companyCriteria.employeeContacts['PA'] = formValue.pricingAnal.map((contact: Option) => contact.value));
        }

        if ((body.criteria.userCriteria && Object.keys(body.criteria.userCriteria).length === 0)) {
            delete body.criteria.userCriteria;
        }

        return body;
    }

    public createQueryParams(form: UntypedFormGroup): Partial<AccountAdminFormFields> {
        const formArray = Object.entries(form.value).map((entry: [string, unknown]) => {
            const [key, val] = entry;

            if (!val || ((typeof val !== 'boolean') && Object.keys(val).length === 0)) {
                return false;
            }

            return { [key]: typeof val === 'object' ? JSON.stringify(val) : val };
        }).filter(Boolean);

        return Object.assign({}, ...formArray);
    }

    public filterContactsByType(contacts: CompanyContact[], type: string): CompanyContact[] {
        return contacts?.filter((contact: CompanyContact) => contact.contactType === type);
    }

    public fromQueryParams(params: {params:{soldTo:string, shipTo?:string}}) {
        this.advancedSearchForm.patchValue(Object.assign({}, ...this.accountAdminUtilsService.populatedFormFields(params.params)));
    }

    public formatDate(date: string): string {
        return new Date(date).toLocaleString();
    }

    public getAccountAdminContactsById(id: string): Observable<Company> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<Company>(`${environment.api.accountAdminContactsById}/${id}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getAccountAdminContactById(id: string): Observable<Contact[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<Contact[]>(`${environment.api.accountAdminContactById}/${id}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getAccountAdminSearchForm(accountAdminConfiguration?: AccountAdminConfiguratrion): Observable<AccountAdminFormResultMap> {
        if (!this.cacheAccountAdminSearchFormResponse) {
            const getAccountAdminSearchFormObservable = () => forkJoin([
                this.getBrands(),
                this.getContactTypes(),
                this.commonUtils.getCountriesEntity(),
                this.getManagers(),
                this.getCompanyTypes(),
                this.getRelationshipTypes(),
                this.getLegalEntities()
            ]).pipe(
                map((res) => this.accountAdminUtilsService.mapAccountAdminSearchForm(res, accountAdminConfiguration))
            );

            this.cacheAccountAdminSearchFormResponse = CacheObservableResponse.createCachedClass<AccountAdminFormResultMap>(
                this.cacheAccountAdminSearchFormResponse,
                getAccountAdminSearchFormObservable,
                this.getAccountAdminSearchForm.name
            );
        }

        return this.cacheAccountAdminSearchFormResponse.value();
    }

    public updateDataHeaders(item: string, dataHeaders: DataHeader[]): DataHeader[] {
        return dataHeaders.map((header: DataHeader) => {
            if (header.value === item?.split('-')[0]) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const order: any = item?.split('-')[1];

                header.order = order;
            }
            else {
                header.order = null;
            }

            return header;
        });
    }

    public getRelationshipTypes(): Observable<RelationshipType[]> {
        return this.accountAdminExtendedService.getRelationshipTypes();
    }

    public getBrands(): Observable<AccountAdminBrandsItemsEntity[]> {
        return this.accountAdminExtendedService.getBrands(this.isInternalUser$.value);
    }

    public getContactTypes(): Observable<AccountAdminContactType[]> {
        return this.accountAdminExtendedService.getContactTypes();
    }

    public getManagers(): Observable<AccountAdminManagersEntity> {
        return this.accountAdminExtendedService.getManagers();
    }

    public getCompanyTypes(): Observable<CompanyCategoryResult[]> {
        return this.accountAdminExtendedService.getCompanyTypes();
    }

    public getLegalEntities(): Observable<LegalEntityResponse[]> {
        return this.accountAdminExtendedService.legalEntities();
    }

    companyAddressToValidate(companyDetailsForm: UntypedFormGroup): AddressLookup {
        const form = companyDetailsForm.controls;

        return {
            address1: form.address1.value,
            address2: form.address2.value,
            city: form.city.value,
            state: {
                label: form.state.value[0].name ?? form.state.value,
                code: form.state.value[0].value ?? form.state.value
            },
            country: {
                label: form.country.value[0].name ?? form.country.value,
                code: form.country.value[0].value ?? form.country.value
            },
            postalCode: form.postalCode.value
        };
    }

    onAddressConfirmation(address: AddressLookup): void {
        this.companyDetailsForm.controls.address1.setValue(address.address1, { emitEvent: false });
        this.companyDetailsForm.controls.address2.setValue(address.address2, { emitEvent: false });
        this.companyDetailsForm.controls.city.setValue(address.city, { emitEvent: false });
        this.companyDetailsForm.controls.state.setValue(address.state.label, { emitEvent: false });
        this.companyDetailsForm.controls.country.setValue(address.country.label, { emitEvent: false });
        this.companyDetailsForm.controls.postalCode.setValue(address.postalCode, { emitEvent: false });
    }
}
