import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { LanguageTranslationService } from 'common/services/language-translation.service';
import { EnrollmentOption, EnrollmentStatusPayload, ProgramDetails, ProgramEnrollment } from 'private/app/models/account-admin-program.model';
import { BaseResponse } from 'src/common/models/account-status';
import { AccountAdminApiService } from 'private/app/services/account-admin/account-admin-api.service';
import { AccountAdminProgramService } from 'private/app/services/account-admin/account-admin-program.service';
import { ToastService } from 'common/services/toast.service';
import { BehaviorSubject } from 'rxjs';
import { delay, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { AccountAdminProgramEnrollmentService } from 'private/app/services/account-admin/account-admin-program-enrollment.service';
import { AppConstants } from 'common/app-constants';
import { OktaService } from 'common/services/okta/okta.service';
import { IdToken } from 'common/models';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { BaseComponent } from 'common/components/base/base.component';
import { ManageEnrollmentsService } from 'private/app/services/manage-enrollments.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'hvac-manage-enrollments',
    templateUrl: './manage-enrollments.component.html',
    styleUrls: ['./manage-enrollments.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ManageEnrollmentsComponent extends BaseComponent implements OnInit {
    public breadCrumbData: ContentHeadingData = this.accountAdminProgramEnrollmentService.breadCrumbData;
    public manageEnrollmentsForm = this.accountAdminProgramEnrollmentService.manageEnrollmentsForm;
    public isLoading: boolean = true;
    public isTableLoading: boolean = false;
    public isMultipleEnroll: boolean = true;
    public disableExportButton: boolean = true;
    public isEnrollModalVisible: boolean = false;
    public isEnrollActionsEnabled: boolean = false;
    public programManagersList: string[] = [];
    public loggedInUserName: string;
    public isProgramManager?: boolean = false;
    public isDealerConversionProgram: boolean;
    public isEnrollmentLevel: boolean;
    public currentPage: number = AppConstants.currentPage;
    public pageSize: number = AppConstants.pageSize;
    public totalPages: number;
    public selectedTab: number = 0;
    public enrollmentActionForm: FormGroup;
    public enrollmentAction: EnrollmentOption;
    public enrollment: ProgramEnrollment;
    public enrollmentConfirmMessage: string;
    public programData: ProgramDetails;
    public tabs: string[] = this.accountAdminProgramEnrollmentService.enrollmentStatusTabs;
    public currentPageEnrollmentData$ = new BehaviorSubject<ProgramEnrollment[]>([]);
    public filteredData$ = new BehaviorSubject<ProgramEnrollment[]>([]);
    public enrollmentStatus$ = new BehaviorSubject('');
    public showManageEnrollmentDropdowns$ = new BehaviorSubject<Boolean>(false);
    public isAllEnrllmntsSlctdFrCrntFltr = false;
    private searchFilteredData: ProgramEnrollment[] = [];
    private enrollmentStatusPayload: EnrollmentStatusPayload;
    private selectedEnrollments: Map<number, ProgramEnrollment> = new Map();

    constructor(
        public translate: TranslateService,
        public accountAdminProgramService: AccountAdminProgramService,
        public accountAdminProgramEnrollmentService : AccountAdminProgramEnrollmentService,
        private languageTranslationService: LanguageTranslationService,
        private route: ActivatedRoute,
        private readonly accountAdminApiService: AccountAdminApiService,
        private readonly toastService: ToastService,
        private oktaService: OktaService,
        private manageEnrollmentsService : ManageEnrollmentsService
    ) {
        super();
        this.languageTranslationService.translationsLoaded().pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => {
            setTimeout(() => {
                this.accountAdminProgramService.setProgramAdminBreadCrumData(this.breadCrumbData);
            }, 0);
        });
    }

    ngOnInit(): void {
        if (this.route.snapshot.params.programId) {
            this.accountAdminApiService.getAccountAdminProgramsById(this.route.snapshot.params.programId).subscribe((data) => {
                this.programData = data;
                this.programManagersList = data.programManagers.map((manager) => `${(manager.firstName).toLowerCase()} ${(manager.lastName).toLowerCase()}`);
                this.isDealerConversionProgram = data.enrollmentWorkflow?.name === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.DEALER_CONVERSION_PROGRAM');
                if (!this.isDealerConversionProgram) {
                    this.tabs = this.accountAdminProgramEnrollmentService.nonDealerConversionEnrollmentStatusTabs;
                }
                this.isEnrollmentLevel = data.enrollmentLevel;
                this.showManageEnrollmentDropdowns$ = this.manageEnrollmentsService.checkDropdownVisibility(data);
                this.oktaService.idToken$.subscribe((res: IdToken) => {
                    this.loggedInUserName = (res?.claims?.name) ? res?.claims?.name : '';
                    if (!this.programData.enrollmentWorkflow && (res?.claims?.idm_user_roles?.includes(IdmUserRoles.DEFAULT_INTERNAL) || res?.claims?.idm_user_roles?.includes(IdmUserRoles.DEFAULT_DISTRIBUTOR))) {
                        this.isEnrollActionsEnabled = true;
                    }
                    this.isProgramManager = (res?.claims?.idm_user_roles?.includes(IdmUserRoles.PROGRAM_MANAGER) && this.programManagersList.includes((this.loggedInUserName).toLowerCase()));
                });
                this.getAccountAdminProgramEnrollments();
            }, (error: HttpErrorResponse) => this.toastService.raiseDefaultErrorToast('ManageEnrollmentsComponent-ngOnInit', error.message));
        }
        this.enrollmentStatus$.pipe(takeUntil(this.ngOnDestroy$), distinctUntilChanged(), tap(() => this.isTableLoading = true), delay(AppConstants.enrollmentsSearchDelay)).subscribe(() => {
            this.filterProgramEnrollments();
        });
        this.manageEnrollmentsForm.valueChanges.pipe(takeUntil(this.ngOnDestroy$), distinctUntilChanged(), tap(() => this.isTableLoading = true), delay(AppConstants.enrollmentsSearchDelay)).subscribe(() => {
            this.filterProgramEnrollments();
        });
    }

    handleTabClick(tabSelection: number, tab: string) {
        this.isTableLoading = true;
        this.selectedTab = tabSelection;
        this.currentPage = AppConstants.currentPage;
        const val = this.accountAdminProgramEnrollmentService.tabValue(tab);
        this.enrollmentStatus$.next(val);
    }

    handlePageChange(page: number) {
        this.currentPage = parseInt(page.toString(), this.pageSize);
        const filterData = this.searchFilteredData.length ? this.searchFilteredData : this.filteredData$.value;
        this.currentPageEnrollmentData$.next(
            filterData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
        );
    }

    onSelectDealerConversionEnrollment(enrollment: ProgramEnrollment, enrollmentAction: EnrollmentOption) {
        if (this.isDealerConversionProgram && enrollmentAction.name === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_ACTIONS.ACTIVE')) {
            this.accountAdminProgramEnrollmentService.openProgramEnrollmentForm(enrollment, this.programData);
        }
        else {
            this.isEnrollModalVisible = true;
            this.enrollment = enrollment;
            this.enrollmentAction = enrollmentAction;
            this.enrollmentConfirmMessage = enrollmentAction.confirmDetails;
        }
    }

    showMultipleEnrollConfirmationModal() {
        this.isEnrollModalVisible = true;
        this.enrollmentConfirmMessage = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_ACTION_MESSAGE.SELECTED_ACTIVE');
    }

    handleCloseModal() {
        if (this.isEnrollModalVisible) {
            this.isEnrollModalVisible = false;
            this.enrollmentActionForm.controls[`enrollmentAction${this.enrollment.dealerId}`].setValue('', { emitEvent: true });
        }
    }

    handleOkModal() {
        this.isEnrollModalVisible = false;
        if (this.isMultipleEnroll) {
            this.confirmSingleEnrollment();
        }
        else {
            this.confirmMultipleEnrollments();
        }
    }

    confirmSingleEnrollment() {
        if (this.enrollmentAction.value) {
            this.isTableLoading = true;
            if (this.enrollmentAction.enrollmentType === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.STATUS')) {
                this.enrollmentStatusPayload = {
                    programId: Number(this.programData.id),
                    dealerCompanies: [this.enrollment.dealerId],
                    enrollmentStatusId: Number(this.enrollmentAction.value)
                };
                this.accountAdminApiService.accountAdminProgramEnrollmentStatus(this.enrollmentStatusPayload).subscribe((data: BaseResponse) => {
                    if (data?.status === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.SUCCESS')) {
                        let message: string;
                        if (this.enrollment.enrollmentStatus === 0) {
                            const messageObject = {
                                dealerName: this.enrollment.dealerName,
                                dealerDisplayId: this.enrollment.dealerDisplayId,
                                programName: this.programData.name,
                                programCode: this.programData.code
                            };
                            message = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ENROLLMENT_STATUS_SINGLE_SUCCESS', messageObject);
                        }
                        else {
                            message = data?.details;
                        }
                        this.displayToast(message, 'success');
                        this.getAccountAdminProgramEnrollments(true);
                    }
                },
                (error) => {
                    this.isTableLoading = false;
                    this.displayToast(error.error?.message, 'error');
                });
            }
            else if (this.enrollmentAction.enrollmentType === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.LEVEL')) {
                this.accountAdminApiService.accountAdminProgramEnrollmentLevel(Number(this.programData.id), this.enrollment.dealerId, Number(this.enrollmentAction.value)).subscribe((data: BaseResponse) => {
                    if (data?.status === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.SUCCESS')) {
                        this.displayToast(data?.details, 'success');
                        this.getAccountAdminProgramEnrollments(true);
                    }
                },
                (error) => {
                    this.isTableLoading = false;
                    this.displayToast(error.error?.message, 'error');
                });
            }
        }
    }

    confirmMultipleEnrollments() {
        this.isTableLoading = true;
        let selectedItems:number[] = [];
        this.currentPageEnrollmentData$.subscribe((val: ProgramEnrollment[]) => {
            selectedItems = val.filter((item) => item.isChecked).map((dealer) => dealer.dealerId);
        });
        this.enrollmentStatusPayload = {
            programId: Number(this.programData.id),
            dealerCompanies: selectedItems,
            enrollmentStatusId: 1
        };
        this.accountAdminApiService.accountAdminProgramEnrollmentStatus(this.enrollmentStatusPayload).subscribe((data: BaseResponse) => {
            if (data?.status === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.SUCCESS')) {
                const messageObject = {
                    programName: this.programData.name,
                    programCode: this.programData.code
                };
                const message = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ENROLLMENT_STATUS_MULTIPLE_SUCCESS', messageObject);
                this.displayToast(message, 'success');
                this.getAccountAdminProgramEnrollments(true);
            }
        },
        (error) => {
            this.isTableLoading = false;
            this.displayToast(error.error?.message, 'error');
        });
    }

    selectEnrollment(enrollment: ProgramEnrollment) {
        enrollment.isChecked = !enrollment.isChecked;
        if (this.selectedEnrollments.get(enrollment.dealerId)) {
            this.selectedEnrollments.delete(enrollment.dealerId);
        }
        else {
            this.selectedEnrollments.set(enrollment.dealerId, enrollment);
        }
        this.isEnrollDisabled();
        const currentFilteredData = this.searchFilteredData.length ? this.searchFilteredData : this.filteredData$.value;
        this.isAllEnrllmntsSlctdFrCrntFltr = this.selectedEnrollments.size === currentFilteredData.length;
    }

    exportSelectedEnrollments() {
        this.accountAdminProgramEnrollmentService.exportSelectedEnrollments(this.programData.name, this.selectedEnrollments, this.isDealerConversionProgram, this.isEnrollmentLevel);
    }

    handleSelectAll(event: Event) {
        this.accountAdminProgramEnrollmentService.handleSelectAllEnrollments(event, this.searchFilteredData.length ? this.searchFilteredData : this.filteredData$.value, this.selectedEnrollments);
        this.isEnrollDisabled();
        this.isAllEnrllmntsSlctdFrCrntFltr = true;
    }

    private isEnrollDisabled() {
        this.currentPageEnrollmentData$.subscribe((val: ProgramEnrollment[]) => {
            this.isMultipleEnroll = !val.some((item) => item.isChecked);
            this.disableExportButton = this.selectedEnrollments.size === 0;
        });
    }

    private getAccountAdminProgramEnrollments(checkForFilter = false) {
        const isEnrollmentFormEmpty = this.programData.enrollmentForm === null;
        const isEnrollmentWorkflowEmpty = this.programData.enrollmentWorkflow === null;
        this.accountAdminApiService.getAccountAdminProgramEnrollmentsById(this.programData.id).subscribe((enrollmentsData) => {
            this.isLoading = false;
            this.totalPages = Math.ceil(enrollmentsData.length / this.pageSize);
            this.filteredData$.next(enrollmentsData);
            const group: {[key: string]: FormControl} = {};
            enrollmentsData.forEach((item: ProgramEnrollment) => {
                item.isChecked = false;
                item.enrollmentOptions = this.accountAdminProgramEnrollmentService.getEnrollmentActionOptions(item, isEnrollmentFormEmpty, isEnrollmentWorkflowEmpty);
                item.enrollmentOptions = (this.isProgramManager) ? item.enrollmentOptions : item.enrollmentOptions.filter((val) => val.value !== '0');
                group[`enrollmentAction${item.dealerId}`] = new FormControl('');
            });
            this.enrollmentActionForm = new FormGroup(group);
            if (checkForFilter) {
                this.filterProgramEnrollments();

                return;
            }
            this.currentPageEnrollmentData$.next(enrollmentsData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize));
            this.isTableLoading = false;
        });
    }

    private displayToast(content: string, theme: 'success' | 'error') {
        this.toastService.raiseDefaultToastMessage(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_TOAST_MESSAGE_ID'), content, theme);
    }

    private filterProgramEnrollments() {
        const formControls = this.manageEnrollmentsForm.value;
        let workflowStatusId: string = '';
        if (formControls.workflowStatus.length) {
            workflowStatusId = this.accountAdminProgramEnrollmentService.getWorkflowStatus(formControls.workflowStatus);
        }
        this.searchFilteredData = this.filteredData$.value.map((enrollmentData) => {
            enrollmentData.isChecked = false;

            return enrollmentData;
        }).filter((item) => item.dealerName?.toLowerCase().includes(formControls.dealerName?.toLowerCase()) &&
                item.dealerDisplayId?.toString().toLowerCase().includes(formControls.dealerDisplayId?.toLowerCase()) && item.city?.toLowerCase().includes(formControls.city?.toLowerCase()) &&
                item.state?.toLowerCase().includes(formControls.state?.toLowerCase()) && item.enrollmentWorkFlowStatusId?.toString().toLowerCase().includes(workflowStatusId) &&
                item.enrollmentStatus?.toString().toLowerCase().includes(this.enrollmentStatus$.value?.toLowerCase()) && item.sponsoringDistributor?.toString().toLowerCase().includes(formControls.sponsoringDistributor?.toLowerCase()));
        this.selectedEnrollments = new Map();
        this.isAllEnrllmntsSlctdFrCrntFltr = false;
        this.totalPages = Math.ceil(this.searchFilteredData.length / this.pageSize);
        this.currentPageEnrollmentData$.next(this.searchFilteredData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize));
        this.isTableLoading = false;
    }
}
