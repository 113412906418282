/* eslint-disable max-lines */
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserApiService } from 'private/app/services/account-admin/user/user-api.service';
import { BehaviorSubject } from 'rxjs';
import { Option } from '../../../../../common/components/select/select.component';
import { CompanyResultDataResponse } from 'private/app/models/account-admin-search-results';
import { UserAdminService } from 'private/app/services/account-admin/user/user-admin.service';
import { AccountAdminStateEntity, DataHeader, User } from 'private/app/models/account-admin-search';
import { AccountAdminService } from 'private/app/services/account-admin/account-admin.service';
import { RegistrationHistory, UserDetails, UserRegistrationDetails, UserRegistrationRequest } from 'private/app/models/user.model';
import { AccountAdminCompanyUtilsService } from 'private/app/services/account-admin/account-admin-company-utils.service';
import { ToastService, ToastTheme } from 'common/services/toast.service';
import { IdToken } from 'common/models';
import { OktaService } from 'common/services/okta/okta.service';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
import { PaginationUtils } from 'common/utils/PaginationUtils';

@Component({
    selector: 'hvac-user-registrations-details',
    templateUrl: './user-registrations-details.component.html',
    styleUrls: ['./user-registrations-details.component.scss']
})
export class UserRegistrationsDetailsComponent implements OnInit {
  @Input() usersData: UserRegistrationRequest;
  @Input() company: CompanyResultDataResponse;
  @Output() userDetails = new EventEmitter<UserDetails>();
  @Output() registrationUserChangePage = new EventEmitter<{
      registrationUserStep: string,
      usersData : UserRegistrationDetails
  }>();

  public isUserDetailsAvailable: boolean;
  public viewRegHistoryClick: boolean = false;
  public stateOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
  public countryOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
  public actionOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
  public userState: string;
  public initialUserState: string;
  public userCountry: string;
  public userCountryTemp: string;
  public userStateTemp: string;
  public registrationAction: string = '';
  public user: UserRegistrationDetails;
  public userCompanyTypeName = '';
  public userCompanyTypeCode = '';
  public actionValue = '';
  public maxCommentLimit: number = 500;
  public apiRequestActive = false;
  public registrationHistories: PaginationUtils<RegistrationHistory>;

  public registrationHistoryDataHeaders: DataHeader[] = [
      {
          title: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.DATE'),
          value: 'date',
          order: null
      },
      {
          title: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.SUBMITTED_BY'),
          value: 'submittedBy',
          order: null
      },
      {
          title: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.STATUS'),
          value: 'status',
          order: null
      },
      {
          title: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.COMMENT'),
          value: 'comment',
          order: null
      }
  ];

   actionList: Option[] = [
       {
           name: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.SELECT'),
           value: 'Select'
       },
       {
           name: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.APPROVE'),
           value: 'Approve'
       },
       {
           name: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.SAVE_ONLY'),
           value: 'Save Only'
       },
       {
           name: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.REJECT'),
           value: 'Reject'
       },
       {
           name: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.REJECT_DUPLICATE'),
           value: 'Reject-Duplicate'
       }
   ];

private forward: Option = {
    name: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.FORWARD_TO_INTENAL'),
    value: 'Forward to Internal'
};

  private _userCompanyEditMode = false;
  private bannerType = 'UserEdit';
  private _isCompanyListDataAvailable = false;
  private companyCategory : string = '';
  private readonly REGISTRATION_HISTORY_PAGE_SIZE = 4;

  constructor(
      public userApiService: UserApiService,
      public userAdminService: UserAdminService,
      public accountAdminService: AccountAdminService,
      public accountAdminCompanyUtilsService: AccountAdminCompanyUtilsService,
      private readonly toastService: ToastService,
      private oktaService: OktaService,
      public translateService: TranslateService,
      private commonUtils: CommonUtilsService,
      private cdr: ChangeDetectorRef
  ) {
      this.onCompanySelect = this.onCompanySelect.bind(this);
      this.registrationHistories = new PaginationUtils<RegistrationHistory>([], this.REGISTRATION_HISTORY_PAGE_SIZE, this.registrationHistoryDataHeaders);
  }

  get userCompanyEditMode(): boolean {
      return this._userCompanyEditMode;
  }

  get isCompanyListDataAvailable(): boolean {
      return this._isCompanyListDataAvailable;
  }

  ngOnInit(): void {
      this.oktaService.idToken$.subscribe((res: IdToken) => {
          this.companyCategory = res?.claims?.idm_user_companies[0]?.companyCategory?.name;
          if (this.companyCategory !== this.translateService.instant('ACCOUNT_ADMIN.INTERNAL')) {
              this.actionList.push(this.forward);
          }
      });

      this.commonUtils.getCountryOptions().subscribe((countryList) => {
          this.countryOptionData$.next(this.accountAdminCompanyUtilsService.filterCountryArraywithMostUsed(countryList));
      });

      this.userApiService.getRegistrationUserDetailsById(this.usersData?.requestId).subscribe((data: UserRegistrationDetails) => {
          this.cdr.detectChanges();
          this.user = data;
          this.initialUserState = data.userState;
          this.registrationHistories.initData(this.userAdminService.transformRegHistoryToPaginableData(data?.registrationHistory));
          this.isUserDetailsAvailable = true;
          this.userCountryTemp = this.user.userCountry.includes('-') ? this.user.userCountry?.split(' - ')[1] : this.user.userCountry;
          this.userCountry = this.countryOptionData$.value.find((country) => country.value === this.userCountryTemp)?.name || '';
          this.userAdminService.userEditForm.controls.userCountry.setValue(this.userCountry);
          this.commonUtils.getStateEntityByCountry(this.userCountryTemp).subscribe((countryList) => {
              const optionList: Option[] = [];
              countryList?.forEach((state: AccountAdminStateEntity) => {
                  optionList.push({
                      name: state.name,
                      value: state.code
                  });
              });
              this.stateOptionData$.next(optionList);
              this.userStateTemp = this.initialUserState.includes('-') ? this.initialUserState?.split(' - ')[0] : this.initialUserState;
              this.userState = this.stateOptionData$.value.find((state) => state.value === this.userStateTemp)?.name || '';
              this.userAdminService.userEditForm.controls.userState.setValue(this.userState);
          });
          this.actionOptionData$.next(this.actionList);
          this.updateUIWithUser();
      });

      this.cdr.detectChanges();
      this.userAdminService.smoothScrollToTop();

      this.userAdminService.userEditForm.controls.userCountry.valueChanges.subscribe((selectedCountry: string) => {
          if (Array.isArray(selectedCountry)) {
              this.userAdminService.userEditForm.controls.userState.setValue('', { emitEvent: true });
              this.userAdminService.userEditForm.controls.userState.markAsDirty();
              this.getStateListByCountry(selectedCountry[0].value);
          }
          else {
              const countrySelection = this.countryOptionData$.value.find((country) => country.value === selectedCountry);
              if (countrySelection) {
                  this.getStateListByCountry(countrySelection.value);
              }
          }
      });
  }

  ngAfterViewInit() {
      this.cdr.detectChanges();
  }

  updateUIWithUser() {
      this.userAdminService.userEditForm.setValue({
          firstName: this.user.firstName || '',
          lastName: this.user.lastName || '',
          userID: this.user.userID || '',
          userTitle: this.user.userTitle || '',
          employerName: this.user.employerName || '',

          companyName: this.user.employerName || '',
          companyId: this.user.companyId || '',
          hvacPin: this.user.hvacPin || '',

          userAddress1: this.user.userAddress1 || '',
          userAddress2: this.user.userAddress2 || '',
          userCity: this.user.userCity || '',

          userState: this.userState || '',
          userPostalCode: this.user.userPostalCode || '',
          userCountry: this.userCountry || '',

          userPhone: this.user.userPhone || '',
          userCellPhone: this.user.userCellPhone || '',
          userFax: this.user.userFax || '',

          email: this.user.email || '',
          contactOnly: this.user.contactOnly || '',

          lastLoginDate: this.user.lastLoginDate || '',

          registrationAction: 'Select',
          comments: ''
      }, { emitEvent: true });
  }

  onCountryChange(option: Option[]) {
      this.user.userCountry = option[0]?.value;
  }

  onStateChange(option: Option[]) {
      this.user.userState = option[0]?.value;
  }

  onActionChange(option: Option[]) {
      this.actionValue = option[0]?.value;
  }

  onUserCompanyChangeClick() {
      const selectedUserModel = localStorage.getItem(this.translateService.instant('ACCOUNT_ADMIN.LOCAL_HOST_KEYS.USER_MODEL_DATA_KEY'));

      if (selectedUserModel) {
          const user: User = JSON.parse(selectedUserModel) as User;
          const [userDefaultCompany] = (user?.companies?.filter((company) => company.id === user.defaultCompanyId) || []);

          if (userDefaultCompany) {
              this.userCompanyTypeCode = userDefaultCompany.companyType?.code || '';
              this.userCompanyTypeName = userDefaultCompany.companyType?.name || '';
          }
      }

      this._userCompanyEditMode = true;
  }

  onViewRegHistoryClick(viewClick: boolean) {
      this.viewRegHistoryClick = !viewClick;
  }

  public onCompanySelect(company: CompanyResultDataResponse | null): void {
      if (company) {
          this.userAdminService.userEditForm.controls.employerName.setValue(company.companyName);
          this.userAdminService.userEditForm.controls.companyId.setValue(company.hvacCompanyId);
          this.userAdminService.userEditForm.controls.employerName.markAsDirty();
          this.userAdminService.userEditForm.controls.companyId.markAsDirty();
      }
      else {
          this.showToastBar('error', this.translateService.instant('USER_ADMIN.USER_EDIT.ERROR.SOMETHING_WENT_WRONG'));
      }
      this._userCompanyEditMode = false;
  }

  public searchCompanyModalClose() {
      this._userCompanyEditMode = false;
  }

  public onClickClearSearch() {
      const companyValue = this.accountAdminService.advancedSearchForm.controls.companyType.value;

      this.accountAdminService.advancedSearchForm.reset(this.accountAdminService.advancedSearchFormDefault.value);
      this.accountAdminService.advancedSearchForm.controls.companyType.setValue(companyValue);
  }

  public onCompanySearchClick() {
      this._isCompanyListDataAvailable = true;
  }

  public onContinueClick() {
      this.registrationUserChangePage.emit({
          registrationUserStep: 'step2_userRegistrationForm',
          usersData: this.userAdminService.createUserRegistrationPostBody(this.user)
      });
      this.userDetails.emit(this.userAdminService.mapCreateUserDetails());
  }

  public onSaveClick() {
      this.apiRequestActive = true;
      this.userApiService.updateUserRegistrationDetails(this.userAdminService.createUserRegistrationPostBody(this.user)).subscribe((response) => {
          this.apiRequestActive = false;
          this.userAdminService.smoothScrollToTop();
          this.showToastBar('success', response.details);
          this.userAdminService.userEditForm.markAsPristine();
          this.registrationUserChangePage.emit({
              registrationUserStep: 'step0_searchUser',
              usersData: this.user
          });
      }, (error) => {
          this.apiRequestActive = false;
          this.userAdminService.smoothScrollToTop();
          this.showToastBar('error', error.error?.message === this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.BAD_REQUEST') ? error.error?.details[0] : error.error.message);
      });
  }

  public onCancelClick() {
      this.registrationUserChangePage.emit({
          registrationUserStep: 'step0_searchUser',
          usersData: this.user
      });
  }

  private getStateListByCountry(selectedCountry: string) {
      this.commonUtils.getStateEntityByCountry(selectedCountry).subscribe((countryList) => {
          const optionList: Option[] = [];
          countryList?.forEach((state: AccountAdminStateEntity) => {
              optionList.push({
                  name: state.name,
                  value: state.code
              });
          });
          this.stateOptionData$.next(optionList);
          this.userState = this.stateOptionData$.value.find((state) => state.value === this.user.userState)?.name || '';
          this.userAdminService.userEditForm.controls.userState.setValue(this.userState);
      });
  }

  private showToastBar(theme: ToastTheme, response: string) {
      this.toastService.add({
          content: response,
          theme: theme,
          id: this.bannerType,
          closeable: true,
          autoClose: true
      });
  }
}
