/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { ChartArea, ChartData, ChartOptions } from 'chart.js';
import { Option } from 'common/components/select-new/select-new.component';
import { CHART_COLORS, TempUnitFormat } from 'private/app/views/connected-portal/constants';
import { format, subMonths } from 'date-fns';
import { AppConstants } from 'common/app-constants';
import 'chartjs-adapter-date-fns';
import { TranslateService } from '@ngx-translate/core';
import { BreakPointService } from 'common/services/breakpoint.service';

const DOUGHNUT_CHART_COLORS = [
    '#003366',
    '#00B5A2',
    '#0079AD',
    '#99D8DA'
];

const DEFAULT_DATASET_MAX_VALUE = 10;

export enum MultiLineChartColors {
    Red = '#C32502',
    Blue = '#1971FF',
    DarkBlue = '#004E80',
    Orange = '#FF5433',
    White = '#FFF',
    Black = '#000',
    Turquoise = '#00B5A2',
    Yellow = '#EEA005'
}

export interface BarChartGradient {
    gradientTop: string;
    gradientBottom: string;
}

export const BarChartColors: BarChartGradient[] = [
    {
        gradientTop: 'rgba(0, 51, 102, 1)',
        gradientBottom: 'rgba(0, 51, 102, 0.5)'
    },
    {
        gradientTop: 'rgba(0, 157, 163, 1)',
        gradientBottom: 'rgba(0, 157, 163, 0.5)'
    }
];

export interface MultiLineDataSet {
    id: string;
    configOptions: {
        pointBackgroundColor: string;
        pointBorderColor: string;
        borderColor: string;
    },
    dataSet: number[];
}

export enum RuntimeChartType {
    TEMPERATURE = 'temperature',
    HUMIDITY = 'humidity'
}

@Injectable({ providedIn: 'root' })
export class ChartConfigService {
    private calendarMonths = this.translateService.instant('CALENDAR_MONTHS');

    constructor(
        private translateService: TranslateService,
        private breakpointService: BreakPointService
    ) { }

    public getLineChartConfig(dataset: number[], xScaleMaxTicks: number): ChartOptions<'line'> {
        const datasetMax = [...dataset].sort((itemA, itemB) => (itemB - itemA)).shift() || DEFAULT_DATASET_MAX_VALUE;
        const yMax = datasetMax + Math.round(datasetMax * 0.1);
        const layoutPadding = 12;

        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: { legend: { display: false } },
            layout: { padding: { left: -layoutPadding } },
            scales: {
                x: {
                    grid: { display: false },
                    ticks: {
                        padding: layoutPadding,
                        autoSkip: true,
                        maxTicksLimit: xScaleMaxTicks,
                        maxRotation: 0
                    }
                },
                y: {
                    beginAtZero: true,
                    grid: { drawTicks: false },
                    afterDataLimits: (axis) => {
                        if (axis.max <= 10) {
                            axis.max += axis.max * 0.1;
                        }
                    },
                    ticks: {
                        padding: layoutPadding,
                        maxRotation: 0,
                        // eslint-disable-next-line func-names
                        callback: function(value) {
                            if (Number(value) > 999) {
                                const num = (Number(value) * 0.001).toFixed(1);

                                return `${num} K`;
                            }

                            return value;
                        }
                    },
                    border: { display: false },
                    ...(yMax < 999 && { max: yMax })
                }
            }
        };
    }

    public getMultiLineChartConfig(yMax: number, yMin: number, additionalParams: { xScaleMaxTicks?: number, chartType: string, tempUnitFormat?: TempUnitFormat }, dataLength?: number): ChartOptions<'line'> {
        const layoutPadding = 12;
        // eslint-disable-next-line consistent-this
        const that = this;
        const tempUnit = `°${additionalParams.tempUnitFormat || TempUnitFormat.Fahrenheit}`;

        return {
            maintainAspectRatio: false,
            animation: false,
            plugins: {
                legend: { display: false },
                tooltip: {
                    backgroundColor: MultiLineChartColors.White,
                    titleColor: MultiLineChartColors.Black,
                    bodyColor: MultiLineChartColors.Black,
                    borderColor: MultiLineChartColors.Black,
                    borderWidth: 0.5,
                    caretPadding: 10,
                    displayColors: false,
                    callbacks: {
                        title: () => '',
                        label: ((tooltipItem) => {
                            const date = format(new Date(tooltipItem.parsed.x), AppConstants.timeFormat);

                            return additionalParams.chartType === RuntimeChartType.TEMPERATURE
                                ? ` ${date} | ${tooltipItem.parsed.y} ${tempUnit}`
                                : ` ${date} | ${tooltipItem.parsed.y} %`;
                        })
                    },
                    bodyFont: { weight: 'bold' }
                }
            },
            layout: {
                padding: {
                    left: -layoutPadding,
                    bottom: (additionalParams.chartType === RuntimeChartType.HUMIDITY && !that.breakpointService.isMobile()) ? -32 : 0
                }
            },
            scales: {
                // eslint-disable-next-line multiline-ternary
                x: additionalParams.xScaleMaxTicks ? {
                    type: 'time',
                    time: { unit: 'minute' },
                    grid: {
                        display: true,
                        tickColor: MultiLineChartColors.Black
                    },
                    ticks: {
                        padding: layoutPadding,
                        autoSkip: true,
                        autoSkipPadding: 40,
                        maxTicksLimit: additionalParams.xScaleMaxTicks,
                        maxRotation: 0,
                        // eslint-disable-next-line func-names
                        callback: function(value) {
                            const date = format(new Date(value), AppConstants.dateTimeFormatForRuntimeReport);
                            const time = format(new Date(value), AppConstants.timeFormat);
                            const isMobile = that.breakpointService.isMobile();
                            const mobileVal = isMobile ? [date, time] : '';

                            return additionalParams.chartType === RuntimeChartType.TEMPERATURE ? [date, time] : mobileVal;
                        },
                        align: (dataLength && dataLength === 1) ? 'start' : 'inner'
                    }
                } : { display: false },
                y: {
                    min: yMin,
                    max: yMax,
                    beginAtZero: false,
                    grid: { drawTicks: false },
                    ticks: {
                        callback: ((value) => (additionalParams.chartType === RuntimeChartType.TEMPERATURE ? `${value} ${tempUnit}` : `${value} %`)),
                        padding: layoutPadding,
                        maxTicksLimit: 9,
                        maxRotation: 0
                    },
                    border: { display: false }
                }
            }
        };
    }

    public getDoughnutChartConfig(config?: { legendPosition?: 'right' | 'bottom', radius?: string, cutout?: string }): ChartOptions<'doughnut'> {
        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: config?.legendPosition || 'right',
                    align: 'center',
                    onClick: () => null,
                    labels: {
                        pointStyle: 'circle',
                        textAlign: 'left',
                        boxHeight: 8,
                        boxWidth: 8,
                        padding: 20,
                        usePointStyle: true,
                        sort: (itemA, itemB) => itemB.text.localeCompare(itemA.text),
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        generateLabels: (chart): any => {
                            const data = chart.data;
                            if (data?.labels?.length && data.datasets.length) {
                                if (chart.legend?.options) {
                                    const { labels: { pointStyle, color } } = chart.legend.options;

                                    return data.labels.map((label, index) => {
                                        const meta = chart.getDatasetMeta(0);
                                        const style = meta.controller.getStyle(index, true);

                                        const dataValue = data.datasets[0].data[index];
                                        const dataValueTotal = data.datasets[0].data.reduce((acc, curVal) => {
                                            if (typeof curVal === 'number' && typeof acc === 'number') {
                                                return (acc + (curVal));
                                            }

                                            return acc;
                                        }, 0);

                                        const percent = (typeof dataValueTotal === 'number' && typeof dataValue === 'number')
                                            ? Math.round((100 / dataValueTotal) * dataValue)
                                            : '';

                                        return {
                                            text: `${percent}% - ${label}`,
                                            fillStyle: style.backgroundColor,
                                            strokeStyle: style.borderColor,
                                            fontColor: color,
                                            lineWidth: style.borderWidth,
                                            pointStyle: pointStyle
                                        };
                                    });
                                }
                            }

                            return [];
                        }
                    }
                }
            },
            radius: config?.radius || '75%',
            cutout: config?.cutout || '60%'
        };
    }

    public getGroupedBarChartConfig(config?: {
        xScaleMaxTicks?: number,
        datasetMax?: number,
        xScaleTickFont?: {
            size?: number;
            weight?: string;
        }
    }): ChartOptions<'bar'> {
        const yMax = config?.datasetMax ? config.datasetMax + Math.round(config.datasetMax * 0.1) : null;
        const layoutPadding = 12;

        const data: ChartOptions<'bar'> = {
            animation: false,
            responsive: true,
            maintainAspectRatio: false,
            plugins: { legend: { display: false } },
            layout: { padding: { left: -layoutPadding } },
            scales: {
                x: {
                    grid: { display: false },
                    ticks: {
                        padding: layoutPadding,
                        autoSkip: true,
                        maxTicksLimit: config?.xScaleMaxTicks,
                        maxRotation: 0,
                        font: { ...config?.xScaleTickFont }
                    }
                },
                y: {
                    beginAtZero: true,
                    grid: { drawTicks: false },
                    ticks: {
                        padding: layoutPadding,
                        maxRotation: 0,
                        // eslint-disable-next-line func-names
                        callback: function(value) {
                            if (Number(value) > 999) {
                                let num: number | string = Number(value) * 0.001;

                                if (num % 1 > 0) {
                                    num = num.toFixed(1);
                                }

                                return `${num} K`;
                            }

                            return value;
                        }
                    },
                    border: { display: false }
                }
            }
        };

        if (yMax && data?.scales?.y) {
            data.scales.y.max = yMax;
        }

        return data;
    }

    public getYearRange(minYear: number, maxYear: number): number[] {
        return [...Array(maxYear - minYear).keys()].map((index) => minYear + index + 1);
    }

    public getStartEndDateFromYear(year: number) {
        // first day of the first month of the year
        const startDate = new Date(Date.UTC(year, 0, 1)).toISOString();
        // last day of the last month of the year
        const endDate = new Date(Date.UTC(year, 12, -1)).toISOString();

        return {
            startDate,
            endDate
        };
    }

    public getMonthsOfYear = (year: number) => [...Array(12).keys()].map((index) => new Date(year, index, 1))


    public getChartGradient(ctx: CanvasRenderingContext2D, chartArea: ChartArea, config: { gradientTop: string, gradientBottom: string }) {
        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(1, config.gradientTop);
        gradient.addColorStop(0, config.gradientBottom);

        return gradient;
    }

    public getYearOptions(maxYearCount: number): Option[] {
        const currentYear = new Date().getFullYear();
        const startYear = currentYear - maxYearCount;

        return this.getYearRange(startYear, currentYear).map((year) => ({
            name: year.toString(),
            value: year.toString()
        }));
    }

    public getLineChartData(labels: (string | undefined)[], dataset: number[], pointDivisor: number) {
        const formattedChartData: ChartData<'line'> = {
            labels,
            datasets: [{
                data: dataset,
                fill: true,
                pointBackgroundColor: CHART_COLORS.pointBackground,
                pointBorderColor: CHART_COLORS.pointBorder,
                pointRadius: (chart) => (chart.dataIndex % pointDivisor === 0 ? 8 : 0),
                borderColor: CHART_COLORS.border,
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        return;
                    }

                    return this.getChartGradient(ctx, chartArea, {
                        gradientTop: CHART_COLORS.gradientTop,
                        gradientBottom: CHART_COLORS.gradientBottom
                    });
                }
            }]
        };

        return formattedChartData;
    }

    public getMultiLineChartData(labels: (string | undefined)[], dataSets: MultiLineDataSet[], pointDivisor: number) {
        const formattedChartData: ChartData<'line'> = {
            labels,
            datasets: []
        };

        dataSets.forEach((_val, i) => {
            formattedChartData.datasets.push({
                data: dataSets[i].dataSet,
                fill: false,
                showLine: true,
                pointBackgroundColor: dataSets[i].configOptions.pointBackgroundColor,
                pointBorderColor: dataSets[i].configOptions.pointBorderColor,
                pointRadius: 0,
                pointHoverRadius: (chart) => (chart.dataIndex % pointDivisor === 0 ? 8 : 0),
                borderColor: dataSets[i].configOptions.borderColor
            });
        });

        return formattedChartData;
    }

    public getDonutChartData(labels: (string | undefined)[], dataset: number[], config?: { chartColors?: string[]}) {
        const formattedChartData: ChartData<'doughnut'> = {
            labels,
            datasets: [{
                data: dataset,
                backgroundColor: config?.chartColors || DOUGHNUT_CHART_COLORS,
                borderWidth: 0
            }]
        };

        return formattedChartData;
    }

    public getGroupedBarChartData(labels: (string | undefined)[], datasets: { label: string, data: number[] }[], config?: {
        barColors?: BarChartGradient[], datasetConfig?: { categoryPercentage?: number }
    }) {
        const gradients = config?.barColors || BarChartColors;
        const formattedChartData: ChartData<'bar'> = {
            labels,
            datasets: datasets.map((dataset, index) => ({
                ...dataset,
                borderRadius: 2,
                categoryPercentage: 0.4,
                barPercentage: 8,
                maxBarThickness: 8,
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        return;
                    }

                    const gradient = gradients[index] || BarChartColors[0];

                    return this.getChartGradient(ctx, chartArea, {
                        gradientTop: gradient.gradientTop,
                        gradientBottom: gradient.gradientBottom
                    });
                },
                ...config?.datasetConfig
            }))
        };

        return formattedChartData;
    }

    public getMonthYearOptions(previousMonthsCount: number): Option[] {
        const options: Option[] = [...Array(previousMonthsCount).keys()].map((___, index) => {
            const date = subMonths(new Date(), index + 1);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const monthName = this.calendarMonths[date.getMonth()];
            const optionsList = {
                name: `${monthName}/${year}`,
                value: `${month}-${year}`
            };

            return optionsList;
        });

        return options.reverse();
    }
}
