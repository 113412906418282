import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createAccountEmailInputValidator, createAccountInputValidator, createAccountPostalCodeValidator } from 'common/utils/createAccountValidators';
import { inputValidator } from '../account-admin-edit-form.service';
import { AppConstants } from 'common/app-constants';
import { UserDetails } from 'private/app/models/user.model';

@Injectable({ providedIn: 'root' })
export class UserAdminCreateService {
    public backToPreviousPageStep3: boolean = false;
    public userCreateForm = new UntypedFormGroup({
        firstName: new UntypedFormControl('', [Validators.required, Validators.maxLength(40)]),
        lastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(40)]),
        userID: new UntypedFormControl(''),
        userTitle: new UntypedFormControl('', [createAccountInputValidator(41, false)]),
        employerName: new UntypedFormControl(''),

        companyName: new UntypedFormControl(''),
        companyId: new UntypedFormControl(''),
        hvacPin: new UntypedFormControl(''),

        userAddress1: new UntypedFormControl('', [createAccountInputValidator(61, true)]),
        userAddress2: new UntypedFormControl('', [createAccountInputValidator(61, false)]),
        userCity: new UntypedFormControl('', [createAccountInputValidator(41, true)]),

        userState: new UntypedFormControl('', [Validators.required]),
        userCountry: new UntypedFormControl('', [Validators.required]),
        userPostalCode: new UntypedFormControl('', [createAccountPostalCodeValidator()]),

        userPhone: new UntypedFormControl('', [inputValidator(21, false), Validators.pattern(AppConstants.validPhoneRegex)]),
        userCellPhone: new UntypedFormControl('', [inputValidator(21, false), Validators.pattern(AppConstants.validPhoneRegex)]),
        userFax: new UntypedFormControl('', [inputValidator(21, false), Validators.pattern(AppConstants.validPhoneRegex)]),

        email: new UntypedFormControl('',
            [createAccountEmailInputValidator(101, true, AppConstants.validEmailRegex)]),
        contactOnly: new UntypedFormControl(''),

        lastLoginDate: new UntypedFormControl(''),
        registrationAction: new UntypedFormControl('', [Validators.required]),
        comments: new UntypedFormControl('', [createAccountInputValidator(500, false)])
    });

    private savedFormValues: Partial<UserDetails> | null = null;

    saveFormValues(): void {
        this.savedFormValues = this.userCreateForm.value;
    }

    restoreFormValues(): void {
        if (this.savedFormValues) {
            this.userCreateForm.setValue(this.savedFormValues);
        }
    }

    clearSavedFormValues(): void {
        this.savedFormValues = null;
    }

    public emptyUserInitialization() {
        this.userCreateForm.setValue({
            firstName: '',
            lastName: '',
            userID: '',
            userTitle: '',
            employerName: '',

            companyName: '',
            companyId: '',
            hvacPin: '',

            userAddress1: '',
            userAddress2: '',
            userCity: '',

            userState: '',
            userPostalCode: '',
            userCountry: '',

            userPhone: '',
            userCellPhone: '',
            userFax: '',

            email: '',
            contactOnly: '',

            registrationAction: 'Select',
            comments: '',
            lastLoginDate: ''
        });
    }

    public mapCreateUserDetails() {
        const createUserDetails: UserDetails = {
            userID: this.userCreateForm.value.userID,
            companyId: this.userCreateForm.value.companyId,
            employerName: this.userCreateForm.value.employerName,
            firstName: this.userCreateForm.value.firstName,
            lastName: this.userCreateForm.value.lastName,
            userTitle: this.userCreateForm.value.userTitle,
            userAddress1: this.userCreateForm.value.userAddress1,
            userAddress2: this.userCreateForm.value.userAddress2,
            userCity: this.userCreateForm.value.userCity,
            userCountry: (typeof this.userCreateForm.value.userCountry === 'object') ? this.userCreateForm.value.userCountry[0].value : this.userCreateForm.value.userCountry.substring(this.userCreateForm.value.userCountry.indexOf('-') + 1).trim(),
            userState: (typeof this.userCreateForm.value.userState === 'object') ? this.userCreateForm.value.userState[0].value : this.userCreateForm.value.userState.substring(0, this.userCreateForm.value.userState.indexOf('-')).trim(),
            userPostalCode: this.userCreateForm.value.userPostalCode,
            userPhone: this.userCreateForm.value.userPhone,
            userCellPhone: this.userCreateForm.value.userCellPhone,
            userFax: this.userCreateForm.value.userFax,
            email: this.userCreateForm.value.email,
            contactOnly: this.userCreateForm.value.contactOnly !== ''
        };

        return createUserDetails;
    }
}
