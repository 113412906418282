
import { Option } from '../../../common/components/select/select.component';

export interface Program {
    programId: number;
    programName: string;
    brandName: string;
    programManagerEmails: string[];
    programManagerEmailsCount: string;
    status: string;
    criteriaName: string;
    code: string;
}

export interface DateRange {
    startDate: number | undefined;
    endDate: number | undefined;
}

export interface EligibleCompanies {
    name: string;
    companyId: string;
    city: string;
    state: string;
    country: string;
    tierLevel: string;
}

export interface EligibilityCriteria {
    id: string;
    name: string;
    filterStmt: string,
    dealerIds: string;
    distributorIds: string,
    createdBy: string,
    createDate: string,
    modifiedBy: string,
    modifiedDate: string
    eligibleCompanies: EligibleCompanies[]
}

export interface UpdateCriteriaData {
    criteriaId: number,
    criteriaName: string,
    criteriaFilter: string | null,
    dealerCompanyIds: number[],
    distributorCompanyIds: number[],
    programIds: number[],
    selectedFields: string[]
}

export interface LocatorIcon {
    id: string;
    description: string;
}

export interface ProgramAdminLocatorIcon {
    [key:string]: LocatorIcon[] | [];
}

export interface ProgramManager {
    userId: string;
    username: string;
    firstName: string;
    lastName: string;
}

export interface ProgramDetails {
    id: string;
    name: string;
    code: string;
    brand: {
        id: string;
        name: string;
    }
    programStartDate: string;
    programEndDate: string;
    description: string;
    enrollmentStartDate: string;
    enrollmentEndDate: string;
    locatorStartDate: string;
    locatorEndDate: string;
    status: string;
    eligibilityCriteria: {
        id: string;
        name: string;
    },
    vertical: {
        id: string,
        description: string;
    },
    locatorIcon?: {
        id: string;
        description: string;
    },
    programManagers: ProgramManager[],
    enrollmentLevel: boolean;
    massEnrollFl: boolean;
    enrollmentWorkflow?: {
        id: string;
        name: string;
    },
    enrollmentForm?: {
        id: string;
        name: string;
    },
    finalApprovedBy?: {
        id: string;
        desc: string;
    }
}

export interface ProgramEditFormField {
    id: string;
    name: string;
    code: string;
    brands: Option[]
    programPeriodStart: number;
    programPeriodEnd: number;
    description: string;
    enrollmentStartDate: number;
    enrollmentEndDate: number;
    locatorStartDate: number;
    locatorEndDate: number;
    eligibilityCriteria: Option[],
    vertical: Option[],
    locatorIcon: Option[],
    programManagers: Option[],
    enrollmentLevel: boolean;
    massEnrollFl: boolean;
    enrollmentWorkflow: Option[],
    enrollmentForm: Option[],
    finalApprovedBy: Option[]
}

export interface AccountAdminProgramEditData {
    programDetails: ProgramDetails;
    programStart: number;
    programEnd: number;
    enrollmentPeriodStart: number;
    enrollmentPeriodEnd: number;
    locatorStart: number;
    locatorEnd: number;
    criteria: Option[];
    locatorIcons: Option[];
    locatorIconData: ProgramAdminLocatorIcon;
    programManagers: ProgramManager[];
    brandValue: Option[];
}

export interface ProgramSearchResults {
    data: Program[];
    totalCount: number
}

export interface SortParams {
    field: string,
    direction: string
}
export interface ProgramSearchPayload {
    criteria: {
        programName: string;
        programCode: string;
        programStatus: string;
        brandName: string;
        eligibleCriteriaName: string
    },
    pageParams: {
        page: number,
        pageSize: number
    },
    sortFields: string[],
    sortParams: SortParams[],
    determineTotalCount: boolean
}

export interface Brand {
    name: string;
    id: string;
}

export interface CriteriaName {
    name: string;
    id: string;
}

export interface ProgramData {
    id: number,
    name: string,
    code: string,
    startDate: string,
    endDate: string,
    enrollmentStartDate: string,
    enrollmentEndDate: string,
    description: string,
    locatorStartDate: string,
    locatorEndDate: string,
    parentBrandId: number,
    brandName: string,
    eligibilityCriteriaId: number,
    enrollmentRequiredFi: boolean,
    locatorIconVisibilityId: number,
    massEnrollFi: boolean,
    enrollmentWorkflowId: number,
    verticalTypeId: number,
    approvedUserTypeId: number,
    enrollmentFormTypeId: number
}

export interface EnrollmentOption {
    name: string;
    value: string;
    enrollmentType: string;
    confirmDetails: string;
}

export interface ProgramEnrollment {
    dealerName: string;
    dealerId: number;
    dealerDisplayId: number;
    city: string;
    state: string;
    enrollmentFormId: number;
    enrollmentLevel: number;
    enrollmentStatus: number;
    enrollmentWorkFlowStatusId: number;
    sponsoringDistributor: string;
    isChecked: boolean;
    enrollmentOptions: EnrollmentOption[];
}

export interface AccountAdminProgramEnrollments {
    programId: number;
    programName: string;
    brand: string;
    programStatus: string;
    enrollmentStatus: string;
    enrollmentLevel: string;
    enrollmentWorkFlowStatusId: number;
    enrollmentFormId: number;
    enrollmentWorkflowId: number;
    isChecked: boolean;
    enrollmentOptions: EnrollmentOption[];
}

export interface EnrollmentStatusPayload {
    programId: number;
    dealerCompanies: number[];
    enrollmentStatusId: number;
}

export class DefaultProgramDetails implements ProgramDetails {
    id = '';
    name = '';
    code = '';
    brand = {
        id: '',
        name: ''
    };

    programStartDate = '';
    programEndDate = '';
    description = '';
    enrollmentStartDate = '';
    enrollmentEndDate = '';
    locatorStartDate = '';
    locatorEndDate = '';
    status = '';
    eligibilityCriteria = {
        id: '',
        name: ''
    };

    vertical = {
        id: '',
        description: ''
    };

    locatorIcon = {
        id: '',
        description: ''
    };

    enrollmentLevel = false;
    massEnrollFl = false;
    enrollmentWorkflow = {
        id: '',
        name: ''
    };

    enrollmentForm = {
        id: '',
        name: ''
    };

    finalApprovedBy = {
        id: '',
        desc: ''
    }

    programManagers = [];
}

export interface CountryOption {
    code: string;
    value: string;
}

export interface GMTTimeFormattingOptions {
    formatTimeAs: 'StartOfDay' | 'EndOfDay'
}
