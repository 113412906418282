<ng-template [ngIf]="isUserDetailsAvailable" [ngIfElse]="spinner">
    <div *ngIf="userRegistrationPage2" class="hvac-user-admin-container">
        <h1 class="hvac-p-sm">
            {{ 'USER_ADMIN.USER_SECURITY.ROLE_GROUPING' | translate | uppercase}}
        </h1>
        <hvac-tab-bar variant="secondary">
            <button *ngFor="let tab of rolesTabs; let i = index;" (click)="handleTabClick(i, tab)" [ngClass]="{ 'hvac-active' : selectedTab === i }">{{ tab }}</button>
        </hvac-tab-bar>

        <hvac-table class="hvac-user-admin-table-wrap-roles" [theme]="'zebra'" [responsive]="true">
            <tbody>
                <tr *ngFor="let role of getRolesList()">
                    <td class="hvac-checkbox-container">
                        <utc-checkbox [checked]="role.isSelected || false" (change)="roleSelected(role)"></utc-checkbox>
                    </td>
                    <td class="hvac-user-admin-table-row-data">{{ role.roleDisplayName }}</td>
                    <td class="hvac-user-admin-table-row-details">
                        <a class="hvac-text-link" (click)="onSelectRoleAndFeatureDetails(role,null)">{{"USER_ADMIN.USER_SECURITY.ROLE_DETAILS"| translate}}</a>
                    </td>
                </tr>
            </tbody>
        </hvac-table>
    </div>

    <utc-loading-spinner spinnerSize="small" *ngIf="isLoading"></utc-loading-spinner>

    <div *ngIf="userRegistrationPage3" class="hvac-user-admin-container">
        <h1 class="hvac-p-sm">
            {{ 'ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.FEATURES_CATEGORY' | translate | uppercase}}
        </h1>
        <hvac-tab-bar variant="secondary">
            <button *ngFor="let tab of additionalRolesTab; let i = index;" (click)="handleAddTabClick(i, tab)" [ngClass]="{ 'hvac-active' : selectedAddTab === i }">{{ tab }}</button>
        </hvac-tab-bar>

        <hvac-table class="hvac-user-admin-table-wrap-roles" [theme]="'zebra'" [responsive]="true">
            <tbody>
                <tr *ngFor="let addtnlRole of getAdditionalRolesList()">
                    <td class="hvac-checkbox-container">
                        <utc-checkbox [checked]="addtnlRole.isSelected || false" (change)="addtnlRoleSelected(addtnlRole)"></utc-checkbox>
                    </td>
                    <td class="hvac-user-admin-table-row-data">{{ addtnlRole.featureDisplayName }}</td>
                    <td class="hvac-user-admin-table-row-details">
                        <a class="hvac-text-link" (click)="onSelectRoleAndFeatureDetails(null,addtnlRole)">{{ 'USER_ADMIN.USER_SECURITY.FEATURE_DETAILS' | translate}}</a>
                    </td>
                </tr>   
            </tbody>
        </hvac-table>
    </div>

    <div *ngIf="userRegistrationPage4" class="hvac-user-admin-container hvac-user-info-brief-container">
        <div class="hvac-user-info-brief-header">
            <div class="hvac-user-info-header">
                <h2>{{ 'ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.USER_DETAILS' | translate }}</h2>
            </div>
        </div>
        <div class="hvac-row">
            <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
                <ul class="hvac-account-admin-detail-list">
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.EMPLOYER_ID' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.companyId || '--' }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.EMPLOYER_NAME' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.employerName || '--' }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.USER_NAME' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ (this.page === 'createUser') ? '--' : userDetails.userID }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.FIRST_NAME' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.firstName || '--' }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.LAST_NAME' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.lastName || '--' }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.TITLE' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.userTitle || '--' }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.ADDRESS1' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.userAddress1 || '--' }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.ADDRESS2' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.userAddress2 || '--' }}</div>
                    </li>
                </ul>
            </div>

            <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
                <ul class="hvac-account-admin-detail-list">
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.CITY' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.userCity || '--' }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.COUNTRY' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.userCountry || '--' }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.STATE_PROVINCE' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.userState || '--' }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.ZIP_POSTAL_CODE' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.userPostalCode || '--' }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.PHONE' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.userPhone || '--' }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.MOBILE_NUMBER' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.userCellPhone || '--' }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.FAX_NUMBER' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.userFax || '--' }}</div>
                    </li>
                    <li class="hvac-row hvac-top-xs">
                        <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.EMAIL' | translate}}</div>
                        <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userDetails.email || '--' }}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <section class="hvac-user-admin-container hvac-user-info-brief-container">
        <div class="hvac-user-info-brief-header">
            <div class="hvac-user-info-header">
                <h2>{{ 'ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.USER_SUMMARY' | translate }}</h2>
            </div>
            <div (click)="getUserDetailsSummery()" class="hvac-user-info-down-arrow">
                <utc-svg class="hvac-text-link" src="assets/icons/chevron-down.svg"></utc-svg>
            </div>
        </div>
        <div [ngClass]="{'hidden': isUserDetailsSummeryMode}">
            <div class="hvac-divider"></div>
            <hvac-table class="hvac-user-admin-table-wrap-user-info" [theme]="'zebra'" [responsive]="true">
                <tbody>
                    <tr>
                        <td class="hvac-user-admin-table-row-data">{{ 'USER_ADMIN.USER_SECURITY.USER_SUMMARY.DEFAULT_ROLES' | translate}}</td>
                        <td class="hvac-user-admin-table-row-details">
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ getSystemDefaultRole() }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="hvac-user-admin-table-row-data">{{ 'USER_ADMIN.USER_SECURITY.USER_SUMMARY.SELECTED_ROLES' | translate}}</td>
                        <td class="hvac-user-admin-table-row-details">
                            <ul>
                                <li *ngFor="let item of selectedRoles" class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ item.roleDisplayName }}</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td class="hvac-user-admin-table-row-data">{{ 'USER_ADMIN.USER_SECURITY.USER_SUMMARY.FEATURES_SELECTED_BY_ROLES' | translate}}</td>
                        <td class="hvac-user-admin-table-row-details">
                            <ul>
                                <li *ngFor="let item of featuresSelectedByRole" class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ item }}</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td class="hvac-user-admin-table-row-data">{{ 'USER_ADMIN.USER_SECURITY.USER_SUMMARY.ADDITIONAL_FEATURES_SELECTED' | translate}}</td>
                        <td class="hvac-user-admin-table-row-details">
                            <ul>
                                <li *ngFor="let item of selectedAdditionalRoles" class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ item.featureDisplayName }}</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </hvac-table>
        </div>
    </section>

    <div class="hvac-flex-end" *ngIf="userRegistrationPage2">
        <button type="button" class="hvac-edit-button" hvacButton="default" (click)="goToUserDetails()">
            {{ 'FOOTER.BACK_TO_PREVIOUS_STEP' | translate}}
        </button>
        <button type="button" class="hvac-edit-button" hvacButton="primary" (click)="goToUserFeatures()">
            {{ "FOOTER.CONTINUE" | translate}}
        </button>
    </div>
    <div class="hvac-flex-end" *ngIf="userRegistrationPage3">
        <button class="hvac-edit-button" type="button" [hvacButton]="'default'" (click)="goToUserRoles()">
            <span>{{ "FOOTER.BACK_TO_PREVIOUS_STEP" | translate }}</span>
        </button>
        <button type="button" class="hvac-edit-button" hvacButton="primary" (click)="goToUserSummary()">
            {{ "FOOTER.CONTINUE" | translate}}
        </button>
    </div>
    <div class="hvac-flex-end" *ngIf="userRegistrationPage4">
        <button class="hvac-edit-button" type="button" [hvacButton]="'default'" (click)="goToUserFeatures()">
            <span>{{ "FOOTER.BACK_TO_PREVIOUS_STEP" | translate }}</span>
        </button>
        <button type="button" class="hvac-edit-button" hvacButton="primary" (click)="onSubmit()">
            {{ "FOOTER.SUBMIT" | translate}}
        </button>
    </div>
</ng-template>

<ng-template [ngIf]="userRoleAndFeatureDetails">
    <div class="x-overflow-hidden">
        <utc-modal modal [visible]="true" (closeAction)="userRoleAndFeatureModalClose()" size="dynamic-height" theme="rounded" class="hvac-modal-close" [showHeader]="false">
            <div class="hvac-edit-user-security-modal">
                <hvac-modal-header>
                    <h2>{{ selctedDisplayName }}</h2>
                </hvac-modal-header>
                <div class="modal-content">{{selectedDesc}}</div>
            </div>
            <div class="hvac-divider"></div>
            <div class="display-flex-end">
                <button (click)="userRoleAndFeatureModalClose()" class="modal-button" hvacButton="primary">{{'CLOSE' | translate}}</button>
            </div>
        </utc-modal>
    </div>
</ng-template>

<ng-template #spinner>
    <utc-loading-spinner></utc-loading-spinner>
</ng-template>
