export enum CompanyType {
    DOMESTIC_DISTRIBUTOR = 'DDT',
    INDEPENDENT_SALES_REP = 'REP',
    DIRECT_SALES_OFFICE = 'DSO',
    INTERNATIONAL_DISTRIBUTOR = 'DID',
    MARINE_DISTRIBUTOR = 'DMR',
}

export enum CompanyCategoryType {
    INTERNAL = 'Internal',
    DOMESTIC_DISTRIBUTOR = 'Domestic Distributor',
    INTERNATIONAL_DISTRIBUTOR = 'International Distributor',
    MARINE_DISTRIBUTOR = 'Marine Distributor',
    HVAC_CUSTOMER = 'HVAC Customer',
    CUSTOMER = 'Customer',
    INTERNATIONAL_CUSTOMER = 'International Customer',
    MARINE_CUSTOMER = 'Marine Customer',
    NATIONAL_ACCOUNT_CUSTOMER = 'National Account Customer',
    NATIONAL_ACCOUNT = 'National Account',
    VENDOR = 'Vendor',
    DEFAULT = 'DEF'
}
export interface FormFieldAction {
    view?: boolean | true;
    edit?: boolean | true;
    mandatory?: boolean | false
}

export interface AccountAdminDetailsAssociation {
    associatedDistributor: boolean,
    associatedContact: boolean,
    associatedDealer: boolean,
    addContact: boolean
}

export interface AccountAdminEditFormConfig {
    companyName?: FormFieldAction;
    companyCategory?: FormFieldAction;
    companyType?: FormFieldAction;
    companyId?: FormFieldAction;
    parentCompany?: FormFieldAction;
    companyUrl?: FormFieldAction;
    emailAddress?: FormFieldAction;
    address1?: FormFieldAction;
    address2?: FormFieldAction;
    country?: FormFieldAction;
    state?: FormFieldAction;
    city?: FormFieldAction;
    postalCode?: FormFieldAction;
    phone?: FormFieldAction;
    mobile?: FormFieldAction;
    tollFree?: FormFieldAction;
    fax?: FormFieldAction;
    email?: FormFieldAction;
    rsm?: FormFieldAction;
    prsm?: FormFieldAction;
    accountManager?: FormFieldAction;
    partAccountManager?: FormFieldAction;
    tsm?: FormFieldAction;
    searchRadius: FormFieldAction;
    hoursOfOperation?: FormFieldAction;
    language: FormFieldAction;
    brands?: FormFieldAction;
    services?: FormFieldAction;
    ownership: FormFieldAction;
    // Is Legal Entity or Is Head Quarters
    status?: FormFieldAction;
    soldTo?: FormFieldAction;
    shipToNumber?: FormFieldAction;
    divRegNumber: FormFieldAction;
    isPrimary?: FormFieldAction;
    isAssignable?: FormFieldAction;
    c10Parent: FormFieldAction;
    c10Child: FormFieldAction;
    storeNumber: FormFieldAction;
    flagForPOS?: FormFieldAction;
    flagForDC?: FormFieldAction;
    payer?: FormFieldAction;
    claimType: FormFieldAction;
    contactLic: FormFieldAction;
    priceGroup?: FormFieldAction;
    priceList?: FormFieldAction;
    legalEntity: FormFieldAction;
    pricingAnalyst?: FormFieldAction;
    latitude?: FormFieldAction;
    longitude?: FormFieldAction;
    locatorName?: FormFieldAction;
    showOnDistributorLocator?: FormFieldAction;
    salesOfficeNumber: FormFieldAction;
    notes: FormFieldAction;

    // IT Super Admin
    inventoryAPI: FormFieldAction;
    regionCode: FormFieldAction;
    customerUserName: FormFieldAction;
    customerGUID: FormFieldAction;
}

export interface AccountAdminServiceType { DSO?: string[], DDT?: string[], REP?: string[], DID?: string[], DCR?: string[], ARC?: string[], ENG?: string[], DBC?: string[], NBR?: string[] }

export interface AccountAdminDetailsEditConfiguration {
    companyType?: string;
    configType?: CompanyCategoryType;
    formConfig: AccountAdminEditFormConfig;
    association?: AccountAdminDetailsAssociation;
    elightFeatures?: boolean;
    addBrands?: boolean;
    managerInformation: {
        show?: boolean;
        partAM?: FormFieldAction;
        partRSM?: FormFieldAction;
    };
    distributorLocator?: boolean;
    services?: AccountAdminServiceType;
    program?: boolean;
}


