<p class="hvac-phvac-account-admin-results-count">
    <span *ngIf="totalCount && !isButtonClicked && (companies)?.length">{{ 'ACCOUNT_ADMIN.RESULTS_COUNT' | translate: { totalCount: totalCount} }}</span>
</p>
    <hvac-table class="hvac-program-table-wrap" [theme]="'zebra'" [responsive]="true" *ngIf="!isButtonClicked">
        <thead>
            <tr>
                <td *ngFor="let item of dataHeaders">
                    <button class="hvac-table-sort-btn" (click)="sort(item)">
                        {{item.title}}
                        <span class="hvac-table-list-sort" [ngClass]="{
                            'hvac-table-list-sort-show': item.order,
                            'column-ascending': item.order === 'asc'
                        }">
                            <utc-svg src="assets/icons/chevron-down.svg"></utc-svg>
                        </span>
                    </button>
                </td>
            </tr>
        </thead>
        <tbody>
                <tr *ngFor="let company of companies; let row=even;">
                    <td  [attr.data-label]="dataHeaders[0].title">
                        {{company.hvacCompanyId}}
                    </td>
                    <td [attr.data-label]="dataHeaders[1].title">
                        {{company.soldTo}}
                    </td>
                    <td [attr.data-label]="dataHeaders[2].title">
                        {{company.companyName}}
                    </td>
                    <td [attr.data-label]="dataHeaders[3].title">
                        {{[company.address1, company.address2].join(", ")}}
                    </td>
                    <td [attr.data-label]="dataHeaders[4].title">
                        {{company.city}}
                    </td>
                    <td [attr.data-label]="dataHeaders[5].title">
                        {{company.postalCode}}
                    </td>
                    <td [attr.data-label]="dataHeaders[6].title">
                        {{company.state}}
                    </td>
                    <td [attr.data-label]="dataHeaders[7].title">
                        {{company.country}}
                    </td>
                    <td [attr.data-label]="dataHeaders[8].title">
                        {{company.companyType}}
                    </td>
                    <td class="wrap-word" [attr.data-label]="dataHeaders[9].title">
                        {{company.brands}}
                    </td>
                    <td (click)="onClickSelectCompany(company)"><button [hvacButton]="row ? 'default' : 'secondary'" type="button">Select</button></td>
                </tr>
        </tbody>

    </hvac-table>
    <p class="hvac-admin-programs-no-results" *ngIf="!(companies)?.length && !loading">{{ 'ACCOUNT_ADMIN.NO_RESULTS' | translate }}</p>
    <utc-loading-spinner spinnerSize="small" *ngIf="loading"></utc-loading-spinner>
    <div class="hvac-divider"></div>
    <div class="hvac-pagination" *ngIf="!isButtonClicked">
        <hvac-pagination *ngIf="totalPages > 1 && (companies)?.length" [totalPages]="totalPages" [currentPage]="pagination"
            (pageChange)="handlePageChange($event)">
        </hvac-pagination>
    </div>
    