<section class="hvac-program-enrollments-header">
    <hvac-content-header [data]="breadCrumbData"></hvac-content-header>
</section>


<div class="hvac-program-enrollments">
    <section class="hvac-content-container">
        <div class="hvac-tab-container">
            <h1 class="hvac-h4 hvac-company-name"> {{ hvacpCompanyName }}</h1>
            <button [hvacButton]="'text'" (click)="backToCompanyDetails()">
                <span>{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.BACK_TO_COMPANY_DETAILS' | translate }}</span>
            </button>
        </div>

        <section class="hvac-enrollments-table">
            <hvac-table class="hvac-enrollment-table-wrap" [theme]="'zebra'" [responsive]="true">
                <thead>
                    <tr>
                        <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAM_ENROLLMENTS.PROGRAM_NAME.SELECT_ALL' | translate">
                            <utc-checkbox class="hvac-select-all-checkbox" [checked]="isAllEnrllmntsSlctdFrCrntFltr" (change)="handleSelectAll($event)"></utc-checkbox>
                        </td>
                        <td>
                            {{ 'ACCOUNT_ADMIN.PROGRAM_ENROLLMENTS.PROGRAM_NAME' | translate }}
                        </td>
                        <td>
                            {{ 'ACCOUNT_ADMIN.PROGRAM_ENROLLMENTS.BRAND' | translate }}
                        </td>
                        <td>
                            {{ 'ACCOUNT_ADMIN.PROGRAM_ENROLLMENTS.PROGRAM_STATUS' | translate }}
                        </td>
                        <td>
                            {{ 'ACCOUNT_ADMIN.PROGRAM_ENROLLMENTS.ENROLLMENT_STATUS' | translate }}
                        </td>
                        <td>
                            {{ 'ACCOUNT_ADMIN.PROGRAM_ENROLLMENTS.ENROLLMENT_LEVEL' | translate }}
                        </td>
                        <td>
                            {{ 'ACCOUNT_ADMIN.PROGRAM_ENROLLMENTS.WORKFLOW_STATUS' | translate }}
                        </td>
                        <td>
                            {{ 'ACCOUNT_ADMIN.PROGRAM_ENROLLMENTS.ENROLLMENT_ACTION' | translate }}
                        </td>
                    </tr>
                </thead>
    
                <tbody>
                    <tr *ngFor="let enrollment of (currentPageEnrollmentData$ | async)">
                        <td>
                            <utc-checkbox [checked]="enrollment.isChecked" (change)="selectEnrollment(enrollment)"></utc-checkbox>
                        </td>
                        <td>{{ enrollment.programName }}</td>
                        <td>{{ enrollment.brand }}</td>
                        <td>{{ enrollment.programStatus }}</td>
                        <td>{{ enrollment.enrollmentStatus }}</td>
                        <td>{{ enrollment.enrollmentLevel }}</td>
                        <td>
                            <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 0">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.ELIGIBLE' | translate }}</span>
                            <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 1">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.SM_APPROVED' | translate }}</span>
                            <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 2">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.PM_APPROVED' | translate }}</span>
                            <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 3">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.SM_DECLINED' | translate }}</span>
                            <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 4">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.PM_DECLINED' | translate }}</span>
                            <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 5">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.PM_RETURNED' | translate }}</span>
                            <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 6">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.DIST_SUBMITTED' | translate }}</span>
                            <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 7">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.SM_RETURNED' | translate }}</span>
                        </td>
                        <td>
                            <utc-select [shadow]="'none'" [size]="'small'"
                            [options]="enrollment.enrollmentOptions" 
                            [defaultOption]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.SELECT' | translate"
                            [group]="enrollmentActionForm" [control]="'enrollmentAction' + enrollment.programId"
                            [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.SELECT' | translate"
                            (change)="onSelectDealerConversionEnrollment(enrollment, enrollmentActionForm.controls['enrollmentAction' + enrollment.programId].value[0])">
                            </utc-select>
                        </td>
                    </tr>
                </tbody>
            </hvac-table>
            <p class="hvac-admin-enrollments-no-results" *ngIf="!(currentPageEnrollmentData$ | async)?.length && !isLoading">{{ 'ACCOUNT_ADMIN.NO_RESULTS' | translate }}</p>
            <utc-loading-spinner spinnerSize="small" *ngIf="isTableLoading"></utc-loading-spinner>
            <div class="hvac-row">
                <div class="hvac-col-lg-6 hvac-col-md-6">
                    <div class="hvac-pagination" *ngIf="!isTableLoading">
                        <hvac-pagination *ngIf="totalPages > 1" [totalPages]="totalPages" [currentPage]="currentPage"
                            (pageChange)="handlePageChange($event)">
                        </hvac-pagination>
                    </div>
                </div>
                <div class="hvac-col-lg-6 hvac-col-md-6">
                    <div class="hvac-enrollment-buttons" *ngIf="!isTableLoading">
                        <button [hvacButton]="'default'" (click)="backToCompanyDetails()">
                            <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.CANCEL' | translate }}</span>
                        </button>
                        <button [hvacButton]="'primary'" (click)="exportSelectedPrograms()" [disabled]="isTableLoading">
                            <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.EXPORT' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>

        <utc-modal modal
            [visible]="isEnrollModalVisible"
            theme="rounded"
            (closeAction)="handleCloseModal()"
            class="hvac-enrollment-status-control-modal">
            <hvac-modal-header><span class="hvac-h4">{{ 'ACCOUNT_ADMIN.PROGRAMS.CONFIRM' | translate }}</span></hvac-modal-header>
            <div class="hvac-enrollment-status-content hvac-rhythm-4">
                <p class="hvac-p-sm">{{enrollmentConfirmMessage}}</p>
            </div>
            <div class="hvac-enrollment-status-buttons">
                <button hvacButton="default" size="small" (click)="handleCloseModal()">{{ 'ACCOUNT_ADMIN.PROGRAMS.CANCEL' | translate }}</button>
                <button hvacButton="primary" size="small" (click)="handleOkModal()">{{ 'ACCOUNT_ADMIN.PROGRAMS.OK' | translate }}</button>
            </div>
        </utc-modal>

    </section>
</div>

