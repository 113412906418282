import { Component, Input, NgZone, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { endOfDay, startOfDay } from 'date-fns';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountAdminFormResultMap } from '../../models/account-admin-search';
import { AccountAdminService } from '../../services/account-admin/account-admin.service';
import { Brand, Country } from '../../services/user-validation/user-validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'common/components/select/select.component';
import { environment } from 'common/environments/environment';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { IdToken } from 'common/models';
import { OktaService } from 'common/services/okta/okta.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { RelationshipCriteriaDate } from 'private/app/models/account-admin-company.model';
import endOfYear from 'date-fns/endOfYear';
import { map, mergeMap } from 'rxjs/operators';
import { AccountPostAdminService } from 'private/app/services/account-admin/account-post-admin.service';
import { Select, Store } from '@ngxs/store';
import { ApprovalRequestsState } from 'private/app/store/approval-requests.state';
import { FetchApprovalRequests } from 'private/app/store/approval-requests.actions';
import { AccountAdminDistributorBranch } from 'private/app/models/account-admin-search-results';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
import { ToastService } from 'common/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanyCategoryType } from 'private/app/views/account-admin-company-details/company-query/company-query-configuration';
import { Values } from 'private/app/services/account-admin/account-admin-form-group-config';

export interface AllCountries{
    name: string
    value: string | null
}
@Component({
    selector: 'hvac-account-admin-form',
    templateUrl: './account-admin-form.component.html',
    styleUrls: ['./account-admin-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccountAdminFormComponent implements OnInit {
    @Input() onAccountAdminResult: Boolean;
    @Select(ApprovalRequestsState.requestCount) requestCount$: Observable<number>;
    approvalRequestsNotification$: Observable<string>;
    public readonly todayDate: number = new Date().getTime();
    public readonly endOfYearDate: number = endOfYear(this.todayDate).getTime();
    date$: BehaviorSubject<RelationshipCriteriaDate>;

    dateError$: BehaviorSubject<{ dateType: string, error: boolean }> = new BehaviorSubject<{ dateType: string, error: boolean }>({
        dateType: '',
        error: false
    });

    public startDate: number | undefined;
    public endDate: number | undefined;

    dateErrorMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public idmUserRole: string[];
    public regAdminLogged: boolean = false;
    public showRegistartionRequests = false;
    public registrationRequestFlag: boolean = environment.features.registrationRequest;
    public formData?: AccountAdminFormResultMap;
    public showDealerSearchCriteriaCIN = environment.features.dealerSearchCriteriaCIN;
    public showRelationshipType = environment.features.relationshipType;
    public isLegealEntitySelected: boolean = false;
    public legalEntityOptionData$ = new BehaviorSubject<Option[]>([]);
    public distributorBranchOptionData$ = new BehaviorSubject<Option[]>([]);
    public companyTypes: Option[];
    isCreateUserFeatureAvailable = environment.features.accountAdminCreateUser;
    adminAccess: boolean;
    public isNonInternalDistributor$ = new BehaviorSubject<boolean>(false);
    isAccordionOpen: boolean;
    isSubmitDisabled: boolean = true;
    public countryList$: Observable<Option[]>;
    isResetDateRangePicker: boolean = false;
    public companyRelationDateControls: [FormControl, FormControl];
    public companyCategory : string = '';
    public isInternalUser : boolean = false;
    public isDistributor : boolean = false;
    public selectID: string;

    constructor(
        public accountAdminService: AccountAdminService,
        public commonUtils: CommonUtilsService,
        public accountPostAdminService: AccountPostAdminService,
        private router: Router,
        private translate: TranslateService,
        private ngZone: NgZone,
        private route: ActivatedRoute,
        private oktaService: OktaService,
        private readonly store: Store,
        private toastService: ToastService
    ) {}

    @HostListener('keyup', ['$event'])
    public keyListener(evt: KeyboardEvent) {
        if (evt && evt.code && evt.code.toLowerCase() === 'enter' && !this.isSubmitDisabled) {
            this.onSubmit(this.accountAdminService.advancedSearchForm);
        }
    }

    ngOnInit(): void {
        this.oktaService.idToken$.subscribe((res: IdToken) => {
            this.idmUserRole = res?.claims?.idm_user_roles || [];
            this.companyCategory = res?.claims?.idm_user_companies[0]?.companyCategory?.name;
            this.isInternalUser = this.companyCategory === this.translate.instant('ACCOUNT_ADMIN.INTERNAL');
            this.isDistributor = this.companyCategory === CompanyCategoryType.DOMESTIC_DISTRIBUTOR;
            this.isNonInternalDistributor$.next(this.isNonInternalDistributor(this.idmUserRole));
            if (this.isNonInternalDistributor$.value) {
                this.getDistributorBranches(res?.claims?.idm_user_companies[0]?.id);
            }
            if (this.idmUserRole.includes(IdmUserRoles.REG_ADMIN)) {
                this.regAdminLogged = true;
            }
        });
        this.adminAccess = (this.route.snapshot.data['adminAccess'] as IdmUserRoles[])?.includes(IdmUserRoles.ACCOUNTADMIN);
        this.accountAdminService.getAccountAdminSearchForm().subscribe((data: AccountAdminFormResultMap) => {
            data.brands = this.filterBrands(data.brands);
            this.legalEntityOptionData$.next(data.legalEntity);
            this.companyTypes = data.companyTypes.map(({ companyTypes }) => companyTypes).flat().map((companyType) => ({
                name: companyType?.name!,
                value: companyType?.code!
            })).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            this.companyTypes.unshift({
                name: this.translate.instant('ACCOUNT_ADMIN.SELECT_COMPANY_TYPE'),
                value: ''
            });
            this.formData = data;
        }, (error: HttpErrorResponse) => this.toastService.raiseDefaultErrorToast('AccountAdminFormComponent-getAccountAdminSearchForm', error.message));

        this.countryList$ = this.commonUtils.getCountryOptions().pipe(
            map((countries) => this.reprioritizeCountries(countries))
        );
        this.isAccordionOpen = false;
        this.store.dispatch(new FetchApprovalRequests());
        this.approvalRequestsNotification$ = this.requestCount$.pipe(
            mergeMap((count) => this.translate.get('ACCOUNT_ADMIN.REGISTRATION_REQUEST', { count }))
        );
        this.showRegistartionRequests = this.regAdminLogged && this.registrationRequestFlag;
        this.companyRelationDateControls = [this.accountAdminService.advancedSearchForm.controls.startDate, this.accountAdminService.advancedSearchForm.controls.endDate];

        Object.keys(this.accountAdminService.advancedSearchForm.controls).forEach((field) => {
            if (this.commonUtils.dropDownsList.includes(field)) {
                this.onDropDownChanges(field);
            }
        });

        this.accountAdminService.advancedSearchForm.valueChanges.subscribe((values) => {
            this.isSubmitDisabled = this.checkIfFormIsEmpty(values);
        });
    }

    onDropDownChanges(field: string) {
        const control = this.accountAdminService.advancedSearchForm.get(field) as FormControl;
        control.valueChanges.subscribe((value) => {
            if (value[0]?.value) {
                this.commonUtils.disabledDropdowns = this.commonUtils.updateDropdownState(field as keyof Values);
                this.commonUtils.dropDownsList.forEach((key) => ((key === field) ? '' : this.accountAdminService.advancedSearchForm.get(key)?.disable()));
            }
            else if (value[0]?.value === '') {
                this.commonUtils.disabledDropdowns = this.commonUtils.enableAllDropdowns();
                this.commonUtils.dropDownsList.forEach((key) => {
                    this.accountAdminService.advancedSearchForm.get(key)?.reset('');
                    this.accountAdminService.advancedSearchForm.get(key)?.enable();
                });
            }
        });
    }

    handleToggle() {
        this.isAccordionOpen = !this.isAccordionOpen;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    checkIfFormIsEmpty(values: any): boolean {
        /* eslint-disable no-undefined */
        return !Object.values(values).some((value) => {
            if (typeof value === 'boolean') {
                return value;
            }

            if (Array.isArray(value)) {
                return value[0]?.value !== '';
            }

            return value !== null && value !== '' && value !== undefined;
        });
    }

    public onSubmit(event: UntypedFormGroup) {
        this.accountAdminService.lastQueryParams.next(this.accountAdminService.createQueryParams(event));


        this.ngZone.run(() => {
            this.router.navigate([`/Admin/Admin-Tools/account-admin.html/results/${this.accountAdminService.currentTab}`], { queryParams: this.accountAdminService.lastQueryParams.value });
        });
    }

    setRelationshipCriteriaDate(dates: RelationshipCriteriaDate) {
        this.isResetDateRangePicker = false;
        if (dates.startDate) {
            this.startDate = startOfDay(dates.startDate).getTime();
        }
        if (dates.endDate) {
            this.endDate = endOfDay(dates.endDate).getTime();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reprioritizeCountries(options: any) {
        const filteredOptions = options.filter((item: Country) => item.value !== 'MX' && item.value !== 'US' && item.value !== 'CA' && item.value !== '');
        filteredOptions.unshift({
            name: 'Mexico',
            value: 'MX'
        });
        filteredOptions.unshift({
            name: 'Canada',
            value: 'CA'
        });
        filteredOptions.unshift({
            name: 'USA',
            value: 'US'
        });
        filteredOptions.unshift({
            name: this.translate.instant('ACCOUNT_ADMIN.SELECT_COUNTRY'),
            value: ''
        });

        return filteredOptions;
    }

    clearAll() {
        /* eslint-disable no-undefined */
        this.startDate = undefined;
        this.endDate = undefined;
        this.isResetDateRangePicker = true;
        this.commonUtils.disabledDropdowns = this.commonUtils.enableAllDropdowns();
        this.commonUtils.dropDownsList.forEach((key) => {
            this.accountAdminService.advancedSearchForm.get(key)?.reset('');
            this.accountAdminService.advancedSearchForm.get(key)?.enable();
        });
        this.accountAdminService.advancedSearchForm.reset(this.accountAdminService.advancedSearchFormDefault.value);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterBrands(options: any) {
        const filteredOptions = options.filter((item: Brand) => (item.usageType && item.usageType.includes('PRODUCTS')) || item.name === this.translate.instant('ACCOUNT_ADMIN.SELECT_BRAND'));

        return filteredOptions;
    }

    isNonInternalDistributor(idmUserRoles: string[]) {
        return !idmUserRoles?.includes(IdmUserRoles.DEFAULT_INTERNAL) && (idmUserRoles?.includes(IdmUserRoles.COMPANY_TYPE_INTERNATIONAL_DISTRIBUTOR) || idmUserRoles?.includes(IdmUserRoles.COMPANY_TYPE_DOMESTIC_DISTRIBUTOR) || idmUserRoles?.includes(IdmUserRoles.COMPANY_TYPE_MARINE_DISTRIBUTOR));
    }

    onLegalEntityChange(event: Option[]) {
        if (!this.isNonInternalDistributor$.value) {
            this.selectID = event[0]?.value;
            if (event[0]?.value !== this.accountAdminService.selectedLegalEntityValue$.value) {
                this.accountAdminService.advancedSearchForm.controls.distributorBranch.setValue('');
            }
            this.distributorBranchOptionData$.next([]);
            if (event[0]?.value) {
                this.isLegealEntitySelected = true;
                this.accountAdminService.selectedLegalEntityValue$.next(event[0].value);
                this.getDistributorBranches(event[0].value);
            }
            else {
                this.isLegealEntitySelected = false;
            }
        }
    }

    getDistributorBranches(selectedLegalEntityId: string) {
        this.accountPostAdminService.getDistributorBranch(selectedLegalEntityId).subscribe((branches) => {
            this.distributorBranchOptionData$.next(this.mapDistributorBranches(branches));
        });
    }

    mapDistributorBranches(distBranches: AccountAdminDistributorBranch[]): Option[] {
        return [
            {
                name: this.translate.instant('ACCOUNT_ADMIN.SELECT_DISTRIBUTOR_BRANCH'),
                value: ''
            },
            ...distBranches.map((branch: AccountAdminDistributorBranch) => ({
                name: branch.companyName,
                value: branch.hvacCompanyId
            })).slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()))
        ];
    }
}
