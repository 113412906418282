import { Address } from './address.model';
import { CompaniesEntity } from './company.model';

export interface UserEntity {
    address: Address;
    companies?: (CompaniesEntity)[] | null;
    creationDate: string;
    createdBy: string;
    defaultCompanyId: string;
    defaultCompanyName: string;
    emailAddress: string;
    firstName: string;
    id: string;
    lastName: string;
    lastModifiedDate: string;
    lastModifiedBy: string;
    lastLoginDate: string;
    status: string;
    brandFamily: string;
}

export interface UserFeatures {
    featureId: string;
    featureDesc?: string;
    featureName: string;
    featureCategoryId: string;
    featureDisplayName: string;
    deleted?: boolean;
    isSelected?: boolean;
}

export interface UserSecurityRoles {
    roleId: string;
    roleDesc?: string;
    roleName: string;
    roleCategoryId: string;
    roleDisplayName: string;
    roleCode: string;
    requiresAuthorization?: number;
    hidden?: boolean;
    deleted?: boolean;
    userFeatures?: UserFeatures[];
    isSelected?: boolean;
}

export interface RegistrationHistory {
    id: number,
    action: string,
    comment: string,
    submittedBy: string,
    status: string,
    date: string,
}

export interface UserDetailsEntity {
    id: string;
    userID: string;
    companyId: string;
    employerName: string;
    hvacPin: string;
    firstName: string;
    lastName: string;
    email: string;
    userAddress1: string;
    userAddress2: string;
    userCity: string;
    userCountry: string;
    userPhone: string;
    userPostalCode: string;
    userState: string;
    userStatus: string;
    lastLoginDate: string;
    lastValidatedDate: string;
    isValidationRequired: boolean;
    brandFamily: string;
    contactOnly: boolean;
    userTitle: string;
    companyName: string;
    userCellPhone: string;
    userFax: string;
    userRolesDetails: UserSecurityRoles[];
    userFeaturesDetails: UserFeatures[];
}

export interface ExportUserDetailsEntity {
    CompanyId: string;
    UserName: string;
    EmployerName: string;
    HvacPin: string;
    FirstName: string;
    LastName: string;
    UserEmail: string;
    AddressOne: string;
    AddressTwo: string;
    UserCity: string;
    UserCountry: string;
    UserPhone: string;
    UserPostalCode: string;
    UserState: string;
    Status: string;
    LastLoginDate: string;
    UserCellPhone: string;
    UserFax: string;
    Roles: string;
}

export interface UserAuthorizedRoles {
    roleCategory: string;
    userRoles: UserSecurityRoles[];
}

export interface RolesList {
    roleDisplayName: string;
    roleId: string;
}

export interface UserAuthorizedFeatures {
    featureCategory: string;
    userFeatures: UserFeatures[];
}

export interface UserRegistrationDetails {
    userID: string;
    companyId: string;
    employerName: string;
    firstName: string;
    lastName: string;
    email: string;
    userAddress1: string;
    userAddress2: string;
    userCity: string;
    userCountry: string;
    userPhone: string;
    userPostalCode: string;
    userState: string;
    userStatus: string;
    brandFamily: string;
    userTitle: string;
    userCellPhone: string;
    userFax: string;
    registrationAction: string;
    registrationComment: string;
    registrationHistory?: RegistrationHistory[];
    requestId: string;
    forwardToInternal: boolean,
    hvacPin?: string;
    contactOnly?: string;
    lastLoginDate?: string;
}

export interface UserDetails {
    userID: string;
    companyId: string;
    employerName: string;
    firstName: string;
    lastName: string;
    userTitle: string;

    userAddress1: string;
    userAddress2: string;
    userCity: string;
    userCountry: string;
    userState: string;
    userPostalCode: string;

    userPhone: string;
    userCellPhone: string;
    userFax: string;
    email: string;
    contactOnly: boolean;
}

export interface ApprovalRequestUser {
    OwnerCompanyName: string;
    CompanyName: string;
    Email: string;
    RequestId: number;
    CompanyId: number
    FirstName: string;
    CreatedDate: string;
    LastName: string;
    CompanyType: string;
    UserStatus: string;
    UserName: string;
}

export interface UserRegistrationRequestPayload {
    firstName?: string,
    lastName?: string,
    userName?: string
    companyName?: string,
    companyId?: string,
    createdDate?: string,
    userStatus?: string
}
export interface UserRegistrationRequest {
    firstName: string,
    lastName: string,
    companyName: string,
    companyId: string,
    createdDate: string,
    userStatus: string,
    soldTo: string,
    companyType: string,
    ownerCompanyName: string,
    userName: string,
    email: string,
    requestId: string
}

export interface UserRegistrationRequestsResponse {
    count : string,
    registrations: UserRegistrationRequest[]
}

export interface RegisterUsers {
    registrationUserStep: string;
    usersData : UserRegistrationRequest;
}

export interface UserRegistration {
    registrationUserStep: string;
    usersData : UserRegistrationDetails;
}

export interface UserSoldTos {
    companyId: string;
    companyName: string;
    soldTo: string;
    partsSoldTo?: string;
    assigned: boolean;
}

export interface UserRelationshipsDetails {
    assignedSoldToCount: number;
    availableSoldToCount: number;
    assignedSoldTo: UserSoldTos[];
    availableSoldTo: UserSoldTos[];
}

export interface UpdatedUserRelationshipsDetails {
    userId: string;
    addedList: string[];
    removedList: string[];
}

export interface SelectedSoldTos {
    type: string;
    soldTos: UserSoldTos[]
}

export interface CreateUserDetails {
    userID: string;
    companyId: string;
    firstName: string;
    lastName: string;
    email: string;
    employerName: string;
    userAddress1: string;
    userAddress2: string;
    userCellPhone: string;
    userCity: string;
    userCountry: string;
    userFax: string;
    userPhone: string;
    userPostalCode: string;
    userState: string;
    userStatus?: string | null;
    userTitle: string;
    brandFamily: string | null;
    contactOnly: boolean;
    userRolesDetails: UserSecurityRoles[];
    userFeaturesDetails: UserFeatures[];
}

export enum UserCreateStrings {
    CreateUser = 'createUser',
    RegisterUser = 'registerUser',
    AdminCreate = 'ADMIN_CREATE',
    Active = 'Active',
    SystemDefaults = 'System Defaults',
    ICP = 'ICP'
}
