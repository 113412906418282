import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
    standalone: true
})
export class OrderByPipe implements PipeTransform {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    transform(value?: any[], sortFunction?: any): any[] {
        if (value && Array.isArray(value)) {
            return [...value].sort(sortFunction);
        }

        return [];
    }
}

