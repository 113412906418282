/* eslint-disable max-lines*/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentHeadingData } from 'common/models/content-heading';
import { ConnectedProductUpdate, ConnectedProductUpdateResponse, ProductType, SystemType } from 'private/app/models/connected-product.model';
import { ConnectedPropertyDetails } from 'private/app/models/connected-property.model';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, delay, filter, map, shareReplay, startWith, switchMap, takeLast, takeUntil, tap } from 'rxjs/operators';
import { RecentActivityBannerAlert } from './components/recent-activity-banner/recent-activity-banner.component';
import { PropertySystem } from './components/property-system-panel/property-system-panel.component';
import { ApiResponseCode, MISSING_PRODUCT_DATA, MISSING_REQUIRED_PROPERTY, MISSING_ROUTE_PARAMS_ERROR } from '../../../constants';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { DataSharingService } from 'private/app/services/connected-portal/data-sharing.service';
import { ConnectedSystem } from 'private/app/models/connected-system.model';
import { PageTitleServiceService } from 'private/app/services/connected-portal/page-title-service.service';
import { BaseComponent } from 'common/components/base/base.component';
import { findSystemProductByType } from 'private/app/services/connected-portal/utils';
import { CompanyCode } from 'private/app/models/company.model';
import { ConnectedPortalUserService } from 'private/app/services/connected-portal/connected-portal-user.service';
import { ErrorService } from 'common/content/services/error.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { WallControlCommand } from 'private/app/models/connected-portal-system-diagnostics.model';
import { CustomerPropertyService, CustomersPropertyDetails } from './customer-property.service';
import { AddToast, ToastService } from 'common/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { BrandingState } from 'common/store/branding/branding.state';
import { HomeOwnerServiceService } from 'private/app/services/connected-portal/home-owner-service.service';

const RELEARN_DATA_RELOAD_DELAY = 3000;

@Component({
    selector: 'hvac-connected-portal-customers-property',
    templateUrl: './connected-portal-customers-property.component.html',
    styleUrls: ['./connected-portal-customers-property.component.scss'],
    providers: [CustomerPropertyService]
})
export class ConnectedPortalCustomersPropertyComponent extends BaseComponent implements OnInit, OnDestroy {
    @Select(BrandingState.themeBrand) brand$: Observable<string>;

    public readonly CompanyCode = CompanyCode;
    public readonly SystemType = SystemType;
    public readonly ProductType = ProductType;

    public dealerId$: Observable<string>;
    public recentActivityAlert$: Observable<RecentActivityBannerAlert | null>;
    public companyCode$: Observable<CompanyCode>;
    public breadcrumbs$: Observable<ContentHeadingData>;
    public formattedPropertyDetails$: Observable<CustomersPropertyDetails>;
    public formattedSystems$: Observable<PropertySystem[]>;
    public confirmRelearnSystem$ = new BehaviorSubject(false);
    public isRecentActivityClosed$ = new BehaviorSubject<boolean>(false);
    public isRelearnModalVisible = false;
    public isRelearning = false;
    public relearnSystemId$ = new BehaviorSubject<string | null>(null);
    public isUpdateModalVisible: boolean = false;
    public connectedProductData: ConnectedProductUpdate;
    public isProductUpdateLoaderVisible: boolean;
    public isResetPwdModalVisible = false;
    public selectedSystemId = this.route.snapshot?.queryParamMap.get('systemId');
    public isUserConnectedToNonEcobee$: Observable<boolean>;
    public resetPwdToastOutlet = 'resetPwdToastOutlet';
    public resetPwdLoading = false;

    private loadPropertyData$ = new BehaviorSubject<boolean | string>(true);
    private propertyDetails$: Observable<ConnectedPropertyDetails>;
    private systemsSearchSorted$: Observable<ConnectedSystem[]>;
    private firstSystem$ :Observable<ConnectedSystem>;
    private mqttSerials$: Observable<string[]>;
    private disconnectWallControl$ = new Subject<{ serialNo: string, dealerId: string }>();
    private homeOwnerUserName: string;

    constructor(
        private dealersService: DealersService,
        private productService: ProductService,
        private route: ActivatedRoute,
        private dataSharingService: DataSharingService,
        private pageTitleServiceService: PageTitleServiceService,
        private connectedPortalUserService: ConnectedPortalUserService,
        private errorService: ErrorService,
        private customerPropertyService: CustomerPropertyService,
        private translateService: TranslateService,
        private toastService: ToastService,
        private homeOwnerServiceService: HomeOwnerServiceService
    ) {
        super();
    }

    ngOnInit() {
        if (!this.route.parent) {
            this.errorService.errorStatus$.next(ApiResponseCode.NOT_FOUND);

            return;
        }

        const routeData$ = combineLatest([
            this.route.parent.paramMap,
            this.route.paramMap,
            this.route.queryParamMap
        ]).pipe(
            map(([parentParams, params, queryParams]) => {
                const dealerId = parentParams.get('id');
                const propertyId = params.get('id');
                const searchQuery = queryParams.get('search');

                if (dealerId && propertyId) {
                    return {
                        dealerId,
                        propertyId,
                        searchQuery
                    };
                }

                throw new Error(MISSING_ROUTE_PARAMS_ERROR);
            }),
            shareReplay(),
            catchError(() => {
                this.errorService.errorStatus$.next(ApiResponseCode.NOT_FOUND);

                return EMPTY;
            })
        );

        this.dealerId$ = routeData$.pipe(
            map(({ dealerId }) => dealerId)
        );

        this.propertyDetails$ = combineLatest([this.loadPropertyData$, routeData$, this.brand$]).pipe(
            switchMap(([status, { dealerId, propertyId }, brand]) => this.dealersService.queryPropertyById(propertyId, dealerId, brand).pipe(
                map((res) => res.data),
                tap(({ customer }) => {
                    const customerName = `${customer.firstName} ${customer.lastName}`;
                    this.pageTitleServiceService.setCustomerName(customerName);
                }),
                tap(() => this.resetProductUpdates(status)),
                shareReplay()
            ))
        );

        this.systemsSearchSorted$ = combineLatest([routeData$, this.propertyDetails$]).pipe(
            map(([routeData, propertyDetails]) => {
                const searchQuery = routeData.searchQuery;

                return searchQuery
                    ? this.customerPropertyService.getSystemsSortedBySerialNo(searchQuery, propertyDetails.systems)
                    : propertyDetails.systems;
            })
        );

        this.firstSystem$ = this.systemsSearchSorted$.pipe(
            map((systems) => {
                const firstSystem = systems[0];

                if (firstSystem) {
                    return firstSystem;
                }

                throw new Error(MISSING_PRODUCT_DATA);
            }),
            tap(({ dataSharing, systemType }) => {
                this.dataSharingService.setPermissions(dataSharing, systemType);
            })
        );

        this.breadcrumbs$ = this.dealerId$.pipe(
            switchMap((dealerId) => combineLatest([this.dealersService.queryDealerById(dealerId), this.propertyDetails$]).pipe(
                map(([{ data }, propertyDetails]) => this.customerPropertyService.getBreadcrumbData(
                    dealerId,
                    data.name,
                    propertyDetails.propertyName
                )),
                startWith(this.customerPropertyService.getBreadcrumbData(dealerId))
            ))
        );

        this.recentActivityAlert$ = combineLatest([
            this.isRecentActivityClosed$,
            this.dealerId$,
            this.firstSystem$,
            this.dataSharingService.dataPoints$
        ]).pipe(
            switchMap(([isClosed, dealerId, firstSystem, dataPoints]) => {
                if (isClosed) {
                    return of(null);
                }

                const { systemId, systemType } = firstSystem;

                if (dataPoints.alerts && systemId && systemType !== SystemType.CARRIER_ELT) {
                    return this.customerPropertyService.getRecentAlert$(systemId, systemType, dealerId);
                }

                return of(null);
            })
        );

        this.formattedPropertyDetails$ = combineLatest([this.propertyDetails$, this.systemsSearchSorted$, this.dealerId$]).pipe(
            map(([propertyDetails, systemsSorted, dealerId]) => this.customerPropertyService.formatPropertyData({
                ...propertyDetails,
                systems: systemsSorted
            }, propertyDetails.id, dealerId)),
            shareReplay()
        );

        this.formattedSystems$ = this.formattedPropertyDetails$.pipe(
            map((propertyDetails) => propertyDetails.systems)
        );

        this.companyCode$ = this.connectedPortalUserService.getCompanyCode() as Observable<CompanyCode>;

        this.mqttSerials$ = this.systemsSearchSorted$.pipe(
            map((systems) => this.customerPropertyService.getMqttSystemWallControlSerials(systems))
        );

        // Side effect to start mqtt activation loop for mqtt based systems
        // Controls are removed from activation loop onDestroy via takeLast
        const activateMqttControls$ = combineLatest([this.dealerId$, this.mqttSerials$]).pipe(
            takeUntil(this.ngOnDestroy$),
            tap(([dealerId, mqttSerials]) => {
                if (mqttSerials.length) {
                    this.customerPropertyService.activateMqttControls(mqttSerials, dealerId);
                }
            }),
            takeLast(1),
            tap(([, mqttSerials]) => {
                if (mqttSerials.length) {
                    this.customerPropertyService.removeMqttControls(mqttSerials);
                }
            })
        );

        const relearnSystem$ = combineLatest([
            this.confirmRelearnSystem$,
            this.relearnSystemId$,
            this.systemsSearchSorted$,
            this.dealerId$
        ]).pipe(
            filter(([confirmRelearn, systemId]) => Boolean(confirmRelearn && systemId)),
            switchMap(([confirmRelearn, systemId, systems, dealerId]) => {
                const system = systems.find((item) => item.systemId === systemId);

                if (confirmRelearn && system) {
                    const wallControl = findSystemProductByType(system, ProductType.WallControl);

                    if (wallControl) {
                        return this.startRelearning$(wallControl.serialNo, dealerId, system);
                    }

                    throw new Error(MISSING_REQUIRED_PROPERTY);
                }

                throw new Error(MISSING_REQUIRED_PROPERTY);
            }),
            delay(RELEARN_DATA_RELOAD_DELAY),
            tap(() => {
                // After a successful relearn, we reload the data after a short delay.
                this.loadPropertyData$.next(true);
                this.resetRelearningState();
            }),
            catchError(() => {
                this.resetRelearningState();

                return EMPTY;
            }),
            takeUntil(this.ngOnDestroy$)
        );

        // SUBSCRIPTIONS
        this.disconnectWallControl$.pipe(
            switchMap(({ serialNo, dealerId }) => this.productService.publishCommand(
                serialNo,
                dealerId,
                { command: WallControlCommand.DisconnectCommand }
            ).pipe(
                catchError(() => EMPTY)
            )),
            takeUntil(this.ngOnDestroy$)
        ).subscribe();

        activateMqttControls$.subscribe();
        relearnSystem$.subscribe();

        this.isUserConnectedToNonEcobee$ = combineLatest([this.propertyDetails$, this.companyCode$]).pipe(
            map(([propertyDetails, companyCode]) => {
                this.homeOwnerUserName = propertyDetails.customer.username;
                if (companyCode === CompanyCode.Internal) {
                    return this.customerPropertyService.checkUserConnectedToNonEcobee(propertyDetails.systems);
                }

                return false;
            }),
            takeUntil(this.ngOnDestroy$)
        );
    }

    ngOnDestroy() {
        this.dataSharingService.resetPermissions();
        super.ngOnDestroy();
    }

    public confirmRelearn() {
        this.isRelearning = true;
        this.isRelearnModalVisible = false;
        this.confirmRelearnSystem$.next(true);
    }

    public closeRecentActivity() {
        this.isRecentActivityClosed$.next(true);
    }

    public openRelearnModal(systemId: string) {
        if (!this.isRelearning) {
            this.relearnSystemId$.next(systemId);
            this.isRelearnModalVisible = true;
        }
    }

    public closeRelearnModal() {
        this.relearnSystemId$.next(null);
        this.isRelearnModalVisible = false;
    }

    public openUpdateModal(event: ConnectedProductUpdate) {
        this.connectedProductData = event;
        this.isUpdateModalVisible = true;
    }

    public closeUpdateModal($event: ConnectedProductUpdateResponse) {
        if ($event) {
            if ($event.data && $event.status === 'success') {
                this.loadPropertyData$.next($event.status);
            }
            else {
                this.resetProductUpdates('error');
            }
        }
        else {
            this.resetProductUpdates();
        }
    }

    public showProductUpdateLoader() {
        this.isProductUpdateLoaderVisible = true;
    }

    public resetHomeOwnerPassword() {
        this.resetPwdLoading = true;
        this.homeOwnerServiceService.resetHomeOwnerPassword(this.homeOwnerUserName)
            .subscribe((response) => {
                const success = response.data.recoverPassword.success;

                const toast: AddToast = {
                    content: success ? this.translateService.instant('CONNECTED_PORTAL.PROPERTY_DETAIL.HOME_OWNER_PASSWORD_RESET.TOAST.SUCCESS') : response.data.recoverPassword.message,
                    theme: success ? 'success' : 'error',
                    outletName: this.resetPwdToastOutlet,
                    autoClose: true
                };

                this.toastService.add(toast);
                this.resetPwdLoading = false;
                this.isResetPwdModalVisible = false;
            },
            () => {
                const errorToast: AddToast = {
                    content: this.translateService.instant('CONNECTED_PORTAL.PROPERTY_DETAIL.HOME_OWNER_PASSWORD_RESET.TOAST.ERROR'),
                    theme: 'error',
                    outletName: this.resetPwdToastOutlet,
                    autoClose: true
                };
                this.toastService.add(errorToast);
                this.resetPwdLoading = false;
                this.isResetPwdModalVisible = false;
            });
    }

    private startRelearning$(serialNo: string, dealerId: string, system: ConnectedSystem) {
        const outletName = system.systemId;

        return this.productService.activateEcobeeControlBySerialNo(serialNo, dealerId)
            .pipe(
                switchMap(() => this.productService.publishCommand(
                    serialNo,
                    dealerId,
                    { command: WallControlCommand.StartLearningCommand }
                ).pipe(
                    switchMap(() => this.productService.publishCommand(
                        serialNo,
                        dealerId,
                        { command: WallControlCommand.DisconnectCommand }
                    ).pipe(
                        catchError(() => EMPTY)
                    )),
                    tap(() => {
                        const toast: AddToast = {
                            content: this.translateService.instant('CONNECTED_PORTAL.PROPERTY_DETAIL.RELEARN.TOASTS.SUCCESS'),
                            theme: 'success',
                            outletName,
                            autoClose: true
                        };

                        this.toastService.add(toast);
                    })
                )),
                catchError(() => {
                    this.toastService.removeAll();
                    this.disconnectWallControl$.next({
                        serialNo,
                        dealerId
                    });

                    const errorToast: AddToast = {
                        content: this.translateService.instant('CONNECTED_PORTAL.PROPERTY_DETAIL.RELEARN.TOASTS.ERROR'),
                        theme: 'error',
                        outletName,
                        autoClose: true
                    };

                    this.toastService.add(errorToast);
                    this.resetRelearningState();

                    return EMPTY;
                })
            );
    }

    private resetRelearningState() {
        this.isRelearnModalVisible = false;
        this.isRelearning = false;
        this.relearnSystemId$.next(null);
        this.confirmRelearnSystem$.next(false);
    }

    private resetProductUpdates(status?: string | boolean) {
        if (!this.isUpdateModalVisible) {
            return;
        }

        const outletName = this.connectedProductData?.systemId;

        this.isUpdateModalVisible = false;
        this.isProductUpdateLoaderVisible = false;

        if (status === 'success') {
            const toast: AddToast = {
                content: this.translateService.instant('CONNECTED_PORTAL.UPDATE_PRODUCT_DETAILS.TOAST.UPDATE_SUCCESS'),
                theme: 'success',
                outletName,
                autoClose: true
            };

            this.toastService.add(toast);
        }
        else if (status === 'error') {
            const errorToast: AddToast = {
                content: this.translateService.instant('CONNECTED_PORTAL.UPDATE_PRODUCT_DETAILS.TOAST.UPDATE_ERROR'),
                theme: 'error',
                outletName,
                autoClose: true
            };

            this.toastService.add(errorToast);
        }
    }
}
