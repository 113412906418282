import { Injectable } from '@angular/core';
import { environment } from 'common/environments/environment';
import { ConnectedPortalNavLink } from 'private/app/views/connected-portal/components/connected-portal-navigation/connected-portal-navigation.component';
import { ConnectedPortalUserService } from './connected-portal-user.service';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ManageDealerLocatorService } from '../manage-dealer-locator.service';
import { DealerLocatorEnrollmentList } from 'private/app/models/manage-dealer-locator';
import { FALLBACK_HVPID_FOR_UNASSIGNED_DEALER } from 'private/app/views/connected-portal/constants';

const { dealerSettingFeature, dealerOpportunities, linkedDealersEnabled, factorySystemsOverviewEnabled } = environment.features.connectedPortal;
@Injectable({ providedIn: 'root' })
export class NavigationLinksService {
    public isFADEnabled$: Observable<DealerLocatorEnrollmentList | null> = new Observable<DealerLocatorEnrollmentList | null>();
    private skipEnrollmentCheck$: Observable<boolean> = new Observable<boolean>();

    private dealerNavLinks: ConnectedPortalNavLink[] = [
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.DASHBOARD',
            route: '/connected-portal/dealers/{id}/dashboard'
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.fleetHealth',
            route: '/connected-portal/dealers/{id}/fleet-health',
            isPro: true
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.CUSTOMERS',
            route: '/connected-portal/dealers/{id}/customers'
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.OPPORTUNITIES',
            route: '/connected-portal/dealers/{id}/opportunities',
            comingSoon: !dealerOpportunities
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.FAD',
            route: '/connected-portal/dealers/{id}/fad'
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.SETTINGS',
            route: '/connected-portal/dealers/{id}/settings',
            comingSoon: !dealerSettingFeature
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.LINKED_DEALERS',
            route: '/connected-portal/dealers/{id}/linked-dealers',
            comingSoon: !linkedDealersEnabled
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.RESOURCES',
            route: '/connected-portal/dealers/{id}/resources'
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.FAQ',
            route: '/connected-portal/dealers/{id}/faq'
        }
    ];

    private distributorLinks: ConnectedPortalNavLink[] = [
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.DASHBOARD',
            route: '/connected-portal/distributors/{id}/dashboard'
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.DEALERS',
            route: '/connected-portal/distributors/{id}/dealers'
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.ADVANCED_SEARCH',
            route: '/connected-portal/distributors/{id}/advanced-search'
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.RESOURCES',
            route: '/connected-portal/distributors/{id}/resources'
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.FAQ',
            route: '/connected-portal/distributors/{id}/faq'
        }
    ];

    private factoryLinks: ConnectedPortalNavLink[] = [
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.DASHBOARD',
            route: '/connected-portal/dashboard'
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.DISTRIBUTORS',
            route: '/connected-portal/distributors'
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.ADVANCED_SEARCH',
            route: '/connected-portal/advanced-search'
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.RESOURCES',
            route: '/connected-portal/resources'
        },
        {
            label: 'CONNECTED_PORTAL.NAV_LABELS.FAQ',
            route: '/connected-portal/faq'
        }
    ];

    constructor(
        private cpUserService: ConnectedPortalUserService,
        private dealerLocaterService:ManageDealerLocatorService
    ) {}

    getDealerNavLinks$(dealerId: string): Observable<ConnectedPortalNavLink[]> {
        const navLinks = this.getTransformedNavLinks(this.dealerNavLinks, dealerId)
            .filter((link) => link.label !== 'CONNECTED_PORTAL.NAV_LABELS.LINKED_DEALERS');

        if (dealerId === FALLBACK_HVPID_FOR_UNASSIGNED_DEALER) {
            this.skipEnrollmentCheck$ = of(true);
            this.isFADEnabled$ = of(null);
        }
        else {
            this.skipEnrollmentCheck$ = of(false);
            this.isFADEnabled$ = this.dealerLocaterService.getEnrollments(dealerId).pipe(
                catchError(() => of({
                    assignedZipCodes: [],
                    availableZipCodes: [],
                    companyId: '',
                    dealerLocatorEnrollments: [],
                    isFADEnabled: false,
                    locatorRatings: []
                }))
            );
        }

        const alllinks$ = of(navLinks).pipe(
            switchMap(() => this.cpUserService.isPrimaryDealer$.pipe(
                map((isPrimaryDealer) => this.getTransformedNavLinks(this.dealerNavLinks, dealerId).filter((link) => {
                    if (link.label === 'CONNECTED_PORTAL.NAV_LABELS.LINKED_DEALERS') {
                        return isPrimaryDealer;
                    }

                    return true;
                }))
            )),
            startWith(navLinks)
        );

        return combineLatest([this.isFADEnabled$, alllinks$, this.skipEnrollmentCheck$]).pipe(
            map(([enrollmentData, array, isEnrollmentCheckSkipped]) => {
                if (isEnrollmentCheckSkipped || (enrollmentData && !enrollmentData.isFADEnabled)) {
                    const tempArray = array.filter((item) => item.label !== 'CONNECTED_PORTAL.NAV_LABELS.FAD');

                    return tempArray;
                }

                return array;
            })
        );
    }

    getDistributorNavLinks(distributorId: string) {
        return this.getTransformedNavLinks(this.distributorLinks, distributorId);
    }

    getFactoryNavLinks() {
        if (factorySystemsOverviewEnabled) {
            const clonedLinks = [...this.factoryLinks];

            clonedLinks.splice(1, 0, {
                label: 'CONNECTED_PORTAL.NAV_LABELS.SYSTEMS_OVERVIEW',
                route: '/connected-portal/systems-overview'
            });

            return clonedLinks;
        }

        return this.getTransformedNavLinks(this.factoryLinks);
    }

    getTransformedNavLinks(linkList: ConnectedPortalNavLink[], id?: string) {
        return linkList.map((link) => ({
            ...link,
            route: id ? link.route.replace('{id}', id) : link.route
        }));
    }
}

