/* eslint-disable max-lines */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { gql } from 'apollo-angular';
import { environment } from 'common/environments/environment';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { CatalogService } from 'common/services/catalog.service';
import {
    ConnectedDealerResponse,
    ConnectedDealersResponse,
    ConnectedDealerStats,
    ConnectedDealerStatsResponse,
    DealerInput,
    RequestEcobeePinByDealerIdResponse
} from 'private/app/models/connected-dealer.model';
import { ConnectedPropertiesResponse, ConnectedPropertyDetailsResponse } from 'private/app/models/connected-property.model';
import { ApiNotificationPreferencesResponse, ConnectedSystem, ConnectedSystemsResponse, NotificationPreferencesResponse } from 'private/app/models/connected-system.model';
import { FilterQueryParams, SearchQueryParams } from 'private/app/models/default-query-params';
import { DefaultUpdateResponse } from 'private/app/models/default-update-response.model';
import { QueuedCustomerResponse } from 'private/app/models/queued-customer.model';
import { DEFAULT_SYSTEM_TYPE, SKIP_BRAND_PARAMS } from 'private/app/views/connected-portal/constants';
import { from, Observable } from 'rxjs';
import { catchError, combineAll, map, mergeMap, switchMap } from 'rxjs/operators';
import { ConnectedPortalBaseService, MediaImageSize } from './connected-portal-base.service';
import { ProductImagesService } from './product-images.service';
import { nonEmptyStringOrNull, stringToBoolOrNull } from './utils';
import { ConnectedProduct } from 'private/app/models/connected-product.model';
import { ConnectedPortalGraphQLError } from 'private/app/models/connected-portal-error.model';
import { DefaultFADResponse, FADData } from 'private/app/models/fad-model';


const { ecobeeSystemsFeature, ecobeeDiagnosticsTestingFeature, linkedDealersEnabled, energyEventsEnabled } = environment.features.connectedPortal;
const queryFeatures = {
    isPlcEquipped: ecobeeDiagnosticsTestingFeature ? 'isPlcEquipped' : '',
    systemType: ecobeeSystemsFeature ? 'systemType' : '',
    ecobeePin: ecobeeSystemsFeature ? 'ecobeePin' : '',
    isPrimaryDealer: linkedDealersEnabled ? 'isPrimaryDealer' : '',
    vppActivity: energyEventsEnabled ? 'vppActivity' : '',
    vppEligibility: energyEventsEnabled ? 'vppEligibility' : ''
};

export const QUERY_DEALERS = gql`
    query QueryDealers {
        queryDealers {
            totalCount,
            totalPages,
            data {
                id
            }
        }
    }
`;

export const QUERY_DEALER_BY_ID = gql`
    query QueryDealerById($id: String!) {
        queryDealerById(id: $id) {
            data {
                id
                name
                customerCount
                connectedControlCount
                queuedControlCount
                phone
                email
                address {
                    street
                    city
                    state
                    country
                    zipCode
                }
                notificationSettings {
                    primary {
                        email
                        criticalAlertsEnabled
                        maintenanceEnabled
                        controlOfflineEnabled
                    }
                    secondary {
                        email
                        criticalAlertsEnabled
                        maintenanceEnabled
                        controlOfflineEnabled
                    }
                }
                ${queryFeatures.ecobeePin},
                ${queryFeatures.isPrimaryDealer},
                inVppTerritory
            }
        }
    }
`;

export const UPDATE_DEALER_BY_ID = gql`
    mutation updateDealerById($id: String!, $data: DealerInput! ){
        updateDealerById(id:$id, data:$data) {
            message
            code
        }
    }
`;

export const QUERY_DEALERS_BY_DISTRIBUTOR_ID = gql`
    query QueryDealersByDistributorId($id: String!, $params: SearchQueryParams) {
        queryDealersByDistributorId(id: $id, params: $params) {
            totalCount,
            totalPages,
            data {
                id,
                name,
                connectedControlCount,
                queuedControlCount,
                customerCount
            }
        }
    }
`;

export const QUERY_LINKED_DEALERS_BY_DEALER_ID = gql`
    query queryLinkedDealersByDealerId($id: String!,$params: SearchQueryParams) {
        queryLinkedDealersByDealerId(id: $id, params: $params) {
            totalCount,
            totalPages,
            data {
                id,
                dealerHVPID,
                name,
                connectedControlCount,
                queuedControlCount,
                customerCount
            }
        }
    }
`;

export const QUERY_DEALER_STATS = gql`
    query QueryDealerStats($id: String!) {
        queryDealerStats(id: $id) {
            data {
                connectedControlCount,
                connectedControlPercent,
                connectedCustomerCount,
                queuedControlCount,
                opportunityCount
            }
        }
    }
`;


export const QUERY_PROPERTIES_BY_DEALER_ID = gql`
    query QueryDealerPropertiesById($id: String!, $params: SearchQueryParams) {
        queryPropertiesByDealerId(id: $id, params: $params) {
            totalCount,
            totalPages,
            data {
                id,
                customer {
                    firstName,
                    lastName,
                    email,
                    phone1
                },
                address {
                    address1,
                    address2,
                    city,
                    state,
                    zipCode
                }
                systems {
                    systemId,
                    systemName,
                    wallControlName,
                    dataSharing {
                        viewStatus
                        viewConfig
                        editConfig
                    }
                    modelEcosystem,
                    apiProtocol,
                    products {
                        name
                        modelNo
                        serialNo
                        firmware
                        type
                    }
                }
            }
        }
    }
`;

export const QUERY_QUEUED_CUSTOMERS_BY_DEALER_ID = gql`
    query QueryQueuedCustomersById($id: String!, $params: SearchQueryParams) {
        queryQueuedCustomersByDealerId(id: $id, params: $params) {
            totalCount,
            totalPages,
            data {
                id,
                controlType,
                sn,
                esn,
                status
            }
        }
    }
`;

export const QUERY_QUEUED_CUSTOMERS_BY_DEALER_ID_FOR_EXPORT = gql`
    query QueryQueuedCustomersById($id: String!, $params: SearchQueryParams) {
        queryQueuedCustomersByDealerId(id: $id, params: $params) {
            totalCount
            totalPages
            filepath
        }
    }
`;

export const QUERY_PROPERTY_BY_ID = gql`
    query QueryPropertyById($id: String!, $dealerId: String!) {
        queryPropertyById(id: $id, dealerId: $dealerId) {
            data {
                id,
                address {
                    address1,
                    address2,
                    city,
                    state,
                    country,
                    zipCode
                },
                propertyName,
                dealerHVPID,
                dealerName,
                customer {
                    email,
                    firstName,
                    lastName,
                    phone1,
                    username
                },
                systems {
                    systemId,
                    systemName,
                    wallControlName,
                    dataSharing {
                        viewStatus,
                        viewConfig,
                        editConfig
                    },
                    modelEcosystem,
                    apiProtocol,
                    products {
                        name,
                        modelNo,
                        serialNo,
                        firmware,
                        type,
                        esn,
                        refrigerantType,
                        ${queryFeatures.systemType}
                        ${queryFeatures.isPlcEquipped}
                    }
                }
            }
        }
    }
`;

export const QUERY_SYSTEMS_BY_PROPERTY_ID = gql`
    query QuerySystemsByPropertyId($id: String!, $dealerId: String, $params: FilterQueryParams) {
        querySystemsByPropertyId(id: $id, dealerId: $dealerId, params: $params) {
            data {
                systemId,
                systemName,
                wallControlName,
                dataSharing {
                    viewStatus,
                    viewConfig,
                    editConfig
                },
                modelEcosystem,
                apiProtocol,
                products {
                    name,
                    modelNo,
                    serialNo,
                    firmware,
                    type,
                    isPlcEquipped,
                    ${queryFeatures.systemType},
                    esn,
                    refrigerantType
                },
                ${queryFeatures.vppActivity},
                ${queryFeatures.vppEligibility},
            }
        }
    }
`;


export const QUERY_NOTIFICATION_PREFERENCES_BY_SYSTEM_ID = gql`
    query QueryNotificationPreferencesBySystemId($id: ID!, $dealerId: String) {
        queryNotificationPreferencesBySystemId(id: $id, dealerId: $dealerId) {
            data {
                dealerCritical
                dealerMaintenance
                dealerOffline
                maxNotifications
                primaryConfirmations
                primaryCritical
                primaryEmail
                primaryMaintenance
                primaryOffline
                primaryUtilityEvents
                secondaryConfirmations
                secondaryCritical
                secondaryEmail
                secondaryMaintenance
                secondaryOffline
                secondaryUtilityEvents
                unresponsiveTimeLimitInHours
            }
        }
    }
`;

export const REQUEST_ECOBEE_PIN_BY_DEALER_ID = gql`
    mutation RequestEcobeePinByDealerId($id: String!){
        requestEcobeePinByDealerId(id:$id) {
            data {
                ecobeePin
            }
        }
    }
`;

export const QUERY_FAD_PROGRESSION_BY_DEALERID = gql`
query QueryFadProgressionByDealerID($dealerId: String!, $year: Float! ) {
    queryFadProgressionByDealerID(dealerId: $dealerId, year: $year) {
      data {
        lastUpdatedTime
        Q1 {
          countOpportunityReportDownloads
          countConnectedPortalLogins
        }
        Q2 {
          countOpportunityReportDownloads
          countConnectedPortalLogins
        }
        Q3 {
          countOpportunityReportDownloads
          countConnectedPortalLogins
        }
        Q4 {
          countOpportunityReportDownloads
          countConnectedPortalLogins
        }
        Annual {
          connectedControlPercent
          inteliSenseControlPinLinkedPercent
          countRuntimeReportsDownloaded
        }
      }
    }
  }
`;
@Injectable({ providedIn: 'root' })
export class DealersService extends ConnectedPortalBaseService {
    constructor(
        private catalogService: CatalogService,
        private productImagesService: ProductImagesService,
        injector: Injector
    ) {
        super(injector);
    }

    public queryDealers(): Observable<ConnectedDealersResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryDealers').query<{ queryDealers: ConnectedDealersResponse }>({
                query: QUERY_DEALERS,
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryDealers } = value.data;

                    return queryDealers;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryDealerById(dealerId: string): Observable<ConnectedDealerResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryDealerById').query<{ queryDealerById: ConnectedDealerResponse }>({
                query: QUERY_DEALER_BY_ID,
                variables: { id: dealerId },
                context: { headers: options.headers },
                errorPolicy: 'all'
            }).pipe(
                map((value) => {
                    if (value.errors && value.errors.length > 0) {
                        const formattedError = this.formatApolloError(value.errors[0]);

                        throw new ConnectedPortalGraphQLError(formattedError);
                    }
                    const { queryDealerById } = value.data;

                    if (linkedDealersEnabled) {
                        const { isPrimaryDealer } = queryDealerById.data;

                        return {
                            data: {
                                ...queryDealerById.data,
                                isPrimaryDealer: isPrimaryDealer as unknown === 'true'
                            }
                        };
                    }

                    return queryDealerById;
                })
            )),
            catchError((err: ConnectedPortalGraphQLError) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public updateDealerById(dealerId: string, formattedData: DealerInput) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('updateDealerById').mutate<{ updateDealerById: DefaultUpdateResponse }>({
                mutation: UPDATE_DEALER_BY_ID,
                variables: {
                    id: dealerId,
                    data: formattedData
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => value.data?.updateDealerById)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryDealersByDistributorId(distributorId: string, queryParams?: SearchQueryParams): Observable<ConnectedDealersResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryDealersByDistributorId').query<{ queryDealersByDistributorId: ConnectedDealersResponse }>({
                query: QUERY_DEALERS_BY_DISTRIBUTOR_ID,
                variables: {
                    id: distributorId,
                    params: queryParams
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryDealersByDistributorId } = value.data;

                    return queryDealersByDistributorId;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryLinkedDealersByDealerId(dealer: string, queryParams?: SearchQueryParams): Observable<ConnectedDealersResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryLinkedDealersByDealerId').query<{ queryLinkedDealersByDealerId: ConnectedDealersResponse }>({
                query: QUERY_LINKED_DEALERS_BY_DEALER_ID,
                variables: {
                    id: dealer,
                    params: queryParams
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryLinkedDealersByDealerId } = value.data;

                    return queryLinkedDealersByDealerId;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryDealerStats(dealerId: string): Observable<ConnectedDealerStatsResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryDealerStats').query<{ queryDealerStats: ConnectedDealerStatsResponse }>({
                query: QUERY_DEALER_STATS,
                variables: { id: dealerId },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = value.data.queryDealerStats;

                    const orderRef: ConnectedDealerStats = {
                        opportunityCount: 0,
                        queuedControlCount: 0,
                        connectedCustomerCount: 0,
                        connectedControlCount: 0,
                        connectedControlPercent: 0
                    };

                    const orderedData = Object.assign(orderRef, data);

                    return { data: orderedData };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryPropertiesByDealerId(dealerId: string, queryParams?: SearchQueryParams): Observable<ConnectedPropertiesResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryPropertiesByDealerId').query<{ queryPropertiesByDealerId: ConnectedPropertiesResponse }>({
                query: QUERY_PROPERTIES_BY_DEALER_ID,
                variables: {
                    id: dealerId,
                    params: queryParams
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryPropertiesByDealerId } = value.data;

                    return queryPropertiesByDealerId;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryQueuedCustomersByDealerId(dealerId: string, queryParams?: SearchQueryParams): Observable<QueuedCustomerResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryQueuedCustomersByDealerId').query<{ queryQueuedCustomersByDealerId: QueuedCustomerResponse }>({
                query: QUERY_QUEUED_CUSTOMERS_BY_DEALER_ID,
                variables: {
                    id: dealerId,
                    params: queryParams
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryQueuedCustomersByDealerId } = value.data;

                    return queryQueuedCustomersByDealerId;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryQueuedCustomersByDealerIdExportOnly(dealerId: string, queryParams?: SearchQueryParams): Observable<QueuedCustomerResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryQueuedCustomersByDealerId').query<{ queryQueuedCustomersByDealerId: QueuedCustomerResponse }>({
                query: QUERY_QUEUED_CUSTOMERS_BY_DEALER_ID_FOR_EXPORT,
                variables: {
                    id: dealerId,
                    params: queryParams
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryQueuedCustomersByDealerId } = value.data;

                    return queryQueuedCustomersByDealerId;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryPropertyById(propertyId: string, dealerId: string, brand: string): Observable<ConnectedPropertyDetailsResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryPropertyById').query<{ queryPropertyById: ConnectedPropertyDetailsResponse }>({
                query: QUERY_PROPERTY_BY_ID,
                variables: {
                    id: propertyId,
                    dealerId
                },
                context: { headers: options.headers }
            }).pipe(
                map((val) => {
                    const { queryPropertyById } = val.data;
                    const { systems, ...otherProps } = queryPropertyById.data;
                    const updatedSystems: ConnectedSystem[] = systems.map((system) => ({
                        ...system,
                        systemType: system.products[0]?.systemType || DEFAULT_SYSTEM_TYPE
                    }));

                    return ({
                        data: {
                            queryPropertyById: {
                                data: {
                                    ...otherProps,
                                    systems: updatedSystems
                                }
                            }
                        }
                    });
                }),
                mergeMap((value): Observable<ConnectedPropertyDetailsResponse> => {
                    const { queryPropertyById } = value.data;

                    return this.mapProductDataFromSystems(queryPropertyById.data.systems, brand)
                        .pipe(
                            map((systems) => ({
                                data: {
                                    ...queryPropertyById.data,
                                    systems
                                }
                            }))
                        );
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public querySystemsByPropertyId(propertyId: string, dealerId :string, queryParams?: FilterQueryParams): Observable<ConnectedSystemsResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('querySystemsByPropertyId').query<{ querySystemsByPropertyId: ConnectedSystemsResponse }>({
                query: QUERY_SYSTEMS_BY_PROPERTY_ID,
                variables: {
                    id: propertyId,
                    dealerId,
                    params: queryParams
                },
                context: { headers: options.headers }
            }).pipe(
                switchMap((res): Observable<ConnectedSystemsResponse> => {
                    const { querySystemsByPropertyId } = res.data;

                    return this.mapProductDataFromSystems(querySystemsByPropertyId.data).pipe(
                        map((systemsData) => ({ data: systemsData }))
                    );
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryNotificationPreferencesBySystemId(systemId: string, dealerId: string): Observable<NotificationPreferencesResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryNotificationPreferencesBySystemId').query<{ queryNotificationPreferencesBySystemId: ApiNotificationPreferencesResponse }>({
                query: QUERY_NOTIFICATION_PREFERENCES_BY_SYSTEM_ID,
                variables: {
                    id: systemId,
                    dealerId
                },
                context: { headers: options.headers }
            }).pipe(
                map((value): NotificationPreferencesResponse => {
                    const { data } = value.data?.queryNotificationPreferencesBySystemId;

                    const formattedData: NotificationPreferencesResponse = {
                        data: {
                            maxStoredNotification: data.maxNotifications,
                            unresponsiveTimeLimitHours: data.unresponsiveTimeLimitInHours,
                            customer: {
                                primary: nonEmptyStringOrNull(data.primaryEmail)
                                    ? {
                                        email: data.primaryEmail,
                                        notifications: {
                                            maintenanceEnabled: stringToBoolOrNull(data.primaryMaintenance),
                                            offlineEnabled: stringToBoolOrNull(data.primaryOffline),
                                            criticalEnabled: stringToBoolOrNull(data.primaryCritical),
                                            utilityEventsEnabled: stringToBoolOrNull(data.primaryUtilityEvents),
                                            confirmationsEnabled: stringToBoolOrNull(data.primaryConfirmations)
                                        }
                                    }
                                    : null,
                                secondary: nonEmptyStringOrNull(data.secondaryEmail)
                                    ? {
                                        email: data.secondaryEmail,
                                        notifications: {
                                            maintenanceEnabled: stringToBoolOrNull(data.secondaryMaintenance),
                                            offlineEnabled: stringToBoolOrNull(data.secondaryOffline),
                                            criticalEnabled: stringToBoolOrNull(data.secondaryCritical),
                                            utilityEventsEnabled: stringToBoolOrNull(data.secondaryUtilityEvents),
                                            confirmationsEnabled: stringToBoolOrNull(data.secondaryConfirmations)
                                        }
                                    }
                                    : null
                            },
                            dealerNotifications: {
                                maintenanceEnabled: stringToBoolOrNull(data.dealerMaintenance),
                                offlineEnabled: stringToBoolOrNull(data.dealerOffline),
                                criticalEnabled: stringToBoolOrNull(data.dealerCritical)
                            }
                        }
                    };

                    return formattedData;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public requestEcobeePinByDealerId(dealerId: string): Observable<RequestEcobeePinByDealerIdResponse | undefined> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('requestEcobeePinByDealerId').mutate<{ requestEcobeePinByDealerId: RequestEcobeePinByDealerIdResponse }>({
                mutation: REQUEST_ECOBEE_PIN_BY_DEALER_ID,
                variables: { id: dealerId },
                context: { headers: options.headers }
            }).pipe(
                map((value) => value.data?.requestEcobeePinByDealerId)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryFadProgressionByDealerID(id: string, year: number): Observable<FADData> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryFadProgressionByDealerID').query<{ queryFadProgressionByDealerID: DefaultFADResponse }>({
                query: QUERY_FAD_PROGRESSION_BY_DEALERID,
                variables: {
                    dealerId: id,
                    year: year
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = value.data.queryFadProgressionByDealerID;

                    return data;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);
                throw err;
            })
        );
    }

    private mapProductDataFromSystems(systems: ConnectedSystem[], brand?: string): Observable<ConnectedSystem[]> {
        return from(systems).pipe(
            map((system) => ({
                ...system,
                systemType: system.products[0]?.systemType || DEFAULT_SYSTEM_TYPE
            })),
            map((system) => from(system.products).pipe(
                map((product): Observable<ConnectedProduct> => {
                    const hasModelNo = Boolean(nonEmptyStringOrNull(product.modelNo));

                    return hasModelNo
                        ? this.catalogService
                            .fetchProductAttributes(
                                this.productImagesService.findModel(product.modelNo),
                                SKIP_BRAND_PARAMS
                            )
                            .pipe(
                                switchMap((productAttributes) => this.getProductCatalogData(product, productAttributes, MediaImageSize.Medium, brand)),
                                catchError((_err) => (this.getProductCatalogData(product, null, MediaImageSize.Medium, brand)))
                            )
                        : this.getProductCatalogData(product, null, MediaImageSize.Medium, brand);
                }),
                combineAll(),
                map((products) => ({
                    ...system,
                    products
                }))
            )),
            combineAll()
        );
    }
}
