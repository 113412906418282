<div [ngClass]="'hvac-editable-user-info'">
<div class="hvac-row">
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label hvac-required">{{ 'ACCOUNT_ADMIN.FIRST_NAME' | translate}}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                    [error]="userAdminCreateService.userCreateForm.controls.firstName.dirty && !userAdminCreateService.userCreateForm.controls.firstName.valid"
                            [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.FIRST_NAME' | translate"
                            [inputControl]="userAdminCreateService.userCreateForm.controls.firstName"
                            [hideLabel]="true" type="text" shadow="none" [max]="40">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label hvac-required">{{ 'ACCOUNT_ADMIN.LAST_NAME' | translate}}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                            [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.LAST_NAME' | translate"
                            [hideLabel]="true" [inputControl]="userAdminCreateService.userCreateForm.controls.lastName"
                            [error]="userAdminCreateService.userCreateForm.controls.lastName.dirty && !userAdminCreateService.userCreateForm.controls.lastName.valid"
                            type="text" shadow="none" [max]="40">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.TITLE' | translate}}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminCreateService.userCreateForm.controls.userTitle.dirty && !userAdminCreateService.userCreateForm.controls.userTitle.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.USER_TITLE' | translate" [hideLabel]="true"
                        [inputControl]="userAdminCreateService.userCreateForm.controls.userTitle" type="text" shadow="none" [max]="40">
                    </utc-input>
                </li>
            </ul>
        </div>
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.EMPLOYER_NAME' | translate}}
                    </div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.EMPLOYER_NAME' | translate"
                        [error]="userAdminCreateService.userCreateForm.controls.employerName.dirty && !userAdminCreateService.userCreateForm.controls.employerName.valid"
                        [hideLabel]="true"
                        [disabledInput]="true"
                        [inputControl]="userAdminCreateService.userCreateForm.controls.employerName" type="text" shadow="none">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.EMPLOYER_ID' | translate}}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminCreateService.userCreateForm.controls.companyId.dirty && !userAdminCreateService.userCreateForm.controls.companyId.valid"
                        [disabledInput]="true" [hideLabel]="true"
                        [inputControl]="userAdminCreateService.userCreateForm.controls.companyId" type="text" shadow="none">
                    </utc-input>
                </li>
            </ul>
        </div>
    </div>
    <div class="hvac-divider"></div>

    <div class="hvac-row">
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label hvac-required">{{
                        'ACCOUNT_ADMIN.ADDRESS1' | translate}}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminCreateService.userCreateForm.controls.userAddress1.dirty && !userAdminCreateService.userCreateForm.controls.userAddress1.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.USER_ADDRESS1' | translate" [hideLabel]="true"
                        [inputControl]="userAdminCreateService.userCreateForm.controls.userAddress1" type="text" shadow="none" [max]="60">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.ADDRESS2' | translate}}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminCreateService.userCreateForm.controls.userAddress2.dirty && !userAdminCreateService.userCreateForm.controls.userAddress2.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.USER_ADDRESS2' | translate" [hideLabel]="true"
                        [inputControl]="userAdminCreateService.userCreateForm.controls.userAddress2" type="text" shadow="none" [max]="60">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label hvac-required">{{
                        'ACCOUNT_ADMIN.CITY' | translate}}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminCreateService.userCreateForm.controls.userCity.dirty && !userAdminCreateService.userCreateForm.controls.userCity.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.CITY' | translate" [hideLabel]="true"
                        [inputControl]="userAdminCreateService.userCreateForm.controls.userCity" type="text" shadow="none" [max]="40">
                    </utc-input>
                </li>
            </ul>
        </div>
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label hvac-required">{{ 'ACCOUNT_ADMIN.COUNTRY' | translate}}</div>
                      <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.COUNTRY' | translate }}"
                            [error]="userAdminCreateService.userCreateForm.controls.userCountry.dirty && !userAdminCreateService.userCreateForm.controls.userCountry.valid"
                            [shadow]="'none'"
                            [group]="userAdminCreateService.userCreateForm" control="userCountry" [options]="(countryOptionData$ | async) || []">
                        </utc-select>
                    
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label hvac-required">{{ 'ACCOUNT_ADMIN.CREATE_USER_PAGE.STATE_PROVINCE' | translate}}</div>
                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.STATE' | translate }}"
                            [error]="userAdminCreateService.userCreateForm.controls.userState.dirty && !userAdminCreateService.userCreateForm.controls.userState.valid"
                            [shadow]="'none'"
                            [group]="userAdminCreateService.userCreateForm" control="userState" [options]="(stateOptionData$ | async) || []">
                        </utc-select>
                    
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label hvac-required">{{
                        'ACCOUNT_ADMIN.ZIP_POSTAL_CODE' | translate}}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminCreateService.userCreateForm.controls.userPostalCode.dirty && !userAdminCreateService.userCreateForm.controls.userPostalCode.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.ZIP_CODE' | translate" [hideLabel]="true"
                        [inputControl]="userAdminCreateService.userCreateForm.controls.userPostalCode" type="text" shadow="none" [max]="10">
                    </utc-input>
                </li>
            </ul>
        </div>
    </div>
    <div class="hvac-divider"></div>

    <div class="hvac-row">
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.OFFICE_PHONE' | translate}}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                    [error]="userAdminCreateService.userCreateForm.controls.userPhone.dirty && !userAdminCreateService.userCreateForm.controls.userPhone.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.OFFICE_PHONE' | translate" [hideLabel]="true"
                        [inputControl]="userAdminCreateService.userCreateForm.controls.userPhone" 
                        type="text" shadow="none" [max]="20">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.MOBILE_NUMBER' | translate}}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminCreateService.userCreateForm.controls.userCellPhone.dirty && !userAdminCreateService.userCreateForm.controls.userCellPhone.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.MOBILE_NUMBER' | translate" [hideLabel]="true"
                        [inputControl]="userAdminCreateService.userCreateForm.controls.userCellPhone" type="text" shadow="none" [max]="20">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.FAX_NUMBER' | translate}}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminCreateService.userCreateForm.controls.userFax.dirty && !userAdminCreateService.userCreateForm.controls.userFax.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.FAX_NUMBER' | translate" [hideLabel]="true"
                        [inputControl]="userAdminCreateService.userCreateForm.controls.userFax" type="text" shadow="none" [max]="20">
                    </utc-input>
                </li>
            </ul>
        </div>
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label hvac-required">{{
                        'ACCOUNT_ADMIN.EMAIL' | translate}}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminCreateService.userCreateForm.controls.email.dirty && !userAdminCreateService.userCreateForm.controls.email.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.EMAIL' | translate" [hideLabel]="true"
                        [inputControl]="userAdminCreateService.userCreateForm.controls.email" type="text" shadow="none" [max]="100">
                    </utc-input>
                </li>
                <li *ngIf="brandFamily === brandFamilyValue" class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.CONTACT_ONLY' | translate}}</div>
                    <utc-checkbox class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode" 
                        [inputControl]="userAdminCreateService.userCreateForm.controls.contactOnly"
                        inputId="'ACCOUNT_ADMIN.CONTACT_ONLY' | translate" 
                        inputName="'ACCOUNT_ADMIN.CONTACT_ONLY' | translate">
                    </utc-checkbox>
                </li>
            </ul>
        </div>
    </div>
    <div class="hvac-divider"></div>
    
    
<div class="hvac-row hvac-account-admin-detail-footer hvac-flex-end">   
    <button type="button" [hvacButton]="'default'" (click)="onClickCreateUserChangePage(false)">
        <span>{{ "FOOTER.BACK_TO_PREVIOUS_STEP" | translate }}</span>
    </button>
    <button type="button" class="hvac-edit-button" hvacButton="primary" (click)="onClickCreateUserChangePage(true)" [disabled]="!userAdminCreateService.userCreateForm.valid">
        {{ "FOOTER.CONTINUE" | translate}}
    </button>
</div>
       

    