import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { ContentHeadingData } from 'common/models/content-heading';
import { Observable } from 'rxjs';
import {
    ConnectedPortalStat,
    ConnectedPortalStatsComponent
} from '../../components/connected-portal-stats/connected-portal-stats.component';
import { map, takeUntil } from 'rxjs/operators';
import { FactoryService } from 'private/app/services/connected-portal/factory.service';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { ConnectedPortalModule } from 'private/app/views/connected-portal/connected-portal.module';
import { ContentModule } from 'common/content/content.module';
import { SearchBarComponent } from 'private/app/views/connected-portal/components/search-bar/search-bar.component';
import { FadReportComponent } from 'private/app/views/connected-portal/factory/factory-dashboard/components/fad-report/fad-report.component';
import { FactoryConnectedControlReportComponent } from 'private/app/views/connected-portal/factory/factory-dashboard/components/factory-connected-control-report/factory-connected-control-report.component';
import { CustomerLoginsReportComponent } from 'private/app/views/connected-portal/factory/factory-dashboard/components/customer-logins-report/customer-logins-report.component';
import { ConnectedPortalLayoutContainerComponent } from 'private/app/views/connected-portal/components/connected-portal-layout-container/connected-portal-layout-container.component';

@Component({
    selector: 'hvac-factory-dashboard',
    templateUrl: './factory-dashboard.component.html',
    styleUrls: ['./factory-dashboard.component.scss'],
    imports: [
        ConnectedPortalModule,
        ContentModule,
        ConnectedPortalStatsComponent,
        AsyncPipe,
        SearchBarComponent,
        TranslateModule,
        FadReportComponent,
        FactoryConnectedControlReportComponent,
        CustomerLoginsReportComponent,
        ConnectedPortalLayoutContainerComponent
    ],
    standalone: true
})
export class FactoryDashboardComponent extends BaseComponent implements OnInit {
    public isLoadingList = { stats: false };
    public queryControl = new UntypedFormControl('', Validators.required);
    public stats$?: Observable<ConnectedPortalStat[]>;
    public breadCrumbData: ContentHeadingData = {
        Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DASHBOARD') },
        breadCrumbs: [
            {
                title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                url: '/connected-portal/dashboard'
            }
        ]
    };

    constructor(
        private factoryService: FactoryService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private decimalPipe: DecimalPipe
    ) {
        super();
    }

    ngOnInit(): void {
        this.isLoadingList.stats = true;
        this.stats$ = this.factoryService
            .queryFactoryStats()
            .pipe(
                takeUntil(this.ngOnDestroy$),
                map((res) => {
                    const { data } = res;

                    this.isLoadingList.stats = false;

                    return Object.entries(data).map(([key, value]) => ({
                        label: `CONNECTED_PORTAL.STATS.PROPS.${key}`,
                        value: this.decimalPipe.transform(value, '1.0-0') || ''
                    }), []);
                })
            );
    }

    handleSearchSubmit(query: string | null) {
        const params = { search: query };

        this.router.navigate(['../distributors'], {
            relativeTo: this.route,
            queryParams: params
        });
    }
}
