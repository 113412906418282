/* eslint-disable max-lines */
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IdToken } from 'common/models';
import { OktaService } from 'common/services/okta/okta.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Option } from '../../../../common/components/select/select.component';
import { AuthorizedBrand, AccountAdminFormResultMap, Company, CompanyContact, Contact, CompanyServices } from '../../models/account-admin-search';
import { AccountAdminService } from '../../services/account-admin/account-admin.service';
import { AccountAdminCommonComponentService } from 'private/app/services/account-admin/account-admin-common-constants.service';
import { IdmUserRoles } from '../../enums/idm-user-roles';
import { CompanyQueryService } from '../../services/account-admin/company-query.service';
import { JumpBarLink, JumpbarService } from 'common/content/services/jump-bar.service';
import { Select, Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { AccountAdminEditService } from 'private/app/services/account-admin/account-admin-edit.service';
import { AssociatedContactsComponent } from '../account-admin/associated-contacts/associated-contacts.component';
import { AssociatedDistributorsComponent } from '../account-admin/associated-distributors/associated-distributors.component';
import { AssociatedDealersComponent } from '../account-admin/associated-dealers/associated-dealers.component';
import { AccountAdminEditFormService } from 'private/app/services/account-admin/account-admin-edit-form.service';
import { AccountAdminApiService } from 'private/app/services/account-admin/account-admin-api.service';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { AccountAdminUtilsService } from 'private/app/services/account-admin/account-admin-utils.service';
import { AccountAdminCompanyUtilsService, RESIDENTIAL } from 'private/app/services/account-admin/account-admin-company-utils.service';
import { AccountStatusResponse, BaseResponse } from 'common/models/account-status';
import { ToastService } from 'common/services/toast.service';
import { BrandFamily, UserType } from 'common/models/brand.model';
import { ACTIVATED_STAT } from 'private/app/models/distributor-relationships.model';
import { CountryForMasking } from 'private/app/services/account-admin/account-admin-company-create.service';
import { CompanyCategoryType } from './company-query/company-query-configuration';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AddressLookup } from 'private/app/components/address-lookup/address-lookup.component';
import { UntypedFormGroup } from '@angular/forms';
import { AccountAdminExportService } from 'private/app/services/account-admin/account-admin-export.service';
import { AccountStatusService } from 'common/services/account-status.service';

@Component({
    selector: 'hvac-account-admin-company-details',
    templateUrl: './account-admin-company-details.component.html',
    styleUrls: ['./account-admin-company-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccountAdminCompanyDetailsComponent implements OnInit, AfterViewInit {
    @ViewChild(AssociatedContactsComponent, { static: false }) associateContacts : AssociatedContactsComponent;
    @ViewChild(AssociatedDistributorsComponent, { static: false }) associateDistributor : AssociatedDistributorsComponent;
    @ViewChild(AssociatedDealersComponent, { static: false }) associateDealer : AssociatedDealersComponent;
    @Select(AccountStatusState.getUserBrandFamily) userBrandFamily$: Observable<string | null>;

    public toastOutlet = 'adminCompanyDetailsToast';
    public isLoading = true;
    public state: string;
    public country: string
    public brands?: string;
    public services?: string;
    public company: Company;
    public rsms: string;
    public prsms: string;
    public accountManagers: string;
    public ptams: string;
    public tsms: string;
    public pricingAnal: string;
    public lastModifiedDate: string;
    public createdDate: string;
    public canEdit: boolean = false;
    public distUserWithICP: boolean = false;
    public distUserWithCBP: boolean = false;
    public claimTypeData: Option[] = [];
    public formData?: AccountAdminFormResultMap;
    public onCompanyChange = new BehaviorSubject<Company>({} as Company);
    public contactDetails$ = new BehaviorSubject<Contact[]>([] as Contact[]);
    public idmUserRole: string[];
    public companyDetailsForm = this.accountAdminService.companyDetailsForm;
    public language: string;
    public ngOnDestroy$ = new Subject();
    public showAddContactModal: boolean;
    public editCompanyInformation: boolean;
    public dealerLocatorVisibility: boolean = false;
    public isSameFamily: boolean;
    public deactivateCompanyModal: boolean = false;
    public reactivateCompanyModal: boolean = false;
    public isCBP: boolean = false;
    public userIsInternal: boolean = false;
    public countryOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public stateOptionData: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public servicesOptionData: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public accountAdminJumpBar$ = new BehaviorSubject<string[]>([]);
    public isSoldToRequiredInEdit$ = new BehaviorSubject<boolean>(false);
    public accountAdminResultJumpBar: string[] = [
        this.translatePipe.instant('ACCOUNT_ADMIN.DEALER_INFORMATION'),
        this.translatePipe.instant('ACCOUNT_ADMIN.DISTRIBUTOR_INFORMATION'),
        this.translatePipe.instant('ACCOUNT_ADMIN.ASSOCIATED_USERS'),
        this.translatePipe.instant('ACCOUNT_ADMIN.DISTRIBUTOR_RELATIONSHIPS'),
        this.translatePipe.instant('ACCOUNT_ADMIN.ASSOCIATED_DEALERS')
    ];

    public soldToValue: string;
    public maskPhoneNumber: boolean;
    public enableSoldTo: boolean = true;
    public notesPlaceHolderText = this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.NOTES');
    public adminAccess: boolean;
    public programAccess: boolean;
    public bannerType: string = 'companyEdit';
    public showModalFrMkngCrntCmpnyToAsPrmry: boolean = false;
    public updatedCompanyInfo: Company;
    public existingPrimaryDistributorDetails: string;
    public showAddressVerification$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public addressLookUpInput: AddressLookup;
    public showAddressValidationCheckbox : boolean = false;
    public disableAddressCorrection : boolean = false;
    public metValidation : boolean = false;
    public isBrandSelected: boolean;
    private countriesAllowedToAddressCorrection = ['US', 'USA - US', 'CA', 'Canada - CA'];
    private isAddressVerificationRequired: boolean = true;
    private previousCountry: string = '';

    constructor(
        public accountAdminService: AccountAdminService,
        @Inject(AccountAdminExportService) public accountAdminExportService: AccountAdminExportService,
        private readonly accountAdminCommonComponent: AccountAdminCommonComponentService,
        private readonly jumpBarService: JumpbarService,
        private readonly element: ElementRef,
        private route: ActivatedRoute,
        private translatePipe: TranslateService,
        private oktaService: OktaService,
        private router: Router,
        private readonly statusService: AccountStatusService,
        public companyQuery: CompanyQueryService,
        public readonly accountAdminEditService: AccountAdminEditService,
        public accountAdminUtilService: AccountAdminUtilsService,
        public accountAdminCompanyUtilsService: AccountAdminCompanyUtilsService,
        private readonly store: Store,
        public readonly accountAdminEditForm: AccountAdminEditFormService,
        private readonly accountAdminApiService: AccountAdminApiService,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService,
        private readonly commonUtils: CommonUtilsService
    ) { }

    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent) {
        const clickedInsideMenu = (event.target as HTMLElement).closest('.hvac-dropdown-menu') !== null;
        const clickedOnButton = (event.target as HTMLElement).closest('.hvac-more-options') !== null;
        if (!clickedInsideMenu && !clickedOnButton) {
            this.accountAdminCompanyUtilsService.menuActive = false;
        }
    }

    ngOnInit(): void {
        // SUBSCRIPTIONS
        this.statusService.getUserStatus().subscribe((result: AccountStatusResponse) => {
            this.userIsInternal = (result.company.companyCategory.name === UserType.INTERNAL);
        });
        this.adminAccess = (this.route.snapshot.data['adminAccess'] as IdmUserRoles[])?.includes(IdmUserRoles.ACCOUNTADMIN);
        this.oktaService.idToken$.subscribe((res: IdToken) => {
            this.idmUserRole = res?.claims?.idm_user_roles || [];
            this.programAccess = (this.idmUserRole.includes(IdmUserRoles.MARKETING_PROGRAM_ADMIN) || this.idmUserRole.includes(IdmUserRoles.CMNA_MARKETING_PROGRAM_ADMIN) || this.idmUserRole.includes(IdmUserRoles.PROGRAM_ENROLLMENT_ADMIN) || this.idmUserRole.includes(IdmUserRoles.CMNA_PROGRAM_ENROLLMENT_ADMIN));
        });

        if (!this.route.snapshot.params.id) {
            return;
        }

        this.claimTypeData = JSON.parse(JSON.stringify(this.accountAdminCommonComponent.claimTypeOptions));

        this.accountAdminService.getAccountAdminSearchForm({ excludeParts: true }).subscribe((data: AccountAdminFormResultMap) => {
            const countryArray = this.accountAdminCompanyUtilsService.filterCountryArraywithMostUsed(data.countries);
            this.countryOptionData$.next(countryArray || []);
            this.country = this.accountAdminEditService.getCountryNameByCountryCode(this.countryOptionData$.value);
            this.maskPhoneNumber = Boolean(this.country === CountryForMasking.USA || this.country === CountryForMasking.CANADA);
            this.formData = data;
        }, (error: HttpErrorResponse) => this.toastService.raiseDefaultErrorToast('AccountAdminCompanyDetailsComponent-ngOnInit', error.message));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.router.events.pipe(takeUntil(this.ngOnDestroy$)).subscribe((val: any) => {
            if (val instanceof NavigationEnd && (val.url.includes('company/') || val.url.includes('contact/'))) {
                this.isLoading = true;
                this.getCompanyDetailsById(this.route.snapshot.params.id);
            }
        });
        this.getCompanyDetailsById(this.route.snapshot.params.id);

        this.checkvalidation();
    }

    checkvalidation() {
        this.companyDetailsForm.valueChanges.subscribe(() => {
            const requiredFields = ['companyName', 'address1', 'city', 'postalCode'];
            const stateAndCountryFields = ['state', 'country'];
            this.metValidation = requiredFields.every((field) => this.companyDetailsForm.controls[field].value) &&
                     stateAndCountryFields.every((field) => this.companyDetailsForm.controls[field].value[0].value !== '');
        });
    }

    ngAfterViewInit(): void {
        combineLatest([this.onCompanyChange, this.companyQuery.companyDetailsConfiguration]).subscribe(([companyDetails, config]) => {
            if (companyDetails.hvacCompanyId && this.route.snapshot.params.id !== companyDetails.hvacCompanyId) {
                this.jumpBarService.clearJumpBarData();
                this.accountAdminJumpBar$.next([]);
                this.editCompanyInformation = false;
            }
            if (companyDetails.hvacCompanyId && config.configType && String(this.route.snapshot.params.id) === String(companyDetails.hvacCompanyId)) {
                const jumpData: string[] = [];
                jumpData.push(...this.accountAdminResultJumpBar);
                jumpData.unshift(companyDetails.companyType?.name || '');
                this.accountAdminJumpBar$.next(jumpData);
                setTimeout(() => {
                    const jumpLinks: JumpBarLink[] = [];
                    jumpLinks.push({
                        link: companyDetails.companyType?.name || '',
                        id: [companyDetails.companyType?.name || ''],
                        container: this.element.nativeElement
                    });
                    if (config.association?.associatedContact && this.associateContacts?.getNativeElementRef()) {
                        jumpLinks.push({
                            link: this.translatePipe.instant('ACCOUNT_ADMIN.ASSOCIATED_USERS'),
                            id: [this.translatePipe.instant('ACCOUNT_ADMIN.ASSOCIATED_USERS')],
                            container: this.associateContacts.getNativeElementRef().nativeElement
                        });
                    }
                    if (config.association?.associatedDistributor && this.associateDistributor?.getNativeElementRef()) {
                        jumpLinks.push({
                            link: this.translatePipe.instant('ACCOUNT_ADMIN.DISTRIBUTOR_RELATIONSHIPS'),
                            id: [this.translatePipe.instant('ACCOUNT_ADMIN.DISTRIBUTOR_RELATIONSHIPS')],
                            container: this.associateDistributor.getNativeElementRef().nativeElement
                        });
                    }
                    if (config.association?.associatedDealer && this.associateDealer?.getNativeElementRef()) {
                        jumpLinks.push({
                            link: this.translatePipe.instant('ACCOUNT_ADMIN.ASSOCIATED_DEALERS'),
                            id: [this.translatePipe.instant('ACCOUNT_ADMIN.ASSOCIATED_DEALERS')],
                            container: this.associateDealer.getNativeElementRef().nativeElement
                        });
                    }
                    this.jumpBarService.setJumpBarLink(jumpLinks);
                }, 300);
            }
        });

        this.companyDetailsForm.controls.country.valueChanges.subscribe((selectedCountry: string) => {
            this.previousCountry = (this.companyDetailsForm.controls.country.value).toString || (this.companyDetailsForm.controls.country.value[0].value).toString;
            if (selectedCountry) {
                this.stateOptionData.next([]);
                this.isLoading = true;
                if (Array.isArray(selectedCountry)) {
                    this.maskPhoneNumber = Boolean(selectedCountry[0].value === CountryForMasking.US || selectedCountry[0].value === CountryForMasking.CA);
                    this.accountAdminEditForm.formInputPlaceHolder(selectedCountry[0].value);
                    selectedCountry[0].value ? this.getStateListByCountry(selectedCountry[0].value) : this.isLoading = false;
                }
                else {
                    const countrySelection = this.countryOptionData$.value.find((country) => country.name === selectedCountry);
                    if (countrySelection) {
                        this.accountAdminEditForm.formInputPlaceHolder(countrySelection.value);
                        this.getStateListByCountry(countrySelection.value);
                    }
                }
                if (Array.isArray(selectedCountry)) {
                    if (this.previousCountry.toString !== selectedCountry[0].value.toString) {
                        this.companyDetailsForm.controls.state.setValue('');
                        this.metValidation = false;
                        this.previousCountry = '';
                    }
                }
            }
        });

        this.companyDetailsForm.controls.state.valueChanges.subscribe((selecteState: string) => {
            if (selecteState) {
                this.metValidation = true;
            }
            if (Array.isArray(selecteState)) {
                if (selecteState[0].value === '') {
                    this.metValidation = false;
                }
            }
        });

        this.companyQuery.updateCompanyDetails.subscribe((res: Company) => {
            if (Object.keys(res).length > 0) {
                this.updateCompanyDetails(res);
            }
        });
    }

    onServicesChange() {
        this.isSoldToRequiredInEdit$.next(this.accountAdminEditForm.isSoldToRequired(this.companyDetailsForm));
        this.enableSoldTo = (this.isSoldToRequiredInEdit$.value && !this.soldToValue);
    }

    getCompanyDetailsById(companyId: string) {
        this.accountAdminApiService.getAccountAdminCompanyDetails(companyId).subscribe((res: Company) => {
            this.updateCompanyDetails(res);
        });
    }

    onContactFormSubmitAction() {
        this.getCompanyDetailsById(this.route.snapshot.params.id);
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
        if (this.jumpBarService) {
            this.jumpBarService.clearJumpBarData();
        }
    }

    exportAssociationExcel() {
        this.isLoading = true;
        this.accountAdminApiService.exportAssociationdetails(this.company.hvacCompanyId).subscribe((response: HttpResponse<string>) => {
            this.isLoading = false;
            this.accountAdminExportService.doExport(response);
        },
        (err) => {
            if (err) {
                this.isLoading = false;
                this.toastService.add({
                    content: this.translate.instant('ACCOUNT_ADMIN.ASSOCIATION_EXPORT_ERROR'),
                    theme: 'error',
                    id: this.bannerType,
                    closeable: true,
                    autoClose: true
                });
            }
        });
    }

    issueEditCompanyRequest() {
        this.isLoading = true;
        this.showModalFrMkngCrntCmpnyToAsPrmry = false;
        this.accountAdminApiService.updatedCompanyInformation(this.updatedCompanyInfo, this.companyDetailsForm, this.isSoldToRequiredInEdit$)?.subscribe((data) => {
            if (data) {
                this.toastService.add({
                    content: this.translate.instant('ACCOUNT_ADMIN.COMPANY_EDIT.COMPANY_UPDATE_SUCCESS', {
                        companyName: this.updatedCompanyInfo.name,
                        companyId: this.updatedCompanyInfo.hvacCompanyId
                    }),
                    id: this.bannerType,
                    outletName: this.toastOutlet,
                    theme: 'success',
                    closeable: true,
                    autoClose: true
                });
                this.editCompanyInformation = false;
                this.accountAdminEditForm.resetForm(this.companyDetailsForm);
                this.getCompanyDetailsById(this.route.snapshot.params.id);
                this.isLoading = false;
            }
        },
        (errorResponse) => {
            this.isLoading = false;
            this.toastService.add({
                content: this.translate.instant('ACCOUNT_ADMIN.COMPANY_EDIT.COMPANY_UPDATE_FAILED', {
                    companyName: this.updatedCompanyInfo.name,
                    companyId: this.updatedCompanyInfo.hvacCompanyId,
                    errorReason: errorResponse?.error?.message ? errorResponse?.error?.message : this.translate.instant('ERROR_CONTENT.SOMETHING_WENT_WRONG')
                }),
                id: this.bannerType,
                outletName: this.toastOutlet,
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        });
        this.commonUtils.smoothScrollToTop(this.element);
    }

    onSaveEditClick() {
        if ((this.countriesAllowedToAddressCorrection.includes(this.companyDetailsForm.controls.country.value) || this.countriesAllowedToAddressCorrection.includes(this.companyDetailsForm.controls.country.value[0].value)) && this.isAddressVerificationRequired) {
            this.showAddressVerification$.next(true);
            this.addressLookUpInput = this.companyAddressToBeValidate(this.companyDetailsForm);
        }
        else {
            this.onUpdateCompanyInfo();
            this.showAddressValidationCheckbox = false;
        }
    }

    onUpdateCompanyInfo() {
        this.updatedCompanyInfo = (this.editCompanyInformation) ? this.accountAdminEditService.fetchCompanyInfoEditData(this.companyDetailsForm, this.stateOptionData.value, this.countryOptionData$.value) : this.company;

        if (!this.updatedCompanyInfo.soldToCustomerId && this.updatedCompanyInfo.isPrimaryAccount) {
            this.toastService.add({
                content: this.translate.instant('ACCOUNT_ADMIN.COMPANY_EDIT.SOLD_TO_EMPTY_TO_BE_PRIMARY'),
                id: this.bannerType,
                outletName: this.toastOutlet,
                theme: 'error',
                closeable: true,
                autoClose: true
            });
            this.commonUtils.smoothScrollToTop(this.element);

            return;
        }

        if (
            this.isCompanyCategoryDistributorType(this.updatedCompanyInfo.companyCategory.name) &&
            this.updatedCompanyInfo.isPrimaryAccount &&
            this.updatedCompanyInfo.soldToCustomerId
        ) {
            this.isLoading = true;
            this.companyQuery.isPrimaryDistributorExistsForCrntSoldToCmpny(this.updatedCompanyInfo.soldToCustomerId)
                .subscribe((response: [boolean, string]) => {
                    this.isLoading = false;
                    const [isPrimaryDistributorAlreadyPresent, primaryDistributorDetailsText] = response;

                    if (isPrimaryDistributorAlreadyPresent) {
                        this.getUserCnsntFrMkngCrntSldToAsPrmry(primaryDistributorDetailsText);
                    }
                    else {
                        this.issueEditCompanyRequest();
                    }
                },
                (errorResponse) => {
                    this.isLoading = false;
                    this.toastService.add({
                        content: this.translate.instant('ACCOUNT_ADMIN.COMPANY_EDIT.SOLD_TO_VALIDATION_FAILED', { errorReason: errorResponse?.error?.message ? errorResponse?.error?.message : this.translate.instant('ERROR_CONTENT.SOMETHING_WENT_WRONG') }),
                        id: this.bannerType,
                        outletName: this.toastOutlet,
                        theme: 'error',
                        closeable: true,
                        autoClose: true
                    });
                    this.commonUtils.smoothScrollToTop(this.element);
                });

            return;
        }

        this.issueEditCompanyRequest();
        this.commonUtils.smoothScrollToTop(this.element);
    }

    companyAddressToBeValidate(companyDetailsForm: UntypedFormGroup): AddressLookup {
        return this.accountAdminService.companyAddressToValidate(companyDetailsForm);
    }

    onAddressConfirmation(address: AddressLookup | null): void {
        if (address === null) {
            this.showAddressVerification$.next(false);

            return;
        }

        this.accountAdminService.onAddressConfirmation(address);
        this.showAddressVerification$.next(false);
        this.isAddressVerificationRequired = false;
        this.onUpdateCompanyInfo();
    }

    onEdit(company: Company) {
        if (this.idmUserRole.includes(IdmUserRoles.COMPANYADMIN)) {
            this.showAddressValidationCheckbox = true;
            this.isAddressVerificationRequired = true;
        }
        // redirect users without account admin access to legacy site
        if (!this.canEdit) {
            const query = { cid: company?.hvacCompanyId };
            this.store.dispatch(new Navigate(['/Pages/admin/editcompany.aspx'], query));

            return;
        }

        this.accountAdminEditService.updateCompanyDetailsform(this.companyDetailsForm, this.rsms, this.prsms, this.accountManagers, this.ptams, this.tsms);
        this.accountAdminEditService.statusChangeCompanyDetailsform(this.companyDetailsForm);
        this.accountAdminEditService.setCountrySelection(this.countryOptionData$.value, this.companyDetailsForm);
        this.editCompanyInformation = true;
        this.commonUtils.smoothScrollToTop(this.element);
        this.soldToValue = this.company.soldToCustomerId;
        this.enableSoldTo = (this.accountAdminEditForm.isSoldToRequired(this.companyDetailsForm) && this.soldToValue === '');
        this.accountAdminEditService.fetchParentCompany();
    }

    onParentCompanyLookUp(option: Option) {
        this.accountAdminEditService.companyEditCopy.parentCompany = {
            name: option.name,
            id: option.value
        };
    }

    addressVerificationSelected(event: Event) {
        if ((event.target as HTMLInputElement).checked) {
            this.isAddressVerificationRequired = false;
        }
    }

    public deactivateCompany() {
        const activatedPricingWarranty = this.company.pricingWarrantyRelationships?.filter((pricing) => pricing?.dealerDistributor.status === ACTIVATED_STAT);
        const activatedBrands = this.company.brandedRelationships?.filter((brand) => brand.dealerDistributor.status === ACTIVATED_STAT);
        if ((activatedPricingWarranty && activatedPricingWarranty.length > 0) || (activatedBrands && activatedBrands.length > 0)) {
            this.deactivateCompanyModal = false;
            this.toastService.add({
                content: this.translate.instant('ACCOUNT_ADMIN.DEACTIVATE_COMPANY_MODAL.DEACTIVATE_ERROR', {
                    companyName: this.company.name,
                    companyId: this.company.hvacCompanyId
                }),
                id: this.company.name,
                outletName: this.toastOutlet,
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        }
        else {
            this.accountAdminApiService.deactivate(this.company.hvacCompanyId).subscribe((data:BaseResponse) => {
                if (data.status === this.translate.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.SUCCESS_MESSAGE')) {
                    this.deactivateCompanyModal = false;
                    this.onUpdateCompanyInfo();
                    this.toastService.add({
                        content: this.translate.instant('ACCOUNT_ADMIN.DEACTIVATE_COMPANY_MODAL.DEACTIVATE_SUCCESS', { companyName: this.company.name }),
                        id: this.company.name,
                        outletName: this.toastOutlet,
                        theme: 'success',
                        closeable: true,
                        autoClose: true
                    });
                }
            },
            (error) => {
                this.deactivateCompanyModal = false;
                this.toastService.add({
                    content: error.error.message,
                    id: this.company.name,
                    outletName: this.toastOutlet,
                    theme: 'error',
                    closeable: true,
                    autoClose: true
                });
            });
        }
    }

    public reactivateCompany() {
        const activatedPricingWarranty = this.company.pricingWarrantyRelationships?.filter((pricing) => pricing?.dealerDistributor.status === ACTIVATED_STAT);
        const activatedBrands = this.company.brandedRelationships?.filter((brand) => brand.dealerDistributor.status === ACTIVATED_STAT);

        if (this.companyQuery.isCompanyCategoryCustomerVendor(this.company.companyCategory.name)) {
            this.reactivateCompanyModal = false;
            this.toastService.removeAll();
            if ((activatedPricingWarranty && activatedPricingWarranty.length === 0) || (activatedBrands && activatedBrands.length === 0)) {
                this.accountAdminService.isModalVisible = true;
            }
        }
        else {
            this.accountAdminApiService.reactivate(this.company.hvacCompanyId).subscribe((res:Company) => {
                if (res) {
                    this.reactivateCompanyModal = false;
                    this.updateCompanyDetails(res);
                    this.toastService.add({
                        content: this.translate.instant('ACCOUNT_ADMIN.REACTIVATE_COMPANY_MODAL.REACTIVATE_SUCCESS', { companyName: this.company.name }),
                        id: this.company.name,
                        outletName: this.toastOutlet,
                        theme: 'success',
                        closeable: true,
                        autoClose: true
                    });
                }
            });
        }
    }

    cancelEdit() {
        this.getCompanyDetailsById(this.route.snapshot.params.id);
        this.accountAdminEditForm.resetForm(this.companyDetailsForm);
        this.editCompanyInformation = false;
        this.commonUtils.smoothScrollToTop(this.element);
        this.showAddressValidationCheckbox = false;
    }

    getMaskedNumber(num: string | number | null) {
        if (num) {
            const maskedNumber = num.toString().match(/(\d{3})(\d{3})(\d{4})/);

            return (maskedNumber) ? `(${maskedNumber![1]}) ${maskedNumber![2]}-${maskedNumber![3]}` : '';
        }

        return '--';
    }

    cnclChngngExstingPrmryDstrbtr() {
        this.showModalFrMkngCrntCmpnyToAsPrmry = false;
    }

    isCompanyCategoryDistributorType(companyCategory: string): boolean {
        return [CompanyCategoryType.DOMESTIC_DISTRIBUTOR.toString(), CompanyCategoryType.INTERNATIONAL_DISTRIBUTOR.toString(), CompanyCategoryType.MARINE_DISTRIBUTOR.toString()].includes(companyCategory);
    }

    manageCountiesBrandsConditions(brands: AuthorizedBrand[]): boolean {
        return brands.some((brand) => (brand.name.toLowerCase() === 'carrier' || brand.name.toLowerCase() === 'bryant'));
    }

    reprioritizeState(options: Option[]) {
        const filteredOptions = options.filter((item: Option) => item.value !== '');
        filteredOptions.unshift({
            name: this.translate.instant('ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.STATE'),
            value: ''
        });

        return filteredOptions;
    }

    updateIsBrandSelected(value: boolean) {
        this.isBrandSelected = value;
    }

    private getUserCnsntFrMkngCrntSldToAsPrmry(primaryDistributorDetailsText = '') {
        const detailsText = primaryDistributorDetailsText.split('-');

        this.existingPrimaryDistributorDetails = `${detailsText[1] || ''} - ${detailsText[2] || ''}`;
        this.showModalFrMkngCrntCmpnyToAsPrmry = true;
    }

    private updateCompanyDetails(res: Company) {
        this.maskPhoneNumber = Boolean(res.country === CountryForMasking.US || res.country === CountryForMasking.CA);
        this.companyQuery.generateCompanyDetailsConfiguration(res.companyCategory.name, res?.companyType?.code || '', this.idmUserRole);
        this.company = res;
        this.filterAccountAdminContacts(this.company.companyContacts);
        this.accountAdminEditService.selectedHoursOfOperarion = res.operatingTime || '';
        this.companyQuery.isClaimTypeVisible(this.company.companyServices.map((service: CompanyServices) => service.name));
        this.userBrandFamily$.subscribe((brand) => {
            this.isSameFamily = brand?.toLowerCase() === this.company.brandFamily.toLowerCase();
            this.isCBP = brand?.toLowerCase() === BrandFamily.CBP;
            if (this.isCBP) {
                this.claimTypeData.splice(-2);
            }
            this.distUserWithICP = Boolean((this.idmUserRole.includes(IdmUserRoles.COMPANY_TYPE_DOMESTIC_DISTRIBUTOR) || this.idmUserRole.includes(IdmUserRoles.COMPANY_TYPE_INTERNATIONAL_DISTRIBUTOR) || this.idmUserRole.includes(IdmUserRoles.COMPANY_TYPE_MARINE_DISTRIBUTOR)) && (brand?.toLowerCase() === BrandFamily.ICP) && (this.company.brandFamily.toLowerCase() === BrandFamily.ICP));
            this.distUserWithCBP = Boolean((this.idmUserRole.includes(IdmUserRoles.COMPANY_TYPE_DOMESTIC_DISTRIBUTOR) || this.idmUserRole.includes(IdmUserRoles.COMPANY_TYPE_INTERNATIONAL_DISTRIBUTOR) || this.idmUserRole.includes(IdmUserRoles.COMPANY_TYPE_MARINE_DISTRIBUTOR)) && this.isCBP && (this.idmUserRole.includes(IdmUserRoles.COMPANYADMIN)));
            this.canEdit = (Boolean(this.idmUserRole.includes(IdmUserRoles.ACCOUNTADMIN)) && (!this.distUserWithICP || this.distUserWithCBP));
        });
        this.onCompanyChange.next(this.company);
        this.lastModifiedDate = this.accountAdminService.formatDate(this.company.lastModifiedDate || '');
        this.createdDate = this.accountAdminService.formatDate(this.company.createdDate || '');
        this.company.brands?.map((brand: AuthorizedBrand) => brand.name);
        this.isBrandSelected = Boolean(this.company?.brands.length);
        this.services = this.company.companyServices.map((service: CompanyServices) => service.name)
            .join(', ');
        this.language = this.company.language?.map((language: string) => language).join(', ') || '';
        this.isLoading = false;
        this.getStateListByCountry(this.company.country);
        const isServiceResidential = this.company.companyServices?.some((svc) => svc.code === RESIDENTIAL);
        if (this.canEdit && isServiceResidential) {
            this.getDealerLocatorVisibility(this.company.hvacCompanyId);
        }
        this.accountAdminApiService.getAccountAdminCompanyServices(this.company.companyType?.name || '', this.servicesOptionData);
        this.accountAdminEditService.copyCompanyEditDetails(this.company);
    }

    private getDealerLocatorVisibility(companyId: string) {
        this.accountAdminApiService.getAccountAdminCompanyDealerLocatorBrands(companyId).subscribe((brands: string[]) => {
            this.dealerLocatorVisibility = Boolean(brands?.length);
        });
    }

    private getStateListByCountry(selectedCountry: string) {
        if (selectedCountry.length <= 0) {
            return;
        }
        this.commonUtils.getStateByCountry(selectedCountry).subscribe(
            (stateList) => {
                this.stateOptionData.next(this.reprioritizeState(stateList));
                this.accountAdminEditService.setStateSelection(this.reprioritizeState(stateList), this.companyDetailsForm);
                this.state = this.accountAdminEditService.getStateNameByStateCode(this.reprioritizeState(stateList));
                this.isLoading = false;
            },
            (error) => {
                this.isLoading = false;
                this.stateOptionData.error(error);
            }
        );
    }

    private filterAccountAdminContacts(contacts: CompanyContact[]) {
        this.company.companyContacts = contacts.filter((contact) => contact.contactType !== 'Electronic Subscriber');
        this.rsms = this.accountAdminService.filterContactsByType(contacts, 'Regional Sales Manager')
            ?.map((contact: CompanyContact) => `${contact.userName}`)
            .join(', ');

        this.prsms = this.accountAdminService.filterContactsByType(contacts, 'Parts Regional Sales Manager')
            ?.map((contact: CompanyContact) => `${contact.userName}`)
            .join(', ');

        this.accountManagers = this.accountAdminService.filterContactsByType(contacts, 'Account Manager')
            ?.map((contact: CompanyContact) => `${contact.userName}`)
            .join(', ');

        this.ptams = this.accountAdminService.filterContactsByType(contacts, 'Parts Account Manager')
            ?.map((contact: CompanyContact) => `${contact.userName}`)
            .join(', ');

        this.tsms = this.accountAdminService.filterContactsByType(contacts, 'Technical Service Manager (TSM)')
            ?.map((contact: CompanyContact) => `${contact.userName}`)
            .join(', ');

        this.pricingAnal = this.accountAdminService.filterContactsByType(contacts, 'Pricing Analyst')
            ?.map((contact:CompanyContact) => `${contact.userName}`)
            .join(' ,');
    }
}
