import { Inject, Injectable } from '@angular/core';
import { DuplicateCompanyLookup } from 'private/app/components/duplicate-company-check/duplicate-company-check.component';
import { AuthorizedBrand } from 'private/app/models/account-admin-search';
import { AccountPostAdminService } from 'private/app/services/account-admin/account-post-admin.service';
import { BehaviorSubject } from 'rxjs';

export interface DuplicateResultData {
    hvacCompanyId?: number;
    soldTo?: string;
    shipTo?: string;
    payer?: string;
    companyName?: string;
    state?: string;
    city?: string;
    phone?: string;
    email?: string;
    address1?: string;
    address2?: string;
    brandsName?: string;
    companyType?: string;
    country?: string;
    fax?: string;
    parentBrandsName?: string;
    postalCode?: string;
    warranty?: string;
    companyCategory?: string;
    companyServices?: string[];
    authorizedBrands?: AuthorizedBrand[];
}

export interface DuplicateCompanyCheckResult {
    data?: DuplicateResultData[] | DuplicateResultData;
    totalCount?: number;
}


@Injectable({ providedIn: 'root' })
export class DuplicateCompanyCheckService {
    public incorrectProcessStatus: string[] = ['I1', 'I2'];
    duplicateCompanyCheckResult$: BehaviorSubject<DuplicateCompanyCheckResult> = new BehaviorSubject<DuplicateCompanyCheckResult>({});
    constructor(@Inject(AccountPostAdminService) private accountPostAdminService: AccountPostAdminService) { }

    public duplicateCompanyCheckFunction(duplicateCompanyLookup: DuplicateCompanyLookup) {
        this.accountPostAdminService.duplicateCompanyAuthSearch({
            criteria: {
                companyTypeCodes: duplicateCompanyLookup.companyType,
                brandFamily: duplicateCompanyLookup.brandFamily,
                address1: duplicateCompanyLookup.address1,
                city: duplicateCompanyLookup.city,
                state: duplicateCompanyLookup.state.code,
                postalCode: duplicateCompanyLookup.postalCode,
                skipHierarchy: true
            },
            pageParams: {
                page: 0,
                pageSize: 50
            },
            determineTotalCount: true
        }).subscribe((response) => {
            this.duplicateCompanyCheckResult$.next(response);
        });
    }
}

