import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Toast } from 'common/services/toast.service';
import { ComponentsModule } from 'private/app/components/components.module';
import { SystemType } from 'private/app/models/connected-product.model';
import { WallControlSystemDiagnosticsComponent } from '../../../customer-product-details/wall-control/components/wall-control-system-diagnostics/wall-control-system-diagnostics.component';
import { GenericEventLogsComponent } from '../../../customer-product-details/wall-control/components/wall-control-system-diagnostics/components/generic-event-logs/generic-event-logs.component';

@Component({
    selector: 'hvac-system-diagnostics-modal',
    templateUrl: './system-diagnostics-modal.component.html',
    styleUrls: ['./system-diagnostics-modal.component.scss'],
    standalone: true,
    imports: [ComponentsModule, TranslateModule, CommonModule, WallControlSystemDiagnosticsComponent, GenericEventLogsComponent]
})
export class SystemDiagnosticsModalComponent {
    @Input() dealerId: string;
    @Input() serialNo: string;
    @Input() isDiagnosticModalVisible: boolean = false;
    @Input() propertyId: string;
    @Input() systemType: SystemType;

    @Output() closeDiagnosticsTestModal = new EventEmitter<Toast>();

    public hideDiagnosticAttentionInfo = false;

    handleCloseDiagnosticsTestModal(toastMessage?: Toast | undefined) {
        this.closeDiagnosticsTestModal.emit(toastMessage);
        this.hideDiagnosticAttentionInfo = false;
    }

    handleCloseAttentionInfo() {
        this.hideDiagnosticAttentionInfo = true;
    }
}
