/* eslint-disable max-lines */
import * as d3 from 'd3';
import { Component, ElementRef, Input, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { format, differenceInHours } from 'date-fns';
import { BaseComponent } from 'common/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { CharSymbol } from 'common/enums/char-symbol';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SystemType } from 'private/app/models/connected-product.model';

interface SensorDataPoint {
    date: Date;
    value: number | null;
}

interface SensorDataset {
    name: string;
    data: SensorDataPoint[];
    min?: number | null;
    max?: number | null;
}

interface SwimlanePropsData {
    [key: string]: {start: Date | null, end: Date | null}[];
}

interface SwimlaneChartData {
    dates: Date[];
    relayData: SwimlanePropsData;
}

interface Margin {
    top: number;
    bottom: number;
    left: number;
    right: number;
}

enum ChartColors {
    Gray200 = '#E8E8E8',
    Blue500 = '#003366',
    Turquoise600 = '#00B5A2',
    White = '#FFF'
}

export type RelayValue = 'on' | 'auto' | 'circulation' | 'off' | 'Not_Config';

export interface SwimlaneChartProps {
    fan: RelayValue;
    coolingStage1: RelayValue;
    coolingStage2: RelayValue;
    heatingStage1: RelayValue;
    heatingStage2: RelayValue;
    hpHeatingStage1: RelayValue;
    hpHeatingStage2: RelayValue;
    auxHeatingStage1: RelayValue;
    auxHeatingStage2: RelayValue;
}

export interface DataSummary extends SwimlaneChartProps {
    dateTime: string;
    indoorTemp: number;
    coolToSetPoint: number;
    heatToSetPoint: number;
    outdoorTemp: number;
    indoorHumidity: number;
    outdoorHumidity:number;
}

const PROP_TRANSLATE_BASE = 'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.SWIMLANE_CHART';

@Component({
    selector: 'hvac-connected-portal-swimlane-chart',
    templateUrl: './connected-portal-swimlane-chart.component.html',
    styleUrls: ['./connected-portal-swimlane-chart.component.scss'],
    standalone: true
})
export class ConnectedPortalSwimlaneChartComponent extends BaseComponent implements AfterViewInit {
    @ViewChild('chart', { static: false }) chart: ElementRef;

    @Input() labelWidth: number;
    @Input() graphWidth: number;
    @Input() systemType: SystemType;
    @Input() dataSummary$: BehaviorSubject<DataSummary[]>;
    public chartData: SwimlaneChartData;
    public dataSummary: DataSummary[] = [];

    displayTooltip = false;
    displayXAxis = false;
    private chartContainer: ElementRef;
    private ecobeeRelayProps = [
        'fan',
        'coolingStage1',
        'coolingStage2',
        'heatingStage1',
        'heatingStage2',
        'hpHeatingStage1',
        'hpHeatingStage2',
        'auxHeatingStage1',
        'auxHeatingStage2'
    ];

    private eltRelayProps = [
        'fan',
        'coolingStage1',
        'coolingStage2',
        'hpHeatingStage1',
        'hpHeatingStage2',
        'auxHeatingStage1',
        'auxHeatingStage2'
    ];


    constructor(
        private translateService: TranslateService
    ) {
        super();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.drawChart();
    }

    ngOnInit() {
        this.dataSummary$?.pipe(
            takeUntil(this.ngOnDestroy$),
            filter((data) => data.length > 0)
        ).subscribe((data) => {
            this.dataSummary = data;
            this.configureSwimlaneChart();
            this.drawChart();
        });
    }

    ngAfterViewInit(): void {
        this.chartContainer = this.chart;

        // this.drawChart();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        d3.select('#swimlane-overlay')
            .on('mousemove', null)
            .on('mouseout', null);

        d3.selectAll('*').interrupt();
    }

    private drawChart(): void {
        if (!this.chartContainer) {
            return;
        }

        const { relayData, dates } = this.chartData;
        const datasets:SensorDataset[] = [];

        const element = this.chartContainer.nativeElement;

        // Clear existing chart
        d3.select(element).selectAll('*').remove();

        const datesHourDiff = differenceInHours(dates[dates.length - 1], dates[0]);

        const margin: Margin = {
            top: 0,
            right: 0,
            bottom: 0,
            left: this.labelWidth
        };

        const unifiedXAxisHeight = 30;
        const width = this.graphWidth;
        const heightPerSwimlane = 80;

        const relayBarMargin = 8;
        const relayBarHeight = 16 + (relayBarMargin * 2);
        const relayGroupHeight = Object.keys(relayData).length * relayBarHeight;
        const swimlaneGroupHeight = (heightPerSwimlane + margin.top + margin.bottom) * datasets.length;
        const chartHeight = relayGroupHeight + swimlaneGroupHeight + margin.bottom + (this.displayXAxis ? unifiedXAxisHeight : 0);

        const relayYScale = d3.scaleBand()
            .domain(Object.keys(relayData))
            .range([0, relayGroupHeight]);

        const xScale = d3.scaleTime()
            .domain([d3.min([...dates]), d3.max([...dates])] as [Date, Date])
            .range([0, width]);

        const xScaleUnified = d3.scaleTime()
            .domain(d3.extent(dates) as [Date, Date])
            .range([0, width]);

        // Create the SVG container
        const svg = d3.select(element)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', chartHeight);

        // RELAY GROUP START
        const relayGroup = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        relayGroup.append('rect')
            .attr('width', width)
            .attr('height', relayGroupHeight)
            .attr('fill', 'none');

        relayGroup.selectAll('.swimlane-label')
            .data(Object.keys(relayData))
            .enter()
            .append('text')
            .attr('class', 'swimlane-relay-label')
            .attr('x', -margin.left)
            .attr('y', (datum) => relayYScale(datum) as number + relayYScale.bandwidth() / 2)
            .attr('text-anchor', 'start')
            .attr('alignment-baseline', 'middle')
            .text((datum) => this.translateService.instant(`${PROP_TRANSLATE_BASE}.${datum}`));

        relayGroup.selectAll('.swimlane-relay-swimlane')
            .data(Object.entries(relayData))
            .enter()
            .append('g')
            .attr('class', 'swimlane-relay-swimlane')
            .attr('transform', (datum) => `translate(0,${relayYScale(datum[0])})`)
            // eslint-disable-next-line func-names
            .each(function([, intervals]) {
                d3.select(this).selectAll('.swimlane-relay-bar')
                    .data(intervals)
                    .enter()
                    .append('rect')
                    .attr('class', 'swimlane-relay-bar')
                    .attr('x', (datum) => xScale(new Date(datum.start!)))
                    .attr('width', (datum) => xScale(new Date(datum.end!)) - xScale(new Date(datum.start!)))
                    .attr('y', relayBarMargin)
                    .attr('height', relayYScale.bandwidth() - (2 * relayBarMargin));
            });


        const relayGridLines = d3.axisBottom(xScale)
            .tickSizeInner(relayGroupHeight - margin.top)
            .tickSizeOuter(0)
            // No labels for these lines
            .tickFormat(() => '');

        relayGroup.append('g')
            .attr('class', 'swimlane-grid-lines')
            .attr('transform', `translate(0,${margin.top / 2})`)
            .call(relayGridLines)
            .call((grp) => grp.select('.domain').remove())
            .selectAll('line')
            .attr('stroke', ChartColors.Gray200)
            .attr('stroke-width', '2px');


        // Add the unified x-axis at the bottom
        const xScaleGroup = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${datasets.length * (heightPerSwimlane + margin.top + margin.bottom) + relayGroupHeight + margin.bottom})`)
            .attr('class', 'swimlane-axis-x');

        xScaleGroup.call(d3.axisBottom(xScale)
            .tickFormat((date, index) => {
                if (index % 4 === 0) {
                    if (datesHourDiff < 24) {
                        return format(date as Date, 'h:mm a');
                    }

                    return format(date as Date, 'MM/dd');
                }

                return '';
            }))
            // Remove the horizontal line
            .call((grp) => grp.select('.domain').remove());


        // Add an overlay for capturing hover events
        const overlay = svg.append('rect')
            .attr('id', 'swimlane-overlay')
            .attr('width', width)
            .attr('height', datasets.length * (heightPerSwimlane + margin.top + margin.bottom) + relayGroupHeight)
            .attr('transform', `translate(${margin.left}, 0)`)
            .style('fill', 'none')
            .style('pointer-events', 'all');

        // Prepare the vertical line
        const verticalLine = svg.append('g')
            .append('line')
            .attr('y1', margin.top)
            .attr('y2', datasets.length * (heightPerSwimlane + margin.top + margin.bottom) + relayGroupHeight)
            .attr('class', 'swimlane-cursor')
            .style('pointer-events', 'none')
            .style('display', 'none');

        // Prepare the tooltip (but don't populate it yet)
        const tooltip = this.createTooltip(this.chartContainer);

        // Event listener for hover
        overlay.on('mousemove', (event) => {
            const [x] = d3.pointer(event);
            const dateTime = xScaleUnified.invert(x);
            const bisectDate = d3.bisector((datum: SensorDataPoint) => datum.date).left;

            // Show and position the vertical line
            verticalLine
                .attr('x1', x + margin.left)
                .attr('x2', x + margin.left)
                .style('display', this.displayXAxis ? 'block' : 'none');

            // Prepare tooltip data
            const tooltipData = datasets.map((dataset) => {
                const closestDataPoint = bisectDate(dataset.data, dateTime);
                const closestValue = dataset.data[closestDataPoint]?.value;
                const value = this.getTooltipValue(closestValue);

                return {
                    property: dataset.name,
                    value
                };
            });

            // Populate the tooltip
            const tooltipTimeHtml = `
                <div class="swimlane-tooltip-time">${format(dateTime, 'MM/dd hh:mm:ss a')}</div>
            `;

            const tooltipPropHtml = tooltipData.map((datum) => `
                <div class="swimlane-tooltip-prop">
                    <span class="swimlane-tooltip-prop-label">${this.getParameterPropName(datum.property)}</span>:
                    <span class="swimlane-tooltip-prop-value">${datum.value}</span>
                </div>`).join('');

            tooltip.html(`${tooltipTimeHtml} ${tooltipPropHtml}`)
                .style('left', `${event.layerX + 10}px`)
                .style('top', `${event.layerY + 10}px`)
                .style('display', this.displayTooltip ? 'block' : 'none');
        });

        // Hide the vertical line when not hovering
        overlay.on('mouseout', () => {
            verticalLine.style('display', 'none');
            tooltip.style('display', 'none');
        });
    }


    private getTooltipValue(value: number | null, min?: number | null, max?: number | null) {
        if (
            typeof value === 'number'
            && typeof min === 'number'
            && typeof max === 'number'
            && (value < min || value > max)
        ) {
            return CharSymbol.EmDash;
        }

        return Number.isFinite(value) ? value : CharSymbol.EmDash;
    }

    private getParameterPropName(propKey: string) {
        return this.translateService.instant(`${PROP_TRANSLATE_BASE}.PARAMETER_PROPS.${propKey}.LABEL`);
    }

    private createTooltip(elRef: ElementRef) {
        return d3.select(elRef.nativeElement)
            .append('div')
            .attr('class', 'swimlane-tooltip')
            .style('position', 'absolute')
            .style('pointer-events', 'none')
            .style('display', 'none');
    }

    private transformSwimlaneRelayEventData(thermostatEvents: DataSummary[], selectedRelays: string[] | null): SwimlanePropsData {
        if (!thermostatEvents || !selectedRelays) {
            return {};
        }

        // Pick only the required relays data
        const dataset = thermostatEvents.map((event) => {
            const relayProps = Object.entries(event)
                .reduce((acc, [key, value]) => {
                    if (selectedRelays.includes(key)) {
                        acc[key] = value;
                    }

                    return acc;
                }, {} as { [key: string]: number | null });

            return {
                date: event.dateTime,
                ...relayProps
            };
        });

        // Skip relay which contains "not_config" throughout the whole timeseries
        const updatedRelays = selectedRelays
            .filter((mainKey) => !dataset
                .map((item: {[key: string]: string, date: string}) => item[mainKey])
                .every((val) => String(val).toLowerCase() === 'not_config' || val === null));

        const transformedData = updatedRelays
            .reduce((acc: SwimlanePropsData, key: string) => {
                const intervals: {start: Date | null, end: Date | null}[] = [];
                let start: Date | null = null;

                dataset.forEach((entry: {[key: string]: string, date: string}, index) => {
                    const entryDate = new Date(Date.parse(entry.date));
                    const isOn = ['on', 'auto', 'circulation'].includes(String(entry[key]).toLowerCase());
                    if (isOn && start === null) {
                        start = entryDate;
                    }
                    else if (!isOn && start !== null) {
                        intervals.push({
                            start,
                            end: entryDate
                        });
                        start = null;
                    }

                    // Handle the case where the last entry is still "on"
                    if (index === (dataset.length - 1) && start !== null) {
                        intervals.push({
                            start,
                            end: entryDate
                        });
                    }
                });

                acc[key] = intervals;

                return acc;
            }, {});

        return transformedData;
    }

    private configureSwimlaneChart() {
        let RELAY_PROPS = null;
        if (this.systemType === SystemType.CARRIER_ELT) {
            RELAY_PROPS = this.eltRelayProps;
        }
        else if (this.systemType === SystemType.ECOBEE) {
            RELAY_PROPS = this.ecobeeRelayProps;
        }
        const relayData = this.transformSwimlaneRelayEventData(this.dataSummary, RELAY_PROPS);
        const dates: Date[] = this.dataSummary?.map((item) => new Date(Date.parse(item.dateTime))) || [];

        this.chartData = {
            dates,
            relayData
        };
    }
}
