import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { HeapService } from 'common/services/heap.service';
import { DateRange } from 'private/app/models/account-admin-program.model';
import { ApplyFiltersOutput, EngineeringInsightsFiltersInput } from 'private/app/models/engineering-insights-filters.model';
import { ENGINEERING_GRAPH_MAX_DATE_TIME_DELTA } from 'private/app/views/connected-portal/constants';
import { eventNames } from 'private/app/views/connected-portal/heap/constants';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';

@Component({
    selector: 'hvac-factory-engineering-insights-filters',
    templateUrl: './factory-engineering-insights-filters.component.html',
    styleUrls: ['./factory-engineering-insights-filters.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, TranslateModule, AsyncPipe, NgIf, NgForOf]
})
export class FactoryEngineeringInsightsFiltersComponent implements OnInit, OnChanges {
    @Output() onApplyFilters: EventEmitter<ApplyFiltersOutput> = new EventEmitter<ApplyFiltersOutput>();
    @Output() onFilterReset = new EventEmitter();

    @Input() isFilterOpen:boolean | null;

    public startDate: number;
    public endDate: number;
    public startDateEndDateDeltaMax = ENGINEERING_GRAPH_MAX_DATE_TIME_DELTA
    public isApplyFiltersDisabled$: Observable<boolean>;
    public hasInvalidDates$ = new BehaviorSubject(false);

    private hasEmptySelection$ = new BehaviorSubject(false);
    private _filterInputs: EngineeringInsightsFiltersInput;

    constructor(private heapService: HeapService) {}

    @Input()
    get filterInputs() {
        return this._filterInputs;
    }

    set filterInputs(value: EngineeringInsightsFiltersInput) {
        this._filterInputs = { ...value };
    }

    ngOnInit() {
        this.startDate = this.filterInputs && this.filterInputs.startDate;
        this.endDate = this.filterInputs && this.filterInputs.endDate;
        this.hasEmptySelection$.next(this.getIsSelectionEmpty());

        this.isApplyFiltersDisabled$ = combineLatest([this.hasEmptySelection$, this.hasInvalidDates$]).pipe(
            map(([hasEmptySelection, hasInvalidDates]) => hasEmptySelection || hasInvalidDates)
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.filterInputs?.currentValue) {
            this.hasEmptySelection$.next(this.getIsSelectionEmpty());
        }
    }

    applyFilters() {
        this.heapService.track(eventNames.handleSystemsOverviewInsightsClick);
        this.onApplyFilters.emit({ ...this.filterInputs });
    }

    resetFilters() {
        this.onFilterReset.emit();
    }

    getUpdatedMinValue(min: number, i: number) {
        this._filterInputs.parameters[i].min = min;
    }

    getUpdatedMaxValue(max: number, i: number) {
        this._filterInputs.parameters[i].max = max;
    }

    setDates($event: DateRange) {
        if (!($event.startDate && $event.endDate)) {
            return;
        }

        if (this.getHasInvalidDates($event)) {
            this.hasInvalidDates$.next(true);
        }
        else {
            this.hasInvalidDates$.next(false);
        }

        this._filterInputs.startDate = new Date($event.startDate).getTime();
        this._filterInputs.endDate = new Date($event.endDate).getTime();
    }

    setRelayValue($event: Event, i:number) {
        this._filterInputs.relays[i].isSelected = ($event.target as HTMLInputElement).checked;

        this.hasEmptySelection$.next(this.getIsSelectionEmpty());
    }

    setParamValue($event: Event, i:number) {
        this._filterInputs.parameters[i].isSelected = ($event.target as HTMLInputElement).checked;

        this.hasEmptySelection$.next(this.getIsSelectionEmpty());
    }

    private getHasInvalidDates(dates: DateRange) {
        if (!(dates.startDate && dates.endDate)) {
            return;
        }

        return (dates.endDate - dates.startDate) > ENGINEERING_GRAPH_MAX_DATE_TIME_DELTA;
    }

    private getIsSelectionEmpty() {
        return [...this._filterInputs.relays, ...this._filterInputs.parameters].some((item) => item.isSelected) === false;
    }
}
