import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataHeader } from 'common/views/parts/parts-subcategory-details/parts-catalog-list/parts-catalog-list.component';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from 'common/services/toast.service';
import { AccountAdminCompanyCreateService } from 'private/app/services/account-admin/account-admin-company-create.service';
import { AccountAdminCompanyUtilsService } from 'private/app/services/account-admin/account-admin-company-utils.service';
import { AccountAdminFormResultMap, AccountAdminStateEntity } from 'private/app/models/account-admin-search';
import { Option } from '../../../../common/components/select/select.component';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
import { AddressLookupService } from 'private/app/services/address-lookup/address-lookup.service';
import { AddressLookupResult, ResultData } from 'private/app/models/address-lookup.model';

export interface AddressLookup {
    address1: string;
    address2: string;
    city: string;
    state: {
        label: string;
        code: string;
    };
    country: {
        label: string;
        code: string;
    };
    postalCode: string;
}

@Component({
    selector: 'hvac-address-lookup',
    templateUrl: './address-lookup.component.html',
    styleUrls: ['./address-lookup.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddressLookupComponent implements OnInit {
    @Input() addressLookup: AddressLookup;
    @Input() visible: boolean;
    @Output() onAddressConfirmation = new EventEmitter<AddressLookup | null>();
    @Output() toastOpenStatus = new EventEmitter<boolean>(false);

    public toastOutlet = 'addressLookupToast';
    address$ = new BehaviorSubject<AddressLookup[]>([]);
    processStatus$ = new BehaviorSubject<boolean>(true);
    spinnerStatus$ = new BehaviorSubject<boolean>(true);
    toastStatus$ = new BehaviorSubject<boolean>(true);
    public companyCreateForm = this.companyCreateService.companyCreateForm;
    public stateProvince: string = '';
    formData: AccountAdminFormResultMap;
    dataHeaders: DataHeader[] = [
        {
            title: this.translate.instant('ADDRESS_LOOKUP.ADDRESS_1'),
            value: 'address1',
            order: null
        },
        {
            title: this.translate.instant('ADDRESS_LOOKUP.ADDRESS_2'),
            value: 'address2',
            order: null
        },
        {
            title: this.translate.instant('ADDRESS_LOOKUP.CITY'),
            value: 'city',
            order: null
        },
        {
            title: this.translate.instant('ADDRESS_LOOKUP.STATE'),
            value: 'state',
            order: null
        },
        {
            title: this.translate.instant('ADDRESS_LOOKUP.COUNTRY'),
            value: 'country',
            order: null
        },
        {
            title: this.translate.instant('ADDRESS_LOOKUP.POSTAL_CODE'),
            value: 'postalCode',
            order: null
        },
        {
            title: this.translate.instant('ADDRESS_LOOKUP.ACTION'),
            value: 'action',
            order: null
        }
    ];

    private bannerType = 'AddressLookupToast';

    constructor(
        private readonly translate: TranslateService,
        private toast: ToastService,
        private commonUtils: CommonUtilsService,
        public accountAdminCompanyUtilsService: AccountAdminCompanyUtilsService,
        public readonly companyCreateService: AccountAdminCompanyCreateService,
        private addressLookupService: AddressLookupService
    ) {
    }

    ngOnInit(): void {
        this.spinnerStatus$.subscribe((status) => {
            if (status === false) {
                this.spinnerStatus$.next(true);
            }
        });

        this.addressLookupService.addressLookUpResult$.subscribe((resultData: AddressLookupResult) => {
            this.processStatus$.next(true);
            if (this.addressLookupService.incorrectProcessStatus.includes(resultData?.Envelope?.Body.ProcessResponse.ProcessResult?.Results.Result.ProcessStatus.text || '')) {
                this.address$.next([{
                    address1: this.addressLookup?.address1.replace(/#\s[A-Z]{2}/g, ''),
                    city: this.addressLookup?.city,
                    country: {
                        label: this.addressLookup?.country.code,
                        code: this.addressLookup?.country.code
                    },
                    state: {
                        label: this.addressLookup?.state.label,
                        code: this.addressLookup?.state.code
                    },
                    postalCode: this.addressLookup?.postalCode
                } as unknown as AddressLookup]);
                this.processStatus$.next(false);

                this.toast.add({
                    content: this.translate.instant('ADDRESS_LOOKUP.NO_MATCHING_ADDRESS_FOUND'),
                    theme: 'warning',
                    id: this.bannerType,
                    closeable: false,
                    autoClose: false,
                    outletName: this.toastOutlet
                });

                this.toastStatus$.next(true);
                this.toastOpenStatus.emit(true);
                this.spinnerStatus$.next(false);

                return;
            }
            const addressDetails = {
                address1: this.addressLookup?.address1,
                address2: this.addressLookup?.address2,
                city: this.addressLookup?.city,
                country: {
                    label: this.addressLookup?.country.code,
                    code: this.addressLookup?.country.code
                },
                state: {
                    label: this.addressLookup?.state.code,
                    code: this.addressLookup?.state.code
                },
                postalCode: this.addressLookup?.postalCode
            } as unknown as AddressLookup;
            const result: ResultData[] | ResultData | null = resultData?.Envelope?.Body.ProcessResponse.ProcessResult?.Results.Result.ResultDataSet.ResultData || null;
            if (result) {
                if (Array.isArray(result)) {
                    this.address$.next([
                        ...result?.map((data) => ({
                            address1: data.Address.DeliveryAddressLines.string.text.replace(/#\s[A-Z]{2}/g, ''),
                            city: data.Address.Locality.string.text,
                            country: {
                                label: this.addressLookup.country.label,
                                code: this.addressLookup.country.code
                            },
                            state: {
                                label: this.addressLookup.state.label,
                                code: this.addressLookup.state.code
                            },
                            postalCode: data.Address.PostalCode.string.text
                        } as unknown as AddressLookup)),
                        ...[addressDetails]
                    ]);
                    this.spinnerStatus$.next(false);
                }
                else {
                    const dataObj = result as ResultData;
                    if (Array.isArray(dataObj.Address.Province.string)) {
                        this.stateProvince = dataObj.Address.Province.string[0].text;
                    }
                    else {
                        this.stateProvince = dataObj.Address.Province.string.text;
                    }

                    this.address$.next([
                        {
                            address1: dataObj.Address.DeliveryAddressLines.string.text.replace(/#\s[A-Z]{2}/g, ''),
                            city: dataObj.Address.Locality.string.text,
                            country: {
                                label: this.addressLookup.country.label,
                                code: this.addressLookup.country.code
                            },
                            state: {
                                label: this.stateProvince,
                                code: this.stateProvince
                            },
                            postalCode: dataObj.Address.PostalCode.string.text
                        } as unknown as AddressLookup,
                        ...[addressDetails]
                    ]);
                    this.spinnerStatus$.next(false);
                }
            }
        });

        this.companyCreateService.formData.subscribe((formData) => {
            this.formData = formData;
            this.companyCreateService.countryOptionData$.next(this.accountAdminCompanyUtilsService.filterCountryArraywithMostUsed(formData.countries) || []);
        });

        this.companyCreateForm.controls.country.valueChanges.subscribe((selectedCountry: string) => {
            if (Array.isArray(selectedCountry)) {
                this.accountAdminCompanyUtilsService.formInputPlaceHolder(selectedCountry[0].value);
                this.getStateListByCountry(selectedCountry[0].value);
            }
            else {
                const countrySelection = this.companyCreateService.countryOptionData$.value.find((country) => country.name === selectedCountry);
                if (countrySelection) {
                    this.accountAdminCompanyUtilsService.formInputPlaceHolder(countrySelection.value);
                    this.getStateListByCountry(countrySelection.value);
                }
            }
            this.companyCreateService.stateOptionData$.next([]);
            this.companyCreateForm.controls.state.setValue('');
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.addressLookup?.currentValue) {
            this.address$.next([]);
            this.addressLookupService.addressDoctorLookUp(changes?.addressLookup?.currentValue);
        }
    }

    userAddress(address: AddressLookup) {
        this.onAddressConfirmation.emit(address);
        this.toast.remove(this.bannerType);
        this.toastOpenStatus.emit(false);
        this.spinnerStatus$.next(true);
    }

    onSubmit() {
        this.onAddressConfirmation.emit(this.addressLookup);
    }

    checkAddress() {
        this.addressLookup = {
            address1: this.companyCreateForm.controls.address1.value,
            address2: this.companyCreateForm.controls.address2.value,
            city: this.companyCreateForm.controls.city.value,
            country: {
                label: this.companyCreateForm.controls.country.value[0].name,
                code: this.companyCreateForm.controls.country.value[0].value
            },
            state: {
                label: this.companyCreateForm.controls.state.value[0].name,
                code: this.companyCreateForm.controls.state.value[0].value
            },
            postalCode: this.companyCreateForm.controls.postalCode.value
        } as unknown as AddressLookup;

        this.addressLookupService.addressDoctorLookUp(this.addressLookup);
    }

    onCancel() {
        this.onAddressConfirmation.emit(null);
        this.toast.remove(this.bannerType);
        this.toastOpenStatus.emit(false);
        this.spinnerStatus$.next(true);
    }

    onCountryChange(option: Option[]) {
        this.companyCreateService.company.country = Array.isArray(option) ? this.accountAdminCompanyUtilsService.getCountryCodeByCountry(option[0].name, this.companyCreateService.countryOptionData$.value) : '';
    }

    onStateChange(option: Option[]) {
        this.companyCreateService.company.state = Array.isArray(option) ? this.accountAdminCompanyUtilsService.getRegionCodeByState(option[0].name, this.companyCreateService.stateOptionData$.value) : '';
    }

    private getStateListByCountry(selectedCountry: string) {
        this.commonUtils.getStateEntityByCountry(selectedCountry).subscribe((countryList) => {
            const optionList: Option[] = [];
            countryList.forEach((state: AccountAdminStateEntity) => {
                optionList.push({
                    name: state.name,
                    value: state.code
                });
            });
            this.companyCreateService.stateOptionData$.next(optionList);
        });
    }
}
