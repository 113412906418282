<section class="hvac-manage-counties-header">
    <hvac-content-header [data]="breadCrumbData"></hvac-content-header>
</section>

<div class="hvac-manage-counties">
    <section class="hvac-content-container">
        <div class="hvac-tab-container">
            <h1 class="hvac-h4"> {{ hvacpCompanyName }}</h1>
            <button [hvacButton]="'text'" (click)="backToCompanyDetails()">
                <span>{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.BACK_TO_COMPANY_DETAILS' | translate }}</span>
            </button>
        </div>

        <section class="hvac-manage-counties-content">
            <div class="hvac-manage-counties-container">
                <div class="hvac-manage-counties-search-container">
                    <p class="hvac-content-header-sub-title">
                        <span>{{ ( showAssignedCounties ? 'ACCOUNT_ADMIN.MANAGE_COUNTIES.ASSIGNED_COUNTIES' : 'ACCOUNT_ADMIN.MANAGE_COUNTIES.AVAILABLE_COUNTIES' ) | translate  }}</span>
                    </p>
                    <div class="hvac-row">
                        <button class="hvac-available-assigned-counties-button" [ngClass]="{ 'active': !showAssignedCounties}" hvacButton="default" (click)="toggleTable(availableCountiesTable)">
                            <span>{{ 'ACCOUNT_ADMIN.MANAGE_COUNTIES.AVAILABLE_COUNTIES' | translate }}</span>
                            <span *ngIf="(availableCountiesArray$ | async)?.length" class="hvac-button-badge">{{ (availableCountiesArray$ | async)?.length }}</span>
                        </button>
                        <button class="hvac-available-assigned-counties-button" [ngClass]="{ 'active': showAssignedCounties}" hvacButton="default" (click)="toggleTable(assignedCountiesTable)">
                            <span>{{ 'ACCOUNT_ADMIN.MANAGE_COUNTIES.ASSIGNED_COUNTIES' | translate }}</span>
                            <span *ngIf="(assignedCountiesArray$ | async)?.length" class="hvac-button-badge">{{ (assignedCountiesArray$ | async)?.length }}</span>
                        </button>
                    </div>
                </div>

                <div class="hvac-manage-counties-table-container">
                    <div class="hvac-manage-counties-table">
                        <hvac-app-manage-counties-table [hvacCompanyId]="hvacCompanyId" [emptyCountiesList]="emptyCountyList" [tableTypeIdentifier]="showAssignedCounties ? assignedCountiesTable : availableCountiesTable" [loading]="loading"
                        (onSelectAllClick)="onSelectAllCheckboxSelection($event)" (selectedCountyArray)="onSelectedCounties($event)"
                        (onSave)="saveAction()"
                        [countiesArray]="(showAssignedCounties ? (assignedCountiesArray$ | async) : (availableCountiesArray$ | async) )|| []"></hvac-app-manage-counties-table>
                    </div>
                </div>
            </div>
            <utc-loading-spinner *ngIf="loading"></utc-loading-spinner>
        </section>
    </section>
</div>