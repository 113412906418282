<div class="hvac-create-user-review-user-header">
    <h1 class="hvac-h4">{{
        'ACCOUNT_ADMIN.CREATE_USER_PAGE.REVIEW_USER_ALREADY_EXIST_HEADING' | translate }}</h1>
    <h1 class="hvac-p-sm">{{
        'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.COMPANY_DETAILS' | translate }}</h1>
    <div class="hvac-company-info">
        <h1 class="hvac-p-sm hvac-p-company-desc">{{
            'ACCOUNT_ADMIN.COMPANY_ID' | translate }}</h1>
        <h1 class="hvac-p-sm">{{company.hvacCompanyId}}</h1>
    </div>
    <div class="hvac-company-info">
        <h1 class="hvac-p-sm hvac-p-company-desc">{{
            'ACCOUNT_ADMIN.COMPANY_NAME' | translate }}</h1>
        <h1 class="hvac-p-sm">{{company.companyName}}</h1>
    </div>

</div>
<p class="hvac-phvac-account-admin-results-count">
    <span *ngIf="accountAdminCompanyUtilsService.totalUsers">{{ 'ACCOUNT_ADMIN.RESULTS_COUNT' | translate: { totalCount: accountAdminCompanyUtilsService.totalUsers } }}</span>
</p>
    <hvac-table class="hvac-program-table-wrap" [theme]="'zebra'" [responsive]="true">
        <thead>
            <tr>
                <td *ngFor="let item of dataHeaders">
                    <button class="hvac-table-sort-btn">
                        {{item.title}}
                        <span class="hvac-table-list-sort" [ngClass]="{
                            'hvac-table-list-sort-show': item.order,
                            'column-ascending': item.order === 'asc'
                        }">
                            <utc-svg src="assets/icons/chevron-down.svg"></utc-svg>
                        </span>
                    </button>
                </td>
            </tr>
        </thead>
        <tbody>
                <tr *ngFor="let user of usersData; let row=even;">
                    <td [attr.data-label]="dataHeaders[0].title">
                        {{user.lastName}}
                    </td>
                    <td [attr.data-label]="dataHeaders[1].title">
                        {{user.firstName}}
                    </td>
                    <td [attr.data-label]="dataHeaders[2].title">
                        {{user.userID}}
                    </td>
                    <td [attr.data-label]="dataHeaders[3].title">
                        {{user.email}}
                    </td>
                    <td [attr.data-label]="dataHeaders[4].title">
                        {{user.userAddress1}}
                    </td>
                    <td [attr.data-label]="dataHeaders[5].title">
                        {{user.userPhone}}
                    </td>
                    <td [attr.data-label]="dataHeaders[6].title">
                        {{user.userCity}}
                    </td>
                    <td [attr.data-label]="dataHeaders[7].title">
                        {{user.userPostalCode}}
                    </td>
                    <td [attr.data-label]="dataHeaders[8].title">
                        {{user.userCountry}}
                    </td>
                </tr>
        </tbody>

    </hvac-table>
    <p class="hvac-admin-programs-no-results" *ngIf="!(usersData)?.length && !isLoading">{{ 'ACCOUNT_ADMIN.NO_RESULTS' | translate }}</p>
    <utc-loading-spinner spinnerSize="small" *ngIf="isLoading"></utc-loading-spinner>
    <div class="hvac-divider"></div>
    <div class="footer-controls">
        <hvac-pagination [totalPages]="accountAdminCompanyUtilsService.totalPages" [currentPage]="accountAdminCompanyUtilsService.userListCurrentPage"
            (pageChange)="this.accountAdminCompanyUtilsService.handleCreateUserListPageChange($event)">
        </hvac-pagination>
        <div class="display-flex self-adjust-end">
            <button class="hvac-edit-button footer-control-button" type="button" [hvacButton]="'default'" (click)="onClickCreateUserChangePage(false)">
                <span>{{ "FOOTER.BACK_TO_PREVIOUS_STEP" | translate }}</span>
            </button>
            <button type="button" (click)="onClickCreateUserChangePage(true)" class="hvac-edit-button" hvacButton="primary">
                {{ 'FOOTER.CONTINUE' | translate}}
            </button>
        </div>
    </div>
    