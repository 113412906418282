import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'common/app-constants';
import { Option } from 'common/components/select/select.component';
import { ContentHeadingData } from 'common/models/content-heading';
import { ToastService } from 'common/services/toast.service';
import { AccountAdminProgramEditData, Brand, GMTTimeFormattingOptions, ProgramDetails, DateRange } from 'private/app/models/account-admin-program.model';
import { AccountAdminApiService } from 'private/app/services/account-admin/account-admin-api.service';
import { AccountAdminProgramService } from 'private/app/services/account-admin/account-admin-program.service';
import { LanguageTranslationService } from 'common/services/language-translation.service';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { GMTTimeConversionUtilsService } from 'common/services/gmt-time-transformation.service';
import { DateUtilsService } from 'common/services/date-utils-service';
import { ProgramEnrollmentDateUtilsService } from 'private/app/services/account-admin/program-enrollment-date-utils-service';
import { enumDatePickerField } from 'common/components/date-range-picker/date-range-picker.component';
import { HttpErrorResponse } from '@angular/common/http';

export interface PeriodErrorValidationResult {
    fieldName?: string;
    genericErrorMessage: string;
    startDateFieldErrorMessage: string;
    endDateFieldErrorMessage: string
}

@Component({
    selector: 'hvac-edit-program',
    templateUrl: './edit-program.component.html',
    styleUrls: ['./edit-program.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EditProgramComponent implements OnInit, OnDestroy {
    public maxProgramDescriptionLimit: number = 500;
    public breadCrumbData: ContentHeadingData;
    public isLoading = true;
    public isRequesting = false;
    public isLocatorDateSet: boolean = true;
    public programManagers: Option[];
    public programEditForm: FormGroup;
    public brandOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public programAdminData: AccountAdminProgramEditData;
    public isFormDataChanged: boolean;
    public programDate$: BehaviorSubject<DateRange>
    public programEndDate$: BehaviorSubject<number> = new BehaviorSubject<number>((new Date()).setMonth(new Date().getMonth()));
    public programAdminFullAccess: boolean;
    public programPeriodErrorValidationResult: PeriodErrorValidationResult = this.programEnrollmentDateUtilsService.getEmptyPeriodErrorValidationResult('programPeriodFieldName');
    public enrollmentPeriodErrorValidationResult: PeriodErrorValidationResult = this.programEnrollmentDateUtilsService.getEmptyPeriodErrorValidationResult('enrollmentPeriodFieldName');
    public locatorPeriodErrorValidationResult: PeriodErrorValidationResult = this.programEnrollmentDateUtilsService.getEmptyPeriodErrorValidationResult('locatorPeriodDateName');
    public programDateControls: [FormControl, FormControl];
    public enrollmentDateControls: [FormControl, FormControl];
    public locatorDateControls: [FormControl, FormControl];
    private bannerType = 'ProgramEditBanner';
    private buttonTimer: NodeJS.Timeout;

    constructor(
        public translate: TranslateService,
        public accountAdminProgramService: AccountAdminProgramService,
        private languageTranslationService: LanguageTranslationService,
        private route: ActivatedRoute,
        private readonly accountAdminApiService: AccountAdminApiService,
        private readonly toastService: ToastService,
        private router: Router,
        private dateUtils: DateUtilsService,
        private gmtTimeConversionUtilsService: GMTTimeConversionUtilsService,
        private programEnrollmentDateUtilsService: ProgramEnrollmentDateUtilsService
    ) {
    }

    get areDateFieldsInError(): boolean {
        return this.programEnrollmentDateUtilsService.areDateFieldsInError([this.programPeriodErrorValidationResult, this.enrollmentPeriodErrorValidationResult, this.locatorPeriodErrorValidationResult]);
    }

    ngOnInit() {
        this.programDate$ = new BehaviorSubject<DateRange>({
            startDate: this.gmtTimeConversionUtilsService.getCurrentGMTTime({ formatTimeAs: 'StartOfDay' }),
            endDate: this.gmtTimeConversionUtilsService.getCurrentGMTTime({ formatTimeAs: 'EndOfDay' })
        });
        this.programAdminFullAccess = (this.route.snapshot.data['programAccess'] as IdmUserRoles[])?.includes(IdmUserRoles.MARKETING_PROGRAM_ADMIN) ||
            (this.route.snapshot.data['programAccess'] as IdmUserRoles[])?.includes(IdmUserRoles.CMNA_MARKETING_PROGRAM_ADMIN);
        this.languageTranslationService.translationsLoaded().subscribe(() => {
            this.breadCrumbData = {
                Content: {
                    title: this.setContent(),
                    pageTitle: this.setContent()
                },
                breadCrumbs: [
                    {
                        title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_1'),
                        url: '/admin'
                    },
                    {
                        title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_2'),
                        url: '/Admin/Program'
                    },
                    {
                        title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_3'),
                        url: '/Admin/Program/search-program/search'
                    }
                ]
            };
            this.accountAdminProgramService.setProgramAdminBreadCrumData(this.breadCrumbData);
        });
        this.accountAdminApiService.getAccountAdminProgramBrands().subscribe((brands: Brand[]) => {
            this.brandOptionData$.next(brands.map((brand: Brand) => ({
                name: brand.name,
                value: brand.id
            })));
        });
        this.programEditForm = this.accountAdminProgramService.programEditForm;
        this.programDateControls = [this.accountAdminProgramService.programEditForm.controls.programPeriodStart, this.accountAdminProgramService.programEditForm.controls.programPeriodEnd];
        this.enrollmentDateControls = [this.accountAdminProgramService.programEditForm.controls.enrollmentStartDate, this.accountAdminProgramService.programEditForm.controls.enrollmentEndDate];
        this.locatorDateControls = [this.accountAdminProgramService.programEditForm.controls.locatorStart, this.accountAdminProgramService.programEditForm.controls.locatorEnd];
        if (this.route.snapshot.params.programId) {
            this.getAccountAdminProgramEditData(this.route.snapshot.params.programId).subscribe((programAdminData) => {
                this.isLoading = false;
                this.accountAdminProgramService.updateUI(programAdminData);
                this.programAdminData = programAdminData;
                this.programManagers = programAdminData.programManagers.map((manager) => ({
                    name: `${`${manager.firstName.toUpperCase()} ${manager.lastName.toUpperCase()}`} `,
                    value: manager.userId
                }));
                this.updateProgramStartEndDates(programAdminData.programDetails.programStartDate, programAdminData.programDetails.programEndDate);
            }, (error: HttpErrorResponse) => this.toastService.raiseDefaultErrorToast('EditProgramComponent-ngOnInit', error.message));
            this.programDate$.subscribe((programDate) => {
                if (!(programDate.startDate && programDate.endDate)) {
                    return;
                }
                if (this.accountAdminProgramService.programAdminData) {
                    this.accountAdminProgramService.programAdminData.programDetails.programStartDate = this.dateUtils.formatDate(programDate.startDate);
                    this.accountAdminProgramService.programAdminData.programDetails.programEndDate = this.dateUtils.formatDate(programDate.endDate);
                    this.accountAdminProgramService.programAdminData.programStart = programDate.startDate;
                    this.accountAdminProgramService.programAdminData.programEnd = programDate.endDate;
                }
            });
        }
        this.programEditForm.controls.enrollmentWorkflow.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.accountAdminProgramService.programAdminData.programDetails['enrollmentWorkflow'] = {
                    name: value[0].name,
                    id: value[0].value
                };
                this.programEditForm.controls.enrollmentForm.setValue(this.accountAdminProgramService.programEnrollmentFormOptions[0].name);
                this.accountAdminProgramService.programAdminData.programDetails['enrollmentForm'] = {
                    name: this.accountAdminProgramService.programEnrollmentFormOptions[0].name,
                    id: this.accountAdminProgramService.programEnrollmentFormOptions[0].value
                };
                this.programEditForm.controls.finalApprovedBy.setValue(this.accountAdminProgramService.programFinalApprover[0].name);
                this.accountAdminProgramService.programAdminData.programDetails['finalApprovedBy'] = {
                    desc: this.accountAdminProgramService.programFinalApprover[0].name,
                    id: this.accountAdminProgramService.programFinalApprover[0].value
                };
            }
        });
        this.programEditForm.controls.enrollmentForm.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.accountAdminProgramService.programAdminData.programDetails['enrollmentForm'] = {
                    name: value[0].name,
                    id: value[0].value
                };
            }
        });
        this.programEditForm.controls.finalApprovedBy.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.accountAdminProgramService.programAdminData.programDetails['finalApprovedBy'] = {
                    desc: value[0].name,
                    id: value[0].value
                };
            }
        });
        this.programEditForm.controls.locatorIcon.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                if (value[0].value.length === 0) {
                    delete this.programAdminData.programDetails['locatorIcon'];

                    return;
                }
                this.accountAdminProgramService.programAdminData.programDetails['locatorIcon'] = {
                    description: value[0].name,
                    id: value[0].value
                };
            }
        });
        this.programEditForm.markAsPristine();
    }

    onDateError([errorField, fieldName, errorMessage]: [enumDatePickerField, string, string]): void {
        switch (fieldName) {
            case this.programPeriodErrorValidationResult.fieldName:
                this.programEnrollmentDateUtilsService.setDateFieldError(this.programPeriodErrorValidationResult, errorField, errorMessage);
                break;
            case this.enrollmentPeriodErrorValidationResult.fieldName:
                this.programEnrollmentDateUtilsService.setDateFieldError(this.enrollmentPeriodErrorValidationResult, errorField, errorMessage);
                break;
            case this.locatorPeriodErrorValidationResult.fieldName:
                this.programEnrollmentDateUtilsService.setDateFieldError(this.locatorPeriodErrorValidationResult, errorField, errorMessage);
                break;
            default:
                throw new Error('Invalid case');
        }
    }

    setContent(): string {
        if (this.router.url.includes('/clone/')) {
            return this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.CLONE_PROGRAM');
        }
        else if (this.programAdminFullAccess) {
            return this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.EDIT_PROGRAMS');
        }

        return this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.VIEW_PROGRAM');
    }

    setProgramPeriod(programDates: DateRange) {
        this.programEnrollmentDateUtilsService.setProgramPeriod(
            programDates,
            this.programDate$,
            this.programAdminData,
            this.enrollmentPeriodErrorValidationResult,
            this.locatorPeriodErrorValidationResult
        );
    }

    setEnrollmentPeriod(enrollmentDates: DateRange) {
        this.programEnrollmentDateUtilsService.setEnrollmentPeriod(
            enrollmentDates,
            this.programDate$,
            this.accountAdminProgramService,
            this.enrollmentPeriodErrorValidationResult
        );
    }

    setLocatorPeriod(locatorDates: DateRange) {
        this.isLocatorDateSet = ((typeof locatorDates.startDate === 'number' && typeof locatorDates.endDate === 'number') || (typeof locatorDates.startDate === 'undefined' && typeof locatorDates.endDate === 'undefined'));
        this.programEnrollmentDateUtilsService.setLocatorPeriod(
            locatorDates,
            this.programDate$,
            this.accountAdminProgramService,
            this.locatorPeriodErrorValidationResult
        );
    }

    dateToTimeStamp(date: string, options: GMTTimeFormattingOptions) {
        if (!date) {
            // eslint-disable-next-line no-undefined
            return undefined;
        }

        return this.gmtTimeConversionUtilsService.getGMTTimeFromDate(date, options);
    }

    showToastBar(response: ProgramDetails) {
        this.toastService.add({
            content: this.translate.instant(this.router.url.includes('/clone/') ? 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_CREATE_SUCCESS' : 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_UPDATE_SUCCESS', { programName: response.name }),
            theme: 'success',
            id: this.bannerType,
            closeable: true,
            autoClose: true
        });
    }

    onSubmit(event: UntypedFormGroup) {
        this.isRequesting = true;
        this.accountAdminProgramService.chnagesMadeInProgramManagers = true;
        if (this.router.url.includes('/clone/')) {
            this.accountAdminApiService.createAccountAdminProgramDetails(this.accountAdminProgramService.getProgramAdminPostData(event.value)).subscribe((response) => {
                this.isRequesting = false;
                this.accountAdminProgramService.smoothScrollToTop();
                if (response.id) {
                    this.showToastBar(response);
                }

                this.buttonTimer = setTimeout(() => this.router.navigate(['/Admin/Program/search-program/search']), AppConstants.timeOutNavigation);
            });
        }
        else {
            this.accountAdminApiService.postAccountAdminProgramDetails(this.accountAdminProgramService.getProgramAdminPostData(event.value)).subscribe((response) => {
                this.isRequesting = false;
                this.accountAdminProgramService.smoothScrollToTop();
                if (response.id) {
                    this.showToastBar(response);
                }

                this.buttonTimer = setTimeout(() => this.router.navigate(['/Admin/Program/search-program/search']), AppConstants.timeOutNavigation);
            });
        }
    }

    onCancel() {
        this.router.navigate(['/Admin/Program/search-program/search']);
    }

    ngOnDestroy() {
        if (this.buttonTimer) {
            clearTimeout(this.buttonTimer);
        }
    }

    private getAccountAdminProgramEditData(programId: string): Observable<AccountAdminProgramEditData> {
        return forkJoin([
            this.accountAdminApiService.getAccountAdminProgramsById(programId),
            this.accountAdminApiService.getAccountAdminProgramEligibilityCriteria(),
            this.accountAdminApiService.getAccountAdminProgramBrands(),
            this.accountAdminApiService.getAccountAdminProgramsLocatorIcon(),
            this.accountAdminApiService.getAccountAdminProgramsManagers()
        ]).pipe(
            map((res) => this.accountAdminProgramService.mergeProgramAdminData(res))
        );
    }


    private updateProgramStartEndDates(programStart: string, programEnd: string) {
        this.programDate$.next({
            startDate: this.gmtTimeConversionUtilsService.getGMTTimeFromDate(programStart, { formatTimeAs: 'StartOfDay' }),
            endDate: this.gmtTimeConversionUtilsService.getGMTTimeFromDate(programEnd, { formatTimeAs: 'EndOfDay' })
        });
    }
}
