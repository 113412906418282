import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { ContentHeadingData } from 'common/models/content-heading';
import { CompanyCode } from 'private/app/models/company.model';
import { TranslateService } from '@ngx-translate/core';
import { ConnectedPortalUserService } from 'private/app/services/connected-portal/connected-portal-user.service';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { ApiResponseCode, MISSING_ROUTE_PARAMS_ERROR } from '../../constants';
import { ErrorService } from 'common/content/services/error.service';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { BrandingState } from 'common/store/branding/branding.state';
import { Select } from '@ngxs/store';
import { ConnectedPortalModule } from 'private/app/views/connected-portal/connected-portal.module';
import { ContentModule } from 'common/content/content.module';
import { AsyncPipe, NgIf } from '@angular/common';
import { OpportunityReportCardsComponent } from 'private/app/views/connected-portal/dealer/dealer-opportunities/components/opportunity-report-cards/opportunity-report-cards.component';
import { ConnectedPortalLayoutContainerComponent } from '../../components/connected-portal-layout-container/connected-portal-layout-container.component';

interface OpportunityCardData {
    dealerId: string;
    companyCode: CompanyCode;
    brand: string;
    inVppTerritory: string;
}

@Component({
    selector: 'hvac-dealer-opportunities',
    templateUrl: './dealer-opportunities.component.html',
    styleUrls: ['./dealer-opportunities.component.scss'],
    imports: [
        ConnectedPortalModule,
        ContentModule,
        NgIf,
        AsyncPipe,
        OpportunityReportCardsComponent,
        ConnectedPortalLayoutContainerComponent
    ],
    standalone: true
})
export class DealerOpportunitiesComponent implements OnInit {
    @Select(BrandingState.themeBrand) brand$: Observable<string>;

    public readonly CompanyCode = CompanyCode;

    public dealerId$: Observable<string>;
    public companyCode$: Observable<CompanyCode>;
    public inVppTerritory$: Observable<string>;
    public breadcrumbs$: Observable<ContentHeadingData>;
    public opportunityCardData$: Observable<OpportunityCardData>;

    constructor(
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private connectedPortalUserService: ConnectedPortalUserService,
        private dealersService: DealersService,
        private errorService: ErrorService
    ) { }

    ngOnInit(): void {
        if (!this.route.parent) {
            this.errorService.errorStatus$.next(ApiResponseCode.NOT_FOUND);

            return;
        }

        const routeData$ = this.route.parent.paramMap.pipe(
            map((params) => {
                const dealerId = params.get('id');

                if (dealerId) {
                    return { dealerId };
                }

                throw new Error(MISSING_ROUTE_PARAMS_ERROR);
            }),
            catchError(() => {
                this.errorService.errorStatus$.next(ApiResponseCode.NOT_FOUND);

                return EMPTY;
            })
        );

        this.dealerId$ = routeData$.pipe(
            map(({ dealerId }) => dealerId),
            shareReplay()
        );

        this.companyCode$ = this.connectedPortalUserService.getCompanyCode().pipe(
            map((code) => code as CompanyCode),
            shareReplay()
        );

        this.inVppTerritory$ = this.dealerId$.pipe(
            switchMap((dealerId) => this.dealersService.queryDealerById(dealerId).pipe(
                map((data) => data.data.inVppTerritory)
            ))
        );

        this.opportunityCardData$ = combineLatest([this.dealerId$, this.companyCode$, this.brand$, this.inVppTerritory$]).pipe(
            map(([dealerId, companyCode, brand, inVppTerritory]) => ({
                companyCode,
                dealerId,
                brand,
                inVppTerritory
            }))
        );

        this.breadcrumbs$ = this.dealerId$.pipe(
            switchMap((dealerId) => this.dealersService.queryDealerById(dealerId).pipe(
                map(({ data }) => this.getBreadcrumbData(dealerId, data.name)),
                startWith(this.getBreadcrumbData(dealerId))
            ))
        );
    }

    private getBreadcrumbData(dealerId: string, dealerName?: string): ContentHeadingData {
        return {
            Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.OPPORTUNITIES') },
            breadCrumbs: [
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                    url: '/connected-portal'
                },
                {
                    title: dealerName ? dealerName : this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DEALER'),
                    url: `/connected-portal/dealers/${dealerId}`
                }
            ]
        };
    }
}
