import { Injectable } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppConstants } from 'common/app-constants';
import { createAccountEmailInputValidator } from 'common/utils/createAccountValidators';
import { Company } from 'private/app/models/account-admin-search';
import { inputValidator, coordinateValidator } from './account-admin-edit-form.service';

export interface AccountAdminParentForm {
    accountManager: FormControl;
    address1: FormControl;
    brand: FormControl;
    city: FormControl;
    companyType: FormControl;
    readonlyCompanyType: FormControl;
    entityType: FormControl;
    distributorBranch: FormControl;
    companyName: FormControl;
    companyId: FormControl;
    contactType: FormControl;
    country: FormControl;
    email: FormControl;
    firstName: FormControl;
    fax: FormControl;
    lastName: FormControl;
    id: FormControl;
    locationBranch: FormControl;
    locationMain: FormControl;
    payer: FormControl;
    cin: FormControl;
    hvp: FormControl;
    phone: FormControl;
    priceGroup: FormControl;
    priceList: FormControl;
    relationshipType: FormControl;
    rsm: FormControl;
    shipTo: FormControl;
    soldTo: FormControl;
    title: FormControl;
    region: FormControl;
    statusActive: FormControl;
    statusInactive: FormControl;
    tsm: FormControl;
    postalCode: FormControl;
    pricingAnal: FormControl;
    parentCompanyID: FormControl;
    parentCompanyName: FormControl;
    createdDate: FormControl;
    distributorID: FormControl;
    soldToNumber: FormControl;
    status: FormControl;
    type: FormControl;
    tierLevel: FormControl;
    startDate: FormControl;
    endDate: FormControl;
    statusPendingApproval: FormControl;
    statusProspect: FormControl;
    prsm: FormControl;
    distributorCompanyID: FormControl;
}

@Injectable({ providedIn: 'root' })
export class AccountAdminFormFieldService {
    public emptyCompanyInitialization: Company = {
        id: '',
        address: {
            addressLine1: '',
            city: '',
            regionCode: '',
            countryCode: '',
            postalCode: ''
        },
        hvacCompanyId: '',
        parentCompany: {
            id: '',
            name: ''
        },
        soldToCustomerId: '',
        shipToCustomerId: '',
        payerCustomerId: '',
        companyType: {
            code: '',
            name: ''
        },
        companyCategory: {
            code: '',
            name: ''
        },
        name: '',
        emailAddress: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        phoneNumber: '',
        faxNumber: 0,
        notes: '',
        brands: [],
        companyServices: [],
        brandFamily: '',
        tollFreeNumber: 0,
        url: '',
        searchRadius: '',
        contractorLicense: '',
        divRegNum: '',
        language: [],
        mobileNum: 0,
        operatingTime: '',
        warrantyType: '',
        c10Parent: '',
        c10Child: '',
        storeNumber: '',
        flagForPOS: false,
        flagForDC: false,
        companyContacts: [],
        ownershipType: '',
        isActive: false,
        legalEntity: false,
        isPrimaryAccount: false,
        isAssignable: false,
        priceListType: '',
        includeInDistributorLocator: false,
        priceGroup: '',
        latitude: '',
        longitude: '',
        salesOfficeNumber: '',
        lastModifiedBy: '',
        lastModifiedDate: '',
        dealerLocatorBrands: '',
        brandedRelationships: [],
        pricingWarrantyRelationships: []
    }

    public accountAdminParentFormFields : FormGroup<AccountAdminParentForm> = new FormGroup({
        accountManager: new FormControl(''),
        address1: new FormControl(''),
        brand: new FormControl(''),
        city: new FormControl(''),
        companyType: new FormControl(''),
        readonlyCompanyType: new FormControl(''),
        entityType: new FormControl(''),
        distributorBranch: new FormControl(''),
        companyName: new FormControl(''),
        companyId: new FormControl(''),
        contactType: new FormControl(''),
        country: new FormControl(''),
        email: new FormControl(''),
        firstName: new FormControl(''),
        fax: new FormControl(''),
        lastName: new FormControl(''),
        id: new FormControl(''),
        locationBranch: new FormControl(false),
        locationMain: new FormControl(false),
        payer: new FormControl(''),
        cin: new FormControl(''),
        hvp: new FormControl(''),
        phone: new FormControl(''),
        priceGroup: new FormControl(''),
        priceList: new FormControl(''),
        relationshipType: new FormControl(''),
        rsm: new FormControl(''),
        shipTo: new FormControl(''),
        soldTo: new FormControl(''),
        title: new FormControl(''),
        region: new FormControl(''),
        statusActive: new FormControl(false),
        statusInactive: new FormControl(false),
        tsm: new FormControl(''),
        postalCode: new FormControl(''),
        pricingAnal: new FormControl(''),
        parentCompanyID: new FormControl(''),
        parentCompanyName: new FormControl(''),
        createdDate: new FormControl(''),
        distributorID: new FormControl(''),
        soldToNumber: new FormControl(''),
        status: new FormControl(''),
        type: new FormControl(''),
        tierLevel: new FormControl(''),
        startDate: new FormControl(''),
        endDate: new FormControl(''),
        statusPendingApproval: new FormControl(''),
        statusProspect: new FormControl(''),
        prsm: new FormControl(''),
        distributorCompanyID: new FormControl('')
    });


    public companyDetailsForm = new UntypedFormGroup({
        companyName: new UntypedFormControl('', [Validators.required, Validators.maxLength(200)]),
        companyCategory: new UntypedFormControl(''),
        companyType: new UntypedFormControl(''),
        companyId: new UntypedFormControl(''),
        companyUrl: new UntypedFormControl('', Validators.pattern(AppConstants.validUrlRegex)),
        parentCompany: new UntypedFormControl(''),
        soldTo: new UntypedFormControl('', [inputValidator(10, false)]),
        shipTo: new UntypedFormControl('', [inputValidator(10, false), Validators.pattern(AppConstants.validNumberRegex)]),
        payer: new UntypedFormControl('', [inputValidator(10, false)]),
        priceList: new UntypedFormControl('', [inputValidator(2, false)]),
        priceGroup: new UntypedFormControl('', [inputValidator(2, false)]),
        contLic: new UntypedFormControl(''),
        pricingAnalyst: new UntypedFormControl(''),
        address1: new UntypedFormControl('', [inputValidator(200, false)]),
        address2: new UntypedFormControl('', [inputValidator(200, false)]),
        city: new UntypedFormControl('', [inputValidator(100, false)]),
        state: new UntypedFormControl(''),
        postalCode: new UntypedFormControl('', [inputValidator(20, false)]),
        country: new UntypedFormControl(''),
        phone: new UntypedFormControl('', [inputValidator(20, false), Validators.pattern(AppConstants.validPhoneRegex)]),
        mobile: new UntypedFormControl('', [inputValidator(20, false), Validators.pattern(AppConstants.validPhoneRegex)]),
        tollFree: new UntypedFormControl('', [inputValidator(20, false), Validators.pattern(AppConstants.validPhoneRegex)]),
        fax: new UntypedFormControl('', [inputValidator(20, false), Validators.pattern(AppConstants.validPhoneRegex)]),
        email: new UntypedFormControl('', [createAccountEmailInputValidator(101, false, AppConstants.validEmailRegex)]),
        rsm: new UntypedFormControl(''),
        partRSM: new UntypedFormControl(''),
        accountManagers: new UntypedFormControl(''),
        partAM: new UntypedFormControl(''),
        tsm: new UntypedFormControl(''),
        location: new UntypedFormControl(''),
        distributor: new UntypedFormControl(''),
        services: new UntypedFormControl(''),
        statusActive: new UntypedFormControl(false),
        statusInactive: new UntypedFormControl(false),
        hoursOfOperation: new UntypedFormControl(''),
        notes: new UntypedFormControl('', [Validators.maxLength(1000)]),
        c10Parent: new UntypedFormControl(''),
        c10Child: new UntypedFormControl(''),
        storeNumber: new UntypedFormControl(''),
        flagForPOS: new UntypedFormControl(''),
        flagForDC: new UntypedFormControl(''),
        searchRadius: new UntypedFormControl('', [Validators.required, Validators.pattern(AppConstants.validNumberRegex)]),
        divRegNumber: new UntypedFormControl('', [inputValidator(10, false)]),
        language: new UntypedFormControl(''),
        ownership: new UntypedFormControl(''),
        claimType: new UntypedFormControl(''),
        showOnDistributorLocator: new UntypedFormControl(''),
        latitude: new UntypedFormControl('', [coordinateValidator(90, -90, false)]),
        longitude: new UntypedFormControl('', [coordinateValidator(180, -180, false)]),
        locatorName: new UntypedFormControl('', [inputValidator(15, false)]),
        salesOfficeNumber: new UntypedFormControl(''),
        // IT SUper Admin
        inventoryAPI: new UntypedFormControl(''),
        regionCode: new UntypedFormControl(''),
        customerUserName: new UntypedFormControl(''),
        customerGUID: new UntypedFormControl('')

    });

    public companyCreateForm = new UntypedFormGroup({
        companyName: new UntypedFormControl('', [Validators.required, Validators.maxLength(200)]),
        companyCategory: new UntypedFormControl(''),
        companyType: new UntypedFormControl(''),
        companyId: new UntypedFormControl(''),
        companyUrl: new UntypedFormControl('', Validators.pattern(AppConstants.validUrlRegex)),
        parentCompany: new UntypedFormControl(''),
        brands: new UntypedFormControl('', [Validators.required]),
        soldTo: new UntypedFormControl('', [inputValidator(10, false)]),
        shipTo: new UntypedFormControl('', [inputValidator(10, false), Validators.pattern(AppConstants.validNumberRegex)]),
        payer: new UntypedFormControl('', [inputValidator(10, false)]),
        priceList: new UntypedFormControl('', [inputValidator(2, false)]),
        priceGroup: new UntypedFormControl('', [inputValidator(2, false)]),
        contLic: new UntypedFormControl(''),
        address1: new UntypedFormControl('', [inputValidator(200, false), Validators.required]),
        address2: new UntypedFormControl('', [inputValidator(200, false)]),
        city: new UntypedFormControl('', [inputValidator(100, false), Validators.required]),
        state: new UntypedFormControl('', [Validators.required]),
        postalCode: new UntypedFormControl('', [inputValidator(20, false), Validators.required]),
        country: new UntypedFormControl('', [Validators.required]),
        phone: new UntypedFormControl('', [inputValidator(20, false), Validators.pattern(AppConstants.validPhoneRegex)]),
        mobile: new UntypedFormControl('', [inputValidator(20, false), Validators.pattern(AppConstants.validPhoneRegex)]),
        tollFree: new UntypedFormControl('', [inputValidator(20, false), Validators.pattern(AppConstants.validPhoneRegex)]),
        fax: new UntypedFormControl('', [inputValidator(20, false), Validators.pattern(AppConstants.validPhoneRegex)]),
        email: new UntypedFormControl('',
            [createAccountEmailInputValidator(101, false, AppConstants.validEmailRegex)]),
        rsm: new UntypedFormControl(''),
        partRSM: new UntypedFormControl(''),
        accountManagers: new UntypedFormControl(''),
        partAM: new UntypedFormControl(''),
        tsm: new UntypedFormControl(''),
        pricingAnalyst: new UntypedFormControl(''),
        location: new UntypedFormControl(''),
        distributor: new UntypedFormControl(''),
        services: new UntypedFormControl('', [Validators.required]),
        statusActive: new UntypedFormControl(false),
        statusInactive: new UntypedFormControl(false),
        hoursOfOperation: new UntypedFormControl(''),
        notes: new UntypedFormControl('', [Validators.maxLength(1000)]),
        c10Parent: new UntypedFormControl(''),
        c10Child: new UntypedFormControl(''),
        storeNumber: new UntypedFormControl(''),
        flagForPOS: new UntypedFormControl(''),
        flagForDC: new UntypedFormControl(''),
        searchRadius: new UntypedFormControl('', [Validators.pattern(AppConstants.validNumberRegex)]),
        divRegNumber: new UntypedFormControl('', [inputValidator(10, false)]),
        language: new UntypedFormControl(''),
        ownership: new UntypedFormControl(''),
        claimType: new UntypedFormControl(''),
        latitude: new UntypedFormControl('', [coordinateValidator(90, -90, false)]),
        longitude: new UntypedFormControl('', [coordinateValidator(180, -180, false)]),
        locatorName: new UntypedFormControl('', [inputValidator(15, false)]),
        salesOfficeNumber: new UntypedFormControl(''),

        // IT Super Admin
        inventoryAPI: new UntypedFormControl(''),
        regionCode: new UntypedFormControl(''),
        customerUserName: new UntypedFormControl(''),
        customerGUID: new UntypedFormControl('')
    });
}

