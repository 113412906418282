<utc-modal class='hvac-address-lookup-modal' size="dynamic-height" (closeAction)="onCancel()" [visible]="visible"
    theme='rounded' [showHeader]="false">
    <utc-loading-spinner *ngIf="(spinnerStatus$ | async)" [spinnerSize]="'small'"></utc-loading-spinner>
    <ng-container *ngIf="duplicateCompany$ | async as duplicateCompanySet">
        <div class="hvac-address-lookup-wrapper">
            <h3 class="hvac-h3">{{
                'DUPLICATE_COMPANY_CHECK.COMPANY_SUGGESTION' | translate }}</h3>
            <div class="hvac-divider hvac-button-divider"></div>
            <div class="hvac-address-lookup-input" *ngIf="duplicateCompanyLookup">
                <p class="hvac-info-value hvac-company-address-information hvac-label">
                    {{'DUPLICATE_COMPANY_CHECK.POSSIBLE_EXISTING_COMPANY_FOUND_NOTE' | translate }}
                </p>
            </div>
            <div class="hvac-address-lookup-export-warning" *ngIf="!(processStatus$ | async)">
                <hvac-toast [name]="toastOutlet"></hvac-toast>
            </div>

            <div *ngIf="!(toastStatus$ | async)$">
                <div class="hvac-company-form">
                    <form [formGroup]="companyCreateForm">
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-6">
                                <div class="hvac-row hvac-address-lookup-row-padding">
                                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                        {{ "ACCOUNT_ADMIN.COMPANY_CREATE.COMPANY_NAME" | translate }}
                                    </div>
                                    <utc-input shadow="light"
                                        class="hvac-col-xs-12 hvac-col-lg-8 hvac-address-correction-input"
                                        [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.COMPANY_NAME' | translate"
                                        [hideLabel]="false" [inputControl]="companyCreateForm.controls.companyName"
                                        size="small" type="text">
                                    </utc-input>
                                </div>
                                <div class="hvac-row hvac-address-lookup-row-padding">
                                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                        {{ "ACCOUNT_ADMIN.COMPANY_CREATE.COMPANY_ID" | translate }}
                                    </div>
                                    <utc-input shadow="light"
                                        class="hvac-col-xs-12 hvac-col-lg-8 hvac-address-correction-input"
                                        [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.COMPANY_ID' | translate"
                                        [hideLabel]="false" [inputControl]="companyCreateForm.controls.companyId"
                                        size="small" type="text">
                                    </utc-input>
                                </div>

                                <div class="hvac-row hvac-address-lookup-row-padding">
                                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                        {{ "ACCOUNT_ADMIN.COMPANY_CREATE.ADDRESS1" | translate }}
                                    </div>
                                    <utc-input shadow="light"
                                        class="hvac-col-xs-12 hvac-col-lg-8 hvac-address-correction-input"
                                        [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ADDRESS1' | translate"
                                        [hideLabel]="false" [inputControl]="companyCreateForm.controls.address1"
                                        size="small" type="text">
                                    </utc-input>
                                </div>

                                <div class="hvac-row hvac-address-lookup-row-padding">
                                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                        {{ "ACCOUNT_ADMIN.COMPANY_CREATE.ADDRESS2" | translate }}
                                    </div>
                                    <utc-input shadow="light"
                                        class="hvac-col-xs-12 hvac-col-lg-8 hvac-address-correction-input"
                                        [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ADDRESS1' | translate"
                                        [hideLabel]="false" [inputControl]="companyCreateForm.controls.address2"
                                        size="small" type="text">
                                    </utc-input>
                                </div>

                                <div class="hvac-row hvac-address-lookup-row-padding">
                                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                        {{ "ACCOUNT_ADMIN.COMPANY_CREATE.CITY" | translate }}
                                    </div>
                                    <utc-input shadow="light"
                                        class="hvac-col-xs-12 hvac-col-lg-8 hvac-address-correction-input"
                                        [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.CITY' | translate"
                                        [hideLabel]="false" [inputControl]="companyCreateForm.controls.city"
                                        size="small" type="text">
                                    </utc-input>
                                </div>

                            </div>
                            <div class="hvac-col-xs-12 hvac-col-lg-6">
                                <div class="hvac-row hvac-address-lookup-row-padding">
                                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                        {{ "ACCOUNT_ADMIN.COMPANY_CREATE.STATE" | translate }}
                                    </div>

                                    <utc-select class="hvac-col-xs-12 hvac-col-lg-8 hvac-address-correction-input"
                                        shadow="none" size="small" (onChange)="onStateChange($event)"
                                        [group]="companyCreateForm" control="state"
                                        [options]="(companyCreateService.stateOptionData$ | async) || []">>
                                    </utc-select>
                                </div>
                                <div class="hvac-row hvac-address-lookup-row-padding">
                                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                        {{ "ACCOUNT_ADMIN.COMPANY_CREATE.ZIP/POSTAL" | translate }}
                                    </div>
                                    <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8"
                                        [error]="companyCreateForm.controls.postalCode.dirty && !companyCreateForm.controls.postalCode.valid"
                                        [hideLabel]="false" [inputControl]="companyCreateForm.controls.postalCode"
                                        size="small" type="text">
                                    </utc-input>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="hvac-address-lookup-form-action">
                        <button buttonType="button" hvacButton="primary" (click)="checkCompany()">
                            {{ 'DUPLICATE_COMPANY_CHECK.CHECK_DUPLICATE_COMPANY'| translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="hvac-address-lookup-table">
                <hvac-table>
                    <thead>
                        <tr>
                            <td *ngFor="let item of dataHeaders">
                                <button class="hvac-table-sort-btn">
                                    <span class="hvac-address-lookup-column-header">{{item.title}}</span>
                                    <span class="hvac-table-list-sort" [ngClass]="{
                                        'hvac-table-list-sort-show': item.order,
                                        'column-ascending': item.order === 'asc'
                                    }">
                                        <utc-svg src="assets/icons/chevron-down.svg"></utc-svg>
                                    </span>
                                </button>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let duplicateCompany of duplicateCompanySet; let indexOfElement = index;"
                            [ngClass]="{'hvac-address-lookup-form-blue-background' : indexOfElement !== duplicateCompanySet.length - 1}">
                            <td>
                                {{duplicateCompany.companyName}}
                            </td>
                            <td>
                                {{duplicateCompany.companyId}}
                            </td>
                            <td>
                                {{duplicateCompany.address1}}
                            </td>
                            <td>
                                {{duplicateCompany.address2}}
                            </td>
                            <td>
                                {{duplicateCompany.city}}
                            </td>
                            <td>
                                {{duplicateCompany.state.label}}
                            </td>
                            <td>
                                {{duplicateCompany.country.label}}
                            </td>
                            <td>
                                {{duplicateCompany.postalCode}}
                            </td>
                            <td>
                                <button type="button" (click)="userCreatedCompany(duplicateCompany)"
                                    hvacButton="text">{{('DUPLICATE_COMPANY_CHECK.USE_COMPANY') | translate }}</button>
                            </td>

                        </tr>
                    </tbody>
                </hvac-table>
            </div>
            <div class="hvac-address-lookup-modal-actions">
                <button buttonType="button" hvacButton="default" (click)="onCancel()">
                    {{'ADDRESS_LOOKUP.CANCEL' | translate }}
                </button>
                <button [hvacButton]="'primary'" (click)="userCreatedCompany(duplicateCompanyLookup)"
                    [disabled]="((apiRequestActive) || (!companyCreateForm.valid))">
                    <span>{{("ACCOUNT_ADMIN.COMPANY_CREATE.CREATE" | translate)}}</span>
                </button>
            </div>

        </div>
    </ng-container>
</utc-modal>