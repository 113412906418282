import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataHeader } from 'private/app/models/account-admin-search';
import { SelectedSoldTos, UserSoldTos } from 'private/app/models/user.model';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-soldto-table',
    templateUrl: './soldto-table.component.html',
    styleUrls: ['./soldto-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SoldtoTableComponent implements OnInit, OnDestroy {
    @Input() tableType: string;
    @Input() tableTypeIdentifier: string;
    @Input() soldToArray: UserSoldTos[] = [];
    @Output() public onSelectAllClick = new EventEmitter();
    @Output() public onAddAndRemove = new EventEmitter();
    @Output() public isCancelButtonclicked = new EventEmitter();
    @Output() public selectedSoldToArray = new EventEmitter<SelectedSoldTos>();
    public soldToDisplayArray$ = new BehaviorSubject<UserSoldTos[]>([]);
    public isAllSelected: boolean;
    public searchControl: UntypedFormGroup;
    public filterControlGroup: UntypedFormGroup;
    public currentPage: number = 1;
    public pageSize: number = 10;
    public totalPages: number;
    public removedSoldToList: string[] = [];
    public isLoading: boolean = false;

    dataHeaders: DataHeader[] = [
        {
            title: this.translate.instant('USER_ADMIN.USER_RELATIONSHIPS.COMPANY_NAME'),
            value: 'companyName',
            order: null
        },
        {
            title: this.translate.instant('USER_ADMIN.USER_RELATIONSHIPS.COMPANY_ID'),
            value: 'companyId',
            order: null
        },
        {
            title: this.translate.instant('USER_ADMIN.USER_RELATIONSHIPS.SOLD_TO'),
            value: 'soldTo',
            order: null
        },
        {
            title: this.translate.instant('USER_ADMIN.USER_RELATIONSHIPS.PART_SOLD_TO'),
            value: 'partSoldToValue',
            order: null
        }
    ];

    private soldToArray$ = new BehaviorSubject<UserSoldTos[]>([]);
    private filteredData$ = new BehaviorSubject<UserSoldTos[]>([]);
    private searchKeyUpSubject$ = new BehaviorSubject<{event: string, key: string}>({
        event: '',
        key: ''
    });

    private filterKeyValues = this.dataHeaders.map(({ value }) => ({
        value,
        query: ''
    }));

    private filterSubject$ = new BehaviorSubject(this.filterKeyValues)
    private filter$ = this.filterSubject$.asObservable()
    private dataSource$ = combineLatest([this.filter$, this.soldToArray$]).pipe(
        map(([filter, dataSource]) => dataSource.filter((item) => filter.every((value) => new RegExp(String(value.query).toLowerCase()).test(
            String((item[value.value as keyof UserSoldTos])).toLowerCase()
        ))))
    );

    private ngOnDestroy$ = new Subject();
    constructor(
        private translate: TranslateService
    ) {}

    public sort(item: DataHeader) {
        let sortedArr: UserSoldTos[] = [];
        const currentSort: Partial<DataHeader> = this.dataHeaders.find((header: DataHeader) => header.value === item.value) || {};

        if (!currentSort.order) {
            currentSort.order = 'asc';
        }
        else if (currentSort.order === 'asc') {
            currentSort.order = 'desc';
        }
        else {
            currentSort.order = null;
        }
        if (!currentSort.order) {
            this.soldToDisplayArray$.next(
                this.soldToArray.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
            );

            return;
        }
        if (item.value === 'companyName') {
            sortedArr = this.soldToArray.slice().sort((a, b) => {
                const valueA = a[item.value as keyof UserSoldTos] || '';
                const valueB = b[item.value as keyof UserSoldTos] || '';

                return Number(valueA) - Number(valueB);
            });
        }
        else {
            sortedArr = this.soldToArray.slice().sort((a, b) => {
                const valueA = a[item.value as keyof UserSoldTos] || '';
                const valueB = b[item.value as keyof UserSoldTos] || '';

                return valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase());
            });
        }
        if (currentSort.order === 'desc') {
            sortedArr.reverse();
        }
        this.soldToDisplayArray$.next(
            sortedArr.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
        );
    }

    ngOnInit() {
        this.searchControl = new UntypedFormGroup({ search: new UntypedFormControl('', null) });
        this.filterControlGroup = new UntypedFormGroup({
            companyName: new UntypedFormControl(''),
            companyId: new UntypedFormControl(''),
            soldTos: new UntypedFormControl('')
        });

        this.searchKeyUpSubject$.pipe(
            takeUntil(this.ngOnDestroy$),
            debounceTime(100)
        ).subscribe((searchQuery) => {
            this.filterKeyValues.forEach((filterKeyValue) => {
                if (filterKeyValue.value === searchQuery.event) {
                    filterKeyValue.query = searchQuery.key;
                }
            });
            this.filterSubject$.next(this.filterKeyValues);
            this.currentPage = 1;
        });

        this.filterControlGroup.controls.companyName.valueChanges.subscribe((value: string) => {
            this.inputChange('companyName', value);
        });
        this.filterControlGroup.controls.companyId.valueChanges.subscribe((value: string) => {
            this.inputChange('companyId', value);
        });
        this.filterControlGroup.controls.soldTos.valueChanges.subscribe((value: string) => {
            this.inputChange('soldTo', value);
        });
        this.isLoading = true;
        this.dataSource$.subscribe((data) => {
            this.totalPages = Math.ceil(data.length / this.pageSize);
            this.filteredData$.next(data);
            this.soldToDisplayArray$.next(data.slice(0, this.pageSize));
            this.isLoading = false;
        });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.soldToArray['currentValue']) {
            this.resetForm();
            this.soldToArray = changes.soldToArray['currentValue'];
            this.totalPages = Math.ceil(this.soldToArray.length / this.pageSize);
            this.handlePageChange(1);
            this.soldToArray$.next(this.soldToArray);
            this.soldToDisplayArray$.next(
                this.soldToArray$.value.slice(0, this.pageSize)
            );
        }
    }

    resetForm() {
        this.filterControlGroup?.controls.companyName.setValue('');
        this.filterControlGroup?.controls.companyId.setValue('');
        this.filterControlGroup?.controls.soldTos.setValue('');
        this.filterKeyValues = this.dataHeaders.map(({ value }) => ({
            value,
            query: ''
        }));
    }

    handlePageChange(page: number) {
        this.currentPage = parseInt(page.toString(), 10);
        this.soldToDisplayArray$.next(
            this.filteredData$.value.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
        );
    }

    selectAllSoldTos() {
        this.isAllSelected = !this.isAllSelected;
        if (this.isAllSelected) {
            this.filteredData$.value.map((data) => {
                data.assigned = true;

                return data;
            });

            this.selectedSoldToArray.next(
                {
                    type: this.tableTypeIdentifier,
                    soldTos: this.tableTypeIdentifier === 'availableSoldToTable' ? this.soldToArray$.value.filter((soldto) => soldto.assigned) : this.soldToArray$.value.filter((soldto) => !soldto.assigned)
                }
            );

            return;
        }

        this.filteredData$.value.map((data) => {
            data.assigned = false;

            return data;
        });

        this.selectedSoldToArray.next(
            {
                type: this.tableTypeIdentifier,
                soldTos: this.soldToArray$.value.filter((soldTo) => !soldTo.assigned)
            }
        );
    }

    selectSoldTo(soldTobj: UserSoldTos, event: Event) {
        this.soldToArray$.value.map((data) => {
            if (data.companyId === soldTobj.companyId) {
                data.assigned = this.tableTypeIdentifier === 'availableSoldToTable' ? (event.target as HTMLInputElement).checked : !(event.target as HTMLInputElement).checked;
            }

            return data;
        });

        this.selectedSoldToArray.emit(
            {
                type: this.tableTypeIdentifier,
                soldTos: this.tableTypeIdentifier === 'availableSoldToTable' ? this.soldToArray$.value.filter((soldto) => soldto.assigned) : this.soldToArray$.value.filter((soldto) => !soldto.assigned)
            }
        );
    }

    isButtonDisabled() {
        if (this.tableTypeIdentifier === 'assignedSoldToTable') {
            return this.soldToArray$.value.some((soldto) => !soldto.assigned);
        }

        return this.soldToArray$.value.some((soldto) => soldto.assigned);
    }

    cancelSoldTo() {
        this.isCancelButtonclicked.emit(true);
    }

    assignSelectedSoldTos() {
        this.onAddAndRemove.next();
    }

    private inputChange(event: string, key: string) {
        this.searchKeyUpSubject$.next({
            event,
            key
        });
    }
}
