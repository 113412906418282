import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'emptyToChar',
    standalone: true
})
export class EmptyToCharPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any, char: string): any {
        if (typeof value === 'undefined' || value === null || String(value).trim() === '') {
            return char;
        }

        return value;
    }
}
