import { CommonComponentsModule } from 'common/components/common-components.module';
import { CommonModule } from '@angular/common';
import { ConnectedPortalStatsComponent } from './components/connected-portal-stats/connected-portal-stats.component';
import { ContentModule } from 'common/content/content.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConnectedPortalCustomersPropertyComponent } from './dealer/connected-portal-dealer-customers/connected-portal-customers-property/connected-portal-customers-property.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ConnectedPortalConnectCustomersComponent } from './dealer/connected-portal-dealer-customers/connected-portal-connect-customers/connected-portal-connect-customers.component';
import { CustomersListMenuComponent } from './dealer/connected-portal-dealer-customers/components/customers-list-menu/customers-list-menu.component';
import { TextOverflowModule } from 'common/pipes/text-overflow/text-overflow.module';
import { ProductDetailsCardComponent } from './dealer/components/product-details-card/product-details-card.component';
import { SystemDataSharingHeaderComponent } from './dealer/connected-portal-dealer-customers/connected-portal-customers-property/components/system-data-sharing-header/system-data-sharing-header.component';
import { PropertySystemPanelComponent } from './dealer/connected-portal-dealer-customers/connected-portal-customers-property/components/property-system-panel/property-system-panel.component';
import { ProductDetailAlertsComponent } from './dealer/connected-portal-dealer-customers/components/product-detail-alerts/product-detail-alerts.component';
import { DataSharingToStatusPipe } from 'private/app/pipes/data-sharing-to-status.pipe';
import { ConnectedPortalDealerCustomersComponent } from './dealer/connected-portal-dealer-customers/connected-portal-dealer-customers.component';
import { ConnectedPortalQueuedCustomersComponent } from './dealer/connected-portal-dealer-customers/connected-portal-queued-customers/connected-portal-queued-customers.component';
import { RecentActivityBannerComponent } from './dealer/connected-portal-dealer-customers/connected-portal-customers-property/components/recent-activity-banner/recent-activity-banner.component';
import { DateTimeTzPipe } from 'private/app/pipes/date-time-tz.pipe';
import { DealerComponent } from './dealer/dealer.component';
import { MilitaryToStandard } from 'common/pipes/military-to-standard.pipe';
import { ConnectedPortalWarrantyInfoComponent } from './dealer/connected-portal-dealer-customers/connected-portal-warranty-info/connected-portal-warranty-info.component';
import { WarrantyDetailsComponent } from './dealer/connected-portal-dealer-customers/components/warranty-details/warranty-details.component';
import { SystemNotificationPreferencesComponent } from './dealer/connected-portal-dealer-customers/connected-portal-customers-property/components/system-notification-preferences/system-notification-preferences.component';
import { FilterObjectArrayPipe } from 'private/app/pipes/filter-object-array.pipe';
import { NgChartsModule } from 'ng2-charts';
import { SearchControlComponent } from './dealer/connected-portal-dealer-customers/components/search-control/search-control.component';
import { AddControlComponent } from './dealer/connected-portal-dealer-customers/components/add-control/add-control.component';
import { DealerLoginsReportComponent } from './distributor/distributor-dashboard/components/dealer-logins-report/dealer-logins-report.component';
import { OpportunityUsageReportComponent } from './distributor/distributor-dashboard/components/opportunity-usage-report/opportunity-usage-report.component';
import { ReportChartContainerComponent } from './components/report-chart-container/report-chart-container.component';
import { SystemDiagnosticsModalComponent } from './dealer/connected-portal-dealer-customers/components/system-diagnostics-modal/system-diagnostics-modal.component';
import { DistributorNavigationComponent } from './dealer/components/distributor-navigation/distributor-navigation.component';
import { OutOfRangeToCharPipe } from 'common/pipes/out-of-range-to-char';
import { SafePipeModule } from 'safe-pipe';
import { OduDetailContainerComponent } from './dealer/customer-product-details/odu/odu-detail-container/odu-detail-container.component';
import { InfinityOduDetailComponent } from './dealer/customer-product-details/odu/infinity-odu-detail/infinity-odu-detail.component';
import { EcobeeOduDetailComponent } from './dealer/customer-product-details/odu/ecobee-odu-detail/ecobee-odu-detail.component';
import { WallControlHumidityAndVentilationDetailsComponent } from './dealer/customer-product-details/wall-control/components/wall-control-humidity-and-ventilation-details/wall-control-humidity-and-ventilation-details.component';
import { WallControlStatusDetailsComponent } from './dealer/customer-product-details/wall-control/components/wall-control-status-details/wall-control-status-details.component';
import { WallControlSystemInfoComponent } from './dealer/customer-product-details/wall-control/components/wall-control-system-info/wall-control-system-info.component';
import { InfinityOduConfigurationComponent } from './dealer/customer-product-details/odu/infinity-odu-detail/components/infinity-odu-configuration/infinity-odu-configuration.component';
import { SystemDiagnosticParameterComponent } from './dealer/customer-product-details/wall-control/components/wall-control-system-diagnostics/components/system-diagnostic-parameter/system-diagnostic-parameter.component';
import { SystemDiagnosticEquipmentComponent } from './dealer/customer-product-details/wall-control/components/wall-control-system-diagnostics/components/system-diagnostic-equipment/system-diagnostic-equipment.component';
import { ConfirmConfigEditModalComponent } from './dealer/customer-product-details/components/confirm-config-edit-modal/confirm-config-edit-modal.component';
import { RemoveControlModalComponent } from './dealer/connected-portal-dealer-customers/components/remove-control-modal/remove-control-modal.component';
import { WallControlProgramSettingsComponent } from './dealer/components/system-conditions/components/wall-control-program-settings/wall-control-program-settings.component';
import { FadReportComponent } from './factory/factory-dashboard/components/fad-report/fad-report.component';
import { FaultSourceReportComponent } from './factory/factory-systems-overview/factory-system-insights/components/fault-source-report/fault-source-report.component';
import { FaultSeverityReportComponent } from './factory/factory-systems-overview/factory-system-insights/components/fault-severity-report/fault-severity-report.component';
import { EncodeURIComponentPipe } from 'common/pipes/encode-uri-component.pipe';
import { ConnectedPortalResultsListComponent } from './components/connected-portal-results-list/connected-portal-results-list.component';
import { ConnectedPortalSystemProductCardComponent } from './components/connected-portal-system-product-card/connected-portal-system-product-card.component';
import { UpdateProductDetailsModalComponent } from './dealer/customer-product-details/components/update-product-details-modal/update-product-details-modal.component';
import { ConnectedPortalSwimlaneChartComponent } from './dealer/customer-product-details/wall-control/components/connected-portal-swimlane-chart/connected-portal-swimlane-chart.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { EventTypePipe } from 'private/app/pipes/event-type.pipe';
import { InfoCardComponent } from 'private/app/views/connected-portal/components/info-card/info-card.component';
import { ConnectedPortalNavigationComponent } from 'private/app/views/connected-portal/components/connected-portal-navigation/connected-portal-navigation.component';
import { ScrollToElementDirective } from 'private/app/views/connected-portal/directives/scroll-to-element.directive';
import { ConnectedPortalLayoutContainerComponent } from 'private/app/views/connected-portal/components/connected-portal-layout-container/connected-portal-layout-container.component';
import { WallControlNotificationsComponent } from 'private/app/views/connected-portal/dealer/customer-product-details/wall-control/components/hvac-wall-control-notifications/hvac-wall-control-notifications.component';
import { ErrorRedirectComponent } from './components/error-redirect/error-redirect.component';
import { WallControlEventLogComponent } from 'private/app/views/connected-portal/dealer/customer-product-details/wall-control/components/wall-control-event-log/wall-control-event-log.component';
import { SeverityToAlertTypePipe } from 'private/app/views/connected-portal/pipes/severity-to-alert-type.pipe';
import { ProductPropertyListComponent } from 'common/components/product-property-list/product-property-list.component';
import { ProductPropertyListItemComponent } from 'common/components/product-property-list/product-property-list-item/product-property-list-item.component';
import { ProductPropertyListItemLabelComponent } from 'common/components/product-property-list/product-property-list-item-label/product-property-list-item-label.component';
import { ProductPropertyListItemValueComponent } from 'common/components/product-property-list/product-property-list-item-value/product-property-list-item-value.component';
import { ConfigEditPropComponent } from 'private/app/views/connected-portal/dealer/customer-product-details/components/config-edit-prop/config-edit-prop.component';
import { WallControlSystemDiagnosticsComponent } from 'private/app/views/connected-portal/dealer/customer-product-details/wall-control/components/wall-control-system-diagnostics/wall-control-system-diagnostics.component';
import { GenericEventLogsComponent } from 'private/app/views/connected-portal/dealer/customer-product-details/wall-control/components/wall-control-system-diagnostics/components/generic-event-logs/generic-event-logs.component';

@NgModule({
    declarations: [
        ConnectedPortalConnectCustomersComponent,
        ConnectedPortalCustomersPropertyComponent,
        ConnectedPortalDealerCustomersComponent,
        ConnectedPortalQueuedCustomersComponent,
        DataSharingToStatusPipe,
        DealerComponent,
        EncodeURIComponentPipe,
        PropertySystemPanelComponent,
        RecentActivityBannerComponent,
        SystemDataSharingHeaderComponent,
        InfinityOduConfigurationComponent,
        ConnectedPortalWarrantyInfoComponent,
        WarrantyDetailsComponent,
        SystemNotificationPreferencesComponent,
        FilterObjectArrayPipe,
        SearchControlComponent,
        ConfirmConfigEditModalComponent,
        OutOfRangeToCharPipe,
        OduDetailContainerComponent,
        InfinityOduDetailComponent,
        EcobeeOduDetailComponent,
        WallControlProgramSettingsComponent,
        UpdateProductDetailsModalComponent,
        TooltipDirective,
        EventTypePipe,
        ProductDetailsCardComponent,
        ErrorRedirectComponent
    ],
    providers: [
        EncodeURIComponentPipe,
        DateTimeTzPipe,
        MilitaryToStandard,
        OutOfRangeToCharPipe
    ],
    exports: [
        ProductDetailAlertsComponent,
        DistributorNavigationComponent,
        AddControlComponent,
        DateTimeTzPipe,
        EventTypePipe,
        WallControlProgramSettingsComponent,
        SystemDiagnosticsModalComponent,
        ConfirmConfigEditModalComponent,
        RemoveControlModalComponent,
        ErrorRedirectComponent,
        ProductDetailsCardComponent,
        WallControlStatusDetailsComponent,
        WallControlHumidityAndVentilationDetailsComponent,
        WallControlEventLogComponent,
        WallControlNotificationsComponent,
        WallControlSystemInfoComponent,
        WallControlNotificationsComponent,
        EncodeURIComponentPipe,
        FilterObjectArrayPipe,
        SeverityToAlertTypePipe,
        ConnectedPortalLayoutContainerComponent,
        CustomersListMenuComponent
    ],
    imports: [
        ProductDetailAlertsComponent,
        CustomersListMenuComponent,
        DateTimeTzPipe,
        AddControlComponent,
        CommonComponentsModule,
        CommonModule,
        ContentModule,
        FormsModule,
        NgChartsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        TextOverflowModule,
        SafePipeModule,
        InfoCardComponent,
        SearchBarComponent,
        ConnectedPortalStatsComponent,
        ConnectedPortalSystemProductCardComponent,
        ReportChartContainerComponent,
        FadReportComponent,
        FaultSourceReportComponent,
        FaultSeverityReportComponent,
        DealerLoginsReportComponent,
        OpportunityUsageReportComponent,
        ConnectedPortalResultsListComponent,
        ConnectedPortalNavigationComponent,
        ScrollToElementDirective,
        ConnectedPortalLayoutContainerComponent,
        DistributorNavigationComponent,
        WallControlNotificationsComponent,
        WallControlStatusDetailsComponent,
        SystemDiagnosticEquipmentComponent,
        SystemDiagnosticParameterComponent,
        WallControlEventLogComponent,
        SeverityToAlertTypePipe,
        ConnectedPortalSwimlaneChartComponent,
        WallControlHumidityAndVentilationDetailsComponent,
        WallControlSystemInfoComponent,
        ProductPropertyListComponent,
        ProductPropertyListItemComponent,
        ProductPropertyListItemLabelComponent,
        ProductPropertyListItemValueComponent,
        ConfigEditPropComponent,
        WallControlSystemDiagnosticsComponent,
        GenericEventLogsComponent,
        RemoveControlModalComponent,
        SystemDiagnosticsModalComponent
    ]
})
export class ConnectedPortalModule {}
