import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from 'common/environments/environment';
import { ErrorService } from 'common/content/services/error.service';
import { MqttActivationManagerService } from 'private/app/services/connected-portal/mqtt-activation-manager.service';
import { ActivityTrackerService } from 'private/app/services/connected-portal/activity-tracker.service';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'common/components/base/base.component';
import { switchMap, takeUntil } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { DataResolverService } from 'common/content/services/data-resolver.service';
import { ToastService } from 'common/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'hvac-dealer',
    templateUrl: './dealer.component.html',
    styleUrls: ['./dealer.component.scss'],
    providers: [MqttActivationManagerService]
})
export class DealerComponent extends BaseComponent implements OnInit {
    constructor(
        private errorService: ErrorService,
        private cdr: ChangeDetectorRef,
        private activityTrackerService: ActivityTrackerService,
        private route: ActivatedRoute,
        private dataResolverService: DataResolverService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        if (!environment.features.connectedPortal.dealers) {
            this.errorService.errorStatus$.next(404);
            this.cdr.detectChanges();
        }

        const trackDealerVisit$ = this.route.paramMap
            .pipe(
                switchMap((params) => {
                    const dealerId = params.get('id');

                    if (dealerId) {
                        return this.activityTrackerService.trackDealerVisit(dealerId);
                    }

                    return EMPTY;
                })
            );

        trackDealerVisit$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe();
    }

    ngAfterViewInit(): void {
        this.dataResolverService.limitedFeatures$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((isLimited) => {
                if (isLimited) {
                    this.toastService.add({
                        bgColor: '#F8F8F8',
                        content: this.translateService.instant('CONNECTED_PORTAL.LIMITED_FEATURES_MESSAGE'),
                        id: 'cp-limited-features-message',
                        closeable: true
                    });
                }
            });
    }
}
