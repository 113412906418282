import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { CharSymbol } from 'common/enums/char-symbol';
import { WallControlEnergyEvent, WallControlEnergyEventConfig } from 'private/app/models/energy-events.model';
import { ConnectedPortalModule } from 'private/app/views/connected-portal/connected-portal.module';

@Component({
    selector: 'hvac-wall-control-energy-events',
    templateUrl: './wall-control-energy-events.component.html',
    styleUrls: ['./wall-control-energy-events.component.scss'],
    standalone: true,
    imports: [AsyncPipe, NgIf, CommonModule, CommonComponentsModule, ConnectedPortalModule, TranslateModule]
})
export class WallControlEnergyEventsComponent {
    @Input() data: WallControlEnergyEvent[];
    @Input() totalPages: number;
    @Input() currentPage: number;
    @Input() config?: WallControlEnergyEventConfig = new WallControlEnergyEventConfig();
    @Input() isResponsive: boolean = true;
    @Output() onPageChange = new EventEmitter<number>();
    public readonly CharSymbol= CharSymbol;
    handlePageChange(page: number) {
        this.onPageChange.emit(page);
    }
}
