export enum IdmUserRoles {
    ACCOUNTADMIN = 'AccountAdmin',
    ACCOUNTREADONLY = 'AccountReadOnly',
    COMPANYADMIN = 'CompanyAdmin',
    USERADMIN = 'UserAdmin',
    INTERNALCBP = 'InternalCBP',
    INTERNALICP = 'InternalICP',
    LOCATORADMINUPDATES = 'LocatorPortalUpdate',
    COOLCALCIMPERSONATION = 'CoolCalcImpersonation',
    DEVTEST = 'DevTest',
    DEFAULT_INTERNAL = 'DefaultInternal',
    DEFAULT_DISTRIBUTOR = 'DefaultDistributor',
    TRADE_PARTNERS = 'TradePartners',
    IT_SUPER_ADMIN = 'ITSuperAdmin',
    MARKETING_PROGRAM_ADMIN = 'MarketingPrgAdmin',
    CMNA_MARKETING_PROGRAM_ADMIN = 'CMNAMarketingPrgAdmin',
    PROGRAM_ENROLLMENT_ADMIN = 'ProgramEnrollmentAdmin',
    CMNA_PROGRAM_ENROLLMENT_ADMIN = 'CMNAProgEnrollAdmin',
    COMPANY_TYPE_DOMESTIC_DISTRIBUTOR = 'CompanyType_Domestic Distributor',
    COMPANY_TYPE_INTERNATIONAL_DISTRIBUTOR = 'CompanyType_International Distributor',
    COMPANY_TYPE_MARINE_DISTRIBUTOR = 'CompanyType_Marine Systems Group',
    REGIONAL_SALES_MANAGER = 'RegionalSalesManager',
    PROGRAM_MANAGER = 'ProgramManager',
    REG_ADMIN = 'RegistrationAdmin',
    MIDEA_BRAND = 'Brand_Midea NA',
    MIDEA_BRAND_NA = 'BrandCode_MNA',
    MONTHLYCUSTOMERSTATEMENTS = 'monthlycustomerstatements'
}
