import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppConstants } from 'common/app-constants';
import { ComponentsModule } from 'private/app/components/components.module';
import { ProductAlert, AlertStatusFilter } from 'private/app/models/alert.model';
import { DateTimeTzPipe } from 'private/app/pipes/date-time-tz.pipe';
import { SeverityToAlertTypePipe } from 'private/app/views/connected-portal/pipes/severity-to-alert-type.pipe';

@Component({
    selector: 'hvac-product-detail-alerts',
    templateUrl: './product-detail-alerts.component.html',
    styleUrls: ['./product-detail-alerts.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, ComponentsModule, DateTimeTzPipe, SeverityToAlertTypePipe]
})
export class ProductDetailAlertsComponent implements OnInit {
    @Input() data: ProductAlert[];
    @Input() totalPages: number;
    @Input() currentPage: number;
    @Input() enableFilter: boolean =false;
    @Output() onPageChange = new EventEmitter<number>();
    @Output() selectedFilter = new EventEmitter<AlertStatusFilter>();

    public readonly AppConstants = AppConstants;

    public selectedTab: string = AlertStatusFilter.ACTIVE;

    readonly AlertStatusFilter = AlertStatusFilter;

    handlePageChange(page: number) {
        this.onPageChange.emit(page);
    }

    handleTabClick(tabSelection: AlertStatusFilter) {
        this.selectedTab = tabSelection;
        this.selectedFilter.emit(tabSelection);
    }

    ngOnInit(): void {
        if (this.data?.length === 0 && this.selectedTab === AlertStatusFilter.ACTIVE) {
            this.selectedTab = AlertStatusFilter.ALL;
            this.selectedFilter.emit(AlertStatusFilter.ALL);
        }
    }
}

