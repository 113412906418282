<ng-container *ngIf="(dataSharingPermissions$ | async) as dataSharingPermissions">
    <div class="hvac-row">
        <div class="hvac-col-xs-12">
            <div class="hvac-cp-card">
                <hvac-product-details-card *ngIf="(productData$ | async) as productData; else productDetailLoader"
                    [dealerId]="dealerId"
                    [propertyId]="propertyId"
                    [systemInfo]="productData.system"
                    [product]="productData.productInfo"
                    [isRuntimeReportEnabled]="productData.isRuntimeReportEnabled"
                    [isTestEquipmentEnabled]="productData.isTestEquipmentEnabled"
                    [isConnectionStatusVisible]="true"
                    [isDeviceConnected]="productData.isWallControlConnected"
                    (onTestEquipmentClick)="handleTestEquipmentClick()"
                    (onRunReportClick)="productContextService.isReportModalVisible$.next(true)"
                    [isRemoveControlEnabled]="true"></hvac-product-details-card>
                <ng-template #productDetailLoader>
                    <div class="hvac-product-detail-loader">
                        <utc-loading-spinner></utc-loading-spinner>
                    </div>
                </ng-template>
            </div>

            <div *ngIf="dataSharingPermissions?.systemConditions" class="hvac-system-conditions-container hvac-cp-card">
                <div class="hvac-row hvac-end-md hvac-rhythm-4">
                    <div class="hvac-zones-header hvac-col-xs-12">
                        <div>
                            <div *ngIf="(wallControlStatusTime$ | async) as wallControlStatusTime"
                                class="hvac-zone-status-date hvac-p-sm">{{ 'CONNECTED_PORTAL.WALL_CONTROL.STATUS_AS_OF'
                                | translate }} {{ wallControlStatusTime | dateTimeTz }}</div>
                            <button class="hvac-refresh-button"
                                [ngClass]="{ 'hvac-refresh-button-loading' : isEcobeeDataRefreshing$ | async}"
                                hvacButton="primary" size="small"
                                (click)="refreshEcobeeData$.next(true)"
                                [disabled]="!(isWallControlConnected$ | async) || !dataSharingPermissions?.editConfig">
                                <span *ngIf="!(isEcobeeDataRefreshing$ | async) else loadingData">
                                    {{'CONNECTED_PORTAL.WALL_CONTROL.ECOBEE_DATA_REFRESH.REFRESH_DATA' | translate }}
                                </span>
                                <ng-template #loadingData>
                                    <span>{{ 'CONNECTED_PORTAL.WALL_CONTROL.ECOBEE_DATA_REFRESH.CONNECTING' | translate }}</span>
                                    <div class="hvac-button-spinner"></div>
                                </ng-template>
                            </button>

                            <div *ngIf="!(isWallControlConnected$ | async) || !dataSharingPermissions?.editConfig" class="hvac-data-refresh-info-message">
                                <utc-svg class="hvac-data-refresh-info-icon" src="assets/icons/info.svg"></utc-svg>
                                <span *ngIf="(refreshButtonMsg$ | async) as refreshButtonMsg" class="hvac-p-sm hvac-fw-rg">{{ refreshButtonMsg }}</span>
                            </div>
                        </div>
                        <div *ngIf="(zoningDetails$ | async)?.length" class="hvac-zone-tabs">
                            <div class="hvac-label-sm hvac-zone-label">{{ 'CONNECTED_PORTAL.WALL_CONTROL.ZONING.HEADER'
                                | translate }}</div>
                            <hvac-tab-bar variant="secondary">
                                <a *ngFor="let zone of (wallControlDetails$ | async)?.zones" [queryParams]="{ scroll: false }" [routerLink]="zone.name | encodeURIComponent"
                                     routerLinkActive="hvac-active">
                                    {{zone.name}} </a>
                            </hvac-tab-bar>
                        </div>
                    </div>

                    <div class="hvac-data-refresh-toast-container hvac-col-xs-12">
                        <hvac-toast [name]="toastOutlet"></hvac-toast>
                    </div>
                </div>
                <router-outlet></router-outlet>
                <utc-loading-spinner class="hvac-zone-loader" *ngIf="!(isZoneRouteSet$ | async)"></utc-loading-spinner>
            </div>
        </div>
    </div>
    <ng-container *ngIf="(accordions$ | async) as accordions">
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new
                    [disabled]="!dataSharingPermissions?.eventLog"
                    [open]="accordions.eventLog && dataSharingPermissions?.eventLog === true"
                    (toggle)="toggleAccordion$.next('eventLog')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-wall-control-event-log
                            *ngIf="(wallControlEventLogData$ | async) as eventLogData"
                            [config]="eventLogData.config"
                            [data]="eventLogData.items"
                            [totalPages]="eventLogData.totalPages"
                            [currentPage]="eventLogData.currentPage"
                            [enableFilter]="true"
                            (onPageChange)="wallControlEventLogCurrentPage$.next($event)"
                            (selectedFilter)="handleFilterChange($event)"
                            ></hvac-wall-control-event-log>
                        <utc-loading-spinner *ngIf="isLoadingAlerts$ | async" variant="overlay"></utc-loading-spinner>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new
                    [disabled]="!dataSharingPermissions?.controlConfiguration"
                    [open]="accordions.config && dataSharingPermissions?.controlConfiguration === true"
                    (toggle)="toggleAccordion$.next('config')">
                    <hvac-accordion-header>
                        <div class="hvac-accordion-config-header">
                            <div>{{ 'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.HEADER' | translate }}</div>
                            <div *ngIf="dataSharingPermissions?.controlConfiguration && dataSharingPermissions.saveConfig " class="hvac-accordion-edit-configuration">
                                <button
                                    [disabled]="(productContextService.ecobeeEditConfigState$ | async) === EcobeeEditConfigState.Connected || !(isWallControlConnected$ | async)"
                                    hvacButton="primary"
                                    size="small"
                                    (click)="productContextService.enableEcobeeEditConfig$.next(true)">

                                    <ng-container [ngSwitch]="(productContextService.ecobeeEditConfigState$ | async)">
                                        <span *ngSwitchDefault>{{ 'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ECOBEE.CONFIG_CONNECTION.EDIT_CONFIGURATIONS' | translate }}</span>

                                        <ng-container *ngSwitchCase="EcobeeEditConfigState.EstablishingConnection">
                                            <span>{{ 'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ECOBEE.CONFIG_CONNECTION.ESTABLISHING_CONNECTION' | translate }}</span> <div class="hvac-button-spinner"></div>
                                        </ng-container>

                                        <ng-container *ngSwitchCase="EcobeeEditConfigState.Connected">
                                            <span>{{ 'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ECOBEE.CONFIG_CONNECTION.CONNECTION_ESTABLISHED' | translate }}</span>
                                        </ng-container>
                                    </ng-container>
                                </button>
                            </div>
                        </div>
                    </hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-ecobee-wall-control-config
                            *ngIf="dataSharingPermissions?.controlConfiguration"
                            [dealerId]="dealerId"
                            [serialNo]="serialNo"
                            [isEditEnabled]="dataSharingPermissions?.editConfig"
                            [isSaveEnabled]="dataSharingPermissions?.saveConfig"
                            [systemType]="systemType"
                            [isDeviceConnected]="(isWallControlConnected$ | async) || false"></hvac-ecobee-wall-control-config>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
    </ng-container>
</ng-container>




