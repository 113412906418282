import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { BaseComponent } from 'common/components/base/base.component';
import { ConfigEditProp } from 'private/app/services/connected-portal/product-configuration.service';
import { formatNumberToRange, isDefined } from 'private/app/services/connected-portal/utils';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { NgIf } from '@angular/common';

export const EDIT_PROP_INPUT_DEBOUNCE_INTERVAL = 500;

@Component({
    selector: 'hvac-config-edit-prop',
    templateUrl: './config-edit-prop.component.html',
    styleUrls: ['./config-edit-prop.component.scss'],
    standalone: true,
    imports: [
        CommonComponentsModule,
        NgIf,
        ReactiveFormsModule
    ]
})
export class ConfigEditPropComponent extends BaseComponent implements OnInit {
    @Input() groupId?: string;
    @Input() editProp: ConfigEditProp;
    @Input() propFormControl: UntypedFormControl;
    @Output() onEditCancel = new EventEmitter();

    initialValue: unknown;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.initialValue = this.editProp.value;

        if (this.editProp.type === 'numberInput') {
            this.propFormControl.valueChanges
                .pipe(
                    takeUntil(this.ngOnDestroy$),
                    debounceTime(EDIT_PROP_INPUT_DEBOUNCE_INTERVAL)
                )
                .subscribe((value) => {
                    const { min, max, step } = this.editProp;
                    this.formatNumberInput(value, min, max, step);
                });
        }
    }

    handlePropEditCancel() {
        this.propFormControl.reset(this.initialValue);

        this.onEditCancel.emit({
            id: this.editProp.id,
            groupId: this.groupId
        });
    }

    handleNumberInputBlur(value: string) {
        if (value.trim() === '') {
            this.propFormControl.reset(this.initialValue);
        }
    }

    private formatNumberInput(value: string, min?: number, max?: number, step?: number) {
        const isValidRange = [min, max, step].every((prop) => isDefined(prop));
        const numberValue = parseFloat(value);

        if (isValidRange && typeof numberValue === 'number') {
            const formattedValue = formatNumberToRange(numberValue, min as number, max as number, step as number);

            if (numberValue !== formattedValue) {
                this.propFormControl.setValue(formattedValue.toString(), { emitEvent: false });
            }
        }
    }
}
