import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountAdminCountriesEntity } from 'private/app/models/account-admin-search';
import { UserDetails, UserDetailsEntity } from 'private/app/models/user.model';
import { AccountAdminCompanyUtilsService } from 'private/app/services/account-admin/account-admin-company-utils.service';
import { UserAdminService } from 'private/app/services/account-admin/user/user-admin.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Option } from '../../../../../common/components/select/select.component';
import { CompanyResultDataResponse } from 'private/app/models/account-admin-search-results';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { Select } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { UserAdminCreateService } from 'private/app/services/account-admin/user/user-admin-create.service';

type SearchCompany = {
    userCreateStep: string,
    company: CompanyResultDataResponse
}

@Component({
    selector: 'hvac-create-user-details',
    templateUrl: './create-user-details.component.html',
    styleUrls: ['./create-user-details.component.scss']
})
export class CreateUserDetailsComponent implements OnInit {
    @Select(AccountStatusState.getUserBrandFamily) userBrandFamily$: Observable<string | null>;
    @Input() company: CompanyResultDataResponse;
    @Input() cloneUserData: UserDetailsEntity;
    @Output() userDetails = new EventEmitter<UserDetails>();
    @Output() createUserChangePage = new EventEmitter<{
        userCreateStep: string,
        company : CompanyResultDataResponse
    }>();

    public user: UserDetailsEntity;
    public userAddress1: string ='';
    public stateOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public countryOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public userState: string;
    public userCountry: string;
    public createUserDetails: UserDetails;
    public brandFamily: string | null;
    public brandFamilyValue: string = 'ICP';

    constructor(
        public accountAdminCompanyUtilsService: AccountAdminCompanyUtilsService,
        public commonUtils: CommonUtilsService,
        public userAdminCreateService: UserAdminCreateService,
        public userAdminService: UserAdminService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.userAdminCreateService.emptyUserInitialization();
        this.getCountries();

        if (this.company) {
            this.countryOptionData$.value.find((country) => country.value === this.company?.country)?.name || '';
            this.company.state = (this.company.state.includes('-')) ? this.company.state.split(' - ')[0] : this.company.state;
        }
        else if (this.cloneUserData) {
            this.countryOptionData$.value.find((country) => country.value === this.cloneUserData?.userCountry)?.name || '';
            this.cloneUserData.userState = (this.cloneUserData.userState.includes('-')) ? this.cloneUserData.userState.split(' - ')[0] : this.cloneUserData.userState;
        }

        this.userCountry = (this.company) ? this.countryOptionData$.value.find((country) => country.value === this.company?.country)?.name || '' : this.countryOptionData$.value.find((country) => country.value === this.cloneUserData?.userCountry)?.name || '';
        this.getStateListByCountry(this.userCountry.split(' - ')[1]);

        this.initializeUiData();

        this.userAdminCreateService.userCreateForm.controls.userCountry.valueChanges.subscribe((selectedCountry: string) => {
            if (selectedCountry) {
                this.stateOptionData$.next([]);
                if (Array.isArray(selectedCountry)) {
                    this.getStateListByCountry(selectedCountry[0].value);
                }
                else {
                    const countrySelection = this.countryOptionData$.value.find((country) => country.value === selectedCountry);
                    if (countrySelection) {
                        this.getStateListByCountry(countrySelection.value);
                    }
                }
            }
        });
        this.userBrandFamily$.subscribe((brand) => this.brandFamily = brand);
    }

    initializeUiData() {
        if (this.cloneUserData) {
            if (this.userAdminCreateService.backToPreviousPageStep3) {
                this.userAdminCreateService.restoreFormValues();
                this.userAdminCreateService.backToPreviousPageStep3 = false;
            }
            else {
                this.userAdminCreateService.userCreateForm.controls.lastName.reset();
                this.userAdminCreateService.userCreateForm.controls.email.reset();
                this.userAdminCreateService.userCreateForm.controls.firstName.reset();
                this.userCountry = this.countryOptionData$.value.find((country) => country.value === this.cloneUserData.userCountry)?.name || '';
                this.userAdminCreateService.userCreateForm.controls.userCountry.setValue(this.userCountry);
                this.userAdminCreateService.userCreateForm.controls.employerName.setValue(this.cloneUserData.employerName);
                this.userAdminCreateService.userCreateForm.controls.companyId.setValue(this.cloneUserData.companyId);
                this.userAdminCreateService.userCreateForm.controls.userAddress1.setValue(this.cloneUserData.userAddress1);
                if (this.cloneUserData && this.cloneUserData.userAddress2) {
                    this.userAdminCreateService.userCreateForm.controls.userAddress2.setValue(this.cloneUserData.userAddress2);
                }
                this.userAdminCreateService.userCreateForm.controls.userCity.setValue(this.cloneUserData.userCity);
                this.userAdminCreateService.userCreateForm.controls.userPostalCode.setValue(this.cloneUserData.userPostalCode);
                this.userAdminCreateService.userCreateForm.controls.registrationAction.setValue('Select');
                this.getStateListByCountry(this.cloneUserData.userCountry);
            }
        }
        else if (this.userAdminCreateService.backToPreviousPageStep3) {
            this.userAdminCreateService.restoreFormValues();
            this.userAdminCreateService.backToPreviousPageStep3 = false;
        }
        else if (!this.cloneUserData && !this.userAdminCreateService.backToPreviousPageStep3) {
            this.updateCreateUI();
        }
    }

    updateCreateUI() {
        this.userAdminCreateService.userCreateForm.setValue({
            firstName: '',
            lastName: '',
            userID: '',
            userTitle: '',
            employerName: this.company.companyName || '',

            companyName: '',
            companyId: this.company.hvacCompanyId || '',
            hvacPin: '',

            userAddress1: this.company.address1 || '',
            userAddress2: this.company.address2 || '',
            userCity: this.company.city || '',

            userState: this.userState || '',
            userPostalCode: this.company.postalCode || '',
            userCountry: this.userCountry || '',

            userPhone: '',
            userCellPhone: '',
            userFax: '',

            email: '',
            contactOnly: '',

            registrationAction: 'Select',
            comments: '',
            lastLoginDate: ''
        }, { emitEvent: true });
        this.userAdminCreateService.userCreateForm.controls.lastName.reset();
        this.userAdminCreateService.userCreateForm.controls.email.reset();
        this.userAdminCreateService.userCreateForm.controls.firstName.reset();
    }

    setContactOnly(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input) {
            this.user.contactOnly = input.checked;
        }
    }

    onClickCreateUserChangePage(userCreateStep3: boolean) {
        if (userCreateStep3) {
            this.userAdminCreateService.saveFormValues();
        }
        const emptyCompany: CompanyResultDataResponse = {
            hvacCompanyId: 0,
            soldTo: '',
            shipTo: '',
            payer: '',
            companyName: '',
            state: '',
            city: '',
            phone: '',
            email: '',
            address1: '',
            address2: '',
            brandsName: '',
            companyType: '',
            country: '',
            fax: '',
            parentBrandsName: '',
            postalCode: '',
            warranty: ''
        };

        if (this.cloneUserData) {
            this.company = emptyCompany;
        }

        const createUserObj : SearchCompany = {
            company: this.company,
            userCreateStep: 'step4_userRolesFeatures'
        };

        this.userDetails.emit(this.userAdminCreateService.mapCreateUserDetails());

        if (!userCreateStep3) {
            if (this.cloneUserData) {
                createUserObj.userCreateStep = 'ReadOnlyUserInfo';
            }
            else {
                createUserObj.userCreateStep = 'step2_registeredUsersInCompany';
            }
        }
        this.createUserChangePage.emit(createUserObj);
    }

    getStateNameByStateCode(stateOptionData: Option[]): string {
        return stateOptionData?.find((state) => state.value === this.company?.state)?.name || '';
    }

    getCountryNameByCountryCode(countryOptionData: Option[]): string {
        return countryOptionData?.find((country) => country.value === this.company?.country)?.name || '';
    }

    private getCountries() {
        this.commonUtils.getCountriesEntity().subscribe((countryList) => {
            const optionList: Option[] = [];
            optionList.push({
                name: this.translate.instant('ACCOUNT_ADMIN.SELECT_COUNTRY'),
                value: ''
            });
            countryList.forEach((country: AccountAdminCountriesEntity) => {
                optionList.push({
                    name: country.name,
                    value: country.code
                });
            });
            this.countryOptionData$.next(this.accountAdminCompanyUtilsService.filterCountryArraywithMostUsed(optionList));
        });
    }

    private getStateListByCountry(selectedCountry: string) {
        if (selectedCountry?.length <= 0) {
            return;
        }
        this.commonUtils.getStateByCountry(selectedCountry).subscribe((stateList) => {
            const optionList: Option[] = [];
            optionList.push({
                name: this.translate.instant('ACCOUNT_ADMIN.SELECT_STATE'),
                value: ''
            });
            stateList.forEach((state) => {
                optionList.push({
                    name: state.name,
                    value: state.value
                });
            });
            this.stateOptionData$.next(optionList);
            this.userState = (this.company) ? this.stateOptionData$.value.find((state) => state.value === this.company.state)?.name || '' : this.stateOptionData$.value.find((state) => state.value === this.cloneUserData.userState)?.name || '';
            this.userAdminCreateService.userCreateForm.controls.userState.setValue(this.userState);
        });
    }
}
