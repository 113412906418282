export const eventNames = {
    handleRunReportClick: 'handle_run_report_click',
    handleTestEquipmentClick: 'handle_test_equipment_click',
    handleSystemsOverviewInsightsClick: 'handle_systems_overview_insights_click',
    handleSystemsOverviewFaultsLoad: 'handle_systems_overview_faults_load',
    handleRemoveQueuedControlClick: 'handle_remove_queued_control_click',
    handleRemoveConnectedControlClick: 'handle_remove_connected_control_click',
    handleDashboardAlertDeleteClick: 'handle_dashboard_alert_delete_click',
    handleDataSharingOpportunityReportDownloadClick: 'handle_data_sharing_opportunity_report_download_click',
    handleRegistrationOpportunityReportDownloadClick: 'handle_registration_opportunity_report_download_click',
    handleDistributorAdvancedSearchClick: 'handle_distributor_advanced_search_click',
    handleInternalAdvancedSearchClick: 'handle_internal_advanced_search_click',
    handleWallControlConfigChangeConfirmClick: 'handle_wall_control_config_change_confirm_click',
    handleOutdoorUnitConfigChangeConfirmClick: 'handle_outdoor_unit_config_change_confirm_click',
    handleIndoorUnitConfigChangeConfirmClick: 'handle_indoor_unit_config_change_confirm_click',
    handleFleetHealthTabClick: 'handle_fleet_health_tab_click',
    handleOpenRefreshDataPane: 'handle_open_refresh_data_pane',
    handleCustomRefreshClick: 'handle_custom_refresh_click'
};
