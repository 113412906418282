import { Component } from '@angular/core';
import { ConnectedPortalNavigationComponent } from 'private/app/views/connected-portal/components/connected-portal-navigation/connected-portal-navigation.component';

@Component({
    selector: 'hvac-connected-portal-layout-container',
    templateUrl: './connected-portal-layout-container.component.html',
    styleUrls: ['./connected-portal-layout-container.component.scss'],
    imports: [
        ConnectedPortalNavigationComponent
    ],
    standalone: true
})
export class ConnectedPortalLayoutContainerComponent {
}
