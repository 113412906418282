import { Component, Input } from '@angular/core';
import { ConnectedProduct, ProductType } from 'private/app/models/connected-product.model';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgForOf, NgIf } from '@angular/common';

export interface FactorySystemDetails {
    productInfo: ConnectedProduct[];
}

@Component({
    selector: 'hvac-factory-system-details',
    templateUrl: './factory-system-details.component.html',
    styleUrls: ['./factory-system-details.component.scss'],
    standalone: true,
    imports: [
        CommonComponentsModule,
        TranslateModule,
        NgForOf,
        NgIf
    ]
})
export class FactorySystemDetailsComponent {
    @Input() isLoading: boolean;
    @Input() isWallControlConnected: boolean | null;
    @Input() data: FactorySystemDetails | null;

    readonly ProductType = ProductType;
}
