import { Component, Input, OnInit } from '@angular/core';
import { CharSymbol } from 'common/enums/char-symbol';
import { ActivityMap } from 'private/app/models/wall-control.model';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { Subject } from 'rxjs';

export interface WallControlProgramSettings {
    activeActivityTime: string;
    activities: (ActivityMap | null)[] | null;
    isHoldOn: boolean;
}

@Component({
    selector: 'hvac-wall-control-program-settings',
    templateUrl: './wall-control-program-settings.component.html',
    styleUrls: ['./wall-control-program-settings.component.scss']
})
export class WallControlProgramSettingsComponent implements OnInit {
    @Input() data: WallControlProgramSettings;

    public tempUnitFormat: string = CharSymbol.Fahrenheit;
    public tempUnitFormat$: Subject<string | null | undefined>;
    public readonly CharSymbol = CharSymbol;

    constructor(productService: ProductService) {
        this.tempUnitFormat$ = productService.tempUnitFormat$;
    }

    ngOnInit(): void {
        this.tempUnitFormat$.subscribe((data) => {
            if (data === 'C') {
                this.tempUnitFormat = CharSymbol.Celsius;
            }
        });
    }
}
