import { Component, Input } from '@angular/core';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

export interface ConnectedPortalStat {
    label: string;
    value: string | number | undefined;
    comingSoon?: boolean;
}

@Component({
    selector: 'hvac-connected-portal-stats',
    templateUrl: './connected-portal-stats.component.html',
    styleUrls: ['./connected-portal-stats.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, NgForOf, NgClass, TranslateModule, NgIf]
})
export class ConnectedPortalStatsComponent {
    @Input() statsData: ConnectedPortalStat[] = [];
    @Input() isLoading?: boolean;
}
