import { AccountAdminDetailsEditConfiguration, CompanyCategoryType } from './company-query-configuration';


export const MarineDistributorConfig = {
    configType: CompanyCategoryType.MARINE_DISTRIBUTOR,
    formConfig: {
        companyUrl: { view: true },
        parentCompany: {
            view: true,
            edit: true
        },
        soldTo: {
            view: true,
            edit: false
        },
        shipToNumber: {
            view: true,
            edit: true
        },
        payer: {
            view: true,
            edit: true
        },
        searchRadius: {
            view: true,
            edit: true
        },
        divRegNumber: {
            view: true,
            edit: true
        },
        address1: {
            view: true,
            edit: true
        },
        address2: {
            view: true,
            edit: true
        },
        country: {
            view: true,
            edit: true
        },
        state: {
            view: true,
            edit: true
        },
        city: {
            view: true,
            edit: true
        },
        postalCode: {
            view: true,
            edit: true
        },
        phone: {
            view: true,
            edit: true
        },
        mobile: {
            view: true,
            edit: true
        },
        tollFree: {
            view: true,
            edit: true
        },
        fax: {
            view: true,
            edit: true
        },
        email: {
            view: true,
            edit: true
        },
        rsm: {
            view: true,
            edit: true
        },
        prsm: {
            view: true,
            edit: true
        },
        accountManager: {
            view: true,
            edit: true
        },
        partAccountManager: {
            view: true,
            edit: true
        },
        tsm: {
            view: true,
            edit: true
        },
        pricingAnalyst: {
            view: true,
            edit: true
        },
        latitude: {
            view: true,
            edit: true
        },
        longitude: {
            view: true,
            edit: true
        },
        locatorName: {
            view: true,
            edit: true
        },
        showOnDistributorLocator: {
            view: true,
            edit: true
        },
        isPrimary: {
            view: true,
            edit: true
        },
        isAssignable: {
            view: true,
            edit: true
        },
        brands: { view: true },
        priceGroup: { view: true },
        priceList: { view: true }
    },
    association: {
        associatedDistributor: false,
        associatedContact: true,
        addContact: false,
        associatedDealer: true
    },
    managerInformation: {
        show: true,
        partRSM: { view: true },
        partAM: { view: true }
    },
    addBrands: true

} as AccountAdminDetailsEditConfiguration;
