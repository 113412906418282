import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { AppConstants } from 'common/app-constants';
import { BaseResponse } from 'common/models/account-status';
import { ContentHeadingData } from 'common/models/content-heading';
import { ToastService } from 'common/services/toast.service';
import { AccountAdminProgramEnrollments, EnrollmentOption, EnrollmentStatusPayload } from 'private/app/models/account-admin-program.model';
import { AccountAdminApiService } from 'private/app/services/account-admin/account-admin-api.service';
import { AccountAdminExportService } from 'private/app/services/account-admin/account-admin-export.service';
import { AccountAdminExtendedService } from 'private/app/services/account-admin/account-admin-extended.service';
import { AccountAdminProgramEnrollmentService } from 'private/app/services/account-admin/account-admin-program-enrollment.service';
import { ProgramEnrollmentsService } from 'private/app/services/program-enrollments.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-program-enrollments',
    templateUrl: './program-enrollments.component.html',
    styleUrls: ['./program-enrollments.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProgramEnrollmentsComponent implements OnInit {
    public breadCrumbData: ContentHeadingData = {
        Content: { title: this.translate.instant('ACCOUNT_ADMIN.PROGRAM_ENROLLMENTS.PROGRAM_ENROLLMENTS_TITLE') },
        breadCrumbs: [
            {
                title: this.translate.instant('ACCOUNT_ADMIN.PARENT_1'),
                url: '/admin'
            },
            {
                title: this.translate.instant('ACCOUNT_ADMIN.PARENT_2'),
                url: '/admin/admin tools'
            }
        ]
    };

    public hvacpCompanyName: string;
    public hvacpDealerId: string;
    public isLoading: boolean = true;
    public isTableLoading: boolean = true;
    public currentPage: number = AppConstants.currentPage;
    public pageSize: number = AppConstants.pageSize;
    public totalPages: number;
    public isEnrollModalVisible: boolean = false;
    public isEnrollActionsEnabled: boolean = false;
    public enrollment: AccountAdminProgramEnrollments;
    public enrollmentAction: EnrollmentOption;
    public enrollmentConfirmMessage: string;
    public programEnrollmentsData$ = new BehaviorSubject<AccountAdminProgramEnrollments[]>([]);
    public currentPageEnrollmentData$ = new BehaviorSubject<AccountAdminProgramEnrollments[]>([]);
    // public disableExportButton: boolean = true;
    public isAllEnrllmntsSlctdFrCrntFltr = false;
    public enrollmentActionForm: FormGroup;
    public enrollmentStatusPayload: EnrollmentStatusPayload;
    private hvacpCompanyId: string;
    private selectedEnrollments: Map<number, AccountAdminProgramEnrollments> = new Map();

    private ngOnDestroy$ = new Subject();

    constructor(
        public programEnrollmentsService: ProgramEnrollmentsService,
        public accountAdminProgramEnrollmentService : AccountAdminProgramEnrollmentService,
        @Inject(AccountAdminExportService) public accountAdminExportService: AccountAdminExportService,
        private accountAdminExtendedService: AccountAdminExtendedService,
        private readonly accountAdminApiService: AccountAdminApiService,
        private translate: TranslateService,
        private router: Router,
        private store: Store,
        private toastService: ToastService
    ) {
        this.router.events.pipe(takeUntil(this.ngOnDestroy$)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const urlSplit = event.urlAfterRedirects.split('/').slice(-1).toString() || '';
                this.hvacpCompanyId = urlSplit ? urlSplit.split('?compName=')[0] : '';
                this.hvacpCompanyName = urlSplit ? decodeURIComponent(urlSplit.substring(urlSplit.indexOf('compName=') + 9, urlSplit.lastIndexOf('&compId'))) : '';
                this.hvacpDealerId = urlSplit ? decodeURIComponent(urlSplit.split('&compId=')[1]) : '';
            }
        });
    }

    ngOnInit(): void {
        this.accountAdminExtendedService.programEnrollmentsBackAction.pipe(takeUntil(this.ngOnDestroy$), skip(1)).subscribe((value) => {
            if (value) {
                this.store.dispatch(new Navigate(['Admin/Admin-Tools/account-admin.html/company', this.hvacpCompanyId]));
            }
        });
        if (this.hvacpCompanyId) {
            this.getProgramEnrollments();
        }
        this.toastService;
    }

    getProgramEnrollments() {
        this.accountAdminExtendedService.getProgramEnrollments(this.hvacpCompanyId).subscribe((enrollmentsData) => {
            this.isLoading = false;
            this.isTableLoading = false;
            this.totalPages = Math.ceil(enrollmentsData.length / this.pageSize);
            this.programEnrollmentsData$.next(enrollmentsData);
            const group: {[key: string]: FormControl} = {};
            enrollmentsData.forEach((item: AccountAdminProgramEnrollments) => {
                item.isChecked = false;
                item.enrollmentOptions = this.programEnrollmentsService.getEnrollmentActionOptions(item);
                group[`enrollmentAction${item.programId}`] = new FormControl('');
            });
            this.enrollmentActionForm = new FormGroup(group);
            this.currentPageEnrollmentData$.next(enrollmentsData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize));
        });
    }

    handlePageChange(page: number) {
        this.currentPage = parseInt(page.toString(), this.pageSize);
        const filterData = this.programEnrollmentsData$.value;
        this.currentPageEnrollmentData$.next(
            filterData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
        );
    }

    onSelectDealerConversionEnrollment(enrollment: AccountAdminProgramEnrollments, enrollmentAction: EnrollmentOption) {
        if (enrollment.enrollmentWorkflowId === 1 && enrollmentAction.name === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_ACTIONS.ACTIVE')) {
            this.programEnrollmentsService.openProgramEnrollmentForm(enrollment, this.hvacpCompanyId);
        }
        else {
            this.isEnrollModalVisible = true;
            this.enrollment = enrollment;
            this.enrollmentAction = enrollmentAction;
            this.enrollmentConfirmMessage = enrollmentAction.confirmDetails;
        }
    }

    backToCompanyDetails() {
        this.accountAdminExtendedService.programEnrollmentsBackAction.next(true);
    }

    exportSelectedPrograms() {
        this.isTableLoading = true;
        this.accountAdminExtendedService.exportProgramEnrollments(this.hvacpCompanyId).subscribe((response: HttpResponse<string>) => {
            this.isTableLoading = false;
            this.accountAdminExportService.doExport(response);
        },
        (err) => {
            if (err) {
                this.isTableLoading = false;
                this.toastService.add({
                    content: this.translate.instant('ACCOUNT_ADMIN.ASSOCIATION_EXPORT_ERROR'),
                    theme: 'error',
                    id: 'ProgramEnrollment',
                    closeable: true,
                    autoClose: true
                });
            }
        });
    }

    selectEnrollment(enrollment: AccountAdminProgramEnrollments) {
        enrollment.isChecked = !enrollment.isChecked;
        if (this.selectedEnrollments.get(enrollment.programId)) {
            this.selectedEnrollments.delete(enrollment.programId);
        }
        else {
            this.selectedEnrollments.set(enrollment.programId, enrollment);
        }
        // this.disableExportButton = this.selectedEnrollments.size === 0;
        this.isAllEnrllmntsSlctdFrCrntFltr = this.selectedEnrollments.size === this.programEnrollmentsData$.value.length;
    }

    handleSelectAll(event: Event) {
        const isSelectAllChecked = (event.target as HTMLInputElement).checked;

        this.programEnrollmentsData$.value.forEach((program) => {
            program.isChecked = isSelectAllChecked;
            if (isSelectAllChecked) {
                this.selectedEnrollments.set(program.programId, program);
            }
            else {
                this.selectedEnrollments.delete(program.programId);
            }
        });
        // this.disableExportButton = this.selectedEnrollments.size === 0;
        this.isAllEnrllmntsSlctdFrCrntFltr = true;
    }

    handleCloseModal() {
        if (this.isEnrollModalVisible) {
            this.isEnrollModalVisible = false;
            this.enrollmentActionForm.controls[`enrollmentAction${this.enrollment.programId}`].setValue('', { emitEvent: true });
        }
    }

    handleOkModal() {
        this.isEnrollModalVisible = false;
        this.confirmSingleEnrollment();
    }

    confirmSingleEnrollment() {
        if (this.enrollmentAction.value) {
            this.isTableLoading = true;
            if (this.enrollmentAction.enrollmentType === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.STATUS')) {
                this.enrollmentStatusPayload = {
                    programId: Number(this.enrollment.programId),
                    dealerCompanies: [Number(this.hvacpDealerId)],
                    enrollmentStatusId: Number(this.enrollmentAction.value)
                };
                this.accountAdminApiService.accountAdminProgramEnrollmentStatus(this.enrollmentStatusPayload).subscribe((data: BaseResponse) => {
                    if (data?.status === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.SUCCESS')) {
                        let message: string;
                        if (Number(this.enrollment.enrollmentStatus) === 0) {
                            const messageObject = {
                                dealerName: this.hvacpCompanyName,
                                dealerDisplayId: this.hvacpCompanyId,
                                programName: this.enrollment.programName
                            };
                            message = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ENROLLMENT_STATUS_SINGLE_SUCCESS', messageObject);
                        }
                        else {
                            message = data?.details;
                        }
                        this.displayToast(message, 'success');
                        this.getProgramEnrollments();
                    }
                },
                (error) => {
                    this.isTableLoading = false;
                    this.displayToast(error.error?.message, 'error');
                });
            }
            else if (this.enrollmentAction.enrollmentType === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.LEVEL')) {
                this.accountAdminApiService.accountAdminProgramEnrollmentLevel(Number(this.enrollment.programId), Number(this.hvacpDealerId), Number(this.enrollmentAction.value)).subscribe((data: BaseResponse) => {
                    if (data?.status === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.SUCCESS')) {
                        this.displayToast(data?.details, 'success');
                        this.getProgramEnrollments();
                    }
                },
                (error) => {
                    this.isTableLoading = false;
                    this.displayToast(error.error?.message, 'error');
                });
            }
        }
    }

    private displayToast(content: string, theme: 'success' | 'error') {
        this.toastService.raiseDefaultToastMessage(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_TOAST_MESSAGE_ID'), content, theme);
    }
}
