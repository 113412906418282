import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptions, ApiOptionsService } from 'common/services/api-options/api-options.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CountiesResponseData } from '../models/manage-counties.model';
import { environment } from 'common/environments/environment';
import { BaseResponse } from 'common/models/account-status';

@Injectable({ providedIn: 'root' })
export class ManageCountiesService {
  public backAction = new BehaviorSubject(false);

  constructor(
      private readonly httpClient: HttpClient,
      private apiOptions: ApiOptionsService
  ) {}

  public getCounties(id: string): Observable<CountiesResponseData> {
      const options$ = this.apiOptions.getAuthedHttpOptions();

      return options$.pipe(
          switchMap((options: ApiOptions) => this.httpClient.get<CountiesResponseData>(`${environment.api.accountAdminCompanyCounties}?hvacCompanyID=${id}`, { ...options })),
          catchError((err: HttpErrorResponse) => {
              throw err;
          })
      );
  }

  public postUpdatedCounties(countiesData: CountiesResponseData) {
      const options$ = this.apiOptions.getAuthedHttpOptions();

      return options$.pipe(
          switchMap((options) => this.httpClient.post<BaseResponse>(`${environment.api.accountAdminCompanyCounties}`, countiesData, { ...options })),
          catchError((err: HttpErrorResponse) => {
              throw err;
          })
      );
  }

  public smoothScrollToTop() {
      window.scroll({
          behavior: 'smooth',
          top: 0,
          left: 0
      });
  }
}
