<div class="hvac-soldto-assignment-container">
    <div class="hvac-manage-dealer-soldto-search-container">
        <p class="hvac-content-header-sub-title">
            <span>{{ ( showAssignedSoldTo ? 'USER_ADMIN.USER_RELATIONSHIPS.ASSIGNED_SOLD_TOS' : 'USER_ADMIN.USER_RELATIONSHIPS.AVAILABLE_SOLD_TOS' ) | translate  }}</span>
        </p>
        <div class="hvac-row">
            <button class="hvac-soldto-button" [ngClass]="{ 'active': !showAssignedSoldTo}" hvacButton="default" (click)="toggleTable('availableSoldToTable')">
                <span>{{ 'USER_ADMIN.USER_RELATIONSHIPS.AVAILABLE_SOLD_TOS' | translate }}</span>
                <span *ngIf="availableSoldToArray.length" class="hvac-button-badge">{{ availableSoldToArray.length }}</span>
            </button>
            <button class="hvac-soldto-button" [ngClass]="{ 'active': showAssignedSoldTo}" hvacButton="default" (click)="toggleTable('assignedSoldToTable')">
                <span>{{'USER_ADMIN.USER_RELATIONSHIPS.ASSIGNED_SOLD_TOS' | translate }}</span>
                <span *ngIf="assignedSoldToArray.length" class="hvac-button-badge">{{ assignedSoldToArray.length }}</span>
            </button>
        </div>
    </div>
    <div class="hvac-manage-dealer-soldto-table-container">
        <div class="hvac-manage-dealer-soldto-table">
            <hvac-soldto-table [tableTypeIdentifier]="showAssignedSoldTo ? assignedSoldToTable : availableSoldToTable"
                   [soldToArray]="showAssignedSoldTo ? assignedSoldToArray : availableSoldToArray"
                   (onAddAndRemove)="assignSoldToAction()" (selectedSoldToArray)="onSelectedSoldTos($event)" (isCancelButtonclicked)="isCancelButtonclickedFn($event)"> 
            </hvac-soldto-table>
        </div>
    </div>
</div>
<ng-template [ngIf]="isLoading">
    <utc-loading-spinner></utc-loading-spinner>
</ng-template>
