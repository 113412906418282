/* eslint-disable max-lines */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { Toast, ToastService } from 'common/services/toast.service';
import { DiagnosticEquipments, DiagnosticEquipmentStageParameters, DiagnosticTest } from 'private/app/models/connected-portal-system-diagnostics.model';
import { SystemDiagnosticsService } from 'private/app/services/connected-portal/system-diagnostics.service';
import { ApiErrorName, ApiResponseCode, SYSTEM_DIAGNOSTIC_TOAST_TIME } from 'private/app/views/connected-portal/constants';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { differenceInMinutes } from 'date-fns';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { EMPTY, of } from 'rxjs';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { CommonComponentsModule } from 'common/components/common-components.module';

enum SystemDiagnosticNotification {
    CannotBeTested = 'cannotBeTested',
    ConnectionInterrupted = 'connectionInterrupted',
    ConnectionError = 'connectionError',
    TestComplete = 'testCompleted',
    ConnectionInProgress = 'connectionInProgress',
    TestInProgressError='testInProgressError',
    DeviceDisconnectedOrUnAck = 'deviceDisconnectedOrUnacknowledged',
    TestCanceledByDevice = 'testCanceledByDevice'
}

@Component({
    selector: 'hvac-system-diagnostic-equipment',
    templateUrl: './system-diagnostic-equipment.component.html',
    styleUrls: ['./system-diagnostic-equipment.component.scss'],
    imports: [
        NgForOf,
        CommonComponentsModule,
        NgClass,
        TranslateModule,
        NgIf
    ],
    standalone: true
})
export class SystemDiagnosticEquipmentComponent extends BaseComponent implements OnInit {
    @Input() equipmentsList: DiagnosticEquipments[];
    @Input() dealerId: string;
    @Input() serialNo: string;
    @Output() onReset = new EventEmitter<Toast>();
    @Output() onEquipmentSelect = new EventEmitter<DiagnosticEquipments>();
    @Output() onWebSocketDataError = new EventEmitter<ApiErrorName>();

    public selectedEquipment: DiagnosticEquipments;
    public isTestInProgress: boolean;
    public isCancelTestInitiated: boolean = false;
    public toastOutlet = 'systemDiagnosticsEquipmentToast';
    public elapsedTime: number = 0;
    public timer: boolean;
    public formattedElapsedTime: string;

    private stageParamList = this.setStageParamList();
    private currentTestStage: DiagnosticTest;
    private isWSConnectionInProgress = false;
    private startTime: number;


    constructor(
        private systemDiagnosticSvc: SystemDiagnosticsService,
        private toastService: ToastService,
        private translate: TranslateService,
        private productService: ProductService
    ) {
        super();
    }

    ngOnInit(): void {
        this.toastService.removeAll();

        const defaultSelectedEquipment = this.getSelectedEquipment();

        if (defaultSelectedEquipment) {
            this.selectedEquipment = defaultSelectedEquipment;

            if (!this.selectedEquipment.isTestable) {
                this.showToast(SystemDiagnosticNotification.CannotBeTested);
            }

            this.onEquipmentSelect.next(this.selectedEquipment);
        }
        else if (this.equipmentsList) {
            if (this.currentTestStage) {
                this.currentTestStage.hasStarted = false;
            }
        }

        this.systemDiagnosticSvc.errorMessages$.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe(() => {
            this.resetOnSocketError();
        });

        this.systemDiagnosticSvc.webSocketDataError$.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((errorString) => {
            if (errorString) {
                switch (errorString) {
                    case ApiErrorName.DisconnectUserEnded:
                        this.onWebSocketDataError.emit(errorString as ApiErrorName);
                        break;
                    default:
                        this.showToast(SystemDiagnosticNotification.ConnectionInterrupted);
                        this.resetConnection();
                        break;
                }
            }
        });

        this.systemDiagnosticSvc.webSocketError$.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe(() => {
            this.resetOnSocketError();
        });

        this.systemDiagnosticSvc.webSocketStartAck$.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe(() => {
            this.isWSConnectionInProgress = false;

            if (this.isCancelTestInitiated) {
                this.isCancelTestInitiated = false;
                this.timer = false;
                this.toggleStageTest(this.currentTestStage);
            }
        });

        this.watchSocketConnectionLoss();
        this.handlePollingInactiveMode();
    }

    ngOnDestroy() {
        this.clearPolling();
        this.systemDiagnosticSvc.exitDiagnosticsMode(this.serialNo, this.dealerId);
        this.toastService.removeAll();

        super.ngOnDestroy();
    }

    trackByEquipmentList(_index: number, equipmentList: DiagnosticEquipments) {
        return equipmentList.equipmentName;
    }

    selectEquipment(equipment: DiagnosticEquipments) {
        if (equipment.isSelected) {
            return;
        }

        const selectedEquipment = this.getSelectedEquipment();

        if (selectedEquipment) {
            selectedEquipment.isSelected = false;
            selectedEquipment.hasAlertNotification = false;
        }

        equipment.isSelected = true;
        this.selectedEquipment = equipment;
        this.resetConnection();

        if (!this.selectedEquipment.isTestable) {
            this.showToast(SystemDiagnosticNotification.CannotBeTested);
        }

        this.onEquipmentSelect.next(this.selectedEquipment);
    }

    getSelectedEquipment(): DiagnosticEquipments | undefined {
        let connectedEquipment: DiagnosticEquipments | undefined;

        if (this.equipmentsList) {
            connectedEquipment = this.equipmentsList.find(
                (eqp: DiagnosticEquipments) => eqp.isSelected
            );
        }

        return connectedEquipment;
    }

    toggleStageTest(testStage: DiagnosticTest) {
        if (!this.selectedEquipment.isTestable) {
            this.showToast(SystemDiagnosticNotification.CannotBeTested);

            return;
        }

        if (this.isWSConnectionInProgress) {
            this.isCancelTestInitiated = true;

            return;
        }

        this.toastService.removeAll();
        this.selectedEquipment.hasAlertNotification = false;

        if (this.currentTestStage && this.currentTestStage.testId !== testStage.testId) {
            this.currentTestStage.hasStarted = false;
            this.systemDiagnosticSvc.resetTestState(this.serialNo, this.dealerId);
        }
        if (testStage.hasStarted) {
            this.clearPolling();
            testStage.hasStarted = false;
            this.timer = false;
            this.systemDiagnosticSvc.resetTestState(this.serialNo, this.dealerId);
        }
        else {
            this.clearPolling(true);
            testStage.hasStarted = true;
            this.isWSConnectionInProgress = true;
            this.isTestInProgress = true;
            this.setSelectedTestStage(testStage, this.selectedEquipment.serialNumber);

            this.productService.activateEcobeeControlBySerialNo(this.serialNo, this.dealerId).pipe(
                switchMap((activateRes) => {
                    if (activateRes?.code === ApiResponseCode.SUCCESS) {
                        return this.systemDiagnosticSvc.runDiagnosticsTestBySerialNo({
                            id: this.serialNo,
                            data: this.stageParamList
                        }, this.serialNo);
                    }

                    return of(activateRes);
                }),
                catchError(() => {
                    this.showToast(SystemDiagnosticNotification.ConnectionError);

                    this.isWSConnectionInProgress = false;
                    this.isTestInProgress = false;
                    this.isCancelTestInitiated = false;
                    this.clearPolling();
                    this.timer = false;

                    if (this.currentTestStage) {
                        this.currentTestStage.hasStarted = false;
                    }

                    return EMPTY;
                })
            ).subscribe((res) => {
                if (res?.code !== ApiResponseCode.SUCCESS) {
                    this.isWSConnectionInProgress = false;
                    this.isTestInProgress = false;
                    this.isCancelTestInitiated = false;
                    this.clearPolling();
                    this.timer = false;

                    if (this.currentTestStage) {
                        this.currentTestStage.hasStarted = false;
                    }

                    if (res?.code === ApiResponseCode.SERVICE_UNAVAILABLE) {
                        const hasNamedError = res.data?.every((datum) => {
                            switch (datum.name) {
                                case ApiErrorName.DiagnosticsTestCompletionDatetime: {
                                    const testMinutesRemaining = datum.value
                                        ? differenceInMinutes(new Date(datum.value), Date.now())
                                        : this.translate.instant('CONNECTED_PORTAL.NOT_AVAILABLE');

                                    this.showToast(SystemDiagnosticNotification.TestInProgressError, { testMinutesRemaining });

                                    return true;
                                }
                                case ApiErrorName.DeviceDisconnectedError:
                                case ApiErrorName.DeviceCommandUnacknowledgedError: {
                                    this.showToast(SystemDiagnosticNotification.DeviceDisconnectedOrUnAck);

                                    return true;
                                }
                                default:
                                    return false;
                            }
                        });

                        if (!hasNamedError) {
                            this.showToast(SystemDiagnosticNotification.ConnectionError);
                        }
                    }
                    else {
                        this.showToast(SystemDiagnosticNotification.ConnectionError);
                    }
                }
            });
        }

        this.currentTestStage = testStage;
    }

    startTimer() {
        this.startTime = Date.now();
        this.timer = true;
        this.updateElapsedTime();
    }

    updateElapsedTime() {
        setInterval(() => {
            if (this.timer) {
                const currentTime = Date.now();
                this.elapsedTime = currentTime - this.startTime;
                this.formatElapsedTime();
            }
        }, 1000);
    }

    formatElapsedTime(): void {
        const seconds = Math.floor((this.elapsedTime / 1000) % 60);
        const minutes = Math.floor((this.elapsedTime / (1000 * 60)) % 60);
        const hours = Math.floor(this.elapsedTime / (1000 * 60 * 60));

        this.formattedElapsedTime = `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
    }

    padZero(num: number): string {
        return num < 10 ? `0${num}` : num.toString();
    }


    sortEquipment(a: DiagnosticEquipments, b: DiagnosticEquipments) {
        return a.displayOrder - b.displayOrder;
    }

    resetConnection() {
        this.clearPolling();
        this.systemDiagnosticSvc.resetTestState(this.serialNo, this.dealerId);
        this.toastService.removeAll();
        if (this.currentTestStage) {
            this.currentTestStage.hasStarted = false;
        }
    }

    private watchSocketConnectionLoss() {
        this.systemDiagnosticSvc.wsPollingListener$().pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((connectionStatus) => {
            if (connectionStatus === 'disconnect') {
                this.showToast(SystemDiagnosticNotification.ConnectionInterrupted);
            }
            else {
                this.toastService.remove('cp-diag-test-conn-interrupt');
            }
        });
    }

    private clearPolling(toReset?: boolean) {
        if (toReset) {
            this.systemDiagnosticSvc.resetPollingTimer();
            this.startTimer();
        }
        else {
            this.systemDiagnosticSvc.clearPollingTimer();
        }

        this.stageParamList = this.setStageParamList();
        this.isTestInProgress = false;
        this.isCancelTestInitiated = false;
        this.onReset.next();
    }

    private showToast(msgType: SystemDiagnosticNotification, data?: { [key: string]: string | number }) {
        this.toastService.removeAll();

        const defaultToastProps: Partial<Toast> = {
            theme: 'error',
            outletName: this.toastOutlet,
            closeable: true
        };

        switch (msgType) {
            case SystemDiagnosticNotification.CannotBeTested:
                this.toastService.add({
                    ...defaultToastProps,
                    theme: 'info',
                    content: this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.TOAST.EQUIPMENT_NOT_TESTABLE')
                });
                break;
            case SystemDiagnosticNotification.ConnectionInterrupted:
                this.toastService.add({
                    ...defaultToastProps,
                    content: this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.TOAST.CONNECTION_INTERRUPTED'),
                    id: 'cp-diag-test-conn-interrupt'
                });
                break;
            case SystemDiagnosticNotification.ConnectionError:
                this.toastService.add({
                    ...defaultToastProps,
                    content: this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.TOAST.CONNECTION_ERROR')
                });
                break;
            case SystemDiagnosticNotification.ConnectionInProgress:
                this.toastService.add({
                    ...defaultToastProps,
                    content: this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.TOAST.CONNECTION_IN_PROGRESS'),
                    autoClose: true,
                    timeOut: SYSTEM_DIAGNOSTIC_TOAST_TIME
                });
                break;
            case SystemDiagnosticNotification.TestInProgressError:
                this.toastService.add({
                    ...defaultToastProps,
                    content: this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.TOAST.TEST_ALREADY_IN_PROGRESS', { testMinutesRemaining: data?.testMinutesRemaining }),
                    autoClose: true,
                    timeOut: SYSTEM_DIAGNOSTIC_TOAST_TIME
                });
                break;
            case SystemDiagnosticNotification.DeviceDisconnectedOrUnAck:
                this.toastService.add({
                    ...defaultToastProps,
                    content: this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.TOAST.CONNECTION_CANNOT_BE_ESTABLISHED')
                });
                break;
            case SystemDiagnosticNotification.TestCanceledByDevice:
                this.toastService.add({
                    ...defaultToastProps,
                    content: this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.TOAST.TEST_CANCELED_BY_DEVICE')
                });
                break;
            default:
                break;
        }
    }

    private handlePollingInactiveMode() {
        this.systemDiagnosticSvc.getIsPollingInactive$().pipe(
            takeUntil(this.ngOnDestroy$)
        )
            .subscribe(() => {
                if (this.currentTestStage) {
                    this.currentTestStage.hasStarted = false;
                }
                this.selectedEquipment.hasAlertNotification = false;
                this.timer = false;
                this.systemDiagnosticSvc.resetTestState(this.serialNo, this.dealerId);
                this.onReset.next();
                this.isTestInProgress = false;
                this.toastService.add({
                    content: this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.TOAST.TEST_COMPLETED'),
                    id: 'cp-diag-test-complete',
                    outletName: this.toastOutlet,
                    closeable: true,
                    autoClose: true,
                    timeOut: SYSTEM_DIAGNOSTIC_TOAST_TIME
                });
            });
    }

    private setStageParamList() {
        const data = Object.values(DiagnosticEquipmentStageParameters)
            .reduce((stageParamsUploadData, value: string) => {
                stageParamsUploadData[value] =
                (
                    value === DiagnosticEquipmentStageParameters.HUMIDIFIER_ON
                    || value === DiagnosticEquipmentStageParameters.VENTILATION_ON
                    || value === DiagnosticEquipmentStageParameters.DEHUMIDIFIER_ON
                )
                    ?
                    false
                    :
                    0;

                return stageParamsUploadData;
            }, {} as Record<string, boolean | number>);

        return data;
    }

    private setSelectedTestStage(testStage: DiagnosticTest, stageParam: string) {
        this.stageParamList[stageParam] =
        (
            stageParam === DiagnosticEquipmentStageParameters.HUMIDIFIER_ON
            || stageParam === DiagnosticEquipmentStageParameters.VENTILATION_ON
            || stageParam === DiagnosticEquipmentStageParameters.DEHUMIDIFIER_ON
        )
            ?
            true
            :
            testStage.testId;
    }

    private resetOnSocketError() {
        this.showToast(SystemDiagnosticNotification.ConnectionError);
        this.isWSConnectionInProgress = false;
        this.isTestInProgress = false;
        this.isCancelTestInitiated = false;
        this.clearPolling();
        this.timer = false;

        if (this.currentTestStage) {
            this.currentTestStage.hasStarted = false;
        }
    }
}
