export enum EventType {
    ELIGIBLE = 'eligible',
    NOT_ELIGIBLE = 'not eligible',
    ENROLLED = 'enrolled',
    NONE = 'none'
}

export enum Activity {
    SCHEDULED= 'scheduled',
    RESPONDING = 'responding',
    INACTIVE = 'inactive',
    NONE = 'none'
}

export type EnergyEventsType = 'eligible' | 'enrolled' | 'notEligible' | 'scheduled' | 'responding' | 'inactive';
