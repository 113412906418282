/* eslint-disable max-lines */

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ProductImagesService {
    private models = [
        'SYSTXBB4ZC01',
        '187B',
        '180B',
        'SYSTXBBECC01-A',
        'SYSTXBBECF01-B',
        'SYSTXBBECN01-A',
        'SYSTXBBECW01-A',
        '619KC',
        '619KD',
        '280A',
        '286B',
        '289B',
        '186B',
        '189BNV',
        '187BNC',
        'SYSTXBBECC01-B',
        'SYSTXBBWEC01-B',
        '619KF',
        '538FR',
        '619FB',
        '619KB',
        '619PHA',
        '880TA',
        '986T',
        '285B',
        '285BNH',
        '286BNC',
        '288BNV',
        '186CNV',
        '538KR',
        '987M',
        '315A',
        '284ANV',
        'FE4A',
        'DGAPA',
        'GAPAA',
        'GAPAB',
        'GC',
        'GZ',
        '38MPRA',
        'SYSTXBBWEF01',
        '40VMD',
        'LON',
        '38MHRBC',
        '38MHRC',
        'SYSTXCCITN01-A',
        'SYSTXCCITC01-B',
        'SYSTXCCITC01-A',
        'SYSTXCCITW01-A',
        'FE5',
        '40GJD',
        '40GJC',
        'SYSTXCCWIC01-B',
        'SYSTXCCWIF01-B',
        '25HNB5',
        '25HNB6',
        '25HNB9',
        '25HNH5',
        '25VNA8',
        'SYSTXCCICF01-B',
        '58CVA',
        '58TN',
        '59TN6',
        '40GJF',
        '40GRQ',
        '40GJB',
        '40MPHA',
        '50XL',
        '24ANB1',
        '24ANB6',
        '24ANB7',
        '24ANB7--C',
        '24VNA9',
        '38GRQ',
        '50XT-A',
        '25HNB6--C',
        '38GJQ',
        '24VNA0',
        '24VNA6',
        '25VNA0',
        '25VNA4',
        '59MN7',
        '48XL-A',
        '48XT-A',
        '33ZCSPTCO2-01',
        '33ZCSPTCO2LCD-01',
        '58TP',
        '05FY',
        '05G',
        '05HY',
        '05T',
        '06CC',
        '06D',
        '06E',
        '06M',
        '06T',
        '06TS',
        '06TT',
        '06TU',
        '06TV',
        '06Z',
        '07D',
        '07E',
        '07FY',
        '07HY',
        '09AZ',
        '09DP',
        '09XC',
        '105A',
        '10RT',
        '113A',
        '114C',
        '114CNC',
        '114S',
        '115S',
        '115SAN---C',
        '116B',
        '123A',
        '124ANS',
        '126B',
        '126CNA',
        '126S',
        '127A',
        '127T',
        '127T-----C',
        '17DA',
        '180CNV',
        '19DV',
        '19MV',
        '19XRV',
        '214D',
        '214DNC',
        '215B',
        '215S',
        '224ANS',
        '225B',
        '225S',
        '226A',
        '226C',
        '227T',
        '23XRV',
        '24AAA5',
        '24ABB3',
        '24ABC6',
        '24ACA4--C',
        '24ACB3',
        '24ACB7',
        '24ACC4',
        '24ACC6',
        '24AHA4',
        '24APB6',
        '24SCA4',
        '24SCA5',
        '24SCA5---C',
        '24SPA6',
        '24TPA7',
        '24TPA7---C',
        '25HBC5',
        '25HCB6',
        '25HCC5',
        '25HCE4',
        '25HCE4--C',
        '25HHA4',
        '25HPB6',
        '25SCA5',
        '25SPA5',
        '25TPA7',
        '28B',
        '30HX',
        '30MP',
        '30RAP',
        '30RB',
        '30RC',
        '30XA',
        '30XV',
        '30XW',
        '315SA',
        'R33CNTPILOT-01-R',
        'R33CNTRAN485-01-R',
        'R33CNTRANLON-01-R',
        '33CONNECTSTAT',
        '33CONNECTSTAT43',
        '33CONNECTSTAT43FX',
        '33CS2PP2S-03',
        '33CS2PPRH-03',
        '33CSCNACHP-01',
        '33CSCNACHP-FC',
        '33CSCPACHP-01',
        '33CSCPACHP-FC',
        '33CSENTHSW',
        '33CSENTSEN',
        '-R33CSPREMLK-01-R',
        '33UNIVCTRL-01',
        '33ZCBC-01',
        '33ZCFANCOL',
        '33ZCFANTRM',
        '33ZCSECTRM',
        '33ZCSENDAT',
        '33ZCSENDRH-02',
        '33ZCSENOAT',
        '33ZCSENORH-02',
        '33ZCSENPAT',
        '33ZCSENSAT',
        '33ZCSENSRH-02',
        '33ZCT55CO2-02',
        '33ZCT55SPT',
        '33ZCT56CO2-02',
        '33ZCT56SPT',
        '33ZCT59SPT',
        '33ZCVAVTRM',
        '33ZCVVTZC-01',
        '34SCA5',
        '35BD',
        '35E',
        '35J',
        '35K',
        '35L',
        '35N',
        '36IB',
        '36S',
        '37HS',
        '38AP',
        '38AUD',
        '38AUQ',
        '38AUZ',
        '38MAR',
        '38MARB',
        '38MBR',
        '38MBRB',
        '38MBRC',
        '38MGR',
        '38MGRB',
        '38MHRBQ',
        '38MHRCC',
        '38MHRCQ',
        '38MPRB',
        '38MURA',
        '38VMH',
        '38VMH-1P',
        '38VMR',
        '39CC',
        '39DC',
        '39L',
        '39M',
        '39S',
        '40MAHB',
        '40MAQ',
        '40MBAA',
        '40MBAB',
        '40MBCAQ',
        '40MBCQ',
        '40MBDQ',
        '40MBFQ',
        '40MHHC',
        '40MHHQ',
        '40MPHB',
        '40MUAA',
        '40QQ',
        '40QQ-E',
        '40RFA',
        '40RFQ',
        '40RFS',
        '40RUA',
        '40RUQ',
        '40RUS',
        '40U',
        '40VM900001',
        '40VM900002',
        '40VM900003',
        '40VM900005',
        '40VM900006',
        '40VM900007',
        '40VM900008',
        '40VM900010',
        '40VM900012',
        '40VM900013',
        '40VM900051',
        '40VM900052',
        '40VMA',
        '40VMC',
        '40VMF',
        '40VMH',
        '40VMI',
        '40VML',
        '40VMM',
        '40VMR',
        '40VMU',
        '40VMV',
        '40VMW',
        '40VMZ',
        '42B',
        '42C',
        '42D',
        '42S',
        '42V',
        '42WKN',
        '45J',
        '45K',
        '45M',
        '45N',
        '45Q',
        '45R',
        '48A',
        '48EZ-A',
        '48FC',
        '48GC',
        '48HC',
        '48JC',
        '48KC',
        '48LC',
        '48LCH',
        '48P',
        '48TC',
        '48VG',
        '48VL',
        '48VR',
        '48VT',
        '50A',
        '50BVC',
        '50BVJ',
        '50BVQ',
        '50BVT',
        '50BVV',
        '50BVW',
        '50FC',
        '50FCQ',
        '50GC',
        '50GCQ',
        '50HC',
        '50HCQ',
        '50HQP',
        '50JC',
        '50KC',
        '50KCQ',
        '50LC',
        '50LCH',
        '50P',
        '50PCH',
        '50PCV',
        '50PEC',
        '50PSH',
        '50PSV',
        '50PSW',
        '50PTH',
        '50PTV',
        '50TC',
        '50TCQ',
        '50VG',
        '50VL',
        '50VQP',
        '50VR',
        '50VT',
        '50XCA',
        '50XCR',
        '50XCW',
        '50ZH',
        '50ZHC',
        '50ZP',
        '50ZPC',
        '524J',
        '547J',
        '547K',
        '548J',
        '549J',
        '549K',
        '551J',
        '551K',
        '558J',
        '559J',
        '559K',
        '569J',
        '575J',
        '577C',
        '577E',
        '580J',
        '581J',
        '581K',
        '582J',
        '582K',
        '58CU0',
        '58SB',
        '58SC',
        '58SP',
        '58SU0',
        '58SU0A',
        '59CU5',
        '59SC2',
        '59SC5',
        '59SC6',
        '59SP6',
        '59SU5',
        '59TP6',
        '5F',
        '5H',
        '607C',
        '607E',
        '619AHB',
        '619PB',
        '619PHB',
        '62X',
        '677C',
        '677E',
        '707C',
        '707E',
        '800S',
        '800SA',
        '801S',
        '810S',
        '810SA',
        '811S',
        '820SA',
        '820TA',
        '830CA',
        '830S',
        '830SA',
        '912S',
        '915S',
        '916S',
        '926S',
        '926T',
        '935CA',
        '935S',
        '935SA',
        'WTHRADD-HR-WTHR',
        'ADD-IPCREDIT',
        'ADD-LDAP',
        'ADD-OADR2',
        'AOSADD-OG-AOS',
        'DIPADD-OG-DIP',
        'IAMADD-OG-IAM',
        'BACNETADD-SCH-BACNET',
        'EXCHADD-SCH-EXCH',
        'ADD-TRNDEXP',
        'BA13',
        'BA14NA',
        'BA15NA',
        'BA16NA',
        'BA16NW',
        'BA17',
        'BH14NB',
        'BH16NA',
        'BH17',
        'BMS-CT1280UL',
        'BMS-CT5120UL',
        'BMS-IFBN640TLUL',
        'BMS-IFLSV4UL',
        'BMS-SM1280HTLUL',
        'BS2',
        'BT485',
        'BW3',
        'BW4',
        'BW5',
        'BW9',
        'BWB',
        'BWC',
        'BWH',
        'BWM',
        'CA13',
        'CA14',
        'CA15',
        'CA16',
        'CA17',
        'CAPMP',
        'CAPVP',
        'CAPVU',
        'RCEPL130530-10-R',
        'RCEPL130531-10-R',
        'CH14NB',
        'CH16',
        'CH17',
        'CIV-CR',
        'CUSTOMCIV-ER-CUSTOM',
        'DASHCIV-ER-DASH',
        'STORYCIV-ER-STORY',
        'CIV-L',
        'CIV-OL',
        'CIV-OPN',
        'CIV-OPNPL',
        'CIV-OPNPR',
        'CIV-OPNPRC',
        'CIV-OPNPRLS',
        'CNPHP',
        'CNPVP',
        'CNPVU',
        'CNRVU',
        'COALM',
        'CSPHP',
        'CSPVA',
        'CVPMA',
        'CVPVA',
        'CVPVP',
        'DEHXX',
        'DHMAHB',
        'DHMPHA',
        'DHMPHB',
        'DLCERBA',
        'DLCERBH',
        'DLCLRC',
        'DLCSRB',
        'DLCURA',
        'DLFLAB',
        'DLFLCB',
        'DLFSAB',
        'DLFSCB',
        'DLFUAA',
        'DX-INTERFACE',
        '01EB-STATE3LTBB-01',
        '01EB-STATE3LTBR-01',
        '01EB-STATE3LTCB-01',
        '01EB-STATE3LTCR-01',
        '01EB-STATE5BB-01',
        '01EB-STATE5BR-01',
        '01EB-STATE5CB-01',
        '01EB-STATE5CR-01',
        '01EB-STATE6BR-01',
        '01EB-STATE6CR-01',
        'CAREQT1-4-CAR',
        'EQT2',
        'ERVCRLHB',
        'ERVCRNVA',
        'ERVXXSHA',
        'ERVXXSHB',
        'ERVXXSVA',
        'ERVXXSVB',
        'EZXCABCR',
        'FAV-CONTROL',
        'FB4C',
        'FJ4',
        'FMA4P',
        'FMA4X',
        'FMC',
        'FMU',
        'FN1AAF',
        'FNCCAB',
        'FPMA',
        'FS-BOX',
        'FSFXXAOA',
        'FV4C',
        'FX4D',
        'FZ4A',
        'GA4S',
        'GA5S',
        'GA7T',
        'GB',
        'GH5S',
        'GH7T',
        'GP',
        'GW',
        'HRVCRLHB',
        'HRVXXSHA',
        'HRVXXSHB',
        'HRVXXSVA',
        'HRVXXSVB',
        'HUMCRLBP',
        'HUMCRLFP',
        'HUMCRSBP',
        'HUMCRSTM',
        'HUMCRWBP',
        '4IAQ1-D-4',
        'KSACN0101AAA',
        'KSACN0601AAA',
        'KSACN0701AAA',
        'KSACN0801AAA',
        'KSACN1001AAA',
        'KSAIC0301230',
        'KSAIF0101AAA',
        'KSAIF0201AAA',
        'KSAIF0301AAA',
        'KSAIF0401AAA',
        'KSAIF0601AAA',
        'KUAWC0101COR-A10',
        'KVACN',
        'LON-OC',
        'MCY7',
        'MMC1',
        'MMC8',
        'MMD1',
        'MMD4',
        'MMD6B',
        'MMD6H',
        'MMDB',
        'MMDP',
        'MMDV',
        'MMK3',
        'MMK7',
        'MML4',
        'MMLB',
        'MMU1Y',
        'MMU2',
        'MMU4',
        'MMUM',
        'MMYF',
        'MMYF-1P',
        'MMYH',
        'POWERNSA-12-POWER',
        'RHNSA-3PCT-RH',
        '20-ANALOGNSA-4-20-ANALOG',
        'POWERNSA-48-POWER',
        'AVGNSA-4PT-AVG',
        'CURRENTNSA-ADJ-CURRENT',
        'MININSA-ADJ-MINI',
        'POWERNSA-ADJ-POWER',
        'CO2-DUCTNSA-ASENSE-CO2-DUCT',
        'CO2-RMNSA-ASENSE-CO2-RM',
        'NSA-BULLET',
        'NSA-BUTTON',
        'DUCTNSA-CO2-DUCT',
        'ROOMNSA-CO2-ROOM',
        'NSA-COMMAND',
        'AVGNSA-COP-AVG',
        'NSA-COVER',
        'NSA-DBL',
        'NSA-DIFFER',
        'LONSA-DIFFER-LO',
        'REMOTENSA-DIFFER-REMOTE',
        'NSA-DUCT',
        'NO-BOXNSA-DUCT-NO-BOX',
        'NSA-FIXED',
        'MININSA-FIXED-MINI',
        'NSA-FREEZE',
        'NSA-GAGE',
        'NSA-GAS',
        'NSA-IMM',
        'RHNSA-MICRO-RH',
        'TEMPNSA-MICRO-TEMP',
        'LONSA-MINI-LO',
        'NSA-OA',
        'PICKUPNSA-OA-PICKUP',
        'RHNSA-OA-RH',
        'NSA-PIPE',
        'NSA-PITOT',
        'STATINSA-PITOT-STATIC',
        'VELNSA-PITOT-VEL',
        'DUCTNSA-RH-DUCT',
        'ROOMNSA-RH-ROOM',
        'NSA-ROOM',
        'NSA-SPLIT',
        'NSA-TRANSFORM',
        'NSA-VOLTAGE',
        'NSA-WALL',
        'PICKUPNSA-WALL-PICKUP',
        '2-ANSB-10K-2-A',
        '2-DNSB-10K-2-D',
        '2-H200NSB-10K-2-H200',
        '2-INSB-10K-2-I',
        '2-RPPNSB-10K-2-RPP',
        '2-STPNSB-10K-2-STP',
        'NSB-ACC',
        'LDNSB-AQ-LD',
        'NSB-MISC',
        'TNSB-ZPS-T',
        'OBL',
        'OBM',
        'OPN-APP',
        'P-02OPN-B3-P-02',
        'OPN-FC',
        'OPN-MPCXPIO816',
        'OPN-MTCC',
        'OPN-RTUM2',
        '02OPN-SECTRM-02',
        'OPN-UC',
        'OPN-UCXP',
        'OPN-UCXPIO',
        'OPN-UPC',
        'OPN-UV',
        '02OPN-VAVB1-02',
        '02OPN-VAVB3-02',
        '02OPN-VVTBP-02',
        '02OPN-VVTZC-02',
        'OPN-WSHPM',
        'OPN-WSHPW2WM',
        'OPND',
        'OPNDR',
        'OVL',
        'OVM',
        'P701',
        'PA13NA',
        'PA14NC',
        'PA15NC',
        'PA16NA',
        'PA16NW',
        'PA17NA',
        'PA4S',
        'PA4Z',
        'PA5S',
        'PA7T',
        'PF4M',
        'PF4MNX',
        'PG80ESA',
        'PG80ESUA',
        'PG80MSA',
        'PG80MSU',
        'PG80VTL',
        'PG92ESA',
        'PG92MSA',
        'PG95ESA',
        'PG95ESU',
        'PG95MSU',
        'PG96MSA',
        'PG96VTA',
        'PH14NB',
        'PH15NB',
        'PH16NA',
        'PH16NC',
        'PH4Z',
        'PH5S',
        'PH7T',
        'PROT485',
        'PY4G',
        'RAVAT',
        'RAVAT2',
        'RAVBT',
        'RAVBT2',
        'RAVCT',
        'RAVCT2',
        'RAVKR',
        'RAVKR2',
        'RAVUT',
        'RAVUT2',
        'ULRBC-AMS54E-UL',
        'REP485',
        'RGAP',
        'RMAP',
        'SV-PD',
        'SV-PI',
        'CARSYST1-4-CAR',
        'SYSTXBBECC01-C',
        'SYSTXBBNIM01',
        'SYSTXBBRRS01',
        'SYSTXBBWEF01-B',
        'SYSTXCC4ZC01',
        'SYSTXCCITC01-C',
        'SYSTXCCNIM01',
        'SYSTXCCRRS01',
        'SYSTXZNSMS01',
        'TB-24-C',
        'TB-C',
        'H-TBPL-24-H-C',
        'TBPL-H-C',
        'TC-ACCESSORIES',
        'TCB-IFLN642TLUL',
        'TCB-IFTH1GUL',
        'TCB-IFVN1UL',
        'TV-MPCXP',
        'TV-MPCXP1628',
        'NRTV-MPCXP1628-NR',
        'TV-MPCXPIO',
        'TV-OPT',
        'TV-PSM',
        'VTV-UC253-V',
        'TV-UC561',
        'TV-UC683T',
        'TV-UCXP683T',
        'VTV-UCXP683T-V',
        'E2TV-VAVB3-E2',
        'E2TV-VVTBP-E2',
        'E2TV-VVTZC-E2',
        'UVLBB1LP',
        'UVLBB2LP',
        'UVLCC1LP',
        'UVLCC2LP',
        'XT-LB',
        'XT-RB',
        'ZONEBB3ZAC01',
        'ZONEBB3ZHP01',
        'ZONEBB8KIT01-B',
        'ZONECC2KIT01-B',
        'ZONECC3ZAC01',
        'ZONECC3ZHP01',
        'ZONECC4KIT01-B',
        'ZONECC8KIT01-B',
        'ZS-CAR',
        'ZS-CLAMP',
        'ZS-DUCT',
        'ZS-IMM',
        'ZS-OA',
        'BNKZS-RMT-BNK',
        'ZSP-CAR',
        'CARZSP-M-CAR',
        'ZSPF-CAR',
        'ZSPL-CAR',
        // ICP model nos
        'C4A3',
        'CAS',
        'CCA7',
        'CCA9',
        'CCH6',
        'CCH9',
        'CHS',
        'CNPFU',
        'CSA5',
        'CSA6',
        'CSH4',
        'CSH5',
        'CSH6',
        'CVA9',
        'CVH8',
        'DHMSHA',
        'DLCERAA',
        'DLCERAH',
        'DLCLRA',
        'DLCLRB',
        'DLCMRA',
        'DLCPRA',
        'DLCSRA',
        'DLFEHAH',
        'DLFLAA',
        'DLFLCA',
        'DLFLDA',
        'DLFLFA',
        'DLFPHA',
        'DLFSAA',
        'DLFSCA',
        'DLFSDA',
        'DLFSFA',
        'DLFSHA',
        'DLFSHB',
        'EAA4X',
        'EAM4X',
        'EDM',
        'EHD4X',
        'EMA',
        'ENA4X',
        'END4X',
        'ENH4X',
        'ENW4X',
        'F80CSU',
        'F80CTL',
        'F95CSU',
        'F96CTN',
        'F96VTN',
        'F97CMN',
        'FAS',
        'FB4C',
        'FCM4X',
        'FED',
        'FEM4P',
        'FEM4X',
        'FEU',
        'FHS',
        'FMA4P',
        'FMA4X',
        'FMC4X',
        'FMU4X',
        'FXM4X',
        'G80CSU',
        'G80CTL',
        'G8MTL',
        'G8MVL',
        'G8MXL',
        'G95CSU',
        'G96CTN',
        'G96VTN',
        'G97CMN',
        'G9MAE',
        'G9MVE',
        'G9MXE',
        'G9MXT',
        'H4A3',
        'HB',
        'HCA7',
        'HCA9',
        'HCH6',
        'HCH9',
        'HP',
        'HS',
        'HSA5',
        'HSA6',
        'HSH4',
        'HSH5',
        'HSH6',
        'HVA9',
        'HVH8',
        'HW',
        'HXA6',
        'HXH5',
        'KSACN0401AAA',
        'KSACN0501AAA',
        'KSACN0601AAA',
        'KSACN0701AAA',
        'KSACN0801AAA',
        'KSACN0901AAA',
        'KSAIC0101115',
        'KSAIC0101230',
        'KSAIC0301230',
        'KSAIC0401230',
        'KSAIF0101AAA',
        'KSAIF0201AAA',
        'KSAIF0301AAA',
        'KSAIF0401AAA',
        'KSAIF0501AAA',
        'KSAIF0601AAA',
        'MB',
        'MF',
        'MV',
        'N4A3',
        'N4A3--C',
        'N4A4-C',
        'N4A5',
        'N4A6',
        'N4A7',
        'N4H3--C',
        'N4H3',
        'N4H4',
        'N4H4--C',
        'N4H6',
        'N80ESN',
        'N80ESU',
        'N80VSL',
        'N8MSN',
        'N8MXL',
        'N92ESN',
        'N95ESN',
        'N95ESU',
        'N96VSN',
        'N9DSE',
        'N9MSB',
        'N9MSE',
        'NADA',
        'NAUA',
        'NH4A4',
        'NH4H4',
        'NOMV',
        'NXA4',
        'NXA6',
        'NXH5',
        'NXH6',
        'OLF',
        'OLR',
        'OLV',
        'OMF',
        'OMV',
        'OUF',
        'PAD4-E',
        'PAJ4',
        'PAR5',
        'PDD4',
        'PDD4-E',
        'PDS4',
        'PDS4-E',
        'PGD4',
        'PGD4-E',
        'PGR5',
        'PGS4',
        'PGS4-E',
        'PHD4',
        'PHD4-F',
        'PHJ4',
        'PHR5',
        'R4A3',
        'R4A4',
        'R4A5',
        'R4H4',
        'R80ESN',
        'R80ESU',
        'R92ESN',
        'R95ESN',
        'R95ESU',
        'RAH',
        'RAS',
        'RAV',
        'RAW',
        'RGH',
        'RGS',
        'RGV',
        'RGW',
        'RHH',
        'RHS',
        'RHV',
        'RHW',
        'SYST0101CW',
        'SYST0101RM',
        'SYST0101ZP',
        'T4A3',
        'TCA7',
        'TCH6',
        'TSA5',
        'TSA6',
        'TSH4',
        'TSH6',
        'TSTAT0201CW',
        'TSTAT0408',
        'TVA9',
        'TVH8',
        'WPG4',
        '127TBN',
        '134SAN',
        '135SAN',
        '135SAN---C',
        '146SAN',
        '148TAN',
        '148TAN---C',
        '191VAN',
        '24TPB7',
        '26SCA4',
        '26SCA5',
        '26SCA5---C',
        '26SPA6',
        '26TPA8',
        '26TPA8---C',
        '26VNA1',
        'GA4SAN5',
        'GA5SAN5',
        'GA8TAN5',
        '59TN7',
        '987T',
        '59TP7',
        '927T',
        'F97CTN',
        'G97CTN',
        // Heat pumps
        'N4H5S',
        'N4H7T',
        'N5H5S',
        'N5H5S--C',
        'N5H8T',
        'N5H8T--C',
        'R4H5S',
        'R5H5S',
        'T4H5S',
        'T4H7T',
        'T5H0V',
        'T5H3V',
        'T5H6S',
        'T5H8T'
    ];

    public findModel(modelNo: string): string {
        return this.models.find((model) => modelNo.startsWith(model)) || modelNo;
    }
}
