import { Injectable } from '@angular/core';
import enrollmentStatusConstants from 'private/app/views/program/enrollment-form/enrollment-forms/enrollment-status.json';
import { AccountAdminProgramEnrollments, EnrollmentOption } from '../models/account-admin-program.model';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from 'common/services/language-translation.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ToastService } from 'common/services/toast.service';

@Injectable({ providedIn: 'root' })
export class ProgramEnrollmentsService {
    public enrollmentStatusTabs: string[];
    public PROGRAM_ENROLLMENT_ACTION_OPTION_ENROLL_DEALER: EnrollmentOption;
    public PROGRAM_ENROLLMENT_ACTION_OPTION_DEACTIVATE: EnrollmentOption;
    public PROGRAM_ENROLLMENT_ACTION_OPTION_RESET_TO_ELIGIBLE: EnrollmentOption;
    public PROGRAM_ENROLLMENT_ACTION_OPTION_LOCATOR_SUSPEND: EnrollmentOption;
    public PROGRAM_ENROLLMENT_ACTION_OPTION_LOCATOR_ACTIVE: EnrollmentOption;
    public nonDealerConversionEnrollmentStatusTabs: string[];
    private ngOnDestroy$ = new Subject();

    constructor(
        private translateService: TranslateService,
        private router: Router,
        private languageTranslationService: LanguageTranslationService,
        private readonly toastService: ToastService
    ) {
        this.languageTranslationService.translationsLoaded().pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => {
            this.initDropDownOptions();
        });
    }

    public getEnrollmentActionOptions(enrollment: AccountAdminProgramEnrollments) {
        let options: EnrollmentOption[] = [];

        switch (enrollment.enrollmentStatus) {
            case enrollmentStatusConstants.enrollmentStatusReverseMapping['0']:
                return [
                    this.PROGRAM_ENROLLMENT_ACTION_OPTION_ENROLL_DEALER
                ];
            case enrollmentStatusConstants.enrollmentStatusReverseMapping['1']:
                options = [
                    this.PROGRAM_ENROLLMENT_ACTION_OPTION_ENROLL_DEALER,
                    this.PROGRAM_ENROLLMENT_ACTION_OPTION_RESET_TO_ELIGIBLE,
                    this.PROGRAM_ENROLLMENT_ACTION_OPTION_DEACTIVATE
                ];

                options.push(this.getEnrollmentLevelOption(enrollment));

                return options;
            case enrollmentStatusConstants.enrollmentStatusReverseMapping['2']:
            case enrollmentStatusConstants.enrollmentStatusReverseMapping['3']:
            case enrollmentStatusConstants.enrollmentStatusReverseMapping['4']:
            case enrollmentStatusConstants.enrollmentStatusReverseMapping['5']:
                options = [
                    this.PROGRAM_ENROLLMENT_ACTION_OPTION_ENROLL_DEALER,
                    this.PROGRAM_ENROLLMENT_ACTION_OPTION_RESET_TO_ELIGIBLE
                ];

                options.push(this.getEnrollmentLevelOption(enrollment));

                return options;
            default: return options;
        }
    }

    public getEnrollmentLevelOption(enrollment: AccountAdminProgramEnrollments): EnrollmentOption {
        return (enrollment.enrollmentLevel === 'Locator') ? this.PROGRAM_ENROLLMENT_ACTION_OPTION_LOCATOR_SUSPEND : this.PROGRAM_ENROLLMENT_ACTION_OPTION_LOCATOR_ACTIVE;
    }

    public openProgramEnrollmentForm(enrollment: AccountAdminProgramEnrollments, hvacpCompanyId: string) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(
                ['/Admin/Program/search-program/enrollments/form'],
                {
                    queryParams: {
                        cid: hvacpCompanyId,
                        pid: enrollment.programId,
                        brandName: enrollment.brand
                    }
                }
            )
        );

        const emptyTab = window.open('about:blank', '_blank');

        if (emptyTab) {
            emptyTab.location.href = url;
        }
        else {
            this.toastService.add({
                content: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.ENROLLMENT_FORM_OPEN_ERROR'),
                id: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_TOAST_MESSAGE_ID'),
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        }
    }

    private initDropDownOptions() {
        this.enrollmentStatusTabs = [
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ALL'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ELIGIBLE'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ACTIVE'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.INACTIVE'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.PENDING_INITIAL'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.PENDING_FINAL'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.RETURNED')
        ];
        this.nonDealerConversionEnrollmentStatusTabs = [
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ALL'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ELIGIBLE'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ACTIVE'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.INACTIVE')
        ];
        this.PROGRAM_ENROLLMENT_ACTION_OPTION_ENROLL_DEALER = {
            name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_ACTIONS.ACTIVE'),
            value: '1',
            enrollmentType: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.STATUS'),
            confirmDetails: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_ACTION_MESSAGE.STATUS_ACTIVE')
        };
        this.PROGRAM_ENROLLMENT_ACTION_OPTION_DEACTIVATE = {
            name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_ACTIONS.INACTIVE'),
            value: '2',
            enrollmentType: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.STATUS'),
            confirmDetails: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_ACTION_MESSAGE.STATUS_INACTIVE')
        };
        this.PROGRAM_ENROLLMENT_ACTION_OPTION_RESET_TO_ELIGIBLE = {
            name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_ACTIONS.ELIGIBLE'),
            value: '0',
            enrollmentType: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.STATUS'),
            confirmDetails: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_ACTION_MESSAGE.STATUS_ELIGIBLE')
        };
        this.PROGRAM_ENROLLMENT_ACTION_OPTION_LOCATOR_SUSPEND = {
            name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_ACTIONS.LOCATOR_SUSPEND'),
            value: '2',
            enrollmentType: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.LEVEL'),
            confirmDetails: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_ACTION_MESSAGE.LEVEL_SUSPEND')
        };
        this.PROGRAM_ENROLLMENT_ACTION_OPTION_LOCATOR_ACTIVE = {
            name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_ACTIONS.LOCATOR_ACTIVE'),
            value: '1',
            enrollmentType: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.LEVEL'),
            confirmDetails: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_ACTION_MESSAGE.LEVEL_ACTIVE')
        };
    }
}
