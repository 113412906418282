/* eslint-disable max-lines */
import { NgModule } from '@angular/core';
import { Data, Route, RouterModule } from '@angular/router';
import { environment } from 'common/environments/environment';
import { Auth } from 'private/app/guards/auth.guard';
import { CreateAccount } from 'private/app/guards/createAccount.guard';
import { Login } from 'private/app/guards/login.guard';
import { InboxComponent } from 'private/app/components/inbox/inbox.component';
import { BrandSelectComponent } from './views/brand-select/brand-select.component';
import { CreateEmailValidateComponent } from './views/create-email-validate/createEmailvalidate.component';
import { CallbackComponent } from './views/callback/callback.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { CoolCalcComponent } from '../app/views/cool-calc/cool-calc.component';
import { CreateAccountInformationComponent } from './views/create-account-information/createAccountInformation.component';
import { CreateAccountLookupComponent } from './views/create-account-lookup/createAccountLookup.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { LoginComponent } from './views/login/login.component';
import { SignOutComponent } from './views/sign-out/signOut.component';
import { ProfileComponent } from './views/profile-landing/profile/profile.component';
import { ProfileLandingComponent } from './views/profile-landing/profile-landing.component';
import { CompanyInfoComponent } from './views/profile-landing/company-info/company-info.component';
import { ManageSubscriptionsComponent } from './views/profile-landing/manage-subscriptions/manage-subscriptions.component';
import { AssociatedDistributorLookup } from 'private/app/components/associated-distributor-lookup/associated-distributor-lookup';
import { CoolCalcTermsAndConditionsComponent } from './views/cool-calc-terms-and-conditions/cool-calc-terms-and-conditions.component';
import { DistributorComponent } from './views/connected-portal/distributor/distributor.component';
import { ConnectedPortalGuard } from './guards/connected-portal/connected-portal.guard';
import { CompanyCode } from './models/company.model';
import { CoolCalTermsGuard } from './guards/cool-calc-terms.guard';
import { TradePartnersGuard } from './guards/trade-partners.guard';
import { AccountAdminContactDetailsComponent } from './views/account-admin-contact-details/account-admin-contact-details.component';
import { AccountAdminComponent } from './views/account-admin/account-admin.component';
import { AccountAdminFormComponent } from './components/account-admin-form/account-admin-form.component';
import { AccountAdminResultsComponent } from './views/account-admin-results/account-admin-results.component';
import { CompaniesTableComponent } from './views/account-admin-results/companies-table/companies-table.component';
import { UsersTableComponent } from './views/account-admin-results/users-table/users-table.component';
import { AccountAdminCompanyDetailsComponent } from './views/account-admin-company-details/account-admin-company-details.component';
import { accountAdminRoutes } from './../../private/app/account-admin-routes';
import { DealerComponent } from './views/connected-portal/dealer/dealer.component';
import { ConnectedPortalDealerCustomersComponent } from './views/connected-portal/dealer/connected-portal-dealer-customers/connected-portal-dealer-customers.component';
import { ConnectedPortalConnectCustomersComponent } from './views/connected-portal/dealer/connected-portal-dealer-customers/connected-portal-connect-customers/connected-portal-connect-customers.component';
import { ConnectedPortalQueuedCustomersComponent } from './views/connected-portal/dealer/connected-portal-dealer-customers/connected-portal-queued-customers/connected-portal-queued-customers.component';
import { ConnectedPortalCustomersPropertyComponent } from './views/connected-portal/dealer/connected-portal-dealer-customers/connected-portal-customers-property/connected-portal-customers-property.component';
import { SystemConditionsComponent } from './views/connected-portal/dealer/components/system-conditions/system-conditions.component';
import { DistributorDashboardComponent } from './views/connected-portal/distributor/distributor-dashboard/distributor-dashboard.component';
import { DistributorDealersComponent } from './views/connected-portal/distributor/distributor-dealers/distributor-dealers.component';
import { DealerGuard } from './guards/connected-portal/dealer.guard';
import { ConnectedPortalWarrantyInfoComponent } from './views/connected-portal/dealer/connected-portal-dealer-customers/connected-portal-warranty-info/connected-portal-warranty-info.component';
import { AppConstants, EnvName } from 'common/app-constants';
import { FactoryComponent } from './views/connected-portal/factory/factory.component';
import { FactoryDashboardComponent } from './views/connected-portal/factory/factory-dashboard/factory-dashboard.component';
import { FactoryDistributorsComponent } from './views/connected-portal/factory/factory-distributors/factory-distributors.component';
import { TradepartnersComponent } from './views/trade-partners/trade-partners.component';
import { PurchaseOrderDetailComponent } from './views/trade-partners/purchase-order-detail/purchase-order-detail.component';
import { PurchaseOrderListComponent } from './views/trade-partners/purchase-order-list/purchase-order-list.component';
import { AcknowledgementComponent } from './views/trade-partners/acknowledgement/acknowledgement.component';
import { AdvancedShipmentComponent } from './views/trade-partners/advanced-shipment/advanced-shipment.component';
import { UsersComponent } from './views/account-admin-results/user/users.component';
import { UserDetailsComponent } from './views/account-admin-results/user/user-details/user-details.component';
import { IdmUserRoles } from './enums/idm-user-roles';
import { AccountAdminGuard } from './guards/account-admin/account-admin.guard';
import { IDMUserRolesResolver } from './resolver/idm-user-role.resolver';
import { DealerOpportunitiesComponent } from './views/connected-portal/dealer/dealer-opportunities/dealer-opportunities.component';
import { WallControlDetailContainerComponent } from './views/connected-portal/dealer/customer-product-details/wall-control/wall-control-detail-container/wall-control-detail-container.component';
import { OduDetailContainerComponent } from './views/connected-portal/dealer/customer-product-details/odu/odu-detail-container/odu-detail-container.component';
import { FactorySystemsOverviewComponent } from './views/connected-portal/factory/factory-systems-overview/factory-systems-overview.component';
import { DealerPendingActivationComponent } from './views/connected-portal/dealer/dealer-pending-activation/dealer-pending-activation.component';
import { OptiCleanSizingSelectionToolComponent } from 'src/private/app/components/opti-clean-sizing-selection-tool/opti-clean-sizing-selection-tool.component';
import { MonthlyCustomerStatementsComponent } from './components/monthly-customer-statements/monthly-customer-statements/monthly-customer-statements.component';
import { MonthlyCustomerStatementGuard } from './guards/monthly-customer-statements.guard';

export interface PrivateAppRouteData extends Data {
    allowed?: boolean,
    tridionFeatures?: string[],
    envFeature?: boolean,
    allowedCompanyCodes?: CompanyCode[],
    viewType?: CompanyCode,
    accessRoles?: IdmUserRoles[]
}

export interface PrivateAppRoute extends Route {
    data?: PrivateAppRouteData;
    children?: PrivateAppRoute[]
}

const connectedPortalRouteData = {
    internal: {
        allowedCompanyCodes: [
            CompanyCode.Internal
        ],
        viewType: CompanyCode.Internal
    },
    distributor: {
        allowedCompanyCodes: [
            CompanyCode.Internal,
            CompanyCode.Distributor
        ],
        viewType: CompanyCode.Distributor
    },
    dealer: {
        allowedCompanyCodes: [
            CompanyCode.Internal,
            CompanyCode.Distributor,
            CompanyCode.Dealer
        ],
        viewType: CompanyCode.Dealer
    }
};
const testPage = [];
if (environment.envName === EnvName.Local) {
    testPage.push(
        {
            path: 'test',
            loadComponent: () => import('./views/connected-portal/test-page/test-page.component').then((c) => c.TestPageComponent)
        }
    );
}

const connectedPortalRoutes: PrivateAppRoute[] = [];

if (environment.envName === EnvName.Staging || environment.envName === EnvName.Dev) {
    connectedPortalRoutes.push(
        {
            path: 'v0-connected-portal',
            loadComponent: () => import('./views/connected-portal/connected-portal.component').then((c) => c.ConnectedPortalComponent),
            canActivate: [
                Auth
            ],
            canActivateChild: [
                ConnectedPortalGuard
            ],
            runGuardsAndResolvers: 'always',
            children: [
                {
                    path: '',
                    component: FactoryComponent,
                    data: connectedPortalRouteData.internal,
                    children: [
                        {
                            path: '',
                            redirectTo: 'dashboard',
                            pathMatch: 'full'
                        },
                        {
                            path: 'dashboard',
                            component: FactoryDashboardComponent,
                            data: connectedPortalRouteData.internal
                        },
                        {
                            path: 'systems-overview',
                            data: connectedPortalRouteData.internal,
                            component: FactorySystemsOverviewComponent
                        },
                        {
                            path: 'distributors',
                            component: FactoryDistributorsComponent,
                            data: connectedPortalRouteData.internal
                        },
                        {
                            path: 'resources',
                            loadComponent: () => import('./views/connected-portal/factory/factory-resources/factory-resources.component').then(
                                (m) => m.FactoryResourcesComponent
                            ),
                            data: connectedPortalRouteData.internal
                        },
                        {
                            path: 'faq',
                            loadComponent: () => import('./views/connected-portal/factory/factory-faq/factory-faq.component').then(
                                (m) => m.FactoryFaqComponent
                            ),
                            data: connectedPortalRouteData.internal
                        },
                        {
                            path: 'advanced-search',
                            loadComponent: () => import('./views/connected-portal/factory/factory-advanced-search/factory-advanced-search.component').then(
                                (m) => m.FactoryAdvancedSearchComponent
                            ),
                            data: connectedPortalRouteData.internal
                        }
                    ]
                },
                {
                    path: 'distributors/:id',
                    component: DistributorComponent,
                    data: connectedPortalRouteData.distributor,
                    children: [
                        {
                            path: '',
                            redirectTo: 'dashboard',
                            pathMatch: 'full'
                        },
                        {
                            path: 'dashboard',
                            component: DistributorDashboardComponent,
                            data: connectedPortalRouteData.distributor
                        },
                        {
                            path: 'dealers',
                            component: DistributorDealersComponent,
                            data: connectedPortalRouteData.distributor
                        },
                        {
                            path: 'advanced-search',
                            loadComponent: () => import('./views/connected-portal/distributor/distributor-advanced-search/distributor-advanced-search.component').then(
                                (m) => m.DistributorAdvancedSearchComponent
                            ),
                            data: connectedPortalRouteData.distributor
                        },
                        {
                            path: 'resources',
                            loadComponent: () => import('./views/connected-portal/distributor/distributor-resources/distributor-resources.component').then(
                                (m) => m.DistributorResourcesComponent
                            ),
                            data: connectedPortalRouteData.distributor
                        },
                        {
                            path: 'faq',
                            loadComponent: () => import('./views/connected-portal/distributor/distributor-faq/distributor-faq.component').then(
                                (m) => m.DistributorFaqComponent
                            ),
                            data: connectedPortalRouteData.distributor
                        }
                    ]
                },

                {
                    path: 'dealers/:id',
                    component: DealerComponent,
                    data: connectedPortalRouteData.dealer,
                    canActivate: [DealerGuard],
                    children: [
                        {
                            path: '',
                            redirectTo: 'dashboard',
                            pathMatch: 'full'
                        },
                        {
                            path: 'dashboard',
                            loadComponent: () => import('./views/connected-portal/dealer/dealer-dashboard/dealer-dashboard.component').then((mod) => mod.DealerDashboardComponent),
                            data: connectedPortalRouteData.dealer
                        },
                        {
                            path: 'fleet-health',
                            loadComponent: () => import('./views/connected-portal/dealer/dealer-fleet-analysis/dealer-fleet-analysis-page/dealer-fleet-analysis-page.component').then((mod) => mod.DealerFleetAnalysisPageComponent),
                            data: connectedPortalRouteData.dealer
                        },
                        {
                            path: 'settings',
                            loadComponent: () => import('./views/connected-portal/dealer/dealer-settings/dealer-settings.component').then((mod) => mod.DealerSettingsComponent),
                            data: connectedPortalRouteData.dealer
                        },
                        {
                            path: 'resources',
                            loadComponent: () => import('./views/connected-portal/dealer/dealer-resources/dealer-resources.component').then(
                                (m) => m.DealerResourcesComponent
                            ),
                            data: connectedPortalRouteData.dealer
                        },
                        {
                            path: 'opportunities',
                            component: DealerOpportunitiesComponent,
                            data: connectedPortalRouteData.dealer
                        },
                        {
                            path: 'fad',
                            loadComponent: () => import('./views/connected-portal/dealer/dealer-fad/dealer-fad.component').then((mod) => mod.DealerFadComponent),
                            data: connectedPortalRouteData.dealer
                        },
                        {
                            path: 'faq',
                            loadComponent: () => import('./views/connected-portal/dealer/dealer-faq/dealer-faq.component').then(
                                (m) => m.DealerFaqComponent
                            ),
                            data: connectedPortalRouteData.dealer
                        },
                        {
                            path: 'linked-dealers',
                            loadComponent: () => import('./views/connected-portal/dealer/dealer-linked-dealers/dealer-linked-dealers.component').then(
                                (m) => m.DealerLinkedDealersComponent
                            ),
                            data: connectedPortalRouteData.dealer
                        },
                        {
                            path: 'customers',
                            component: ConnectedPortalDealerCustomersComponent,
                            data: connectedPortalRouteData.dealer,
                            children: [
                                {
                                    path: '',
                                    redirectTo: 'connected',
                                    pathMatch: 'full'
                                },
                                {
                                    path: 'connected',
                                    component: ConnectedPortalConnectCustomersComponent,
                                    data: connectedPortalRouteData.dealer
                                },
                                {
                                    path: 'queued',
                                    component: ConnectedPortalQueuedCustomersComponent,
                                    data: connectedPortalRouteData.dealer
                                }
                            ]
                        },
                        {
                            path: 'customers/:id',
                            component: ConnectedPortalCustomersPropertyComponent,
                            data: connectedPortalRouteData.dealer
                        },
                        {
                            path: 'customers/:id/products/outdoor-unit/:systemType/:serialNo',
                            component: OduDetailContainerComponent,
                            data: connectedPortalRouteData.dealer
                        },
                        {
                            path: 'customers/:id/products/indoor-unit/:systemType/:serialNo',
                            loadComponent: () => import('./views/connected-portal/dealer/customer-product-details/idu/idu-detail-container/idu-detail-container.component').then(
                                (m) => m.IduDetailContainerComponent
                            ),
                            data: connectedPortalRouteData.dealer
                        },
                        {
                            path: 'customers/:id/products/wall-control/:systemType/:serialNo',
                            component: WallControlDetailContainerComponent,
                            data: connectedPortalRouteData.dealer,
                            children: [
                                {
                                    path: ':zone',
                                    component: SystemConditionsComponent,
                                    data: connectedPortalRouteData.dealer
                                }
                            ]
                        },
                        {
                            path: 'customers/:id/products/wall-control/:systemType/:serialNo/warranty/warranty-details',
                            component: ConnectedPortalWarrantyInfoComponent,
                            data: connectedPortalRouteData.dealer
                        },
                        {
                            path: 'customers/:id/products/indoor-unit/:systemType/:serialNo/warranty/warranty-details',
                            component: ConnectedPortalWarrantyInfoComponent,
                            data: connectedPortalRouteData.dealer
                        },
                        {
                            path: 'customers/:id/products/outdoor-unit/:systemType/:serialNo/warranty/warranty-details',
                            component: ConnectedPortalWarrantyInfoComponent,
                            data: connectedPortalRouteData.dealer
                        }
                    ]
                },
                {
                    path: '**',
                    pathMatch: 'full',
                    redirectTo: ''
                }
            ]
        }
    );
}

const routes: PrivateAppRoute[] = [
    ...testPage,
    ...accountAdminRoutes,
    ...connectedPortalRoutes,
    {
        path: '',
        component: DashboardComponent,
        canActivate: [Auth],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'Pages',
        loadChildren: () => import('private/app/views/frame-content/frame-content.module').then((m) => m.FrameContentModule),
        canActivate: [Auth],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'brand-select',
        component: BrandSelectComponent
    },
    {
        path: 'ArchivedPages',
        loadChildren: () => import('private/app/views/frame-content/frame-content.module').then((m) => m.FrameContentModule),
        canActivate: [Auth],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [Login]
    },
    {
        path: 'logout',
        component: LoginComponent
    },
    {
        path: 'login-error',
        component: LoginComponent
    },
    {
        path: 'callback',
        component: CallbackComponent
    },
    {
        path: 'Admin/Admin-Tools/account-admin.html',
        component: AccountAdminComponent,
        canActivate: [
            Auth,
            AccountAdminGuard
        ],
        data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN, IdmUserRoles.ACCOUNTREADONLY] },
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: '',
                component: AccountAdminFormComponent,
                data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] },
                resolve: { adminAccess: IDMUserRolesResolver }
            },
            {
                path: 'results',
                component: AccountAdminResultsComponent,
                data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] },
                resolve: { adminAccess: IDMUserRolesResolver },
                children: [
                    {
                        path: '',
                        redirectTo: 'companies',
                        pathMatch: 'full'
                    },
                    {
                        path: 'companies',
                        component: CompaniesTableComponent
                    },
                    {
                        path: 'contacts',
                        component: UsersTableComponent
                    },
                    {
                        path: 'users',
                        component: UsersComponent
                    }
                ]
            },
            {
                path: 'company/:id',
                component: AccountAdminCompanyDetailsComponent,
                data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] },
                resolve: { adminAccess: IDMUserRolesResolver }
            },
            {
                path: 'contact/:id',
                component: AccountAdminContactDetailsComponent
            },
            {
                path: 'user-details/:id',
                component: UserDetailsComponent
            }
        ]
    },
    {
        path: 'Admin/Admin-Tools/account-admin.html',
        component: AccountAdminComponent,
        canActivate: [
            Auth,
            AccountAdminGuard
        ],
        data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN, IdmUserRoles.ACCOUNTREADONLY] },
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: '',
                component: AccountAdminFormComponent,
                data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] },
                resolve: { adminAccess: IDMUserRolesResolver }
            },
            {
                path: 'results',
                component: AccountAdminResultsComponent,
                data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] },
                resolve: { adminAccess: IDMUserRolesResolver },
                children: [
                    {
                        path: '',
                        redirectTo: 'companies',
                        pathMatch: 'full'
                    },
                    {
                        path: 'companies',
                        component: CompaniesTableComponent
                    },
                    {
                        path: 'contacts',
                        component: UsersTableComponent
                    },
                    {
                        path: 'users',
                        component: UsersComponent
                    }
                ]
            },
            {
                path: 'company/:id',
                component: AccountAdminCompanyDetailsComponent,
                data: { accessRoles: [IdmUserRoles.ACCOUNTADMIN] },
                resolve: { adminAccess: IDMUserRolesResolver }
            },
            {
                path: 'contact/:id',
                component: AccountAdminContactDetailsComponent
            },
            {
                path: 'user-details/:id',
                component: UserDetailsComponent
            }
        ]
    },
    {
        path: 'secure-callback',
        component: CallbackComponent
    },
    {
        path: 'create-account-information',
        component: CreateAccountInformationComponent
    },
    {
        path: 'create-account-email-validate',
        component: CreateEmailValidateComponent,
        canActivate: [CreateAccount]
    },
    {
        path: 'create-account-company-lookup',
        component: CreateAccountLookupComponent,
        canActivate: [CreateAccount]
    },
    {
        path: 'associated-distributor-lookup',
        component: AssociatedDistributorLookup,
        canActivate: [CreateAccount]
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [Auth]
    },
    {
        path: 'profile',
        component: ProfileLandingComponent,
        canActivate: [Auth],
        children: [
            {
                path: '',
                redirectTo: 'profile',
                pathMatch: 'full'
            },
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'company-info',
                component: CompanyInfoComponent
            },
            {
                path: 'manage-subscriptions',
                component: ManageSubscriptionsComponent
            }
        ]
    },
    {
        path: 'products/parts/parts-catalog',
        loadChildren: () => import('common/views/parts/parts.module').then((m) => m.PartsModule),
        canActivate: [Auth]
    },
    {
        path: 'marketing/sales-tools/opticlean-tool',
        component: OptiCleanSizingSelectionToolComponent,
        canActivate: [Auth]
    },
    {
        path: 'support/pre-sale-support/cool-calc',
        component: CoolCalcComponent,
        canActivate: [Auth, CoolCalTermsGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'ordering/reports/claiming-and-sales-reporting/monthly-customer-statements',
        component: MonthlyCustomerStatementsComponent,
        canActivate: [Auth, MonthlyCustomerStatementGuard],
        data: { accessRoles: [IdmUserRoles.MONTHLYCUSTOMERSTATEMENTS] }
    },
    {
        path: 'trade-partners',
        component: TradepartnersComponent,
        canActivate: [Auth],
        canActivateChild: [TradePartnersGuard],
        data: {
            headerData: 'TRADE_PARTNERS.LANDING',
            envFeature: environment.features.tradePartner
        },
        children: [
            {
                path: '',
                component: PurchaseOrderListComponent,
                pathMatch: 'full'
            },
            {
                path: ':id',
                component: PurchaseOrderDetailComponent,
                data: { headerData: 'TRADE_PARTNERS.PO_DETAIL' }
            },
            {
                path: ':id/confirm',
                component: AcknowledgementComponent,
                title: 'Purchase Order Acknowledgement',
                data: { headerData: 'TRADE_PARTNERS.ACKNOWLEDGE.HEADER_DATA' }
            },
            {
                path: ':id/advanced-shipment',
                component: AdvancedShipmentComponent,
                title: 'Advanced Shipment Acknowledgement',
                data: { headerData: 'TRADE_PARTNERS.ADVANCED_SHIPMENT' }
            }
        ]
    },
    {
        path: 'support/pre-sale-support/cool-calc/terms',
        component: CoolCalcTermsAndConditionsComponent
    },
    {
        path: 'search',
        loadChildren: () => import('private/app/views/private-search/private-search.module').then((m) => m.PrivateSearchModule),
        canActivate: [Auth],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'products/dashboards/productcatalog',
        loadChildren: () => import('private/app/views/products/products.module').then((m) => m.ProductsModule),
        canActivate: [Auth],
        runGuardsAndResolvers: 'always'
    },
    {
        path: AppConstants.PrivatePartsLiteratureSearchURL,
        loadChildren: () => import('common/views/product-literature/product-literature.module').then((m) => m.ProductLiteratureModule),
        canActivate: [Auth],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'support/literature/productliterature',
        loadChildren: () => import('common/views/product-literature/product-literature.module').then((m) => m.ProductLiteratureModule),
        canActivate: [Auth],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'products/detail',
        loadChildren: () => import('common/views/product-details/product-details.module').then((m) => m.ProductDetailsModule),
        canActivate: [Auth],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'sign-out',
        component: SignOutComponent
    },
    {
        path: 'inbox',
        component: InboxComponent
    },
    {
        path: 'pending-dealer-activation',
        component: DealerPendingActivationComponent
    },
    {
        path: '**',
        loadChildren: () => import('private/app/views/trid-content-page/trid-content-page.module').then((m) => m.TridionContentPageModule),
        canActivate: [Auth],
        runGuardsAndResolvers: 'always'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload',
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
