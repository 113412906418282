import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'common/environments/environment';
import { ApiOptions, ApiOptionsService } from 'common/services/api-options/api-options.service';
import { AccountAdminFormFields, UserResponse } from 'private/app/models/account-admin-search';
import { Observable } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { Option } from '../../../../common/components/select/select.component';
import { AccountAdminUtilsService } from './account-admin-utils.service';

export interface CompanyCriteria {
    hvacCompanyIds?: number[];
    statuses?: string[];
    companyTypeCodes?: string[];
    brandCodes?: string[];
    soldToCustomerId?: string;
    shipToCustomerId?: string;
    payerCustomerId?: string;
    name?: string;
    locationTypes?: string[];
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    regionCodes?: string[];
    postalCode?: string;
    countryCodes?: string[];
    phoneNumber?: string;
    faxNumber?: string;
    emailAddress?: string;
    priceGroup?: string;
    priceListType?: string;
    employeeContacts?: {
        AM?: string[];
        PTAM?: string[];
        RSM?: string[];
        TSM?: string[];
        PRSM?: string[];
        PA?: string[];
    }
}
export interface PostUserSearchQuery {
    criteria: {
        companyCriteria: CompanyCriteria;
        statuses?: string[];
        id?: string;
        lastName?: string;
        firstName?: string;
        title?: string;
        addressLine1?: string;
        addressLine2?: string;
        city?: string;
        regionCodes?: string[];
        postalCode?: string;
        countryCodes?: string[];
        phoneNumber?: string;
        faxNumber?: string;
        emailAddress?: string;
        creationDateStart?: string;
        creationDateEnd?: string;
        roles?: string[]
    },
    pageParams?: {
        page: number,
        pageSize: number
    },
    sortFields?: string[],
    determineTotalCount: true
}


export interface UserModel {
    data: UserResponse[];
    totalCount: number
}

@Injectable({ providedIn: 'root' })
export class AccountAdminUserService {
    constructor(private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService,
        private readonly accountAdminUtilsService: AccountAdminUtilsService) {}

    public createUserSearchBody(formValue: Partial <AccountAdminFormFields>, pagination: number | null, sortParams: string) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: any = {
            criteria: {
                companyCriteria:
                { companyTypeCodes: [] }
            },
            pageParams: {
                page: (pagination || 1) - 1,
                pageSize: 10
            },
            determineTotalCount: true
        };

        // Create Structure
        if (sortParams?.length) {
            body.sortFields = [sortParams];
        }

        // User
        formValue.firstName && (body.criteria.firstName = `*${formValue.firstName}*`);
        formValue.lastName && (body.criteria.lastName = `*${formValue.lastName}*`);
        formValue.id && (body.criteria.id = `*${formValue.id}*`);
        formValue.title && (body.criteria.title = `*${formValue.title}*`);

        // Address
        formValue.address1 && (body.criteria.addressLine1 = `*${formValue.address1}*`);
        formValue.city && (body.criteria.city = `*${formValue.city}*`);
        (typeof (formValue.region) === 'string') ? formValue.region && (body.criteria.companyCriteria.regionCodes = [formValue.region]) : formValue.region && (body.criteria.companyCriteria.regionCodes = formValue.region.map((regionOption) => regionOption.value));
        formValue.postalCode && (body.criteria.postalCode = `*${formValue.postalCode}*`);

        // INFO
        formValue.email && (body.criteria.emailAddress = `*${formValue.email}*`);
        formValue.phone && (body.criteria.phoneNumber = `*${formValue.phone}*`);

        formValue.country?.length && formValue.country?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.countryCodes = formValue.country.map((country: Option) => country.value));
        formValue.fax && (body.criteria.faxNumber = `*${formValue.fax}*`);

        if (formValue.statusActive && !formValue.statusInactive) {
            body.criteria.statuses = ['ACTIVE'];
        }
        else if (!formValue.statusActive && formValue.statusInactive) {
            body.criteria.statuses = ['INACTIVE'];
        }
        else if (formValue.statusActive && formValue.statusInactive) {
            body.criteria.statuses = ['ACTIVE', 'INACTIVE'];
        }

        // Company Criteria
        formValue.entityType?.length && (body.criteria.legalEntity = Number(formValue.entityType[0].value));
        formValue.distributorBranch?.length && (body.criteria.distBranch = formValue.distributorBranch[0].value);
        formValue.companyType?.length && formValue.companyType?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.companyCriteria.companyTypeCodes = formValue.companyType.map((type: Option) => type.value));
        formValue.relationshipType?.length && formValue.relationshipType?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.companyCriteria.relationShipTypes = formValue.relationshipType.map((type: Option) => type.value));
        formValue.brand?.length && formValue.brand?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.companyCriteria.brandCodes = formValue.brand.map((brand: Option) => brand.value));
        formValue.companyName && (body.criteria.companyCriteria.name = `*${formValue.companyName}*`);
        formValue.companyId && (body.criteria.companyCriteria.hvacCompanyIds = [Number(formValue.companyId)]);
        formValue.payer && (body.criteria.companyCriteria.payerCustomerId = `*${formValue.payer}*`);
        formValue.cin && (body.criteria.companyCriteria.cin = formValue.cin);
        formValue.hvp && (body.criteria.companyCriteria.hvp = formValue.hvp);
        formValue.priceList && (body.criteria.companyCriteria.priceListType = `*${formValue.priceList}*`);
        formValue.priceGroup && (body.criteria.companyCriteria.priceGroup = `*${formValue.priceGroup}*`);
        formValue.shipTo && (body.criteria.companyCriteria.shipToCustomerId = `*${formValue.shipTo}*`);
        formValue.soldTo && (body.criteria.companyCriteria.soldToCustomerId = `*${formValue.soldTo}*`);
        formValue.parentCompanyID && (body.criteria.companyCriteria.parentCompanyId = [Number(formValue.parentCompanyID)]);
        formValue.parentCompanyName && (body.criteria.companyCriteria.parentCompanyName = `*${formValue.parentCompanyName}*`);
        formValue.soldToNumber && (body.criteria.companyCriteria.relationshipSoldTo = [String(formValue.soldToNumber)]);
        formValue.distributorID && (body.criteria.companyCriteria.relationshipDistributorIds = [Number(formValue.distributorID)]);
        formValue.type?.length && formValue.type?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.companyCriteria.relationShipTypes = formValue.type.map((type: Option) => type.name));
        formValue.tierLevel?.length && formValue.tierLevel?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.companyCriteria.tierLevel = formValue.tierLevel.map((tierLevel: Option) => tierLevel.name));
        formValue.status?.length && formValue.status?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.companyCriteria.relationShipStatuses = formValue.status.map((status: Option) => status.name));

        const dateCriteria = formValue.createdDate?.length && formValue.createdDate?.filter((item: Option) => item.value && item.value !== '').length && formValue.createdDate[0]?.value;

        if (formValue.createdDate?.length && dateCriteria === '1') {
            formValue.startDate && (body.criteria.companyCriteria.relCreatedStartDate = String(formValue.startDate));
            formValue.endDate && (body.criteria.companyCriteria.relCreatedEndDate = String(formValue.endDate));
        }
        else if (formValue.createdDate?.length) {
            formValue.startDate && (body.criteria.companyCriteria.relModifiedStartDate = String(formValue.startDate));
            formValue.endDate && (body.criteria.companyCriteria.relModifiedEndDate = String(formValue.endDate));
        }

        if (formValue.locationMain && !formValue.locationBranch) {
            body.criteria.companyCriteria.locationTypes = ['MAIN'];
        }
        else if (!formValue.locationMain && formValue.locationBranch) {
            body.criteria.companyCriteria.locationTypes = ['BRANCH'];
        }
        else if (formValue.locationMain && formValue.locationBranch) {
            body.criteria.companyCriteria.locationTypes = ['MAIN', 'BRANCH'];
        }

        // Managers
        body.criteria.companyCriteria.employeeContacts = {};
        if (this.accountAdminUtilsService.hasEmployeeContacts(formValue)) {
            body.criteria.companyCriteria.employeeContacts = {};

            formValue.accountManager?.length && (body.criteria.companyCriteria.employeeContacts['AM'] = formValue.accountManager.map((contact: Option) => contact.value));
            formValue.accountManager?.length && (body.criteria.companyCriteria.employeeContacts['PTAM'] = formValue.accountManager.map((contact: Option) => contact.value));
            formValue.rsm?.length && (body.criteria.companyCriteria.employeeContacts['RSM'] = formValue.rsm.map((contact: Option) => contact.value));
            formValue.rsm?.length && (body.criteria.companyCriteria.employeeContacts['PRSM'] = formValue.rsm.map((contact:Option) => contact.value));
            formValue.tsm?.length && (body.criteria.companyCriteria.employeeContacts['TSM'] = formValue.tsm.map((contact: Option) => contact.value));
            formValue.pricingAnal?.length && (body.criteria.companyCriteria.employeeContacts['PA'] = formValue.pricingAnal.map((contact: Option) => contact.value));
        }

        return body;
    }

    public createCompanyAuthSearchBody(formValue: Partial <AccountAdminFormFields>, pagination: number | null, sortParams: string | null, section: string) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: any = {
            criteria: {
                companyTypeCodes: [],
                contactTypeIds: [],
                includeContactsInResponse: false,
                fetchByUsersHierarchy: true
            },
            pageParams: {
                page: (pagination || 1) - 1,
                pageSize: 10
            },
            determineTotalCount: true
        };

        if (sortParams?.length) {
            body.sortFields = [sortParams];
        }

        if (section === 'userCriteria' || this.accountAdminUtilsService.hasContactCriteria(formValue)) {
            body.criteria.userCriteria = {};
            body.criteria.includeContactsInResponse = false;
        }

        // Address
        formValue.address1 && (body.criteria.addressLine1 = `*${formValue.address1}*`);
        formValue.city && (body.criteria.city = `*${formValue.city}*`);
        (typeof (formValue.region) === 'string') ? formValue.region && (body.criteria.regionCodes = [formValue.region]) : formValue.region && (body.criteria.regionCodes = formValue.region.map((regionOption) => regionOption.value));
        formValue.postalCode && (body.criteria.postalCode = `*${formValue.postalCode}*`);

        formValue.country?.length && formValue.country?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.countryCodes = formValue.country.map((country: Option) => country.value));

        // Company Criteria
        formValue.companyType?.length && formValue.companyType?.filter((item: Option) => item.value && item.value !== '').length && (body.criteria.companyTypeCodes = formValue.companyType.map((type: Option) => type.value));

        formValue.companyName && (body.criteria.name = `*${formValue.companyName}*`);
        formValue.companyId && (body.criteria.hvacCompanyIds = [Number(formValue.companyId)]);

        formValue.soldTo && (body.criteria.soldToCustomerId = `*${formValue.soldTo}*`);

        return body;
    }

    public postUserSearch(body: PostUserSearchQuery): Observable<UserModel> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<UserModel>(`${environment.api.accountAdminUserSearch}`, body, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public postUserSearchExport(body: PostUserSearchQuery): Observable<any> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<string>(`${environment.api.accountAdminUserExport}`, body, {
                ...options,
                observe: 'response',
                responseType: 'blob' as 'json'
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }
}
