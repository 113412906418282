import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'common/components/base/base.component';
import { OpportunityReportsService } from 'private/app/services/connected-portal/opportunity-reports.service';
import { catchError, finalize, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, EMPTY, Subject, combineLatest, of } from 'rxjs';
import { CSVExportService } from 'common/services/csv-export.service';
import { CompanyCode } from 'private/app/models/company.model';
import { ActivityCode, ActivityTrackerService } from 'private/app/services/connected-portal/activity-tracker.service';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { environment } from 'common/environments/environment';
import { stringToBool } from 'private/app/services/connected-portal/utils';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { AsyncPipe, KeyValuePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ConnectedPortalModule } from 'private/app/views/connected-portal/connected-portal.module';
import { OpportunityReportCardComponent } from 'private/app/views/connected-portal/dealer/dealer-opportunities/components/opportunity-report-card/opportunity-report-card.component';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { Select } from '@ngxs/store';
import { Observable } from '@apollo/client/utilities';
import { BrandFamily } from 'common/models/brand.model';

interface OpportunityCard {
    isLoading: boolean;
    isDownloading: boolean;
    id: string;
    title: string;
    description: string;
}

export enum OpportunityCardId {
    DataSharing = 'data-sharing',
    Registration = 'registration',
    VppEligibility ='vpp-eligibility'
}

@Component({
    selector: 'hvac-opportunity-report-cards',
    templateUrl: './opportunity-report-cards.component.html',
    styleUrls: ['./opportunity-report-cards.component.scss'],
    standalone: true,
    imports: [
        CommonComponentsModule,
        TranslateModule,
        NgClass,
        ConnectedPortalModule,
        NgForOf,
        AsyncPipe,
        KeyValuePipe,
        OpportunityReportCardComponent,
        NgIf,
        ReactiveFormsModule
    ]
})
export class OpportunityReportCardsComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() brand: string;
    @Input() dealerId: string;
    @Input() userType: string;
    @Input() inVppTerritory: string;

    @Select(AccountStatusState.getUserBrandFamily) userBrandFamily$: Observable<string | null>;

    public yearSelectOptions = this.opportunityReportsService.loginOpportunityYearOptions();
    public startYear = this.yearSelectOptions[this.yearSelectOptions.length - 1].value;
    public loginOpportunityYearFormControl = new UntypedFormControl(this.startYear);
    public isloginOpportunityDownloading = false;
    public vppEligibilityReportEnabled = environment?.features?.connectedPortal?.vppEligiblityReportEnabled || false;

    public opportunityCards$ = new BehaviorSubject({
        ...this.createOpportunityCardPlaceholder(OpportunityCardId.DataSharing),
        ...this.createOpportunityCardPlaceholder(OpportunityCardId.Registration)
    });

    public brandFamily: string | null;

    private brand$ = new BehaviorSubject<string | null>(null);
    private downloader$ = new Subject<OpportunityCardId>();

    constructor(
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private opportunityReportsService: OpportunityReportsService,
        private csvService: CSVExportService,
        private activityTrackerService: ActivityTrackerService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.brand) {
            this.brand$.next(changes.brand.currentValue);
        }

        if (changes.inVppTerritory) {
            this.opportunityCards$.next({
                ...this.opportunityCards$.value,
                ...this.createOpportunityCardPlaceholder(OpportunityCardId.VppEligibility)
            });
        }
    }

    ngOnInit(): void {
        if (!this.route.parent) {
            return;
        }

        this.initCardData().subscribe();
        this.initDownloader().subscribe();
        this.userBrandFamily$.subscribe((brand) => this.brandFamily = brand);
    }

    public handleDownloadClick(id: string) {
        if (this.userType === CompanyCode.Dealer) {
            if (id === OpportunityCardId.DataSharing) {
                this.trackActivity(ActivityCode.DataSharingDisabledReportDownload);
            }
            else if (id === OpportunityCardId.Registration) {
                this.trackActivity(ActivityCode.UnregisteredControlsReportDownload);
            }
            else if (id === OpportunityCardId.VppEligibility) {
                this.trackActivity(ActivityCode.VPPEligibilityReportDownload);
            }
        }
        this.downloader$.next(id as OpportunityCardId);
    }

    public loginOpportunityReportDownload() {
        if (this.isloginOpportunityDownloading) {
            return;
        }
        this.isloginOpportunityDownloading = true;

        const year = this.loginOpportunityYearFormControl.value;
        const dealerId = this.dealerId;

        this.opportunityReportsService.queryDealershipActivityReportByDealerId(dealerId, year)
            .pipe(
                map((res) => res.data?.filepath),
                tap((filepath) => filepath && this.csvService.downloadFile(filepath)),
                catchError((err) => err),
                finalize(() => this.isloginOpportunityDownloading = false)
            ).subscribe();
    }


    private trackActivity(activityCode:ActivityCode) {
        this.activityTrackerService.trackActivity({
            dealerId: this.dealerId,
            activityCode
        })
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe();
    }

    private initDownloader() {
        return this.downloader$.pipe(
            map((cardId) => ({
                cardId,
                dealerId: this.dealerId
            })),
            switchMap(({ cardId, dealerId }) => {
                switch (cardId) {
                    case OpportunityCardId.DataSharing:
                        this.updateOpportunityCard(cardId, { isDownloading: true });

                        return this.opportunityReportsService.queryDisabledDataSharingByDealerId(dealerId).pipe(
                            tap((res) => {
                                this.updateOpportunityCard(cardId, { isDownloading: false });

                                if (res.data?.filepath) {
                                    this.csvService.downloadFile(res.data.filepath);
                                }
                            }),
                            catchError((err) => {
                                this.updateOpportunityCard(cardId, { isDownloading: false });

                                return err;
                            })
                        );
                    case OpportunityCardId.Registration:
                        this.updateOpportunityCard(cardId, { isDownloading: true });

                        return this.opportunityReportsService.queryUnregisteredControlsByDealerId(dealerId).pipe(
                            tap((res) => {
                                this.updateOpportunityCard(cardId, { isDownloading: false });

                                if (res.data?.filepath) {
                                    this.csvService.downloadFile(res.data.filepath);
                                }
                            }),
                            catchError((err) => {
                                this.updateOpportunityCard(cardId, { isDownloading: false });

                                return err;
                            })
                        );

                    case OpportunityCardId.VppEligibility:
                        this.updateOpportunityCard(cardId, { isDownloading: true });

                        return this.opportunityReportsService.queryVppEligibilityByDealerID(dealerId).pipe(
                            tap((res) => {
                                this.updateOpportunityCard(cardId, { isDownloading: false });

                                if (res.data?.filepath) {
                                    this.csvService.downloadFile(res.data.filepath);
                                }
                            }),
                            catchError((err) => {
                                this.updateOpportunityCard(cardId, { isDownloading: false });

                                return err;
                            })
                        );

                    default:
                        return EMPTY;
                }
            }),
            takeUntil(this.ngOnDestroy$)
        );
    }

    private initCardData() {
        const dataSharingCount$ = this.opportunityReportsService.queryDisabledDataSharingByDealerId(this.dealerId, true).pipe(
            map(({ totalCount }) => (totalCount)),
            shareReplay()
        );

        const dataSharing$ = dataSharingCount$.pipe(
            tap((count) => {
                this.updateOpportunityCard(OpportunityCardId.DataSharing, {
                    isLoading: false,
                    title: this.translateService.instant('CONNECTED_PORTAL.OPPORTUNITIES.CARDS.DATA_SHARING.TITLE'),
                    description: this.translateService.instant('CONNECTED_PORTAL.OPPORTUNITIES.CARDS.DATA_SHARING.DESCRIPTION', { count })
                });
            })
        );

        const registrationCount$ = this.opportunityReportsService.queryUnregisteredControlsByDealerId(this.dealerId, true).pipe(
            map(({ totalCount }) => (totalCount)),
            shareReplay()
        );

        const registration$ = combineLatest([this.brand$, registrationCount$]).pipe(
            tap(([brand, count]) => {
                if (brand) {
                    const accountLink = this.brandFamily?.toLowerCase() === BrandFamily.ICP ?
                        this.translateService.instant('CONNECTED_PORTAL.OPPORTUNITIES.CARDS.REGISTRATION.ICP_LINK')
                        : brand.toLowerCase() === 'bryant'
                            ? this.translateService.instant('CONNECTED_PORTAL.OPPORTUNITIES.CARDS.REGISTRATION.BRYANT_LINK')
                            : this.translateService.instant('CONNECTED_PORTAL.OPPORTUNITIES.CARDS.REGISTRATION.CARRIER_LINK');

                    const registrationDescriptionKey = this.brandFamily?.toLowerCase() === BrandFamily.ICP ?
                        'CONNECTED_PORTAL.OPPORTUNITIES.CARDS.REGISTRATION.ICP_DESCRIPTION' :
                        'CONNECTED_PORTAL.OPPORTUNITIES.CARDS.REGISTRATION.DESCRIPTION';

                    this.updateOpportunityCard(OpportunityCardId.Registration, {
                        isLoading: false,
                        title: this.translateService.instant('CONNECTED_PORTAL.OPPORTUNITIES.CARDS.REGISTRATION.TITLE'),
                        description: this.translateService.instant(registrationDescriptionKey, {
                            count,
                            accountLink
                        })
                    });
                }
            })
        );

        if (!this.vppEligibilityReportEnabled || !stringToBool(this.inVppTerritory)) {
            return combineLatest([dataSharing$, registration$, of(0)]);
        }

        const vppEligibleDealersCount$ = this.opportunityReportsService.queryVppEligibilityByDealerID(this.dealerId).pipe(
            map(({ totalCount }) => (totalCount)),
            shareReplay()
        );

        const vppEligibleDealers$ = vppEligibleDealersCount$.pipe(
            tap((count) => {
                const eligibleDealersCount = count || 0;
                this.updateOpportunityCard(OpportunityCardId.VppEligibility, {
                    isLoading: false,
                    title: this.translateService.instant('CONNECTED_PORTAL.OPPORTUNITIES.CARDS.VPP_ELIGIBLE_DEALERS.TITLE'),
                    description: this.translateService.instant('CONNECTED_PORTAL.OPPORTUNITIES.CARDS.VPP_ELIGIBLE_DEALERS.DESCRIPTION', { eligibleDealersCount })
                });
            })
        );

        return combineLatest([dataSharing$, registration$, vppEligibleDealers$]);
    }

    private updateOpportunityCard(cardId: OpportunityCardId, cardValue: Partial<OpportunityCard>) {
        if (cardId === OpportunityCardId.VppEligibility && (!this.vppEligibilityReportEnabled || !stringToBool(this.inVppTerritory))) {
            return;
        }

        this.opportunityCards$.next({
            ...this.opportunityCards$.value,
            [cardId]: {
                ...this.opportunityCards$.value[cardId],
                ...cardValue
            }
        });
    }

    private createOpportunityCardPlaceholder(cardId: OpportunityCardId): Record<string, OpportunityCard> {
        if (cardId === OpportunityCardId.VppEligibility && (!this.vppEligibilityReportEnabled || !stringToBool(this.inVppTerritory))) {
            return {};
        }

        return {
            [cardId]: {
                isLoading: true,
                isDownloading: false,
                id: cardId,
                title: '',
                description: ''
            }
        };
    }
}
