import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'common/components/select/select.component';
import { ContentHeadingData } from 'common/models/content-heading';
import { AccountAdminProgramEditData, Brand, EligibilityCriteria, ProgramAdminLocatorIcon, ProgramDetails, ProgramEditFormField, ProgramManager } from 'private/app/models/account-admin-program.model';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LanguageTranslationService } from 'common/services/language-translation.service';
import { AccountAdminProgramFormService } from './account-admin-program-form.service';
import { Router } from '@angular/router';
import { GMTTimeConversionUtilsService } from 'src/common/services/gmt-time-transformation.service';

export enum DateError {
    ENROLLMENT = 'enrollment',
    LOCATOR = 'locator'
}

@Injectable({ providedIn: 'root' })
export class AccountAdminProgramService implements OnDestroy {
    public programSearchForm = AccountAdminProgramFormService.programSearchForm;
    public programEditForm = AccountAdminProgramFormService.programEditForm;
    public statusOptions: Option[];
    public programVerticalOptions: Option[];
    public programEnrollmentWorkflowOptions: Option[];
    public programFinalApprover: Option[];
    public programEnrollmentFormOptions: Option[];
    public isEnrollmentFormVisible$ = new BehaviorSubject<boolean>(false);
    public programAdminData: AccountAdminProgramEditData;
    public isModalVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public isEditProgram: boolean = false;
    public chnagesMadeInProgramManagers: boolean = true;

    private breadCrumbData$: Subject<ContentHeadingData> = new Subject<ContentHeadingData>();
    private ngOnDestroy$ = new Subject();
    private enrollmentFormVisible$: Observable<boolean> = combineLatest([this.programEditForm.controls.enrollmentWorkflow.valueChanges, this.translateService.get('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_WORKFLOW.ENROLLMENT')]).pipe(
        takeUntil(this.ngOnDestroy$),
        map(([value, workFlowSelect]) => {
            if (!Array.isArray(value)) {
                return value?.toLowerCase() !== workFlowSelect.toLowerCase();
            }
            if (value && workFlowSelect) {
                return (value[0] as unknown as Option).name.toLowerCase() !== workFlowSelect.toLowerCase();
            }

            return true;
        })
    );

    constructor(
        private translateService: TranslateService,
        private languageTranslationService: LanguageTranslationService,
        private router: Router,
        private gmtTimeConversionUtilsService: GMTTimeConversionUtilsService
    ) {
        this.enrollmentFormVisible$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((visibility) => this.isEnrollmentFormVisible$.next(visibility))
        ).subscribe();

        this.languageTranslationService.translationsLoaded().subscribe(() => {
            this.getDropDownOptions();
        });
    }

    updateUI(programAdminDetails: AccountAdminProgramEditData) {
        this.programAdminData = programAdminDetails;
        const programDetails = programAdminDetails.programDetails;
        if (this.programEditForm) {
            this.isEditProgram = Boolean(!this.router.url.includes('/clone/'));
            this.programEditForm.controls.name.setValue(this.isEditProgram ? programDetails.name : '');
            this.programEditForm.controls.code.setValue(this.isEditProgram ? programDetails.code : '');
            this.programEditForm.controls.description.setValue(programDetails.description);
            this.programEditForm.controls.brands.setValue(programDetails.brand?.name || '');
            this.programEditForm.controls.enrollmentLevel.setValue(programDetails.enrollmentLevel);
            this.programEditForm.controls.massEnrollFl.setValue(programDetails.massEnrollFl);
            this.programEditForm.controls.vertical.setValue(this.programVerticalOptions.find((vertical) => String(vertical.value) === String(programDetails.vertical?.id))?.name || '');
            this.programEditForm.controls.eligibilityCriteria.setValue(programDetails.eligibilityCriteria?.name || '');
            this.programEditForm.controls.locatorIcon.setValue(programDetails.locatorIcon?.description || this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.SELECT_ICON'));
            this.programEditForm.controls.enrollmentWorkflow.setValue(this.programEnrollmentWorkflowOptions.find((enrollmentFlow) => String(enrollmentFlow.value) === String(programDetails.enrollmentWorkflow?.id))?.name || this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_WORKFLOW.ENROLLMENT'));
            this.programEditForm.controls.enrollmentForm.setValue(this.programEnrollmentFormOptions.find((enrollmentForm) => String(enrollmentForm.value) === String(programDetails.enrollmentForm?.id))?.name || this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENTFORM.STANDARD'));
            this.programEditForm.controls.finalApprovedBy.setValue(programDetails.finalApprovedBy?.desc ? programDetails.finalApprovedBy?.desc : this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.FINAL_APPROVER.SELECT_APPROVER'));
        }
    }

    getProgramAdminPostData(formValue: ProgramEditFormField): ProgramDetails {
        const body: ProgramDetails = this.programAdminData.programDetails;

        formValue.name && (body.name = `${formValue.name}`);
        formValue.code && (body.code = `${formValue.code}`);
        formValue.description && (body.description = `${formValue.description}`);
        const { vertical, eligibilityCriteria, locatorIcon, enrollmentWorkflow, enrollmentForm, finalApprovedBy, brands } = formValue;
        if (vertical) {
            body.vertical.description = this.getSelectTextFromPayload(formValue.vertical);
            body.vertical.id = this.getSelectValueFromOptionData(this.programVerticalOptions, formValue.vertical);
        }

        if (eligibilityCriteria) {
            body.eligibilityCriteria.name = this.getSelectTextFromPayload(formValue.eligibilityCriteria);
            body.eligibilityCriteria.id = this.getSelectValueFromOptionData(this.programAdminData.criteria, formValue?.eligibilityCriteria);
        }
        if (brands) {
            body.brand.name = this.getSelectTextFromPayload(formValue.brands);
            body.brand.id = this.getSelectValueFromOptionData(this.programAdminData.brandValue, formValue?.brands);
        }

        body.enrollmentLevel = formValue.enrollmentLevel;
        body.massEnrollFl = formValue.massEnrollFl;

        if (locatorIcon && body.locatorIcon?.id.length !== 0) {
            body.locatorIcon?.description && (body.locatorIcon.description = this.getSelectTextFromPayload(formValue.locatorIcon));
            body.locatorIcon?.id && (body.locatorIcon.id = this.getSelectValueFromOptionData(this.programAdminData.locatorIcons, formValue.locatorIcon));
        }
        else {
            delete this.programAdminData.programDetails['locatorIcon'];
        }
        if (enrollmentWorkflow) {
            body.enrollmentWorkflow?.name && (body.enrollmentWorkflow.name = this.getSelectTextFromPayload(formValue.enrollmentWorkflow));
            body.enrollmentWorkflow?.id && (body.enrollmentWorkflow.id = this.getSelectValueFromOptionData(this.programEnrollmentWorkflowOptions, formValue.enrollmentWorkflow));
        }
        if (enrollmentForm && body.enrollmentForm) {
            body.enrollmentForm.name = this.getSelectTextFromPayload(formValue.enrollmentForm);
            body.enrollmentForm.id = this.getSelectValueFromOptionData(this.programEnrollmentFormOptions, formValue.enrollmentForm);
        }
        if (finalApprovedBy && body.finalApprovedBy) {
            body.finalApprovedBy.desc = this.getSelectTextFromPayload(formValue.finalApprovedBy);
            body.finalApprovedBy.id = this.getSelectValueFromOptionData(this.programFinalApprover, formValue.finalApprovedBy);
        }
        if (this.isEnrollmentFormVisible$.value) {
            delete this.programAdminData.programDetails['finalApprovedBy'];
        }
        else {
            delete this.programAdminData.programDetails['enrollmentForm'];
            delete this.programAdminData.programDetails['enrollmentWorkflow'];
        }

        return body;
    }

    public setProgramAdminBreadCrumData(breadCrumb: ContentHeadingData) {
        this.breadCrumbData$.next(breadCrumb);
    }

    public getProgramAdminBreadCrumData() {
        return this.breadCrumbData$;
    }

    public mergeProgramAdminData(res: [Partial<ProgramDetails>, EligibilityCriteria[], Brand[], ProgramAdminLocatorIcon, ProgramManager[]]): AccountAdminProgramEditData {
        const [
            programDetails,
            criteriaList,
            brandValueList,
            locatorIcons,
            programManagers
        ] = res;
        const LocatorBrand = Object.keys(locatorIcons).find((brand) => brand.toLowerCase() === programDetails?.brand?.name.toLowerCase());

        return {
            programDetails: programDetails as ProgramDetails,
            programStart: programDetails?.programStartDate ? this.gmtTimeConversionUtilsService.getGMTTimeFromDate(programDetails.programStartDate, { formatTimeAs: 'StartOfDay' })! : this.gmtTimeConversionUtilsService.getCurrentGMTTime({ formatTimeAs: 'StartOfDay' }),
            programEnd: programDetails?.programEndDate ? this.gmtTimeConversionUtilsService.getGMTTimeFromDate(programDetails.programEndDate, { formatTimeAs: 'EndOfDay' })! : this.gmtTimeConversionUtilsService.getCurrentGMTTime({ formatTimeAs: 'EndOfDay' }),
            enrollmentPeriodStart: programDetails?.enrollmentStartDate ? this.gmtTimeConversionUtilsService.getGMTTimeFromDate(programDetails.enrollmentStartDate, { formatTimeAs: 'StartOfDay' })! : 0,
            enrollmentPeriodEnd: programDetails?.enrollmentEndDate ? this.gmtTimeConversionUtilsService.getGMTTimeFromDate(programDetails.enrollmentEndDate, { formatTimeAs: 'EndOfDay' })! : 0,
            locatorStart: programDetails?.locatorStartDate ? this.gmtTimeConversionUtilsService.getGMTTimeFromDate(programDetails.locatorStartDate, { formatTimeAs: 'StartOfDay' })! : 0,
            locatorEnd: programDetails?.locatorEndDate ? this.gmtTimeConversionUtilsService.getGMTTimeFromDate(programDetails.locatorEndDate, { formatTimeAs: 'EndOfDay' })! : 0,
            criteria: criteriaList.map((criteria) => ({
                name: criteria.name,
                value: criteria.id
            })),
            brandValue: brandValueList.map((brandValue) => ({
                name: brandValue.name,
                value: brandValue.id
            })),
            locatorIcons: [
                ...(LocatorBrand
                    ? locatorIcons[LocatorBrand].map((icon) => ({
                        name: icon.description,
                        value: icon.id
                    }))
                    : [])
            ],
            locatorIconData: locatorIcons,
            programManagers: programManagers
        };
    }

    updateProgramManagers(managers: Option[]) {
        this.programAdminData.programDetails.programManagers = [...this.programAdminData.programDetails.programManagers, ...this.programAdminData.programManagers.filter((programManager) => managers.find((manager) => Number(programManager.userId) === Number(manager.value)))];
    }

    addManagerPopUp() {
        this.isModalVisible.next(true);
    }

    modalVisibilityChanged(event: boolean) {
        this.isModalVisible.next(event);
    }

    onManagerListChange(managers: Option[]) {
        this.updateProgramManagers(managers);
        this.chnagesMadeInProgramManagers = false;
    }

    removeManager(managerId: string) {
        this.programAdminData.programDetails.programManagers = this.programAdminData.programDetails.programManagers.filter((programManager) => programManager.userId !== managerId);
        this.chnagesMadeInProgramManagers = false;
    }

    public resetForm() {
        this.programEditForm.reset({
            vertical: '',
            brands: '',
            eligibilityCriteria: '',
            locatorIcon: '',
            enrollmentWorkflow: '',
            finalApprovedBy: '',
            enrollmentForm: ''
        });
    }

    smoothScrollToTop() {
        window.scroll({
            behavior: 'smooth',
            top: 0,
            left: 0
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    private getSelectTextFromPayload(formValue: Option[]): string {
        return Array.isArray(formValue) ? formValue[0].name : `${formValue}`;
    }

    private getSelectValueFromOptionData(controlArray: Option[], formValue: Option[]): string {
        return controlArray.find((approver) => approver.name.toLowerCase() === (formValue === Object(formValue) ? formValue[0].name.toLowerCase() : `${formValue}`).toLowerCase())?.value || '';
    }

    private getDropDownOptions() {
        this.statusOptions = [
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.PLACEHOLDER_TEXT.STATUS'),
                value: ''
            },
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.ACTIVE'),
                value: this.translateService.instant('ACCOUNT_ADMIN.ACTIVE')
            },
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.EXPIRED'),
                value: this.translateService.instant('ACCOUNT_ADMIN.EXPIRED')
            },
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PENDING'),
                value: this.translateService.instant('ACCOUNT_ADMIN.PENDING')
            }
        ];
        this.programVerticalOptions = [
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.PROGRAM_VERTICAL.RESIDENTIAL'),
                value: '1'
            },
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.PROGRAM_VERTICAL.DUCTLESS'),
                value: '2'
            }
        ];
        this.programEnrollmentWorkflowOptions = [
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_WORKFLOW.ENROLLMENT'),
                value: '0'
            },
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_WORKFLOW.DEALER_CONVERSION'),
                value: '1'
            }
        ];
        this.programFinalApprover = [
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.FINAL_APPROVER.SELECT_APPROVER'),
                value: '0'
            },
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.FINAL_APPROVER.INTERNAL'),
                value: '1'
            },
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.FINAL_APPROVER.DISTRIBUTOR'),
                value: '2'
            }
        ];
        this.programEnrollmentFormOptions = [
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENTFORM.BIG_FISH'),
                value: '2'
            },
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENTFORM.DUCTLESS'),
                value: '3'
            },
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENTFORM.NEW_DEALER'),
                value: '4'
            },
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENTFORM.DUCTLESS_WITH_OWNER'),
                value: '5'
            },
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENTFORM.DUCTLESS_NO_OWNER'),
                value: '6'
            }
        ];
    }
}
