<div class="hvac-cp-search-control-container">
    <div class="hvac-cp-search-control-info-txt">{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.SEARCH_CONTROL.INFO_TXT" | translate}}</div>
    <div class="hvac-cp-search-control-info-txt hvac-disclaimer">
        {{"CONNECTED_PORTAL.ADD_NEW_CONTROL.SEARCH_CONTROL.DISCLAIMER.TITLE" | translate}}
        <ul>
            <li *ngFor="let thermostat of disclaimerThermostats$ | async">{{thermostat}}</li>
        </ul>
    </div>
    
    <utc-input
        [error]="searchControlForm.controls.searchControl.dirty && !searchControlForm.controls.searchControl.valid"
        [errorMessage]="searchControlErrorMessage$ | async"
        [inputControl]="searchControlForm.controls.searchControl" inputId="search-control-input"
        [label]="'CONNECTED_PORTAL.ADD_NEW_CONTROL.SEARCH_CONTROL.INPUT_LABEL' | translate" type="text">
    </utc-input>
    <div class="hvac-cp-search-action-section">
        <button hvacbutton="primary"
            class="hvac-button hvac-button-primary hvac-button-small"
            (click)="searchControl()"
            buttonType="button"
            [disabled]="!(searchInputValue && searchControlForm.controls.searchControl.valid)"
        >
            <span>{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.SEARCH_CONTROL.ACTIONS.SEARCH_CONTROL" | translate}}</span>
        </button>
    </div>

    <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
</div>
