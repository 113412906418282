import { ElementRef, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AccountAdminCountriesEntity, AccountAdminStateEntity } from '../models/account-admin-search';
import { HttpClient } from '@angular/common/http';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { Option } from 'common/components/select/select.component';
import { map } from 'rxjs/operators';
import { environment } from 'common/environments/environment';
import { CacheNetworkObservableResponse } from '../models/Cache-Observable-Response';
import { DisabledDropdownStrings, Values } from './account-admin/account-admin-form-group-config';

@Injectable({ providedIn: 'root' })
export class CommonUtilsService {
    public dropDownsList: string[] = [DisabledDropdownStrings.contactType, DisabledDropdownStrings.rsm, DisabledDropdownStrings.tsm, DisabledDropdownStrings.pricingAnal, DisabledDropdownStrings.accountManager];

    public disabledDropdowns: Values = {
        contactType: false,
        rsm: false,
        tsm: false,
        accountManager: false,
        pricingAnal: false
    }

    private cacheForStateEntity: CacheNetworkObservableResponse<AccountAdminStateEntity[]>;
    private cacheForCountryEntity: CacheNetworkObservableResponse<AccountAdminCountriesEntity[]>;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) {}

    public smoothScrollToTop(element: ElementRef) {
        element.nativeElement.parentElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    }

    public getStateByCountry(selectedCountry: string): Observable<Option[]> {
        if (selectedCountry === '') {
            return of([]);
        }

        return this.getStateEntityByCountry(selectedCountry).pipe(
            map((stateEntityList: AccountAdminStateEntity[]): Option[] => {
                const optionList = stateEntityList.map((stateEntity: AccountAdminStateEntity): Option => ({
                    name: stateEntity.name,
                    value: stateEntity.code
                }));

                return optionList;
            })
        );
    }

    public getStateEntityByCountry(selectedCountry: string): Observable<AccountAdminStateEntity[]> {
        if (selectedCountry === '') {
            return of([]);
        }

        const urlForSelectedCountry = environment.api.getStateListByCountry.split('{{state}}').join(selectedCountry);

        if (!this.cacheForStateEntity) {
            this.cacheForStateEntity = CacheNetworkObservableResponse.createCachedClass<AccountAdminStateEntity[]>(
                this.cacheForStateEntity,
                this.httpClient,
                this.apiOptions,
                this.getStateEntityByCountry.name
            );
        }

        return this.cacheForStateEntity.value(urlForSelectedCountry);
    }

    public getCountryOptions(): Observable<Option[]> {
        return this.getCountriesEntity().pipe(
            map((countryEntities: AccountAdminCountriesEntity[]) => countryEntities.map((countryEntity: AccountAdminCountriesEntity) => ({
                name: countryEntity.name,
                value: countryEntity.code
            })))
        );
    }

    public getCountriesEntity(): Observable<AccountAdminCountriesEntity[]> {
        if (!this.cacheForCountryEntity) {
            this.cacheForCountryEntity = CacheNetworkObservableResponse.createCachedClass<AccountAdminCountriesEntity[]>(
                this.cacheForCountryEntity,
                this.httpClient,
                this.apiOptions,
                this.getCountriesEntity.name
            );
        }

        return this.cacheForCountryEntity.value(environment.api.getCountries);
    }

    public updateDropdownState(field: keyof Values) {
        const updatedValue: Values = {
            contactType: field !== DisabledDropdownStrings.contactType,
            rsm: field !== DisabledDropdownStrings.rsm,
            tsm: field !== DisabledDropdownStrings.tsm,
            accountManager: field !== DisabledDropdownStrings.accountManager,
            pricingAnal: field !== DisabledDropdownStrings.pricingAnal
        };

        return updatedValue;
    }

    public enableAllDropdowns() {
        const updatedValue: Values = {
            contactType: false,
            rsm: false,
            tsm: false,
            accountManager: false,
            pricingAnal: false
        };

        return updatedValue;
    }
}
