import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DiagnosticsConfigurations, SystemDiagnosticsParamConfig } from '../../../models/connected-portal-system-diagnostics.model';

@Pipe({
    name: 'systemDiagnosticsParamUnitType',
    standalone: true
})
export class SystemDiagnosticsParamUnitTypePipe implements PipeTransform {
    transform(items: KeyValue<keyof SystemDiagnosticsParamConfig, DiagnosticsConfigurations>[], filter: string, additionalFilter?: { filterparam: string, filterValue: string}): KeyValue<keyof SystemDiagnosticsParamConfig, DiagnosticsConfigurations>[] {
        if (!items || !filter) {
            return items;
        }

        if (additionalFilter) {
            const param = additionalFilter.filterparam as keyof DiagnosticsConfigurations;

            return items.filter((item) => item.value.equipmentType === filter && item.value[param] === additionalFilter.filterValue);
        }

        return items.filter((item) => item.value.equipmentType === filter);
    }
}

