<div class="hvac-models-table-container">
    <div class="hvac-soldTo-filter-container">
        <utc-input class="hvac-col-xs hvac-soldto-input" [inputControl]="filterControlGroup.controls.companyName"
            label="{{ (tableTypeIdentifier === 'availableSoldToTable' ? 'USER_ADMIN.USER_RELATIONSHIPS.SEARCH_AVAILABLE_SOLD_TOS_LABEL' : 'USER_ADMIN.USER_RELATIONSHIPS.SEARCH_ASSIGNED_SOLD_TOS_LABEL' ) | translate }}">
        </utc-input>

        <utc-input class="hvac-col-xs hvac-soldto-input" [inputControl]="filterControlGroup.controls.companyId"
            label="{{ 'USER_ADMIN.USER_RELATIONSHIPS.COMPANY_ID' | translate }}">
        </utc-input>
        <utc-input class="hvac-col-xs hvac-soldto-input" [inputControl]="filterControlGroup.controls.soldTos"
            label="{{ 'USER_ADMIN.USER_RELATIONSHIPS.SOLD_TO' | translate }}">
        </utc-input>
    </div>
    <div class="hvac-model-table-wrap hvac-rhythm-3">
        <hvac-table>
            <thead>
                <tr>
                    <td>
                        <button class="hvac-select-all-button" hvacButton="text" (click)="selectAllSoldTos()">
                        {{ 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.SELECT_ALL' | translate}}
                    </button>
                    </td>
                    <td *ngFor="let item of dataHeaders">
                        <div class="hvac-model-table-header">
                            <button class="hvac-table-sort-btn" (click)="sort(item)">
                                {{item.title}}
                                <span class="hvac-table-list-sort" [ngClass]="{
                            'hvac-table-list-sort-show': item.order,
                            'column-ascending': item.order === 'asc'
                        }">
                                    <utc-svg src="assets/icons/chevron-down.svg"></utc-svg>
                                </span>
                            </button>

                        </div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let data of (soldToDisplayArray$ | async)'>
                    <td>
                        <utc-checkbox  *ngIf="tableTypeIdentifier === 'availableSoldToTable'; else assignedSoldTo" [checked]="data.assigned || false" inputId="create-account-input-terms" inputName="create-account-input-terms"
                            (change)="selectSoldTo(data, $event)">
                        </utc-checkbox>
                        <ng-template #assignedSoldTo>
                            <utc-checkbox [checked]="!data.assigned" inputId="create-account-input-terms" inputName="create-account-input-terms"
                            (change)="selectSoldTo(data, $event)">
                        </utc-checkbox>
                        </ng-template>
                    </td>
                    <td class="hvac-sold-to-parent-brand-name">
                        {{ data.companyName }}
                    </td>
                    <td>
                        {{ data.companyId }}
                    </td>
                    <td>
                        {{ data.soldTo }}
                    </td>
                    <td>
                        {{ data.partsSoldTo }}
                    </td>
                </tr>
            </tbody>
        </hvac-table>
    </div>
    <div class="hvac-row hvac-between-xs">
        <div class="hvac-soldto-pagination-control">
            <hvac-pagination *ngIf="totalPages > 1" [totalPages]="totalPages" [currentPage]="currentPage"
                (pageChange)="handlePageChange($event)">
            </hvac-pagination>
        </div>
        <div  *ngIf="totalPages > 0" class="hvac-soldto-assignment-action-container">
            <button class="hvac-soldto-assignment-cancel-button" [hvacButton]="'primary'" (click)="cancelSoldTo()">
                <span>{{ 'USER_ADMIN.CANCEL' | translate }}</span>
            </button>
            <button class="hvac-soldto-assignment-export-button" [hvacButton]="'primary'" (click)="assignSelectedSoldTos()" [disabled]="!isButtonDisabled()">
                <span>{{ ( tableTypeIdentifier === 'availableSoldToTable' ? 'USER_ADMIN.USER_RELATIONSHIPS.ASSIGN_SELECTED_SOLDTOS' : 'USER_ADMIN.USER_RELATIONSHIPS.REMOVE_SELECTED_SOLDTOS' ) | translate }}</span>
            </button>
        </div>
    </div>
</div>
<ng-template [ngIf]="isLoading">
    <utc-loading-spinner></utc-loading-spinner>
</ng-template>
