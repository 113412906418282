import { Component, Input } from '@angular/core';
import { FactorySystemsFaultsComponent } from 'private/app/views/connected-portal/factory/factory-systems-overview/components/factory-systems-faults/factory-systems-faults.component';
import { ConnectedPortalModule } from 'private/app/views/connected-portal/connected-portal.module';
import { FaultSourceReportComponent } from 'private/app/views/connected-portal/factory/factory-systems-overview/factory-system-insights/components/fault-source-report/fault-source-report.component';
import { FaultSeverityReportComponent } from 'private/app/views/connected-portal/factory/factory-systems-overview/factory-system-insights/components/fault-severity-report/fault-severity-report.component';

@Component({
    selector: 'hvac-factory-system-insights',
    templateUrl: './factory-system-insights.component.html',
    styleUrls: ['./factory-system-insights.component.scss'],
    imports: [
        FactorySystemsFaultsComponent,
        ConnectedPortalModule,
        FaultSourceReportComponent,
        FaultSeverityReportComponent
    ],
    standalone: true
})
export class FactorySystemInsightsComponent {
    @Input() systemType: string;
    @Input() systemId: string;
}
