import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { ContentModule } from 'common/content/content.module';
import { environment } from 'common/environments/environment';
import { Toast, ToastService } from 'common/services/toast.service';
import { ComponentsModule } from 'private/app/components/components.module';
import { WorkflowData, SearchControlStatus } from 'private/app/models/add-control.model';
import { CustomerControlContextService } from 'private/app/services/connected-portal/customer-control-context.service';
import { DealerControlService } from 'private/app/services/connected-portal/dealer-control.service';
import { ApiResponseCode } from 'private/app/views/connected-portal/constants';
import { EMPTY } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-cp-add-control',
    templateUrl: './add-control.component.html',
    styleUrls: ['./add-control.component.scss'],
    standalone: true,
    imports: [CommonModule, ContentModule, TranslateModule, ComponentsModule]
})
export class AddControlComponent extends BaseComponent {
    @Input() statusDetails: WorkflowData;
    @Input() dealerId: string;
    @Output() onModalClose = new EventEmitter<string>();
    readonly searchControlStatus = SearchControlStatus;
    isLoading = false;
    displayControlFoundReadyToLinkAdditionalInfo = false;

    userAcknowledgement = {
        onControlReadyToLink: false,
        onControlLinkedToOtherDealer: false,
        disableCustomerNotification: false
    };

    disableNotificationControl = new UntypedFormControl(false);
    linkToOtherDealerControl = new UntypedFormControl(false);

    public isDisableNotificationEnabled = Boolean(environment.features.connectedPortal.addControlDisableNotification);

    constructor(
        private addControlSvc: DealerControlService,
        private translate: TranslateService,
        private customerControlContextSvc : CustomerControlContextService,
        private toastService: ToastService
    ) {
        super();
    }

    ngOnInit() {
        this.disableNotificationControl.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((val: boolean) => {
            this.userAcknowledgement.disableCustomerNotification = val;
        });

        this.linkToOtherDealerControl.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((val: boolean) => {
            this.userAcknowledgement.onControlLinkedToOtherDealer = val;
        });
        this.checkControlFoundReadyToLinkInfo();
    }

    addDealerControl() {
        this.isLoading = true;
        this.addControlSvc.addDealerControl(this.dealerId, this.statusDetails.controlNumber, this.statusDetails.status, this.userAcknowledgement.disableCustomerNotification)
            .pipe(
                takeUntil(this.ngOnDestroy$),
                catchError(() => {
                    this.isLoading = false;
                    this.closeModal();

                    return EMPTY;
                })
            )
            .subscribe((responseData) => {
                if (responseData) {
                    const { code, message } = responseData;
                    this.isLoading = false;

                    if (code === ApiResponseCode.SUCCESS) {
                        switch (this.statusDetails.status) {
                            case SearchControlStatus.CONTROL_FOUND_READY_TO_LINK:
                                this.customerControlContextSvc.isControlAdded = true;
                                this.customerControlContextSvc.toastContent = this.createToast(this.translate.instant('CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.TOAST.READY_TO_LINK_SUCCESS'));
                                break;
                            case SearchControlStatus.CONTROL_LINKED_TO_OTHER_DEALER:
                                this.customerControlContextSvc.isControlAdded = true;
                                this.customerControlContextSvc.toastContent = this.createToast(this.translate.instant('CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.TOAST.LINKED_TO_OTHER_DEALER_SUCCESS'));
                                break;
                            case SearchControlStatus.CONTROL_NOT_REGISTERED:
                                this.customerControlContextSvc.isControlAdded = true;
                                this.customerControlContextSvc.toastContent = this.createToast(this.translate.instant('CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.TOAST.NOT_REGISTERED_SUCCESS'));
                                break;
                            default:
                                break;
                        }

                        this.closeModal(this.statusDetails.status);
                    }
                    else {
                        this.toastService.add(this.createToast(message, true));
                        this.closeModal();
                    }
                }
            });
    }

    checkControlFoundReadyToLinkInfo() {
        const digitsCheck = (/^\d{12}$/).test(this.statusDetails?.controlNumber || '');
        const statusCheck = this.statusDetails?.status === SearchControlStatus.CONTROL_FOUND_READY_TO_LINK;
        this.displayControlFoundReadyToLinkAdditionalInfo = digitsCheck && statusCheck;
    }

    private createToast(msg: string, isError?: boolean): Toast {
        return ({
            content: msg,
            id: isError ? 'cp-add-control-error' : 'cp-add-control-success',
            closeable: true,
            autoClose: true,
            theme: isError ? 'error' : 'success',
            bgColor: '#F8F8F8'
        });
    }

    private closeModal(status?: string) {
        status ? this.onModalClose.next(status) : this.onModalClose.next();
    }
}
