import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WallControlStatus } from 'private/app/models/wall-control.model';
import { NumberSuffixPipe } from 'common/pipes/number-suffix.pipe';
import { DateTimeTzPipe } from 'private/app/pipes/date-time-tz.pipe';
import { CharSymbol } from 'common/enums/char-symbol';
import { CARRIER_ELT_STATUS_PROPERTIES_DISPLAY_MAP, DEVICE_TEMPERATURE_PROPERTIES } from 'private/app/views/connected-portal/constants';
import { SystemType } from 'private/app/models/connected-product.model';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { NgForOf } from '@angular/common';

@Component({
    selector: 'hvac-wall-control-status-details',
    templateUrl: './wall-control-status-details.component.html',
    styleUrls: ['./wall-control-status-details.component.scss'],
    standalone: true,
    imports: [
        CommonComponentsModule,
        NgForOf
    ]
})
export class WallControlStatusDetailsComponent implements OnChanges {
    @Input() data: WallControlStatus;
    @Input() systemType: SystemType;

    public statusProps: { label: string; value: unknown }[];

    constructor(
        private translateService: TranslateService,
        private dateTimeTzPipe: DateTimeTzPipe,
        private numberSuffixPipe: NumberSuffixPipe
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.data?.currentValue) {
            if (this.systemType === SystemType.CARRIER_ELT) {
                this.statusProps = Object.entries(this.data)
                    .filter(([key]) => CARRIER_ELT_STATUS_PROPERTIES_DISPLAY_MAP.includes(key))
                    .map(([key, value]) => ({
                        label: this.getPropLabel(key),
                        value: this.formatValue(key, value)
                    }))
                    .filter(({ value }) => value !== null);
            }
            else if (this.systemType === SystemType.INFINITY) {
                this.statusProps = Object.entries(this.data)
                    .filter(([key]) => key !== 'isDisconnected')
                    .map(([key, value]) => ({
                        label: this.getPropLabel(key),
                        value: this.formatValue(key, value)
                    }))
                    .filter(({ value }) => value !== null);
            }
        }
    }

    formatValue(propertyName: string, value: string) {
        const tempUnitFormat = this.data?.tempUnitFormat === 'C' ? CharSymbol.Celsius : CharSymbol.Fahrenheit;

        if (DEVICE_TEMPERATURE_PROPERTIES.includes(propertyName)) {
            return this.numberSuffixPipe.transform(value, tempUnitFormat);
        }

        switch (propertyName) {
            case 'isRuntimeReportEnabled':
                return null;
            case 'dateTime':
                return this.dateTimeTzPipe.transform(value);
            case 'filterUsed':
            case 'humidifierPadsUsed':
            case 'uvLightsUsed':
            case 'ventilatorCleanUsed':
                return this.numberSuffixPipe.transform(value, CharSymbol.Percent);
            default:
                return value;
        }
    }

    getPropLabel(propName: string): string {
        const translationKey = `CONNECTED_PORTAL.WALL_CONTROL.DETAILED_STATUS.PROPS.${propName}`;

        return this.translateService.instant(translationKey) || '';
    }
}
