import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataHeader } from 'common/views/parts/parts-subcategory-details/parts-catalog-list/parts-catalog-list.component';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from 'common/services/toast.service';
import { AccountAdminCompanyCreateService } from 'private/app/services/account-admin/account-admin-company-create.service';
import { AccountAdminCompanyUtilsService } from 'private/app/services/account-admin/account-admin-company-utils.service';
import { AccountAdminFormResultMap, AccountAdminStateEntity } from 'private/app/models/account-admin-search';
import { Option } from '../../../../common/components/select/select.component';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
import { DuplicateCompanyCheckResult, DuplicateCompanyCheckService, DuplicateResultData } from 'private/app/services/duplicate-company-check/duplicate-company-check.service';

export interface DuplicateCompanyLookup {
    brandFamily?: string;
    companyType?: string[];
    companyName?: string;
    companyId?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state: {
        label: string;
        code: string;
    };
    country: {
        label: string;
        code: string;
    };
    postalCode?: string;
}

@Component({
    selector: 'hvac-duplicate-company-check',
    templateUrl: './duplicate-company-check.component.html',
    styleUrls: ['./duplicate-company-check.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DuplicateCompanyCheckComponent implements OnInit {
    @Input() duplicateCompanyLookup: DuplicateCompanyLookup;
    @Input() visible: boolean;
    @Input() apiRequestActive: boolean | null;
    @Output() onDuplicateCompanyConfirmation = new EventEmitter<DuplicateCompanyLookup | null>();
    @Output() toastOpenStatus = new EventEmitter<boolean>(false);
    @Output() createCompanyAfterDuplicateCheck = new EventEmitter<undefined>();

    public toastOutlet = 'duplicateCompanyToast';
    duplicateCompany$ = new BehaviorSubject<DuplicateCompanyLookup[]>([]);
    processStatus$ = new BehaviorSubject<boolean>(true);
    spinnerStatus$ = new BehaviorSubject<boolean>(true);
    toastStatus$ = new BehaviorSubject<boolean>(true);
    public companyCreateForm = this.companyCreateService.companyCreateForm;
    public stateProvince: string | undefined = '';
    formData: AccountAdminFormResultMap;
    dataHeaders: DataHeader[] = [
        {
            title: this.translate.instant('DUPLICATE_COMPANY_CHECK.COMPANY_NAME'),
            value: 'companyName',
            order: null
        },
        {
            title: this.translate.instant('DUPLICATE_COMPANY_CHECK.COMPANY_ID'),
            value: 'companyId',
            order: null
        },
        {
            title: this.translate.instant('DUPLICATE_COMPANY_CHECK.ADDRESS_1'),
            value: 'address1',
            order: null
        },
        {
            title: this.translate.instant('DUPLICATE_COMPANY_CHECK.ADDRESS_2'),
            value: 'address2',
            order: null
        },
        {
            title: this.translate.instant('DUPLICATE_COMPANY_CHECK.CITY'),
            value: 'city',
            order: null
        },
        {
            title: this.translate.instant('DUPLICATE_COMPANY_CHECK.STATE'),
            value: 'state',
            order: null
        },
        {
            title: this.translate.instant('DUPLICATE_COMPANY_CHECK.COUNTRY'),
            value: 'country',
            order: null
        },
        {
            title: this.translate.instant('DUPLICATE_COMPANY_CHECK.POSTAL_CODE'),
            value: 'postalCode',
            order: null
        },
        {
            title: this.translate.instant('DUPLICATE_COMPANY_CHECK.ACTION'),
            value: 'action',
            order: null
        }
    ];

    private bannerType = 'DuplicateCompanyToast';

    constructor(
        private readonly translate: TranslateService,
        private toast: ToastService,
        private commonUtils: CommonUtilsService,
        public accountAdminCompanyUtilsService: AccountAdminCompanyUtilsService,
        public readonly companyCreateService: AccountAdminCompanyCreateService,
        private duplicateCompanyCheckService: DuplicateCompanyCheckService
    ) {
    }

    ngOnInit(): void {
        this.spinnerStatus$.subscribe((status) => {
            if (status === false) {
                this.spinnerStatus$.next(true);
            }
        });
        this.duplicateCompanyCheckService.duplicateCompanyCheckResult$.subscribe((resultData: DuplicateCompanyCheckResult) => {
            this.processStatus$.next(true);
            this.duplicateCompany$.next([{
                companyName: this.duplicateCompanyLookup?.companyName,
                companyId: this.duplicateCompanyLookup?.companyId,
                address1: this.duplicateCompanyLookup?.address1,
                address2: this.duplicateCompanyLookup?.address2,
                city: this.duplicateCompanyLookup?.city,
                country: {
                    label: this.duplicateCompanyLookup?.country?.label,
                    code: this.duplicateCompanyLookup?.country?.code
                },
                state: {
                    label: this.duplicateCompanyLookup?.state?.label,
                    code: this.duplicateCompanyLookup?.state?.code
                },
                postalCode: this.duplicateCompanyLookup?.postalCode
            } as unknown as DuplicateCompanyLookup]);

            if (resultData.totalCount === 0) {
                this.processStatus$.next(false);
            }

            this.toast.add({
                content: this.translate.instant('DUPLICATE_COMPANY_CHECK.NO_MATCHING_ADDRESS_FOUND'),
                theme: 'warning',
                id: this.bannerType,
                closeable: false,
                autoClose: false,
                outletName: this.toastOutlet
            });

            this.toastStatus$.next(true);
            this.toastOpenStatus.emit(true);
            this.spinnerStatus$.next(false);
            const result: DuplicateResultData[] | DuplicateResultData | null | undefined = resultData.data;
            if (result) {
                if (Array.isArray(result)) {
                    this.duplicateCompany$.next([
                        ...result.map((data) => ({
                            companyName: data.companyName,
                            companyId: data.hvacCompanyId,
                            address1: data.address1,
                            city: data.city,
                            country: {
                                label: this.duplicateCompanyLookup?.country.code,
                                code: this.duplicateCompanyLookup?.country.code
                            },
                            state: {
                                label: this.duplicateCompanyLookup?.state.label,
                                code: this.duplicateCompanyLookup?.state.code
                            },
                            postalCode: data.postalCode
                        } as unknown as DuplicateCompanyLookup))
                    ]);
                    this.spinnerStatus$.next(false);
                }
                else {
                    const dataObj = result as DuplicateResultData;
                    if (Array.isArray(dataObj.address1)) {
                        this.stateProvince = dataObj.state;
                    }
                    else {
                        this.stateProvince = dataObj.state;
                    }

                    this.duplicateCompany$.next([{
                        companyName: dataObj.companyName,
                        companyId: dataObj?.hvacCompanyId,
                        address1: dataObj.address1,
                        city: dataObj.city,
                        country: {
                            label: this.duplicateCompanyLookup.country.label,
                            code: this.duplicateCompanyLookup.country.code
                        },
                        state: {
                            label: this.stateProvince,
                            code: this.stateProvince
                        },
                        postalCode: dataObj.postalCode
                    } as unknown as DuplicateCompanyLookup]);
                    this.spinnerStatus$.next(false);
                }
            }
        });

        this.companyCreateService.formData.subscribe((formData) => {
            this.formData = formData;
            this.companyCreateService.countryOptionData$.next(this.accountAdminCompanyUtilsService.filterCountryArraywithMostUsed(formData.countries) || []);
        });

        this.companyCreateForm.controls.country.valueChanges.subscribe((selectedCountry: string) => {
            if (Array.isArray(selectedCountry)) {
                this.accountAdminCompanyUtilsService.formInputPlaceHolder(selectedCountry[0].value);
                this.getStateListByCountry(selectedCountry[0].value);
            }
            else {
                const countrySelection = this.companyCreateService.countryOptionData$.value.find((country) => country.name === selectedCountry);
                if (countrySelection) {
                    this.accountAdminCompanyUtilsService.formInputPlaceHolder(countrySelection.value);
                    this.getStateListByCountry(countrySelection.value);
                }
            }
            this.companyCreateService.stateOptionData$.next([]);
            this.companyCreateForm.controls.state.setValue('');
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.duplicateCompanyLookup?.currentValue) {
            this.duplicateCompany$.next([]);
            this.duplicateCompanyCheckService.duplicateCompanyCheckFunction(changes?.duplicateCompanyLookup?.currentValue);
        }
    }

    userCreatedCompany(duplicateCompany: DuplicateCompanyLookup) {
        this.onDuplicateCompanyConfirmation.emit(duplicateCompany);
        this.toast.remove(this.bannerType);
        this.toastOpenStatus.emit(false);
        this.spinnerStatus$.next(true);
    }

    onSubmit() {
        this.onDuplicateCompanyConfirmation.emit(this.duplicateCompanyLookup);
    }

    initCreateCmpnyRqstIfValidInput() {
        this.createCompanyAfterDuplicateCheck.emit();
    }

    checkCompany() {
        this.duplicateCompanyLookup = {
            companyType: this.companyCreateForm.controls.companyType.value,
            companyName: this.companyCreateForm.controls.companyName.value,
            address1: this.companyCreateForm.controls.address1.value,
            city: this.companyCreateForm.controls.city.value,
            country: {
                label: this.companyCreateForm.controls.country.value[0].name,
                code: this.companyCreateForm.controls.country.value[0].value
            },
            state: {
                label: this.companyCreateForm.controls.state.value[0].name,
                code: this.companyCreateForm.controls.state.value[0].value
            },
            postalCode: this.companyCreateForm.controls.postalCode.value
        };

        this.duplicateCompanyCheckService.duplicateCompanyCheckFunction(this.duplicateCompanyLookup);
    }

    onCancel() {
        this.onDuplicateCompanyConfirmation.emit(null);
        this.toast.remove(this.bannerType);
        this.toastOpenStatus.emit(false);
        this.spinnerStatus$.next(true);
    }

    onCountryChange(option: Option[]) {
        this.companyCreateService.company.country = Array.isArray(option) ? this.accountAdminCompanyUtilsService.getCountryCodeByCountry(option[0].name, this.companyCreateService.countryOptionData$.value) : '';
    }

    onStateChange(option: Option[]) {
        this.companyCreateService.company.state = Array.isArray(option) ? this.accountAdminCompanyUtilsService.getRegionCodeByState(option[0].name, this.companyCreateService.stateOptionData$.value) : '';
    }

    private getStateListByCountry(selectedCountry: string) {
        this.commonUtils.getStateEntityByCountry(selectedCountry).subscribe((countryList) => {
            const optionList: Option[] = [];
            countryList.forEach((state: AccountAdminStateEntity) => {
                optionList.push({
                    name: state.name,
                    value: state.code
                });
            });
            this.companyCreateService.stateOptionData$.next(optionList);
        });
    }
}

