/* eslint-disable max-lines */
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from 'common/components/base/base.component';
import { ConnectedPortalAPIError, ConnectedPortalDataError } from 'private/app/models/connected-portal-error.model';
import { SystemType } from 'private/app/models/connected-product.model';
import { WallControlConfig } from 'private/app/models/wall-control.model';
import { ProductConfigurationService } from 'private/app/services/connected-portal/product-configuration.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { ApiResponseCode, CARRIER_ELT_CONFIG_PROP_CATEGORY_MAP, CONFIGURATION_RELOAD_DELAY, MISSING_API_RESPONSE_DATA, TempUnitFormat, UI_TRANSLATION_CONFIG, UI_TRANSLATION_VALUES } from 'private/app/views/connected-portal/constants';
import { BehaviorSubject, EMPTY, NEVER, Observable, combineLatest, of } from 'rxjs';
import { catchError, delay, distinctUntilChanged, map, startWith, pairwise, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProductDetailContextService } from '../../../../product-detail-context.service';
import { COMPRESSOR_LOCK_OUT_TEMP_PROPERTY, CarrierEltCategorizedData, CarrierEltConfigData, CarrierEltWallControlConfigService, HEAT_DELTA_STAGE_2_PROPERTY, COOL_DELTA_STAGE_2_PROPERTY, HEAT_DELTA_AUX_STAGE_2_PROPERTY } from './carrier-elt-wall-control-config.service';
import { stringToNumber } from 'private/app/services/connected-portal/utils';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { AsyncPipe, KeyValuePipe, NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigEditPropComponent } from 'private/app/views/connected-portal/dealer/customer-product-details/components/config-edit-prop/config-edit-prop.component';

enum ViewState {
    Idle = 'Idle',
    Data = 'Data',
    Loading = 'Loading'
}

@Component({
    selector: 'hvac-carrier-elt-wall-control-config',
    templateUrl: './carrier-elt-wall-control-config.component.html',
    styleUrls: ['./carrier-elt-wall-control-config.component.scss'],
    providers: [CarrierEltWallControlConfigService],
    standalone: true,
    imports: [CommonComponentsModule, NgForOf, AsyncPipe, NgIf, KeyValuePipe, TranslateModule, ConfigEditPropComponent]
})
export class CarrierEltWallControlConfigComponent extends BaseComponent implements OnInit {
    @Input() dealerId: string;
    @Input() serialNo: string;
    @Input() systemType: SystemType;
    @Input() isEditEnabled?: boolean;
    @Input() isSaveEnabled?: boolean;
    @Input() isDeviceConnected: boolean;
    @Input() tempUnitFormat: TempUnitFormat;

    public readonly ViewState = ViewState;
    public configData$: Observable<CarrierEltConfigData>;
    public categorizedData$: Observable<CarrierEltCategorizedData>;
    public configFormGroup: UntypedFormGroup | null = null;
    public toastOutlet = 'editConfigUpdateToast';
    public viewState$ = new BehaviorSubject(ViewState.Idle);
    public isCompressorLockOutWarningShown$ = new BehaviorSubject(false);
    public isHeatDeltaWarningShown$ = new BehaviorSubject(false);
    public isCoolDeltaWarningShown$ = new BehaviorSubject(false);
    public isHeatDeltaAuxWarningShown$ = new BehaviorSubject(false);
    public disableEditOnValidation = false;
    readonly COMPRESSOR_LOCK_OUT_TEMP_PROPERTY = COMPRESSOR_LOCK_OUT_TEMP_PROPERTY;
    readonly HEAT_DELTA_STAGE_2_PROPERTY = HEAT_DELTA_STAGE_2_PROPERTY;
    readonly COOL_DELTA_STAGE_2_PROPERTY = COOL_DELTA_STAGE_2_PROPERTY;
    readonly HEAT_DELTA_AUX_STAGE_2_PROPERTY = HEAT_DELTA_AUX_STAGE_2_PROPERTY;

    private loadData$ = new BehaviorSubject<boolean>(true);
    private wallControlConfigData$: Observable<WallControlConfig>;
    private configFormGroupInitialValues: Record<string, unknown> | null = null;
    private modifiedConfigParameters: string[] = [];

    constructor(
        private contextService: ProductDetailContextService,
        private productService: ProductService,
        private carrierEltConfigService: CarrierEltWallControlConfigService,
        private productConfigService: ProductConfigurationService
    ) {
        super();
    }

    ngOnInit(): void {
        // OBSERVABLES
        this.wallControlConfigData$ = this.loadData$.pipe(
            tap(() => {
                this.viewState$.next(ViewState.Loading);
                this.modifiedConfigParameters = [];
            }),
            switchMap(() => this.productService
                .queryWallControlConfigBySerialNo(this.serialNo, this.dealerId, this.systemType)
                .pipe(
                    map((res) => res.data),
                    catchError(() => {
                        this.viewState$.next(ViewState.Data);

                        return EMPTY;
                    })
                ))
        );

        this.configData$ = this.wallControlConfigData$.pipe(
            switchMap((data) => {
                if (this.isEditEnabled) {
                    return this.carrierEltConfigService.getEditableConfigPropData$(data, this.tempUnitFormat)
                        .pipe(
                            tap((editConfigProps) => {
                                const { configDataControls } = editConfigProps;
                                this.configFormGroup = new UntypedFormGroup(configDataControls);
                                this.configFormGroupInitialValues = this.configFormGroup.value;

                                this.disableEditOnValueChangeValidation();
                                this.initCompressorMinAuxMaxControlSyncing(this.configFormGroup);
                                this.setConfigFormValueChangeListner(this.configFormGroup);
                                this.initHeatDeltaControlSyncing(this.configFormGroup);
                                this.initCoolDeltaControlSyncing(this.configFormGroup);
                                this.initHeatDeltaAuxControlSyncing(this.configFormGroup);
                            }),
                            map((editConfigProps) => {
                                const { configData } = editConfigProps;
                                const configProps = configData;

                                return { configProps };
                            })
                        );
                }

                const configProps = this.productConfigService.getEltReadOnlyPropData(data, this.tempUnitFormat);

                return of({ configProps });
            }),
            tap(() => {
                this.viewState$.next(ViewState.Data);
            })
        );

        this.categorizedData$ = this.configData$.pipe(
            map((configData) => this.carrierEltConfigService.getCategorizedData(configData.configProps, CARRIER_ELT_CONFIG_PROP_CATEGORY_MAP))
        );

        // SUBSCRIPTIONS
        this.contextService.onConfigSaveConfirmed$
            .pipe(
                switchMap(() => this.saveConfigUpdates$()),
                takeUntil(this.ngOnDestroy$)
            ).subscribe();
    }

    public openConfirmModal() {
        this.contextService.isConfigEditConfirmModalVisible$.next(true);
    }

    public resetFormGroups() {
        this.configFormGroup?.reset(this.configFormGroupInitialValues);
        this.modifiedConfigParameters = [];
    }

    categoryOrder() {
        return 0;
    }

    onControlEditCancel(evt: Record<string, string>) {
        setTimeout(() => {
            if (evt.id && this.modifiedConfigParameters.includes(evt.id)) {
                const restParams = this.modifiedConfigParameters.filter((elm) => elm !== evt.id);
                this.modifiedConfigParameters = restParams;
            }
        }, 1000);
    }

    private saveConfigUpdates$() {
        if (!(this.configFormGroup)) {
            throw new Error('UPDATE_FAILED: Application Error');
        }

        const baseData = this.productConfigService.getValuesFromFormGroup(this.configFormGroup as UntypedFormGroup);
        const editedParamInitialValues = this.modifiedConfigParameters.reduce((acc, currParam) => {
            if (this.configFormGroupInitialValues && this.configFormGroupInitialValues[currParam]) {
                acc[currParam] = this.configFormGroupInitialValues[currParam];
            }

            return acc;
        }, {} as Record<string, unknown>);

        const submissionData = { ...baseData };

        for (const key in submissionData) {
            if (UI_TRANSLATION_CONFIG.includes(key)) {
                if (key in UI_TRANSLATION_VALUES) {
                    submissionData[key] = this.getKeyByValue(UI_TRANSLATION_VALUES[key], submissionData[key] as string);
                }
            }
        }
        this.viewState$.next(ViewState.Loading);

        return this.productService
            .updateCarrierEltWallControlConfigBySerialNo(this.serialNo, submissionData, editedParamInitialValues)
            .pipe(
                switchMap((res) => {
                    if (res?.code === ApiResponseCode.SUCCESS) {
                        this.contextService.showToast(false, res, this.toastOutlet);

                        return of(true).pipe(
                            delay(CONFIGURATION_RELOAD_DELAY),
                            tap(() => {
                                this.loadData$.next(true);
                            })
                        );
                    }

                    if (res) {
                        throw new ConnectedPortalAPIError({
                            code: res.code,
                            message: res.message,
                            data: res.data
                        });
                    }

                    throw new ConnectedPortalDataError({
                        message: MISSING_API_RESPONSE_DATA,
                        value: MISSING_API_RESPONSE_DATA
                    });
                }),
                catchError((err: ConnectedPortalAPIError | ConnectedPortalDataError) => {
                    this.resetFormGroups();
                    this.viewState$.next(ViewState.Data);

                    if (err instanceof ConnectedPortalAPIError) {
                        this.carrierEltConfigService.showErrorToast(err as ConnectedPortalAPIError, this.toastOutlet);
                    }
                    else {
                        this.carrierEltConfigService.showDataErrorToast(this.toastOutlet);
                    }

                    return NEVER;
                }),
                tap(() => {
                    this.viewState$.next(ViewState.Data);
                })
            );
    }

    private initCompressorMinAuxMaxControlSyncing(configFormGroup: UntypedFormGroup) {
        const compressorOutdoorMinCtrl = configFormGroup.get('compressorOutdoorMinTemp');
        const auxHeatLockoutCtrl = configFormGroup.get('auxHeatLockoutTemp');

        if (compressorOutdoorMinCtrl && auxHeatLockoutCtrl) {
            const validateCompressorOutdoorMinCtrlValue = () => {
                const compressorOutdoorMinNum = stringToNumber(compressorOutdoorMinCtrl.value);
                const auxHeatLockoutNum = stringToNumber(auxHeatLockoutCtrl.value);

                if (auxHeatLockoutNum === null || compressorOutdoorMinNum === null) {
                    return;
                }

                if (compressorOutdoorMinNum >= auxHeatLockoutNum) {
                    this.isCompressorLockOutWarningShown$.next(true);
                }
                else {
                    this.isCompressorLockOutWarningShown$.next(false);
                }
            };

            compressorOutdoorMinCtrl?.valueChanges.subscribe(() => {
                validateCompressorOutdoorMinCtrlValue();
            });

            auxHeatLockoutCtrl.valueChanges.subscribe(() => {
                validateCompressorOutdoorMinCtrlValue();
            });
        }
    }

    private initHeatDeltaControlSyncing(configFormGroup: UntypedFormGroup) {
        const heatDeltaStage1Ctrl = configFormGroup.get('heatDeltaStage1');
        const heatDeltaStage2Ctrl = configFormGroup.get('heatDeltaStage2');

        if (heatDeltaStage1Ctrl && heatDeltaStage2Ctrl) {
            const validateHeatDeltaCtrlValue = () => {
                const heatDeltaStage1Num = stringToNumber(heatDeltaStage1Ctrl.value);
                const heatDeltaStage2Num = stringToNumber(heatDeltaStage2Ctrl.value);

                if (heatDeltaStage1Num === null || heatDeltaStage2Num === null) {
                    return;
                }

                if (heatDeltaStage1Num >= heatDeltaStage2Num) {
                    this.isHeatDeltaWarningShown$.next(true);
                }
                else {
                    this.isHeatDeltaWarningShown$.next(false);
                }
            };

            heatDeltaStage1Ctrl?.valueChanges.subscribe(() => {
                validateHeatDeltaCtrlValue();
            });

            heatDeltaStage2Ctrl.valueChanges.subscribe(() => {
                validateHeatDeltaCtrlValue();
            });
        }
    }

    private initCoolDeltaControlSyncing(configFormGroup: UntypedFormGroup) {
        const coolDeltaStage1Ctrl = configFormGroup.get('coolDeltaStage1');
        const coolDeltaStage2Ctrl = configFormGroup.get('coolDeltaStage2');

        if (coolDeltaStage1Ctrl && coolDeltaStage2Ctrl) {
            const validateCoolDeltaCtrlValue = () => {
                const coolDeltaStage1Num = stringToNumber(coolDeltaStage1Ctrl.value);
                const coolDeltaStage2Num = stringToNumber(coolDeltaStage2Ctrl.value);

                if (coolDeltaStage1Num === null || coolDeltaStage2Num === null) {
                    return;
                }

                if (coolDeltaStage1Num >= coolDeltaStage2Num) {
                    this.isCoolDeltaWarningShown$.next(true);
                }
                else {
                    this.isCoolDeltaWarningShown$.next(false);
                }
            };

            coolDeltaStage1Ctrl?.valueChanges.subscribe(() => {
                validateCoolDeltaCtrlValue();
            });

            coolDeltaStage2Ctrl.valueChanges.subscribe(() => {
                validateCoolDeltaCtrlValue();
            });
        }
    }

    private initHeatDeltaAuxControlSyncing(configFormGroup: UntypedFormGroup) {
        const heatDeltaAuxStage1Ctrl = configFormGroup.get('heatDeltaAuxStage1');
        const heatDeltaAuxStage2Ctrl = configFormGroup.get('heatDeltaAuxStage2');

        if (heatDeltaAuxStage1Ctrl && heatDeltaAuxStage2Ctrl) {
            const validateHeatDeltaAuxCtrlValue = () => {
                const heatDeltaAuxStage1Num = stringToNumber(heatDeltaAuxStage1Ctrl.value);
                const heatDeltaAuxStage2Num = stringToNumber(heatDeltaAuxStage2Ctrl.value);

                if (heatDeltaAuxStage1Num === null || heatDeltaAuxStage2Num === null) {
                    return;
                }

                if (heatDeltaAuxStage1Num >= heatDeltaAuxStage2Num) {
                    this.isHeatDeltaAuxWarningShown$.next(true);
                }
                else {
                    this.isHeatDeltaAuxWarningShown$.next(false);
                }
            };

            heatDeltaAuxStage1Ctrl?.valueChanges.subscribe(() => {
                validateHeatDeltaAuxCtrlValue();
            });

            heatDeltaAuxStage2Ctrl.valueChanges.subscribe(() => {
                validateHeatDeltaAuxCtrlValue();
            });
        }
    }

    private getKeyByValue(object:Record<string, string>, value:string) {
        return Object.keys(object).find((key) => object[key] === value);
    }

    private setConfigFormValueChangeListner(configFormGroup: UntypedFormGroup) {
        configFormGroup.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$),
            startWith(configFormGroup.value),
            pairwise(),
            distinctUntilChanged(),
            map(([oldValues, newValues]) => Object.keys(configFormGroup.controls).find((fieldName) => oldValues[fieldName] !== newValues[fieldName]))
        ).subscribe((modifiedParam) => {
            if (modifiedParam && !this.modifiedConfigParameters.includes(modifiedParam)) {
                this.modifiedConfigParameters.push(modifiedParam);
            }
        });
    }

    private disableEditOnValueChangeValidation() {
        combineLatest([this.isCompressorLockOutWarningShown$, this.isHeatDeltaWarningShown$, this.isCoolDeltaWarningShown$, this.isHeatDeltaAuxWarningShown$])
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe(([isCompressorLockOutWarningShown, isHeatDeltaWarningShown, isCoolDeltaWarningShown, isHeatDeltaAuxWarningShown]) => {
                this.disableEditOnValidation = isCompressorLockOutWarningShown || isHeatDeltaWarningShown || isCoolDeltaWarningShown || isHeatDeltaAuxWarningShown;
            });
    }
}
