import { Component, Input, SimpleChanges } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CharSymbol } from 'common/enums/char-symbol';
import {
    ComfortProfileInput,
    ComfortProfileOutput,
    ComfortProfileProps
} from 'private/app/models/wall-control.model';
import { TempUnitFormat } from 'private/app/views/connected-portal/constants';
import { NgClass, NgForOf, NgIf, SlicePipe, TitleCasePipe } from '@angular/common';
import { CommonComponentsModule } from 'common/components/common-components.module';
interface DayData {
    isOpen: boolean;
    activities: Omit<ComfortProfileProps, 'day'>[];
    day: string;
}

@Component({
    selector: 'hvac-wall-control-elt-program-settings',
    templateUrl: './wall-control-elt-program-settings.component.html',
    styleUrls: ['./wall-control-elt-program-settings.component.scss'],
    imports: [
        NgIf,
        CommonComponentsModule,
        TranslateModule,
        NgClass,
        TitleCasePipe,
        SlicePipe,
        NgForOf
    ],
    standalone: true
})
export class WallControlEltProgramSettingsComponent {
    @Input() data: ComfortProfileInput;
    @Input() tempUnitFormat: TempUnitFormat;

    public transformedData: ComfortProfileOutput;
    scheduleEnabled: string;
    currentScheduleId: string;
    public groupedDataByDay: {
        day: string;
        isOpen: boolean;
        activities: Omit<ComfortProfileProps, 'day'>[];
    }[];

    public readonly CharSymbol = CharSymbol;
    public readonly TempUnitFormat = TempUnitFormat;
    constructor(
        private readonly translate: TranslateService
    ) {

    }

    ngOnInit(): void {
        this.transformedData = Object.keys(this.data).reduce(
            (acc, activity) => {
                activity !== 'scheduleInfo' &&
                    this.data[activity]?.forEach((item) => {
                        if (!acc[item.day]) {
                            acc[item.day] = [];
                        }
                        acc[item.day].push({
                            activity,
                            startTime: item.startTime,
                            clsp: item.clsp,
                            htsp: item.htsp,
                            id: item.id
                        });
                    });

                return acc;
            },
            {} as { [key: string]: Omit<ComfortProfileProps, 'day'>[] }
        );

        this.groupedDataByDay = Object.keys(this.transformedData).map((day) => {
            const activities = this.transformedData[day];

            return {
                day: this.getDayName(Number(day)),
                activities: this.sortByTime(activities),
                isOpen: false
            };
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.data?.currentValue) {
            this.scheduleEnabled =
                changes.data.currentValue?.scheduleInfo?.scheduleEnabled;
            this.currentScheduleId =
                changes?.data?.currentValue?.scheduleInfo?.currentScheduleId;
        }
    }

    getDayName(dayNumber: number): string {
        const daysOfWeek = this.translate.instant('CONNECTED_PORTAL.DAYS_OF_WEEK');

        return daysOfWeek[dayNumber];
    }

    toggleAccordion(day: DayData) {
        day.isOpen = !day.isOpen;
    }

    private sortByTime(item: Omit<ComfortProfileProps, 'day'>[]) {
        item.sort((alpha, beta) => {
            const curr = Number(alpha.startTime.split(':').join(''));
            const prev = Number(beta.startTime.split(':').join(''));

            return curr - prev;
        });

        return item;
    }
}
