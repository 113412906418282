import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'common/environments/environment';
import { Observable } from 'rxjs';

export const RECOVER_HOME_OWNER_PASSWORD = `mutation recoverPassword($input: RecoverPasswordInput!) {
    recoverPassword(input: $input) {
        success
        message
    }
}`;

@Injectable({ providedIn: 'root' })
export class HomeOwnerServiceService {
    constructor(private readonly httpClient: HttpClient) {}

    public resetHomeOwnerPassword(userName: string) {
        const postBody = {
            query: RECOVER_HOME_OWNER_PASSWORD,
            variables: { input: { username: userName } }
        };

        return this.httpClient
            .post(
                environment.api.connectedPortal.homeOwnerResetAPI,
                postBody
            ) as Observable<{
            data:{
                recoverPassword:{
                    message:string,
                    success:boolean
                }
            }
        }>;
    }
}
