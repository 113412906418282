import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountAdminBrandsItemsEntity, RelationshipType } from 'private/app/models/account-admin-search';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Option } from 'common/components/select/select.component';
import { AccountAdminService } from './account-admin.service';
import { AccountPostAdminService } from './account-post-admin.service';
import { BrandedPricingAndWarrantyConfiguration, CompanyLookUpQuery, RelationshipTabOption } from 'private/app/models/account-admin-company.model';
import { CompanyDetailsResponse } from 'private/app/models/distributor-relationships.model';
import { CompanySearchResultResponse } from 'private/app/models/account-admin-search-results';
type ManageRelationshipProfiles = keyof BrandedPricingAndWarrantyConfiguration


export enum CompanyCategory {
    CUSTOMER = 'HVAC Customer',
    INTERNATIONAL_CUSTOMER = 'International Customer',
    NATIONAL_ACCOUNT = 'National Account Customer',
    MARINE_CUSTOMER = 'Marine Customer',
    VENDOR = 'Vendor'
}

export enum RelationshipCategory {
    DISTRIBUTOR = 'ZDDT',
    INTERNATIONAL_DISTRIBUTOR = 'ZDID',
    MARINE_DISTRIBUTOR = 'ZDMR',
    INTERNAL = 'ZINT'
}

export enum CompanyServicesCode {
    WARRANTY_DISTRIBUTOR_WTYD = 20
}

export enum RelationshipTypeId {
    PRICING = 38,
    WARRANTY = 39
}

export enum RelationshipTypeCode {
    PRICING = 'PRC',
    WARRANTY = 'WTY'
}

export enum ClaimOption {
    ENTITLEMENT = 'E',
    ENTITLEMENT_AND_CLAIMS = 'C',
    INACTIVE = 'I'
}

export enum AutocompleteInputTypes {
    CompanyName = 'companyName',
    HvacpId = 'hvacCompanyIds',
    SoldTo = 'SoldTo'
}

export const NON_BRANDED = ['ZNBR', 'NBR'];

@Injectable({ providedIn: 'root' })
export class CompanyRelationshipService {
    public tierLevelOptions: Option[] = [
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.TIER_LEVEL_PLACEHOLDER'),
            value: ''
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.COMPANY_CREATE.TIER_LEVEL.LEADERSHIP'),
            value: '1'
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.COMPANY_CREATE.TIER_LEVEL.ADVERTISING'),
            value: '2'
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.COMPANY_CREATE.TIER_LEVEL.RESIDENTIAL_CONTRACTOR'),
            value: '3'
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.COMPANY_CREATE.TIER_LEVEL.COMMERCIAL_CONTRACTOR'),
            value: '5'
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.COMPANY_CREATE.TIER_LEVEL.OTHERS'),
            value: '4'
        }
    ];

    public companyDetails: CompanyDetailsResponse[] = [];
    isLoadMoreData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    claimTypeOptions$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    claimTypeName$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public initialPage = 0;
    private totalCount = 0;
    private pageSize = 50;
    private defaultCompanyType = [RelationshipCategory.DISTRIBUTOR, RelationshipCategory.INTERNAL, RelationshipCategory.INTERNATIONAL_DISTRIBUTOR, RelationshipCategory.MARINE_DISTRIBUTOR]
    constructor(
        private translateService: TranslateService,
        private adminService: AccountAdminService,
        private accountPostAdminService: AccountPostAdminService
    ) { }

    public getBrandsByRelationship(): Observable<Option[]> {
        return forkJoin([
            this.adminService.getBrands(),
            this.adminService.getRelationshipTypes()
        ]).pipe(
            map((res) => this.mergeRelationshipTypeWithBrands(res))
        );
    }

    public getDefaultCompanyType() {
        return this.defaultCompanyType;
    }

    public getCompanyTypeCodes(companyCategory: string | undefined): string[] {
        switch (companyCategory) {
            case CompanyCategory.CUSTOMER:
                return [RelationshipCategory.DISTRIBUTOR, RelationshipCategory.INTERNATIONAL_DISTRIBUTOR];
            case CompanyCategory.INTERNATIONAL_CUSTOMER:
                return [RelationshipCategory.DISTRIBUTOR, RelationshipCategory.INTERNATIONAL_DISTRIBUTOR];
            case CompanyCategory.NATIONAL_ACCOUNT:
                return [RelationshipCategory.DISTRIBUTOR, RelationshipCategory.INTERNATIONAL_DISTRIBUTOR];
            case CompanyCategory.VENDOR:
                return [RelationshipCategory.INTERNAL, RelationshipCategory.DISTRIBUTOR, RelationshipCategory.INTERNATIONAL_DISTRIBUTOR];
            case CompanyCategory.MARINE_CUSTOMER:
                return [RelationshipCategory.MARINE_DISTRIBUTOR];
            default:
                return [RelationshipCategory.DISTRIBUTOR, RelationshipCategory.INTERNAL, RelationshipCategory.INTERNATIONAL_DISTRIBUTOR, RelationshipCategory.MARINE_DISTRIBUTOR];
        }
    }

    public getClaimTypeOptions(warranty: string | undefined) {
        this.claimTypeOptions$.next([{
            name: '',
            value: ''
        }]);
        const entitlementAndClaimOptions: Option[] = [{
            name: this.translateService.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.WARRANTY_CLAIM_DEFAULT'),
            value: ''
        }];
        if (warranty === 'E') {
            entitlementAndClaimOptions.push({
                name: this.translateService.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.ENTITLEMENT'),
                value: ClaimOption.ENTITLEMENT
            });
        }
        else if (warranty === 'C') {
            entitlementAndClaimOptions.push({
                name: this.translateService.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.ENTITLEMENT'),
                value: ClaimOption.ENTITLEMENT
            });
            entitlementAndClaimOptions.push({
                name: this.translateService.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.ENTITLEMENT_CLAIMS'),
                value: ClaimOption.ENTITLEMENT_AND_CLAIMS
            });
        }
        this.claimTypeOptions$.next(entitlementAndClaimOptions);
    }

    getClaimTypeName(claimTypeValue: string | undefined) {
        (claimTypeValue === 'E') ? this.claimTypeName$.next(this.translateService.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.ENTITLEMENT')) : '';
        (claimTypeValue === 'C') ? this.claimTypeName$.next(this.translateService.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.ENTITLEMENT_CLAIMS')) : '';

        return this.claimTypeName$.value;
    }

    public getCompaniesByRelationshipQuery(selectedTab: ManageRelationshipProfiles, inputType: string, query: CompanyLookUpQuery, companyLookUpData$: BehaviorSubject<Option[]>, isLoadMore: boolean) {
        this.initialPage = isLoadMore ? this.initialPage + 1 : this.initialPage;
        this.accountPostAdminService.postCompanyAuthSearch({
            criteria: {
                companyTypeCodes: selectedTab === RelationshipTabOption.BRAND ? this.getCompanyTypeCodes(query.companyCategory) : [],
                brandCodes: query.brand,
                name: (inputType === AutocompleteInputTypes.CompanyName) ? `*${query.company}*` : `**`,
                hvacCompanyIds: (inputType === AutocompleteInputTypes.HvacpId)
                    ? [
                        Number(query.company)
                    ]
                    : [],
                soldToCustomerId: (inputType === AutocompleteInputTypes.SoldTo) ? `*${query.company}*` : `**`,
                brandFamily: (selectedTab === RelationshipTabOption.PRICING || selectedTab === RelationshipTabOption.WARRANTY) ? query.brandFamily : '',
                pricingRelationship: Boolean(selectedTab === RelationshipTabOption.PRICING),
                warrantyRelationship: Boolean(selectedTab === RelationshipTabOption.WARRANTY),
                companyServices: selectedTab === RelationshipTabOption.WARRANTY ? [CompanyServicesCode.WARRANTY_DISTRIBUTOR_WTYD] : [],
                fetchByUsersHierarchy: false
            },
            pageParams: {
                page: this.initialPage,
                pageSize: this.pageSize
            },
            determineTotalCount: true
        }).subscribe((response) => {
            this.totalCount = (response?.totalCount === 0) ? this.totalCount : response?.totalCount;

            if (companyLookUpData$.value.length === 0) {
                this.companyDetails = this.mapCompanyDetails(response);
                this.paginationStatus();
                companyLookUpData$.next(this.filterCompanyByRelationshipType(selectedTab).map((company:CompanyDetailsResponse) => ({
                    value: `${company.hvacCompanyId} - ${company.soldTo}`,
                    name: company.companyName
                })));

                return;
            }

            // removing duplicates
            this.companyDetails = Array.from([...this.companyDetails, ...this.mapCompanyDetails(response)].reduce((objA, objB) => objA.set(objB.hvacCompanyId, objB), new Map()).values());
            this.paginationStatus();
            companyLookUpData$.next(Array.from([...companyLookUpData$.value, ...this.filterCompanyByRelationshipType(selectedTab).map((company:CompanyDetailsResponse) => ({
                value: `${company.hvacCompanyId} - ${company.soldTo}`,
                name: company.companyName
            }))].reduce((objA, objB) => objA.set(objB.value, objB), new Map()).values()));
        });
    }

    filterCompanyByRelationshipType(selectedTab: ManageRelationshipProfiles): CompanyDetailsResponse[] {
        if (selectedTab === 'pricing') {
            return this.companyDetails.filter((companyDetails) => companyDetails.soldTo);
        }

        if (selectedTab === 'warranty') {
            return this.companyDetails.filter((companyDetails) => companyDetails.soldTo && companyDetails.warranty !== 'I');
        }

        return this.companyDetails;
    }

    paginationStatus() {
        this.isLoadMoreData$.next(this.companyDetails.length < this.totalCount);
    }

    private mapCompanyDetails(response: CompanySearchResultResponse) {
        return response.data.map((company) => (
            {
                companyName: company.companyName,
                hvacCompanyId: company.hvacCompanyId.toString(),
                address1: company.address1,
                address2: company.address2,
                city: company.city,
                postalCode: company.postalCode,
                state: company.state,
                soldTo: company.soldTo,
                companyServices: company.companyServices,
                warranty: company.warranty
            }
        ));
    }

    private mergeRelationshipTypeWithBrands(res: [AccountAdminBrandsItemsEntity[], RelationshipType[]]): Option[] {
        const [
            brands,
            relationships
        ] = res;

        return brands.filter((brand) => brand.usageTypes?.includes('PRODUCTS')).map((filteredBrands) => ({
            name: filteredBrands.name,
            value: relationships.find((relationshipType) => relationshipType.brand?.code === filteredBrands.code)?.brand?.id || 0
        } as Option)).slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()));
    }
}
