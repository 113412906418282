<ng-template [ngIf]="isFormReady" [ngIfElse]="spinner">
<form class="hvac-enrollment-form-container" [formGroup]="enrollmentFormGroup" (ngSubmit)="onSubmit()" *ngIf="isFormReady">
    <div class="hvac-enrollment-form-header">
        <h1 class="hvac-h4">
            {{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PROGRAM_NAME' | translate}}<span class="hvac-h4-span">{{enrollmentFormData.programName}} </span>
        </h1>
        <p class="hvac-p-sm"><abbr title="required"
                class="hvac-enrollment-form-header-required">*</abbr>{{"ACCOUNT_ADMIN.PROGRAMS.REQUIRED_INFORMATION" |
            translate }}</p>
    </div>
    <div class="hvac-enrollment-form-form">
        <section class="hvac-row hvac-enrollment-company-information">
            <!-- Dealer Information Group -->
            <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-enrollment-company-details dealer">
                <div class="hvac-company-details" formGroupName="dealerInformation">
                    <h1 class="hvac-h4">
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.DEALER_INFORMATION' | translate}} <span
                            class="hvac-h4-span"></span>
                    </h1>
                    <div *ngFor="let dealerInfo of enrollmentFormConfiguration.dealerInformation; let i=index">
                        <div class="hvac-row">
                            <div class="hvac-col-xs-6 hvac-col-lg-6 hvac-label hvac-company-label" [ngClass]="{'hvac-required': dealerInfo.rules?.required}">
                                {{ dealerInfo.label }}
                            </div>
                            <div class="hvac-col-xs-6 hvac-col-lg-6 hvac-label hvac-company-value">
                                <enrollment-form-builder [field]="dealerInfo" group="dealerInformation" [form]="enrollmentFormGroup" [disable]="disableDealerInfo">
                                </enrollment-form-builder>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End of dealer information form -->

            <!-- Distributor Information Group -->
            <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-enrollment-company-details distributor">
                <div class="hvac-company-details" formGroupName="distributorInformation">
                    <h1 class="hvac-h4">
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.DISTRIBUTOR_INFORMATION' | translate}} <span
                            class="hvac-h4-span"> </span>
                    </h1>
                    <div *ngFor="let distributorInfo of enrollmentFormConfiguration.distributorInformation; let i=index">
                        <div class="hvac-row">
                            <div class="hvac-col-xs-6 hvac-col-lg-6 hvac-label hvac-company-label" [ngClass]="{'hvac-required': distributorInfo.rules?.required}">
                                {{ distributorInfo.label }}
                            </div>
                            <div class="hvac-col-xs-6 hvac-col-lg-6 hvac-label hvac-company-value">
                                <enrollment-form-builder [field]="distributorInfo" group="distributorInformation" [form]="enrollmentFormGroup" [disable]="disableDistributorInfo" [disableRSM]="disableRegionalSalesManager">
                                </enrollment-form-builder>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End of Distributor Information Group -->
        </section>
        <!-- Sales information -->
        <section class="hvac-enrollment-sales-information">
            <div class="hvac-sales-details">
                <div class="hvac-row no-gutter">
                    <div class="hvac-col-xs-12 hvac-col-lg-3 hvac-sales-info-title">
                        <h1 class="hvac-h4">
                            {{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.SALES_INFORMATION' | translate}} <span
                                class="hvac-h4-span"></span>
                        </h1>
                    </div>
                </div>
                <section class="hvac-enrollment-sales-information">
                    <div class="hvac-sales-details" formGroupName="salesInfoCommon">
                        <div class="hvac-row no-gutter" *ngFor="let salesInfoCommon of enrollmentFormConfiguration.salesInfoCommon; let i=index">
                            <div class="hvac-col-xs-6 hvac-col-lg-5 hvac-label hvac-company-label hvac-sales-info-title"
                            [ngClass]="{'hvac-required': salesInfoCommon.rules?.required}">
                                {{ salesInfoCommon.label }}
                            </div>
                            <div class="hvac-col-xs-6 hvac-col-lg-3 hvac-label hvac-company-value">
                                <enrollment-form-builder [field]="salesInfoCommon" group="salesInfoCommon" [form]="enrollmentFormGroup" [disable]="disableSalesInfo">
                                </enrollment-form-builder>
                            </div>
                        </div>
                        <div class="hvac-rhythm-3"></div>
                    </div>
                </section>
                <!-- Sales form -->
                <section formGroupName="salesInformation">
                    <div class="hvac-rhythm-3"></div>
                    <div class="hvac-row no-gutter" *ngFor="let salesInfo of enrollmentFormConfiguration.salesInformation; let i=index">
                        <div class="hvac-col-xs-6 hvac-col-lg-5 hvac-label hvac-company-label hvac-sales-info-title" [ngClass]="{'hvac-required': salesInfo.rules?.required}">
                            {{ salesInfo.label }}
                        </div>
                        <div class="hvac-col-xs-6 hvac-col-lg-3 hvac-label hvac-company-value">
                            <enrollment-form-builder [field]="salesInfo" group="salesInformation" [form]="enrollmentFormGroup" [disable]="disableSalesInfo">
                            </enrollment-form-builder>
                        </div>
                    </div>
                </section>
                <div class="hvac-rhythm-3"></div>
                <!-- Split of Business -->
                <section class="hvac-enrollment-sales-information" *ngIf="enrollmentFormConfiguration.splitOfBusiness.length">
                    <div class="hvac-sales-details" formGroupName="splitOfBusiness">
                        <div class="hvac-row no-gutter" *ngFor="let splitOfBusiness of enrollmentFormConfiguration.splitOfBusiness; let i=index">
                            <div class="hvac-col-xs-6 hvac-col-lg-5 hvac-label hvac-company-label hvac-sales-info-title" [ngClass]="{'hvac-required': splitOfBusiness.asyncRules?.required || splitOfBusiness.rules?.required }">
                                {{ splitOfBusiness.label }}
                            </div>
                            <div class="hvac-col-xs-6 hvac-col-lg-3 hvac-label hvac-company-value">
                                <label class="hvac-input-label error" *ngIf="i === 0 && !(enrollmentFormService.totalSplitOfBusiness$ | async)">{{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.SUM_OF_THE_VALUE' | translate }}</label>
                                <enrollment-form-builder [field]="splitOfBusiness" group="splitOfBusiness" [form]="enrollmentFormGroup" [disable]="disableSalesInfo">
                                </enrollment-form-builder>
                            </div>
                        </div>
                        <div class="hvac-rhythm-3"></div>
                    </div>
                </section>

                <section class="hvac-enrollment-sales-information">
                    <div class="hvac-row no-gutter hvac-sales-details">
                        <div class="hvac-rhythm-3"></div>

                        <div class="hvac-col-lg-5" formGroupName="salesBrandSold">
                            <div class="hvac-label hvac-sales-info-title hvac-row no-gutter">
                                {{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.BRAND_SOLD' | translate }}
                            </div>

                            <div class="hvac-col-lg-12">
                                <div class="hvac-row no-gutter"
                                    *ngFor="let salesBrandSold of enrollmentFormConfiguration.salesBrandSold; let i=index">
                                    <div class="hvac-col-xs-6 hvac-col-lg-6 hvac-label hvac-company-value">
                                        <enrollment-form-builder [field]="salesBrandSold" group="salesBrandSold" [form]="enrollmentFormGroup" [disable]="disableSalesInfo">
                                        </enrollment-form-builder>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="hvac-col-lg-7" formGroupName="percentOfPriorYearSales">
                            <div class="hvac-label hvac-sales-info-title hvac-error-info">
                               <div>{{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PERCENT_OF_PRIOR_YEAR_SALES' | translate }}</div>
                            </div>
                                <div class="hvac-row no-gutter"
                                    *ngFor="let percentOfPriorYearSale of enrollmentFormConfiguration.percentOfPriorYearSales; let i=index">
                                    <div class="hvac-col-xs-6 hvac-col-lg-6 hvac-label hvac-company-value">
                                        <enrollment-form-builder [field]="percentOfPriorYearSale" group="percentOfPriorYearSales" [form]="enrollmentFormGroup" [disable]="disableSalesInfo">
                                        </enrollment-form-builder>
                                    </div>
                                    <div class="hvac-label hvac-sales-info-title hvac-error-info">
                                         <label class="hvac-input-label error" *ngIf="i === 0 && !(enrollmentFormService.percentOfPriorYearSales$ | async)">{{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.SUM_OF_THE_VALUE' | translate }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="hvac-enrollment-sales-information">
                    <div class="hvac-sales-details">
                        <div class="hvac-rhythm-3"></div>
                        <div class="hvac-row no-gutter">
                            <div class="hvac-col-xs-6 hvac-col-lg-3 hvac-label hvac-company-label hvac-sales-info-title">
                                {{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.COMMENT' | translate }}
                            </div>
                            <div class="hvac-col-xs-6 hvac-col-lg- hvac-label hvac-company-value">
                                <utc-textarea class="hvac-distributor-comments-input"
                                    [placeholder]="'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.COMMENTS_PLACEHOLDER' | translate"
                                    shadow="none"
                                    [inputControl]="enrollmentFormGroup.controls.comments" [disabledInput]="disableSalesInfo"></utc-textarea>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
        <!-- End of Sales information -->


        <!-- Approver information -->
        <section *ngIf="displayApproverInfo" class="hvac-enrollment-approver-information">
            <div class="hvac-approver-details">
                <div class="hvac-row no-gutter">
                    <div class="hvac-col-xs-12 hvac-col-lg-3 hvac-approver-info-title">
                        <h1 class="hvac-h4">
                            {{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.APPROVER_INFORMATION' | translate}} <span
                                class="hvac-h4-span"></span>
                        </h1>
                    </div>
                </div>
                <section class="hvac-enrollment-approver-information">
                    <div class="hvac-approver-details" formGroupName="approverInformation">
                        <div class="hvac-row no-gutter" *ngFor="let approverInformation of enrollmentFormConfiguration.approverInformation; let i=index">
                            <div class="hvac-col-xs-6 hvac-col-lg-5 hvac-label hvac-company-label hvac-sales-info-title">
                                {{ approverInformation.label }}
                            </div>
                            <div class="hvac-col-xs-6 hvac-col-lg-3 hvac-label hvac-company-value">
                                <enrollment-form-builder [field]="approverInformation" group="approverInformation" [form]="enrollmentFormGroup" [disable]="disableApproverInfo">
                                </enrollment-form-builder>
                            </div>
                        </div>
                        <div class="hvac-rhythm-3"></div>
                    </div>
                </section>
            </div>
        </section>
        <!-- End of Approver information --> 

    </div>
    <div class="hvac-enrollment-form-footer">
        <button hvacButton="default" (click)="onClose()">
            <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.CANCEL' | translate }}</span>
        </button>
        <button type="submit" hvacButton="primary" [disabled]="!enrollmentFormGroup.valid || isFormSubmitted || !isSumHundred || isapprovalActionDisable">
            <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.SUBMIT_FORM' | translate }}</span>
        </button>
    </div>
</form>
</ng-template>
<ng-template #spinner>
    <utc-loading-spinner></utc-loading-spinner>
</ng-template>