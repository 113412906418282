import { CommonModule, NgClass, NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { ProductPropertyListItemComponent } from 'common/components/product-property-list/product-property-list-item/product-property-list-item.component';
import { ProductPropertyListComponent } from 'common/components/product-property-list/product-property-list.component';
import { CharSymbol } from 'common/enums/char-symbol';
import { MilitaryToStandard } from 'common/pipes/military-to-standard.pipe';
import { NumberSuffixPipe } from 'common/pipes/number-suffix.pipe';
import { Status, WallControlZoning } from 'private/app/models/wall-control.model';
import { DateTimeTzPipe } from 'private/app/pipes/date-time-tz.pipe';
import { DEVICE_TEMPERATURE_PROPERTIES } from 'private/app/views/connected-portal/constants';

@Component({
    selector: 'hvac-wall-control-zoning-details',
    templateUrl: './wall-control-zoning-details.component.html',
    styleUrls: ['./wall-control-zoning-details.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, CommonModule, TranslateModule, NgClass, NgIf, ProductPropertyListComponent, ProductPropertyListItemComponent]
})
export class WallControlZoningDetailsComponent implements OnChanges {
    @Input() data: WallControlZoning[];
    @Input() tempUnitFormat?: string | null;
    @Input() isZoningEnabled?: Status | null;

    public selectedTab: number = 0;
    public tabs: string[];
    public formattedData: { label: string; value: unknown }[][];

    constructor(
        private translateService: TranslateService,
        private dateTimeTzPipe: DateTimeTzPipe,
        private numberSuffixPipe: NumberSuffixPipe,
        private militaryToStandardPipe: MilitaryToStandard
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.data?.currentValue || changes?.tempUnitFormat?.currentValue) {
            this.formattedData = this.data?.map((zone) => Object.entries(zone).map(([key, value]) => ({
                label: this.getPropLabel(key),
                value: this.formatValue(key, value, this.tempUnitFormat)
            })));

            this.tabs = this.data.map((zone, index) => (zone.zoneName
                ? String(zone.zoneName)
                : `${this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.ZONE_NAME_DEFAULT')} ${index + 1}`));
        }
    }

    handleTabClick(tabSelection: number) {
        this.selectedTab = tabSelection;
    }

    orderByIndex() {
        return 0;
    }

    formatValue(propertyName: string, value: string, tempUnitFormat?: string | null) {
        if (DEVICE_TEMPERATURE_PROPERTIES.includes(propertyName)) {
            return this.numberSuffixPipe.transform(value, tempUnitFormat === 'C' ? CharSymbol.Celsius : CharSymbol.Fahrenheit);
        }

        switch (propertyName) {
            case 'zoneName':
                return String(value);
            case 'timestamp':
                return this.dateTimeTzPipe.transform(value);
            case 'overrideUntilTime':
                return value ? this.militaryToStandardPipe.transform(value) : value;
            default:
                return value;
        }
    }

    getPropLabel(propName: string): string {
        const translationKey = `CONNECTED_PORTAL.WALL_CONTROL.ZONING.PROPS.${propName}`;

        return this.translateService.instant(translationKey) || '';
    }
}
