import { ProductDescriptor } from 'common/models/product-descriptor';
import { ProductDetailsCardData } from '../views/connected-portal/dealer/components/product-details-card/product-details-card.component';
import { ProductAlert } from './alert.model';
import { ConnectedSystem } from 'private/app/models/connected-system.model';

export enum ProductType {
    WallControl = 'Wall Control',
    IndoorUnit = 'Indoor Unit',
    OutdoorUnit = 'Outdoor Unit'
}

export enum SystemType {
    ECOBEE = 'ECOBEE',
    INFINITY = 'INFINITY',
    ECOBEE_NI = 'ECOBEE_NI',
    CARRIER_ELT = 'CARRIER_ELT'
}

export enum ConnectedProductId {
    SerialNo = 'serialNo',
    ModelNo = 'modelNo'
}

export class ConnectedProduct {
    name: string;
    modelNo: string;
    serialNo: string;
    firmware: string;
    type: ProductType;
    image?: string;
    isPlcEquipped?: string;
    systemType: SystemType;
    stage: string | null;
    esn: string | null;
    dealerId: string;
    wallControlSerialNo: string;
    dateTime?: string;
    refrigerantType?: string | null;
}

export class ConnectedProductRes {
    data: ConnectedProduct
}

export interface Pagination {
    totalPages: number;
    currentPage: number;
}

export interface AlertsData extends Pagination {
    items: ProductAlert[];
}

export interface InfinityProductDetailsData {
    productInfo: ProductDetailsCardData;
    isWallControlConnected: boolean;
    system: ConnectedSystem | null;
}

export interface EcobeeNIProductDetailsData extends InfinityProductDetailsData {
    isRuntimeReportEnabled: boolean;
    modelName: string;
}
export interface CarrierELTProductDetailsData extends InfinityProductDetailsData {
    isRuntimeReportEnabled: boolean;
    modelName: string;
}
export interface EcobeeProductDetailsData extends EcobeeNIProductDetailsData {
    isTestEquipmentEnabled: boolean;
}

export interface StatusDataProps {
    statusProps: [string, string][],
    airflowVerificationProps: [string, string][] | null
}

export interface ConnectedProductUpdate {
    systemId: string;
    productType: string;
    data: {
        modelNo?: string;
        serialNo?: string;
    }
}

export interface ConnectedProductDescriptor extends ProductDescriptor {
    id?: ConnectedProductId;
}

export interface ConnectedProductUpdateResponse {
    status: string;
    data?: ConnectedProductUpdate;
}
