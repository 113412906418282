<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-cp-header">
            <hvac-content-header [data]="breadCrumbData"></hvac-content-header>
        </div>
    </div>
    <div bottom>
        <div class="hvac-cp-card">
            <div class="hvac-cp-search">
                <hvac-search-bar [label]="'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.DISTRIBUTOR_SEARCH' | translate"
                    [queryControl]="queryControl"
                    [placeholder]="'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.SEARCH_PLACEHOLDER' | translate"
                    (onSearchSubmit)="handleSearchSubmit($event)"
                    (onSearchReset)="handleSearchReset()"></hvac-search-bar>
            </div>
            <hvac-table [responsive]="true" theme="zebra">
                <thead>
                    <tr>
                        <th>{{ 'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.HVPID' | translate }}</th>
                        <th>{{ 'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.NAME' | translate }}</th>
                        <th>{{ 'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.DEALERS' | translate }}</th>
                        <th>{{ 'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.CONNECTED_CONTROLS' | translate }}</th>
                        <th>{{ 'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.CUSTOMERS' | translate }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody [ngClass]="{ 'hvac-cp-empty-list' : distributors.length === 0 }">
                    <tr *ngFor="let distributor of distributors">
                        <td [attr.data-label]="'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.HVPID' | translate">{{ distributor.id }}
                        </td>
                        <td [attr.data-label]="'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.NAME' | translate">{{ distributor.name
                            }}</td>
                        <td [attr.data-label]="'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.DEALERS' | translate">{{
                            distributor.connectedDealers }}</td>
                        <td [attr.data-label]="'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.CONNECTED_CONTROLS' | translate">{{
                            distributor.connectedControlCount }}</td>
                        <td [attr.data-label]="'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.CUSTOMERS' | translate">{{
                            distributor.connectedCustomerCount }}</td>
                        <td>
                            <div class="hvac-cp-details-link-wrapper">
                                <a *ngIf="distributor.id" class="hvac-cp-details-link" hvacButton="default" size="small"
                                    [routerLink]="['../distributors', distributor.id]" [queryParams]="{name: distributor.name}">{{
                                    'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.DETAILS' | translate }}</a>
                            </div>
                        </td>
                    </tr>
                    <ng-container *ngIf="!isLoading && distributors?.length === 0">
                        <tr>
                            <td colspan="6" [align]="'center'">
                                <div class="hvac-fw-bd">{{ 'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.EMPTY_MESSAGE' |
                                    translate }}</div>
                            </td>
                        </tr>
                    </ng-container>
                    <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
                </tbody>
            </hvac-table>
            <div class="hvac-cp-list-footer">
                <div class="hvac-cp-list-footer-pagination">
                    <hvac-pagination *ngIf="totalPages > 1" [totalPages]="totalPages"
                        [currentPage]="currentPage" (pageChange)="handlePageChange($event)">
                    </hvac-pagination>
                </div>
                <div class="hvac-cp-list-footer-export">
                    <button class="hvac-cp-action-button" hvacButton="primary" (click)="handleExportList()"
                        [disabled]="distributors.length === 0"> {{ 'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.EXPORT_LIST'
                        | translate }} </button>
                </div>
            </div>
        </div>
    </div>
</hvac-connected-portal-layout-container>
