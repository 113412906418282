<section class="hvac-monthly-customer-statements-header">
    <hvac-content-header [data]="(monthlycustomerStatementsService.getAdminBreadCrumbData() | async ) || breadCrumbData"></hvac-content-header>
</section>

<section class="hvac-content-container hvac-monthly-customer-statements-container">
    <div class="hvac-monthly-customer-statements-criteria-header hvac-statements-heading-default hvac-statements-heading">
        <h1 class="hvac-h4">{{ 'MONTHLY_CUSTOMER_STATEMENT.SELECTION_CRITERIA' | translate }}</h1>
    </div>
    <form [formGroup]="monthlycustomerStatementsService.customerStatementForm">
        <div class="account-number-container">
            <div class="hvac-row hvac-account-number-selection">
                <div class="hvac-label hvac-label-customer-statement hvac-required">
                    {{ "MONTHLY_CUSTOMER_STATEMENT.SELECT_ACCOUNT_NUMBERS" | translate }}
                </div>
                <div class="hvac-label hvac-label-customer-statement select-multiple">
                    <button hvacButton="text" (click)="this.isModalVisible = true"
                        class="hvac-multiple-select">{{
                        'MONTHLY_CUSTOMER_STATEMENT.SELECT' | translate }}</button>
                </div>
            </div>
            <div class="hvac-row">
                <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4"
                    [disabledInput]="true"
                    [placeholder]= "'MONTHLY_CUSTOMER_STATEMENT.ACCOUNT_NUMBER_PLACEHOLDER' | translate"
                    [inputControl]="this.monthlycustomerStatementsService.customerStatementForm.controls.customerIds">
                </utc-input>
            </div>
        </div>

        <div class="search-for-container">
            <div class="hvac-row">
                <div class="hvac-label hvac-label-customer-statement">
                    {{ "MONTHLY_CUSTOMER_STATEMENT.SEARCH_FOR" | translate }}
                </div>
            </div>
            <div class="hvac-row hvac-customer-statement-radio-container">
                <hvac-radio-button [checked]="!monthlycustomerStatementsService.monthlyStatementForm.closedItems || false" value="'open_item'" (change)="monthlycustomerStatementsService.monthlyStatementForm.closedItems = false"
                    [inputName]="'closedItems'">
                    {{ 'MONTHLY_CUSTOMER_STATEMENT.OPEN_ITEMS' | translate }}
                </hvac-radio-button>
                <hvac-radio-button [checked]="monthlycustomerStatementsService.monthlyStatementForm.closedItems || false" value="'closed_item'" (change)="monthlycustomerStatementsService.monthlyStatementForm.closedItems = true"
                    [inputName]="'closedItems'">
                    {{ 'MONTHLY_CUSTOMER_STATEMENT.CLOSED_ITEMS' | translate }}
                </hvac-radio-button>
            </div>
        </div>

        <div *ngIf="monthlycustomerStatementsService.monthlyStatementForm.closedItems === true" class="dates-container">
            <div class="hvac-row">
                <div class="hvac-label hvac-label-customer-statement hvac-required">
                    {{ "MONTHLY_CUSTOMER_STATEMENT.SELECT_CLEARING_DATE" | translate }}
                </div>
            </div>
            <div class="hvac-row hvac-date-range-picker">
                <div class="hvac-form-field hvac-col-xs-12 hvac-col-md-6 hvac-col-md-4">
                    <hvac-date-range-picker 
                        [startDate]="startDate"
                        [endDate]="endDate"
                        [disableLastValidDateRestore]="true"
                        [controls]="inputDateControls"
                        [error]="dateRangeError"
                        (onSelection)="setSelectionDate({ startDate: $event.startDate, endDate: $event.endDate })" theme="retro"></hvac-date-range-picker>
                </div>
            </div>
        </div>

        <utc-loading-spinner *ngIf="isAPIRequestActive" class="hvac-loading-overlay hvac-monthly-customer-statement-loader" spinnerSize="small"></utc-loading-spinner>

        <div class="hvac-divider"></div>

        <div class="export-button-container">
            <div class="hvac-account-admin-buttons">
                <button [hvacButton]="'primary'" 
                [disabled]="isAPIRequestActive || monthlycustomerStatementsService.customerStatementForm.controls.customerIds.value?.length === 0 || (monthlycustomerStatementsService.monthlyStatementForm.closedItems === true && (dateRange$.value.startDate === undefined || dateRange$.value.endDate === undefined || dateRangeError !== ''))"
                (click)="onDownload(monthlycustomerStatementsService.customerStatementForm)">
                <span>{{ 'MONTHLY_CUSTOMER_STATEMENT.DOWNLOAD' | translate }}</span>
                </button>
            </div>
            <div *ngIf="isAPIRequestActive" class="hvac-label hvac-label-customer-statement">
                {{ "MONTHLY_CUSTOMER_STATEMENT.EXPORT_IN_PROGRESS" | translate }}
            </div>
        </div>
    </form>

    <utc-modal class="hvac-multiple-selection-modal" [visible]="isModalVisible"
        (closeAction)="handleCloseModal()">
        <hvac-modal-header>
            <div class="hvac-multiple-account-number-modal-header">
                <h2>
                    {{"MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.SELECT_ACCOUNT_NUMBERS" | translate }}
                </h2>
            </div>
        </hvac-modal-header>

        <div class="havc-modal-table-container">

            <div class="hvac-filter-container">
                <utc-input class="hvac-col-lg-3  hvac-col-md-6 hvac-col-xs-12 hvac-filter-input" [inputControl]="filterControlGroup.controls.accountNumberControl"
                    label="{{ 'MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.CUSTOMER_NUMBER' | translate }}">
                </utc-input>
                <utc-input class="hvac-col-lg-4  hvac-col-md-6 hvac-col-xs-12 hvac-filter-input" [inputControl]="filterControlGroup.controls.accountNameControl"
                    label="{{ 'MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.CUSTOMER_NAME' | translate }}">
                </utc-input>
                <utc-input class="hvac-col-lg-2  hvac-col-md-6 hvac-col-xs-12 hvac-filter-input" [inputControl]="filterControlGroup.controls.soldToControl"
                label="{{ 'MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.SOLD_TO' | translate }}">
                </utc-input>
                <utc-input class="hvac-col-lg-2  hvac-col-md-6 hvac-col-xs-12 hvac-filter-input" [inputControl]="filterControlGroup.controls.cityControl"
                label="{{ 'MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.CITY' | translate }}">
                </utc-input>
                <utc-input class="hvac-col-lg-1  hvac-col-md-6 hvac-col-xs-12 hvac-filter-input" [inputControl]="filterControlGroup.controls.stateControl"
                    label="{{ 'MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.STATE' | translate }}">
                </utc-input>
            </div>

            <div *ngIf="isNoPayerAssigned" class="hvac-model-table-wrap hvac-rhythm-3">
                <hvac-table>
                    <thead>
                        <tr>
                            <td class="hvac-select-all-button">
                            </td>
                            <td class="hvac-col-lg-3 hvac-header-label"> {{'MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.CUSTOMER_NUMBER' | translate }}</td>
                            <td class="hvac-col-lg-4 hvac-header-label">{{ 'MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.CUSTOMER_NAME' | translate }}</td>
                            <td class="hvac-col-lg-2 hvac-header-label">{{ 'MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.SOLD_TO' | translate }}</td>
                            <td class="hvac-col-lg-2 hvac-header-label">{{ 'MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.CITY' | translate }}</td>
                            <td class="hvac-col-lg-1 hvac-header-label">{{ 'MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.STATE' | translate }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <div class="hvac-table-loading-spinner">
                            <utc-loading-spinner spinnerSize="small" *ngIf="isTableLoading"></utc-loading-spinner>
                        </div>

                        <tr *ngFor='let data of (accountNumberDisplayArray$ | async)'>
                            <td>
                                <utc-checkbox [checked]="data.isSelected" inputId="create-account-input-terms" inputName="create-account-input-terms"
                                    (change)="selectAccountNumber(data, $event)">
                                </utc-checkbox>
                            </td>
                            <td>
                                {{ data.payer }}
                            </td>
                            <td>
                                {{ data.companyName }}
                            </td>
                            <td>
                                {{ data.soldTo }}
                            </td>
                            <td>
                                {{ data.city }}
                            </td>
                            <td>
                                {{ data.state }}
                            </td>
                        </tr>
                    </tbody>
                </hvac-table>
            </div>
            <p class="hvac-customer-statements-no-results" *ngIf="!isNoPayerAssigned">{{ 'MONTHLY_CUSTOMER_STATEMENT.NO_PAYER_ASSIGNED' | translate }}</p>
        </div>
        
        <div class="hvac-row hvac-between-xs hvac-account-selection-footer">
            <div class="hvac-account-selection-pagination-control">
                <hvac-pagination *ngIf="totalPages > 1" [totalPages]="totalPages" [currentPage]="currentPage"
                    (pageChange)="handlePageChange($event)">
                </hvac-pagination>
            </div>
            <div *ngIf="isNoPayerAssigned" class="hvac-account-selection-action-container">
                <button class="hvac-account-selection-submit-button"
                    type="button" [hvacButton]="'primary'" (click)="selectMultipleAccountsButton()">
                    <span>{{ 'MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.SELECT' | translate }}</span>
                </button>
            </div>
        </div>

    </utc-modal>

</section>

