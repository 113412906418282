import { DataHeader } from 'common/views/parts/parts-subcategory-details/parts-catalog-list/parts-catalog-list.component';

export interface Paginable<T> {
    data: T;
    getId: () => string;
}

export interface Selectable<T> extends Paginable<T> {
    isSelected: boolean;
}

export class PaginationData<T> {
    public isSelectAllChecked: boolean = false;
    private currentPageNum: number;
    private totalPages: number;
    private pageSize: number;
    private dataForPagination: string[];
    private dataSize: number;
    private dataHeaders: DataHeader[];

    constructor(data: (Selectable<T> | Paginable<T>)[], pageSize: number, dataHeaders: DataHeader[]) {
        this.pageSize = pageSize;
        this.dataHeaders = dataHeaders;
        this.initData(data);
    }

    public get crntPgNum() {
        return this.currentPageNum;
    }

    public get pgsCount() {
        return this.totalPages;
    }

    public get pgSize() {
        return this.pageSize;
    }

    public get dataFrPgntn() {
        return this.dataForPagination;
    }

    public get size() {
        return this.dataSize;
    }

    public get headers() {
        return this.dataHeaders;
    }

    public set crntPgNum(pgNum: number) {
        this.currentPageNum = pgNum;
    }

    static loadcurrentPageNumResults = <U>(dataItems: U[], pageNum: number, pageSize: number): U[] => dataItems.slice((pageNum - 1) * pageSize, pageNum * pageSize);


    public initData(data: Paginable<T>[]) {
        this.dataSize = data.length;
        this.totalPages = Math.ceil(data.length / this.pageSize);
        this.currentPageNum = 1;
        this.dataForPagination = data.map((item) => item.getId());
    }

    public setSortOrderForColumns(header: DataHeader): DataHeader | null {
        if (!header.title) {
            return null;
        }

        let currentSort: DataHeader = header;
        this.headers.forEach((dataHeader: DataHeader) => {
            if (dataHeader.value === header.value) {
                currentSort = header;
            }
            else {
                dataHeader.order = null;
            }
        });

        if (!currentSort.order) {
            currentSort.order = 'asc';
        }
        else if (currentSort.order === 'asc') {
            currentSort.order = 'desc';
        }
        else {
            currentSort.order = null;
        }

        return currentSort;
    }
}

export class PaginationUtils<T> extends PaginationData<T> {
    private currentPageData: Paginable<T>[];
    private unsortedOriginalData: Paginable<T>[];
    private sortedOriginalData: Paginable<T>[];

    constructor(data: Paginable<T>[], pageSize: number, dataHeaders: DataHeader[]) {
        super(data, pageSize, dataHeaders);
        this.initData(data);
    }

    public get crntPgUIData() {
        return this.currentPageData;
    }

    public get getAllPagesData() {
        return this.sortedOriginalData;
    }

    public initData(data: Paginable<T>[]) {
        super.initData(data);
        this.unsortedOriginalData = data;
        this.sortedOriginalData = [...this.unsortedOriginalData];
        this.currentPageData = PaginationUtils.loadcurrentPageNumResults(data, this.crntPgNum, this.pgSize);
    }

    public sortItems(header: DataHeader): void {
        if (!header.value) {
            return;
        }

        if (!header.order) {
            this.sortedOriginalData = [...this.unsortedOriginalData];

            return;
        }

        this.sortedOriginalData = this.sortedOriginalData.sort((item1, item2): number => {
            if (header.order === 'asc') {
                return item1.data[header.value as keyof T] < item2.data[header.value as keyof T] ? -1 : 1;
            }

            return item1.data[header.value as keyof T] > item2.data[header.value as keyof T] ? -1 : 1;
        });
    }

    public sort = (dataHeader: DataHeader) => {
        const newDataHeader = this.setSortOrderForColumns(dataHeader);

        if (!newDataHeader) {
            return;
        }

        this.sortItems(newDataHeader);
        this.currentPageData = PaginationUtils.loadcurrentPageNumResults(this.sortedOriginalData, this.crntPgNum, this.pgSize);
    }

    public onPageChange(pageNum: number) {
        this.crntPgNum = pageNum;
        this.currentPageData = PaginationUtils.loadcurrentPageNumResults(this.sortedOriginalData, pageNum, this.pgSize);
    }
}

export class SelectablePaginationUtils<T> extends PaginationData<T> {
    private currentSelectablePageData: Selectable<T>[];
    private unsortedSelectableOriginalData: Selectable<T>[];
    private sortedSelectableOriginalData: Selectable<T>[];
    private dataMap: Map<string, Selectable<T>>;

    constructor(data: Selectable<T>[], pageSize: number, dataHeaders: DataHeader[]) {
        super(data, pageSize, dataHeaders);
        this.initData(data);
    }

    public get crntPgSlctblUIData() {
        return this.currentSelectablePageData;
    }

    public get getAllPagesData(): Selectable<T>[] {
        return this.sortedSelectableOriginalData;
    }

    public initData(data: Selectable<T>[]) {
        super.initData(data);
        this.unsortedSelectableOriginalData = data;
        this.sortedSelectableOriginalData = [...this.unsortedSelectableOriginalData];
        this.currentSelectablePageData = SelectablePaginationUtils.loadcurrentPageNumResults(this.sortedSelectableOriginalData, this.crntPgNum, this.pgSize);
        this.dataMap = new Map(data.map((dataItem) => [dataItem.getId(), dataItem]));
    }

    public sortItems(header: DataHeader): void {
        if (!header.value) {
            return;
        }

        if (!header.order) {
            this.sortedSelectableOriginalData = [...this.unsortedSelectableOriginalData];

            return;
        }

        this.sortedSelectableOriginalData = this.sortedSelectableOriginalData.sort((item1: Selectable<T>, item2: Selectable<T>): number => {
            if (header.order === 'asc') {
                return item1.data[header.value as keyof T] < item2.data[header.value as keyof T] ? -1 : 1;
            }

            return item1.data[header.value as keyof T] > item2.data[header.value as keyof T] ? -1 : 1;
        });
    }

    public sort = (dataHeader: DataHeader) => {
        const newDataHeader = this.setSortOrderForColumns(dataHeader);

        if (!newDataHeader) {
            return;
        }

        this.sortItems(newDataHeader);
        this.currentSelectablePageData = SelectablePaginationUtils.loadcurrentPageNumResults(this.sortedSelectableOriginalData, this.crntPgNum, this.pgSize);
    }


    onSelectAllCheckboxClick() {
        this.isSelectAllChecked = !this.isSelectAllChecked;
        this.isSelectAllChecked ? this.onSelectAllFromAllPagesClick() : this.onDeselectAllFromAllPagesClick();
    }

    public selectItem(event: Event, selectedId: string) {
        const selectedItem = this.dataMap.get(selectedId);

        if (selectedItem) {
            selectedItem.isSelected = (event.target as HTMLInputElement).checked;
        }
        this.isSelectAllChecked = this.sortedSelectableOriginalData.every((item) => item.isSelected);
    }

    public onSelectAllCurrentPageClick() {
        this.currentSelectablePageData.forEach((user) => {
            user.isSelected = true;

            return user;
        });
        this.isSelectAllChecked = this.sortedSelectableOriginalData.every((item) => item.isSelected);
    }

    public onSelectAllFromAllPagesClick() {
        this.sortedSelectableOriginalData.forEach((user) => {
            user.isSelected = true;

            return user;
        });
        this.isSelectAllChecked = this.sortedSelectableOriginalData.every((item) => item.isSelected);
    }

    public onDeselectAllFromAllPagesClick() {
        this.sortedSelectableOriginalData.forEach((user) => {
            user.isSelected = false;

            return user;
        });
    }

    public onPageChange(pageNum: number) {
        this.crntPgNum = pageNum;
        this.currentSelectablePageData = SelectablePaginationUtils.loadcurrentPageNumResults(this.sortedSelectableOriginalData, pageNum, this.pgSize);
    }
}
