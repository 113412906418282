import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'hvac-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, TranslateModule, NgIf, ReactiveFormsModule, FormsModule]
})
export class SearchBarComponent implements OnChanges {
    @Input() label: string;
    @Input() queryControl: UntypedFormControl;
    @Input() showAdvancedSearchButton? = false;
    @Input() placeholder: string;
    @Output() public onSearchSubmit = new EventEmitter<string | null>();
    @Output() public onSearchReset = new EventEmitter<boolean>();
    @Output() public onAdvancedSearchClick = new EventEmitter<boolean>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.searchTerm?.currentValue) {
            const query = changes.searchTerm.currentValue;

            this.queryControl.setValue(query);
        }
    }

    handleSearchSubmit() {
        const query = this.queryControl.value;

        if (query && typeof query === 'string') {
            this.onSearchSubmit.emit(query.trim());
        }
        else {
            this.onSearchSubmit.emit(null);
        }
    }

    handleAdvancedSearchClick() {
        this.onAdvancedSearchClick.emit(true);
    }

    handleSearchReset() {
        this.onSearchReset.emit(true);
    }
}
