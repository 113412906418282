import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'hvac-distributor-navigation',
    templateUrl: './distributor-navigation.component.html',
    styleUrls: ['./distributor-navigation.component.scss'],
    standalone: true,
    imports: [
        CommonComponentsModule,
        TranslateModule
    ]
})
export class DistributorNavigationComponent {
    constructor(private router: Router) { }

    goToDistributorDashboard() {
        this.router.navigate(['/connected-portal']);
    }
}
