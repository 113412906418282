<main class="hvac-main-content-container">
    <hvac-primer-frame *ngIf='!(isPublicPlusUser$ | async)'></hvac-primer-frame>

    <div [ngClass]="{'hide-content':(dataResolverService.resolving$ | async)}" class='hvac-secured-body'>
        <div class="hvac-dashboard-main">
            <div class="hvac-content-container">
                <div class="hvac-account-status-notification" *ngIf='isPublicPlusUser$ | async'>
                    <utc-svg class="hvac-account-status-icon" src="assets/images/clipboard.svg"></utc-svg>
                    <div class="hvac-account-status-message">
                        <p class="hvac-account-status-title">{{ 'DASHBOARD.PUBLIC_PLUS.ACCOUNT_REVIEWED' | translate }}
                        </p>
                        <p class="hvac-account-status-body">
                            {{ 'DASHBOARD.PUBLIC_PLUS.ACCOUNT_EXPECTED_TIME' | translate }}
                            <br />
                            <span
                                [innerHTML]="'DASHBOARD.PUBLIC_PLUS.ACCOUNT_ASSISTANCE' | translate | safe: 'html'"></span>
                        </p>
                    </div>
                </div>
                <div class="hvac-title-container">
                    <h2 class='hvac-secured-title'>
                        {{'DASHBOARD.TITLE' | translate }}
                    </h2>
                    <ul class="hvac-recent-activity-menu">
                        <button class="hvac-recent-activity-button" (click)="openRecentActivity()">
                            <utc-svg class="hvac-recent-activity-icon" src="assets/icons/icn-recent.svg"></utc-svg>
                            <span class="hvac-accessible-text">{{ 'QUICKLINK.NEXT' | translate }}</span>
                        </button>
                    </ul>
                </div>
                <div class="hvac-banner-container" [ngClass]="{'hide-content': (dataResolverService.limitedFeatures$ | async)}">
                    <hvac-toast ></hvac-toast>
                </div>
                <hvac-notifications></hvac-notifications>
                <div class="hvac-whats-new-quick-links">
                    <hvac-whats-new *ngIf="(whatsNewContent$ | async)" [whatsNew]="(whatsNewContent$ | async)!">
                    </hvac-whats-new>
                    <hvac-document-list *ngIf="(whatsNewDocListContent$ | async)"
                        [data]="whatsNewDocListContent$ | async"></hvac-document-list>
                        <hvac-quick-links></hvac-quick-links>
                    </div>
                <hvac-video-widget [videos]="(videosContent$ | async)!"></hvac-video-widget>
            </div>
        </div>
        <div class="hvac-dashboard-highlights" *ngIf="(isActiveUser$ | async) && (showHighlightsSection$ | async)">
            <hvac-highlights [highlights]="(highlightsContent$ | async)!"
                [serviceBulletins]="(serviceBulletinsContent$ | async)!"></hvac-highlights>
            <section class="hvac-highlights">
                <div class="hvac-dashboard-bulletin-list">
                    <hvac-document-list *ngIf="(bulletinsDocListContent$ | async)"
                        [data]="bulletinsDocListContent$ | async"></hvac-document-list>
                </div>
            </section>
        </div>
    </div>
    <div *ngIf='isPublicPlusUser$ | async' class='hvac-secured-body hvac-pending-section'>
        <div class="hvac-content-container">
            <div class='hvac-product-browse-container'>
                <hvac-browse [data]="{ Content: { title: null, text: null } }"></hvac-browse>
            </div>
        </div>
    </div>
</main>
