/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'common/environments/environment';
import { SystemProduct } from 'common/models/system-product';
import { AlertSeverity } from 'private/app/models/alert.model';
import { ConnectedProduct, ConnectedProductDescriptor, ConnectedProductId, ProductType, SystemType } from 'private/app/models/connected-product.model';
import { ConnectedPropertyDetails } from 'private/app/models/connected-property.model';
import { ApiProtocol, ConnectedSystem, DataSharing } from 'private/app/models/connected-system.model';
import { AlertsService } from 'private/app/services/connected-portal/alerts.service';
import { MqttActivationManagerService } from 'private/app/services/connected-portal/mqtt-activation-manager.service';
import { findSystemProductByType, getIsValidSerialOrModelNo, getWarrantyLookupUrl, nonEmptyStringOrNull, stringToBool } from 'private/app/services/connected-portal/utils';
import { map } from 'rxjs/operators';
import { MAX_ACTIVATED_MQTT_SYSTEMS, RECENT_ACTIVITY_BANNER_MAX_AGE_DAYS, UNDOCUMENTED_MODELS } from '../../../constants';
import { PropertySystem } from './components/property-system-panel/property-system-panel.component';

export interface CustomersPropertyDetails {
    id: string;
    propertyName: string;
    customerInfo: {
        name: string;
        phone: string;
        email: string;
        username: string;
    },
    propertyAddress: {
        address1: string;
        address2?: string | null;
        region: string;
    },
    dealerInfo: {
        name: string;
        hvpId: string;
    },
    systems: PropertySystem[]
}

@Injectable()
export class CustomerPropertyService {
    private ecobeeEditConfigEnabled = environment.features.connectedPortal.ecobeeEditConfigFeature;

    constructor(
        private alertsService: AlertsService,
        private translateService: TranslateService,
        private mqttActivationManager: MqttActivationManagerService
    ) {}

    public activateMqttControls(wallControlSerials: string[], dealerId: string) {
        wallControlSerials.forEach((serialNo) => {
            this.mqttActivationManager.addControl(serialNo, dealerId);
        });
    }

    public removeMqttControls(wallControlSerials: string[]) {
        wallControlSerials.forEach((serialNo) => {
            this.mqttActivationManager.removeControl(serialNo);
        });
    }

    public getRecentAlert$(systemId: string, systemType: string, dealerId: string) {
        return this.alertsService.queryAlertsBySystemId(
            systemId,
            systemType,
            dealerId,
            {
                sort: {
                    field: 'dateTime',
                    order: 'desc'
                }
            }
        ).pipe(
            map((value) => {
                const [recentCriticalAlert] = [...value.data]
                    .filter((alert) => {
                        const maxAlertAgeDate = new Date().setDate(-RECENT_ACTIVITY_BANNER_MAX_AGE_DAYS);

                        const isRecentAlert = new Date(alert.dateTime).getTime() > maxAlertAgeDate;
                        const isCriticalAlert = alert.error.severity === AlertSeverity.CRITICAL;

                        return isRecentAlert && isCriticalAlert;
                    })
                    .sort((itemA, itemB) => itemB.dateTime.localeCompare(itemA.dateTime));

                return recentCriticalAlert || null;
            })
        );
    }

    public getBreadcrumbData(dealerId: string, dealerName?: string, propertyName?: string) {
        const defaultName = this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.PROPERTY');

        const data = {
            Content: { title: propertyName || defaultName },
            breadCrumbs: [
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                    url: `/connected-portal`
                },
                {
                    title: dealerName ? dealerName : this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DEALER'),
                    url: `/connected-portal/dealers/${dealerId}`
                },
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CUSTOMERS'),
                    url: `/connected-portal/dealers/${dealerId}/customers`
                }
            ]
        };

        return data;
    }

    public formatPropertyData(property: ConnectedPropertyDetails, propertyId: string, dealerId?: string): CustomersPropertyDetails {
        const {
            id,
            propertyName,
            customer,
            address,
            dealerName,
            dealerHVPID,
            systems
        } = property;

        const propertyAddress = {
            address1: address.address1,
            address2: address.address2,
            region: `${address.city}, ${address.state} ${address.zipCode}`
        };

        const customerInfo = {
            name: `${customer.firstName} ${customer.lastName}`,
            email: customer.email,
            phone: customer.phone1,
            username: customer.username
        };

        const dealerInfo = {
            name: dealerName,
            hvpId: dealerHVPID
        };

        const systemData = systems.map((system, index): PropertySystem => {
            const { dataSharing, products, systemId, systemType, systemName } = system;
            const systemProducts = this.formatProductsData(products, dataSharing, propertyId, dealerId);

            const dataSharingFormatted: DataSharing = {
                ...dataSharing,
                editConfig: systemType === SystemType.ECOBEE ? (this.ecobeeEditConfigEnabled && dataSharing.editConfig) : dataSharing.editConfig
            };

            const name = systemName && systemName.trim() !== ''
                ? systemName
                : `${this.translateService.instant('CONNECTED_PORTAL.PROPERTY_DETAIL.SYSTEMS.SYSTEM')} ${index + 1}`;

            const serialNo = findSystemProductByType(system, ProductType.WallControl)?.serialNo;

            return {
                systemId,
                name,
                dataSharing: dataSharingFormatted,
                products: systemProducts,
                systemType,
                serialNumber: serialNo
            };
        });

        return {
            id,
            propertyName,
            customerInfo,
            propertyAddress,
            dealerInfo,
            systems: systemData
        };
    }

    public getMqttSystemWallControlSerials(systems: ConnectedSystem[]) {
        const mqttSystems = systems.filter((system) => system.apiProtocol === ApiProtocol.MQTT).slice(0, MAX_ACTIVATED_MQTT_SYSTEMS);

        const serials = mqttSystems
            .map((system) => {
                const wallControlSerial = system.products.find((product) => product.type === ProductType.WallControl);

                return wallControlSerial?.serialNo || null;
            })
            .filter(Boolean);

        return serials as string[];
    }

    public getSystemsSortedBySerialNo(serialNo: string, systems: ConnectedSystem[]) {
        return [...systems].sort((systemA, systemB) => {
            const systemASortValue = systemA.products.find((product) => product.serialNo === serialNo) ? 1 : 0;
            const systemBSortValue = systemB.products.find((product) => product.serialNo === serialNo) ? 1 : 0;

            return systemBSortValue - systemASortValue;
        });
    }

    public checkUserConnectedToNonEcobee(systems: ConnectedSystem[]) {
        for (let i = 0; i < systems.length; i++) {
            if (!(systems[i].systemType === SystemType.ECOBEE || systems[i].systemType === SystemType.ECOBEE_NI)) {
                return true;
            }
        }

        return false;
    }

    private formatProductsData(products: ConnectedProduct[], dataSharing: DataSharing, propertyId?: string, dealerId?: string) {
        const wallControl = products.find((product) => product.type === 'Wall Control');

        return products.map((product): SystemProduct => {
            const { name: productName, serialNo, modelNo, firmware, type, image, systemType, isPlcEquipped, refrigerantType } = product;

            const descriptors: ConnectedProductDescriptor[] = [
                {
                    label: this.translateProductStrings('modelNo'),
                    id: ConnectedProductId.ModelNo,
                    value: modelNo,
                    ...((getIsValidSerialOrModelNo(modelNo) && !UNDOCUMENTED_MODELS.includes(modelNo)) && {
                        infoLink:
                        {
                            title: this.translateProductStrings('documentation'),
                            url: this.getDocumentationLinkUrl(type, modelNo)
                        }
                    })
                },
                {
                    label: this.translateProductStrings('serialNo'),
                    id: ConnectedProductId.SerialNo,
                    value: serialNo,
                    ...((environment.features.connectedPortal.warrantyLookup && getIsValidSerialOrModelNo(serialNo)) && {
                        infoLink: {
                            title: this.translateProductStrings('warranty'),
                            url: (dealerId && propertyId && getWarrantyLookupUrl(dealerId, propertyId, serialNo, systemType, type)),
                            target: '_self'
                        }
                    })
                }
            ];

            if (!([ProductType.IndoorUnit, ProductType.OutdoorUnit].includes(type) && [SystemType.ECOBEE_NI, SystemType.CARRIER_ELT].includes(systemType))) {
                descriptors.push({
                    label: this.translateProductStrings('firmware'),
                    value: firmware
                });
            }

            if (([ProductType.IndoorUnit, ProductType.OutdoorUnit].includes(type)) && refrigerantType) {
                descriptors.push({
                    label: this.translateProductStrings('refrigerantType'),
                    value: refrigerantType
                });
            }

            return {
                resourceType: 'systemProduct',
                image: image || '',
                name: this.getProductName(productName, type, systemType, modelNo, stringToBool(isPlcEquipped)),
                showFaultIndicator: false,
                descriptors,
                ...((this.getIsDataSharingEnabled(dataSharing, wallControl?.systemType) || type === 'Wall Control') && {
                    productLink:
                    {
                        title: this.translateProductStrings('productLink'),
                        url: this.getProductLinkUrl(type, wallControl?.serialNo, wallControl?.systemType)
                    }
                }),
                systemType,
                type,
                ...this.getProductStatusMetaData(type, systemType, modelNo, serialNo, stringToBool(isPlcEquipped))
            };
        });
    }

    private getProductLinkUrl(productType: ProductType, serialNo: string | undefined, systemType: SystemType | undefined): string {
        switch (productType) {
            case ProductType.WallControl:
                return `products/wall-control/${systemType}/${serialNo}`;
            case ProductType.IndoorUnit:
                return `products/indoor-unit/${systemType}/${serialNo}`;
            case ProductType.OutdoorUnit:
                return `products/outdoor-unit/${systemType}/${serialNo}`;
            default:
                return '';
        }
    }

    private getDocumentationLinkUrl(productType: ProductType, modelNo: string | undefined): string {
        switch (productType) {
            case ProductType.WallControl:
                return `/products/detail/${modelNo}`;
            case ProductType.IndoorUnit:
                return `/search/documents?q=${modelNo}`;
            case ProductType.OutdoorUnit:
                return `/search/documents?q=${modelNo}`;
            default:
                return '';
        }
    }

    private translateProductStrings(descriptor: 'modelNo' | 'serialNo' | 'firmware' | 'productLink' | 'documentation' | 'warranty' | 'refrigerantType') {
        switch (descriptor) {
            case 'modelNo':
                return this.translateService.instant('CONNECTED_PORTAL.PROPERTY_DETAIL.PRODUCT.MODEL_NO');
            case 'serialNo':
                return this.translateService.instant('CONNECTED_PORTAL.PROPERTY_DETAIL.PRODUCT.SERIAL_NO');
            case 'firmware':
                return this.translateService.instant('CONNECTED_PORTAL.PROPERTY_DETAIL.PRODUCT.FIRMWARE');
            case 'productLink':
                return this.translateService.instant('CONNECTED_PORTAL.PROPERTY_DETAIL.PRODUCT.PRODUCT_LINK');
            case 'documentation':
                return this.translateService.instant('CONNECTED_PORTAL.PRODUCT_CARD.DOCUMENTATION');
            case 'warranty':
                return this.translateService.instant('CONNECTED_PORTAL.PRODUCT_CARD.WARRANTY_LOOKUP');
            case 'refrigerantType':
                return this.translateService.instant('CONNECTED_PORTAL.PRODUCT_CARD.REFRIGERANT_TYPE');
            default:
                return '';
        }
    }

    private getIsDataSharingEnabled(dataSharing: DataSharing, systemType: SystemType | undefined): boolean {
        return ((dataSharing.viewStatus === 'true' || dataSharing.viewConfig === 'true') && systemType !== SystemType.ECOBEE_NI);
    }

    private getProductStatusMetaData(type: ProductType, systemType: SystemType, modelNo: string | undefined, serialNo: string | undefined, isPlcModel?: boolean): Pick<SystemProduct, 'isUpdateEnabled' | 'noDataMessage' | 'showDescriptor'> {
        const metaData: Pick<SystemProduct, 'isUpdateEnabled' | 'noDataMessage' | 'showDescriptor'> = {};

        switch (systemType) {
            case SystemType.ECOBEE:
                if ([ProductType.IndoorUnit, ProductType.OutdoorUnit].includes(type) && !isPlcModel) {
                    metaData.isUpdateEnabled = true;
                    metaData.noDataMessage = modelNo || serialNo ?
                        this.translateService.instant('CONNECTED_PORTAL.PROPERTY_DETAIL.PRODUCT.NON_INTELISENSE_EQUIPMENT') :
                        this.translateService.instant('CONNECTED_PORTAL.PROPERTY_DETAIL.PRODUCT.NO_INTELISENSE_EQUIPMENT_DETECTED');
                    metaData.showDescriptor = Boolean(modelNo || serialNo);
                }
                break;
            case SystemType.CARRIER_ELT:
                metaData.isUpdateEnabled = [ProductType.IndoorUnit, ProductType.OutdoorUnit].includes(type) || false;
                break;
            case SystemType.ECOBEE_NI:
                metaData.isUpdateEnabled = ([ProductType.IndoorUnit, ProductType.OutdoorUnit].includes(type) && !isPlcModel) || false;
                break;
            default:
                break;
        }

        return metaData;
    }

    private getProductName(productName: string, type: ProductType, systemType: SystemType, modelNo: string | undefined, isPlcEquipped?: boolean): string {
        if (systemType === SystemType.ECOBEE && [ProductType.IndoorUnit, ProductType.OutdoorUnit].includes(type) && !isPlcEquipped) {
            return type;
        }

        return ((!nonEmptyStringOrNull(modelNo) && [ProductType.IndoorUnit, ProductType.OutdoorUnit].includes(type)) || !nonEmptyStringOrNull(productName)) ? type : productName;
    }
}
