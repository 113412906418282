import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserAdminService } from 'private/app/services/account-admin/user/user-admin.service';
import { AppConstants } from 'common/app-constants';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { UserApiService } from 'private/app/services/account-admin/user/user-api.service';
import { UserAuthorizedFeatures, UserAuthorizedRoles, UserDetailsEntity, UserFeatures, UserSecurityRoles } from 'private/app/models/user.model';
import { BaseResponse } from 'common/models/account-status';
import { ToastService } from 'common/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
    selector: 'hvac-edit-user-security',
    templateUrl: './edit-user-security.component.html',
    styleUrls: ['./edit-user-security.component.scss']
})
export class EditUserSecurityComponent {
    @Input() userDetails: UserDetailsEntity;
    @Output() cancelUserSecurityChanges = new EventEmitter();

    public apiRequestActive = false;
    public isUserDetailsAvailable: boolean = true;
    public isUserDetailsSummeryMode: boolean = true;
    public isuserEditLastPage: boolean = false;
    public selectedTab: number = 0;
    public selectedTabName: string;
    public selectedAddTab: number = 0;
    public selectedAddTabName: string;
    public isTableLoading: boolean = false;
    public currentPage: number = AppConstants.currentPage;
    public rolesTabs: string[] = [];
    public additionalRolesTab: string[] = [];
    public editUserSecurity$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public companyId: string;
    public userAuthorizedRoles: UserAuthorizedRoles[];
    public userAuthorizedFeatures: UserAuthorizedFeatures[];
    public selectedRoles: UserSecurityRoles[] = [];
    public selectedAdditionalRoles: UserFeatures[] = [];
    public featuresSelectedByRole: string[] = [];
    public selectedFeaturesAndAdditionalRoles: UserFeatures[] = [];
    public postUserRoleDetails: UserDetailsEntity;
    public userRoleDetail: boolean = false;
    public featureDetail: boolean = false;
    public selctedDisplayName: string;
    public selectedDesc?: string;
    public userIdToDisplay?: string;

    constructor(
        private translate: TranslateService,
        private route: ActivatedRoute,
        public userAdminService: UserAdminService,
        public userAPIService : UserApiService,
        private readonly toastService: ToastService
    ) {}

    get userRoleAndFeatureDetails(): boolean {
        if (this.userRoleDetail) {
            return this.userRoleDetail;
        }
        if (this.featureDetail) {
            return this.featureDetail;
        }

        return false;
    }


    ngOnInit() {
        this.isUserDetailsAvailable = false;
        this.userAdminService.menuActive = false;
        this.companyId = this.userDetails?.companyId;
        this.userIdToDisplay = this.userDetails?.userID;
        this.selectedAdditionalRoles = this.userDetails?.userFeaturesDetails.map((item: UserFeatures) => item);
        if (this.companyId) {
            this.userAPIService.getUserAuthorizedRoles(this.companyId).subscribe((authorizedRoles) => {
                this.isUserDetailsAvailable = true;
                this.userAuthorizedRoles = authorizedRoles;
                this.userAuthorizedRoles.forEach((item) => {
                    this.rolesTabs.push(item.roleCategory);
                    item.userRoles.sort((a, b) => a.roleDisplayName.localeCompare(b.roleDisplayName));
                    item.userRoles.forEach((role) => {
                        role.isSelected = Boolean(this.checkRoleSelection(role));
                        (role.isSelected) ? this.updateFeaturesSelected(role) : '';
                    });
                });
                this.rolesTabs = this.rolesTabs.filter((item) => item !== 'System Defaults');
                this.selectedTabName = this.rolesTabs[0];
                this.selectedRoles = this.userDetails.userRolesDetails.map((item) => item);
                this.selectedRoles.sort((a, b) => a.roleDisplayName.localeCompare(b.roleDisplayName));
                this.selectedAdditionalRoles = this.selectedAdditionalRoles.filter((val) => !this.featuresSelectedByRole.includes(val.featureDisplayName));
                this.selectedAdditionalRoles.sort((a, b) => a.featureDisplayName.localeCompare(b.featureDisplayName));
            }, (error: HttpErrorResponse) => this.toastService.raiseDefaultErrorToast('EditUserSecurityComponent-ngOnInit', error.message));
            this.userAPIService.getUserAuthorizedFeatures(this.companyId).subscribe((authorizedFeatures) => {
                this.userAuthorizedFeatures = authorizedFeatures;
                this.userAuthorizedFeatures.forEach((item) => {
                    this.additionalRolesTab.push(item.featureCategory);
                    item.userFeatures.sort((a, b) => a.featureDisplayName.localeCompare(b.featureDisplayName));
                    item.userFeatures.forEach((feature) => feature.isSelected = Boolean(this.checkAddtnlRoleSelection(feature)));
                });
                this.selectedAddTabName = this.additionalRolesTab[0];
            }, (error: HttpErrorResponse) => this.toastService.raiseDefaultErrorToast('EditUserSecurityComponent-getUserAuthorizedFeatures', error.message));
        }
    }

    public updateFeaturesSelected(role: UserSecurityRoles) {
        role.userFeatures?.map((feature) => {
            this.featuresSelectedByRole.push(feature.featureDisplayName);
            this.selectedFeaturesAndAdditionalRoles.push(feature);
            this.featuresSelectedByRole.sort((a, b) => a.localeCompare(b));

            return feature;
        });
    }

    public checkRoleSelection(role: UserSecurityRoles) {
        return this.userDetails.userRolesDetails.find((item: UserSecurityRoles) => (item.roleId === role.roleId));
    }

    public checkAddtnlRoleSelection(feature: UserFeatures) {
        return this.userDetails.userFeaturesDetails.find((item: UserFeatures) => (item.featureId === feature.featureId));
    }

    public getRolesList() {
        return this.userAuthorizedRoles?.find((item) => item.roleCategory === this.selectedTabName)?.userRoles || [];
    }

    public getAdditionalRolesList() {
        return (this.userAuthorizedFeatures?.find((item) => item.featureCategory === this.selectedAddTabName)?.userFeatures || []).filter((val) => !this.featuresSelectedByRole.includes(val.featureDisplayName));
    }

    public getSystemDefaultRole() {
        return this.userAuthorizedRoles?.find((item) => item.roleCategory === 'System Defaults')?.userRoles[0].roleDisplayName;
    }

    public roleSelected(role: UserSecurityRoles) {
        role.isSelected = !role.isSelected;
        this.selectedRoles = [];
        this.featuresSelectedByRole = [];
        this.selectedFeaturesAndAdditionalRoles = [];
        this.userAuthorizedRoles.forEach((item) => {
            item.userRoles.forEach((subItem) => {
                if (subItem.isSelected) {
                    this.selectedRoles.push(subItem);
                    this.updateFeaturesSelected(subItem);
                }
            });
        });
        this.selectedRoles.sort((a, b) => a.roleDisplayName.localeCompare(b.roleDisplayName));
        this.selectedAdditionalRoles = this.selectedAdditionalRoles.filter((val) => !this.featuresSelectedByRole.includes(val.featureDisplayName));
    }

    public addtnlRoleSelected(addtnlRole: UserFeatures) {
        addtnlRole.isSelected = !addtnlRole.isSelected;
        this.selectedAdditionalRoles = [];
        this.userAuthorizedFeatures.forEach((item) => {
            item.userFeatures.forEach((subItem) => {
                (subItem.isSelected) ? this.selectedAdditionalRoles.push(subItem) : '';
            });
        });
        this.selectedAdditionalRoles.sort((a, b) => a.featureDisplayName.localeCompare(b.featureDisplayName));
        this.selectedAdditionalRoles = this.selectedAdditionalRoles.filter((val) => !this.featuresSelectedByRole.includes(val.featureDisplayName));
    }

    public cancelChanges() {
        this.cancelUserSecurityChanges.emit(false);
    }

    public handleTabClick(tabSelection: number, tab: string) {
        this.isTableLoading = true;
        this.selectedTab = tabSelection;
        this.selectedTabName = tab;
        this.currentPage = AppConstants.currentPage;
    }

    public handleAddTabClick(tabSelection: number, tab: string) {
        this.selectedAddTab = tabSelection;
        this.selectedAddTabName = tab;
        this.isTableLoading = true;
    }

    public getUserName() {
        return `${this.userDetails?.firstName} ${this.userDetails?.lastName}`;
    }

    public getCompanyInfo() {
        return `${this.userDetails?.employerName} ${this.userDetails?.userAddress1}, ${this.userDetails?.userCity}, ${this.userDetails?.userState}, ${this.userDetails?.userCountry}`;
    }

    public submitChanges() {
        this.isUserDetailsAvailable = false;
        this.apiRequestActive = true;
        this.userAdminService.smoothScrollToTop();
        this.postUserRoleDetails = this.userDetails;
        this.postUserRoleDetails.userRolesDetails = this.selectedRoles;
        this.postUserRoleDetails.userFeaturesDetails = (this.selectedAdditionalRoles.length === 0) ? this.selectedFeaturesAndAdditionalRoles : this.selectedAdditionalRoles.concat(this.selectedFeaturesAndAdditionalRoles);
        this.userAPIService.updateUserProfileDetails(this.postUserRoleDetails).subscribe((data:BaseResponse) => {
            this.isUserDetailsAvailable = true;
            if (data.status === this.translate.instant('USER_ADMIN.SUCCESS.USER_UPDATE_SUCCESS')) {
                this.toastService.add({
                    content: this.translate.instant('USER_ADMIN.SUCCESS.USER_UPDATE', { userID: this.route.snapshot.params.id }),
                    id: 'userUpdateSuccess',
                    theme: 'success',
                    closeable: true,
                    autoClose: true
                });
                this.cancelUserSecurityChanges.emit(false);
            }
        },
        () => {
            this.isUserDetailsAvailable = true;
            this.toastService.add({
                content: this.translate.instant('USER_ADMIN.SUCCESS.USER_UPDATE_ERROR'),
                id: 'userUpdateError',
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        });
    }

    public onChangePage() {
        this.isuserEditLastPage = !this.isuserEditLastPage;
        this.userAdminService.smoothScrollToTop();
    }

    public getUserDetailsSummery() {
        this.isUserDetailsSummeryMode = !this.isUserDetailsSummeryMode;
    }

    public userRoleAndFeatureModalClose() {
        if (this.userRoleDetail) {
            this.userRoleDetail = false;
        }
        if (this.featureDetail) {
            this.featureDetail = false;
        }
    }

    public onSelectRoleAndFeatureDetails(role:UserSecurityRoles | null, feature: UserFeatures | null) {
        if (role) {
            this.userRoleDetail = true;
            this.selctedDisplayName = role.roleDisplayName;
            this.selectedDesc = role.roleDesc;
        }
        if (feature) {
            this.featureDetail = true;
            this.selctedDisplayName = feature.featureDisplayName;
            this.selectedDesc = feature.featureDesc;
        }
    }
}
