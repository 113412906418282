<ng-container *ngIf="(dataSharingPermissions$ | async) as dataSharingPermissions">
    <div class="hvac-row">
        <div class="hvac-col-xs-12">
            <div class="hvac-cp-card">
                <hvac-product-details-card *ngIf="(productData$ | async) as productData; else productDetailLoader"
                    [product]="productData.productInfo"
                    [systemInfo]="systemProperties"
                    [isConnectionStatusVisible]="true"
                    [dealerId] = "dealerId"
                    [propertyId] = "propertyId"
                    [isRuntimeReportEnabled]="productData.isRuntimeReportEnabled"
                    [isDeviceConnected]="productData.isWallControlConnected"
                    (onRunReportClick)="contextService.isReportModalVisible$.next(true)"
                    [isRemoveControlEnabled]="true"
                    [dataSharingPermissions]="dataSharingPermissions"></hvac-product-details-card>
                <ng-template #productDetailLoader>
                    <div class="hvac-product-detail-loader">
                        <utc-loading-spinner></utc-loading-spinner>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="dataSharingPermissions?.systemConditions" class="hvac-system-conditions-container hvac-cp-card">
                <div class="hvac-row hvac-end-md hvac-rhythm-4">
                    <div class="hvac-zones-header hvac-col-xs-12">
                        <div>
                            <div *ngIf="(wallControlStatus$ | async) as wallControlStatus"
                                class="hvac-zone-status-date hvac-p-sm">{{ 'CONNECTED_PORTAL.WALL_CONTROL.STATUS_AS_OF'
                                | translate }} {{ wallControlStatus.dateTime | dateTimeTz }}</div>
                        </div>
                    </div>
                </div>
                <hvac-carrier-elt-system-conditions *ngIf="(tempUnitFormat$ | async) as tempUnitFormat"
                    [eltWallControlStatus]="(wallControlStatus$ | async)"
                    [systemType]="systemType"
                    [serialNo]="serialNo"
                    [dealerId]="dealerId"
                    [tempUnitFormat]="tempUnitFormat"
                    [dataSharingPermissions]="dataSharingPermissions">
                </hvac-carrier-elt-system-conditions>
            </div>
        </div>
    </div>
    <ng-container *ngIf="(accordions$ | async) as accordions">
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.detailedStatus"
                    [open]="accordions.detailedStatus && dataSharingPermissions?.detailedStatus === true"
                    (toggle)="toggleAccordion$.next('detailedStatus')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.DETAILED_STATUS.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-wall-control-status-details *ngIf="(wallControlStatus$ | async) as wallControlStatus"
                            [data]="wallControlStatus"
                            [systemType]="systemType"
                        >
                        </hvac-wall-control-status-details>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.humidityAndVentilation"
                    [open]="accordions.humidityAndVentilation && dataSharingPermissions?.humidityAndVentilation === true"
                    (toggle)="toggleAccordion$.next('humidityAndVentilation')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.HUMIDITY_AND_VENTILATION.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-wall-control-humidity-and-ventilation-details
                            *ngIf="(wallControlHumidAndVentData$ | async) as wallControlHumidAndVent"
                            [tempUnitFormat]="wallControlHumidAndVent.tempUnitFormat"
                            [systemType]="systemType"
                            [data]="wallControlHumidAndVent.comfortProfiles">
                        </hvac-wall-control-humidity-and-ventilation-details>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="(accordions$ | async) as accordions">
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.controlConfiguration"
                    [open]="accordions.config && dataSharingPermissions?.controlConfiguration === true"
                    (toggle)="toggleAccordion$.next('config')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <ng-container *ngIf="dataSharingPermissions?.controlConfiguration">
                            <hvac-carrier-elt-wall-control-config *ngIf="(wallControlConfigData$ | async) as configData"
                                [tempUnitFormat]="configData.tempUnitFormat"
                                [isEditEnabled]="configData.isEditEnabled"
                                [isSaveEnabled]="configData.isSaveEnabled"
                                [isDeviceConnected]="configData.isDeviceConnected"
                                [dealerId]="configData.dealerId"
                                [serialNo]="configData.serialNo"
                                [systemType]="configData.systemType"
                            ></hvac-carrier-elt-wall-control-config>
                        </ng-container>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="(accordions$ | async) as accordions">
        <div class="hvac-row hvac-rhythm-3" *ngIf="isEltWallControlEnergyEventEnabled">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.energyEvents || systemProperties?.vppEligibility !== EventType.ENROLLED"
                    [open]="accordions.energyEvents && dataSharingPermissions?.energyEvents === true"
                    (toggle)="toggleAccordion$.next('energyEvents')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.WALL_CONTROL.ENERGY_EVENTS.HEADER' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <ng-container *ngIf="dataSharingPermissions?.energyEvents">
                            <hvac-wall-control-energy-events
                                *ngIf="(wallControlEnergyEventData$ | async) as eventData"
                                [data]="eventData.items"
                                [totalPages]="eventData.totalPages"
                                [currentPage]="eventData.currentPage"
                                (onPageChange)="wallControlEnergyEventCurrentPage$.next($event)"
                            ></hvac-wall-control-energy-events>
                            <utc-loading-spinner *ngIf="isLoadingEnergyEvents$ | async" variant="overlay"></utc-loading-spinner>
                        </ng-container>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
    </ng-container>
</ng-container>




