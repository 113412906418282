import { Injectable } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { Subject } from 'rxjs';
import { CustomerMonthlyStatementPayload } from '../models/monthly-customer-statement.model';
import { DataHeader } from '../models/account-admin-search';

@Injectable({ providedIn: 'root' })
export class MonthlyCustomerStatementsService {
  public breadCrumbData = {
      Content: { title: this.translateService.instant('MONTHLY_CUSTOMER_STATEMENT.HEADING') },
      breadCrumbs: [
          {
              title: this.translateService.instant('MONTHLY_CUSTOMER_STATEMENT.PARENT_1'),
              url: '/ordering'
          },
          {
              title: this.translateService.instant('MONTHLY_CUSTOMER_STATEMENT.PARENT_2'),
              url: '/admin/reports'
          }
      ]
  };

  public customerStatementForm = new FormGroup({
      customerIds: new FormControl(['']),
      closedItems: new FormControl(false),
      startDate: new FormControl(''),
      endDate: new FormControl('')
  });

  public monthlyStatementForm: CustomerMonthlyStatementPayload = {
      CustomerIds: [],
      closedItems: false,
      startDate: '',
      endDate: '',
      requestType: '',
      responseFormat: ''
  };

  public filterControlGroup = new UntypedFormGroup({
      accountNumberControl: new UntypedFormControl(''),
      accountNameControl: new UntypedFormControl(''),
      soldToControl: new UntypedFormControl(''),
      cityControl: new UntypedFormControl(''),
      stateControl: new UntypedFormControl('')
  });

  public dataHeaders: DataHeader[] = [
      {
          title: this.translateService.instant('MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.CUSTOMER_NUMBER'),
          value: 'accountNumber',
          order: null
      },
      {
          title: this.translateService.instant('MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.CUSTOMER_NAME'),
          value: 'accountName',
          order: null
      },
      {
          title: this.translateService.instant('MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.SOLD_TO'),
          value: 'soldTo',
          order: null
      },
      {
          title: this.translateService.instant('MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.CITY'),
          value: 'city',
          order: null
      },
      {
          title: this.translateService.instant('MONTHLY_CUSTOMER_STATEMENT.SELECT_MULTIPLE_MODAL.STATE'),
          value: 'state',
          order: null
      }
  ];

  private breadCrumbData$: Subject<ContentHeadingData> = new Subject<ContentHeadingData>();

  constructor(private translateService: TranslateService) { }


  public setAdminBreadCrumbData(breadCrumb: ContentHeadingData) {
      this.breadCrumbData$.next(breadCrumb);
  }

  public getAdminBreadCrumbData() {
      return this.breadCrumbData$;
  }
}
