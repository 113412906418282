import { ChangeDetectorRef, Component, OnInit, AfterViewInit } from '@angular/core';
import { environment } from 'common/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'common/services/toast.service';
import { ErrorService } from 'common/content/services/error.service';
import { RouterOutlet } from '@angular/router';
import { DataResolverService } from 'common/content/services/data-resolver.service';
import { BaseComponent } from 'common/components/base/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-factory',
    templateUrl: './factory.component.html',
    styleUrls: ['./factory.component.scss'],
    imports: [
        RouterOutlet
    ],
    standalone: true
})
export class FactoryComponent extends BaseComponent implements OnInit, AfterViewInit {
    private showToast = false;

    constructor(
        private errorService: ErrorService,
        private cdr: ChangeDetectorRef,
        private toastService: ToastService,
        private translate: TranslateService,
        private dataResolverService: DataResolverService
    ) {
        super();
    }

    ngOnInit(): void {
        if (!environment.features.connectedPortal.internal) {
            this.errorService.errorStatus$.next(404);
            this.cdr.detectChanges();
            this.showToast = true;
        }
    }

    ngAfterViewInit(): void {
        if (this.showToast) {
            this.toastService.add({
                bgColor: '#F8F8F8',
                content: this.translate.instant('CONNECTED_PORTAL.BETA_DISTRIBUTOR_INTERNAL_TOAST'),
                id: 'cp-beta-message',
                closeable: true
            });
        }

        this.dataResolverService.limitedFeatures$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((isLimited) => {
                if (isLimited) {
                    this.toastService.add({
                        bgColor: '#F8F8F8',
                        content: this.translate.instant('CONNECTED_PORTAL.LIMITED_FEATURES_MESSAGE'),
                        id: 'cp-limited-features-message',
                        closeable: true
                    });
                }
            });
    }
}
