import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[scrollToElement]',
    standalone: true
})
export class ScrollToElementDirective {
    @Input() scrollToElement = false;
    @Input() scrollBehavior: 'auto' | 'smooth' = 'auto';

    constructor(
        private elementRef: ElementRef<HTMLElement>
    ) {
    }

    ngOnInit() {
        if (this.scrollToElement) {
            this.elementRef.nativeElement.scrollIntoView({
                behavior: this.scrollBehavior,
                block: 'start',
                inline: 'nearest'
            });
        }
    }
}
