import { RelationshipTypesUpdated, ACTIVATED_STAT, DEACTIVATED_STAT, InActiveActiveTabs } from './../../../models/distributor-relationships.model';
import { BaseResponse } from 'common/models/account-status';
import { Option } from 'common/components/select/select.component';
import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AccountAdminFormResultMap, Company, Contact } from 'private/app/models/account-admin-search';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { AssociationsParamsObject } from 'private/app/models/company.model';
import { AssociationBaseComponent } from '../association-base/association-base.component';
import { Brand } from 'private/app/services/user-validation/user-validation.service';
import { AccountAdminDetailsEditConfiguration, CompanyCategoryType } from '../../account-admin-company-details/company-query/company-query-configuration';
import { HvacAutoCompleteEvent } from 'common/components/hvac-autocomplete/hvac-auto-complete.component';
import { Select } from '@ngxs/store';
import { ContentState } from 'common/store/content/content.state';
import { BrandList } from 'common/models/brand.model';
import { PricingBrandWarrantyRelationship, CompanyRelationshipsResult, RelationshipTabOption } from 'private/app/models/account-admin-company.model';

@Component({
    selector: 'app-associated-distributors',
    templateUrl: './associated-distributors.component.html',
    styleUrls: ['./associated-distributors.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AssociatedDistributorsComponent extends AssociationBaseComponent {
    @ViewChild('distributorContainer', { static: true }) distributorContainer: ElementRef;
    @Select(ContentState.contentBrandList) availableBrandsList$!: Observable<BrandList[]>;

    autoComplete$: BehaviorSubject<HvacAutoCompleteEvent> = new BehaviorSubject<HvacAutoCompleteEvent>({ loading: false });
    public selectedRelationShipTab: RelationshipTabOption = RelationshipTabOption.BRAND;
    public deactivatedRelationships$ = new BehaviorSubject<PricingBrandWarrantyRelationship[]>([] as PricingBrandWarrantyRelationship[]);
    public brandsList$ = new BehaviorSubject<Brand[]>([]);
    public relationshipBrandId: string;
    public readonly relationshipTabOption: typeof RelationshipTabOption = RelationshipTabOption;
    public isRelationshipButtonVisible$ = this.companyQuery.companyDetailsConfiguration.pipe(
        map((config) => (config.configType === CompanyCategoryType.DOMESTIC_DISTRIBUTOR)
            || (config.configType === CompanyCategoryType.INTERNATIONAL_DISTRIBUTOR)
            || (config.configType === CompanyCategoryType.MARINE_DISTRIBUTOR)
            || (config.configType === CompanyCategoryType.INTERNAL))
    );

    companyConfiguration: AccountAdminDetailsEditConfiguration;
    brandList: Option[];
    invalidCIN$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    eventsSubject: Subject<PricingBrandWarrantyRelationship> = new Subject<PricingBrandWarrantyRelationship>();
    authorisedBrandList$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    displayLastWarningModal: boolean = true;
    public relationshipId: number;
    public relationshipTypes$ = new BehaviorSubject<RelationshipTypesUpdated[]>([] as RelationshipTypesUpdated[]);
    public hvp?: string = '';
    public contactList: Contact[];
    public toastOutlet = 'relationshipBannerToast';

    private activatedDistributorRelationships$ = new BehaviorSubject<(PricingBrandWarrantyRelationship)[]>([]);
    private relationshipToDeactivate: PricingBrandWarrantyRelationship;
    private activatedBrands: PricingBrandWarrantyRelationship[];

    ngOnInit() {
        // SUBSCRIPTIONS
        this.adminService.getAccountAdminSearchForm().pipe((takeUntil(this.ngOnDestroy$))).subscribe((data: AccountAdminFormResultMap) => {
            data.brands = this.distributorService.filterBrands(data.brands || []);
            this.brandFormData = { brands: data.brands };
            this.allBrands = data.brands;
        });
        (this.company) ? this.company.hvacCompanyId = this.route.snapshot.params.id : '';

        this.companyQuery.companyDetailsConfiguration.pipe(
            map((config) => {
                this.companyConfiguration = config;

                return (config.configType === CompanyCategoryType.DOMESTIC_DISTRIBUTOR)
                || (config.configType === CompanyCategoryType.INTERNATIONAL_DISTRIBUTOR)
                || (config.configType === CompanyCategoryType.MARINE_DISTRIBUTOR)
                || (config.configType === CompanyCategoryType.INTERNAL);
            })
        ).subscribe();


        this.availableBrandsList$.subscribe((brandList) => {
            let availableBrandList: Option[] = [];
            brandList?.forEach((brand) => {
                brand.catalogBrands?.forEach((catalogBrand) => {
                    availableBrandList.push({
                        name: catalogBrand.name,
                        value: catalogBrand.code
                    });
                });
            });

            // remove brand duplicates
            availableBrandList = Array.from(availableBrandList.reduce((objA, objB) => objA.set(objB.name, objB), new Map()).values()).slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()));
            this.authorisedBrandList$.next(availableBrandList);
        });
        this.route.queryParamMap.pipe(takeUntil(this.ngOnDestroy$)).subscribe((params) => {
            const pageNo = params.get('distributorPagination') as string;
            this.navigateToPage(pageNo, 'DDT');
        });

        this.onCompanyChange.pipe(takeUntil(this.ngOnDestroy$)).subscribe((comp: Company) => {
            if (comp?.id && this.company.id !== comp.id) {
                this.company = comp;
                this.navigateToPage(null, 'DDT');
            }
        });

        this.contactDetails$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((res: Contact[]) => {
            this.contactList = res;
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.urlChanged.pipe(takeUntil(this.ngOnDestroy$)).subscribe((value: any) => {
            let pair = null;
            const data: AssociationsParamsObject[] = [];
            if (value.url.includes('distributorPagination') && value.url.includes('distributorTable')) {
                const keyval = value.url.split('?')[1].split('&');
                keyval.forEach((element: string) => {
                    pair = element.split('=');
                    data.push({
                        key: pair[0],
                        value: pair[1]
                    });
                });
                const filteredArray: AssociationsParamsObject[] = data.filter((obj: AssociationsParamsObject) => obj.key === 'distributorPagination');
                if (filteredArray && filteredArray.length > 0) {
                    this.navigateToPage(filteredArray[0].value, 'DDT');
                }
            }
        });

        this.getCurrentDateAndTime();
        this.getRelationships();
    }

    initializeJumpbar() {
        this.jumpBarData = {
            link: this.translate.instant('ACCOUNT_ADMIN.DISTRIBUTOR_RELATIONSHIPS'),
            id: [this.translate.instant('ACCOUNT_ADMIN.DISTRIBUTOR_RELATIONSHIPS')],
            container: this.element.nativeElement
        };
        this.jumpBarService.registerJumpBarLink(this.jumpBarData);
    }

    getNativeElementRef() {
        return this.distributorContainer;
    }

    handleTabClickTabs(id: InActiveActiveTabs) {
        this.selectedTab = id;
        this.distributorListResults = this.selectedTab === 'active' ? this.activatedDistributorRelationships$.getValue() : this.deactivatedRelationships$.getValue();
        this.initializePagination();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    deactivateRelationship(distributor: PricingBrandWarrantyRelationship) {
        this.relationshipToDeactivate = distributor;
        this.companyRelationShipId = distributor.dealerDistributor.companyRelationShipId;
        const isBrandSelected = Boolean(this.activatedBrands.find((item) => item.brandId === this.relationshipToDeactivate.brandId));
        if (isBrandSelected) {
            this.deactivateRelationshipModal = true;
        }
        else if (this.tableData$.value.length === 1 && !isBrandSelected) {
            this.deactivateLastRelationshipModal = true;
        }
        else {
            this.deactivate();
        }
    }

    deactivateCheck() {
        const isBrandSelected = Boolean(this.activatedBrands.find((item) => item.brandId === this.relationshipToDeactivate.brandId));
        if (this.tableData$.value.length === 1 && isBrandSelected && this.displayLastWarningModal) {
            this.deactivateRelationshipModal = false;
            this.deactivateLastRelationshipModal = true;
            this.displayLastWarningModal = false;
        }
        else {
            this.deactivate();
        }
    }

    deactivate() {
        const isActiveusersExists = Boolean(this.contactList.find((item) => item.user.status === 'ACTIVE'));
        const isBrandSelected = Boolean(this.activatedBrands.find((item) => item.brandId === this.relationshipToDeactivate.brandId));
        (this.deactivateLastRelationshipModal) ? this.deactivateLastRelationshipModal = false : '';
        if (isBrandSelected && this.activatedBrands.length === 1 && isActiveusersExists) {
            this.toastService.add({
                content: this.translate.instant('ACCOUNT_ADMIN.DEACTIVATE_RELATIONSHIP_MODAL.ACTIVE_USERS_WARNING'),
                id: this.relationshipToDeactivate?.brandId?.toString()!,
                outletName: this.toastOutlet,
                theme: 'error',
                closeable: true,
                autoClose: true
            });
            this.deactivateRelationshipModal = false;

            return;
        }
        const body: CompanyRelationshipsResult = {
            brandedRelationships: [],
            pricingWarrantyRelationships: [],
            dealerCompanyId: Number(this.company?.hvacCompanyId),
            deactivatedRelationships: this.relationshipToDeactivate.dealerDistributor.companyRelationShipId ? [(this.relationshipToDeactivate.dealerDistributor.companyRelationShipId.toString())] : []
        };
        this.updateRelationship(body);
    }

    handleLastCloseModal() {
        this.displayLastWarningModal = true;
        this.deactivateLastRelationshipModal = false;
    }

    showManageModal(managingRelationshipInfo:PricingBrandWarrantyRelationship) {
        this.adminService.isModalVisible = true;
        this.eventsSubject.next(managingRelationshipInfo);
    }

    public postRelationships(relationshipData: CompanyRelationshipsResult) {
        const body: CompanyRelationshipsResult = {
            brandedRelationships: relationshipData.brandedRelationships,
            pricingWarrantyRelationships: relationshipData.pricingWarrantyRelationships,
            dealerCompanyId: Number(this.company?.hvacCompanyId),
            edit: relationshipData.edit || false,
            deactivatedRelationships: []
        };
        this.updateRelationship(body);
    }

    private updateRelationship(body: CompanyRelationshipsResult) {
        this.handleCloseModal();
        this.loading = true;
        let contentValue: string;
        this.distributorService.postAssociationsData(body).pipe(takeUntil(this.ngOnDestroy$)).subscribe((data:BaseResponse) => {
            this.loading = false;

            if (body.edit) {
                contentValue = data.details;
            }
            else if (body.deactivatedRelationships?.length === 0) {
                contentValue = this.translate.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.RELATIONSHIP_SUCCESS');
            }
            else if (body.deactivatedRelationships?.length !== 0) {
                contentValue = this.translate.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.RELATIONSHIP_REMOVED');
            }

            if (data.status === this.translate.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.SUCCESS_MESSAGE')) {
                this.toastService.add({
                    content: contentValue,
                    id: this.translate.instant('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.RELATIONSHIP_SUCCESS'),
                    outletName: this.toastOutlet,
                    theme: 'success',
                    closeable: true,
                    autoClose: true
                });

                if (this.tableData$.value.length === 1 && body.brandedRelationships.length === 0 && body.pricingWarrantyRelationships.length === 0) {
                    const currentUrl = this.router.url;
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        this.router.navigate([currentUrl]);
                    });
                }

                if (this.companyQuery.isCompanyCategoryCustomerVendor(this.company.companyCategory.name)) {
                    if (body.brandedRelationships.length > 0 || body.pricingWarrantyRelationships.length > 0) {
                        this.adminApiService.reactivate(this.company.hvacCompanyId).pipe(takeUntil(this.ngOnDestroy$)).subscribe((res:Company) => {
                            this.companyQuery.updateCompanyDetails.next(res);
                        });
                    }
                }
            }
            this.companyRelationShipId = 0;
            this.getRelationships();
        }, (error) => {
            this.loading = false;
            this.toastService.add({
                content: this.translate.instant(error.error.message),
                id: 'error',
                outletName: this.toastOutlet,
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        });
    }

    private getRelationships() {
        if (this.company) {
            this.distributorService.getCompanyRelationships(this.company.hvacCompanyId).pipe(takeUntil(this.ngOnDestroy$)).subscribe((res:CompanyRelationshipsResult) => {
                const activatedPricingWarranty = res.pricingWarrantyRelationships.filter((pricing) => pricing?.dealerDistributor.status === ACTIVATED_STAT);
                const deactivatedPricingWarranty = res.pricingWarrantyRelationships.filter((pricing) => pricing?.dealerDistributor.status === DEACTIVATED_STAT);
                this.activatedBrands = res.brandedRelationships.filter((brand) => brand.dealerDistributor.status === ACTIVATED_STAT);
                const deactivatedBrands = res.brandedRelationships.filter((brand) => brand.dealerDistributor.status === DEACTIVATED_STAT);
                this.deactivatedRelationships$.next([...deactivatedPricingWarranty, ...deactivatedBrands]);
                this.activatedDistributorRelationships$.next([...activatedPricingWarranty, ...this.activatedBrands]);
                this.distributorListResults = this.selectedTab === 'active' ? this.activatedDistributorRelationships$.getValue() : this.deactivatedRelationships$.getValue();
                this.initializePagination();
            });
        }
    }
}
